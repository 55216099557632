import React, { useState } from "react";
import { useTable } from "react-table";
import Modal from "react-modal";

import { error, success } from "utils/toast";
import Confirm from "commons/components/Confirm";
import AddEditUser from "./AddEditUser";
import { ITenantUser } from "store/actions/types/multitenancy";
import { addUser, updateUser, deleteUser } from "services/multitenancy";
import { useHistory } from "react-router-dom";

interface Props {
  usersListData: ITenantUser[];
  fetchAllUsers: () => void;
  tenantId: string;
}

const UsersTable = (props: Props) => {
  const history = useHistory();

  const { usersListData, fetchAllUsers, tenantId } = props;

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [isDeleteConfirmActive, setDeleteConfirmActive] = useState(false);
  const [userDeleting, setUserDeleting] = useState<boolean>(false);
  const toggleDeleteConfirmActive = () => {
    setDeleteConfirmActive((prevState) => !prevState);
  };

  const handleAddUser = (payload: ITenantUser) => {
    addUser(payload)
      .then((response: any) => {
        closeAddEditModal();
        success("User created");
        fetchAllUsers();
      })
      .catch((err) => {
        const { message } = err.response.data;
        if (Array.isArray(message)) {
          error(message.join("\n"));
        } else {
          error(message);
        }
      });
  };

  const handleUpdateUser = (payload: ITenantUser, userId: string) => {
    updateUser(userId, payload)
      .then((response: any) => {
        success("User updated");
        fetchAllUsers();
      })
      .catch((err) => {
        const { message } = err.response.data;
        if (Array.isArray(message)) {
          error(message.join("\n"));
        } else {
          error(message);
        }
      });
  };

  const handleUserSave = (payload: ITenantUser) => {
    isEditing
      ? handleUpdateUser(payload, payload?._id || "")
      : handleAddUser(payload);
  };

  const openAddEditModal = () => setIsAddEditModalOpen(true);
  const closeAddEditModal = () => setIsAddEditModalOpen(false);

  const handleUserDelete = () => {
    setUserDeleting(true);
    deleteUser(selectedRow._id).then((response) => {
      closeAddEditModal();
      fetchAllUsers();
      success("User deleted.");
      setUserDeleting(false);
    });
  };

  const data = React.useMemo(() => [...usersListData], []);

  const columns: any = React.useMemo(
    () => [
      {
        Header: "Fullname",
        Cell: ({ row }: any) => {
          return (
            <span>
              {row.original.firstName} {row.original.middleName}{" "}
              {row.original.lastName}
            </span>
          );
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Actions",
        // accessor: "_id",
        // disableSortBy: true,
        // width: 50,
        Cell: ({ row }: { row: any }) => {
          return (
            <div className="action-group tw-justify-start tw-space-x-2 tw-items-center">
              <span
                onClick={() => {
                  setIsEditing(true);
                  openAddEditModal();
                  setSelectedRow(row.original);
                }}
                className="mr-2x link-item"
              >
                <box-icon name="highlight" size="sm" color="#959FAE" />
              </span>
              <span
                onClick={() => {
                  setSelectedRow(row.original);
                  toggleDeleteConfirmActive();
                }}
                className="link-item mr-2x"
              >
                <box-icon name="trash" size="sm" color="#959FAE" />
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  // const emptyFormData = {
  //   firstname: "",
  //   middlename: "",
  //   lastname: "",
  //   email: "",
  //   tenantId: "",
  // };

  return (
    <div className="">
      <div className="tw-flex tw-justify-between tw-pb-2 tw-border-0 tw-border-solid tw-border-b tw-border-[#dedede]">
        <span
          onClick={() => history.push("/")}
          className="link-item tw-flex tw-space-x-1 tw-items-center"
        >
          <box-icon name="arrow-back"></box-icon>
          <span>Go Back</span>
        </span>
        {/* <h1>User List</h1>
        <div className="tw-w-28" /> */}
      </div>
      <div className="section-heading tw-mt-6 border-bottom mb-0x">
        <div className="page-heading">
          <h2>User List</h2>
          <div className="page-heading__right">
            <div className="filter-group align-items-center">
              <button
                className="btn btn--primary"
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  openAddEditModal();
                }}
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table tw-table-fixed" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="head__row">
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps()}
                    className="table__col table-col-relative"
                  >
                    <span className="item-container">
                      <span className="item">{column.render("Header")}</span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table__row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="table__col table-col-relative"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        className="modal-block"
        isOpen={isDeleteConfirmActive}
        ariaHideApp={false}
        onRequestClose={toggleDeleteConfirmActive}
      >
        <Confirm
          closeModal={toggleDeleteConfirmActive}
          disabled={userDeleting}
          handleOk={handleUserDelete}
          message={{
            header: "Confirm delete user.",
            body: "Are you sure you want to delete this user?",
          }}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isAddEditModalOpen}
        onRequestClose={closeAddEditModal}
      >
        <AddEditUser
          isEditing={isEditing}
          handleUserSave={handleUserSave}
          closeModal={closeAddEditModal}
          selectedRow={selectedRow}
          tenantId={tenantId}
        />
      </Modal>
    </div>
  );
};

export default UsersTable;
