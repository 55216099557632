import _ from "lodash";
import React from "react";
import Select from "react-select";

import { ICenter } from "commons/types/centers";
import CenterSchema from "commons/schemas/centers";
import usCities from "../../assets/data/usCities.json";
import { TextInput } from "commons/components/form-fields";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";

interface IForm {
  initialValues: ICenter;
  isEdit?: boolean;
  closeModal: () => void;
  saveCenter?: (center: ICenter) => void;
  updateCenter?: (id: string, center: ICenter) => void;
}

interface LabelValue {
  label: string;
  value: string;
}

const Form: React.FC<IForm> = ({
  initialValues,
  isEdit = false,
  closeModal,
  saveCenter,
  updateCenter,
}) => {
  const [cities, setCities] = React.useState<LabelValue[]>(
    initialValues.state !== ""
      ? usCities
          .filter((data) => data.state_name === initialValues.state)
          .map((city) => ({
            label: city.city,
            value: city.city,
          }))
      : [],
  );

  const states = _.uniqBy(
    usCities.map((city) => ({
      label: city.state_name,
      value: city.state_name,
    })),
    "value",
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CenterSchema,
    onSubmit: async (values) => {
      delete values._id;
      if (!isEdit && saveCenter) {
        await saveCenter(values);
      }
      if (updateCenter) {
        await updateCenter(initialValues._id || "", values);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body pb-0x">
          <div className="row">
            <div className="col-12">
              <TextInput
                label="Center Name"
                name="name"
                placeholder="Enter center name"
                touched={formik.touched}
                errors={formik.errors}
                values={formik.values}
                handleChange={formik.handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextInput
                label="Street Address"
                name="address"
                placeholder="Enter street address"
                touched={formik.touched}
                errors={formik.errors}
                values={formik.values}
                handleChange={formik.handleChange}
                required={true}
              />
            </div>
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label required">State</label>
                <Field
                  name="state"
                  component={({ field, form }: { field: any; form: any }) => {
                    return (
                      <>
                        <Select
                          placeholder="Please select state"
                          onChange={(option) => {
                            if (option) {
                              form.setFieldValue(field.name, option.value);

                              const data: LabelValue[] = usCities
                                .filter(
                                  (data) => data.state_name === option.value,
                                )
                                .map((city) => ({
                                  label: city.city,
                                  value: city.city,
                                }));

                              setCities(data);
                            }
                          }}
                          value={states?.find(
                            (option) => option.value === field.value,
                          )}
                          options={states}
                          classNamePrefix="react-select"
                        />
                        <label className="input__error">
                          <ErrorMessage name="state" />
                        </label>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label required">City</label>
                <Field
                  name="city"
                  component={({ field, form }: { field: any; form: any }) => (
                    <>
                      <Select
                        isDisabled={cities.length < 1}
                        placeholder="Please select city"
                        onChange={(option) => {
                          if (option)
                            form.setFieldValue(field.name, option.value);
                        }}
                        value={cities?.find(
                          (option) => option.value === field.value,
                        )}
                        options={cities}
                        classNamePrefix="react-select"
                      />
                      <label className="input__error">
                        <ErrorMessage name="city" />
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
            <div className="col-6">
              <TextInput
                label="Zip code"
                name="zipCode"
                placeholder="Enter zip code"
                touched={formik.touched}
                errors={formik.errors}
                values={formik.values}
                handleChange={formik.handleChange}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="modal-wrap__footer pt-0x">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {isEdit ? "Update" : "Save"}
              </button>
              <span className="btn txt-primary-color" onClick={closeModal}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Form;
