export enum FetchTwilioTokenActionType {
  FETCH_TWILIO_TOKEN_PENDING = "FETCH_TWILIO_TOKEN_PENDING",
  FETCH_TWILIO_TOKEN_REJECTED = "FETCH_TWILIO_TOKEN_REJECTED",
  FETCH_TWILIO_TOKEN_FULFILLED = "FETCH_TWILIO_TOKEN_FULFILLED",
}

export interface fetchTwilioTokenAction {
  type: FetchTwilioTokenActionType;
  data: {
    jwt: string;
    identity: string;
  };
}
