import "boxicons";
import * as React from "react";
import { connect } from "react-redux";

import ScheduleMessage from "./ScheduleMessage";
import ScheduledMessages from "./ScheduledMessages";
import Menu, { MenuType } from "commons/components/menu";
import MultiStepForm from "commons/components/multi-step-form";

import { IUser } from "commons/types/users";
import { IStepData, Steps, SubSteps } from "commons/types/scheduledMessages";
import { fetchBasicInfoByAuth0Id } from "services/users";

const STEPS = [
  {
    step: 1,
    component: (props: any) => <ScheduledMessages {...props} />,
  },
  {
    step: 2,
    component: (props: any) => <ScheduleMessage {...props} />,
  },
];

const Dropdown: React.FunctionComponent<{
  channelUniqueName: string;
  students: IUser[];
}> = ({ channelUniqueName, students }) => {
  const [stepData, setStepData] = React.useState<IStepData>({
    currentStep: Steps.ScheduledMessages,
    visible: false,
  });
  const [currentStudent, setCurrentStudent] = React.useState<
    IUser | undefined
  >();

  React.useEffect(() => {
    const studentAuth0Id = channelUniqueName.split(",").pop();
    let student = students.find(
      (student) => student.auth0UserId === studentAuth0Id,
    );

    if (!student) {
      const fetchAndSetCurrentStudent = async (auth0Id: string) => {
        student = await fetchBasicInfoByAuth0Id(auth0Id);
        setCurrentStudent(student);
      };
      fetchAndSetCurrentStudent(studentAuth0Id || "");
    } else {
      setCurrentStudent(student);
    }
  }, [channelUniqueName, students]);

  const setModalClose = () => {
    setStepData({
      currentStep: Steps.ScheduledMessages,
      visible: false,
    });
  };

  const editHandler = (id: string) => {
    setStepData({
      currentStep: Steps.ScheduleMessage,
      visible: true,
      selectedId: id,
    });
  };

  return (
    <React.Fragment>
      {stepData.visible ? (
        <MultiStepForm
          currentStep={stepData.currentStep}
          steps={STEPS}
          onClose={setModalClose}
          goToHandler={(to: Steps) =>
            setStepData({
              currentStep: to,
              visible: true,
            })
          }
          data={{
            studentId: currentStudent?._id,
            selectedId: stepData.selectedId,
            template: stepData.template,
            subStep: stepData.subStep,
          }}
          editHandler={editHandler}
        />
      ) : null}
      <Menu type={MenuType.Custom}>
        <li
          className="menu-list"
          onClick={() =>
            setStepData({
              currentStep: Steps.ScheduleMessage,
              visible: true,
              subStep: SubSteps.MessageForm,
            })
          }
        >
          <box-icon name="history" />
          <span className="ml-2x">Schedule a message</span>
        </li>
        <li
          className="menu-list"
          onClick={() =>
            setStepData({
              currentStep: Steps.ScheduleMessage,
              visible: true,
              subStep: SubSteps.Templates,
            })
          }
        >
          <box-icon name="chat" />
          <span className="ml-2x">New from template</span>
        </li>
        <li
          className="menu-list bordered__item"
          onClick={() =>
            setStepData({
              currentStep: Steps.ScheduledMessages,
              visible: true,
            })
          }
        >
          <box-icon name="history" />
          <span className="ml-2x">Scheduled messages</span>
        </li>
      </Menu>
    </React.Fragment>
  );
};

interface State {
  students: { fetchStudents: IUser[] };
}

const mapStateToProps = (state: State) => ({
  students: state.students.fetchStudents,
});

export default connect(mapStateToProps)(Dropdown);
