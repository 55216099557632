let context: any = null;
const AudioContext =
  window.AudioContext || // Default
  (window as any).webkitAudioContext || // Safari and old versions of Chrome
  null;

if (AudioContext) {
  context = new AudioContext();
} else {
  // Web Audio API is not supported
  alert(
    "Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox",
  );
}

export function playFile(filepath: string) {
  // Fetch the file
  fetch(filepath)
    // Read it into memory as an arrayBuffer
    .then((response) => response.arrayBuffer())
    // Turn it from mp3/aac/whatever into raw audio data
    .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      // Now we're ready to play!
      const soundSource = context.createBufferSource();
      soundSource.buffer = audioBuffer;
      soundSource.connect(context.destination);
      soundSource.start();
    });
}
