import { Dispatch } from "redux";

import * as goalsService from "services/uow";
import { error, success } from "utils/toast";
import { IGoal, IGoalParams } from "commons/types/goals";
import {
  AddGoalActionType,
  FetchGoalActionType,
  FetchGoalsActionType,
  UpdateGoalActionType,
} from "store/actions/types/goals";

export const addGoal = (payload: IGoal) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddGoalActionType.ADD_GOAL_PENDING,
  });
  try {
    const goal = await goalsService.add(payload);
    dispatch({
      type: AddGoalActionType.ADD_GOAL_FULFILLED,
      data: goal,
    });
    success("Saved goal");

    return goal;
  } catch (ex) {
    dispatch({
      type: AddGoalActionType.ADD_GOAL_REJECTED,
      data: ex,
    });

    error("Failed to save goal");
  }
};

export const updateGoal =
  (id: string, payload: IGoal) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateGoalActionType.UPDATE_GOAL_PENDING,
    });
    try {
      const { data: goal } = await goalsService.update(id, payload);
      dispatch({
        type: UpdateGoalActionType.UPDATE_GOAL_FULFILLED,
        data: goal,
      });
      success("Updated goal");

      return goal;
    } catch (ex) {
      dispatch({
        type: UpdateGoalActionType.UPDATE_GOAL_REJECTED,
        data: ex,
      });

      error("Failed to update goal");
    }
  };

export const fetchGoal = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchGoalActionType.FETCH_GOAL_PENDING,
  });
  try {
    const goal = await goalsService.fetch(id);
    dispatch({
      type: FetchGoalActionType.FETCH_GOAL_FULFILLED,
      data: goal,
    });

    return goal;
  } catch (ex) {
    dispatch({
      type: FetchGoalActionType.FETCH_GOAL_REJECTED,
      data: ex,
    });

    error("Failed to fetch goal");
  }
};

export const fetchGoals =
  (params: IGoalParams) => async (dispatch: Dispatch) => {
    if (!params.uowType) params.uowType = "Goal";
    dispatch({
      type: FetchGoalsActionType.FETCH_GOALS_PENDING,
    });
    try {
      const { data: goals } = await goalsService.fetchAll(params);
      dispatch({
        type: FetchGoalsActionType.FETCH_GOALS_FULFILLED,
        data: goals,
      });

      return goals;
    } catch (ex) {
      dispatch({
        type: FetchGoalsActionType.FETCH_GOALS_REJECTED,
        data: ex,
      });

      error("Failed to fetch goals");
    }
  };
