import React from "react";
import { error, success } from "utils/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { TextInput } from "commons/components/form-fields";
import { updateUserEmail } from "services/users";

const ChangeEmailAddressSchema: Yup.ObjectSchema<ObjectShape> =
  Yup.object().shape({
    newEmail: Yup.string().required("Please enter email address.").email(),
    confirmNewEmail: Yup.string()
      .required("Please enter email address.")
      .email(),
  });

interface IAddCourseProps {
  closeModal: () => void;
  email?: string;
  userId: string;
  fetchStudent: () => void;
}

const ChangeEmailAddress: React.FC<IAddCourseProps> = ({
  closeModal,
  email,
  userId,
  fetchStudent,
}) => {
  const formik: any = useFormik({
    initialValues: {
      email,
      newEmail: "",
      confirmNewEmail: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (values.newEmail !== values.confirmNewEmail) {
          error("Emails do not match");
          return;
        }
        await updateUserEmail(userId, values.newEmail);
        success("You have successfully changed your email address.");
        resetForm();
        closeModal();
        fetchStudent();
      } catch (err: any) {
        error("Failed to change email address");
      } finally {
      }
    },
    validationSchema: ChangeEmailAddressSchema,
  });

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Change Email Address</h3>
        <span className="link-item" onClick={() => closeModal()}>
          <box-icon name="x" />
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body scroll-modal-content pb-0x">
          <section className="section mb-0x">
            <div className="row">
              <div className="col-12">
                <TextInput
                  label={"Email address"}
                  name={"email"}
                  placeholder={"Email address"}
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label={"New email address"}
                  name={"newEmail"}
                  placeholder={"New email address"}
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  required={true}
                  type="email"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label={"Confirm email address"}
                  name={"confirmNewEmail"}
                  placeholder={"Confirm email address"}
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  type="email"
                  required={true}
                />
              </div>
            </div>
          </section>
        </div>
        <div className="modal-wrap__footer pt-0x">
          <section className="section">
            <div className="section__content mt-0x">
              <div className="row">
                <div className="col-12 d-flex">
                  <button
                    className="btn btn--primary mr-4x"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Change Email Address
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    </div>
  );
};

export default ChangeEmailAddress;
