import "boxicons";
import logo from "assets/images/boost_logo.svg";

const SurveyForm = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="progress-bar">
          <div className="container">
            <ul className="progress-bar__steps">
              <li className="done">Organizaition</li>
              <li className="active">Planning</li>
              <li>Completion</li>
              <li>Communication</li>
              <li>Mentality</li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="content-7x">
            <div className="content-panel">
              <div className="page-heading mt-4x">
                <div className="page-heading__left">
                  <div className="heading-detail">
                    <h2>Student Assessment Survey</h2>
                  </div>
                </div>
              </div>
              <section className="section">
                <div className="section__title">
                  <h3>Student Information</h3>
                </div>
                <div className="section__content">
                  <div className="row">
                    <div className="col-6">
                      <div className="input-wrap">
                        <label className="input__label required">
                          First Name
                        </label>
                        <input
                          type="text"
                          placeholder="Samantha"
                          className="input"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-wrap">
                        <label className="input__label required">
                          Last Name
                        </label>
                        <input
                          type="text"
                          placeholder="Doe"
                          className="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="section__title">
                  <h3>Organization</h3>
                </div>
                <div className="section__content">
                  <div className="card align-items-center">
                    <div className="card-content">
                      <div className="card-text">
                        <div className="card-head">
                          <h3>
                            1. It is easy to find (physical) school supplies
                            when I need them.
                          </h3>
                        </div>
                        <div className="radio-inline mb-0x mt-2x">
                          <div className="radio-options">
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio1"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio1" className="input__label">
                                Never
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio2"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio2" className="input__label">
                                Rarely
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio3"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio3" className="input__label">
                                Sometimes
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Usually
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Always
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card align-items-center">
                    <div className="card-content">
                      <div className="card-text">
                        <div className="card-head">
                          <h3>
                            1. It is easy to find (physical) school supplies
                            when I need them.
                          </h3>
                        </div>
                        <div className="radio-inline mb-0x mt-2x">
                          <div className="radio-options">
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio1"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio1" className="input__label">
                                Never
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio2"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio2" className="input__label">
                                Rarely
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio3"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio3" className="input__label">
                                Sometimes
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Usually
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Always
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card align-items-center">
                    <div className="card-content">
                      <div className="card-text">
                        <div className="card-head">
                          <h3>
                            1. It is easy to find (physical) school supplies
                            when I need them.
                          </h3>
                        </div>
                        <div className="radio-inline mb-0x mt-2x">
                          <div className="radio-options">
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio1"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio1" className="input__label">
                                Never
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio2"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio2" className="input__label">
                                Rarely
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio3"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio3" className="input__label">
                                Sometimes
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Usually
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Always
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card align-items-center">
                    <div className="card-content">
                      <div className="card-text">
                        <div className="card-head">
                          <h3>
                            1. It is easy to find (physical) school supplies
                            when I need them.
                          </h3>
                        </div>
                        <div className="radio-inline mb-0x mt-2x">
                          <div className="radio-options">
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio1"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio1" className="input__label">
                                Never
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio2"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio2" className="input__label">
                                Rarely
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio3"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio3" className="input__label">
                                Sometimes
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Usually
                              </label>
                            </div>
                            <div className="input-wrap">
                              <input
                                type="radio"
                                id="radio4"
                                name="hello"
                                className="box-links__nodes"
                              />
                              <label htmlFor="radio4" className="input__label">
                                Always
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="section__content">
                  <div className="row">
                    <div className="col-12 d-flex">
                      <button
                        className="btn btn--outlined-primary mr-4x"
                        type="button"
                      >
                        Next Step
                      </button>
                      <button className="btn btn--primary mr-4x" type="button">
                        Next Step
                      </button>
                      <button className="btn" type="button">
                        Clear Form
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
