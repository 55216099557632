import config from "configs";
import { Params } from "commons/types/urls";
import { http, stringify } from "utils/http";
import { ITenantUser } from "store/actions/types/multitenancy";

export const fetchInitialTenanatConfigs = async (params: Params = {}) => {
  const url = stringify(config.endpoints.multitenancy.getInitialConfig, {});

  const { data } = await http.get(url);

  return data;
};

export const getTenantProfileDetail = async (token: string) => {
  const { data } = await http.get(
    config.endpoints.multitenancy.getTenantProfile,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};

export const fetchFinalConfigs = async (params: Params = {}) => {
  const url = stringify(config.endpoints.multitenancy.getFinalConfig, {
    institute: "Leapfrog",
  });

  const { data } = await http.get(url);

  return data;
};

export const fetchTenantsList = async () => {
  const url = config.endpoints.multitenancy.getTenantsList;

  const { data } = await http.get(url);

  return data;
};

export const fetchTenantDetail = async (tenantId: string) => {
  const url = config.endpoints.multitenancy.getOneTenant.replace(
    ":tenantId",
    tenantId,
  );

  const { data } = await http.get(url);

  return data;
};

export const addTenant = async (payload: any) => {
  const url = config.endpoints.multitenancy.addTenant;

  const { data } = await http.post(url, payload);

  return data;
};

export const updateTenant = async (payload: any, tenantId: string) => {
  const url = config.endpoints.multitenancy.updateTenant.replace(
    ":tenantId",
    tenantId,
  );

  const { data } = await http.put(url, payload);

  return data;
};

export const deleteTenant = async (tenantId: string) => {
  const url = config.endpoints.multitenancy.deleteTenant.replace(
    ":tenantId",
    tenantId,
  );

  const { data } = await http.delete(url);

  return data;
};

export const fetchTenantUserList = async (tenantId: string) => {
  const url = config.endpoints.multitenancy.getTenantUserList.replace(
    ":tenantId",
    tenantId,
  );

  const { data } = await http.get(url);

  return data;
};

export const fetchUsers = async () => {
  const url = config.endpoints.multitenancy.user.getUsers;

  const { data } = await http.get(url);

  return data;
};

export const addUser = async (payload: ITenantUser) => {
  const url = config.endpoints.multitenancy.user.addUser;

  const { data } = await http.post(url, payload);

  return data;
};

export const updateUser = async (userId: string, payload: ITenantUser) => {
  const url = config.endpoints.multitenancy.user.editUser.replace(
    ":userId",
    userId,
  );

  const { data } = await http.patch(url, payload);

  return data;
};

export const deleteUser = async (userId: string) => {
  const url = config.endpoints.multitenancy.user.deleteUser.replace(
    ":userId",
    userId,
  );

  const { data } = await http.delete(url);

  return data;
};
