import { SurveyTypes } from "../../../commons/types/surveys";

export const selectBoxStyle = {
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    width: "92%",
    justifyContent: "space-between",
  }),
  control: (provided: any) => ({
    ...provided,
    maxHeight: "100px",
    overflowY: "auto",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "0px",
    margin: "0px",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: "0px",
    margin: "0px",
  }),
  input: (provided: any) => ({
    ...provided,
    width: "1px",
    display: "inline",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    display: "flex",
    padding: "0px 3px 0px 0px",
    justifyContent: "center",
  }),
};

export const surveyTypes = [
  { label: SurveyTypes.INITIAL_SURVEY, value: SurveyTypes.INITIAL },
  { label: SurveyTypes.FINAL_SURVEY, value: SurveyTypes.FINAL },
];
