import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import config from "configs";
import TermSchema from "commons/schemas/term";
import {
  DateInput,
  CustomSelect,
  TextInput,
} from "commons/components/form-fields";
import { ILocation } from "commons/types";
import { error, success } from "utils/toast";
import { fetchLocations } from "services/tenant";
import { checkEndDateBeforeStartDate } from "utils/dates";
import { addTerm, fetchAcademicYear } from "services/terms";
import { IAcademicYearResponse } from "commons/types/academicYear";
import { subdomainName } from "utils/http";

interface IOptions {
  value: string;
  label: string;
}

const Form: React.FC = () => {
  const [centers, setCenters] = useState<IOptions[]>([]);
  const [center, setCenter] = useState<any>(null);
  const [academicYears, setAcademicYears] = useState<IOptions[]>([]);
  const [academicYear, setAcademicYear] = useState<any>(null);
  const history = useHistory();

  const formik: any = useFormik({
    initialValues: {
      academicYear: "",
      termTitle: "",
      isCurrent: false,
      startDate: "",
      endDate: "",
      learningCenters: [],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await addTerm(values);
        success("Term added successfully");
        resetForm();
        setCenter(null);
        setAcademicYear(null);
        history.push(config.uiPath.terms.list);
      } catch (err: any) {
        error("Failed to add term");
      } finally {
      }
    },
    validationSchema: TermSchema,
  });

  const populateAcademicYear = React.useCallback(async () => {
    const { data } = await fetchAcademicYear();

    setAcademicYears(
      data.map((option: IAcademicYearResponse) => {
        return { label: option.name, value: option._id };
      }),
    );
  }, []);

  useEffect(() => {
    const setLearningCenters = async () => {
      const { data } = await fetchLocations();
      setCenters(
        data.map((option: ILocation) => {
          return { label: option.name, value: option._id };
        }),
      );
    };
    setLearningCenters();
    populateAcademicYear();
  }, [populateAcademicYear]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <section className="section mb-0x">
        <div className="row">
          <div className="col-12">
            <CustomSelect
              name="academicYear"
              label={"Academic Year"}
              options={academicYears}
              value={academicYear}
              handleChange={(value: any) => {
                formik.setFieldValue("academicYear", value.value);
                setAcademicYear(value);
              }}
              touched={formik.touched}
              errors={formik.errors}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextInput
              label={"Title of the Term"}
              name={"termTitle"}
              placeholder={"Enter Title"}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label required" htmlFor="startDate">
                Start Date
              </label>
              <DateInput
                id="startDate"
                name="startDate"
                isValid={!(formik.touched.startDate && formik.errors.startDate)}
                selected={formik.values.startDate}
                onChange={(date) => {
                  checkEndDateBeforeStartDate(date, formik.values.endDate) &&
                    formik.setFieldValue("endDate", date?.toString());
                  formik.setFieldValue("startDate", date?.toString());
                }}
                onBlur={formik.handleBlur}
              />
              {formik.errors.startDate && formik.touched.startDate ? (
                <p className="input__error">{formik.errors.startDate}</p>
              ) : null}
            </div>
          </div>

          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label required" htmlFor="endDate">
                End Date
              </label>
              <DateInput
                id="endDate"
                name="endDate"
                isValid={!(formik.touched.endDate && formik.errors.endDate)}
                selected={formik.values.endDate}
                onChange={(date) =>
                  formik.setFieldValue("endDate", date?.toString())
                }
                onBlur={formik.handleBlur}
                minDate={new Date(formik.values.startDate)}
              />
              {formik.errors.endDate && formik.touched.endDate ? (
                <p className="input__error">{formik.errors.endDate}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomSelect
              label={`${subdomainName} Learning Center`}
              name={"learningCenters"}
              options={centers}
              isMulti={true}
              value={center}
              handleChange={(value: IOptions, formikFieldValue: any) => {
                formik.setFieldValue("learningCenters", formikFieldValue);
                setCenter(value);
              }}
              touched={formik.touched}
              errors={formik.errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <input
                type="checkbox"
                id="check3"
                className="form-group__radio mr-10"
                name="isCurrent"
                checked={formik.values.isCurrent || false}
                onChange={(e) =>
                  formik.setFieldValue("isCurrent", e.target.checked)
                }
                onBlur={formik.handleBlur}
              />
              <label htmlFor="check3" className="box-links__nodes">
                Is current term
              </label>
              <label className="input__error">
                {formik.touched.isCurrent && formik.errors.isCurrent}
              </label>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="section__content mt-0x">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Add Term
              </button>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};
export default Form;
