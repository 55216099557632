import React from "react";
import Modal from "react-modal";

import EditAcademicYear from "../edit";
import DeleteConfirm from "commons/components/DeleteConfirm";
import { IAcademicYearInitValues } from "../edit/editAcademicYear.interface";

const AcademicYearActionCell = ({ row, onDeleteAcademicYear }: any) => {
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false);

  React.useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const getInitialValues: () => IAcademicYearInitValues = () => {
    return {
      _id: row.original._id,
      year: row.original.name,
    };
  };

  const handleDeleteAcademicYear = async () => {
    onDeleteAcademicYear(row.original._id);

    setIsDeleteConfirmationOpen(false);
  };

  return (
    <>
      <div className="action-group">
        <span
          className="link-item mr-2x"
          onClick={() => {
            setEditOpen(true);
          }}
        >
          <box-icon name="edit-alt" color="#959FAE" />
        </span>
        <span
          className="link-item mr-2x"
          onClick={() => {
            setIsDeleteConfirmationOpen(true);
          }}
        >
          <box-icon name="trash-alt" color="#959FAE" />
        </span>
      </div>
      <Modal isOpen={isEditOpen} className="modal-block">
        <EditAcademicYear
          initialValues={getInitialValues()}
          closeModal={() => setEditOpen(false)}
        />
      </Modal>
      <Modal isOpen={isDeleteConfirmationOpen} className="modal-block">
        <DeleteConfirm
          closeModal={() => setIsDeleteConfirmationOpen(false)}
          message={{
            header: "Do you want to delete this academic year?",
            body: "This action is not reversible",
          }}
          deleteResource={handleDeleteAcademicYear}
        />
      </Modal>
    </>
  );
};

export default AcademicYearActionCell;
