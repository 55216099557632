import {
  clearLocalDataStorage,
  getDataFromLocalStorage,
} from "./../../services/localStorage";
import { useAuth0 } from "@auth0/auth0-react";
import pusherInstance from "utils/pusher";

const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    const profile = getDataFromLocalStorage("profile") || {};

    clearLocalDataStorage();
    auth0Logout({ returnTo: window.location.origin });
    pusherInstance.unSubscribeToUserChannel(profile?._id || "");
  };

  return { logout };
};

export default useLogout;
