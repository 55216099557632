import "boxicons";
import React from "react";
import { useEffect } from "react";

import { error } from "utils/toast";
import eventBus from "utils/event-bus";
import { getFullName } from "utils/names";
import IProfile from "commons/types/profile";
import { getDataFromLocalStorage } from "services/localStorage";
import { createChatClient, getTwilioToken } from "services/chat";

const useChatHook = () => {
  const profileData: IProfile = getDataFromLocalStorage("profile");
  // token needed for initializing the twilio client sdk
  const [token, setToken] = React.useState(null);

  // twilio client-sdk initialize
  const [chatClient, setChatClient] = React.useState<any>(null);

  // "currentChannel" is the currently clicked mentor-student channel to display conversation at right section
  const [currentChannel, setCurrentChannel] = React.useState(null);

  // showMessages is true||false determining to show conversation of particular chat at right section when click to specific student by mentor
  const [showMessages, setShowMessages] = React.useState(false);

  useEffect(() => {
    try {
      const twilioTokenFromService = getTwilioToken();
      twilioTokenFromService
        .then(async (data) => {
          setToken(data.jwt);
          const chatClientData = await createChatClient(data.jwt);
          setChatClient(chatClientData);

          const updateClientToken = async () => {
            try {
              const token = await getTwilioToken();
              setToken(token.jwt);
              chatClientData.updateToken(token.jwt);
            } catch (err: any) {
              error("Failed to update twilio token");
            }
          };

          // Handle token about to expire
          chatClientData.on("tokenAboutToExpire", updateClientToken);

          // Handle Token Expired
          chatClientData.on("tokenExpired", updateClientToken);

          // Handling Twilio connection error
          chatClientData.on("connectionError", (data: any) => {
            console.info("Error connectting to Twilio.", data);
          });
        })
        .catch((err) => {
          console.error(err.name + ": " + err.message);
        });
    } catch (ex) {
      console.error("Failed to fetch twilio token", ex);
    }
    return () => {
      // chatClientData.off();
    };
  }, [currentChannel, profileData?._id]);

  /**
   * Invite user to the Channel
   * @param channel
   * @param studentIdentity
   * @param profileData
   * @returns void
   */
  const inviteUserToChannel = (
    channel: any,
    studentIdentity: string,
    profileData: IProfile,
  ) => {
    if (
      channel &&
      studentIdentity &&
      channel.createdBy === profileData.auth0UserId &&
      channel.members.size < 1
    ) {
      channel
        .invite(studentIdentity)
        .then(() => {
          console.log("Your student has been invited!=> ", studentIdentity);
        })
        .catch((err: any) => {
          console.info("Could not invite student: ", err.message, channel);
        });
    }
  };

  // STEP 1
  const showChatHistoryOfAChannel = async (
    studentId: string,
    studentData: any,
  ) => {
    const studentIdentity = `${studentId}`;

    if (chatClient) {
      const mentorStudentChannel =
        await getOrCreateOneToOneChannelOfMentorAndStudent(
          chatClient,
          profileData.auth0UserId,
          studentIdentity,
          studentData,
        );

      setCurrentChannel(mentorStudentChannel);
      eventBus.dispatch("chatChannelChanged", {
        channel: mentorStudentChannel,
        message: "Chat Channel changed",
      });

      // Show Messages
      setShowMessages(true);
    }
  };

  // STEP : 2
  // Tries to get specific channel of mentor&Student
  // if yes fetch and set channel
  // else creates new one-to-one channel
  const getOrCreateOneToOneChannelOfMentorAndStudent = async (
    chatClient: any,
    mentorIdentity: string,
    studentIdentity: string,
    studentData: any,
  ) => {
    let mentorStudentChannel: any;
    let mentorStudentChannelName = `one_to_one,${mentorIdentity},${studentIdentity}`;

    try {
      mentorStudentChannel = await chatClient.getChannelByUniqueName(
        mentorStudentChannelName,
      );
    } catch (err: any) {
      if (!mentorStudentChannel) {
        try {
          const channelFriendlyName =
            getFullName(profileData) + "," + getFullName(studentData);
          mentorStudentChannel = await chatClient.createChannel({
            uniqueName: mentorStudentChannelName,
            friendlyName: channelFriendlyName,
            isPrivate: true,
          });
        } catch (error: any) {
          console.error(
            "Could not create channel with the student.",
            error.message,
          );
        }
      }
    }

    if (
      mentorStudentChannel &&
      mentorStudentChannel.channelState.status !== "joined"
    ) {
      try {
        await mentorStudentChannel.join();
      } catch (ex) {
        console.warn("The user is already member of the chat");
      }
    }

    // Invite User to the Channel
    inviteUserToChannel(mentorStudentChannel, studentIdentity, profileData);

    return mentorStudentChannel;
  };

  // NEED FIX
  // same logic as step 2
  const getStudentMentorChannel = async (
    studentIdentity: string,
    mentorIdentity: string,
    studentData: any,
    mentorData: any,
  ) => {
    let mentorStudentChannel: any;
    let mentorStudentChannelName = `one_to_one,${mentorIdentity},${studentIdentity}`;

    try {
      mentorStudentChannel = await chatClient.getChannelByUniqueName(
        mentorStudentChannelName,
      );
    } catch (err: any) {
      if (!mentorStudentChannel) {
        try {
          const channelFriendlyName =
            getFullName(mentorData) + "," + getFullName(studentData);

          mentorStudentChannel = await chatClient.createChannel({
            uniqueName: mentorStudentChannelName,
            friendlyName: channelFriendlyName,
            isPrivate: true,
          });
        } catch (error: any) {
          console.error(
            "Could not create channel with the student.",
            error.message,
          );
        }
      }
    }

    if (
      mentorStudentChannel &&
      mentorStudentChannel.channelState.status !== "joined"
    ) {
      try {
        await mentorStudentChannel.join();
      } catch (ex) {
        console.warn("The user is already member of the chat");
      }
    }

    // Invite User to the Channel
    inviteUserToChannel(mentorStudentChannel, studentIdentity, studentData);

    return mentorStudentChannel;
  };

  return {
    token,
    setToken,
    chatClient,
    setChatClient,
    currentChannel,
    setCurrentChannel,
    showMessages,
    setShowMessages,
    showChatHistoryOfAChannel,
    getStudentMentorChannel,
  };
};

export default useChatHook;
