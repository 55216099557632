import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import config from "configs";
import { IProfile } from "commons/types/users";
import useLogout from "commons/hooks/useLogout";

interface IProfileLeftPanelProps {
  activeTab: string;
  currentUser: IProfile;
}
const ProfileLeftPanel: React.FC<IProfileLeftPanelProps> = ({
  activeTab,
  currentUser,
}) => {
  const history = useHistory();

  const { logout } = useLogout();

  const handleBackAction = () => {
    history.push(config.uiPath.mentor.dashboard);
  };

  return (
    <>
      <div className="left-panel">
        <ul className="nav-left">
          <li className="nav-left__item" onClick={handleBackAction}>
            <span>
              <box-icon name="arrow-back"></box-icon>
              Go Back
            </span>
          </li>
          <li className="nav-left__item--title">Account</li>
          <li
            className={classNames({
              "nav-left__item--active": activeTab === "profile",
              "nav-left__item": activeTab !== "profile",
            })}
          >
            <span>
              <box-icon name="user" />
              Edit Profile
            </span>
          </li>
          <li
            className="nav-left__item"
            onClick={() => {
              logout();
            }}
          >
            <span>
              <box-icon name="log-out" />
              Log Out
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfileLeftPanel;
