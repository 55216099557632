import config from "configs";
import { useEffect } from "react";
import { getNameInitials } from "utils/names";

import { RINGTONE } from "constants/communication";

import useSound from "use-sound";

interface IIncomingCallProps {
  room: string;
  caller: string;
  isAudio: boolean;
  avatar: string;
  answerHandler: () => void;
  cancelHandler: () => void;
  to: string;
  from: string;
}

const startCallWindow = (
  to: string,
  from: string,
  roomName: string,
  isAudio: boolean = false,
) => {
  window.open(
    `${config.uiPath.call.video}?caller=${from}&receiver=${to}&roomName=${roomName}&isAudio=${isAudio}`,
    "window",
    "toolbar=no, menubar=no, resizable=yes, fullscreen=yes",
  );
};

const IncomingCall: React.FC<IIncomingCallProps> = ({
  room,
  to,
  from,
  caller,
  avatar,
  isAudio,
  answerHandler,
  cancelHandler,
}) => {
  // TODO: audio file needs to be replaced
  const [play, { stop }] = useSound(RINGTONE, {
    loop: true,
  });

  useEffect(() => {
    play();

    return () => {
      stop();
    };
  }, [play, stop]);

  return (
    <>
      <div className="video-call__incoming-call-body">
        <div className="incoming-call-avatar">
          {avatar ? <img src={avatar} alt={caller} /> : getNameInitials(caller)}
        </div>
        <div className="info">
          <h2>{caller}</h2>
          <p>{`is${!isAudio ? " video" : ""} calling you`}</p>{" "}
        </div>
      </div>
      <div className="video-call__footer">
        <div className="button-group">
          <button
            onClick={() => cancelHandler()}
            className="btn btn__with-icon btn--red"
          >
            <box-icon name="x" animation="tada-hover"></box-icon>
          </button>
          <button
            onClick={() => {
              startCallWindow(to, from, room, isAudio);
              answerHandler();
            }}
            className="btn btn__with-icon btn--green"
          >
            <box-icon name="phone-call" animation="tada-hover"></box-icon>
          </button>
        </div>
      </div>
    </>
  );
};

export default IncomingCall;
