import { FC } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
} from "react-accessible-accordion";

import { getDateInFormat } from "utils/dates";
import {
  INote,
  SurveyResponse,
  SurveyResponseObject,
  SurveyResponseResult,
} from "commons/types/surveys";
import { getOptionById, getQuestionById } from "utils/window";

interface IProps {
  responseData: SurveyResponse;
}

const Notes: FC<IProps> = ({ responseData }) => {
  const results = responseData.result;

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="accordion"
      preExpanded={results.map(
        (result: SurveyResponseResult) => result.skillset._id,
      )}
    >
      {results.some((result: SurveyResponseResult) =>
        result.responses.some((response) =>
          response?.revisions?.some((revision) => revision.notes?.length),
        ),
      ) ||
      results.some((result: SurveyResponseResult) =>
        result.responses.some((response) => response.answer.notes?.length),
      ) ? (
        results.map((result: SurveyResponseResult) =>
          result.responses.some((response) =>
            response?.revisions?.some((revision) => revision.notes?.length),
          ) ||
          result.responses.some((response) => response.answer.notes?.length) ? (
            <AccordionItem
              uuid={result.skillset._id}
              className="accordion-item"
            >
              <AccordionItemState>
                {({ expanded }) => (
                  <>
                    <AccordionItemButton className="accordion-item__title pb-6px pl-4x pr-0x">
                      <div className="title-left ">
                        <h3 className="text-color-darkgray">
                          {result.skillset.name}
                        </h3>
                      </div>
                      <div className="title-right">
                        <div className="action d-flex align-items-center">
                          <span className="link-item">
                            <box-icon
                              name={!expanded ? "chevron-down" : "chevron-up"}
                            ></box-icon>
                          </span>
                        </div>
                      </div>
                    </AccordionItemButton>
                    <AccordionItemPanel className="accordion-item__content">
                      {result.responses.map(
                        (response: SurveyResponseObject) => {
                          const answers = [
                            ...(response.revisions?.sort(
                              (a, b) =>
                                new Date(a?.date as string).getTime() -
                                new Date(b?.date as string).getTime(),
                            ) || []),
                            {
                              answer: response.answer.answer,
                              value: response.answer.value,
                              notes: response.answer.notes,
                              question: response.question,
                            },
                          ];

                          const NoteDetails: FC<{
                            note: INote;
                            index: number;
                          }> = ({ note, index }) => {
                            return (
                              <div
                                key={note.createdAt + note.note}
                                className="card-notes__details pl-2x"
                              >
                                <ul>
                                  <li>
                                    <div className="card-notes__icon mr-2x">
                                      <box-icon name="calendar-check"></box-icon>
                                    </div>
                                    <div className="card-notes__content">
                                      <span className="text-lightgray">
                                        {getDateInFormat(
                                          note.createdAt,
                                          "ddd, MMM DD, YYYY",
                                        )}
                                        , Previous skill score:{" "}
                                        {index
                                          ? getOptionById(
                                              answers[index - 1].answer,
                                            )?.value
                                          : getOptionById(answers[0]?.answer)
                                              ?.value}
                                      </span>
                                      <span className="txt-black-color">
                                        {note.note}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            );
                          };

                          return response.revisions?.length ? (
                            <div
                              key={response.question}
                              className="card align-items-center card-notes"
                            >
                              <div className="card-content">
                                <div className="card-text card-text--no-margin">
                                  <div className="card-info">
                                    <h4>
                                      {getQuestionById(response.question)?.skill
                                        ?.name || ""}
                                    </h4>
                                    <span className="d-block text-lightgray">
                                      Updated Skill Score:{" "}
                                      {response.answer?.value} (
                                      {
                                        getOptionById(response.answer?.answer)
                                          ?.label
                                      }
                                      )
                                    </span>
                                  </div>
                                  {answers.map((revision, index) =>
                                    revision.notes?.map((note: INote) => (
                                      <NoteDetails note={note} index={index} />
                                    )),
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null;
                        },
                      )}
                    </AccordionItemPanel>
                  </>
                )}
              </AccordionItemState>
            </AccordionItem>
          ) : null,
        )
      ) : (
        <div className="accordion">
          <div className="accordion-item">
            <div className="card empty-card">
              <div className="card-content">
                <div className="tasks ml-2x">
                  <box-icon name="info-circle" type="solid" />
                </div>
                <div className="card-text">
                  <div className="title-left">
                    <h3 className="mb-2x">No notes in the survey response.</h3>
                  </div>
                  <p className="card-info">Add notes from responses.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Accordion>
  );
};

export default Notes;
