import { IGoal } from "commons/types/goals";

export enum AddGoalActionType {
  ADD_GOAL_PENDING = "ADD_GOAL_PENDING",
  ADD_GOAL_REJECTED = "ADD_GOAL_REJECTED",
  ADD_GOAL_FULFILLED = "ADD_GOAL_FULFILLED",
}

export interface AddGoalAction {
  type: AddGoalActionType;
  data: IGoal;
}

export enum FetchGoalsActionType {
  FETCH_GOALS_PENDING = "FETCH_GOALS_PENDING",
  FETCH_GOALS_REJECTED = "FETCH_GOALS_REJECTED",
  FETCH_GOALS_FULFILLED = "FETCH_GOALS_FULFILLED",
}

export interface FetchGoalsAction {
  type: FetchGoalsActionType;
  data: IGoal[];
}

export enum FetchGoalActionType {
  FETCH_GOAL_PENDING = "FETCH_GOAL_PENDING",
  FETCH_GOAL_REJECTED = "FETCH_GOAL_REJECTED",
  FETCH_GOAL_FULFILLED = "FETCH_GOAL_FULFILLED",
}

export interface fetchGoalAction {
  type: FetchGoalActionType;
  data: IGoal;
}

export enum UpdateGoalActionType {
  UPDATE_GOAL_PENDING = "UPDATE_GOAL_PENDING",
  UPDATE_GOAL_REJECTED = "UPDATE_GOAL_REJECTED",
  UPDATE_GOAL_FULFILLED = "UPDATE_GOAL_FULFILLED",
}

export interface updateGoalAction {
  type: UpdateGoalActionType;
  data: IGoal;
}
