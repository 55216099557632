import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { IStudentEnrollment, IUser } from "commons/types/users";
import AddCourse from "pages/courses/add/AddCourse";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { getFullName, getNameInitials } from "utils/names";
import {
  deleteCourse as deleteCourseAction,
  fetchCourses,
} from "store/actions/data/courses";
import UpdateCourse from "pages/courses/update/UpdateCourse";
import DeleteConfirm from "pages/courses/delete/DeleteConfirm";
import { ICourses, ICoursesParams } from "commons/types/courses";
import { IDistrict, ISchools } from "commons/types/institutions";
import {
  getCurrentEnrollment,
  getStudentEnrollments,
  getStudentFormValues,
} from "utils/user";
import { fetchStudent, updateStudent } from "store/actions/data/students";
import UpdateStudentAcademicInfo from "../update/UpdateStudentAcademicInfo";
import { fetchDistricts, fetchSchools } from "store/actions/data/institution";
import TermNotEnrolledError from "pages/layouts/mentor/TermNotEnrolledError";
import UpdateMentorSchedule from "../update/UpdateMentorSchedule";
import { IMentorshipSchedule } from "commons/types/mentorshipSchedule";
import { error, success } from "utils/toast";
import { changeMentorShipSchedule } from "services/users";
import EnrolledTermCard from "./EnrolledTermCard";
import { getDateInFormat } from "utils/dates";

interface IAcademicInfoProps {
  student: IUser;
  fetchStudent: (id: string) => Promise<IUser>;
  match: { params: { id: string } };
  updateStudent: (id: string, payload: IUser) => Promise<IUser>;
  districts: IDistrict[];
  schools: ISchools[];
  fetchDistricts: any;
  fetchSchools: any;
  courses: ICourses[];
  fetchStudentLoading: boolean;
  fetchCourses: (params: ICoursesParams) => any;
  deleteCourseAction: (id: string) => any;
}

const AcademicInfo: React.FC<IAcademicInfoProps> = ({
  student,
  fetchStudent,
  match: {
    params: { id },
  },
  updateStudent,
  districts,
  schools,
  fetchDistricts,
  fetchSchools,
  courses,
  fetchStudentLoading,
  fetchCourses,
  deleteCourseAction,
}) => {
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [isAddCourseOpen, setAddCourseOpen] = useState<boolean>(false);
  const [actionCourse, setActionCourse] = useState<ICourses>();
  const [isEditCourseOpen, setEditCourseOpen] = useState<boolean>(false);
  const [isDeleteCourseOpen, setDeleteCourseOpen] = useState<boolean>(false);
  const [isEditScheduleOpen, setEditScheduleOpen] = useState<boolean>(false);
  const [selectedTerm, setSelectedTerm] = useState<number>(0);
  const studentEnrollments = getStudentEnrollments(student);
  const {
    schoolDistrict,
    grade,
    institution,
    academicYear,
    academicTerm,
    accommodation,
    isOnAcademicProbation,
  }: any = student.userData?.schoolInfo || {};

  const schoolInfoData = [
    // TODO: change the find block when api provides ref of schoolDistrict and institution
    {
      label: "School District",
      value: schoolDistrict?.name,
    },
    {
      label: "School",
      value: institution?.name,
    },
    { label: "Grade", value: grade },
    { label: "School Year", value: academicYear },
    { label: "Term", value: academicTerm },
    { label: "Accommodation", value: accommodation?.name },
    { label: "School Probation", value: isOnAcademicProbation ? "Yes" : "No" },
  ];

  const updateStudentInformation = async (payload: IUser) => {
    await updateStudent(id, payload);
    fetchStudent(id);
  };

  useEffect(() => {
    if (!(student && student._id === id)) {
      fetchStudent(id);
    }
  }, [student, fetchStudent, id]);

  useEffect(() => {
    if (!districts.length) {
      fetchDistricts();
    }
  }, [districts, fetchDistricts]);

  useEffect(() => {
    if (!schools.length) {
      fetchSchools({ type: student?.userData?.schoolInfo?.schoolType });
    }
  }, [schools, fetchSchools, student]);

  useEffect(() => {
    fetchCourses({
      userId: student._id,
      termId: getCurrentEnrollment(student)?.enrolledTerm?._id,
    });
  }, [fetchCourses, student]);

  const deleteCourse = async () => {
    if (actionCourse) {
      await deleteCourseAction(actionCourse?._id);
      fetchCourses({
        userId: student._id,
        termId: getCurrentEnrollment(student)?.enrolledTerm?._id,
      });
      setDeleteCourseOpen(false);
    }
  };

  //Opens schedule edit window and sets key to use in UpdateMentorSchedule component.
  const handleEditMentorOpen = (key: number) => {
    setSelectedTerm(key);
    setEditScheduleOpen(true);
  };

  const NoEnrolledTermsView = () => {
    return (
      <div className="section__content">
        <div className="card card-addinfo">
          <div className="card-content">
            <div className="card-text">
              <span className="d-block">Not enrolled in any term.</span>
            </div>
          </div>
          <div className="card-actions"></div>
        </div>
      </div>
    );
  };

  /**
   ** This function sends patch request to edit terms schedule eg. Day of week and Planning horizon.
   * @param payload
   * @returns Success message if planning period of terms is updated successfully.
   
   */

  const handleEditMentorSchedule = async (payload: IMentorshipSchedule) => {
    try {
      payload.day = getDateInFormat(payload.day!, "YYYY-MM-DD");
      await changeMentorShipSchedule(id, payload);
      success("Mentorship schedule updated successfully");
      fetchStudent(id);
      setEditScheduleOpen(false);
    } catch (err: any) {
      error("Failed to update mentorship schedule");
    }
  };

  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);

  return (
    <>
      <LeftPanel student={student} activeTab="Academic Info" />
      <div className="content-panel">
        {isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : null}
        <div className="page-heading">
          <div className="page-heading__left">
            <div className="avatar avatar--sm">
              {student.avatar ? (
                <img src={student.avatar} alt="Student" />
              ) : (
                getNameInitials(student)
              )}
            </div>
            <div className="heading-detail">
              <span className="text-light-sm fs-14">
                {getFullName(student)}
              </span>
              <h2>Academic Info</h2>
            </div>
          </div>
        </div>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">School Information</h3>

            <div className="actions">
              <div className="link-item" onClick={() => setEditOpen(true)}>
                <box-icon name="edit-alt" />
              </div>
            </div>
          </div>
          <div className="section__content">
            {schoolInfoData.map((info, index) => (
              <div className="card card-addinfo" key={index}>
                <div className="card-content">
                  <div className="card-text">
                    <span className="card-address">{info.label}</span>
                    <span className="d-block info-value">
                      {info.value || "-"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Terms Enrolled</h3>
          </div>
          {studentEnrollments?.length === 0
            ? NoEnrolledTermsView()
            : studentEnrollments?.map(
                (enrollment: IStudentEnrollment, index: number) => {
                  return (
                    <EnrolledTermCard
                      handleEditMentorOpen={handleEditMentorOpen}
                      index={index}
                      enrollment={enrollment}
                    />
                  );
                },
              )}
        </section>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Courses</h3>
            <div className="actions">
              {courses?.length ? (
                <span
                  className="link-item"
                  onClick={() => setAddCourseOpen(true)}
                >
                  <box-icon name="plus-circle" type="solid" />
                </span>
              ) : null}
            </div>
          </div>
          <div className="section__content">
            {Array.isArray(courses) && courses.length ? (
              courses.map((course: ICourses, index) => (
                <div className="card card-addinfo" key={index}>
                  <div className="card-content">
                    <div className="card-text">
                      <div className="d-block info-value">{course.name}</div>
                      <div className="card-address">{course.teacher}</div>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionCourse(course);
                        setEditCourseOpen(true);
                      }}
                    >
                      <box-icon name="edit-alt" />
                    </span>
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionCourse(course);
                        setDeleteCourseOpen(true);
                      }}
                    >
                      <box-icon name="trash-alt" />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="add-new-text">
                        Please add Course for {getFullName(student)}
                      </span>
                      <span
                        className="add-new link-item"
                        onClick={() => setAddCourseOpen(true)}
                      >
                        <box-icon name="plus" /> Add Course
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
      <Modal
        isOpen={isEditOpen}
        onRequestClose={() => setEditOpen(false)}
        className="modal-block"
      >
        <UpdateStudentAcademicInfo
          closeModal={() => setEditOpen(false)}
          updateStudent={updateStudentInformation}
          student={getStudentFormValues(student)}
          schools={schools}
          districts={districts}
        />
      </Modal>
      <Modal
        isOpen={isAddCourseOpen}
        onRequestClose={() => setAddCourseOpen}
        className="modal-block"
      >
        <AddCourse
          student={student}
          closeModal={() => setAddCourseOpen(false)}
        />
      </Modal>
      {actionCourse ? (
        <Modal
          isOpen={isEditCourseOpen}
          onRequestClose={() => setEditCourseOpen(false)}
          className="modal-block"
        >
          <UpdateCourse
            student={student}
            closeModal={() => setEditCourseOpen(false)}
            course={actionCourse}
          />
        </Modal>
      ) : null}
      <Modal
        isOpen={isDeleteCourseOpen}
        onRequestClose={() => setDeleteCourseOpen(false)}
        className="modal-block"
      >
        <DeleteConfirm
          closeModal={() => setDeleteCourseOpen(false)}
          deleteCourse={deleteCourse}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isEditScheduleOpen}
        onRequestClose={() => setEditScheduleOpen(false)}
      >
        <UpdateMentorSchedule
          closeModal={() => setEditScheduleOpen(false)}
          student={student}
          editMentorshipSchedule={handleEditMentorSchedule}
          selectedTerm={selectedTerm}
        />
      </Modal>
    </>
  );
};

interface State {
  students: { fetchStudent: IUser; fetchStudentLoading: boolean };
  institutions: { fetchDistricts: IDistrict[]; fetchSchools: ISchools[] };
  courses: { fetchCourses: ICourses[] };
}

const mapStateToProps = (state: State) => ({
  student: state.students.fetchStudent,
  fetchStudentLoading: state.students.fetchStudentLoading,
  districts: state.institutions.fetchDistricts,
  schools: state.institutions.fetchSchools,
  courses: state.courses.fetchCourses,
});

const mapDispatchToProps = {
  fetchStudent,
  updateStudent,
  fetchDistricts,
  fetchSchools,
  fetchCourses,
  deleteCourseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcademicInfo);
