import React from "react";
import Modal from "react-modal";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import { getDateInFormat } from "utils/dates";
import { TERM_STATUS_OPTIONS } from "constants/term";

interface NameItemProps {
  label: string;
  studentInfo: IUser;
}

const NameItem: React.FC<NameItemProps> = ({ label, studentInfo }) => {
  const [isDetailOpen, setIsDetailOpen] = React.useState(false);

  const getMentorsName = () => {
    if (studentInfo.relatedUsers?.practitioners?.length) {
      const names = studentInfo.relatedUsers?.practitioners?.map((mentor) => {
        return ` ${getFullName(mentor.userId)}`;
      });

      return names.join();
    }
    return "-";
  };

  const getParentsName = () => {
    if (studentInfo.relatedUsers?.sponsors?.length) {
      const names = studentInfo.relatedUsers?.sponsors?.map((parent) => {
        return ` ${getFullName(parent.userId)}`;
      });

      return names.join();
    }
    return "-";
  };

  return (
    <>
      <span
        className="txt-bold link-primary-default"
        onClick={() => setIsDetailOpen(true)}
      >
        {label}
      </span>
      <Modal
        isOpen={isDetailOpen}
        className="modal-block"
        onRequestClose={() => setIsDetailOpen(false)}
      >
        <div className="modal-wrap modal-wrap--lg">
          <div className="modal-wrap__header">
            <h3>Student Information</h3>
            <span className="link-item" onClick={() => setIsDetailOpen(false)}>
              <box-icon name="x"></box-icon>
            </span>
          </div>
          <div className="section__content">
            <div className="px-2x">
              <div className="card card-addinfo">
                <div className="card-content">
                  <div className="card-text">
                    <span className="card-address">Student Name</span>
                    <span className="d-block">{getFullName(studentInfo)}</span>
                  </div>
                </div>
              </div>
              <div className="card card-addinfo">
                <div className="card-content">
                  <div className="card-text">
                    <span className="card-address">Mentor</span>
                    <span className="d-block">{getMentorsName()}</span>
                  </div>
                </div>
              </div>
              <div className="card card-addinfo">
                <div className="card-content">
                  <div className="card-text">
                    <span className="card-address">Parent Information</span>
                    <span className="d-block">{getParentsName()}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__title px-4x py-2x">
              <h3 className="txt-darkgrey-color">Term Information</h3>
            </div>
            <div className="modal-scrollable-list-view px-2x">
              {studentInfo.userData?.enrollments?.map((enrollment, index) => {
                if (enrollment.status !== "Draft") {
                  return (
                    <div
                      className="card card-addinfo border-bottom"
                      key={index}
                    >
                      <div className="card-content">
                        <div className="card-text">
                          <span className="d-block pb-1x">
                            {enrollment.enrolledTerm?.name || "-"}
                          </span>
                          <ul className="card-info">
                            <li className="duedate warning-icon">
                              {enrollment.status === "Dropped" ||
                              enrollment.status === "DropInProgress" ? (
                                <>
                                  <box-icon
                                    name="info-circle"
                                    color="#FF1154"
                                  />
                                  <strong color="#FF1154">
                                    {
                                      TERM_STATUS_OPTIONS.find(
                                        (val) =>
                                          val.value === enrollment.status,
                                      )?.label
                                    }
                                  </strong>
                                </>
                              ) : (
                                <>
                                  <box-icon
                                    name="info-circle"
                                    color="#959FAE"
                                  />
                                  {
                                    TERM_STATUS_OPTIONS.find(
                                      (val) => val.value === enrollment.status,
                                    )?.label
                                  }
                                </>
                              )}
                            </li>
                            <li className="duedate warning-icon">
                              <box-icon name="calendar" color="#959FAE" />
                              <span className="px-1x">Start Date :</span>
                              <strong>
                                {enrollment.enrollmentDate
                                  ? getDateInFormat(
                                      new Date(enrollment.enrollmentDate),
                                    )
                                  : "-"}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                }
                return <div key={index} />;
              })}
            </div>
            <div className="modal-wrap__footer">
              <div className="row">
                <div className="col-12 d-flex">
                  <button
                    className="btn btn--primary mr-4x"
                    type="button"
                    onClick={() => setIsDetailOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NameItem;
