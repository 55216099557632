import "boxicons";
import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";

const ChatScreen = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="link-item user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Dashboard
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="group"></box-icon>
                    Students
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="page-heading border-bottom">
                <h2>Matt's Dashboard</h2>
              </div>
              <div className="filters border-bottom">
                <ul className="filters__list">
                  <li>
                    <span className="link-item filter-item">
                      All Students
                      <box-icon name="chevron-down"></box-icon>
                    </span>
                  </li>
                  <li>
                    <span className="link-item filter-item">
                      This week
                      <box-icon name="chevron-down"></box-icon>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="accordion">
                <div className="accordion-item">
                  <div className="accordion-item__title">Overdue</div>
                  <div className="accordion-item__content">
                    <div className="card card--error">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Samantha Doe</span>{" "}
                            (Grade 7)
                          </span>
                          <h3>Research about the Romans</h3>
                          <ul className="card-info">
                            <li className="duedate">
                              <box-icon name="error-alt"></box-icon>Yesterday
                            </li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due{" "}
                              <strong> April 3, 2021</strong>
                            </li>
                            <li>
                              <box-icon
                                type="solid"
                                name="graduation"
                              ></box-icon>
                              Learn Roman history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>

                    <div className="card card--warning">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Samantha Doe</span>{" "}
                            (Grade 7)
                          </span>
                          <h3>Research about the Romans</h3>
                          <ul className="card-info">
                            <li className="duedate">
                              <box-icon name="error-alt"></box-icon>Yesterday
                            </li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due{" "}
                              <strong> April 3, 2021</strong>
                            </li>
                            <li>
                              <box-icon
                                type="solid"
                                name="graduation"
                              ></box-icon>
                              Learn Roman history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-item__title">Sunday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-item__title">Monday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-item__title">Tuesday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-item__title">Wednesday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-item__title">Thursday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-item__title">Friday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-item__title">Saturday</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-item__title">On the horizon</div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Samantha Doe</span>{" "}
                            (Grade 7)
                          </span>
                          <h3>Research about the Romans</h3>
                          <ul className="card-info">
                            <li className="duedate">
                              <box-icon name="error-alt"></box-icon>Yesterday
                            </li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due{" "}
                              <strong> April 3, 2021</strong>
                            </li>
                            <li>
                              <box-icon
                                type="solid"
                                name="graduation"
                              ></box-icon>
                              Learn Roman history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <span className="d-flex">
                    <span className="link-item">
                      <box-icon name="arrow-back"></box-icon>
                    </span>
                    <h3>Samantha Doe</h3>
                  </span>
                  <div className="message-actions">
                    <span className="action-links link">
                      <box-icon name="phone-call"></box-icon>
                    </span>
                    <span className="action-links link">
                      <box-icon name="video"></box-icon>
                    </span>
                    <span className="action-links">
                      <box-icon name="dots-horizontal-rounded"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content chat-box">
                  <div className="scroll">
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>
                            Hey Samantha, glad to beyou mentor. Please
                            don’thesitate to reach outwhenever you need help!
                          </p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Likewise. Thanks for your help. looking fwd.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>
                            Hey Samantha, glad to beyou mentor. Please
                            don’thesitate to reach outwhenever you need help!
                          </p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Likewise. Thanks for your help. looking fwd.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>
                            Hey Samantha, glad to beyou mentor. Please
                            don’thesitate to reach outwhenever you need help!
                          </p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Looking fwd.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Hey Samantha!</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Thanks.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Hey</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="message__box">
                  <div className="input-wrap">
                    <textarea className="input"></textarea>
                  </div>
                  <button type="submit">
                    <box-icon name="send"></box-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatScreen;
