import config from "configs";
import classNames from "classnames";
import pinterpolate from "pinterpolate";
import { useHistory } from "react-router-dom";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import { isStudentLoggedIn } from "utils/window";

interface LeftPanelProps {
  student: IUser;
  activeTab?: string;
}

const LeftPanel: React.FC<LeftPanelProps> = ({ student, activeTab }) => {
  const history = useHistory();

  const handleBackAction = () => {
    if (student._id) {
      if (activeTab === "AssessmentDetails") {
        const studentAssessmentsPath = pinterpolate(
          config.uiPath.students.assessment,
          { id: student._id },
        );

        history.replace(studentAssessmentsPath);

        return;
      }

      history.push(config.uiPath.mentor.students);
    }
  };

  return (
    <div className="left-panel">
      <ul className="nav-left">
        {!isStudentLoggedIn() && (
          <li onClick={handleBackAction} className="nav-left__item">
            <span>
              <box-icon name="arrow-back"></box-icon>
              Go Back
            </span>
          </li>
        )}
        <li
          className={`nav-left__item--title ${
            isStudentLoggedIn() && "tw-border-t-0"
          }`}
        >
          {getFullName(student)}
        </li>
        <li
          onClick={() => {
            if (student._id) {
              history.push(
                isStudentLoggedIn()
                  ? config.uiPath.students.studentPlans.replace(
                      ":id",
                      student?._id,
                    )
                  : config.uiPath.students.plans.replace(":id", student?._id),
              );
            }
          }}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Plans",
            "nav-left__item": activeTab !== "Plans",
          })}
        >
          <span>
            <box-icon name="calendar-check"></box-icon>
            Plans
          </span>
        </li>
        <li
          onClick={() => {
            if (student._id) {
              history.push(
                isStudentLoggedIn()
                  ? config.uiPath.students.studentGoals.replace(
                      ":id",
                      student?._id,
                    )
                  : config.uiPath.students.goals.replace(":id", student?._id),
              );
            }
          }}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Goals",
            "nav-left__item": activeTab !== "Goals",
          })}
        >
          <span>
            <box-icon name="bullseye"></box-icon>
            Goals
          </span>
        </li>
        {!isStudentLoggedIn() && (
          <>
            <li
              onClick={() => {
                if (student._id) {
                  history.push(
                    config.uiPath.students.assignments.replace(
                      ":id",
                      student?._id,
                    ),
                  );
                }
              }}
              className={classNames({
                "nav-left__item--active text-bold": activeTab === "Assignments",
                "nav-left__item": activeTab !== "Assignments",
              })}
            >
              <span>
                <box-icon name="book-open"></box-icon>
                Missing Assignments
              </span>
            </li>
            <li
              onClick={() => {
                if (student._id) {
                  history.push(
                    config.uiPath.students.grades.replace(":id", student?._id),
                  );
                }
              }}
              className={classNames({
                "nav-left__item--active text-bold": activeTab === "Grades",
                "nav-left__item": activeTab !== "Grades",
              })}
            >
              <span>
                <box-icon name="medal"></box-icon>
                Grades
              </span>
            </li>
          </>
        )}
        {/* <li
          className={
            activeTab === "Sessions"
              ? "nav-left__item--active"
              : "nav-left__item"
          }
        >
          <span>
            <box-icon name="calendar"></box-icon>
            Sessions
          </span>
        </li> */}
        <li className="nav-left__item--title tw-py-4">
          Additional Information
        </li>
        <li
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Personal Info",
            "nav-left__item": activeTab !== "Personal Info",
          })}
          onClick={() => {
            if (student._id) {
              history.push(
                isStudentLoggedIn()
                  ? config.uiPath.students.studentPersonalInfo.replace(
                      ":id",
                      student._id,
                    )
                  : config.uiPath.students.personalInfo.replace(
                      ":id",
                      student._id,
                    ),
              );
            }
          }}
        >
          <span>
            <box-icon name="user"></box-icon>
            {isStudentLoggedIn() ? "My Profile" : "Personal"}
          </span>
        </li>
        {isStudentLoggedIn() ? (
          <li
            className={classNames({
              "nav-left__item--active text-bold": activeTab === "My Mentor",
              "nav-left__item": activeTab !== "My Mentor",
            })}
            onClick={() => {
              if (student._id) {
                history.push(
                  config.uiPath.students.studentMyMentor.replace(
                    ":id",
                    student._id,
                  ),
                );
              }
            }}
          >
            <span>
              <box-icon name="user-voice"></box-icon>
              My Mentor
            </span>
          </li>
        ) : (
          <>
            <li
              className={classNames({
                "nav-left__item--active text-bold":
                  activeTab === "Academic Info",
                "nav-left__item": activeTab !== "Academic Info",
              })}
              onClick={() => {
                if (student._id) {
                  history.push(
                    config.uiPath.students.academicInfo.replace(
                      ":id",
                      student._id,
                    ),
                  );
                }
              }}
            >
              <span>
                <box-icon type="solid" name="graduation"></box-icon>
                Academic
              </span>
            </li>
            <li
              className={classNames({
                "nav-left__item--active text-bold":
                  activeTab === "Assessment" ||
                  activeTab === "AssessmentDetails",
                "nav-left__item": activeTab !== "Assessment",
              })}
              onClick={() => {
                if (student._id) {
                  history.push(
                    config.uiPath.students.assessment.replace(
                      ":id",
                      student._id,
                    ),
                  );
                }
              }}
            >
              <span>
                <box-icon name="spreadsheet" type="solid" />
                Assessment
              </span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default LeftPanel;
