import React from "react";
import { connect } from "react-redux";

import { IUser } from "commons/types/users";
import MarkCompletedForm from "./MarkCompletedForm";
import {
  updateAssignment,
  fetchAssignments,
} from "store/actions/data/assignments";
import {
  IAssignment,
  IAssignmentParams,
  IUpdateAssignment,
} from "commons/types/assignments";

interface IMarkCompletedProps {
  assignment: IAssignment;
  closeModal: () => void;
  student: IUser;
  updateAssignment: (id: string, payload: IUpdateAssignment) => any;
  fetchAssignments: (params: IAssignmentParams) => any;
}
const MarkCompleted: React.FC<IMarkCompletedProps> = ({
  assignment,
  closeModal,
  student,
  updateAssignment,
  fetchAssignments,
}) => {
  const updateAssignmentData = async (payload: IUpdateAssignment) => {
    await updateAssignment(assignment._id || "", payload);
  };
  const fetchAssignmentsData = async () => {
    await fetchAssignments({ subject: student._id });
  };
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Mark this assignment as complete?</h3>
        <span onClick={closeModal} className="link-item">
          <box-icon name="x" />
        </span>
      </div>
      <MarkCompletedForm
        assignment={assignment}
        closeModal={closeModal}
        fetchAssignments={fetchAssignmentsData}
        updateAssignment={updateAssignmentData}
      />
    </div>
  );
};
interface State {}
const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  updateAssignment,
  fetchAssignments,
};
export default connect(mapStateToProps, mapDispatchToProps)(MarkCompleted);
