import React from "react";
import { useFormik } from "formik";

import { IUser } from "commons/types/users";
import { CustomSelect } from "commons/components/form-fields";
import { IDistrict, ISchools } from "commons/types/institutions";

interface IUpdateAcademicInfoProps {
  student: IUser;
  closeModal: () => void;
  updateStudent: (payload: IUser) => void;
  districts: IDistrict[];
  schools: ISchools[];
}
const UpdateStudentAcademicInfo: React.FC<IUpdateAcademicInfoProps> = ({
  student,
  closeModal,
  updateStudent,
  districts,
  schools,
}) => {
  const formik = useFormik({
    initialValues: student,
    onSubmit: async (values) => {
      try {
        await updateStudent(values);
        closeModal();
      } catch (err: any) {}
    },
  });

  const districtOptions = districts.map((dist) => ({
    label: dist.name,
    value: dist._id,
  }));

  const schoolOptions = schools.map((school: ISchools) => ({
    label: school.name,
    value: school._id,
  }));

  return (
    <>
      <div className="modal-wrap modal-wrap--lg">
        <div className="modal-wrap__header">
          <h3>School Information</h3>
          <span className="link-item" onClick={closeModal}>
            <box-icon name="x" />
          </span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-wrap__body">
            <div className="row">
              <div className="col-12">
                <CustomSelect
                  label="School District"
                  options={districtOptions}
                  name="userData.schoolInfo.schoolDistrict"
                  errors={formik.errors}
                  value={
                    districtOptions.find(
                      (dist) =>
                        dist.value ===
                        formik.values.userData?.schoolInfo?.schoolDistrict,
                    ) || { label: "", value: "" }
                  }
                  touched={formik.touched}
                  handleChange={(value: any) =>
                    formik.setFieldValue(
                      "userData.schoolInfo.schoolDistrict",
                      value.value,
                    )
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <CustomSelect
                  label="School"
                  options={schoolOptions}
                  name="userData.schoolInfo.institution"
                  errors={formik.errors}
                  value={
                    schoolOptions.find(
                      (school) =>
                        school.value ===
                        formik.values.userData?.schoolInfo?.institution,
                    ) || { label: "", value: "" }
                  }
                  touched={formik.touched}
                  handleChange={(value: any) =>
                    formik.setFieldValue(
                      "userData.schoolInfo.institution",
                      value.value,
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-wrap__footer">
            <div className="row">
              <div className="col-12 d-flex">
                <button
                  className="btn btn--primary mr-4x"
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    formik.values === formik.initialValues
                  }
                >
                  Save
                </button>
                <button
                  disabled={formik.isSubmitting}
                  onClick={closeModal}
                  className="btn txt-primary-color"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateStudentAcademicInfo;
