import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router";

import config from "configs";

interface ISettingsPanel {
  activeTab?: string;
}

const SettingsPanel: React.FC<ISettingsPanel> = ({ activeTab }) => {
  const history = useHistory();

  return (
    <div className="left-panel">
      <ul className="nav-left">
        <li className="nav-left__item--no-border-title">Settings</li>
        <li
          onClick={() => history.push(config.uiPath.settings.center)}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Centers",
            "nav-left__item": activeTab !== "Centers",
          })}
        >
          <span>Center</span>
        </li>
        <li
          onClick={() => history.push(config.uiPath.settings.satelliteLocation)}
          className={classNames({
            "nav-left__item--active text-bold":
              activeTab === "Satellite Location",
            "nav-left__item": activeTab !== "Satellite Location",
          })}
        >
          <span>Satellite Location</span>
        </li>
        <li
          onClick={() => history.push(config.uiPath.settings.accommodation)}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Accommodation",
            "nav-left__item": activeTab !== "Accommodation",
          })}
        >
          <span>Accommodation</span>
        </li>
        <li
          onClick={() => history.push(config.uiPath.settings.academicYear)}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Academic Year",
            "nav-left__item": activeTab !== "Academic Year",
          })}
        >
          <span>Academic Year</span>
        </li>
        <li
          onClick={() => history.push(config.uiPath.settings.gradeType)}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Grade Type",
            "nav-left__item": activeTab !== "Grade Type",
          })}
        >
          <span>Grade Type</span>
        </li>
        <li
          onClick={() => history.push(config.uiPath.settings.schoolDistrict)}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "SchoolDistrict",
            "nav-left__item": activeTab !== "SchoolDistrict",
          })}
        >
          <span>School District</span>
        </li>
        <li
          onClick={() => history.push(config.uiPath.settings.school)}
          className={classNames({
            "nav-left__item--active text-bold": activeTab === "Schools",
            "nav-left__item": activeTab !== "Schools",
          })}
        >
          <span>Schools</span>
        </li>
      </ul>
    </div>
  );
};

export default SettingsPanel;
