import Modal from "react-modal";
import { connect } from "react-redux";

import { error } from "utils/toast";
import { answerCall, rejectCall } from "store/actions/data/communication";
import {
  CallInitiateEvent,
  CallType,
  ICall,
  INotifyCall,
} from "commons/types/call";

import { notifyCall } from "services/call";
import { getFullName } from "utils/names";

import IncomingCall from "./IncomingCall";

interface IIncomingCallModal {
  isIncomingCall: boolean;
  data: ICall;
  answerCall: () => void;
  rejectCall: () => void;
}

const IncomingCallModal = (props: IIncomingCallModal) => {
  const cancelHandler = async () => {
    const { room, to, from, type } = props.data;
    const call: INotifyCall = {
      to,
      from,
      room,
      event: CallInitiateEvent.CALL_ENDED,
      type: type === CallType.AUDIO ? CallType.AUDIO : CallType.VIDEO,
      triggeredBy: to,
    };

    props.rejectCall();

    try {
      await notifyCall(call);
    } catch (ex) {
      error("Failed to notify call rejection");
    }
  };

  const { caller, room, type, to, from } = props.data;

  const incomingData = {
    room,
    to,
    from,
    caller: getFullName(caller),
    avatar: caller?.avatar,
    isAudio: type === CallType.AUDIO,
    answerHandler: () => {
      props.answerCall();
    },
    cancelHandler,
  };

  return (
    <Modal
      className="modal-block video-block"
      isOpen={props.isIncomingCall}
      ariaHideApp={false}
    >
      <IncomingCall {...incomingData} />
    </Modal>
  );
};

interface State {
  communication: { isIncomingCall: boolean; data: any };
}

const mapStateToProps = (state: State) => ({
  isIncomingCall: state.communication.isIncomingCall,
  data: state.communication.data,
});

const mapDispatchToProps = {
  answerCall,
  rejectCall,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCallModal);
