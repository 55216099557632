import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

export const QuantitativeGradeTypeSchema: Yup.ObjectSchema<ObjectShape> =
  Yup.object().shape({
    data: Yup.object().shape({
      percentage: Yup.object().shape({
        minimum: Yup.string().required("Minimum is required"),
        maximum: Yup.string().required("Maximum is required"),
      }),
      gradeScale: Yup.object().shape({
        minimum: Yup.string().required("Minimum is required"),
        maximum: Yup.string().required("Maximum is required"),
      }),
    }),
  });

export const QualitativeGradeTypeSchema: Yup.ObjectSchema<ObjectShape> =
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Grade type is required")
      .max(TITLE_LENGTH, `Title must be less than ${TITLE_LENGTH} characters`),
    data: Yup.object().shape({
      range: Yup.object().shape({
        minimum: Yup.string().required("Minimum is required"),
        maximum: Yup.string().required("Maximum is required"),
      }),
      options: Yup.array(
        Yup.object().shape({
          lessThan: Yup.string(),
          greaterThanEqual: Yup.string(),
          key: Yup.string()
            .trim()
            .required("Name is required")
            .max(
              TITLE_LENGTH,
              `Name must be less than ${TITLE_LENGTH} characters`,
            ),
        }),
      ),
    }),
  });
