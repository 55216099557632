import * as Yup from "yup";
import { PHONE_REGEX } from "constants/regex";
import { ObjectShape } from "yup/lib/object";

import { NAME_LENGTH, PHONE_LENGTH } from "constants/index";

const mentorSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(NAME_LENGTH, `First name must be less than ${NAME_LENGTH} characters`)
    .required("Please enter the first name"),
  lastName: Yup.string()
    .trim()
    .max(NAME_LENGTH, `Last name must be less than ${NAME_LENGTH} characters`)
    .required("Please enter the last name"),

  phone: Yup.string()
    .matches(PHONE_REGEX, "Invalid number")
    .max(
      PHONE_LENGTH,
      `Phone number must be less than ${PHONE_LENGTH} characters`,
    )
    .required("Please enter the phone number"),

  email: Yup.string().email("Invalid email").required("Please enter the email"),
  newEmail: Yup.string()
    .email("Invalid email")
    .when("isEmailChangeActive", {
      is: false,
      then: Yup.string().email("Invalid email."),
      otherwise: Yup.string()
        .email("Invalid email.")
        .test(
          "testDuplicateEmail",
          "Email is same as current email",
          function (newEmail) {
            return newEmail?.toLowerCase() !== this.parent?.email.toLowerCase();
          },
        )
        .required("Required"),
    }),
  newEmailConfirm: Yup.string().when("isEmailChangeActive", {
    is: false,
    then: Yup.string().email("Invalid email."),
    otherwise: Yup.string()
      .email("Invalid email.")
      .oneOf([Yup.ref("newEmail"), null], "Emails don't match")
      .required("Required"),
  }),
  environment: Yup.array()
    .of(Yup.string())
    .min(1, "Please select the learning environment"),

  learningCenter: Yup.array().min(1, "Please select the learning center"),
  status: Yup.string().required("Please select the status"),
});

export default mentorSchema;
