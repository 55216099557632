import AddSponsorForm from "./SponsorForm";
import { UserType } from "constants/index";
import { ISponsor, IUser } from "commons/types/users";

interface Props {
  closeModal: () => void;
  existingSponsors?: ISponsor[];
  handleSubmit: (data: IUser) => void;
}

const AddSponsor: React.FC<Props> = ({
  closeModal,
  handleSubmit,
  existingSponsors,
}): JSX.Element => {
  const initialValues = {
    roles: [
      {
        _id: "",
        name: "",
      },
    ],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    relationshipWithSponsor: null,
    livingScheduleWithParents: null,
    userData: {
      type: UserType.Sponsor,
    },
    relatedUsers: {
      type: UserType.Sponsor,
      subjects: [],
    },
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add a Parent/Guardian</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <AddSponsorForm
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        existingSponsors={existingSponsors}
      />
    </div>
  );
};

export default AddSponsor;
