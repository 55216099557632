import Modal from "react-modal";
import classNames from "classnames";
import { connect } from "react-redux";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { getRole } from "utils/roles";
import { Role } from "constants/roles";
import AddSponsor from "pages/sponsors/add";
import { error, success } from "utils/toast";
import SearchSponsor from "pages/sponsors/search";
import UpdateSponsor from "pages/sponsors/update";
import { ISponsor, IUser } from "commons/types/users";
import { CustomErrorMessages } from "constants/errors";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { getFullName, getNameInitials } from "utils/names";
import DeleteConfirm from "commons/components/DeleteConfirm";
import { fetchUserProfile } from "store/actions/data/profile";
import { getDataFromLocalStorage } from "services/localStorage";
import LoginCredentialForm from "../update/LoginCredentialForm";

import UpdateStudentDiagnosis from "../update/UpdateStudentDiagnosis";
import { addSponsor, updateSponsor } from "store/actions/data/sponsors";
import {
  validateAvatar,
  getCurrentEnrollment,
  getStudentFormValues,
} from "utils/user";
import {
  addNote,
  updateNote,
  fetchNotes,
  deleteNote,
} from "store/actions/data/notes";
import { LEARNING_ENVIRONMENTS_OPTIONS } from "constants/index";
import { fetchStudent, updateStudent } from "store/actions/data/students";
import {
  addAvatar,
  removeAvatar,
  sendWelcomeEmail,
  sendPasswordReset,
} from "services/users";
import { getDateInFormat } from "utils/dates";
import UpdateStudentPersonalInfo from "../update/UpdateStudentPersonalInfo";
import TermNotEnrolledError from "pages/layouts/mentor/TermNotEnrolledError";
import { IStudentEnrollment } from "commons/types/users";
import { INote, INoteParams, IPagedNotes } from "commons/types/notes";
import NoteForm from "./NoteForm";
import Loader from "commons/components/Loader";
import { subdomainName } from "utils/http";

type PersonalInfoProps = {
  notesData: IPagedNotes;
  profile: IUser;
  student: IUser;
  match: { params: { id: string } };
  fetchStudent: (id: string) => Promise<IUser>;
  updateStudent: (
    id: string,
    payload: IUser,
    isDelete?: boolean,
  ) => Promise<IUser>;
  updateSponsor: any;
  addSponsor: any;
  fetchNotesLoading: boolean;
  fetchStudentLoading: boolean;
  addNote: (note: INote) => Promise<IPagedNotes>;
  deleteNote: (id: string) => any;
  fetchUserProfile: () => Promise<void>;
  updateNote: (id: string, note: INote) => any;
  fetchNotes: (params: INoteParams) => Promise<IPagedNotes>;
};

const TABS = {
  STUDENT_INFO: "STUDENT_INFO",
  NOTES: "NOTES",
};

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  match: {
    params: { id },
  },
  notesData,
  profile,
  student,
  addNote,
  updateNote,
  fetchNotes,
  deleteNote,
  addSponsor,
  fetchStudent,
  updateStudent,
  updateSponsor,
  fetchUserProfile,
  fetchNotesLoading,
  fetchStudentLoading,
}) => {
  const paginationLimit = 10;

  const roles = getDataFromLocalStorage("roles");
  const [activeTab, setActiveTab] = useState(TABS.STUDENT_INFO);
  const [isViewDiagnosis, setViewDiagnoses] = useState<boolean>(false);
  const [addNoteModelOpen, setAddNoteModelOpen] = useState<boolean>(false);
  const [selectSponsor, setSelectSponsor] = useState<ISponsor | null>(null);
  const [isEditPersonalInfoOpen, setEditPersonalInfoOpen] =
    useState<boolean>(false);
  const [editNoteModelOpen, setEditNoteModelOpen] = useState<INote | undefined>(
    undefined,
  );
  const [deleteNoteModelOpen, setDeleteNoteModelOpen] = useState<
    INote | undefined
  >(undefined);
  const [isEditDiagnosisOpen, setEditDiagnosisOpen] = useState<boolean>(false);
  const [isLoginCredsAddOpen, setLoginCredsAddOpen] = useState<boolean>(false);
  const [isEditLoginOpen, setEditLoginOpen] = useState<boolean>(false);
  const [actionLoginCreds, setActionLoginCreds] = useState<any>();
  const [isEditParentOpen, setEditParentOpen] = useState<boolean>(false);
  const [actionParent, setActionParent] = useState<any>();
  const [isAddSponsorOpen, setAddSponsorOpen] = useState<boolean>(false);
  const [isSearchSponsorOpen, setSearchSponsorOpen] = useState<boolean>(false);
  const [isDeleteCredentialOpen, setDeleteCredentialOpen] =
    useState<boolean>(false);
  const inputPictureRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isDeleteAvatarOpen, setDeleteAvatarOpen] = useState<boolean>(false);
  const [isEmailButtonDisabled, setEmailButtonDisabled] =
    useState<boolean>(false);
  const [avatarValidationError, setAvatarValidationError] =
    useState<string>("");
  const { academicYear, academicTerm }: any =
    student.userData?.schoolInfo || {};
  const { externalResources }: any = student.userData || {};

  const { page, data } = notesData;
  const notes = data;
  const notesPage = page;

  useEffect(() => {
    const enrolledTermCount =
      student.userData?.enrollments?.reduce(
        (result, enrollment: IStudentEnrollment) => {
          if (enrollment.enrolledTerm) {
            result++;
          }
          return result;
        },
        0,
      ) || 0;

    enrolledTermCount <= 0 && setEmailButtonDisabled(true);
  }, []);

  useEffect(() => {
    fetchUserProfile();
    if (student._id)
      fetchNotes({
        createdFor: student._id,
        offset: 1,
        limit: paginationLimit,
      });
  }, [student._id, fetchNotes, fetchUserProfile]);

  const loadPreviousPage = () => {
    if (notesPage?.currentPage > 1 && !fetchNotesLoading) {
      fetchNotes({
        createdFor: student._id,
        offset: notesPage?.currentPage - 1,
        limit: paginationLimit,
      });
    }
  };

  const loadNextPage = () => {
    if (notesPage?.currentPage < notesPage?.totalPage && !fetchNotesLoading) {
      fetchNotes({
        createdFor: student._id,
        offset: notesPage?.currentPage + 1,
        limit: paginationLimit,
      });
    }
  };

  const getPageInformation = () => {
    if (notesPage?.currentPage === 0) {
      return "0 - 0 of 0";
    }
    if (notesPage?.currentPage === 1) {
      return `1 - ${notesPage?.itemCountInPage} of ${notesPage?.totalItem}`;
    }
    if (notesPage?.currentPage === notesPage?.totalPage)
      return `${(notesPage?.currentPage - 1) * paginationLimit + 1} - ${
        notesPage?.totalItem
      } of ${notesPage?.totalItem}`;

    return `${(notesPage?.currentPage - 1) * paginationLimit + 1} - ${
      (notesPage?.currentPage - 1) * paginationLimit +
      notesPage?.itemCountInPage
    } of ${notesPage?.totalItem}`;
  };

  /**
   *
   * @param dataId :String
   * @param payload
   * Adds newly added sponsor in related users of student
   */
  const addSponsorInStudent = async (dataId: string, payload: any) => {
    if (dataId && student._id) {
      const studentFormData = getStudentFormValues(student);
      await updateStudent(student._id, {
        ...studentFormData,
        relatedUsers: {
          ...studentFormData?.relatedUsers,
          sponsors: Array.isArray(studentFormData?.relatedUsers?.sponsors)
            ? [
                ...studentFormData?.relatedUsers?.sponsors,
                {
                  userId: dataId,
                  relationshipWithSponsor: payload.relationshipWithSponsor,
                  livingScheduleWithParents: payload.livingScheduleWithParents,
                },
              ]
            : [],
        },
      });
      setAddSponsorOpen(false);
      fetchStudent(id);
    }
  };
  /**
   *
   * @param id
   * Removes unassigned mentor from related users of student
   */
  const removeSponsorFromStudent = async (id: string) => {
    if (student._id) {
      const studentFormData = getStudentFormValues(student);
      const updatedStudent = await updateStudent(
        student._id,
        {
          ...studentFormData,
          relatedUsers: {
            ...studentFormData.relatedUsers,
            sponsors: Array.isArray(studentFormData?.relatedUsers?.sponsors)
              ? [...studentFormData?.relatedUsers?.sponsors].filter(
                  (sponsor) => sponsor.userId !== id,
                )
              : [],
          },
        },
        true,
      );
      if (updatedStudent) {
        setSearchSponsorOpen(false);
        fetchStudent(student._id);
      }
    }
  };
  /**
   *
   * @param payload
   * Creates new user as sponsor and adds  sponsor info in relatedUsers.sponsors of student
   */
  const handleAddSponsorSubmit = async (payload: any) => {
    const sponsor = getRole(roles, Role.SPONSOR);
    if (!sponsor) {
      return error(CustomErrorMessages.ROLE_MISSING);
    }

    const guardian = { ...payload };
    const { _id, name } = sponsor;
    guardian.roles = [{ _id, name }];

    delete guardian.relationshipWithSponsor;
    delete guardian.livingScheduleWithParents;

    const { data } = await addSponsor(guardian);
    addSponsorInStudent(data._id, payload);
  };

  /**
   *
   * @param payload
   * Updates sponsor and subsequently update relatedusers.sponsors field of the student
   */
  const handleUpdateGuardianSubmit = async (payload: any) => {
    const guardian = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
    };
    const updatedSponsor = await updateSponsor(payload.id, guardian);
    if (updatedSponsor && student._id) {
      const studentFormData = getStudentFormValues(student);
      await updateStudent(student._id, {
        ...studentFormData,
        relatedUsers: {
          ...studentFormData.relatedUsers,
          sponsors: Array.isArray(studentFormData?.relatedUsers?.sponsors)
            ? [...studentFormData.relatedUsers?.sponsors]?.map(
                (sponsor: any) => {
                  if (sponsor.userId === payload.id) {
                    return {
                      userId: payload.id,
                      relationshipWithSponsor: payload.relationshipWithSponsor,
                      livingScheduleWithParents:
                        payload.livingScheduleWithParents,
                    };
                  }
                  return sponsor;
                },
              )
            : [],
        },
      });
    }

    setEditParentOpen(false);
    setSelectSponsor(null);
    fetchStudent(id);
  };
  useEffect(() => {
    fetchStudent(id);
  }, [id, fetchStudent]);

  const editInformation = async (payload: IUser) => {
    await updateStudent(id, payload);
    fetchStudent(id);
  };

  const deleteLoginCredential = async () => {
    const studentFormData = getStudentFormValues(student);
    const updatedStudent = await updateStudent(
      id,
      {
        ...studentFormData,
        userData: {
          ...studentFormData.userData,
          externalResources: Array.isArray(
            studentFormData.userData?.externalResources,
          )
            ? [...studentFormData.userData?.externalResources]?.filter(
                (resource) => resource !== actionLoginCreds,
              )
            : [],
        },
      },
      true,
    );
    setDeleteCredentialOpen(false);
    if (updatedStudent && student._id) {
      fetchStudent(student._id);
    }
  };

  const handlePictureChange = async (event: any) => {
    try {
      if (validateAvatar(event.target.files[0])) {
        setAvatarValidationError("");
        const updatedUser = await addAvatar(id, event.target.files[0]);
        inputPictureRef.current.value = "";
        success("Profile picture updated successfully");
        if (updatedUser) {
          fetchStudent(id);
        }
      } else {
        setAvatarValidationError("Please use supported file format and size");
      }
    } catch (err: any) {
      error("Failed to upload profile picture");
    }
  };

  const handleAvatarDelete = async () => {
    try {
      setAvatarValidationError("");
      const updatedUser = await removeAvatar(id);
      success("Profile picture removed successfully");
      if (updatedUser) {
        fetchStudent(id);
      }
      setDeleteAvatarOpen(false);
      inputPictureRef.current.value = "";
    } catch (err: any) {
      error("Failed to remove profile picture");
    }
  };

  const saveNoteHandler = async (newNote: INote) => {
    const addedNote = await addNote(newNote);

    if (addedNote) {
      fetchNotes({
        createdFor: student._id,
        limit: paginationLimit,
        offset: 1,
      });
      setAddNoteModelOpen(false);
    }
  };

  const deleteNoteHandler = async () => {
    if (deleteNoteModelOpen?._id) {
      const deletedNote = await deleteNote(deleteNoteModelOpen?._id);

      if (deletedNote) {
        fetchNotes({
          createdFor: student._id,
          limit: paginationLimit,
          offset: notesPage.currentPage,
        });
        setDeleteNoteModelOpen(undefined);
      }
    }
  };

  const updateNoteHandler = async (id: string, newNote: INote) => {
    const updatedNote = await updateNote(id, newNote);

    if (updatedNote) {
      fetchNotes({
        createdFor: student._id,
        limit: paginationLimit,
        offset: notesPage.currentPage,
      });
      setEditNoteModelOpen(undefined);
    }
  };

  const sendWelcomeEmailHandler = async (studentId: string) => {
    setEmailButtonDisabled(true);
    try {
      const user = await sendWelcomeEmail(studentId);

      if (user) {
        success("Welcome email sent");
        await fetchStudent(id);
      }
    } catch (err: any) {
      error("Failed to send welcome email");
    }
    setEmailButtonDisabled(false);
  };

  /**
   * @param studentId :String
   * Sends password reset email to student.
   */

  const sendResetEmailHandler = async (studentId: string) => {
    setEmailButtonDisabled(true);
    try {
      const user = await sendPasswordReset(studentId);

      if (user) {
        success("Password reset email sent.");
        await fetchStudent(id);
      }
    } catch (err: any) {
      error("Failed to send reset email");
    }
    setEmailButtonDisabled(false);
  };

  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);

  const NotesPageControl = () => {
    return (
      <div className="notes-page-control-bar">
        <h3 className="txt-darkgrey-color mb-3x">Mentor Notes</h3>
        <div className="notes-action">
          <div className="text-wrapper">
            <h4>{getPageInformation()}</h4>
          </div>
          <div className="action-group">
            <div
              className={classNames({
                "button-wrapper--active": notesPage?.currentPage > 1,
                "button-wrapper--disabled": notesPage?.currentPage <= 1,
              })}
            >
              <span className="mr-2x link-item">
                <box-icon name="chevron-left" onClick={loadPreviousPage} />
              </span>
            </div>
            <div
              className={classNames({
                "button-wrapper--active":
                  notesPage?.currentPage < notesPage?.totalPage,
                "button-wrapper--disabled":
                  notesPage?.currentPage >= notesPage?.totalPage,
              })}
            >
              <span className="mr-2x link-item">
                <box-icon name="chevron-right" onClick={loadNextPage} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Notes = () => {
    return (
      <>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Notes</h3>
            <div className="actions">
              <div
                className="link-item"
                onClick={() => setAddNoteModelOpen(true)}
              >
                <box-icon name="plus-circle" type="solid"></box-icon>
              </div>
            </div>
          </div>
          <div className="section__content">
            <h3 className="txt-darkgrey-color mb-3x">Registration Notes</h3>
            {student.userData?.enrollments &&
            student.userData?.enrollments[0].mentorshipNote ? (
              <div className="card card-addinfo">
                <div className="card-content">
                  <div className="card-text tw-space-y-2">
                    <p className="txt-bold">
                      {student.userData?.enrollments[0].mentorshipNote}
                    </p>
                    <ul className="card-info">
                      <li>
                        <box-icon name="calendar" />
                        Added on
                        <span className="txt-bold ml-1x">
                          {getDateInFormat(student.createdAt || "")}
                        </span>
                      </li>
                      <li className="ml-4x">
                        <box-icon name="user" />
                        Added by
                        <span className="txt-bold ml-1x">Intake Manager</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card card-addinfo">
                <div className="card-content">
                  <div className="card-text">
                    <span className="d-block">No registration notes</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="section__content">
            {!fetchNotesLoading && <NotesPageControl />}
            {fetchNotesLoading ? (
              <Loader type="ThreeDots" />
            ) : notes.length ? (
              notes.map((note: INote) => (
                <div key={note._id} className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text tw-space-y-1">
                      <p className="txt-bold">{note.notes}</p>
                      <ul className="card-info">
                        <li>
                          <box-icon name="calendar" />
                          Added on
                          <span className="txt-bold ml-1x">
                            {getDateInFormat(note.createdAt || "")}
                          </span>
                        </li>
                        <li className="ml-4x">
                          <box-icon name="user" />
                          Added by
                          <span className="txt-bold ml-1x">
                            {note.createdBy
                              ? getFullName(note.createdBy as IUser)
                              : "Mentor"}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span
                      onClick={() =>
                        (note.createdBy as IUser)._id === profile?._id &&
                        setEditNoteModelOpen(note)
                      }
                      className="link-item"
                    >
                      <box-icon
                        color={
                          (note.createdBy as IUser)._id === profile?._id
                            ? "#959FAE"
                            : "#E2E1DF"
                        }
                        name="edit-alt"
                      ></box-icon>
                    </span>
                    <span
                      onClick={() =>
                        (note.createdBy as IUser)._id === profile?._id &&
                        setDeleteNoteModelOpen(note)
                      }
                      className="link-item"
                    >
                      <box-icon
                        color={
                          (note.createdBy as IUser)._id === profile?._id
                            ? "#959FAE"
                            : "#E2E1DF"
                        }
                        name="trash-alt"
                      ></box-icon>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="card card-addinfo">
                <div className="card-content">
                  <div className="card-text">
                    <span className="d-block">No mentor notes</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  };

  const StudentInfo = () => {
    return (
      <>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Student Information</h3>
            <div className="actions">
              <div
                className="link-item"
                onClick={() => setEditPersonalInfoOpen(true)}
              >
                <box-icon name="edit-alt" />
              </div>
            </div>
          </div>
          <div className="section__content">
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Student name</span>
                  <span className="d-block">{getFullName(student)}</span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Nickname</span>
                  <span className="d-block">
                    {student.userData?.nickName || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Email address</span>
                  <span className="d-block">{student.email}</span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Grade</span>
                  <span className="d-block">
                    {student?.userData?.schoolInfo?.grade
                      ? `Grade ${student?.userData?.schoolInfo?.grade}`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Academic year</span>
                  <span className="d-block">
                    {academicYear || " "}{" "}
                    {academicTerm ? `(${academicTerm})` : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">{subdomainName} term</span>
                  <span className="d-block">
                    {getCurrentEnrollment(student)?.enrolledTerm?.name || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Learning environment</span>
                  <span className="d-block">
                    {student.userData?.learningEnvironment
                      ? LEARNING_ENVIRONMENTS_OPTIONS.find(
                          (environment: any) =>
                            environment.value ===
                            student.userData?.learningEnvironment,
                        )?.label || "-"
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">{subdomainName} location</span>
                  <span className="d-block">
                    {getCurrentEnrollment(student)?.learningCenter?.name || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Parent Information</h3>
            <div className="actions">
              <span
                className="link-item mr-4x"
                onClick={() => setSearchSponsorOpen(true)}
              >
                <box-icon name="search"></box-icon>
              </span>
              <span
                className="link-item"
                onClick={() => setAddSponsorOpen(true)}
              >
                <box-icon name="plus-circle" type="solid"></box-icon>
              </span>
            </div>
          </div>
          <div className="section__content">
            {student?.relatedUsers?.sponsors?.length ? (
              student?.relatedUsers?.sponsors?.map((sponsor, index) => (
                <div className="card card-addinfo" key={index}>
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        {sponsor.userId && getFullName(sponsor.userId)}
                        {` (${sponsor.relationshipWithSponsor || "-"})`}
                      </span>
                      <span className="card-address">
                        {sponsor.userId?.phoneNumber} ({sponsor.userId?.email})
                      </span>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionParent(sponsor);
                        setEditParentOpen(true);
                      }}
                    >
                      <box-icon name="edit-alt"></box-icon>
                    </span>
                    <span
                      className="link-item"
                      onClick={() => {
                        removeSponsorFromStudent(sponsor.userId._id);
                      }}
                    >
                      <box-icon name="trash-alt"></box-icon>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        Please add parent information
                      </span>
                      <span
                        className="add-new link-item"
                        onClick={() => setAddSponsorOpen(true)}
                      >
                        <box-icon name="plus" /> Add Parent
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Login Credentials</h3>
            <div className="actions">
              <span
                className="link-item"
                onClick={() => setLoginCredsAddOpen(true)}
              >
                <box-icon name="plus-circle" type="solid"></box-icon>
              </span>
            </div>
          </div>

          <div className="section__content">
            {externalResources?.length ? (
              Array.isArray(externalResources) &&
              externalResources.map((resource) => (
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">{resource.name}</span>
                      <span className="card-address">
                        <a href={resource.url} target="_blank" rel="noreferrer">
                          {resource.url}
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionLoginCreds(resource);
                        setEditLoginOpen(true);
                      }}
                    >
                      <box-icon name="show-alt"></box-icon>
                    </span>
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionLoginCreds(resource);
                        setDeleteCredentialOpen(true);
                      }}
                    >
                      <box-icon name="trash-alt"></box-icon>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        Please add login credentials
                      </span>
                      <span
                        className="add-new link-item"
                        onClick={() => setLoginCredsAddOpen(true)}
                      >
                        <box-icon name="plus" /> Add Credential
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <div className="ml-10x">
          <span
            className="viewmore"
            onClick={() => setViewDiagnoses(!isViewDiagnosis)}
          >
            {isViewDiagnosis ? (
              <>
                <box-icon name="chevron-up" /> Hide diagnosis{" "}
              </>
            ) : (
              <>
                <box-icon name="chevron-down" />
                View diagnosis
              </>
            )}
          </span>
          {isViewDiagnosis && (
            <section className="section mt-8x">
              <div className="section-title mb-0x">
                <h3 className="txt-darkgrey-color">Shared Diagnosis</h3>
              </div>
              <div className="mt-3x mb-2x">
                Diagnosis, behavioral issues, or other concerns
              </div>
              <div className="section-content">
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        {student.userData?.diagnosis}
                      </span>
                    </div>
                  </div>
                  <div className="card-actions align-self-top">
                    <span
                      className="link-item"
                      onClick={() => setEditDiagnosisOpen(true)}
                    >
                      <box-icon name="edit-alt" />
                    </span>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  };

  /**
   * @returns Boolean
   * Checks if auth0id and twilioUserId has been created for user which is created after
   *  welcome email is sent.
   */
  const hasWelcomeEmailBeenSent = (() => {
    if (!student.auth0UserId && !student.twilioUserSid) {
      return false;
    }
    return true;
  })();

  return (
    <>
      <LeftPanel activeTab="Personal Info" student={student} />
      <div className="content-panel">
        {isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : null}
        <div className="page-heading">
          <div className="page-heading__left">
            <div className="avatar">
              {student.avatar ? (
                <img src={student.avatar} alt="Student" />
              ) : (
                getNameInitials(student)
              )}
            </div>
            <div className="heading-detail">
              <span className="text-light-sm fs-14">
                {getFullName(student)}
              </span>
              <h2>Personal Info</h2>
            </div>
          </div>
        </div>
        <section className="section ml-10x">
          {!hasWelcomeEmailBeenSent ? (
            <div className="alert alert__warning">
              <span className="status link-item">
                <box-icon name="error-alt"></box-icon>
              </span>
              <div className="alert__content">
                <strong>Welcome email has not been sent</strong>
                Chat is not activated for {getFullName(student)}
              </div>
            </div>
          ) : null}
          <div className="section__content">
            <div className="card card-profile">
              <div className="card-content">
                <div className="avatar avatar--lg mr-2x mt-0x">
                  {student.avatar ? (
                    <img src={student.avatar} alt="Student" />
                  ) : (
                    getNameInitials(student)
                  )}
                </div>
                <div className="card-text">
                  <h3>Profile Picture</h3>
                  <div className="d-flex link-text fs-small">
                    <input
                      type="file"
                      ref={inputPictureRef}
                      onChange={handlePictureChange}
                      className={"input-avatar"}
                    />
                    <span
                      onClick={() => {
                        inputPictureRef && inputPictureRef.current.click();
                      }}
                    >
                      Upload new photo
                    </span>
                    <span
                      className="status status--error ml-2x"
                      onClick={() => setDeleteAvatarOpen(true)}
                    >
                      {student?.avatar ? "Remove" : null}
                    </span>
                  </div>
                  <div className="d-flex mt-2x">
                    <span className="text-light-sm">
                      File size should be limited to 10MB.
                    </span>
                  </div>
                  <div className="d-flex mb-0x">
                    <span className="text-light-sm">
                      The supported file formats are jpg, jpeg, png.
                    </span>
                  </div>

                  <div className="d-flex">
                    {avatarValidationError.length ? (
                      <span className="input__error avatar-error">
                        <box-icon name="error-circle" size="xs" /> Please use
                        the supported file format and size
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {!hasWelcomeEmailBeenSent ? (
                <div className="pull-right">
                  <button
                    onClick={() =>
                      student._id && sendWelcomeEmailHandler(student._id)
                    }
                    disabled={isEmailButtonDisabled}
                    className="btn--primary btn"
                  >
                    Send Welcome Email
                  </button>
                </div>
              ) : (
                <div className="pull-right">
                  <button
                    onClick={() =>
                      student._id && sendResetEmailHandler(student._id)
                    }
                    disabled={isEmailButtonDisabled}
                    className="btn--primary btn"
                  >
                    Send Password Reset
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="section ml-10x tab-wrapper mb-4x">
          <ul className="tab">
            <li
              className={classNames({
                "tab__item full tab__item--active":
                  activeTab === TABS.STUDENT_INFO,
                "tab__item full": activeTab !== TABS.STUDENT_INFO,
              })}
              onClick={() => setActiveTab(TABS.STUDENT_INFO)}
            >
              Student Info
            </li>
            <li
              className={classNames({
                "tab__item full tab__item--active": activeTab === TABS.NOTES,
                "tab__item full": activeTab !== TABS.NOTES,
              })}
              onClick={() => setActiveTab(TABS.NOTES)}
            >
              Notes
            </li>
          </ul>
        </section>

        {activeTab === TABS.STUDENT_INFO ? <StudentInfo /> : <Notes />}
      </div>

      <Modal
        className="modal-block-form"
        isOpen={isEditPersonalInfoOpen}
        onRequestClose={() => setEditPersonalInfoOpen(false)}
      >
        <UpdateStudentPersonalInfo
          student={getStudentFormValues(student)}
          closeModal={() => setEditPersonalInfoOpen(false)}
          editInformation={editInformation}
          hasWelcomeEmailBeenSent={hasWelcomeEmailBeenSent}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isEditDiagnosisOpen}
        onRequestClose={() => setEditDiagnosisOpen(false)}
      >
        <UpdateStudentDiagnosis
          student={getStudentFormValues(student)}
          closeModal={() => setEditDiagnosisOpen(false)}
          editInformation={editInformation}
        />
      </Modal>
      <Modal className="modal-block" isOpen={isLoginCredsAddOpen}>
        <LoginCredentialForm
          closeModal={() => {
            setLoginCredsAddOpen(false);
          }}
          student={getStudentFormValues(student)}
          isEdit={false}
          updateUser={editInformation}
        />
      </Modal>
      <Modal className="modal-block" isOpen={isEditLoginOpen}>
        <LoginCredentialForm
          closeModal={() => {
            setEditLoginOpen(false);
          }}
          student={getStudentFormValues(student)}
          isEdit={true}
          initialValues={actionLoginCreds}
          updateUser={editInformation}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isEditParentOpen}
        onRequestClose={() => setEditParentOpen(false)}
      >
        <UpdateSponsor
          sponsor={actionParent}
          handleSubmit={handleUpdateGuardianSubmit}
          closeModal={() => setEditParentOpen(false)}
          existingSponsors={student.relatedUsers?.sponsors || []}
        />
      </Modal>
      <Modal
        isOpen={!!selectSponsor}
        className="modal-block"
        ariaHideApp={false}
      >
        {!!selectSponsor && (
          <UpdateSponsor
            sponsor={selectSponsor}
            handleSubmit={handleUpdateGuardianSubmit}
            existingSponsors={student.relatedUsers?.sponsors || []}
            closeModal={() => {
              selectSponsor.userId._id &&
                removeSponsorFromStudent(selectSponsor.userId._id);
              setSelectSponsor(null);
            }}
          />
        )}
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isAddSponsorOpen}
        onRequestClose={() => setAddSponsorOpen(false)}
      >
        <AddSponsor
          existingSponsors={student?.relatedUsers?.sponsors}
          handleSubmit={handleAddSponsorSubmit}
          closeModal={() => setAddSponsorOpen(false)}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isSearchSponsorOpen}
        onRequestClose={() => setSearchSponsorOpen(false)}
      >
        <SearchSponsor
          selectedSponsors={student.relatedUsers?.sponsors || []}
          handleSelectSponsor={(sponsor: any) => {
            addSponsorInStudent(sponsor._id, sponsor);
            setSelectSponsor({
              userId: sponsor,
              relationshipWithSponsor: "",
              livingScheduleWithParents: "",
            });
            setSearchSponsorOpen(false);
          }}
          handleUnselectSponsor={(sponsor: any) =>
            removeSponsorFromStudent(sponsor._id)
          }
          closeModal={() => setSearchSponsorOpen(false)}
          addSponsor={() => setAddSponsorOpen(true)}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={!!deleteNoteModelOpen}
        onRequestClose={() => setDeleteNoteModelOpen(undefined)}
      >
        <DeleteConfirm
          closeModal={() => setDeleteNoteModelOpen(undefined)}
          deleteResource={deleteNoteHandler}
          message={{
            header: "Do you want to delete this note?",
            body: "This action is not reversible",
          }}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isDeleteCredentialOpen}
        onRequestClose={() => setDeleteCredentialOpen(false)}
      >
        <DeleteConfirm
          closeModal={() => setDeleteCredentialOpen(false)}
          deleteResource={deleteLoginCredential}
          message={{
            header: "Do you want to delete this login credential?",
            body: "This action is not reversible",
          }}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isDeleteAvatarOpen}
        onRequestClose={() => setDeleteAvatarOpen(false)}
      >
        <DeleteConfirm
          closeModal={() => setDeleteAvatarOpen(false)}
          deleteResource={handleAvatarDelete}
          message={{
            header: "Do you want to delete this profile picture?",
            body: "This action is not reversible",
          }}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={addNoteModelOpen}
        onRequestClose={() => setAddNoteModelOpen(false)}
      >
        <div className="modal-wrap modal-wrap--lg">
          <div className="modal-wrap__header">
            <h3>Add a Note</h3>
            <span
              className="link-item"
              onClick={() => setAddNoteModelOpen(false)}
            >
              <box-icon name="x" />
            </span>
          </div>
          {student._id ? (
            <NoteForm
              initialValues={{ notes: "", createdFor: student }}
              isEdit={false}
              updateNote={updateNoteHandler}
              closeModal={() => setAddNoteModelOpen(false)}
              saveNote={saveNoteHandler}
            />
          ) : (
            <Loader type="ThreeDots" />
          )}
        </div>
      </Modal>
      <Modal
        className="modal-block"
        isOpen={!!editNoteModelOpen}
        onRequestClose={() => setEditNoteModelOpen(undefined)}
      >
        <div className="modal-wrap modal-wrap--lg">
          <div className="modal-wrap__header">
            <h3>Edit a Note</h3>
            <span
              className="link-item"
              onClick={() => setEditNoteModelOpen(undefined)}
            >
              <box-icon name="x" />
            </span>
          </div>
          {editNoteModelOpen ? (
            <NoteForm
              initialValues={editNoteModelOpen}
              isEdit={true}
              updateNote={updateNoteHandler}
              closeModal={() => setEditNoteModelOpen(undefined)}
              saveNote={saveNoteHandler}
            />
          ) : (
            <Loader type="ThreeDots" />
          )}
        </div>
      </Modal>
    </>
  );
};

interface State {
  profile: { fetchProfile: IUser };
  students: { fetchStudent: IUser; fetchStudentLoading: boolean };
  notes: { fetchNotes: IPagedNotes; fetchNotesLoading: boolean };
}

const mapStateToProps = (state: State) => ({
  notesData: state.notes.fetchNotes,
  profile: state.profile.fetchProfile,
  student: state.students.fetchStudent,
  fetchNotesLoading: state.notes.fetchNotesLoading,
  fetchStudentLoading: state.students.fetchStudentLoading,
});

const mapDispatchToProps = {
  addNote,
  addSponsor,
  updateNote,
  fetchNotes,
  deleteNote,
  fetchStudent,
  updateStudent,
  updateSponsor,
  fetchUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
