import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import { IUser } from "commons/types/users";
import {
  AddStudentActionType,
  FetchPagedStudentsActionType,
  FetchStudentActionType,
  FetchStudentsActionType,
  UpdateStudentActionType,
  StudentFilterActionType,
} from "store/actions/types/students";
import { Params } from "commons/types/urls";
import * as studentsService from "services/students";
import { DUPLICATE_USER_STATUS_CODE } from "constants/index";

export const addStudent = (payload: IUser) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddStudentActionType.ADD_STUDENT_PENDING,
  });

  try {
    const student = await studentsService.add(payload);
    dispatch({
      type: AddStudentActionType.ADD_STUDENT_FULFILLED,
      data: student,
    });
    dispatch({
      type: StudentFilterActionType.RESET_PAGINATION,
    });
    success("Saved student");

    return student;
  } catch (ex: any) {
    dispatch({
      type: AddStudentActionType.ADD_STUDENT_REJECTED,
      data: ex,
    });

    if (ex.response?.status === DUPLICATE_USER_STATUS_CODE) {
      throw ex;
    }

    error("Failed to save student");
  }
};

export const updateStudent =
  (id: string, payload: IUser, isDelete?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateStudentActionType.UPDATE_STUDENT_PENDING,
    });
    try {
      const student = await studentsService.update(id, payload);
      dispatch({
        type: UpdateStudentActionType.UPDATE_STUDENT_FULFILLED,
        data: student,
      });
      success(isDelete ? "Deleted successfully" : "Student updated");

      return student;
    } catch (ex) {
      dispatch({
        type: UpdateStudentActionType.UPDATE_STUDENT_REJECTED,
        data: ex,
      });

      error("Failed to update student");
    }
  };

export const fetchStudent = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchStudentActionType.FETCH_STUDENT_PENDING,
  });
  try {
    const student = await studentsService.fetch(id);
    dispatch({
      type: FetchStudentActionType.FETCH_STUDENT_FULFILLED,
      data: student,
    });

    return student;
  } catch (ex) {
    dispatch({
      type: FetchStudentActionType.FETCH_STUDENT_REJECTED,
      data: ex,
    });

    error("Failed to fetch Student");
  }
};

export const fetchStudents =
  (params: Params = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FetchStudentsActionType.FETCH_STUDENTS_PENDING,
    });
    try {
      const students = await studentsService.fetchAll(params);
      dispatch({
        type: FetchStudentsActionType.FETCH_STUDENTS_FULFILLED,
        data: students,
      });

      return students;
    } catch (ex) {
      dispatch({
        type: FetchStudentsActionType.FETCH_STUDENTS_REJECTED,
        data: ex,
      });

      error("Failed to fetch students");
    }
  };

export const fetchPagedStudents =
  (params: Params = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FetchPagedStudentsActionType.FETCH_PAGED_STUDENTS_PENDING,
    });
    try {
      const pagedStudents = await studentsService.fetchPagedAll(params);
      dispatch({
        type: FetchPagedStudentsActionType.FETCH_PAGED_STUDENTS_FULFILLED,
        data: pagedStudents,
      });

      return pagedStudents;
    } catch (ex) {
      dispatch({
        type: FetchPagedStudentsActionType.FETCH_PAGED_STUDENTS_REJECTED,
        data: ex,
      });

      error("Failed to fetch students");
    }
  };

export const fetchMentorStudents =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchStudentsActionType.FETCH_STUDENTS_PENDING,
    });
    try {
      const students = await studentsService.fetchAllStudentsOfPractitioner(id);
      dispatch({
        type: FetchStudentsActionType.FETCH_STUDENTS_FULFILLED,
        data: students,
      });

      return students;
    } catch (ex) {
      dispatch({
        type: FetchStudentsActionType.FETCH_STUDENTS_REJECTED,
        data: ex,
      });

      error("Failed to fetch students");
    }
  };

export const saveStudentFilters =
  (filters: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: StudentFilterActionType.SET_FILTER,
      data: filters,
    });
  };

export const resetStudentFilters = () => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.RESET_FILTER,
  });
};

export const setTermInFilter = (filters: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.SET_TERM,
    data: filters,
  });
};

export const resetTermInFilter = () => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.RESET_TERM,
  });
};

export const setTermStatusInFilter =
  (filters: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: StudentFilterActionType.SET_TERM_STATUS,
      data: filters,
    });
  };

export const resetTermStatusInFilter = () => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.RESET_TERM_STATUS,
  });
};

export const setUserStatusInFilter =
  (filters: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: StudentFilterActionType.SET_USER_STATUS,
      data: filters,
    });
  };

export const resetUserStatusInFilter = () => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.RESET_USER_STATUS,
  });
};

export const setLearningCenterInFilter =
  (filters: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: StudentFilterActionType.SET_LEARNING_CENTER,
      data: filters,
    });
  };

export const resetLearningCenterInFilter = () => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.RESET_LEARNING_CENTER,
  });
};

export const saveStudentsPagination =
  (offset: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: StudentFilterActionType.SET_PAGINATION,
      data: offset,
    });
  };

export const resetStudentsPagination = () => async (dispatch: Dispatch) => {
  dispatch({
    type: StudentFilterActionType.RESET_PAGINATION,
  });
};
