import config from "configs";
import { http } from "utils/http";
import pinterpolate from "pinterpolate";

export const fetchTerms = async () => {
  const { data } = await http.get(config.endpoints.terms.all);
  return data;
};
interface ITermParams {
  academicYear: string;
  termTitle: string;
  startDate: string;
  endDate: string;
  isCurrent: boolean;
  learningCenters?: string[];
}
interface ITermUpdateParams extends ITermParams {
  id: string;
}
export const addTerm = async ({
  academicYear,
  termTitle,
  startDate,
  endDate,
  isCurrent,
  learningCenters,
}: ITermParams) => {
  const { data } = await http.post(config.endpoints.terms.all, {
    yearId: academicYear,
    name: termTitle,
    startDate,
    endDate,
    isCurrent,
    centers: learningCenters ? learningCenters : [],
  });
  return data;
};

export const updateTerm = async ({
  id,
  academicYear,
  termTitle,
  startDate,
  endDate,
  isCurrent,
  learningCenters,
}: ITermUpdateParams) => {
  const url = pinterpolate(config.endpoints.terms.one, { id: id });
  await http.patch(url, {
    yearId: academicYear,
    name: termTitle,
    isCurrent,
    startDate,
    endDate,
    centers: learningCenters ? learningCenters : [],
  });
};

export const fetchAcademicYear = async () => {
  const { data } = await http.get(config.endpoints.academicYear.all);

  return data;
};
