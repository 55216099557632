import * as React from "react";

import Loader from "../Loader";
import INotification from "commons/types/notification";
import { updateMany, updateSeen } from "services/notifications";
import { isDateInRange, getDayFromDate } from "utils/dates";
import { getDataFromLocalStorage } from "services/localStorage";

interface INotifications {
  Today?: INotification[];
  Yesterday?: INotification[];
  Old?: INotification[];
  "Last week"?: INotification[];
}

interface INotificationsProps {
  notificationList: INotification[];
  isLoading: boolean;
  fetchData: (id: string) => void;
  fetchNotificationCount: (id: string) => void;
}

const Notifications: React.FC<INotificationsProps> = ({
  notificationList,
  isLoading,
  fetchData,
  fetchNotificationCount,
}) => {
  const profile = getDataFromLocalStorage("profile");

  const updateCount = React.useCallback(
    async (id: string) => {
      await updateSeen(id);
      fetchNotificationCount(id);
    },
    [fetchNotificationCount],
  );

  React.useEffect(() => {
    updateCount(profile._id);
    fetchData(profile._id);
  }, [fetchData, profile._id, updateCount]);

  let notifications: INotifications = {};
  let notificationIds: string[] = [];

  notificationList.sort(
    (a: INotification, b: INotification) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
  const ids: string[] = [];
  const dayWiseNotification: INotifications = {};
  const today = new Date();
  const weekBack = new Date();
  weekBack.setDate(today.getDate() - 7);

  if (notificationList.length) {
    notificationList.forEach((notification: INotification) => {
      let section: "Old" | "Today" | "Yesterday" | "Last week" = "Old";

      if (isDateInRange(weekBack, today, new Date(notification.createdAt))) {
        const day = getDayFromDate(notification.createdAt, true);
        if (day === "Today" || day === "Yesterday") {
          section = day;
        } else {
          section = "Last week";
        }
      }
      if (!dayWiseNotification[section]) {
        dayWiseNotification[section] = [];
      }
      dayWiseNotification[section]?.push({ ...notification });
      ids.push(notification._id);
    });
    notifications = dayWiseNotification;
    notificationIds = ids;
  }

  const markAllAsRead = async () => {
    await updateMany({ ids: notificationIds, isRead: true });
    fetchData(profile._id);
  };

  const getIconNameByNotificationType = (notificationType: string) => {
    switch (notificationType) {
      case "notification.STUDENT_ASSIGNED":
        return "group";
      case "notification.MISSED_HIGH_PRIORITY":
        return "bell";
      case "notification.MESSAGE_RECEIVED":
        return "chat";
      case "notification.FULL_PROGRESS_BAR":
        return "calendar-check";
      case "notification.UNDO_COMPLETED_TASK":
        return "book-open";
      default:
        return "bell";
    }
  };

  const getFormattedDate = (section: string, createdAt: string) => {
    const createdTimeString = new Date(createdAt)
      .toLocaleTimeString()
      .replace(/(.*)\D\d+/, "$1");
    if (isDateInRange(weekBack, today, new Date(createdAt))) {
      const day = getDayFromDate(createdAt, true);
      if (day === "Today" || day === "Yesterday") {
        section = day;
        return createdTimeString;
      } else {
        return `${getDayFromDate(createdAt)} at ${createdTimeString}`;
      }
    } else {
      return `${new Date(
        createdAt,
      ).toLocaleDateString()} at ${createdTimeString}`;
    }
  };

  const getNotifications = () => {
    const notificationElements: any = [];
    Object.keys(notifications).forEach((key: string, idx) => {
      notificationElements.push(
        <React.Fragment key={idx}>
          <h3 className="notification__day mb-2x">{key}</h3>
          {(key === "Old" ||
            key === "Today" ||
            key === "Yesterday" ||
            key === "Last week") &&
            notifications[key]?.map((notification: INotification) => {
              return (
                <div key={notification._id} className="notification">
                  <div className="icon">
                    <box-icon
                      name={getIconNameByNotificationType(notification.type)}
                    />
                  </div>
                  <div className="notification-text">
                    <p
                      dangerouslySetInnerHTML={{ __html: notification.message }}
                    ></p>
                    <p>{getFormattedDate(key, notification.createdAt)}</p>
                  </div>
                </div>
              );
            })}
        </React.Fragment>,
      );
    });

    return notificationElements;
  };

  return (
    <>
      <div className="modal-wrap__header no-border">
        <h2>Activity notifications</h2>
        {notificationIds.length > 0 ? (
          <span
            onClick={markAllAsRead}
            className="link-item small mt-1x link-text"
          >
            Mark all as read ({notificationIds.length})
          </span>
        ) : (
          <span
            onClick={() => fetchData(profile._id)}
            className="link-item small mt-1x link-text"
          >
            Refresh
          </span>
        )}
      </div>
      <div className="modal-wrap__body">
        <div className="scroll-section">
          {isLoading ? (
            <Loader type="ThreeDots" />
          ) : notificationIds.length ? (
            getNotifications()
          ) : (
            <div className="empty-section mt-4x">
              <div className="empty-section__content wide">
                <box-icon name="bell" />
                <label>You have no unread notifications.</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
