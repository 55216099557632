import "boxicons";
import * as React from "react";

import { error } from "utils/toast";
import Loader from "commons/components/Loader";
import useMountedRef from "commons/hooks/useMountedRef";
import { getTemplates } from "services/scheduledMessages";

import { ComponentStatus } from "commons/types/status";
import { ITemplateComponentState } from "commons/types/scheduledMessages";

import tasksImage from "assets/images/tasks.svg";
interface MessageTemplatesProps {
  selectTemplateHandler: (body: string) => void;
}

const MessageTemplates: React.FC<MessageTemplatesProps> = ({
  selectTemplateHandler,
}) => {
  const [componentState, setComponentState] =
    React.useState<ITemplateComponentState>({
      status: ComponentStatus.Idle,
      data: [],
    });

  const isMounted = useMountedRef();

  const getTemplateList = React.useCallback(async () => {
    setComponentState({
      status: ComponentStatus.Loading,
      data: [],
    });

    try {
      const res = await getTemplates();

      if (isMounted) {
        setComponentState({
          status: ComponentStatus.Loaded,
          data: res.data,
        });
      }
    } catch (err: any) {
      error(err.message || "Error while fetching template list");
      if (isMounted) {
        setComponentState({
          status: ComponentStatus.Error,
          data: [],
        });
      }
    }
  }, [setComponentState, isMounted]);

  React.useEffect(() => {
    getTemplateList();
  }, [getTemplateList]);

  const getComponentBody = () => {
    switch (componentState.status) {
      case ComponentStatus.Loaded:
        if (!componentState.data?.length) {
          return (
            <div className="empty-section mt-20x">
              <div className="empty-section__content empty-section__modal-content">
                <img src={tasksImage} alt="Message templates" />
                <h3>You currently do not have any message templates.</h3>
              </div>
            </div>
          );
        }
        return componentState.data.map((template) => (
          <div
            className="card card-addinfo link-item"
            onClick={() => selectTemplateHandler(template.body)}
            key={template._id}
          >
            <div className="card-content">
              <div className="card-text">
                <span className="d-block">“{template.body}”</span>
              </div>
            </div>
          </div>
        ));

      case ComponentStatus.Loading:
        return <Loader type="ThreeDots"></Loader>;

      case ComponentStatus.Error:
        return (
          <div className="d-flex flex-column align-items-center">
            <p>Error when loading data </p>
            <button
              className="btn btn--primary mt-4x"
              type="submit"
              onClick={() => getTemplateList()}
            >
              Reload
            </button>
          </div>
        );

      default:
      case ComponentStatus.Idle:
        return <div>Empty</div>;
    }
  };

  return (
    <div className="modal-wrap__body modal-wrap__body--gray modal-wrap__body--scroll">
      <section className="section">
        <div className="section__content">{getComponentBody()}</div>
      </section>
    </div>
  );
};

export default MessageTemplates;
