import { useState } from "react";
import { useFormik } from "formik";

import { TextInput } from "commons/components/form-fields";
import { QualitativeGradeTypeSchema } from "commons/schemas/gradeType";
import { IQualitativeGradesFormProps } from "./qualitativeGrades.interface";

const Form: React.FC<IQualitativeGradesFormProps> = ({
  closeModal,
  initialValues,
  isEdit = false,
  saveGradeType,
  updateGradeType,
}) => {
  const [gradesCount, setGradesCount] = useState(
    initialValues?.data?.options.length || 0,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (!isEdit && saveGradeType) {
        const newGradeType = await saveGradeType(values);
        if (newGradeType) closeModal();
      }
      if (updateGradeType && initialValues._id) {
        const updatedGradeType = await updateGradeType(
          initialValues._id,
          values,
        );
        if (updatedGradeType) closeModal();
      }
    },
    validationSchema: QualitativeGradeTypeSchema,
  });

  const addNewGrade = () => {
    formik.setFieldValue(`data.options[${gradesCount}].key`, "");
    formik.setFieldValue(
      `data.options[${gradesCount}].greaterThanEqual`,
      formik.values.data.range.minimum,
    );
    formik.setFieldValue(
      `data.options[${gradesCount}].lessThan`,
      gradesCount > 0
        ? formik.values?.data?.options[gradesCount - 1]?.greaterThanEqual
        : formik.values.data.range.maximum,
    );
    setGradesCount((gradesCount) => gradesCount + 1);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body pb-0x">
        <div className="row">
          <div className="col-12">
            <TextInput
              label="Grade Type"
              name="name"
              placeholder="Enter grade type"
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row pt-4x bg-gray">
          <div className="col-12 txt-black-color fm-medium mb-2x">
            Set Range
          </div>
          <div className="col-6">
            <TextInput
              label="Minimum"
              name="data.range.minimum"
              placeholder="Enter Minimum"
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
          <div className="col-6">
            <TextInput
              label="Maximum"
              name="data.range.maximum"
              placeholder="Enter Maximum"
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>

        <div className="txt-black-color fm-medium mb-4x mt-6x">Set Grades</div>
        <div className="scroll-section--sm scroll-section">
          {[...Array(gradesCount)].map((_, idx) => (
            <div className="row" key={idx}>
              <div className="col-4">
                <div className="input-wrap">
                  <label className="input__label required">Name</label>
                  <input
                    type={"text"}
                    className={
                      formik.touched?.data?.options &&
                      formik.touched?.data?.options[idx]?.key &&
                      formik.errors?.data?.options &&
                      formik.errors?.data?.options[idx]
                        ? "input input--error"
                        : "input"
                    }
                    placeholder={"Enter name"}
                    name={`data.options[${idx}].key`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.data.options[idx]?.key}
                  />
                  {formik.touched?.data?.options &&
                    formik.touched?.data?.options[idx]?.key &&
                    formik.errors?.data?.options &&
                    formik.errors?.data?.options[idx] && (
                      <label className="input__error">
                        {
                          // TODO: formik.errors?.data?.options[idx]?.key
                          formik.values?.data?.options[idx]?.key
                            ? "Invalid name"
                            : "Name is required"
                        }
                      </label>
                    )}
                </div>
              </div>
              <div className="col-4">
                <div className="input-wrap">
                  <label className="input__label">Greater than/equal</label>
                  <input
                    type={"text"}
                    className="input"
                    placeholder={"Enter greater than/equal"}
                    name={`data.options[${idx}].greaterThanEqual`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.data.options[idx]?.greaterThanEqual}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="input-wrap">
                  <label className="input__label">Less than</label>
                  <input
                    type={"text"}
                    className="input"
                    placeholder={"Enter Less than"}
                    name={`data.options[${idx}].lessThan`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.data?.options[idx]?.lessThan}
                  />
                </div>
              </div>
              <span
                className="link-item"
                onClick={() => {
                  if (gradesCount) {
                    setGradesCount((gradesCount) => gradesCount - 1);
                    const options = [...formik.values.data.options];
                    options.splice(idx, 1);
                    formik.setFieldValue("data.options", options);
                  }
                }}
              >
                <box-icon name="x" />
              </span>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="link-add-new" onClick={addNewGrade}>
              <box-icon name="plus"></box-icon>
              <span className="link-text">Add Grades</span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
