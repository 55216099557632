import React from "react";

import StudentListCard from "./StudentListCard";

interface IStudentsProps {
  students: any[];
  showChatHistoryOfAChannel: any;
}

const ListStudents: React.FC<IStudentsProps> = ({
  students,
  showChatHistoryOfAChannel,
}) => {
  return (
    <>
      <div className="content-list">
        {students.map((student) => {
          return (
            <StudentListCard
              key={student.id}
              student={student}
              showChatHistoryOfAChannel={showChatHistoryOfAChannel}
            />
          );
        })}
      </div>
    </>
  );
};

export default ListStudents;
