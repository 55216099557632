import React from "react";
import { connect } from "react-redux";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import Form from "pages/assignments/add/Form";
import { IAssignment } from "commons/types/assignments";
import { updateAssignment } from "store/actions/data/assignments";

interface IUpdateAssignmentProps {
  closeModal: () => void;
  assignment: any;
  updateAssignment: any;
  student: IUser;
}

const UpdateAssignment: React.FC<IUpdateAssignmentProps> = ({
  assignment,
  updateAssignment,
  closeModal,
  student,
}): JSX.Element => {
  const intialValues = {
    name: assignment.name,
    submissionDate: new Date(assignment.submissionDate),
    isPrintable: assignment.isPrintable,
    course: assignment.course?._id,
  };
  const saveAssignment = async (payload: IAssignment) => {
    await updateAssignment(assignment._id, payload);
  };

  return (
    <>
      <div className="modal-wrap modal-wrap--lg">
        <div className="modal-wrap__header">
          <h3>Edit Missing Assignment for {getFullName(student)}</h3>
          <span onClick={closeModal} className="link-item">
            <box-icon name="x" />
          </span>
        </div>
        <Form
          student={student}
          closeModal={closeModal}
          saveAssignment={saveAssignment}
          initialValues={intialValues}
        />
      </div>
    </>
  );
};
interface State {}
const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  updateAssignment,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAssignment);
