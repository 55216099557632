import Form from "../Form";
import { IAddQualitativeGradesProps } from "./AddQualitativeGrades.interface";

const AddQualitativeGrades: React.FC<IAddQualitativeGradesProps> = ({
  closeModal,
  saveGradeType,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add Grade</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={{
          name: "",
          type: "Qualitative",
          data: {
            type: "Qualitative",
            range: {
              minimum: "",
              maximum: "",
            },
            options: [],
          },
        }}
        saveGradeType={saveGradeType}
        closeModal={closeModal}
      />
    </div>
  );
};

export default AddQualitativeGrades;
