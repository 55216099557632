import { ICenter } from "commons/types/centers";

export enum AddCenterActionType {
  ADD_CENTER_PENDING = "ADD_CENTER_PENDING",
  ADD_CENTER_REJECTED = "ADD_CENTER_REJECTED",
  ADD_CENTER_FULFILLED = "ADD_CENTER_FULFILLED",
}
export interface AddAddCenterAction {
  type: AddCenterActionType;
  data: ICenter;
}

export enum FetchCentersActionType {
  FETCH_CENTERS_PENDING = "FETCH_CENTERS_PENDING",
  FETCH_CENTERS_REJECTED = "FETCH_CENTERS_REJECTED",
  FETCH_CENTERS_FULFILLED = "FETCH_CENTERS_FULFILLED",
}
export interface FetchAddCentersAction {
  type: FetchCentersActionType;
  data: ICenter[];
}

export enum FetchCentersType {
  FETCH_CENTERS_PENDING = "FETCH_CENTERS_PENDING",
  FETCH_CENTERS_REJECTED = "FETCH_CENTERS_REJECTED",
  FETCH_CENTERS_FULFILLED = "FETCH_CENTERS_FULFILLED",
}
export interface FetchCenterAction {
  type: FetchCentersActionType;
  data: ICenter;
}

export enum UpdateCenterActionType {
  UPDATE_CENTER_PENDING = "UPDATE_CENTER_PENDING",
  UPDATE_CENTER_REJECTED = "UPDATE_CENTER_REJECTED",
  UPDATE_CENTER_FULFILLED = "UPDATE_CENTER_FULFILLED",
}
export interface UpdateAddCentersAction {
  type: UpdateCenterActionType;
  data: ICenter;
}

export enum DeleteCenterActionType {
  DELETE_CENTER_PENDING = "DELETE_CENTER_PENDING",
  DELETE_CENTER_FULFILLED = "DELETE_CENTER_FULFILLED",
  DELETE_CENTER_REJECTED = "DELETE_CENTER_REJECTED",
}

export interface DeleteCenterAction {
  type: DeleteCenterActionType;
  data: ICenter;
}
