import {
  FetchDistrictsActionType,
  FetchSchoolsActionType,
} from "store/actions/types/institution";

const INITIAL_STATE = {
  fetchDistricts: [],
  fetchDistrictsLoading: false,
  fetchDistrictsError: null,
  fetchSchools: [],
  fetchSchoolsError: null,
  fetchSchoolsLoading: false,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchDistrictsActionType.FETCH_DISTRICT_PENDING:
      return {
        ...state,
        fetchDistrictsLoading: true,
        fetchDistrictsError: null,
      };
    case FetchDistrictsActionType.FETCH_DISTRICT_FULFILLED:
      return {
        ...state,
        fetchDistrictsLoading: false,
        fetchDistrictsError: null,
        fetchDistricts: action.data,
      };
    case FetchDistrictsActionType.FETCH_DISTRICT_REJECTED:
      return {
        ...state,
        fetchDistrictsLoading: false,
        fetchDistrictsError: action.data,
      };
    case FetchSchoolsActionType.FETCH_SCHOOLS_PENDING:
      return {
        ...state,
        fetchSchoolsLoading: true,
        fetchSchoolsError: null,
      };
    case FetchSchoolsActionType.FETCH_SCHOOLS_FULFILLED:
      return {
        ...state,
        fetchSchools: action.data,
        fetchSchoolsLoading: false,
        fetchSchoolsError: null,
      };
    case FetchSchoolsActionType.FETCH_SCHOOLS_REJECTED:
      return {
        ...state,
        fetchSchoolsLoading: false,
        fetchSchoolsError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
