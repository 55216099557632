import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  ISatelliteLocation,
  ISatelliteLocationParams,
  IUpdateSatelliteLocation,
} from "commons/types/satelliteLocations";

export const add = async (payload: ISatelliteLocation) => {
  const url = config.endpoints.satelliteLocations.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: ISatelliteLocationParams) => {
  const url = stringify(config.endpoints.satelliteLocations.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: IUpdateSatelliteLocation) => {
  const url = pinterpolate(config.endpoints.satelliteLocations.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.satelliteLocations.one, { id: id });
  const data = await http.delete(url);
  return data;
};
