import { Dispatch } from "redux";
import {
  FetchCoursesActionType,
  AddCourseActionType,
  UpdateCourseActionType,
  DeleteCourseActionType,
} from "../types/courses";

import { error, success } from "utils/toast";
import * as courseService from "services/courses";
import { ICourses, ICoursesInput, ICoursesParams } from "commons/types/courses";

export const fetchCourses =
  (params: ICoursesParams) =>
  async (dispatch: Dispatch): Promise<ICourses[]> => {
    dispatch({ type: FetchCoursesActionType.FETCH_COURSES_PENDING });
    try {
      const { data } = await courseService.fetchCourses(params);
      dispatch({
        type: FetchCoursesActionType.FETCH_COURSES_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchCoursesActionType.FETCH_COURSES_REJECTED,
        data: err,
      });

      return [];
    }
  };

export const addCourse =
  (payload: ICoursesInput) => async (dispatch: Dispatch) => {
    dispatch({ type: AddCourseActionType.ADD_COURSE_PENDING });
    try {
      const { data } = await courseService.addCourse(payload);
      dispatch({
        type: AddCourseActionType.ADD_COURSE_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      error("Failed to add course");
      dispatch({
        type: AddCourseActionType.ADD_COURSE_REJECTED,
        data: err,
      });
    }
  };

export const updateCourse =
  (payload: Partial<ICoursesInput>, id: string) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: UpdateCourseActionType.UPDATE_COURSE_PENDING });
    try {
      const { data } = await courseService.updateCourse(payload, id);
      success("Course updated successfully");
      dispatch({
        type: UpdateCourseActionType.UPDATE_COURSE_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      error("Failed to update course");
      dispatch({
        type: UpdateCourseActionType.UPDATE_COURSE_REJECTED,
        data: err,
      });
    }
  };

export const deleteCourse = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: DeleteCourseActionType.DELETE_COURSE_PENDING });
  try {
    const { data } = await courseService.deleteCourse(id);
    success("Course deleted Successfully");
    dispatch({
      type: DeleteCourseActionType.DELETE_COURSE_FULFILLED,
      data: data,
    });
    return data;
  } catch (err: any) {
    error("Failed to delete course");
    dispatch({
      type: DeleteCourseActionType.DELETE_COURSE_REJECTED,
      data: err,
    });
  }
};
