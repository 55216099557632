import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as schoolDistrictService from "services/schoolDistricts";
import {
  AddSchoolDistrictActionType,
  DeleteSchoolDistrictActionType,
  FetchSchoolDistrictsActionType,
  UpdateSchoolDistrictActionType,
} from "../types/schoolDistricts";
import {
  ISchoolDistrict,
  ISchoolDistrictParams,
} from "commons/types/schoolDistrict";

export const addSchoolDistrict =
  (payload: ISchoolDistrict) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddSchoolDistrictActionType.ADD_School_District_PENDING,
    });
    try {
      const { data } = await schoolDistrictService.add(payload);
      dispatch({
        type: AddSchoolDistrictActionType.ADD_School_District_FULFILLED,
        data: data,
      });
      success("Saved school district");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddSchoolDistrictActionType.ADD_School_District_REJECTED,
        data: err,
      });

      error("School District saved to fail");
    }
  };

export const fetchSchoolDistricts =
  (params: ISchoolDistrictParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchSchoolDistrictsActionType.FETCH_SCHOOL_DISTRICTS_PENDING,
    });
    try {
      const { data } = await schoolDistrictService.fetchAll(params);
      dispatch({
        type: FetchSchoolDistrictsActionType.FETCH_SCHOOL_DISTRICTS_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchSchoolDistrictsActionType.FETCH_SCHOOL_DISTRICTS_REJECTED,
        data: err,
      });

      error("School District failed to load");
    }
  };

export const updateSchoolDistrict =
  (id: string, payload: ISchoolDistrict) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateSchoolDistrictActionType.UPDATE_SCHOOL_DISTRICT_PENDING,
    });
    try {
      const { data } = await schoolDistrictService.update(id, payload);
      dispatch({
        type: UpdateSchoolDistrictActionType.UPDATE_SCHOOL_DISTRICT_FULFILLED,
        data: data,
      });
      success("School district updated successfully");
    } catch (err: any) {
      dispatch({
        type: UpdateSchoolDistrictActionType.UPDATE_SCHOOL_DISTRICT_REJECTED,
        data: err,
      });

      error("School district failed to update");
    }
  };

export const deleteSchoolDistrict =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: DeleteSchoolDistrictActionType.DELETE_SCHOOL_DISTRICT_PENDING,
    });
    try {
      const { data } = await schoolDistrictService.deleteOne(id);
      dispatch({
        type: DeleteSchoolDistrictActionType.DELETE_SCHOOL_DISTRICT_FULFILLED,
        data: data,
      });
      success("School district deleted successfully");

      return data;
    } catch (err: any) {
      dispatch({
        type: DeleteSchoolDistrictActionType.DELETE_SCHOOL_DISTRICT_REJECTED,
        data: err,
      });

      error("School District failed to delete");
    }
  };
