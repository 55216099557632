import React from "react";

import { ISurveyInitiate } from "../../../../commons/types/surveys";

interface Props {
  assessmentFormData: ISurveyInitiate;
  isInvalidSubmission: Boolean;
}

const ValidationErrorMessage: React.FC<Props> = ({
  assessmentFormData,
  isInvalidSubmission,
}) => {
  if (!isInvalidSubmission) {
    return null;
  }
  const validationErrorMessage = (() => {
    if (
      assessmentFormData.category === "" ||
      assessmentFormData?.surveyType === "" ||
      assessmentFormData.term === ""
    ) {
      return "Please select all the required fields";
    }

    if (assessmentFormData?.responders?.length === 0) {
      return "At least one student must be selected to send the survey";
    }
  })();

  return <label className="input__error">{validationErrorMessage}</label>;
};

export default ValidationErrorMessage;
