import React from "react";
import { ICenter } from "commons/types/centers";

import Form from "../Form";

interface IAddCenter {
  closeModal: () => void;
  saveCenter: (center: ICenter) => Promise<void>;
}
const AddCenter: React.FC<IAddCenter> = ({ closeModal, saveCenter }) => {
  const initialValue: ICenter = {
    name: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add a center</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        saveCenter={saveCenter}
        initialValues={initialValue}
        closeModal={closeModal}
      />
    </div>
  );
};

export default AddCenter;
