import React from "react";

import { update } from "services/uow";
import { getFullName } from "utils/names";
import { error, success } from "utils/toast";
import AddGoalForm from "pages/goals/add/AddGoalForm";
import { isStudentLoggedIn } from "utils/window";

type Props = {
  goal: any;
  closeModal: any;
  goalRefresh: any;
};

const UpdateForm: React.FC<Props> = ({
  goal,
  closeModal,
  goalRefresh,
}: Props): JSX.Element => {
  const updateGoal = async (updatedGoal: any) => {
    try {
      await update(goal._id, updatedGoal);
      goalRefresh();
      success("Goal successfully edited");
    } catch (err: any) {
      error("Failed to update");
    } finally {
      closeModal();
    }
  };

  const initialValues = {
    name: goal?.name,
    requiredDeadline: goal?.requiredDeadline.toString(),
    workData: {
      workType: goal?.workData?.workType || "",
      status: goal?.workData?.status || "Created",
      uowType: goal?.workData?.uowType || "Goal",
      metrics: {
        dataType: goal?.workData?.metrics?.dataType || "String",
        metricsType: goal?.workData?.metrics?.metricsType || "",
        baselineMetric: goal?.workData?.metrics?.baselineMetric || "",
        achievedMetric: goal?.workData?.metrics?.achievedMetric || "",
        baselineDate: goal?.workData?.metrics?.baselineDate,
      },
    },
    isCompleted: goal?.isCompleted,
    assignee: goal.assignee?._id,
    uowType: "Goal",
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>
          Edit goal{" "}
          {!isStudentLoggedIn() && `for ${getFullName(goal?.assignee)}`}
        </h3>
        <span onClick={closeModal} className="link-item">
          <box-icon name="x" />
        </span>
      </div>
      <AddGoalForm
        initialValues={initialValues}
        closeModal={closeModal}
        saveGoal={updateGoal}
      />
    </div>
  );
};

export default UpdateForm;
