import { useFormik } from "formik";

import { TextInput } from "commons/components/form-fields";
import AccommodationSchema from "commons/schemas/accommodation";
import { IAccommodationFormProps } from "./accommodation.interface";

const Form: React.FC<IAccommodationFormProps> = ({
  closeModal,
  initialValues,
  isEdit = false,
  saveAccommodation,
  updateAccommodation,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (!isEdit && saveAccommodation) {
        return saveAccommodation(values);
      }

      if (updateAccommodation) {
        updateAccommodation(initialValues._id, values);
      }
    },
    validationSchema: AccommodationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body pb-0x">
        <div className="row">
          <div className="col-12">
            <TextInput
              label="Accommodation Name"
              name="name"
              placeholder="Enter name"
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {isEdit ? "Update" : "Save"}
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
