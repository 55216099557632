import { Dispatch } from "redux";

import * as tasksService from "services/uow";
import { error, success } from "utils/toast";
import {
  IGoal as ITask,
  IGoalParams as ITaskParams,
} from "commons/types/goals";
import {
  AddTaskActionType,
  DeleteTaskActionType,
  FetchTaskActionType,
  FetchTasksActionType,
  UpdateTaskActionType,
  MarkAsIncompleteTaskType,
} from "store/actions/types/tasks";
import { UowDeleteEditType } from "../../../constants";

export const addTask = (payload: ITask) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddTaskActionType.ADD_TASK_PENDING,
  });
  try {
    const task = await tasksService.add(payload);
    dispatch({
      type: AddTaskActionType.ADD_TASK_FULFILLED,
      data: task,
    });
    success("Saved task");

    return task;
  } catch (ex) {
    dispatch({
      type: AddTaskActionType.ADD_TASK_REJECTED,
      data: ex,
    });

    error("Failed to save task");
  }
};

export const updateTask =
  (id: string, payload: ITask) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateTaskActionType.UPDATE_TASK_PENDING,
    });
    try {
      const { data: task } = await tasksService.update(id, payload);
      dispatch({
        type: UpdateTaskActionType.UPDATE_TASK_FULFILLED,
        data: task,
      });
      success("Updated task");

      return task;
    } catch (ex) {
      dispatch({
        type: UpdateTaskActionType.UPDATE_TASK_REJECTED,
        data: ex,
      });

      error("Failed to update task");
    }
  };

export const fetchTask = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchTaskActionType.FETCH_TASK_PENDING,
  });
  try {
    const task = await tasksService.fetch(id);
    dispatch({
      type: FetchTaskActionType.FETCH_TASK_FULFILLED,
      data: task,
    });

    return task;
  } catch (ex) {
    dispatch({
      type: FetchTaskActionType.FETCH_TASK_REJECTED,
      data: ex,
    });

    error("Failed to fetch task");
  }
};

export const fetchTasks =
  (params: ITaskParams) => async (dispatch: Dispatch) => {
    if (!params.uowType) params.uowType = "Task";
    dispatch({
      type: FetchTasksActionType.FETCH_TASKS_PENDING,
    });
    try {
      const { data: tasks } = await tasksService.fetchAll(params);
      dispatch({
        type: FetchTasksActionType.FETCH_TASKS_FULFILLED,
        data: tasks,
      });

      return tasks;
    } catch (ex) {
      dispatch({
        type: FetchTasksActionType.FETCH_TASKS_REJECTED,
        data: ex,
      });

      error("Failed to fetch tasks");
    }
  };

export const deleteTask =
  (id: string, type: UowDeleteEditType = UowDeleteEditType.SELF) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: DeleteTaskActionType.DELETE_TASK_PENDING,
    });
    try {
      const { data: task } = await tasksService.deleteUow(id, type);
      dispatch({
        type: DeleteTaskActionType.DELETE_TASK_FULFILLED,
        data: task,
      });
      success("Deleted task");

      return task;
    } catch (ex) {
      dispatch({
        type: DeleteTaskActionType.DELETE_TASK_REJECTED,
        data: ex,
      });

      error("Failed to delete task");
    }
  };

export const markAsIncomplete = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: MarkAsIncompleteTaskType.MARK_AS_INCOMPLETE_TASK_PENDING,
  });
  try {
    const { data: task } = await tasksService.markAsIncomplete(id);
    dispatch({
      type: MarkAsIncompleteTaskType.MARK_AS_INCOMPLETE_TASK_FULFILLED,
      data: task,
    });
    success("Marked as incomplete");

    return task;
  } catch (ex) {
    dispatch({
      type: MarkAsIncompleteTaskType.MARK_AS_INCOMPLETE_TASK_FULFILLED,
      data: ex,
    });

    error("Failed to update task");
  }
};
