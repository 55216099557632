import { ChangeEventHandler, FC, ReactElement } from "react";

import { getFieldValue } from "utils/arrays";

interface IFieldProps {
  id: string;
  label: string;
  value: string;
  isChecked: boolean;
  disabled?: boolean;
}

interface IOptionProps {
  type: string;
  className?: string;
  labelClassName?: string;
  optionsClassName?: string;
  groupName: string;
  options: IFieldProps[];
  errors: any;
  touched: any;
  handleChange: ChangeEventHandler<HTMLInputElement>;
}

const OptionsInput: FC<IOptionProps> = ({
  type,
  groupName,
  options,
  handleChange,
  errors,
  touched,
  className,
  labelClassName,
  optionsClassName,
}): ReactElement => {
  return (
    <>
      <div className={`radio-inline ${className || ""}`}>
        <div className={`radio-options ${optionsClassName || ""}`}>
          {options.map(
            (
              { label, id, value, isChecked, disabled }: IFieldProps,
              index: number,
            ) => (
              <div key={`checkbox-${index}`} className="input-wrap">
                <input
                  className="box-links__nodes"
                  type={type}
                  name={groupName}
                  value={value}
                  checked={isChecked}
                  onChange={handleChange}
                  id={id}
                  disabled={disabled}
                />
                <label
                  className={`input__label${disabled && " button-disabled"} ${
                    labelClassName || ""
                  }`}
                  htmlFor={id}
                >
                  {label}
                </label>
              </div>
            ),
          )}
        </div>
        {getFieldValue(errors, groupName) &&
          getFieldValue(touched, groupName) && (
            <p className="input__error">{getFieldValue(errors, groupName)}</p>
          )}
      </div>
    </>
  );
};

export default OptionsInput;
