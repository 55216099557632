import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import { IUser } from "commons/types/users";
import AssignmentList from "./AssignmentList";
import Loader from "commons/components/Loader";
import { getFullName, getNameInitials } from "utils/names";
import {
  fetchAssignments,
  updateAssignment,
  deleteAssignment,
} from "store/actions/data/assignments";
import {
  IAssignment,
  IAssignmentParams,
  IUpdateAssignment,
} from "commons/types/assignments";
import { subdomainName } from "utils/http";

type AssignmentsProps = {
  id: string;
  student: IUser;
  assignments: IAssignment[];
  fetchAssignmentsLoading?: boolean;
  updateAssignmentLoading?: boolean;
  openAddAssignmentModal: () => any;
  fetchAssignments: (param: IAssignmentParams) => Promise<IAssignment[]>;
  updateAssignment: (id: string, payload: IUpdateAssignment) => Promise<any>;
  deleteAssignment: (id: string) => any;
};

const Assignments: React.FC<AssignmentsProps> = ({
  id,
  assignments,
  student,
  fetchAssignments,
  openAddAssignmentModal,
  updateAssignment,
  deleteAssignment,
  fetchAssignmentsLoading = true,
}) => {
  const fetchAssignmentsData = useCallback(() => {
    if (student._id) {
      fetchAssignments({ subject: student._id });
    }
  }, [student._id, fetchAssignments]);

  useEffect(() => {
    fetchAssignmentsData();
  }, [fetchAssignmentsData]);

  return (
    <>
      <div className="page-heading">
        <div className="page-heading__left">
          <div className="avatar mt-3x">
            {student.avatar ? (
              <img src={student.avatar} alt="Student" />
            ) : (
              getNameInitials(student)
            )}
          </div>
          <div className="heading-detail">
            <span className="text-light-sm fs-14">
              Student Profile | {subdomainName} Learning
            </span>
            <h2>{getFullName(student)}</h2>

            <ul
              className={classNames("heading-info", {
                "round round--horizontal":
                  student?.userData?.schoolInfo?.grade &&
                  student?.userData?.schoolInfo?.institution?.name,
              })}
            >
              <li className="">
                {student?.userData?.schoolInfo?.grade
                  ? `Grade ${student?.userData?.schoolInfo?.grade}`
                  : null}
              </li>
              <li>
                <span className="link-item link-text">
                  {student?.userData?.schoolInfo?.institution?.name}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="page-heading__right">
          <button
            onClick={openAddAssignmentModal}
            className="btn btn--primary mr-2x"
            type="button"
          >
            Add Missing Assignment
          </button>
        </div>
      </div>
      <div className="section-heading">
        <h3>Missing Assignments</h3>
      </div>
      {fetchAssignmentsLoading && !assignments.length ? (
        <Loader type="ThreeDots" />
      ) : (
        <AssignmentList
          assignments={assignments}
          updateAssignment={updateAssignment}
          fetchAssignments={fetchAssignmentsData}
          student={student}
          deleteAssignment={deleteAssignment}
        />
      )}
    </>
  );
};

interface State {
  assignments: {
    fetchAssignments: IAssignment[];
    fetchAssignmentsLoading: boolean;
    updateAssignmentLoading: boolean;
  };
}

const mapStateToProps = (state: State) => ({
  assignments: state.assignments.fetchAssignments,
  fetchAssignmentsLoading: state.assignments.fetchAssignmentsLoading,
  updateAssignmentLoading: state.assignments.updateAssignmentLoading,
});

const mapDispatchToProps = {
  fetchAssignments,
  updateAssignment,
  deleteAssignment,
};
export default connect(mapStateToProps, mapDispatchToProps)(Assignments);
