import React from "react";
import { useFormik } from "formik";

import { IUser } from "commons/types/users";
import { studentSchema } from "commons/schemas/student";
import { TextAreaInput } from "commons/components/form-fields";
interface IUpdateStudentDiagnosisProps {
  student: IUser;
  closeModal: () => void;
  editInformation: (payload: IUser) => void;
}
const UpdateStudentDiagnosis: React.FC<IUpdateStudentDiagnosisProps> = ({
  student,
  closeModal,
  editInformation,
}) => {
  const formik = useFormik({
    initialValues: student,
    onSubmit: async (values) => {
      try {
        await editInformation(values);
        closeModal();
      } catch (err: any) {}
    },
    validationSchema: studentSchema,
  });
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Please add your notes here</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body pb-0x">
          <div className="row">
            <div className="col-12">
              <TextAreaInput
                name="userData.diagnosis"
                placeholder=""
                handleChange={formik.handleChange}
                values={formik.values.userData?.diagnosis}
                errors={formik.errors}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                label=""
              />
            </div>
          </div>
        </div>
        <div className="modal-wrap__footer pt-0x">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={
                  formik.isSubmitting || formik.values === formik.initialValues
                }
              >
                Add
              </button>
              <button
                disabled={formik.isSubmitting}
                onClick={closeModal}
                className="btn txt-primary-color"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateStudentDiagnosis;
