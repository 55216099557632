import { Dispatch } from "redux";

import { error } from "utils/toast";
import * as tenantService from "services/tenant";
import {
  FetchDistrictsActionType,
  FetchSchoolsActionType,
} from "../types/institution";
import { IInstitutionParams } from "commons/types/institutions";

export const fetchDistricts = () => async (dispatch: Dispatch) => {
  dispatch({ type: FetchDistrictsActionType.FETCH_DISTRICT_PENDING });
  try {
    const { data } = await tenantService.fetchDistricts();
    dispatch({
      type: FetchDistrictsActionType.FETCH_DISTRICT_FULFILLED,
      data: data,
    });
  } catch (err: any) {
    dispatch({
      type: FetchDistrictsActionType.FETCH_DISTRICT_REJECTED,
      data: err,
    });

    error("Failed to fetch districts");
  }
};

export const fetchSchools =
  (params: IInstitutionParams) => async (dispatch: Dispatch) => {
    dispatch({ type: FetchSchoolsActionType.FETCH_SCHOOLS_PENDING });
    try {
      const { data } = await tenantService.fetchInstitutions(params);
      dispatch({
        type: FetchSchoolsActionType.FETCH_SCHOOLS_FULFILLED,
        data: data,
      });
    } catch (err: any) {
      dispatch({
        type: FetchSchoolsActionType.FETCH_SCHOOLS_REJECTED,
        data: err,
      });

      error("Failed to fetch schools");
    }
  };
