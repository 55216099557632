import Form from "./Form";
import { useTranslation } from "react-i18next";
import { IUpdateParams } from "./updateparams.interface";

const MentorUpdate = (props: IUpdateParams) => {
  const { t } = useTranslation();

  return (
    <div className="modal-wrap">
      <div className="modal-wrap__header">
        <h3>
          {props.isFromIntakeManager
            ? t("mentorForm.editFormTitle")
            : t("mentorForm.editProfileTitle")}
        </h3>
        <span className="link-item" onClick={props.closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <Form {...props} />
    </div>
  );
};
export default MentorUpdate;
