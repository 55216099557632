import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select, { components } from "react-select";

import Loader from "commons/components/Loader";
import { fetchTerms } from "store/actions/data/terms";
import { ITerm } from "commons/types/terms";
import {
  ISurvey,
  ISurveyInitiate,
  ISurveyInitaiteResponders,
} from "commons/types/surveys";
import { ISponsor, IUser } from "commons/types/users";
import { fetchMentorStudents } from "store/actions/data/students";
import {
  sendSurveyInfo,
  getSurveyCategories,
} from "store/actions/data/surveys";
import { SurveyTypes } from "commons/types/surveys";
import { ISelectOption } from "commons/types/form";
import { identifyIsTermCurrentPastOrFuture } from "utils/term";
import ValidationErrorMessage from "./ValidationErrorMessage";
import SurveySendConfirm from "./SurveySendConfirm";
import StudentParentCheckSection from "./StudentParentCheckSection";
import StudentParentCheckBulkSection from "./StudentParentCheckBulkSection";

interface Props {
  terms: ITerm[];
  fetchTerms: () => void;
  toggleAssessmentForm: () => void;
  sendSurveyInfo: (payload: ISurveyInitiate) => Promise<void>;
  fetchMentorStudents: (id: string) => Promise<IUser[]>;
  getSurveyCategories: () => void;
  students: IUser[];
  fetchStudentsLoading: boolean;
  surveyCategories: ISurvey[];
  surveyInitiateSuccess: boolean;
  surveyInitiateLoading: boolean;
  onAssessmentSentSuccess?: () => void;
}

const surveyTypes = [
  { label: SurveyTypes.INITIAL_SURVEY, value: SurveyTypes.INITIAL },
  { label: SurveyTypes.FINAL_SURVEY, value: SurveyTypes.FINAL },
];

const SendAssessmentForm: React.FC<Props> = ({
  toggleAssessmentForm,
  fetchTerms,
  sendSurveyInfo,
  fetchMentorStudents,
  getSurveyCategories,
  surveyCategories,
  terms,
  students,
  fetchStudentsLoading,
  surveyInitiateSuccess,
  surveyInitiateLoading,
  onAssessmentSentSuccess,
}) => {
  const [sendConfirmOpen, setSendConfirmOpen] = useState<boolean>(false);
  const [sendToMentor, setSendToMentor] = useState<boolean>(true);
  const [responders, setResponders] = useState<ISurveyInitaiteResponders[]>([]);
  const [totalParents, setTotalParents] = useState<string[]>([]);
  const [totalParentsResponders, setTotalParentsFromResponders] = useState<
    string[]
  >([]);
  const [totalStudentsResponders, setTotalStudentsFromResponders] = useState<
    string[]
  >([]);
  const [isInvalidSubmission, setIsInvalidSubmission] =
    useState<boolean>(false);

  const newStudents = students.map((student: IUser, index) => {
    return { ...student, mapId: index };
  });

  const user = localStorage.getItem("profile");
  let currentUser: any = {};
  if (user) {
    try {
      currentUser = JSON.parse(user);
    } catch (err: any) {
      console.error(err);
    }
  }

  const practitionerId = currentUser._id;

  const [assessmentFormData, setAssessmentFormData] = useState<ISurveyInitiate>(
    {
      term: "",
      surveyType: "",
      category: "",
      responders: [],
      createdDate: new Date(),
      createdBy: practitionerId,
    },
  );

  useEffect(() => {
    fetchTerms();
    getSurveyCategories();
  }, [fetchTerms, getSurveyCategories]);

  useEffect(() => {
    students.length === 0 && fetchMentorStudents(practitionerId);
  }, []);

  useEffect(() => {
    setAssessmentFormData({
      ...assessmentFormData,
      responders: responders,
    });
    calculateTotalParentsFromResponders();
    calculateTotalStudentsFromResponders();
  }, [responders]);

  useEffect(() => {
    calculateTotalParents();
  }, [students]);

  useEffect(() => {
    surveyInitiateSuccess && toggleAssessmentForm();
    setSendConfirmOpen(false);
  }, [surveyInitiateSuccess]);

  const calculateTotalParents = () => {
    const totalParentsIds = students.flatMap(
      (student: IUser) =>
        student?.relatedUsers?.sponsors &&
        student?.relatedUsers?.sponsors.map((sponsor: any) => {
          return sponsor.userId._id;
        }),
    );
    setTotalParents(totalParentsIds);
  };

  const calculateTotalParentsFromResponders = () => {
    const totalParentsIds = responders.flatMap(
      (responder: ISurveyInitaiteResponders) =>
        responder?.sponsors &&
        responder?.sponsors.map((sponsor: any) => {
          return sponsor;
        }),
    );
    setTotalParentsFromResponders(totalParentsIds);
  };

  const calculateTotalStudentsFromResponders = () => {
    const totalStudentIds: string[] = responders.map(
      (responder: ISurveyInitaiteResponders) => {
        return responder.subject;
      },
    );
    setTotalStudentsFromResponders(totalStudentIds);
  };

  const handleFormSubmit = async () => {
    const newResponders: ISurveyInitaiteResponders[] = [];
    //handling if to send mentor id or not according to Send to mentor checkbox at the bottom of form.
    if (sendToMentor) {
      responders.map((responder) =>
        newResponders.push({
          sponsors: responder.sponsors,
          subject: responder.subject,
          practitioners: [practitionerId],
        }),
      );
    } else {
      responders.map((responder) =>
        newResponders.push({
          sponsors: responder.sponsors,
          subject: responder.subject,
          practitioners: [],
        }),
      );
    }

    await sendSurveyInfo({
      ...assessmentFormData,
      responders: newResponders,
    });

    onAssessmentSentSuccess && onAssessmentSentSuccess();
  };

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <div className="text-small">{props.data.subLabel}</div>
      </components.Option>
    );
  };

  // const termsOption: ISelectOption[] = () => {
  //    const activeEnrollment = student.userData?.enrollments?.filter(
  //       (enrollment) => enrollment.status !== "Draft",
  //     );

  //     if (activeEnrollment) {
  //       const activeEnrollmentWithTerms = activeEnrollment.filter(
  //         (enrollment) => enrollment.enrolledTerm,
  //       );
  //       return activeEnrollmentWithTerms.map((enrollment) => {
  //         return {
  //           label: enrollment.enrolledTerm.name,
  //           subLabel: identifyIsTermCurrentPastOrFuture(
  //             enrollment.enrolledTerm,
  //           ),
  //           value: enrollment.enrolledTerm._id,
  //         };
  //       });
  //     }
  //     return [];
  // };
  const termsOption: ISelectOption[] = terms.map((term) => {
    return {
      label: term.name,
      subLabel: identifyIsTermCurrentPastOrFuture(term),
      value: term._id,
    };
  });

  const surveyCategoriesOption: ISelectOption[] = surveyCategories.map(
    (survey) => {
      return { label: survey.name, value: survey._id };
    },
  );

  //Function used when a single student is selected using checkbox.

  const handleStudentCheck = (
    student: IUser & { mapId: number },
    event: any,
  ) => {
    const currentResponder = responders.find(
      (responder) => responder?.subject === student?._id,
    );
    const arrayWithoutCurrentUser = responders.filter(
      (responder) => responder?.subject !== student?._id,
    );
    if (event.target.checked) {
      if (
        responders.filter((resp) => resp.subject !== student._id).length === 1
      ) {
        setResponders([
          ...responders,
          {
            subject: student._id || "",
            sponsors: [],
            practitioners: [],
          },
        ]);
      } else {
        setResponders([
          ...responders,
          {
            subject: student._id || "",
            sponsors: currentResponder?.sponsors || [],
            practitioners: [],
          },
        ]);
      }
    } else {
      setResponders([...arrayWithoutCurrentUser]);
    }
  };

  //Function used when a single parent is selected using checkbox.

  const handleParentsCheck = (
    sponsor: ISponsor,
    currentStudent: IUser & { mapId: number },
    event: any,
  ) => {
    const newResponders = responders.filter(
      (responder) => responder.subject !== currentStudent._id,
    );

    const currentResponder = responders.find(
      (responder: ISurveyInitaiteResponders) =>
        responder.subject === currentStudent?._id,
    );

    if (event.target.checked) {
      const newSponsors = currentResponder ? currentResponder.sponsors : [];
      newSponsors.push(sponsor.userId._id || "");
      setResponders([
        ...newResponders,
        {
          subject: currentStudent.id || "",
          sponsors: newSponsors || [],
          practitioners: [],
          mapId: currentStudent.mapId,
        },
      ]);
    } else {
      const currentSponsors = currentResponder ? currentResponder.sponsors : [];
      const newSponsors = currentSponsors.filter(
        (sponsr) => sponsr !== sponsor.userId._id,
      );
      const studentFromResponder = responders.find(
        (responder) =>
          responder.subject === currentStudent._id &&
          responder.mapId === currentStudent.mapId,
      );
      setResponders([
        ...newResponders,
        {
          practitioners: [],
          subject: studentFromResponder?.subject || "",
          sponsors: newSponsors,
          mapId: currentStudent.mapId,
        },
      ]);
    }
  };

  //For Bulk students select

  const handleStudentBulkSelect = () => {
    const allStudentsResponders: ISurveyInitaiteResponders[] = [];
    const allStudentsIds: string[] = newStudents.map((student: IUser) => {
      return student?._id || "";
    });
    newStudents.map((student) => {
      const studentSponsors = student.relatedUsers?.sponsors || [];
      if (totalParentsResponders.length === totalParents.length) {
        allStudentsResponders.push({
          mapId: student.mapId,
          sponsors: studentSponsors.map((sponsor) => {
            return sponsor.userId._id;
          }),
          subject: student?._id || "",
          practitioners: [],
        });
      } else {
        allStudentsResponders.push({
          mapId: student.mapId,
          sponsors: [],
          subject: student?._id || "",
          practitioners: [],
        });
      }
    });
    if (totalStudentsResponders.length === students.length) {
      setTotalStudentsFromResponders([]);
      setTotalParentsFromResponders([]);
      setResponders([]);
    } else {
      setTotalStudentsFromResponders(allStudentsIds);
      setResponders(allStudentsResponders);
    }
  };

  //For bulk parents select

  const handleBulkParentsSelect = () => {
    const allParentFromResponders: ISurveyInitaiteResponders[] = [];
    newStudents.map((student) => {
      const studentSponsors = student.relatedUsers?.sponsors || [];
      if (studentSponsors.length > 0 || responders.length === students.length) {
        allParentFromResponders.push({
          mapId: student.mapId,
          sponsors: studentSponsors.map((sponsor) => {
            return sponsor.userId._id;
          }),
          subject: student._id || "",
          practitioners: [],
        });
      }
    });
    if (totalParentsResponders.length === totalParents.length) {
      setTotalParentsFromResponders([]);
      setResponders([]);
    } else {
      setResponders(allParentFromResponders);
    }
  };

  const handleFormData = (value: string, state: string) => {
    setAssessmentFormData({
      ...assessmentFormData,
      [state]: value,
    });
  };

  const handleOnSendClick = () => {
    if (assessmentFormValidate(assessmentFormData)) {
      setIsInvalidSubmission(true);
    } else {
      setIsInvalidSubmission(false);
      setSendConfirmOpen(true);
    }
  };

  const assessmentFormValidate = (assessmentFormData: ISurveyInitiate) => {
    if (
      assessmentFormData.category === "" ||
      assessmentFormData?.responders?.length === 0 ||
      assessmentFormData?.surveyType === "" ||
      assessmentFormData.term === ""
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className=" modal-wrap__body">
        <div className="section__content">
          <div className="row">
            <div className="col-6">
              <div className="input-wrap">
                <label className={`input__label required`}>Term</label>
                <Select
                  name="term"
                  placeholder="Select a term"
                  options={termsOption}
                  components={{ Option }}
                  onChange={(e: any) => {
                    handleFormData(e.value, "term");
                  }}
                ></Select>
              </div>
            </div>
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label required">
                  Select Survey Type
                </label>
                <Select
                  name="surveyType"
                  placeholder="Survey Term"
                  options={surveyTypes}
                  onChange={(e: any) => {
                    handleFormData(e.value, "surveyType");
                  }}
                  required={true}
                ></Select>
              </div>
            </div>
          </div>
          <div className="row-6">
            <div className="input-wrap">
              <label className={`input__label required`}>Select Survey</label>
              <Select
                name="category"
                placeholder="Survey Name"
                options={surveyCategoriesOption}
                onChange={(e: any) => {
                  handleFormData(e.value, "category");
                }}
              ></Select>
            </div>
          </div>
          {fetchStudentsLoading ? (
            <Loader type="ThreeDots" />
          ) : (
            <div className="mb-20 send-assessment-check">
              <StudentParentCheckBulkSection
                handleBulkParentsSelect={handleBulkParentsSelect}
                handleStudentBulkSelect={handleStudentBulkSelect}
                totalParents={totalParents}
                totalParentsResponders={totalParentsResponders}
                students={students}
                responders={responders}
              />
              <div className="send-assessment-check__content">
                {newStudents.map((student: IUser & { mapId: number }) => {
                  return (
                    <StudentParentCheckSection
                      student={student}
                      handleParentsCheck={handleParentsCheck}
                      handleStudentCheck={handleStudentCheck}
                      responders={responders}
                      totalStudentsResponders={totalStudentsResponders}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div className="row-6 mt-8x">
            <div className="input-wrap">
              <input
                type="checkbox"
                name="checkbox"
                value="Checkbox Inline 2"
                id="check2"
                className="form-group__radio mr-10"
                checked={sendToMentor}
                onChange={() => setSendToMentor(!sendToMentor)}
              />
              <label htmlFor="check2" className="box-links__nodes">
                Send this survey for mentor
              </label>
            </div>
          </div>
          <ValidationErrorMessage
            assessmentFormData={assessmentFormData}
            isInvalidSubmission={isInvalidSubmission}
          />
        </div>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <button
            onClick={handleOnSendClick}
            className="btn btn--primary mr-4x"
            disabled={fetchStudentsLoading}
          >
            Send
          </button>
          <button onClick={toggleAssessmentForm} className="btn" type="button">
            Cancel
          </button>
        </div>
      </div>
      <SurveySendConfirm
        handleFormSubmit={handleFormSubmit}
        setSendConfirmOpen={setSendConfirmOpen}
        surveyInitiateLoading={surveyInitiateLoading}
        sendConfirmOpen={sendConfirmOpen}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  terms: state.terms.fetchTerms,
  surveyCategories: state.surveys.surveyCategories,
  surveyLoading: state.surveys.surveySendingLoading,
  students: state.students.fetchStudents,
  fetchStudentsLoading: state.students.fetchStudentsLoading,
  surveyInitiateSuccess: state.surveys.surveyInitiateSuccess,
  surveyInitiateLoading: state.surveys.surveyInitiateLoading,
});

const mapDispatchToProps = {
  fetchTerms,
  sendSurveyInfo,
  fetchMentorStudents,
  getSurveyCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendAssessmentForm);
