import "boxicons";
import dayjs from "dayjs";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useEffect, useState, useCallback } from "react";

import Menu from "./Menu";
import AddTask from "../add";
import UpdateTask from "../update";
import eventBus from "utils/event-bus";
import { IGoal } from "commons/types/goals";
import { toggleInArray } from "utils/arrays";
import { error, success } from "utils/toast";
import ConfirmSuccess from "./ConfirmSuccess";
import TaskDetail from "../detail/TaskDetail";
import Loader from "commons/components/Loader";
import { ITaskList } from "./taskList.interface";
import tasksImage from "assets/images/tasks.svg";
import useChatHook from "commons/hooks/useChatHook";
import { UowDeleteEditType } from "constants/index";
import { IGoal as ITask } from "commons/types/goals";
import { checkDropDownPosition } from "utils/dropdown";
import useMountedRef from "commons/hooks/useMountedRef";
import DeleteConfirm from "commons/components/DeleteConfirm";
import { IWeeksTasks } from "../../plans/list/plans.interface";
import InCompleteConfirm from "commons/components/IncompleteConfirm";
import MessageAttachment from "commons/components/message-attachment";
import SelectRecurrenceDeleteEditType from "commons/components/SelectRecurrenceDeleteEditType";
import {
  getDayFromDate,
  getDateInFormat,
  getPlanningPeriodByDate,
} from "utils/dates";
import {
  updateTask,
  deleteTask,
  markAsIncomplete,
} from "store/actions/data/tasks";
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemHeading,
} from "react-accessible-accordion";
import { isStudentLoggedIn } from "utils/window";

const TaskList: React.FC<ITaskList> = ({
  goal,
  tasks,
  loading,
  student,
  saveTask,
  deleteTask,
  updateTask,
  markAsIncomplete,
  handleBulkTaskUpdate,
  handleBulkTaskDelete,
  initialWeeks,
  currentWeekTasks,
  planningPeriods,
  fetchTasks,
}: ITaskList) => {
  const user = localStorage.getItem("profile");
  const currentUser: any = user ? JSON.parse(user) : null;

  const { showChatHistoryOfAChannel } = useChatHook();
  const isMounted = useMountedRef();

  const [openMenu, setOpenMenu] = useState(false);
  const [isDeleteTaskOpen, setIsDeleteTaskOpen] = useState(false);
  const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);
  const [isDuplicateTaskOpen, setIsDuplicateTaskOpen] = useState(false);
  const [actionTask, setActionTask] = useState<null | IGoal>(null);
  const [isMarkAsIncompleteOpen, setIsMarkAsIncompleteOpen] = useState(false);
  const [bulkTransactionLoading, setBulkTransactionLoading] =
    useState<boolean>(false);
  const [isBulkDeleteTaskOpen, setIsBulkDeleteTaskOpen] = useState(false);
  const [isTaskSuccessOpen, setIsTaskSuccessOpen] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [actionTaskGroup, setActionTaskGroup] = useState<null | string>(null);
  const [triggeredBulkTaskAction, setTriggeredBulkTaskAction] =
    useState<string>("");
  const [isMenuTop, setMenuTop] = useState<boolean>(false);
  const [channel, setChannel] = useState(null);
  const [weeksTasks, setWeeksTasks] = useState<IWeeksTasks>({
    ...initialWeeks,
  });
  const [showDeleteTaskOptions, setShowDeleteTaskOptions] = useState(false);
  const [isTaskDetailOpen, setIsTaskDetailOpen] = useState<null | ITask>(null);
  const [selectedDeleteType, setSelectedDeleteType] = useState(
    UowDeleteEditType.SELF,
  );

  const setCurrentChannel = async (data: any) => {
    setChannel(data.channel);
  };

  useEffect(() => {
    eventBus.on("chatChannelChanged", setCurrentChannel);

    return () => {
      eventBus.remove("chatChannelChanged", setCurrentChannel);
    };
  }, []);

  const getCreator = (task: any) => {
    return task?.createdBy?._id === currentUser._id
      ? "you"
      : task?.createdBy?.firstName;
  };

  // Deleting recurringTask parent id it was blocking task being recurred from child task
  const toggleOpenMenu = (task: IGoal) => {
    const newState = !openMenu;
    if (newState) {
      delete task.recurringTaskParent;
      setActionTask(task);
    }
    setOpenMenu(newState);
  };

  const closeMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  const updateTaskHandler = async (id: string, task: IGoal) => {
    const updatedTask = await updateTask(id, task);

    if (updatedTask) {
      fetchTasks();
      setIsEditTaskOpen(false);
      setOpenMenu(false);
      handleBulkActionReset();
    }
  };

  const completeTaskHandler = async (id: string) => {
    const updatedTask = await handleBulkTaskUpdate({
      ids: [id],
      isCompleted: true,
    });

    if (updatedTask) {
      fetchTasks();
      setOpenMenu(false);
      setIsTaskDetailOpen(null);
      setIsTaskSuccessOpen(true);
      handleBulkActionReset();
    }
  };

  const publishTaskHandler = async (id: string) => {
    const updatedTask = await updateTask(id, { isPublished: true });

    if (updatedTask) {
      fetchTasks();
      setOpenMenu(false);
      setIsTaskDetailOpen(null);
      handleBulkActionReset();
    }
  };

  const deleteTaskHandler = async (
    id: string,
    type: UowDeleteEditType = UowDeleteEditType.SELF,
  ) => {
    const deletedTask = await deleteTask(id, type);

    if (deletedTask && isMounted) {
      fetchTasks();
      setIsDeleteTaskOpen(false);
      setOpenMenu(false);
      setSelectedDeleteType(UowDeleteEditType.SELF);
      handleBulkActionReset();
    }
  };

  useEffect(() => {
    if (openMenu) {
      document.addEventListener("click", closeMenu);
    } else {
      document.removeEventListener("click", closeMenu);
    }

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [openMenu, closeMenu]);

  const openEditMenu = (task: ITask) => {
    setActionTask(task);
    setIsEditTaskOpen(true);
  };

  useEffect(() => {
    let tasks: IWeeksTasks = { ...initialWeeks };
    currentWeekTasks.map((task: ITask) => {
      const day: string = getDateInFormat(task.requiredDeadline, "MM-DD-YYYY");

      tasks[day] = {
        date: task.requiredDeadline,
        checked: false,
        tasks: tasks[day] ? [...tasks[day].tasks, task] : [task],
      };

      return tasks;
    });

    setWeeksTasks(tasks);
  }, [currentWeekTasks, initialWeeks]);

  const checkTaskBulkActionDisabled = (task: any) => {
    // Disable check when bulk action in progress
    if (bulkTransactionLoading) return true;

    // Enable check if none selected or if action triggered is delete only
    if (!triggeredBulkTaskAction || triggeredBulkTaskAction === "delete")
      return false;

    // Disable if task is already complete and the action is not delete
    if (task.isCompleted) return true;

    // Disable if action is to complete but the task is in DRAFT
    if (triggeredBulkTaskAction === "complete" && !task.isPublished)
      return true;

    // Disable if the task is already published and the action is to publish
    if (
      task._id &&
      task.isPublished &&
      triggeredBulkTaskAction === "publish" &&
      !selectedTasks.includes(task._id)
    )
      return true;

    return false;
  };

  const bulkPublishTasks = async () => {
    setBulkTransactionLoading(true);
    try {
      const result = await handleBulkTaskUpdate({
        ids: selectedTasks,
        isPublished: true,
      });

      if (result) handleBulkActionReset();
    } catch (err: any) {
      error("Failed to publish tasks");
    }
    setBulkTransactionLoading(false);
  };

  const bulkCompleteTasks = async () => {
    setBulkTransactionLoading(true);
    try {
      const result = await handleBulkTaskUpdate({
        ids: selectedTasks,
        isCompleted: true,
      });

      if (result) {
        setIsTaskSuccessOpen(true);
        handleBulkActionReset();
      }
    } catch (err: any) {
      error("Failed to mark tasks as completed");
    }
    setBulkTransactionLoading(false);
  };

  const handleBulkDelete = async () => {
    setBulkTransactionLoading(true);
    try {
      const result = await handleBulkTaskDelete(selectedTasks);

      if (result) {
        success("Tasks deleted");
        setIsBulkDeleteTaskOpen(false);
        handleBulkActionReset();
      }
    } catch (err: any) {
      error("Failed to delete tasks");
    }
    setBulkTransactionLoading(false);
  };

  const handleNewSelectedTasks = (
    newSelectedTasks: string[],
    actionType: string,
  ) => {
    if (newSelectedTasks.length < 1) {
      return handleBulkActionReset();
    }

    setTriggeredBulkTaskAction(actionType);
    setSelectedTasks(newSelectedTasks);
  };

  const handleWeekDayCheck = (day: string) => {
    const newDayCheck = !weeksTasks[day].checked;
    const newTasks = { ...weeksTasks };
    newTasks[day] = {
      ...weeksTasks[day],
      checked: newDayCheck,
    };

    let newTriggeredActionType = "";
    const newSelectedTasks = [...selectedTasks];
    if (newDayCheck) {
      newTriggeredActionType = "complete";
      weeksTasks[day].tasks.forEach((task: any) => {
        if (
          task._id &&
          !newSelectedTasks.includes(task._id) &&
          !task.isCompleted
        )
          newSelectedTasks.push(task._id);
      });
    } else {
      weeksTasks[day].tasks.forEach((task: any) => {
        if (task._id && newSelectedTasks.includes(task._id))
          newSelectedTasks.splice(newSelectedTasks.indexOf(task._id), 1);
      });
    }

    setWeeksTasks(newTasks);
    handleNewSelectedTasks(newSelectedTasks, newTriggeredActionType);
  };

  const handleBulkActionReset = () => {
    setTriggeredBulkTaskAction("");
    setSelectedTasks([]);
  };

  const handleSaveTask = async (data: IGoal) => {
    const task = await saveTask(data);

    if (task) {
      setIsDuplicateTaskOpen(false);
    }
  };

  const markAsIncompleteHandler = async (id: string) => {
    const task = await markAsIncomplete(id);
    if (task) {
      setIsMarkAsIncompleteOpen(false);
      setIsTaskDetailOpen(null);
      fetchTasks();
    }
  };

  const handleSingleCheckBulkEffect = (selectedTasks: string[]) => {
    const newTasks = { ...weeksTasks };
    Object.keys(weeksTasks).map((day) => {
      if (
        weeksTasks[day].tasks.length &&
        weeksTasks[day].tasks.every(
          (task: ITask) => task._id && selectedTasks.includes(task._id),
        )
      ) {
        newTasks[day] = {
          ...weeksTasks[day],
          checked: true,
        };
      } else {
        newTasks[day] = {
          ...weeksTasks[day],
          checked: false,
        };
      }

      return day;
    });
    setWeeksTasks(newTasks);
  };

  const handleCheck = (task: ITask) => {
    if (task.isCompleted) {
      setActionTask(task);
      setIsMarkAsIncompleteOpen(true);
      return;
    }
    const newTriggeredActionType = !task.isPublished
      ? "publish"
      : !task.isCompleted
      ? "complete"
      : "delete";
    const newSelectedTasks = [...toggleInArray(selectedTasks, task._id)];

    handleSingleCheckBulkEffect(newSelectedTasks);

    handleNewSelectedTasks(newSelectedTasks, newTriggeredActionType);
  };

  const menuElement = (
    task: ITask & { recurrenceType: string },
    group: string,
  ) => (
    <span
      className="link-item dropdown-button"
      onClick={(event) => {
        setMenuTop(checkDropDownPosition(event));
        toggleOpenMenu(task);
        setActionTaskGroup(group);
      }}
    >
      <box-icon name="dots-horizontal-rounded" />
      {openMenu && actionTask?._id === task._id && actionTaskGroup === group && (
        <Menu
          deleteMenu={() => {
            task.recurrenceType === "Non-Repeat"
              ? setIsDeleteTaskOpen(true)
              : setShowDeleteTaskOptions(true);
          }}
          showDetail={() => {
            setIsTaskDetailOpen(task);
          }}
          incompleteTask={
            task.isPublished && task.isCompleted
              ? () => task._id && markAsIncompleteHandler(task._id)
              : null
          }
          completeTask={
            task.isPublished && !task.isCompleted
              ? () => task._id && completeTaskHandler(task._id)
              : null
          }
          editMenu={() => openEditMenu(task)}
          duplicateTask={() => setIsDuplicateTaskOpen(true)}
          isMenuTop={isMenuTop}
        />
      )}
    </span>
  );

  const RecurrenceDetail = ({ title }: { title: String }) => {
    return (
      <div className="recurrence-details mt-1x mb-1x">
        <box-icon name="repeat" color="#666666" class="mr-1x"></box-icon>
        <span>{title}</span>
      </div>
    );
  };

  const GetDay = ({ task }: { task: IGoal }) => {
    return (
      <>
        {getDayFromDate(task.requiredDeadline, true) === "Today" ? (
          <p className="color-tertiary-red-80">Today</p>
        ) : (
          <p>{getDayFromDate(task.requiredDeadline, true)}</p>
        )}
      </>
    );
  };

  const checkTasksComplete = (task: IGoal[]) => {
    return task.every((task: any) => task.isCompleted);
  };

  return (
    <div className="accordion">
      <div className="accordion-item">
        <div className="accordion-item__content">
          {loading ? (
            <Loader type="ThreeDots" />
          ) : !tasks || tasks?.length < 1 ? (
            <div className="empty-section">
              <div className="empty-section__content">
                <img src={tasksImage} alt="Tasks" />
                <h3>You currently do not have any tasks.</h3>
                <p>
                  Go out and have some fun, or create a new task to get some
                  work done.
                </p>
              </div>
            </div>
          ) : (
            <>
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                className="accordion"
                preExpanded={["overdue", ...Object.keys(weeksTasks)]}
              >
                {Object.keys(weeksTasks).map((day) => (
                  <AccordionItem
                    uuid={day}
                    key={day}
                    className="accordion-item"
                  >
                    <AccordionItemState>
                      {({ expanded }) => (
                        <>
                          <AccordionItemHeading className="">
                            <AccordionItemButton className="accordion-item__title">
                              <div className="title-left">
                                <div
                                  className={
                                    checkTasksComplete(weeksTasks[day].tasks)
                                      ? "input-wrap mb-8x button-disabled"
                                      : "input-wrap mb-8x"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id={day}
                                    className="form-group__radio mr-10"
                                    checked={
                                      !!weeksTasks[day].tasks.length &&
                                      (weeksTasks[day].checked ||
                                        checkTasksComplete(
                                          weeksTasks[day].tasks,
                                        ))
                                    }
                                    onChange={() => {
                                      handleWeekDayCheck(day);
                                    }}
                                    disabled={checkTasksComplete(
                                      weeksTasks[day].tasks,
                                    )}
                                  />
                                  <label
                                    htmlFor={day}
                                    className="box-links__nodes"
                                  ></label>
                                </div>
                                <span>{getDayFromDate(day)}</span>
                                <span className="ml-2x">
                                  {dayjs(weeksTasks[day].date).format("MM/DD")}
                                </span>
                              </div>
                              <div className="title-right">
                                <span>
                                  <box-icon
                                    name={
                                      !expanded ? "chevron-down" : "chevron-up"
                                    }
                                  ></box-icon>
                                </span>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="accordion-item__content">
                            {weeksTasks[day].tasks.map((task: any) => (
                              <div
                                key={task._id}
                                className={`card ${
                                  task?.isCompleted ? "card--success" : ""
                                }`}
                              >
                                <div className="card-content">
                                  <div
                                    className={
                                      task.isCompleted ||
                                      checkTaskBulkActionDisabled(task)
                                        ? "input-wrap button-disabled mr-2x"
                                        : "input-wrap mr-2x"
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      name="checkbox"
                                      value="Checkbox Inline 2"
                                      id={task._id}
                                      checked={
                                        task._id &&
                                        (selectedTasks.includes(task._id) ||
                                          task.isCompleted)
                                          ? true
                                          : false
                                      }
                                      onChange={() => handleCheck(task)}
                                      disabled={checkTaskBulkActionDisabled(
                                        task,
                                      )}
                                      className="form-group__radio mr-10"
                                    />
                                    <label
                                      htmlFor={task._id}
                                      className="box-links__nodes"
                                    ></label>
                                  </div>
                                  <div className="card-text">
                                    <h3
                                      className={
                                        task.isCompleted
                                          ? "link-item txt-darkgrey-color"
                                          : "link-item"
                                      }
                                      onClick={() => setIsTaskDetailOpen(task)}
                                    >
                                      {task.name}
                                    </h3>
                                    {task.recurrenceType === "Weekly" && (
                                      <RecurrenceDetail
                                        title={`Weekly on  ${day}`}
                                      />
                                    )}
                                    {task.recurrenceType === "Daily" && (
                                      <RecurrenceDetail title={`Daily`} />
                                    )}
                                    <ul className="card-info">
                                      <li className="duedate warning-icon">
                                        {task.workData?.priority ===
                                          "Priority" && (
                                          <box-icon
                                            name="error-alt"
                                            color="#ff1154"
                                          ></box-icon>
                                        )}
                                        <GetDay task={task} />
                                      </li>
                                      {task.workData?.optionalDeadline ? (
                                        <li className="ml-4x">
                                          <box-icon name="calendar"></box-icon>
                                          {"Due "}
                                          <strong className="ml-2x">
                                            {getDateInFormat(
                                              task.workData?.optionalDeadline,
                                            )}
                                          </strong>
                                        </li>
                                      ) : null}
                                      <li>
                                        {getCreator(task) !== "you" &&
                                          `Added by ${getCreator(task)}`}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="card-actions">
                                  {!isStudentLoggedIn() && (
                                    <MessageAttachment
                                      channel={channel}
                                      showChatHistoryOfAChannel={
                                        showChatHistoryOfAChannel
                                      }
                                      attributes={{
                                        _id: task._id || "",
                                        name: task.name,
                                        uowType: task.uowType,
                                        childOf: task.childOf?._id,
                                        assignee: student?._id || "",
                                      }}
                                      receiver={goal.assignee}
                                    />
                                  )}
                                  {menuElement(task, day)}
                                </div>
                              </div>
                            ))}
                          </AccordionItemPanel>
                        </>
                      )}
                    </AccordionItemState>
                  </AccordionItem>
                ))}
              </Accordion>
            </>
          )}
        </div>

        <Modal
          className="modal-block"
          isOpen={showDeleteTaskOptions}
          ariaHideApp={false}
          onRequestClose={() => setShowDeleteTaskOptions(false)}
        >
          <SelectRecurrenceDeleteEditType
            onCancel={() => setShowDeleteTaskOptions(false)}
            onTypeSelected={(type: UowDeleteEditType) => {
              setSelectedDeleteType(type);
              setIsDeleteTaskOpen(true);
              setShowDeleteTaskOptions(false);
            }}
          />
        </Modal>

        <Modal
          isOpen={isEditTaskOpen}
          className="modal-block-task"
          ariaHideApp={false}
          onRequestClose={() => setIsEditTaskOpen(false)}
        >
          {actionTask === null ? (
            <Loader type="ThreeDots" />
          ) : (
            <UpdateTask
              goal={goal}
              saveTask={updateTaskHandler}
              closeModal={() => setIsEditTaskOpen(false)}
              task={actionTask}
              student={student}
            />
          )}
        </Modal>
        <Modal
          isOpen={isDuplicateTaskOpen}
          className="modal-block-task"
          ariaHideApp={false}
          onRequestClose={() => setIsDuplicateTaskOpen(false)}
        >
          {actionTask === null ? (
            <Loader type="ThreeDots" />
          ) : (
            <AddTask
              goal={goal}
              saveTask={handleSaveTask}
              closeModal={() => setIsDuplicateTaskOpen(false)}
              duplicate={actionTask}
              student={student}
            />
          )}
        </Modal>
        <Modal
          className="modal-block"
          isOpen={isDeleteTaskOpen}
          ariaHideApp={false}
          onRequestClose={() => {
            setIsDeleteTaskOpen(false);
            setSelectedDeleteType(UowDeleteEditType.SELF);
          }}
        >
          {actionTask === null ? (
            <Loader type="ThreeDots" />
          ) : (
            <DeleteConfirm
              deleteResource={() => {
                if (actionTask._id)
                  deleteTaskHandler(actionTask._id, selectedDeleteType);
              }}
              closeModal={() => {
                setIsDeleteTaskOpen(false);
                setSelectedDeleteType(UowDeleteEditType.SELF);
              }}
              message={{
                header: "Do you want to delete the task(s)?",
                body: "Deleting tasks will remove them permanently. This action is not reversible",
              }}
            />
          )}
        </Modal>
        <Modal
          className="modal-block"
          isOpen={isMarkAsIncompleteOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsMarkAsIncompleteOpen(false)}
        >
          {actionTask === null ? (
            <Loader type="ThreeDots" />
          ) : (
            <InCompleteConfirm
              inCompleteTaskHandler={() => {
                if (actionTask._id) markAsIncompleteHandler(actionTask._id);
              }}
              closeModal={() => setIsMarkAsIncompleteOpen(false)}
              message={{
                header: "Are you sure you want to mark this as incomplete?",
                body: "Student can see this task has been marked as incomplete.",
              }}
            />
          )}
        </Modal>
        <Modal
          className="modal-block"
          isOpen={isBulkDeleteTaskOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsBulkDeleteTaskOpen(false)}
        >
          {selectedTasks.length < 1 ? (
            <Loader type="ThreeDots" />
          ) : (
            <DeleteConfirm
              deleteResource={handleBulkDelete}
              closeModal={() => setIsBulkDeleteTaskOpen(false)}
              message={{
                header: "Do you want to delete the task(s)?",
                body: "Deleting tasks will remove them permanently. This action is not reversible",
              }}
            />
          )}
        </Modal>
        <Modal
          className="modal-block"
          isOpen={isTaskSuccessOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsTaskSuccessOpen(false)}
        >
          <ConfirmSuccess
            multiple={true}
            user={goal.assignee}
            closeModal={() => setIsTaskSuccessOpen(false)}
          />
        </Modal>
        <Modal
          className="modal-block"
          isOpen={isTaskDetailOpen !== null}
          ariaHideApp={false}
          onRequestClose={() => setIsTaskDetailOpen(null)}
        >
          {isTaskDetailOpen && (
            <TaskDetail
              closeModal={() => {
                setIsTaskDetailOpen(null);
                setActionTask(null);
              }}
              planningPeriod={getPlanningPeriodByDate(
                planningPeriods,
                new Date(isTaskDetailOpen.requiredDeadline),
                true,
              )}
              inCompleteTaskHandler={markAsIncompleteHandler}
              publishTaskHandler={publishTaskHandler}
              completeTaskHandler={completeTaskHandler}
              task={isTaskDetailOpen}
            />
          )}
        </Modal>
      </div>
      {selectedTasks.length > 0 && (
        <div className="bulk-action-block">
          <div className="bulk-action-block__content">
            <div className="bulk-action-block-left">
              {triggeredBulkTaskAction === "publish" && (
                <button
                  disabled={bulkTransactionLoading}
                  onClick={bulkPublishTasks}
                  className="btn btn--primary"
                >
                  Publish Drafts
                </button>
              )}
              {triggeredBulkTaskAction === "complete" && (
                <button
                  disabled={bulkTransactionLoading}
                  onClick={bulkCompleteTasks}
                  className="btn btn--green"
                >
                  Mark as Completed
                </button>
              )}
              <button
                disabled={bulkTransactionLoading}
                onClick={() => setIsBulkDeleteTaskOpen(true)}
                className="btn"
              >
                Delete
              </button>
            </div>

            <div className="bulk-action-block-right">
              <button
                disabled={bulkTransactionLoading}
                onClick={handleBulkActionReset}
                className="btn txt-primary-color"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface IState {
  updateTask: (id: string, task: IGoal) => Promise<IGoal>;
}

const mapStateToProps = (state: IState) => ({});

const mapDispatchToProps = {
  updateTask,
  deleteTask,
  markAsIncomplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
