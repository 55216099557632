import * as Yup from "yup";

import { NAME_LENGTH, PHONE_LENGTH } from "constants/index";
import { PHONE_REGEX } from "constants/regex";

export const sponsorSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First name is required")
    .max(NAME_LENGTH, `First name must be less than ${NAME_LENGTH} characters`),
  lastName: Yup.string()
    .trim()
    .required("Last name is required")
    .max(NAME_LENGTH, `Last name must be less than ${NAME_LENGTH} characters`),
  middleName: Yup.string().optional().nullable(),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, "Phone number is not valid")
    .max(
      PHONE_LENGTH,
      `Phone number must be less than ${PHONE_LENGTH} characters`,
    )
    .required("Phone number is required"),
  relationshipWithSponsor: Yup.string()
    .trim()
    .required("Relationship with sponsor is required"),
  livingScheduleWithParents: Yup.string()
    .trim()
    .required("Living schedule with parents is required!"),
  relatedUsers: Yup.object().shape({
    subjects: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().nullable(),
        firstName: Yup.string().nullable(),
        lastName: Yup.string().nullable(),
        email: Yup.string().nullable(),
      }),
    ),
  }),
});
