import { Dispatch } from "redux";
import { Channel } from "twilio-chat/lib/channel";

import { ToggleChatActionType, ChatStatusType } from "../types/chat";

export const toggleChat = () => (dispatch: Dispatch) => {
  dispatch({ type: ToggleChatActionType.TOGGLE_CHAT });
};

export const setUnreadMessage = (channel: Channel) => (dispatch: Dispatch) => {
  dispatch({ type: ChatStatusType.SET_UNREAD_MESSAGE, data: channel });
};

export const setReadMessage = (channel: Channel) => (dispatch: Dispatch) => {
  dispatch({ type: ChatStatusType.SET_READ_MESSAGE, data: channel });
};
