import { isStudentLoggedIn } from "utils/window";

interface IProfileActionsProps {
  onClose: () => void;
  taskClickHandler: () => void;
  goalClickHandler: () => void;
  sessionClickHandler: () => void;
  assignmentClickHandler: () => void;
}

const ProfileActions: React.FC<IProfileActionsProps> = ({
  onClose,
  taskClickHandler,
  goalClickHandler,
  sessionClickHandler,
  assignmentClickHandler,
}) => (
  <div className="modal-wrap modal-wrap--sm">
    <div className="modal-wrap__header no-border">
      <h3>Add a new</h3>
      <span className="link-item" onClick={onClose}>
        <box-icon name="x"></box-icon>
      </span>
    </div>
    <div className="profile-action modal-wrap__body pb-8x">
      <div onClick={taskClickHandler} className="action-block">
        <button className="btn btn__with-icon btn--primary">
          <box-icon name="calendar-check" size="24px" />
        </button>
        <label className="mt-4x link-item">Task</label>
      </div>
      <div onClick={goalClickHandler} className="action-block">
        <button className="btn btn__with-icon btn--red">
          <box-icon name="bullseye" size="24px" />
        </button>
        <label className="mt-4x link-item">Goal</label>
      </div>
      {/* <div onClick={sessionClickHandler} className="action-block">
        <button className="btn btn__with-icon btn--green">
          <box-icon name="calendar-check" size="24px" />
        </button>
        <label className="mt-4x link-item">Session</label>
      </div> */}
      {!isStudentLoggedIn() && (
        <div onClick={assignmentClickHandler} className="action-block">
          <button className="btn btn__with-icon btn--secondary">
            <box-icon name="book-open" size="24px" />
          </button>
          <label className="mt-4x link-item align-center">
            Missing <br />
            Assignment
          </label>
        </div>
      )}
    </div>
  </div>
);

export default ProfileActions;
