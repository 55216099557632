import { ICenter } from "commons/types/centers";
import React from "react";

import Form from "../Form";

interface IEditCenterProps {
  closeModal: () => void;
  initialValue: ICenter;
  updateCenter: (id: string, center: ICenter) => Promise<void>;
}

const EditCenter: React.FC<IEditCenterProps> = ({
  closeModal,
  initialValue,
  updateCenter,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Edit a center</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        isEdit={true}
        updateCenter={updateCenter}
        initialValues={initialValue}
        closeModal={closeModal}
      />
    </div>
  );
};

export default EditCenter;
