import React from "react";
import { useFormik } from "formik";

import { ASSIGNMENT_STATUS } from "constants/index";
import { IAssignment, IUpdateAssignment } from "commons/types/assignments";
import { OptionsInput, TextAreaInput } from "commons/components/form-fields";

interface MentorCompletedProps {
  assignment: IAssignment;
  closeModal: () => void;
  updateAssignment: (payload: IUpdateAssignment) => any;
  fetchAssignments: () => any;
}

const MarkCompletedForm: React.FC<MentorCompletedProps> = ({
  assignment,
  closeModal,
  updateAssignment,
  fetchAssignments,
}): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      status: assignment.status,
      description: assignment.description || "",
    },
    onSubmit: async (values) => {
      await updateAssignment(values);
      await fetchAssignments();
      closeModal();
    },
  });

  const statusOptionValues = [
    {
      id: "radio1",
      label: ASSIGNMENT_STATUS.Missed,
      value: ASSIGNMENT_STATUS.Missed,
      isChecked: formik.values.status === ASSIGNMENT_STATUS.Missed,
    },
    {
      id: "radio2",
      label: ASSIGNMENT_STATUS.Submitted,
      value: ASSIGNMENT_STATUS.Submitted,
      isChecked: formik.values.status === ASSIGNMENT_STATUS.Submitted,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body">
        <div className="row">
          <div className="col-12">
            <OptionsInput
              type={"radio"}
              groupName={"status"}
              options={statusOptionValues}
              handleChange={formik.handleChange}
              errors={formik.errors}
              touched={formik.touched}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              label="Please add a note(optional)"
              name="description"
              placeholder=""
              values={formik.values.description}
              touched={formik.touched}
              handleChange={formik.handleChange}
              errors={formik.errors}
            />
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={
                formik.isSubmitting || formik.values === formik.initialValues
              }
            >
              Update Status
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MarkCompletedForm;
