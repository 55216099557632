import { ISchool } from "commons/types/school";

export enum AddSchoolActionType {
  ADD_SCHOOL_PENDING = "ADD_SCHOOL_PENDING",
  ADD_SCHOOL_REJECTED = "ADD_SCHOOL_REJECTED",
  ADD_SCHOOL_FULFILLED = "ADD_SCHOOL_FULFILLED",
}
export interface AddSchoolAction {
  type: AddSchoolActionType;
  data: ISchool;
}

export enum FetchSchoolsActionType {
  FETCH_SCHOOLS_PENDING = "FETCH_SCHOOLS_PENDING",
  FETCH_SCHOOLS_REJECTED = "FETCH_SCHOOLS_REJECTED",
  FETCH_SCHOOLS_FULFILLED = "FETCH_SCHOOLS_FULFILLED",
}
export interface FetchSchoolsAction {
  type: FetchSchoolsActionType;
  data: ISchool[];
}

export enum FetchSchoolActionType {
  FETCH_SCHOOL_PENDING = "FETCH_SCHOOL_PENDING",
  FETCH_SCHOOL_REJECTED = "FETCH_SCHOOL_REJECTED",
  FETCH_SCHOOL_FULFILLED = "FETCH_SCHOOL_FULFILLED",
}
export interface FetchSchoolAction {
  type: FetchSchoolActionType;
  data: ISchool;
}

export enum UpdateSchoolActionType {
  UPDATE_SCHOOL_PENDING = "UPDATE_SCHOOL_PENDING",
  UPDATE_SCHOOL_REJECTED = "UPDATE_SCHOOL_REJECTED",
  UPDATE_SCHOOL_FULFILLED = "UPDATE_SCHOOL_FULFILLED",
}
export interface UpdateSchoolAction {
  type: UpdateSchoolActionType;
  data: ISchool;
}

export enum DeleteSchoolActionType {
  DELETE_SCHOOL_PENDING = "DELETE_SCHOOL_PENDING",
  DELETE_SCHOOL_FULFILLED = "DELETE_SCHOOL_FULFILLED",
  DELETE_SCHOOL_REJECTED = "DELETE_SCHOOL_REJECTED",
}

export interface DeleteSchoolAction {
  type: DeleteSchoolActionType;
  data: ISchool;
}
