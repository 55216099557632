import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  IAccommodationCreate,
  IAccommodationParams,
  IUpdateAccommodation,
} from "commons/types/accommodation";

export const add = async (payload: IAccommodationCreate) => {
  const url = config.endpoints.accommodations.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: IAccommodationParams) => {
  const url = stringify(config.endpoints.accommodations.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: IUpdateAccommodation) => {
  const url = pinterpolate(config.endpoints.accommodations.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.accommodations.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
