import * as React from "react";

const Menu = ({ editMenu, markCompletedMenu, deleteMenu }: any) => {
  return (
    <div className="menu-dialog">
      <ul className="menu-dialog__body">
        <li className="menu-list" onClick={markCompletedMenu}>
          <box-icon name="check"></box-icon>
          <span className="ml-2x">Mark Completed</span>
        </li>
        <li className="menu-list" onClick={editMenu}>
          <box-icon name="edit-alt"></box-icon>
          <span className="ml-2x">Edit</span>
        </li>
        <li className="menu-list menu-list--danger" onClick={deleteMenu}>
          <box-icon name="trash-alt"></box-icon>
          <span className="ml-2x">Delete</span>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
