import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ErrorMessage, Form, FormikProvider, useFormik, Field } from "formik";
import Select, { components, OptionTypeBase } from "react-select";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import { ITerm } from "commons/types/terms";
import { fetchTerms } from "store/actions/data/terms";
import { ISelectOption } from "commons/types/form";
import {
  sendSurveyInfo,
  getSurveyCategories,
} from "store/actions/data/surveys";
import { identifyIsTermCurrentPastOrFuture } from "utils/term";
import {
  ISurvey,
  ISurveyInitiate,
  SurveyTypes,
} from "../../../commons/types/surveys";
import { OptionsInput } from "commons/components/form-fields";
import { SendIndividualSurveySchema } from "commons/schemas/survey";
import { getCurrentEnrollment } from "utils/user";

interface ISendIndividualAssessmentProps {
  closeModal: () => void;
  student: IUser;
  terms: ITerm[];
  surveyCategories: ISurvey[];
  surveyInitiateSuccess: boolean;
  surveyInitiateLoading: boolean;
  sendSurveyInfo: (payload: ISurveyInitiate) => Promise<void>;
  onAssessmentSentSuccess?: () => void;
  fetchTerms: () => void;
  getSurveyCategories: () => void;
}

interface FormValues {
  surveyType: string;
  termId: string;
  category: string;
  isPractitionerChecked: boolean;
  subject: string;
  sponsors: string[];
}

const surveyTypes = [
  { label: SurveyTypes.INITIAL_SURVEY, value: SurveyTypes.INITIAL },
  { label: SurveyTypes.FINAL_SURVEY, value: SurveyTypes.FINAL },
];

const SendIndividualAssessment: React.FC<ISendIndividualAssessmentProps> = (
  props,
) => {
  const {
    closeModal,
    student,
    terms,
    surveyCategories,
    onAssessmentSentSuccess,
    sendSurveyInfo,
    fetchTerms,
    getSurveyCategories,
  } = props;

  const currentEnrollment = getCurrentEnrollment(student);
  const [activeTermId, setActiveTermId] = React.useState(
    currentEnrollment?.enrolledTerm._id || "",
  );

  useEffect(() => {
    fetchTerms();
    getSurveyCategories();
  }, [fetchTerms, getSurveyCategories]);

  const initialFormValues: FormValues = {
    surveyType: "",
    termId: activeTermId,
    category: "",
    isPractitionerChecked: true,
    subject: student.id || "",
    sponsors: [],
  };

  const formik = useFormik<FormValues>({
    initialValues: initialFormValues,
    validationSchema: SendIndividualSurveySchema,
    onSubmit: (formValues: FormValues) =>
      handleFormSubmit(
        student,
        formValues,
        sendSurveyInfo,
        closeModal,
        onAssessmentSentSuccess,
      ),
  });

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <div className="text-small">{props.data.subLabel}</div>
      </components.Option>
    );
  };

  const DropDownSelection = () => {
    // const termsOption: ISelectOption[] = terms.map((term) => {
    //   if (term._id === currentEnrollment?.enrolledTerm._id && !activeTermId) {
    //     setActiveTermId(term._id);
    //   }

    //   return {
    //     label: term.name,
    //     subLabel: identifyIsTermCurrentPastOrFuture(term),
    //     value: term._id,
    //   };
    // });

    const termsOption = (() => {
      const activeEnrollment = student.userData?.enrollments?.filter(
        (enrollment) => enrollment.status !== "Draft",
      );

      if (activeEnrollment) {
        const activeEnrollmentWithTerms = activeEnrollment.filter(
          (enrollment) => enrollment.enrolledTerm,
        );
        return activeEnrollmentWithTerms.map((enrollment) => {
          return {
            label: enrollment.enrolledTerm.name,
            subLabel: identifyIsTermCurrentPastOrFuture(
              enrollment.enrolledTerm,
            ),
            value: enrollment.enrolledTerm._id,
          };
        });
      }
      return [];
    })();

    const surveyCategoriesOption: ISelectOption[] = surveyCategories.map(
      (survey) => {
        return { label: survey.name, value: survey._id };
      },
    );

    const selectedTerm = (() => {
      if (activeTermId) {
        return termsOption.find((option) => option.value === activeTermId);
      }

      return { label: "Please select", value: "", subLabel: "" };
    })();

    // const selectedTerm = (() => {
    //   if (activeTermId) {
    //     return termsOption.find((term) => term.value === activeTermId);
    //   }

    //   return { label: "Please select", value: "", subLabel: "" };
    // })();

    const onTermSelection = (selectedOption: OptionTypeBase | null) => {
      setActiveTermId(selectedOption?.value || "");
      formik.setFieldValue("termId", selectedOption?.value);
    };

    return (
      <>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label className={`input__label required`}>Term</label>
              <Field
                name="termId"
                component={() => {
                  return (
                    <>
                      <Select
                        placeholder="Select term"
                        onChange={onTermSelection}
                        value={selectedTerm}
                        options={termsOption}
                        components={{ Option }}
                        classNamePrefix="react-select"
                      />
                      <label className="input__error">
                        <ErrorMessage name="termId" />
                      </label>
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <label className={`input__label required`}>
                Select Survey Type
              </label>
              <Field
                name="surveyType"
                component={({ field, form }: { field: any; form: any }) => {
                  return (
                    <>
                      <Select
                        placeholder="Survey Type"
                        onChange={(option) => {
                          if (option) {
                            form.setFieldValue(field.name, option.value);
                          }
                        }}
                        value={surveyTypes?.find(
                          (option) => option.value === field.value,
                        )}
                        options={surveyTypes}
                        classNamePrefix="react-select"
                      />
                      <label className="input__error">
                        <ErrorMessage name="surveyType" />
                      </label>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-wrap">
              <label className={`input__label required`}>Select Survey</label>
              <Field
                name="category"
                component={({ field, form }: { field: any; form: any }) => {
                  return (
                    <>
                      <Select
                        placeholder="Select survey"
                        onChange={(option) => {
                          if (option) {
                            form.setFieldValue(field.name, option.value);
                          }
                        }}
                        value={surveyCategoriesOption?.find(
                          (option) => option.value === field.value,
                        )}
                        options={surveyCategoriesOption}
                        classNamePrefix="react-select"
                      />
                      <label className="input__error">
                        <ErrorMessage name="category" />
                      </label>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-wrap">
              <label className="input__label">Student Name</label>
              <input
                disabled={true}
                type="text"
                placeholder={getFullName(student)}
                className="input"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ResponderSelection = () => {
    const sponsors = student.relatedUsers?.sponsors;
    const sponsorCheckBoxOptions =
      sponsors?.map((sponsor) => ({
        id: `sponsor-${sponsor.userId._id}`,
        label: `${getFullName(sponsor.userId)} (${
          sponsor.relationshipWithSponsor
        })`,
        value: sponsor.userId._id,
        isChecked: formik.values.sponsors.includes(sponsor?.userId?._id),
      })) || [];

    const practitionersCheckBoxOptions = [
      {
        id: `practitioner`,
        label: "Send this survey to mentor",
        value: true.toString(),
        isChecked: formik.values.isPractitionerChecked,
      },
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h2 className="txt-bold text-large mt-3x mb-2x">Select Parents</h2>
            <label className="input__label text-small color-grey-50 mb-4x mt-1x">
              Send this survey to parents also.
            </label>
            <div className="d-flex flex-column pb-2x">
              {sponsorCheckBoxOptions.length ? (
                <OptionsInput
                  type={"checkbox"}
                  groupName={"sponsors"}
                  options={sponsorCheckBoxOptions}
                  handleChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  optionsClassName="flex-column"
                />
              ) : (
                "No Parents Assigned"
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4x">
            <OptionsInput
              type={"checkbox"}
              groupName={"isPractitionerChecked"}
              options={practitionersCheckBoxOptions}
              handleChange={() => {
                formik.setFieldValue(
                  "isPractitionerChecked",
                  !formik.values.isPractitionerChecked,
                );
              }}
              errors={formik.errors}
              touched={formik.touched}
              labelClassName={"text-bold"}
            />
          </div>
        </div>
      </>
    );
  };

  const ActionButtons = () => (
    <div className="row">
      <div className="col-12 d-flex">
        <button
          disabled={formik.isSubmitting}
          className="btn btn--primary mr-4x"
          type="submit"
        >
          Send
        </button>
        <button
          type="reset"
          disabled={formik.isSubmitting}
          className="btn txt-primary-color"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Send an Assessment</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <div className="modal-wrap__body">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DropDownSelection />
            <ResponderSelection />
            <ActionButtons />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

async function handleFormSubmit(
  student: IUser,
  formValues: FormValues,
  sendSurveyInfo: (payload: ISurveyInitiate) => Promise<void>,
  successCallback: () => void,
  onAssessmentSentSuccess?: () => void,
) {
  const user = localStorage.getItem("profile");
  let currentUser: any = {};
  if (user) {
    try {
      currentUser = JSON.parse(user);
    } catch (err) {
      console.error(err);
    }
  }

  const practitionerId = currentUser._id;

  const selectedPractitionersIds = (() => {
    if (!formValues.isPractitionerChecked) {
      return [];
    }

    const selectedTerm = student.userData?.enrollments?.find(
      (enrollment) => enrollment?.enrolledTerm?._id === formValues.termId,
    );

    // @ts-ignore
    const practitionerIds: Array<string> =
      selectedTerm?.practitioners
        ?.map((practitioner) => practitioner.practitioner._id)
        .filter(Boolean) || [];

    if (practitionerIds.length > 0) {
      return practitionerIds;
    } else {
      return [practitionerId];
    }
  })();

  try {
    const sendAssessmentPayload: ISurveyInitiate = {
      term: formValues.termId,
      surveyType: formValues.surveyType,
      category: formValues.category,
      responders: [
        {
          subject: student?._id || "",
          practitioners: selectedPractitionersIds,
          sponsors: formValues.sponsors,
        },
      ],
      createdDate: new Date(),
      createdBy: practitionerId,
    };

    await sendSurveyInfo(sendAssessmentPayload);

    onAssessmentSentSuccess && onAssessmentSentSuccess();
    successCallback();
  } catch (err) {}
}

const mapStateToProps = (state: any) => ({
  terms: state.terms.fetchTerms,
  surveyCategories: state.surveys.surveyCategories,
  surveyLoading: state.surveys.surveySendingLoading,
  surveyInitiateSuccess: state.surveys.surveyInitiateSuccess,
  surveyInitiateLoading: state.surveys.surveyInitiateLoading,
});

const mapDispatchToProps = {
  fetchTerms,
  sendSurveyInfo,
  getSurveyCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendIndividualAssessment);
