import { connect } from "react-redux";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";

import Sponsor from "./Sponsor";
import { Params } from "commons/types/urls";
import Loader from "commons/components/Loader";
import { DEBOUNCE_TIME } from "constants/index";
import { ISponsor, IUser } from "commons/types/users";
import { fetchSponsors } from "store/actions/data/sponsors";

interface ISearchSponsorsProps {
  sponsors: IUser[];
  closeModal: () => void;
  addSponsor: () => void;
  sponsorsLoading: boolean;
  selectedSponsors: ISponsor[];
  handleSelectSponsor: (sponsor: IUser) => void;
  handleUnselectSponsor: (sponsor: IUser) => void;
  fetchSponsors: (params: Params) => Promise<IUser[]>;
}

const Search: React.FC<ISearchSponsorsProps> = ({
  sponsors,
  closeModal,
  addSponsor,
  fetchSponsors,
  sponsorsLoading,
  selectedSponsors,
  handleSelectSponsor,
  handleUnselectSponsor,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searched, setSearched] = useState(false);

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
    setSearched(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      fetchSponsors({ q: searchValue });
      setSearched(true);
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue, debouncedSearch]);

  const noSponsorsFound = () => (
    <div className="center">
      <div className="margin-auto">
        <box-icon size="32px" color="#959FAE" name="error-alt" />
      </div>
      <p className="m-2x">
        We could not find any parent or guardian matching your request.
      </p>
      <button
        onClick={() => {
          addSponsor();
          closeModal();
        }}
        className="btn btn--outlined-primary"
      >
        Add a parent/guardian
      </button>
    </div>
  );

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Search a Parent/Guardian</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>

      <form>
        <div className="modal-wrap__body">
          <section className="section">
            <div className="section__content">
              <div className="input-wrap">
                <label className="input__label">Search a name or email</label>
                <input
                  type="text"
                  className="input"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  value={searchValue}
                />
              </div>
            </div>
          </section>
          <div className="scroll-section">
            {sponsorsLoading ? (
              <Loader type="ThreeDots" />
            ) : !sponsors.length && searched ? (
              noSponsorsFound()
            ) : (
              sponsors.map((sponsor: IUser) => {
                const selected = selectedSponsors.some(
                  (selectedSponsor) =>
                    selectedSponsor.userId._id === sponsor._id,
                );
                return (
                  <Sponsor
                    key={sponsor._id}
                    sponsor={sponsor}
                    handleSelectSponsor={handleSelectSponsor}
                    selected={selected}
                    handleUnselectSponsor={handleUnselectSponsor}
                  />
                );
              })
            )}
          </div>
        </div>
        {!(!sponsors.length && searched) && (
          <div className="modal-wrap__footer">
            <div className="row">
              <div className="col-12 d-flex">
                <button
                  type="button"
                  className="ml-2x btn txt-primary-color"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

interface IState {
  sponsors: { fetchSponsors: IUser[]; fetchSponsorsLoading: boolean };
}

const mapStateToProps = (state: IState) => ({
  sponsors: state.sponsors.fetchSponsors,
  sponsorsLoading: state.sponsors.fetchSponsorsLoading,
});

const mapDispatchToProps = {
  fetchSponsors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
