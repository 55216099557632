import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
} from "react-accessible-accordion";

import QuestionItem from "./QuestionItem";
import { IProfile } from "commons/types/users";
import { ICreateResponseRevision } from "commons/types/responseRevision";
import {
  SurveyResponse,
  SurveySkillSet,
  IResponseAggregate,
  ISelectOption,
} from "commons/types/surveys";
import Loader from "commons/components/Loader";

interface SkillSetItemProps {
  profile: IProfile;
  isEditable: boolean;
  showRevised: () => void;
  skillSet: SurveySkillSet;
  responseData: SurveyResponse;
  aggregatedValues?: IResponseAggregate;
  createResponseRevision: (
    responseId: string,
    payload: ICreateResponseRevision,
  ) => Promise<any>;
}

const SkillSetItem: React.FC<SkillSetItemProps> = (props) => {
  const {
    profile,
    skillSet,
    isEditable,
    showRevised,
    responseData,
    aggregatedValues,
    createResponseRevision,
  } = props;
  const [aggregatedValuesMap, setAggregatedValuesMap] = useState<{
    [key: string]: number;
  }>({});
  const [isLoading, setIsLoading] = useState(true);

  const getAggregatedValueOfQuestion = (questions: {
    [key: string]:
      | { _id: string; data: ISelectOption }[][]
      | { _id: string; data: ISelectOption }[];
  }) => {
    let totalAnswerOfQuestion = 0;
    let totalAnswers = 0;

    Object.keys(questions)?.forEach((role: string) =>
      questions[role].forEach((answers) => {
        if (Array.isArray(answers)) {
          return answers.forEach((answer) => {
            const value = +answer.data.value;
            totalAnswerOfQuestion += value;
            totalAnswers += 1;

            return value;
          });
        } else if (answers) {
          const value = +answers.data.value;
          totalAnswerOfQuestion += value;
          totalAnswers += 1;

          return value;
        }
      }),
    );

    return totalAnswerOfQuestion / totalAnswers;
  };

  const calculateSkillSetScore = () => {
    setIsLoading(true);
    const questionWeight = new Array<number>();
    const noOfQuestion = skillSet.questions.length;
    const skillSetQuestions = skillSet.questions.map(
      (question) => question.question._id,
    );

    const newMap = { ...aggregatedValuesMap };
    for (const question of skillSetQuestions) {
      if (aggregatedValues && aggregatedValues.hasOwnProperty(question)) {
        const aggregatedValue = getAggregatedValueOfQuestion(
          aggregatedValues[question],
        );
        questionWeight.push(aggregatedValue);
        newMap[question] = aggregatedValue;
      } else
        responseData.result.map((responses) =>
          responses.responses.map((response) => {
            if (response.question === question) {
              questionWeight.push(
                Number.isNaN(response.answer?.value)
                  ? 0
                  : parseInt(response.answer?.value) || 0,
              );
            }

            return response;
          }),
        );
    }

    if (questionWeight.length > 0) {
      const totalSum = questionWeight.reduce(
        (accumulator: number, a: number) => {
          return accumulator + a;
        },
        0,
      );

      if (
        Object.keys(aggregatedValuesMap).length !== Object.keys(newMap).length
      )
        setAggregatedValuesMap(newMap);

      setIsLoading(false);
      return totalSum / noOfQuestion;
    }

    setIsLoading(false);
    return "'N/A'";
  };

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="accordion"
      preExpanded={[skillSet.skillset._id]}
    >
      <AccordionItem uuid={skillSet.skillset._id} className="accordion-item">
        <AccordionItemState>
          {({ expanded }) => (
            <>
              <AccordionItemButton className="accordion-item__title pb-6px pl-4x pr-0x">
                <div className="title-left">
                  <h3 className="text-color-darkgray">
                    {skillSet.skillset.name}
                  </h3>
                </div>
                <div className="title-right">
                  <div className="actions d-flex align-items-center">
                    <span className="status status--warning-dark fs-body2">
                      Skill Set Score:{" "}
                      {Number(calculateSkillSetScore()).toFixed(2)}
                    </span>
                    <span className="link-item">
                      <box-icon
                        name={!expanded ? "chevron-down" : "chevron-up"}
                      />
                    </span>
                  </div>
                </div>
              </AccordionItemButton>
              <AccordionItemPanel className="accordion-item__content">
                {isLoading ? (
                  <Loader type="ThreeDots" />
                ) : (
                  skillSet.questions.map((question, index) => {
                    window.questionsMap[question.question._id] =
                      question.question;

                    return (
                      <QuestionItem
                        question={question}
                        key={index}
                        index={index}
                        showRevised={showRevised}
                        profile={profile}
                        isEditable={isEditable}
                        responseData={responseData}
                        aggregatedValue={
                          aggregatedValuesMap[question.question._id]
                        }
                        createResponseRevision={createResponseRevision}
                      />
                    );
                  })
                )}
              </AccordionItemPanel>
            </>
          )}
        </AccordionItemState>
      </AccordionItem>
    </Accordion>
  );
};

export default SkillSetItem;
