import {
  AddSatelliteLocationActionType,
  FetchSatelliteLocationsActionType,
  UpdateSatelliteLocationActionType,
  DeleteSatelliteLocationActionType,
} from "store/actions/types/satelliteLocation";

const INITIAL_STATE = {
  addSatelliteLocation: {},
  addSatelliteLocationLoading: false,
  addSatelliteLocationError: null,
  fetchSatelliteLocations: [],
  fetchSatelliteLocationsLoading: false,
  fetchSatelliteLocationsError: null,
  updateSatelliteLocation: {},
  updateSatelliteLocationLoading: false,
  updateSatelliteLocationError: null,
  deleteSatelliteLocation: {},
  deleteSatelliteLocationLoading: false,
  deleteSatelliteLocationError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddSatelliteLocationActionType.ADD_SATELLITE_LOCATION_PENDING:
      return {
        ...state,
        addSatelliteLocationLoading: true,
        addSatelliteLocationError: null,
      };

    case AddSatelliteLocationActionType.ADD_SATELLITE_LOCATION_FULFILLED:
      return {
        ...state,
        addSatelliteLocation: action.data,
        addSatelliteLocationLoading: false,
        addSatelliteLocationError: null,
      };
    case AddSatelliteLocationActionType.ADD_SATELLITE_LOCATION_REJECTED:
      return {
        ...state,
        addSatelliteLocationLoading: false,
        addSatelliteLocationError: action.data,
      };
    case FetchSatelliteLocationsActionType.FETCH_SATELLITE_LOCATIONS_PENDING:
      return {
        ...state,
        fetchSatelliteLocationsLoading: true,
        fetchSatelliteLocationsError: null,
      };
    case FetchSatelliteLocationsActionType.FETCH_SATELLITE_LOCATIONS_FULFILLED:
      return {
        ...state,
        fetchSatelliteLocationsLoading: false,
        fetchSatelliteLocationsError: null,
        fetchSatelliteLocations: action.data,
      };

    case FetchSatelliteLocationsActionType.FETCH_SATELLITE_LOCATIONS_REJECTED:
      return {
        ...state,
        fetchSatelliteLocationsLoading: false,
        fetchSatelliteLocationsError: action.data,
      };
    case UpdateSatelliteLocationActionType.UPDATE_SATELLITE_LOCATION_PENDING:
      return {
        ...state,
        updateSatelliteLocationLoading: true,
        updateSatelliteLocationError: null,
      };
    case UpdateSatelliteLocationActionType.UPDATE_SATELLITE_LOCATION_FULFILLED:
      return {
        ...state,
        updateSatelliteLocationLoading: false,
        updateSatelliteLocation: action.data,
        updateSatelliteLocationError: null,
      };
    case UpdateSatelliteLocationActionType.UPDATE_SATELLITE_LOCATION_REJECTED:
      return {
        ...state,
        updateSatelliteLocationLoading: false,
        updateSatelliteLocationError: action.data,
      };
    case DeleteSatelliteLocationActionType.DELETE_SATELLITE_LOCATION_PENDING:
      return {
        ...state,
        deleteSatelliteLocationLoading: true,
        deleteSatelliteLocationError: null,
      };
    case DeleteSatelliteLocationActionType.DELETE_SATELLITE_LOCATION_FULFILLED:
      return {
        ...state,
        deleteSatelliteLocation: action.data,
        deleteSatelliteLocationLoading: false,
        deleteSatelliteLocationError: null,
      };
    case DeleteSatelliteLocationActionType.DELETE_SATELLITE_LOCATION_REJECTED:
      return {
        ...state,
        deleteSatelliteLocationLoading: false,
        deleteSatelliteLocationError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
