import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import { ICenter, ICentersParams, IUpdateCenters } from "commons/types/centers";

export const add = async (payload: ICenter) => {
  const url = config.endpoints.locations.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: ICentersParams) => {
  const url = stringify(config.endpoints.locations.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: IUpdateCenters) => {
  const url = pinterpolate(config.endpoints.locations.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.locations.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
