import { Dispatch } from "redux";

import { error } from "utils/toast";
import { TermsActionType } from "store/actions/types/terms";
import { fetchTerms as fetchTermsService } from "services/terms";

export const fetchTerms = () => async (dispatch: Dispatch) => {
  dispatch({
    type: TermsActionType.FETCH_TERMS_PENDING,
  });
  try {
    const terms = await fetchTermsService();
    dispatch({
      type: TermsActionType.FETCH_TERMS_FULFILLED,
      data: terms,
    });

    return terms;
  } catch (ex) {
    dispatch({
      type: TermsActionType.FETCH_TERMS_REJECTED,
      data: ex,
    });

    error("Failed to fetch terms");
  }
};

export const updateTermStatus = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: TermsActionType.UPDATE_TERM_STATUS_PENDING,
  });
  try {
    // const students = await studentsService.fetchAllStudentsOfPractitioner(id);
    dispatch({
      type: TermsActionType.UPDATE_TERM_STATUS_FULFILLED,
      // data: students,
    });

    // return students;
  } catch (ex) {
    dispatch({
      type: TermsActionType.UPDATE_TERM_STATUS_REJECTED,
      data: ex,
    });

    error("Failed to update term status");
  }
};
