import pinterpolate from "pinterpolate";

import config from "configs";
import { Role } from "constants/roles";
import { Params } from "commons/types/urls";
import { http, stringify } from "utils/http";
import { UserType } from "constants/index";

export const fetchMentors = async (params: Params = {}) => {
  const url = stringify(config.endpoints.users.all, {
    role: Role.PRACTITIONER,
    ...params,
  });
  const { data } = await http.get(url);

  return data;
};

interface IMentorParams {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  environment: string;
  learningCenter: string[];
  status: string;
  roles?: { _id: string; name: string }[]; // need only when mentor is being created.
}

export interface IUpdateMentorParams extends IMentorParams {
  id: string;
}

export const addMentor = async ({
  firstName,
  lastName,
  email,
  phone,
  environment,
  learningCenter,
  status,
  roles,
}: IMentorParams) => {
  const { data } = await http.post(config.endpoints.users.all, {
    roles,
    firstName,
    lastName,
    email,
    phoneNumber: phone,
    userData: {
      type: UserType.Practitioner,
      learningEnvironment: environment,
      learningCenter,
      status,
    },
    relatedUsers: {
      type: UserType.Practitioner,
      subjects: [],
    },
  });
  return data;
};

export const updateMentor = async ({
  firstName,
  lastName,
  email,
  phone,
  environment,
  learningCenter,
  status,
  id,
}: IUpdateMentorParams) => {
  const url = pinterpolate(config.endpoints.users.one, { id: id });
  const { data } = await http.patch(url, {
    firstName,
    lastName,
    email,
    phoneNumber: phone,
    userData: {
      type: UserType.Practitioner,
      learningEnvironment: environment,
      learningCenter,
      status,
    },
  });
  return data;
};

export const fetchMentor = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.one, { id: id });
  const { data } = await http.get(url);

  return data;
};
