import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  IGradeType,
  IGradeTypeParams,
  IUpdateGradeType,
} from "commons/types/gradeTypes";

export const add = async (payload: IGradeType) => {
  const url = config.endpoints.gradeTypes.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: IGradeTypeParams) => {
  const url = stringify(config.endpoints.gradeTypes.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: IUpdateGradeType) => {
  const url = pinterpolate(config.endpoints.gradeTypes.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.gradeTypes.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
