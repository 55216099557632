import React from "react";
import { connect } from "react-redux";

import Form from "../Form";
import { IAcademicYear } from "../../../commons/types/academicYear";
import { IEditAcademicYearProps } from "./editAcademicYear.interface";
import {
  updateAcademicYear,
  fetchAcademicYears,
} from "../../../store/actions/data/academicYear";

const EditAcademicYear: React.FC<IEditAcademicYearProps> = ({
  initialValues,
  closeModal,
  updateAcademicYear,
  fetchAcademicYears,
}) => {
  const handleUpdateAcademicYear = async (
    id: string,
    academicYear: IAcademicYear,
  ) => {
    await updateAcademicYear(id, {
      _id: academicYear._id,
      name: academicYear.year,
    });

    fetchAcademicYears({});
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Edit an Academic Year</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        isEdit={true}
        initialValues={initialValues}
        closeModal={closeModal}
        updateAcademicYear={handleUpdateAcademicYear}
      />
    </div>
  );
};

const mapDispatchToProps = {
  updateAcademicYear,
  fetchAcademicYears,
};

export default connect(null, mapDispatchToProps)(EditAcademicYear);
