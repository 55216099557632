import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useState } from "react";
import { Column, useTable } from "react-table";

import AddAccommodation from "./add";
import { RootState } from "store/reducers";
import Loader from "commons/components/Loader";
import SchoolImage from "assets/images/school.svg";
import EmptyState from "commons/components/EmptyState";
import { IAccommodations } from "./accommodation.interface";
import SettingsPanel from "pages/layouts/intake/settingsPanel";
import AccommodationActionCell from "./accommodationActionCell";
import {
  IAccommodation,
  IAccommodationCreate,
  IUpdateAccommodation,
} from "commons/types/accommodation";
import {
  addAccommodation,
  updateAccommodation,
  fetchAccommodations,
  deleteAccommodation,
} from "store/actions/data/accommodation";

const Accommodations: React.FC<IAccommodations> = ({
  accommodations,
  addAccommodation,
  updateAccommodation,
  fetchAccommodations,
  accommodationLoading,
  deleteAccommodation,
}) => {
  const [isAddAccommodationOpen, setAddAccommodationOpen] = useState(false);

  React.useEffect(() => {
    fetchAccommodations({});
  }, [fetchAccommodations]);

  const handleAddAccommodation = async (
    accommodation: IAccommodationCreate,
  ) => {
    const addedAccommodation = await addAccommodation({
      name: accommodation.name,
    });
    setAddAccommodationOpen(false);

    if (addedAccommodation) {
      fetchAccommodations({});
    }
  };

  const handleUpdateAccommodation = React.useCallback(
    async (id: string, accommodation: IUpdateAccommodation) => {
      await updateAccommodation(id, accommodation);

      fetchAccommodations({});
    },
    [updateAccommodation, fetchAccommodations],
  );

  const handleDeleteAccommodation = React.useCallback(
    async (id: string) => {
      await deleteAccommodation(id);

      fetchAccommodations({});
    },
    [deleteAccommodation, fetchAccommodations],
  );

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ value }: { value: string }) => {
            return value;
          },
        },
        {
          Header: "Actions",
          accessor: "_id",
          Cell: ({ row }: any) => {
            return (
              <AccommodationActionCell
                row={row}
                handleUpdateAccommodation={handleUpdateAccommodation}
                handleDeleteAccommodation={handleDeleteAccommodation}
              />
            );
          },
        },
      ] as Column<IAccommodation>[],
    [handleUpdateAccommodation, handleDeleteAccommodation],
  );

  const tableInstance = useTable({ columns, data: accommodations });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (accommodationLoading) {
    return <Loader type="ThreeDots" />;
  }

  if (accommodations && accommodations.length < 1) {
    return (
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <SettingsPanel activeTab="Accommodation" />
            <div className="content-panel">
              <h1>Accommodations</h1>
              <EmptyState
                image={SchoolImage}
                title="You currently do not have any Accommodations."
                description="Please add some accommodation to get some work done."
                alt="school"
              >
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setAddAccommodationOpen(true)}
                >
                  Add Accommodation
                </button>
              </EmptyState>
            </div>
          </div>
        </div>
        <Modal
          className="modal-block"
          isOpen={isAddAccommodationOpen}
          ariaHideApp={false}
          onRequestClose={() => setAddAccommodationOpen(false)}
        >
          <AddAccommodation
            closeModal={() => setAddAccommodationOpen(false)}
            saveAccommodation={handleAddAccommodation}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="Accommodation" />
          <div className="content-panel">
            <div className="page-heading">
              <h1>Accommodations</h1>
              <div className="page-heading__right align-items-center">
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setAddAccommodationOpen(true)}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table {...getTableProps()} className="table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="head-row"
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="table__col">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="table__row">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="table__col">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="right-gap" />
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddAccommodationOpen}
        ariaHideApp={false}
        onRequestClose={() => setAddAccommodationOpen(false)}
      >
        <AddAccommodation
          closeModal={() => setAddAccommodationOpen(false)}
          saveAccommodation={handleAddAccommodation}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accommodations: state.accommodations.fetchAccommodations,
  accommodationLoading: state.accommodations.fetchAccommodationsLoading,
});

const mapDispatchToProps = {
  addAccommodation,
  updateAccommodation,
  fetchAccommodations,
  deleteAccommodation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accommodations);
