import * as Yup from "yup";

const AddEditUserSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
  email: Yup.string().required("Email address is required."),
  phone: Yup.string().required("Phone number is required."),
  // dateOfBirth: Yup.string().required("Date of birth  is required."),
});

export default AddEditUserSchema;
