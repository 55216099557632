import {
  AddAccommodationActionType,
  FetchAccommodationsActionType,
  UpdateAccommodationActionType,
  DeleteAccommodationActionType,
} from "store/actions/types/accommodation";

const INITIAL_STATE = {
  addAccommodation: {},
  addAccommodationLoading: false,
  addAccommodationError: null,
  fetchAccommodations: [],
  fetchAccommodationsLoading: false,
  fetchAccommodationsError: null,
  updateAccommodation: {},
  updateAccommodationLoading: false,
  updateAccommodationError: null,
  deleteAccommodation: {},
  deleteAccommodationLoading: false,
  deleteAccommodationError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddAccommodationActionType.ADD_ACCOMMODATION_PENDING:
      return { ...state, addAssignmentLoading: true, addAssignmentError: null };

    case AddAccommodationActionType.ADD_ACCOMMODATION_FULFILLED:
      return {
        ...state,
        addAccommodation: action.data,
        addAccommodationLoading: false,
        addAccommodationError: null,
      };
    case AddAccommodationActionType.ADD_ACCOMMODATION_REJECTED:
      return {
        ...state,
        addAssignmentLoading: false,
        addAssignmentError: action.data,
      };
    case FetchAccommodationsActionType.FETCH_ACCOMMODATION_PENDING:
      return {
        ...state,
        fetchAccommodationsLoading: true,
        fetchAccommodationsError: null,
      };
    case FetchAccommodationsActionType.FETCH_ACCOMMODATION_FULFILLED:
      return {
        ...state,
        fetchAccommodationsLoading: false,
        fetchAccommodationsError: null,
        fetchAccommodations: action.data,
      };

    case FetchAccommodationsActionType.FETCH_ACCOMMODATION_REJECTED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        fetchAssignmentsError: action.data,
      };
    case UpdateAccommodationActionType.UPDATE_ACCOMMODATION_PENDING:
      return {
        ...state,
        updateAccommodationLoading: true,
        updateAccommodationError: null,
      };
    case UpdateAccommodationActionType.UPDATE_ACCOMMODATION_FULFILLED:
      return {
        ...state,
        updateAccommodationLoading: false,
        updateAccommodation: action.data,
        updateAccommodationError: null,
      };
    case UpdateAccommodationActionType.UPDATE_ACCOMMODATION_REJECTED:
      return {
        ...state,
        updateAccommodationLoading: false,
        updateAccommodationError: action.data,
      };
    case DeleteAccommodationActionType.DELETE_ACCOMMODATION_PENDING:
      return {
        ...state,
        deleteAccommodationLoading: true,
        deleteAccommodationError: null,
      };
    case DeleteAccommodationActionType.DELETE_ACCOMMODATION_FULFILLED:
      return {
        ...state,
        deleteAccommodation: action.data,
        deleteAccommodationLoading: false,
        deleteAccommodationError: null,
      };
    case DeleteAccommodationActionType.DELETE_ACCOMMODATION_REJECTED:
      return {
        ...state,
        deleteAccommodationLoading: false,
        deleteAccommodationError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
