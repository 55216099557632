import { useEffect, useState, useCallback, useRef } from "react";
import {
  Track,
  Participant,
  AudioTrackPublication,
  VideoTrackPublication,
} from "twilio-video";

import { IUser } from "commons/types/users";
import { fetchBasicInfoByAuth0Id } from "services/users";
import { getFullName, getNameInitials } from "utils/names";

import { SCREEN_SHARE } from "constants/communication";

interface ParticipantViewProps {
  isLocal?: boolean;
  isAudio?: boolean;
  isAudioEnabled?: boolean;
  isVideoEnabled?: boolean;
  participant: Participant;
}

const ParticipantView: React.FC<ParticipantViewProps> = ({
  isLocal,
  participant,
  isAudio = false,
}): JSX.Element => {
  const [user, setUser] = useState<IUser | null>(null);
  const [videoTracks, setVideoTracks] = useState<any[]>([]);
  const [audioTracks, setAudioTracks] = useState<any[]>([]);
  const [screenTracks, setScreenTracks] = useState<any[]>([]);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(isAudio ? false : true);
  const [isScreenShareEnabled, setIsScreenShareEnabled] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const screenShareRef = useRef<HTMLVideoElement>(null);

  const trackPubsToTracks = (
    trackMap: Map<string, VideoTrackPublication | AudioTrackPublication>,
  ) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  const fetchUserData = useCallback(async (auth0Id: string) => {
    try {
      const user = await fetchBasicInfoByAuth0Id(auth0Id);
      setUser(user);
    } catch (ex) {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    const auth0Id = participant?.identity?.split(",").pop();
    auth0Id && fetchUserData(auth0Id);
  }, [fetchUserData, participant.identity]);

  useEffect(() => {
    setVideoTracks(trackPubsToTracks(participant.videoTracks));
    setAudioTracks(trackPubsToTracks(participant.audioTracks));

    const trackSubscribed = (track: Track) => {
      if (track.kind === "video") {
        setVideoTracks((prevVideoTracks) => [...prevVideoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((prevAudioTracks) => [...prevAudioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: Track) => {
      if (track.kind === "video") {
        setVideoTracks((prevVideoTracks) =>
          prevVideoTracks.filter((videoTrack) => videoTrack !== track),
        );
      } else if (track.kind === "audio") {
        setAudioTracks((prevAudioTracks) =>
          prevAudioTracks.filter((audioTrack) => audioTrack !== track),
        );
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    if (isLocal) {
      participant.on("trackPublished", (publication) => {
        if (publication.trackName === SCREEN_SHARE) {
          setIsScreenShareEnabled(true);

          const tempScreenTrack = trackPubsToTracks(
            participant.videoTracks,
          ).find((track) => track?.name === SCREEN_SHARE);

          if (tempScreenTrack) {
            setScreenTracks((prevScreenTrack) => [
              ...prevScreenTrack,
              tempScreenTrack,
            ]);
          }

          publication.on("trackDisabled", () => {
            setScreenTracks([]);
            setIsScreenShareEnabled(false);
          });
        }
      });
    }

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      setScreenTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, isLocal]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      audioTrack.on("enabled", (track: any) => {
        setIsAudioEnabled(true);
      });
      audioTrack.on("disabled", (track: any) => {
        setIsAudioEnabled(false);
      });

      return () => {
        audioTrack.detach();
        audioTrack.removeAllListeners();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      setIsVideoEnabled(videoTrack.isEnabled);
      videoTrack.attach(videoRef.current);

      videoTrack.on("enabled", (track: any) => {
        setIsVideoEnabled(true);
      });
      videoTrack.on("disabled", (track: any) => {
        setIsVideoEnabled(false);
      });

      return () => {
        videoTrack.detach();
        videoTrack.removeAllListeners();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const screenTrack = screenTracks[0];

    if (screenTrack) {
      screenTrack.attach(screenShareRef.current);

      return () => {
        screenTrack.disable();
        screenTrack.stop();
        screenTrack.detach();
        screenTrack.removeAllListeners();
      };
    }
  }, [screenTracks]);

  return (
    <div className="user">
      <div className="user-info">
        <div className="user-content">
          <span className="volume">
            <box-icon
              name={isAudioEnabled ? "volume-full" : "volume"}
            ></box-icon>
          </span>
          <span className="name">
            {(user && getFullName(user)) || participant.identity.split(",")[0]}
          </span>
        </div>
      </div>
      <div className={isVideoEnabled && !isScreenShareEnabled ? "" : "hidden"}>
        <video ref={videoRef} autoPlay={true} />
      </div>
      <div className={isScreenShareEnabled ? "" : "hidden"}>
        <video ref={screenShareRef} autoPlay={true} />
      </div>
      <div className={!isVideoEnabled && !isScreenShareEnabled ? "" : "hidden"}>
        <div className="call-avatar">
          {user?.avatar ? (
            <img src={user.avatar} alt="avatar" />
          ) : (
            getNameInitials(participant.identity.split(",")[0])
          )}
        </div>
      </div>
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
};

export default ParticipantView;
