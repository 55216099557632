import * as React from "react";
import { useHistory } from "react-router-dom";

import * as FS from "services/fullstory";
import { Role } from "constants/roles";
import { updateAvailabilityStatus } from "services/users";
import { getDataFromLocalStorage } from "services/localStorage";

import ManagerDashboard from "commons/components/layouts/ManagerDashboard";
import PractitionerDashboard from "commons/components/layouts/PractitionerDashboard";
import StudentDashboard from "commons/components/layouts/StudentDashboard";
import { subdomain } from "utils/http";
import { IProfile } from "commons/types/users";

interface IDashboardProps {}

const updateLastActive = async () => {
  try {
    const currentUser = getDataFromLocalStorage("profile");
    if (currentUser?._id) {
      await updateAvailabilityStatus(currentUser._id, {});
    }
  } catch (err: any) {
    console.error(err);
  }
};

const DashboardPage = (props: IDashboardProps): JSX.Element => {
  const history = useHistory();
  const currentUser: IProfile = getDataFromLocalStorage("profile");

  React.useEffect(() => {
    if (currentUser?._id) {
      updateLastActive();
      FS.identify(currentUser._id, {
        name: currentUser?.firstName,
        email: currentUser?.email,
      });
    }
  }, [currentUser?._id, currentUser?.firstName, currentUser?.email]);

  if (subdomain !== "admin") {
    if (!currentUser?.roles) {
      history.push("/");
    }
  }

  const role = currentUser?.roles && currentUser?.roles[0].name;

  if (role === Role.INTAKE_MANAGER) {
    return <ManagerDashboard />;
  } else if (role === Role.PRACTITIONER) {
    return <PractitionerDashboard />;
  } else if (currentUser.isStudentLoggedIn) {
    return <StudentDashboard />;
  } else {
    return <div />;
  }
};

export default DashboardPage;
