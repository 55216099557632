import React from "react";

import classNames from "classnames";

interface IAssignmentMenuProps {
  editMenu: () => void;
  markCompleted: () => void;
  deleteOpen: () => void;
  isMenuTop: boolean;
}

const Menu: React.FC<IAssignmentMenuProps> = ({
  editMenu,
  markCompleted,
  deleteOpen,
  isMenuTop,
}): JSX.Element => {
  return (
    <div
      className={classNames("menu-dialog", { "menu-dialog--top": isMenuTop })}
    >
      <ul className="menu-dialog__body">
        <li className="menu-list" onClick={markCompleted}>
          <box-icon name="check"></box-icon>
          <span className="ml-2x">Mark Completed</span>
        </li>
        <li className="menu-list" onClick={editMenu}>
          <box-icon name="edit-alt"></box-icon>
          <span className="ml-2x">Edit</span>
        </li>
        <li className="menu-list menu-list--danger" onClick={deleteOpen}>
          <box-icon name="trash-alt"></box-icon>
          <span className="ml-2x">Delete</span>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
