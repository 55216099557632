import { getDataFromLocalStorage } from "services/localStorage";
import Pusher from "pusher-js";
import Channel from "pusher-js/types/src/core/channels/channel";

import config from "configs";
import { EVENT } from "constants/pusher";
import { subdomain, getAccessToken } from "./http";

class PusherClass {
  private pusher: Pusher;

  private channel: Channel | undefined;

  constructor() {
    this.pusher = {} as Pusher;
  }

  getPusher = () => this.pusher;

  getChannel = () => this.channel;

  getUserChannelString = (id: string) => {
    const userChannelString = btoa(id);
    const channelString = `private-${userChannelString}_untapped`;
    return channelString;
  };

  subscribeToUserChannel = (userId: string) => {
    // If channel has been already subscribed, return the subscribe channel instance
    if (this.channel) {
      return this.channel;
    }

    const pusher = getDataFromLocalStorage("pusher");

    // Initialize channel
    const authEndpoint =
      config.serverBaseUrl + config.endpoints.users.pusherAuth;

    const pusherAppKey = pusher?.pusherAppKey;
    const cluster = pusher?.pusherCluster;

    this.pusher = new Pusher(pusherAppKey, {
      cluster,
      authEndpoint,
      auth: {
        headers: {
          "x-tenant-id": subdomain,
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    });

    // Handle Pusher disconnection
    this.pusher.connection.bind("disconnected", () => {
      console.info("Connection to Pusher disconnected!");
    });

    // Subscribe to user channel
    const channelString = this.getUserChannelString(userId);
    this.channel = this.pusher.subscribe(channelString);
    this.channel.bind(EVENT.SUBCRIPTION_SUCCESS, () =>
      console.log(`succeess connecting to ${channelString}`),
    );
    this.channel.bind(EVENT.SUBSCRIPTION_ERROR, (error: any) =>
      console.error(`error connecting to ${channelString}`, error),
    );
    return this.channel;
  };

  unSubscribeToUserChannel = (userId: string) => {
    const channelString = this.getUserChannelString(userId);
    this.pusher.unsubscribe(channelString);
    this.channel = undefined;
  };

  isAlreadySubscribedToChannel = () => {
    return !!this.channel;
  };
}

// Treat PusherClass a singleton class and just provide a single instance to the app
const pusherInstance = new PusherClass();

export default pusherInstance;
