import { IUser } from "commons/types/users";

/**
 * Returns full name of user by firstName and lastName
 * @param user IUser
 */
export const getFullName = (user: IUser) => {
  if (!user || (!user.firstName && !user.lastName && !user.name)) {
    return "";
  }
  if (user.name) return user.name;
  else if (user.firstName && !user.lastName) return user.firstName;
  else if (!user.firstName && user.lastName) return user.lastName;

  if (user.middleName)
    return `${user.firstName} ${user.middleName} ${user.lastName}`;

  return `${user.firstName} ${user.lastName}`;
};

/**
 * Returns initial 2 letters of the full name
 * @param user IUser
 */
export const getNameInitials = (user: IUser | string) => {
  if (!user) {
    return "NA";
  }
  if (typeof user === "string") {
    return `${
      (user.split(" ")[0][0] && user.split(" ")[0][0].toUpperCase()) || ""
    }${(user.split(" ")[1] && user.split(" ")[1][0].toUpperCase()) || ""}`;
  }
  return `${(user.firstName && user.firstName[0].toUpperCase()) || ""}${
    (user.lastName && user.lastName[0].toUpperCase()) || ""
  }`;
};
