import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as surveyService from "services/surveys";
import {
  ISurveyInitiate,
  ISurveysParams,
} from "../../../commons/types/surveys";

import {
  SendSurveysActionType,
  FetchSurveyCategoriesType,
  FetchSurveyResponsesActionType,
  FetchEarlySurveyResponsesActionType,
  SetSurveyRespondResultActionType,
  FetchIndividualSurveysActionType,
  FetchIndividualSurveyDetailActionType,
} from "../types/surveys";

export const getSurveyCategories = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchSurveyCategoriesType.GET_SURVEYS_PENDING,
  });

  try {
    const { data } = await surveyService.getSurveyCategories();
    dispatch({
      type: FetchSurveyCategoriesType.GET_SURVEYS_SUCCESSFULL,
      payload: data,
    });
  } catch (err: any) {
    dispatch({
      type: FetchSurveyCategoriesType.GET_SURVEYS_FAILED,
      payload: err,
    });
    error("Failed to fetch surveys.");
  }
};

export const sendSurveyInfo =
  (payload: ISurveyInitiate) => async (dispatch: Dispatch) => {
    dispatch({
      type: SendSurveysActionType.SEND_SURVEY_PENDING,
    });

    try {
      const { data } = await surveyService.sendSurveyInfo(payload);
      dispatch({
        type: SendSurveysActionType.SEND_SURVEY_SUCCESSFULL,
        data: data,
      });
      dispatch({
        type: FetchSurveyCategoriesType.GET_SURVEYS_PENDING,
      });
      success("Survey Sent Successfully");
      return data;
    } catch (err: any) {
      dispatch({
        type: SendSurveysActionType.SEND_SURVEY_FAILED,
        data: err,
      });
      error("Survey sending failed");
    }
  };

export const setSurveyRespondResult =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: SetSurveyRespondResultActionType.SET_SURVEY_RESPOND_RESULT_PENDING,
    });

    try {
      dispatch({
        type: SetSurveyRespondResultActionType.SET_SURVEY_RESPOND_RESULT_SUCCESSFUL,
        data: payload,
      });

      return payload;
    } catch (err: any) {
      dispatch({
        type: SetSurveyRespondResultActionType.SET_SURVEY_RESPOND_RESULT_FAILED,
        data: err,
      });
      error("Error setting respond");
    }
  };

export const fetchIndividualSurveysForStudent =
  (studentId: string, termId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchIndividualSurveysActionType.FETCH_INDIVIDUAL_SURVEYS_PENDING,
    });
    try {
      const fetchSurveysParams: ISurveysParams = {
        surveyFor: studentId,
        term: termId,
      };

      const { data: surveys } = await surveyService.fetchAllIndividualSurveys(
        fetchSurveysParams,
      );

      dispatch({
        type: FetchIndividualSurveysActionType.FETCH_INDIVIDUAL_SURVEYS_FULFILLED,
        data: surveys,
      });

      return surveys;
    } catch (ex) {
      dispatch({
        type: FetchIndividualSurveysActionType.FETCH_INDIVIDUAL_SURVEYS_REJECTED,
        data: ex,
      });

      error("Failed to fetch individual surveys");
    }
  };

export const fetchSurveyResponsesForStudent =
  (studentId: string, termId?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchSurveyResponsesActionType.FETCH_SURVEY_RESPONSE_PENDING,
    });
    try {
      const { data: surveys } =
        await surveyService.fetchSurveyResponsesForStudent(studentId, termId);

      dispatch({
        type: FetchSurveyResponsesActionType.FETCH_SURVEY_RESPONSE_FULFILLED,
        data: surveys,
      });

      return surveys;
    } catch (ex) {
      dispatch({
        type: FetchSurveyResponsesActionType.FETCH_SURVEY_RESPONSE_REJECTED,
        data: ex,
      });

      error("Failed to fetch individual surveys");
    }
  };

export const fetchEarlySurveyResponsesForStudent =
  (studentId: string, termId?: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchEarlySurveyResponsesActionType.FETCH_EARLY_SURVEY_RESPONSE_PENDING,
    });
    try {
      const { data: surveys } =
        await surveyService.fetchEarlySurveyResponsesForStudent(
          studentId,
          termId,
        );

      dispatch({
        type: FetchEarlySurveyResponsesActionType.FETCH_EARLY_SURVEY_RESPONSE_FULFILLED,
        data: surveys,
      });

      return surveys;
    } catch (ex) {
      dispatch({
        type: FetchEarlySurveyResponsesActionType.FETCH_EARLY_SURVEY_RESPONSE_REJECTED,
        data: ex,
      });

      error("Failed to fetch individual surveys");
    }
  };

export const fetchIndividualSurveyDetail =
  (surveyId: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchIndividualSurveyDetailActionType.FETCH_INDIVIDUAL_SURVEY_DETAIL_PENDING,
    });
    try {
      const { data: survey } = await surveyService.fetchIndividualSurveyDetail(
        surveyId,
      );

      dispatch({
        type: FetchIndividualSurveyDetailActionType.FETCH_INDIVIDUAL_SURVEY_DETAIL_FULFILLED,
        data: survey,
      });

      return survey;
    } catch (ex) {
      dispatch({
        type: FetchIndividualSurveyDetailActionType.FETCH_INDIVIDUAL_SURVEY_DETAIL_REJECTED,
        data: ex,
      });

      error("Failed to fetch individual survey detail");
    }
  };
