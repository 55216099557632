import {
  AddNoteActionType,
  FetchNotesActionType,
  UpdateNoteActionType,
} from "store/actions/types/notes";

const INITIAL_STATE = {
  addNote: {},
  addNoteLoading: false,
  addNoteError: null,
  updateNote: {},
  updateNoteLoading: false,
  updateNoteError: null,
  fetchNotes: [],
  fetchNotesLoading: true,
  fetchNotesError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddNoteActionType.ADD_NOTE_PENDING:
      return {
        ...state,
        addNoteLoading: true,
        addNoteError: null,
      };
    case AddNoteActionType.ADD_NOTE_FULFILLED:
      return {
        ...state,
        addNote: action.data,
        addNoteLoading: false,
        addNoteError: null,
      };
    case AddNoteActionType.ADD_NOTE_REJECTED:
      return {
        ...state,
        addNoteLoading: false,
        addNoteError: action.data,
      };

    case FetchNotesActionType.FETCH_NOTES_PENDING:
      return {
        ...state,
        fetchNotesLoading: true,
        fetchNotesError: null,
      };
    case FetchNotesActionType.FETCH_NOTES_FULFILLED:
      return {
        ...state,
        fetchNotes: action.data,
        fetchNotesLoading: false,
        fetchNotesError: null,
      };
    case FetchNotesActionType.FETCH_NOTES_REJECTED:
      return {
        ...state,
        fetchNotesLoading: false,
        fetchNotesError: action.data,
      };

    case UpdateNoteActionType.UPDATE_NOTE_PENDING:
      return {
        ...state,
        updateNoteLoading: true,
        updateNoteError: null,
      };
    case UpdateNoteActionType.UPDATE_NOTE_FULFILLED:
      return {
        ...state,
        updateNote: action.data,
        updateNoteLoading: false,
        updateNoteError: null,
      };
    case UpdateNoteActionType.UPDATE_NOTE_REJECTED:
      return {
        ...state,
        updateNoteLoading: false,
        updateNoteError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
