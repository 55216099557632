import React from "react";

type DeleteConfirmProps = {
  deleteResource: () => void;
  closeModal: () => void;
  message: { header: string; body?: string };
  confirmButtonLabel?: string;
};

const DeleteConfirm: React.FC<DeleteConfirmProps> = ({
  deleteResource,
  closeModal,
  message,
  confirmButtonLabel = "Delete",
}: DeleteConfirmProps): JSX.Element => {
  return (
    <div className="modal-wrap modal-wrap--sm confirm confirm--error">
      <div className="modal-wrap__body">
        <box-icon name="error-alt" />
        <h3>{message.header}</h3>
        <p>{message.body}</p>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--red mr-4x"
              type="button"
              onClick={deleteResource}
            >
              {confirmButtonLabel}
            </button>
            <button
              className="btn btn-text txt-primary-color"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirm;
