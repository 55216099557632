import React, { SyntheticEvent } from "react";
import DatePicker from "react-datepicker";

interface IDatePickerProps {
  onChange: (
    date: Date | [Date, Date] | null,
    event: SyntheticEvent<any, Event> | undefined,
  ) => void;
  onBlur: (event: any) => void;
  value?: any;
  id?: string;
  name: string;
  minDate?: any;
  maxDate?: any;
  inline?: boolean;
  isValid?: boolean;
  disabled?: boolean;
  dateFormat?: string;
  placeholder?: string;
  autoComplete?: string;
  highlightDates?: any[];
  showTimeSelect?: boolean;
  selected?: Date | string | null;
  onChangeRaw?: (event: any) => void;
}

const DateInput: React.FC<IDatePickerProps> = ({
  id,
  name,
  value,
  onBlur,
  minDate,
  maxDate,
  onChange,
  inline = false,
  disabled = false,
  isValid = true,
  selected = null,
  highlightDates = [],
  showTimeSelect = false,
  autoComplete = "off",
  dateFormat = "MM/dd/yyyy",
  placeholder = "Please select",
  onChangeRaw = (e) => e.preventDefault(),
}): JSX.Element => {
  const calendarItem = React.useRef<any>(null);

  return (
    <div className="date-container">
      <DatePicker
        ref={calendarItem}
        id={id || ""}
        onChange={onChange}
        dateFormat={dateFormat}
        name={name}
        autoComplete={autoComplete}
        className={`${isValid ? "input" : "input input--error"} ${
          disabled ? " input--disabled" : ""
        }`}
        selected={(selected && new Date(selected)) || null}
        placeholderText={placeholder}
        onBlur={onBlur}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        disabled={disabled}
        onChangeRaw={onChangeRaw}
        inline={inline}
        showTimeSelect={showTimeSelect}
        highlightDates={highlightDates}
      />
      <button
        type="button"
        className={`${disabled ? "cursor-default" : ""} btn date-calendar p-0x`}
        onClick={() => {
          if (calendarItem.current) {
            calendarItem.current.setFocus();
          }
        }}
      >
        <box-icon name="calendar" />
      </button>
    </div>
  );
};

export default DateInput;
