import "boxicons";
import dayjs from "dayjs";
import * as React from "react";

import { error, success } from "utils/toast";
import Loader from "commons/components/Loader";
import { ComponentStatus } from "commons/types/status";
import useMountedRef from "commons/hooks/useMountedRef";
import { deleteSchedule, list } from "services/scheduledMessages";
import { IScheduledMessageData, Steps } from "commons/types/scheduledMessages";

import tasksImage from "assets/images/tasks.svg";

interface ScheduledMessagesProps {
  onClose: () => void;
  goToHandler: (step: Steps) => void;
  editHandler: (id: string) => void;
  data: { studentId: string };
}

const ScheduledMessages: React.FC<ScheduledMessagesProps> = ({
  onClose,
  data,
  goToHandler,
  editHandler,
}) => {
  const [scheduledMessageData, setScheduledMessageData] =
    React.useState<IScheduledMessageData>({
      status: ComponentStatus.Idle,
      data: [],
    });

  const isMounted = useMountedRef();

  const getScheduledMessagesList = React.useCallback(
    async (receiver: string) => {
      setScheduledMessageData((currentState) => ({
        status: ComponentStatus.Loading,
        data: currentState.data,
      }));

      try {
        const response = await list(receiver);
        if (isMounted) {
          setScheduledMessageData({
            status: ComponentStatus.Loaded,
            data: response.data,
          });
        }
      } catch (err: any) {
        if (isMounted) {
          setScheduledMessageData({
            status: ComponentStatus.Error,
            data: [],
          });
        }
        error(err?.message || "Failed to fetch scheduled messages");
      }
    },
    [setScheduledMessageData, isMounted],
  );

  React.useEffect(() => {
    getScheduledMessagesList(data.studentId);
  }, [getScheduledMessagesList, data.studentId]);

  const deleteHandler = async (id: string) => {
    setScheduledMessageData((prevState) => ({
      data: prevState.data,
      status: ComponentStatus.Loading,
    }));

    try {
      const res = await deleteSchedule(id);
      success(res.message || "Scheduled message deleted successfully");
      if (isMounted) {
        getScheduledMessagesList(data.studentId);
      }
    } catch (err: any) {
      error(err.message || "Error in scheduled message deletion");
      if (isMounted) {
        setScheduledMessageData({
          data: [],
          status: ComponentStatus.Error,
        });
      }
    }
  };
  const getFormattedDate = (date: string) => {
    if (!date) {
      return "";
    }

    return dayjs(date).format("ddd, MMM D [at] hh:mm a");
  };

  const getScheduledMessageBlock = () => {
    switch (scheduledMessageData.status) {
      case ComponentStatus.Loaded:
        if (!scheduledMessageData.data?.length) {
          return (
            <div className="empty-section mt-20x">
              <div className="empty-section__content empty-section__modal-content">
                <img src={tasksImage} alt="Scheduled messages" />
                <h3>You currently do not have any scheduled messages.</h3>
                <p>
                  Go out and have some fun, or create a new scheduled messages.
                </p>
              </div>
            </div>
          );
        }
        return scheduledMessageData.data.map((scheduledMessage) => (
          <div className="card card-addinfo" key={scheduledMessage._id}>
            <div className="card-content">
              <div className="card-text">
                <span className="d-block card-subheading">
                  "{scheduledMessage.metaData.message}"
                </span>
                <ul className="card-info card-info--vertical">
                  <li className="mt-1x">
                    <box-icon name="history"></box-icon>Scheduled for{" "}
                    {getFormattedDate(scheduledMessage.scheduledFor)}
                  </li>
                  {scheduledMessage.metaData.note ? (
                    <li className="mt-1x">
                      <box-icon name="note"></box-icon>
                      {scheduledMessage.metaData.note}
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="card-actions">
              <button
                className="btn p-0x"
                title="Edit"
                onClick={() => editHandler(scheduledMessage._id)}
              >
                <box-icon name="edit-alt"></box-icon>
              </button>
              <button
                className="btn p-0x"
                title="Delete"
                onClick={() => deleteHandler(scheduledMessage._id)}
              >
                <box-icon name="trash-alt"></box-icon>
              </button>
            </div>
          </div>
        ));

      case ComponentStatus.Error:
        return (
          <div className="d-flex flex-column align-items-center">
            <p>Error when loading data </p>
            <button
              className="btn btn--primary mt-4x"
              type="submit"
              onClick={() => getScheduledMessagesList(data.studentId)}
            >
              Reload
            </button>
          </div>
        );

      case ComponentStatus.Loading:
        return <Loader type="ThreeDots" />;

      case ComponentStatus.Idle:
      default:
        return <div>Idle status</div>;
    }
  };

  return (
    <div className="modal-wrap modal-wrap--lg scheduled-messages--modal-width">
      <div className="modal-wrap__header align-items-center">
        <h3>Scheduled Messages</h3>
        <div className="d-flex">
          <button
            className="btn p-0x pr-2x"
            onClick={() => goToHandler(Steps.ScheduleMessage)}
            title="Schedule new message"
          >
            <box-icon
              name="plus-circle"
              type="solid"
              color="#1D70B8"
            ></box-icon>
          </button>
          <button className="btn p-0x" onClick={onClose} title="Close">
            <box-icon name="x"></box-icon>
          </button>
        </div>
      </div>
      <div className="modal-wrap__body modal-wrap__body--gray modal-wrap__body--scroll">
        <section className="section">
          <div className="section__content">{getScheduledMessageBlock()}</div>
        </section>
      </div>
    </div>
  );
};

export default ScheduledMessages;
