import dayjs from "dayjs";
import { getFullName } from "utils/names";
import { IGoal } from "commons/types/goals";
import { PRIORITY_LEVEL_OPTIONS } from "constants/index";
import { getDateInFormat, getDayFromDate } from "utils/dates";
import { IPlanningPeriod } from "commons/types/planningPeriod";

interface ITaskDetailProps {
  task: IGoal;
  planningPeriod?: IPlanningPeriod;
  closeModal: () => void;
  publishTaskHandler: (id: string) => void;
  completeTaskHandler: (id: string) => void;
  inCompleteTaskHandler: (id: string) => void;
}

const TaskDetail: React.FC<ITaskDetailProps> = ({
  task,
  closeModal,
  planningPeriod,
  publishTaskHandler,
  completeTaskHandler,
  inCompleteTaskHandler,
}) => {
  const getTaskPriority = (task: IGoal) => {
    if (task.workData?.priority) {
      const priorities = PRIORITY_LEVEL_OPTIONS.filter(
        (option: { value: string }) => option.value === task.workData?.priority,
      );

      if (priorities.length) return priorities[0].label;
    }

    return "-";
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Task Details</h3>
        <span onClick={closeModal} className="link-item">
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <div className="modal-wrap__body p-0x">
        <div className="task">
          <div className="task__info">
            <h3>{task.name}</h3>
            <ul className="task-date">
              <li>
                <box-icon name="calendar"></box-icon>
                <span>
                  {planningPeriod
                    ? `${getDateInFormat(
                        planningPeriod?.startDate,
                      )}-${getDateInFormat(planningPeriod?.endDate)}`
                    : "-"}
                </span>
              </li>
              <li
                className={`status ${
                  dayjs(dayjs(new Date())).isAfter(
                    dayjs(task.requiredDeadline),
                    "day",
                  )
                    ? "status--error"
                    : "status--warning"
                }`}
              >
                {task.workData?.priority === "Priority" && (
                  <box-icon name="error-alt" color="#ff1154"></box-icon>
                )}
                {getDayFromDate(task.requiredDeadline, true) === "Today" ? (
                  <p className="color-tertiary-red-80">Today</p>
                ) : (
                  <p>{getDayFromDate(task.requiredDeadline, true)}</p>
                )}
              </li>
            </ul>
          </div>
          <div className="task__details">
            <h4>More information</h4>
            <ul className="task-list">
              <li>
                <box-icon name="bullseye"></box-icon>
                <div className="task-list__content">
                  <span>
                    {task.childOf?.workData.workType
                      ? `Goal (${task.childOf?.workData.workType})`
                      : "Goal"}
                  </span>
                  {task.childOf?.name || "-"}
                </div>
              </li>
              <li>
                <box-icon name="error-alt"></box-icon>
                <div className="task-list__content">
                  <span>Priority</span>
                  {getTaskPriority(task)}
                </div>
              </li>
              <li>
                <box-icon name="user"></box-icon>
                <div className="task-list__content">
                  <span>Created by</span>
                  {getFullName(task.createdBy)} on{" "}
                  {task.createdAt
                    ? dayjs(new Date(task.createdAt)).format(
                        "MMM D, YYYY h:mm a",
                      )
                    : "Not Found"}
                </div>
              </li>
              <li>
                <box-icon name="user"></box-icon>
                <div className="task-list__content">
                  <span>Last updated by</span>
                  {task.updatedBy ? (
                    <>
                      {getFullName(task.updatedBy)} on{" "}
                      {task.updatedAt
                        ? dayjs(new Date(task.updatedAt)).format(
                            "MMM D, YYYY h:mm a",
                          )
                        : "Not Found"}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </li>
              <li>
                <div className="row">
                  <div className="col-6 d-flex">
                    <box-icon name="calendar"></box-icon>
                    <div className="task-list__content">
                      <span>To-do date</span>
                      {task.requiredDeadline
                        ? dayjs(new Date(task.requiredDeadline)).format(
                            "MMM D, YYYY",
                          )
                        : "None"}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="task-list__content">
                      <span>Due date</span>
                      {task.workData?.optionalDeadline
                        ? dayjs(
                            new Date(task.workData?.optionalDeadline),
                          ).format("MMM D, YYYY")
                        : "None"}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            {!task.isPublished ? (
              <button
                onClick={() => {
                  if (task._id) publishTaskHandler(task._id);
                }}
                className="btn btn--primary mr-4x"
                type="button"
              >
                Publish
              </button>
            ) : !task.isCompleted ? (
              <button
                onClick={() => {
                  if (task._id) completeTaskHandler(task._id);
                }}
                className="btn btn--green mr-4x"
                type="button"
              >
                Mark as Completed
              </button>
            ) : (
              <button
                onClick={() => {
                  if (task._id) inCompleteTaskHandler(task._id);
                }}
                className="btn btn--red mr-4x"
                type="button"
              >
                Mark as Incomplete
              </button>
            )}
            <button
              onClick={closeModal}
              className="btn txt-primary-color"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;
