import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import config from "configs";
import { getRole } from "utils/roles";
import { Role } from "constants/roles";
import {
  TextInput,
  OptionsInput,
  CustomSelect,
} from "commons/components/form-fields";
import { Status } from "constants/status";
import { ILocation } from "commons/types";
import { success, error } from "utils/toast";
import { addMentor } from "services/mentors";
import { fetchLocations } from "services/tenant";
import mentorSchema from "commons/schemas/mentor";
import { CustomErrorMessages } from "constants/errors";
import { Environment } from "constants/learningEnvironment";
import { DUPLICATE_USER_STATUS_CODE } from "constants/index";
import { IFormValues } from "../update/updateparams.interface";
import { getDataFromLocalStorage } from "services/localStorage";
import PhoneNumber from "commons/components/form-fields/PhoneNumber";

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  environment: [],
  learningCenter: [],
  status: "",
  roles: [{ _id: "", name: "" }],
};

const RegistrationForm = () => {
  const [center, setCenter] = useState<any>([]);
  const [centers, setCenters] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const roles = getDataFromLocalStorage("roles");

  const formik: any = useFormik({
    initialValues: INITIAL_VALUES,

    onSubmit: async (values, { setFieldError }) => {
      try {
        const practitioner = getRole(roles, Role.PRACTITIONER);
        if (!practitioner) {
          return error(CustomErrorMessages.ROLE_MISSING);
        }
        const mentorData = { ...values };
        const { _id, name } = practitioner;
        mentorData.roles = [{ _id, name }];

        // To change array input of checkbox into string enum of Environment
        const toSubmit: IFormValues = {
          ...mentorData,
          environment:
            values.environment.length > 1
              ? Environment.HYBRID
              : values.environment[0],
        };
        await addMentor(toSubmit);
        success("New mentor registered");
        history.push(config.uiPath.mentors.list);
      } catch (err: any) {
        if (err.response?.status === DUPLICATE_USER_STATUS_CODE) {
          setFieldError("email", "Email already exists");
          error("Email already exists");
        } else {
          error("Mentor registration failed");
        }
      }
    },

    validationSchema: mentorSchema,
  });

  useEffect(() => {
    const fetchLearningCenters = async () => {
      const { data } = await fetchLocations();
      const centerOptions = data.map((location: ILocation) => {
        return { value: location._id, label: location.name };
      });
      setCenters(centerOptions);
    };
    fetchLearningCenters();
  }, []);

  const getEnvironmentOptions = (formik: any) => [
    {
      id: "remote",
      label: t("mentorForm.learningEnv.options.remote"),
      value: Environment.REMOTE,
      isChecked: formik.values.environment.includes(Environment.REMOTE),
    },
    {
      id: "in-person",
      label: t("mentorForm.learningEnv.options.inPerson"),
      value: Environment.IN_PERSON,
      isChecked: formik.values.environment.includes(Environment.IN_PERSON),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <section className="section">
        <div className="row">
          <div className="col-6">
            <TextInput
              label={t("mentorForm.firstName.label")}
              name={"firstName"}
              placeholder={t("mentorForm.firstName.placeholder")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
          <div className="col-6">
            <TextInput
              label={t("mentorForm.lastName.label")}
              name={"lastName"}
              placeholder={t("mentorForm.lastName.placeholder")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TextInput
              label={t("mentorForm.email.label")}
              name={"email"}
              placeholder={t("mentorForm.email.placeholder")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
          <div className="col-6">
            <PhoneNumber
              label={t("mentorForm.phone.label")}
              name={"phone"}
              placeholder={t("mentorForm.phone.placeholder")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mb-3x required">
              {t("mentorForm.learningEnv.label")}
            </div>
            <OptionsInput
              type={"checkbox"}
              groupName={"environment"}
              options={getEnvironmentOptions(formik)}
              handleChange={formik.handleChange}
              errors={formik.errors}
              touched={formik.touched}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CustomSelect
              label={t("mentorForm.learningCenter.label")}
              name={"learningCenter"}
              value={center}
              isMulti={true}
              options={centers}
              handleChange={(value: string, formikFieldValue: any) => {
                formik.setFieldValue("learningCenter", formikFieldValue);
                setCenter(value);
              }}
              touched={formik.touched}
              errors={formik.errors}
              required={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mb-3x required">{t("mentorForm.status.label")}</div>
            <OptionsInput
              type={"radio"}
              groupName={"status"}
              className="mb-0x"
              options={[
                {
                  id: "active",
                  label: t("mentorForm.status.options.active"),
                  value: Status.ACTIVE,
                  isChecked: formik.values.status === Status.ACTIVE,
                },
                {
                  id: "in-active",
                  label: t("mentorForm.status.options.inActive"),
                  value: Status.INACTIVE,
                  isChecked: formik.values.status === Status.INACTIVE,
                },
              ]}
              handleChange={formik.handleChange}
              errors={formik.errors}
              touched={formik.touched}
            />
          </div>
        </div>
      </section>
      <section className="section mb-4x">
        <div className="section_content">
          <div className="row">
            <div className="col-9">
              <p className="text-regular">{t("mentorForm.info")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section mb-5x">
        <div className="section_content">
          <div className="row">
            <div className="col-9">
              <h3 className="text-color-darkgray">{t("mentorForm.note")}</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="section__content">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {t("mentorForm.addMentorBtnLabel")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};

export default RegistrationForm;
