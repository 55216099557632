import config from "configs";
import { Params } from "commons/types/urls";
import { http, stringify } from "utils/http";
import { IInstitutionParams } from "commons/types/institutions";

export const fetchDistricts = async (params: Params = {}): Promise<any> => {
  const { data } = await http.get(config.endpoints.districts.all);
  return data;
};

export const fetchSchools = async (params: Params = {}): Promise<any> => {
  const url = stringify(config.endpoints.institutions.all, {
    type: "School",
    ...params,
  });
  const { data } = await http.get(url);

  return data;
};

export const fetchUniversities = async (params: Params = {}): Promise<any> => {
  const url = stringify(config.endpoints.institutions.all, {
    type: "University",
    ...params,
  });
  const { data } = await http.get(url);

  return data;
};

export const fetchInstitutions = async (
  params: IInstitutionParams = {},
): Promise<any> => {
  const url = stringify(config.endpoints.institutions.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const fetchSatelliteLocations = async (params = {}) => {
  const url = stringify(config.endpoints.satelliteLocations.all, params);
  const { data } = await http.get(url);

  return data;
};

export const fetchOperatingEntities = async (params = {}) => {
  const url = stringify(config.endpoints.operatingEntities.all, params);
  const { data } = await http.get(url);

  return data;
};

export const fetchLocations = async () => {
  const { data } = await http.get(config.endpoints.locations.all);
  return data;
};

export const fetchPrograms = async () => {
  const { data } = await http.get(config.endpoints.programs.all);
  return data;
};
