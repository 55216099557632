import React from "react";

import Form from "../Form";
import { IEditAccommodationProps } from "./editAccommodation.interface";

const EditAccommodation: React.FC<IEditAccommodationProps> = ({
  initialValues,
  closeModal,
  updateAccommodation,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Edit an Accommodation</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        isEdit={true}
        initialValues={initialValues}
        closeModal={closeModal}
        updateAccommodation={updateAccommodation}
      />
    </div>
  );
};

export default EditAccommodation;
