import "boxicons";
import Modal from "react-modal";
import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";
import { subdomainName } from "utils/http";

const MentorStudentProfile = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="nav__link nav__link--active">Student</span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="arrow-back"></box-icon>
                    Go Back
                  </span>
                </li>
                <li className="nav-left__item--title">Samantha Doe</li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Plans
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="bullseye"></box-icon>
                    Goals
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="book-open"></box-icon>
                    Assignments
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar"></box-icon>
                    Sessions
                  </span>
                </li>
                <li className="nav-left__item--title">
                  Additional information
                </li>
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="user"></box-icon>
                    Personal Info
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="graduation" type="solid"></box-icon>
                    Academic Info
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="avatar avatar--sm mt-3x">
                    <img src={student1} alt="Student" />
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-sm fs-14">Samantha Doe</span>
                    <h2>Personal Info</h2>
                  </div>
                </div>
              </div>
              <section className="section ml-10x">
                <div className="section__content">
                  <div className="card card-profile">
                    <div className="card-content">
                      <div className="avatar avatar--lg mr-2x">
                        <img src={student1} alt="Student" />
                      </div>
                      <div className="card-text">
                        <h3>Profile picture</h3>
                        <div className="d-flex link-text fs-small">
                          Upload new one
                          <span className="status status--error ml-2x">
                            Remove
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section ml-10x">
                <div className="section__title mb-0x">
                  <h3>Student Information</h3>
                  <div className="actions">
                    <span className="link-item">
                      <box-icon name="edit-alt"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="section__content">
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">Student Name</span>
                        <span className="d-block">Samantha Doe</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">Nick Name</span>
                        <span className="d-block">Sam</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">Email address</span>
                        <span className="d-block">samantha@school.edu</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">Grade</span>
                        <span className="d-block">6</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">
                          Academic year (Term)
                        </span>
                        <span className="d-block">2020/2021 (Quarter)</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">
                          {subdomainName} Learning Term
                        </span>
                        <span className="d-block">2021 Spring</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">
                          Learning environment
                        </span>
                        <span className="d-block">Hybrid</span>
                      </div>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="card-address">Learning Location</span>
                        <span className="d-block">
                          Springfield Satellite Center, Boulder CO
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section ml-10x">
                <div className="section__title mb-0x">
                  <h3>Planning Period</h3>
                </div>
                <div className="section__content">
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="d-block">Weekly</span>
                        <span className="card-address">
                          Thursdays at 3:00 pm
                        </span>
                      </div>
                    </div>
                    <div className="card-actions">
                      <span className="link-item">
                        <box-icon name="edit-alt"></box-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section ml-10x">
                <div className="section__title mb-0x">
                  <h3>Parent Information</h3>
                  <div className="actions">
                    <span className="link-item mr-4x">
                      <box-icon name="search"></box-icon>
                    </span>
                    <span className="link-item">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="section__content">
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="d-block">John Doe</span>
                        <span className="card-address">
                          +1 782 191 1193 (john@doe.com)
                        </span>
                      </div>
                    </div>
                    <div className="card-actions">
                      <span className="link-item">
                        <box-icon name="edit-alt"></box-icon>
                      </span>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="d-block">Mark Doe</span>
                        <span className="card-address">
                          +1 782 191 1193 (john@doe.com)
                        </span>
                      </div>
                    </div>
                    <div className="card-actions">
                      <span className="link-item">
                        <box-icon name="edit-alt"></box-icon>
                      </span>
                      <span className="link-item">
                        <box-icon name="trash-alt"></box-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section ml-10x">
                <div className="section__title mb-0x">
                  <h3>Login Credentials</h3>
                  <div className="actions">
                    <span className="link-item">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="section__content">
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="d-block">Student portal</span>
                        <span className="card-address">
                          https://schoolinfo.edu/studentportal.jspx
                        </span>
                      </div>
                    </div>
                    <div className="card-actions">
                      <span className="link-item">
                        <box-icon name="show-alt"></box-icon>
                      </span>
                      <span className="link-item">
                        <box-icon name="trash-alt"></box-icon>
                      </span>
                    </div>
                  </div>
                  <div className="card card-addinfo">
                    <div className="card-content">
                      <div className="card-text">
                        <span className="d-block">Assignment portal</span>
                        <span className="card-address">
                          https://homeworkcentral.edu
                        </span>
                      </div>
                    </div>
                    <div className="card-actions">
                      <span className="link-item">
                        <box-icon name="show-alt"></box-icon>
                      </span>
                      <span className="link-item">
                        <box-icon name="trash-alt"></box-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
              <div className="ml-10x">
                <span className="viewmore">
                  <box-icon name="chevron-down"></box-icon>
                  View diagnoses
                </span>
              </div>
            </div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <h3>Messages</h3>
                  <div className="message-actions">
                    <span className="action-links active">
                      <span>Available</span>
                      <box-icon name="alarm-snooze"></box-icon>
                    </span>
                    <span className="action-links default">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content">
                  <div className="message-list unread">
                    <div className="avatar-wrap active">
                      <div className="avatar mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Samantha Doe <span>(Grade 7)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">Just now</span>
                        <span className="msg-preview text-ellipsis">
                          Hi! researched but I dont know the answer.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">BP</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Brendan Pollock <span>(Homework Center)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Yes thanks! I will ask the student.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">LD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Lori Denver <span>(Grade 9)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">Okay</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">JD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        John Doe <span>(Samantha's Parent)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Sure. I will ask Sam about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="modal-wrap modal-wrap--lg">
          <div className="modal-wrap__header">
            <h3>Scheduled Messages</h3>
            <span className="link-item">
              <box-icon name="x"></box-icon>
            </span>
          </div>
          <div className="modal-wrap__body modal-wrap__body--gray">
            <section className="section">
              <div className="section__content">
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        "Hey Samantha, how is everything going? Did you talk to
                        your counselor to fix your schedule and move around your
                        elective?"
                      </span>
                      <ul className="card-info">
                        <li>
                          <box-icon name="history"></box-icon>Scheduled for Thu,
                          Mar 2 at 5:40 pm
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span className="link-item">
                      <box-icon name="edit-alt"></box-icon>
                    </span>
                    <span className="link-item">
                      <box-icon name="trash-alt"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        "Hey, how is studying for your math test coming along?"
                      </span>
                      <ul className="card-info card-info--vertical">
                        <li>
                          <box-icon name="history"></box-icon>Scheduled for Fri,
                          Mar 3 at 9:00 am
                        </li>
                        <li>
                          <box-icon name="note"></box-icon>Math test on Friday
                          11/7
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span className="link-item">
                      <box-icon name="edit-alt"></box-icon>
                    </span>
                    <span className="link-item">
                      <box-icon name="trash-alt"></box-icon>
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="section__title mb-0x">
                <h3>Beginning of semester</h3>
              </div>
              <div className="section__content">
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        “Hey Samantha, how were your first few days of high
                        school? Did you get the code to sign in to your math
                        class on schoology?”
                      </span>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span className="link-item">
                      <box-icon name="check"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        “How is your week going? Did you figure out your
                        Infinite Campus information?”
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        “Happy birthday! Have fun with your family and friends
                        tonight!”
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="d-block">
                        "Glad you got your schedule figured out, how was your
                        first day in pottery?"
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="modal-wrap__footer">
            <div className="row">
              <div className="col-12 d-flex">
                <button className="btn btn--primary mr-4x" type="button">
                  Schedule message
                </button>
                <button className="btn" type="button">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MentorStudentProfile;
