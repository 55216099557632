import * as Yup from "yup";

import {
  CADENCE,
  NAME_LENGTH,
  PHONE_LENGTH,
  MESSAGE_LENGTH,
  INSTITUTION_TYPES,
  LEARNING_ENVIRONMENTS,
} from "../../constants";
import { PHONE_REGEX } from "constants/regex";
import { StatusOptions } from "constants/status";

const sponsorsSchema = Yup.array().of(
  Yup.object().shape({
    userId: Yup.string().nullable(),
    relationshipWithSponsor: Yup.string().nullable(),
    livingScheduleWithParents: Yup.string().nullable(),
  }),
);

const sponsorsRequiredSchema = sponsorsSchema.min(1, "Guardian is required");

const practitionersSchema = Yup.array().of(
  Yup.object().shape({
    userId: Yup.string(),
    status: Yup.string(),
    email: Yup.string(),
  }),
);

export const enrollmentSchema = Yup.object().shape({
  learningCenter: Yup.string(),
  satelliteLocation: Yup.string().nullable(),
  enrolledTerm: Yup.string().nullable(),
  enrollmentDate: Yup.string().nullable(),
  mentorshipNote: Yup.string(),
  mentorshipSchedule: Yup.object().shape({
    latest: Yup.object().shape({
      cadence: Yup.string().oneOf(CADENCE).nullable(),
      day: Yup.string().nullable(),
    }),
  }),
});

export const addMentorSchema = Yup.object().shape({
  userId: Yup.string().required("Please select mentor"),
  mentorshipNote: Yup.string(),
});

export const studentSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("First name is required")
    .max(NAME_LENGTH, `First name must be less than ${NAME_LENGTH} characters`),
  lastName: Yup.string()
    .trim()
    .required("Last name is required")
    .max(NAME_LENGTH, `Last name must be less than ${NAME_LENGTH} characters`),
  middleName: Yup.string().optional(),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  newEmail: Yup.string()
    .email("Invalid email")
    .when("isEmailChangeActive", {
      is: false,
      then: Yup.string().email("Invalid email."),
      otherwise: Yup.string()
        .email("Invalid email.")
        .test(
          "testDuplicateEmail",
          "Email is same as current email",
          function (newEmail) {
            return newEmail?.toLowerCase() !== this.parent?.email.toLowerCase();
          },
        )
        .required("Required"),
    }),
  newEmailConfirm: Yup.string().when("isEmailChangeActive", {
    is: false,
    then: Yup.string().email("Invalid email."),
    otherwise: Yup.string()
      .email("Invalid email.")
      .oneOf([Yup.ref("newEmail"), null], "Emails don't match")
      .required("Required"),
  }),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, "Phone number is not valid")
    .max(
      PHONE_LENGTH,
      `Phone number must be less than ${PHONE_LENGTH} characters`,
    )
    .required("Phone number is required"),
  locations: Yup.array().of(Yup.string()),
  timezone: Yup.string().required("Time Zone is Required"),
  status: Yup.string().oneOf(StatusOptions),
  dateOfBirth: Yup.string().nullable(),
  userData: Yup.object().shape({
    nickName: Yup.string().optional(),
    learningEnvironment: Yup.string()
      .oneOf(
        LEARNING_ENVIRONMENTS,
        `Learning environment must be one of: ${LEARNING_ENVIRONMENTS}`,
      )
      .required("Learning environment must be selected"),
    status: Yup.string().nullable(),
    diagnosis: Yup.string().max(
      MESSAGE_LENGTH,
      `Diagnosis must be less than ${MESSAGE_LENGTH} characters`,
    ),
    isGuardianApplicable: Yup.boolean().nullable(),
    enrollments: Yup.array().of(enrollmentSchema),
    schoolInfo: Yup.object().shape({
      isSchoolInfoApplicable: Yup.boolean(),
      institution: Yup.string(),
      schoolType: Yup.string().oneOf(INSTITUTION_TYPES),
      schoolDistrict: Yup.string(),
      studentType: Yup.string(),
      grade: Yup.string(),
      academicYear: Yup.string(),
      academicTerm: Yup.string(),
      notes: Yup.string(),
      accommodation: Yup.string(),
      isOnAcademicProbation: Yup.boolean(),
    }),
  }),
  relatedUsers: Yup.object()
    .shape({
      sponsors: sponsorsSchema,
      practitioners: practitionersSchema,
    })
    .when("userData.isGuardianApplicable", {
      is: true,
      then: Yup.object().shape({
        sponsors: sponsorsRequiredSchema,
        practitioners: practitionersSchema,
      }),
    }),
});

export const DropReasonSchema = Yup.object().shape({
  dropReason: Yup.string().trim().required("Drop reason is required"),
  otherReason: Yup.string()
    .trim()
    .when("dropReason", {
      is: (val: string) => val === "Other",
      then: Yup.string().required("Other reason description is required"),
    }),
});
