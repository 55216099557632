import {
  IAssessmentItem,
  SurveyListItem,
  SurveyResponse,
} from "commons/types/surveys";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { connect } from "react-redux";
import { RootState } from "store/reducers";
import AssessmentItems from "./AssessmentItems";
import { IUser } from "../../../commons/types/users";
import { getAggregatedAssessments } from "services/assessments";

interface AggregatedAssessmentProps {
  student: IUser;
  individualAssessments: Array<SurveyListItem>;
  fetchIndividualAssessmentLoading: boolean;
  surveyResponses: SurveyResponse[];
  fetchSurveyResponsesLoading: boolean;
}

const AggregatedAssessments = (props: AggregatedAssessmentProps) => {
  const {
    individualAssessments,
    fetchIndividualAssessmentLoading,
    surveyResponses,
    fetchSurveyResponsesLoading,
  } = props;
  const [aggregatedAssessments, setAggregatedAssessments] = useState<
    IAssessmentItem[]
  >([]);

  useEffect(() => {
    const assessmentAggregated = getAggregatedAssessments(
      individualAssessments,
      surveyResponses,
    );
    setAggregatedAssessments(assessmentAggregated);
  }, [individualAssessments, surveyResponses]);

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="accordion"
      preExpanded={["personal-info-aggregated-assessment"]}
    >
      <AccordionItem
        uuid={"personal-info-aggregated-assessment"}
        className="accordion-item"
      >
        <AccordionItemState>
          {({ expanded }) => (
            <>
              <AccordionItemHeading className="p-3x">
                <AccordionItemButton className="accordion-item__title pl-0x">
                  <div className="title-left">
                    <div className="section__title">
                      <h3 className="txt-darkgrey-color">Aggregated</h3>
                    </div>
                  </div>
                  <div className="title-right">
                    <span>
                      <box-icon
                        name={!expanded ? "chevron-down" : "chevron-up"}
                      />
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-item__content">
                <AssessmentItems
                  student={props.student}
                  isAggregated={true}
                  assessments={aggregatedAssessments}
                  isAssessmentItemsLoading={
                    fetchIndividualAssessmentLoading ||
                    fetchSurveyResponsesLoading
                  }
                />
              </AccordionItemPanel>
            </>
          )}
        </AccordionItemState>
      </AccordionItem>
    </Accordion>
  );
};

const mapStateToProps = (state: RootState) => ({
  fetchIndividualAssessmentLoading: state.surveys.fetchIndividualSurveyLoading,
  individualAssessments: state.surveys.individualSurveys,
  surveyResponses: state.surveys.surveyResponses,
  fetchSurveyResponsesLoading: state.surveys.fetchSurveyResponsesLoading,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AggregatedAssessments);
