import "boxicons";
import logo from "assets/images/boost_logo.svg";

const Dashboard = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="link-item user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap pt-8x">
        <div className="container">
          <div className="page-heading">
            <h2>Students</h2>
            <div className="page-heading__right">
              <div className="filter-group">
                <div className="input-wrap input-wrap--icon-right mb-0 mr-2x">
                  <input
                    type="text"
                    placeholder="Resting placeholder"
                    className="input"
                  />
                  <span className="form-icon">
                    <box-icon
                      name="search"
                      size="sm"
                      color="#959FAE"
                    ></box-icon>
                  </span>
                </div>
                <button className="btn btn--primary mr-2x" type="button">
                  New student
                </button>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <tr className="head-row">
                <th className="table__col">Student Name</th>
                <th className="table__col">Location</th>
                <th className="table__col">Parent Name</th>
                <th className="table__col">Mentor</th>
                <th className="table__col">Term</th>
                <th className="table__col">Enrollment Date</th>
                <th className="table__col">Status</th>
                <th className="table__col">Actions</th>
              </tr>
              <tr className="table__row">
                <td className="table__col">
                  <span className="link-text">Clarke Arthur</span>
                </td>
                <td className="table__col">Arizona</td>
                <td className="table__col">Dana Arthur</td>
                <td className="table__col">Jodie Bond</td>
                <td className="table__col">Summer 2021</td>
                <td className="table__col">27 Apr 2021</td>
                <td className="table__col">Enrolled</td>
                <td className="table__col">
                  <div className="action-group">
                    <box-icon name="user-x"></box-icon>
                    <box-icon name="edit-alt"></box-icon>
                    <box-icon name="archive"></box-icon>
                  </div>
                </td>
              </tr>
              <tr className="table__row">
                <td className="table__col">
                  <span>Clarke Arthur</span>
                </td>
                <td className="table__col">Arizona</td>
                <td className="table__col">Dana Arthur</td>
                <td className="table__col">Jodie Bond</td>
                <td className="table__col">Summer 2021</td>
                <td className="table__col">27 Apr 2021</td>
                <td className="table__col">Enrolled</td>
                <td className="table__col">
                  <div className="action-group">
                    <box-icon name="edit-alt"></box-icon>
                    <box-icon name="archive"></box-icon>
                  </div>
                </td>
              </tr>
              <tr className="table__row">
                <td className="table__col">
                  <span>Clarke Arthur</span>
                </td>
                <td className="table__col">Arizona</td>
                <td className="table__col">Dana Arthur</td>
                <td className="table__col">Jodie Bond</td>
                <td className="table__col">Summer 2021</td>
                <td className="table__col">27 Apr 2021</td>
                <td className="table__col">Enrolled</td>
                <td className="table__col">
                  <div className="action-group">
                    <box-icon name="edit-alt"></box-icon>
                    <box-icon name="archive"></box-icon>
                  </div>
                </td>
              </tr>
              <tr className="table__row">
                <td className="table__col">
                  <span>Clarke Arthur</span>
                </td>
                <td className="table__col">Arizona</td>
                <td className="table__col">Dana Arthur</td>
                <td className="table__col">Jodie Bond</td>
                <td className="table__col">Summer 2021</td>
                <td className="table__col">27 Apr 2021</td>
                <td className="table__col">Enrolled</td>
                <td className="table__col">
                  <div className="action-group">
                    <box-icon name="user-x"></box-icon>
                    <box-icon name="edit-alt"></box-icon>
                    <box-icon name="archive"></box-icon>
                  </div>
                </td>
              </tr>
              <tr className="table__row">
                <td className="table__col">
                  <span>Clarke Arthur</span>
                </td>
                <td className="table__col">Arizona</td>
                <td className="table__col">Dana Arthur</td>
                <td className="table__col">Jodie Bond</td>
                <td className="table__col">Summer 2021</td>
                <td className="table__col">27 Apr 2021</td>
                <td className="table__col">Enrolled</td>
                <td className="table__col">
                  <div className="action-group">
                    <box-icon name="edit-alt"></box-icon>
                    <box-icon name="archive"></box-icon>
                  </div>
                </td>
              </tr>
              <tr className="table__row">
                <td className="table__col">
                  <span>Clarke Arthur</span>
                </td>
                <td className="table__col">Arizona</td>
                <td className="table__col">Dana Arthur</td>
                <td className="table__col">Jodie Bond</td>
                <td className="table__col">Summer 2021</td>
                <td className="table__col">27 Apr 2021</td>
                <td className="table__col">Enrolled</td>
                <td className="table__col">
                  <div className="action-group">
                    <box-icon name="user-x"></box-icon>
                    <box-icon name="edit-alt"></box-icon>
                    <box-icon name="archive"></box-icon>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
