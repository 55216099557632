import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";

interface IConfirmSuccessProps {
  user: IUser;
  multiple?: boolean;
  closeModal: () => void;
}

const ConfirmSuccess: React.FC<IConfirmSuccessProps> = ({
  user,
  closeModal,
  multiple = false,
}) => {
  return (
    <div className="modal-wrap modal-wrap--sm confirm confirm--success">
      <div className="modal-wrap__body">
        <box-icon name="check-circle" type="solid"></box-icon>
        <h3>{multiple ? "Task(s)" : "Task"} marked as completed</h3>
        <p>
          You have marked {multiple ? "the task(s)" : "this task"} as completed
          for {getFullName(user)}. If you want to see it later, find it by going
          to the plan for the corresponding week.
        </p>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              onClick={closeModal}
              className="btn btn--text btn--small"
              type="button"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSuccess;
