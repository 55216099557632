import React from "react";
import AddGradeForm from "./AddGradeForm";
import { IAddUpdateGradeData } from "../grade";

interface IAddGradeProps {
  closeModal: () => void;
  tableValues: IAddUpdateGradeData;
  fetchStudentGradesAndCourses: () => void;
}
const AddGrades: React.FC<IAddGradeProps> = ({
  closeModal,
  tableValues,
  fetchStudentGradesAndCourses,
}) => {
  const initialValues: IAddUpdateGradeData = {
    ...tableValues,
  };

  const onGradeUpdateSuccess = () => {
    fetchStudentGradesAndCourses();
  };

  const isGradeNew = (() => {
    const createdByKeysCount =
      (initialValues.createdBy &&
        Object.keys(initialValues.createdBy).length) ||
      0;

    if (createdByKeysCount === 0) {
      return true;
    }

    return false;
  })();

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>{isGradeNew ? "Add Grade" : "Change Grade"} </h3>
        <span className="link-item" onClick={() => closeModal()}>
          <box-icon name="x" />
        </span>
      </div>
      <AddGradeForm
        isGradeNew={isGradeNew}
        closeModal={() => closeModal()}
        initialValues={initialValues}
        onGradeUpdateSuccess={onGradeUpdateSuccess}
      />
    </div>
  );
};

export default AddGrades;
