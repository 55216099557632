import { IUser } from "commons/types/users";

export enum AddSponsorActionType {
  ADD_SPONSOR_PENDING = "ADD_SPONSOR_PENDING",
  ADD_SPONSOR_REJECTED = "ADD_SPONSOR_REJECTED",
  ADD_SPONSOR_FULFILLED = "ADD_SPONSOR_FULFILLED",
}

export interface AddSponsorAction {
  type: AddSponsorActionType;
  data: IUser;
}

export enum UpdateSponsorActionType {
  UPDATE_SPONSOR_PENDING = "UPDATE_SPONSOR_PENDING",
  UPDATE_SPONSOR_REJECTED = "UPDATE_SPONSOR_REJECTED",
  UPDATE_SPONSOR_FULFILLED = "UPDATE_SPONSOR_FULFILLED",
}

export interface updateSponsorAction {
  type: UpdateSponsorActionType;
  data: IUser;
}

export enum FetchSponsorsActionType {
  FETCH_SPONSORS_PENDING = "FETCH_SPONSORS_PENDING",
  FETCH_SPONSORS_REJECTED = "FETCH_SPONSORS_REJECTED",
  FETCH_SPONSORS_FULFILLED = "FETCH_SPONSORS_FULFILLED",
}

export interface FetchSponsorsAction {
  type: FetchSponsorsActionType;
  data: IUser[];
}
