import React from "react";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";

interface INotEnrolledErrorProps {
  student: IUser;
}

const NotEnrolledError: React.FC<INotEnrolledErrorProps> = ({ student }) => {
  return (
    <div className="alert alert__danger">
      <span className="status link-item">
        <box-icon name="error-alt" />
      </span>
      <div className="alert__content">
        <strong>{getFullName(student)} is not enrolled!</strong>
        Please contact admin to enroll the student.
      </div>
    </div>
  );
};

export default NotEnrolledError;
