import { useTranslation } from "react-i18next";
import RegistrationForm from "./RegistrationForm";

const MentorForm = () => {
  const { t } = useTranslation();

  return (
    <div className="content-wrap pt-8x">
      <div className="container">
        <div className="page-heading">
          <h2>{t("mentorForm.addFormTitle")}</h2>
        </div>
        <div className="content-7x">
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};

export default MentorForm;
