import React, { useCallback, useEffect, useState } from "react";
import { Cell, Row, useTable } from "react-table";
import { connect } from "react-redux";
import { useSticky } from "react-table-sticky";
import ReactTooltip from "react-tooltip";
import Modal from "react-modal";

import { IUser } from "commons/types/users";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { fetchPlanningPeriods } from "services/planningPeriods";
import useMountedRef from "commons/hooks/useMountedRef";
import { error } from "utils/toast";
import { fetchCourses } from "store/actions/data/courses";
import { fetchGrade } from "store/actions/data/grades";
import { ICourses, ICoursesParams } from "commons/types/courses";
import { getCurrentEnrollment } from "utils/user";
import { getDateMonthInFormat, isDateInRange } from "utils/dates";
import AddGrades from "./add/AddGrades";
import { IAddUpdateGradeData, IGrade } from "./grade";
import Loader from "commons/components/Loader";
import { uuid } from "utils/uuid";

interface IGradeTableProps {
  student: IUser;
  selectedTerm: string;
  fetchCourses: (params: ICoursesParams) => any;
  fetchGrade: (params: any) => void;
  fetchGradeLoading: boolean;
  fetchCoursesLoading: boolean;
  studentGrades: IAddUpdateGradeData[];
  courses: ICourses[];
  initialValues: IGrade;
}

interface IUpdateGradeData {
  student?: string;
  term: string;
  lastUpdatedBy: string;
  gradeType?: string;
}

const GradeTable: React.FC<IGradeTableProps> = ({
  student,
  selectedTerm,
  fetchCourses,
  fetchGrade,
  fetchCoursesLoading,
  fetchGradeLoading,
  courses,
  studentGrades,
}) => {
  const [isPlanningFetched, setIsPlanningFetched] = useState(false);
  const [planningPeriods, setPlanningPeriods] = useState<IPlanningPeriod[]>([]);
  const [isAddGradeOpen, setAddGradeOpen] = useState<boolean>(false);
  const [gradesData, setGradesData] = useState<IGrade>();
  const [data, setTableData] = useState<any[]>([]);

  const studentCurrentTermId = getCurrentEnrollment(student)?.enrolledTerm?._id;

  const isMounted = useMountedRef();

  const user = localStorage.getItem("profile");
  let currentUser: any = {};
  if (user) {
    try {
      currentUser = JSON.parse(user);
    } catch (err: any) {
      console.error(err);
    }
  }

  const practitionerId = currentUser._id;

  const updateGradeData: IUpdateGradeData = {
    student: student._id,
    term: studentCurrentTermId,
    lastUpdatedBy: practitionerId,
  };

  useEffect(() => {
    if (studentCurrentTermId && student) {
      fetchStudentGradesAndCourses();
      fetchCourses({
        userId: student._id,
        termId: studentCurrentTermId,
      });
    }
  }, []);

  useEffect(() => {
    const tableData: IAddUpdateGradeData[] = generateTableData();
    setTableData(tableData);
  }, [planningPeriods, studentGrades, courses]);

  const fetchStudentGradesAndCourses = () => {
    const studentId = student._id;
    const termId = studentCurrentTermId;

    if (studentCurrentTermId && student) {
      const params = `studentId=${studentId}&termId=${termId}`;
      fetchGrade(params);
      fetchCourses({
        userId: student._id,
        termId: studentCurrentTermId,
      });
    }
  };

  const fetchPlans = useCallback(
    async (term) => {
      if (student?._id && term) {
        setIsPlanningFetched(false);
        try {
          const planningPeriod = await fetchPlanningPeriods({
            user: student._id,
            term: term,
          });

          setPlanningPeriods(planningPeriod);
        } catch (err: any) {
          error(err);
        }
      }
      setIsPlanningFetched(true);
    },
    [student._id, isMounted],
  );

  useEffect(() => {
    fetchPlans(selectedTerm);
  }, [fetchPlans, selectedTerm]);

  const todaysDate = new Date();

  //Table columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Weeks",
        sticky: "left",
        width: "100px",
        columns: [
          {
            Header: "Planning Periods",
            accessor: (row: any) => {
              const isDateBetweenPlanningPeriod = isDateInRange(
                row.PlanningPeriod.startDate,
                row.PlanningPeriod.endDate,
                todaysDate,
                true,
              );
              return (
                <span
                  className={`${
                    isDateBetweenPlanningPeriod ? "primary-color txt-bold" : ""
                  }`}
                >
                  {" "}
                  {row.PlanningPeriod.label}{" "}
                </span>
              );
            },
          },
        ],
      },
      {
        Header: "Courses",
        columns: courses.map((course) => {
          return {
            Header: course.name,
            accessor: (row: any) => {
              const id = uuid();
              return (
                <>
                  <span
                    className={`cursor-pointer  ${
                      courses?.length <= 3 ? "" : "center"
                    }`}
                    data-tip
                    data-for={id}
                  >
                    {row[course.name]?.value}
                  </span>
                  <ReactTooltip id={id} multiline={true}>
                    {`Edit for ${course.name}`}
                  </ReactTooltip>
                </>
              );
            },
            courseId: course._id,
            width: 20,
          };
        }),
      },
    ],
    [courses, gradesData, planningPeriods],
  );

  /**
   * Groups api data according to common
   * planning period so that in can be mapped with student's planning period
   * @returns {gradeGroupedByPlanningPeriod}
   */
  const generateFinalData = (gradesData: IAddUpdateGradeData[]) => {
    const gradeGroupedByPlanningPeriod: any = {};
    gradesData.forEach((element: IAddUpdateGradeData) => {
      const planningPeriodKey = `${element.startDate}+${element.endDate}`;
      // check if the planning period already exist or not
      if (gradeGroupedByPlanningPeriod.hasOwnProperty(planningPeriodKey)) {
        const previousObjectData =
          gradeGroupedByPlanningPeriod[planningPeriodKey];
        previousObjectData.push(element);
      } else {
        gradeGroupedByPlanningPeriod[planningPeriodKey] = [element];
      }
    });
    return gradeGroupedByPlanningPeriod;
  };

  /**
   * Generates data in a structure that can be used in react table
   * by grouping data from courses, planning periods and grades data from API.
   * @returns {data:IAddUpdateGradeData[]}
   */
  const generateTableData = () => {
    const groupedData = generateFinalData(studentGrades);

    return planningPeriods.map((planningPeriod) => {
      const planningPeriodKey = `${planningPeriod.startDate}+${planningPeriod.endDate}`;
      const currentPPGroupedData = groupedData[planningPeriodKey];

      //planningPeriod = planning period
      const planningPeriodObject: any = {
        PlanningPeriod: {
          startDate: planningPeriod.startDate,
          endDate: planningPeriod.endDate,
          label: `${getDateMonthInFormat(
            planningPeriod.startDate,
          )} - ${getDateMonthInFormat(planningPeriod.endDate)}`,
        },
      };
      courses.forEach((course: ICourses) => {
        const courseGradeValue =
          currentPPGroupedData &&
          currentPPGroupedData.filter(
            (item: any) => item.course === course._id,
          )[0];

        const defaultData = {
          grade: "-",
          startDate: planningPeriod.startDate,
          endDate: planningPeriod.endDate,
          term: studentCurrentTermId,
          course: course._id,
        };

        planningPeriodObject[course.name] = {
          value: courseGradeValue?.grade || "-",
          originalValue: courseGradeValue || defaultData,
        };
      });
      return planningPeriodObject;
    });
  };

  const handleOnCellClick = (cell: Cell, row: Row<any>) => {
    if (!cell.column.id) {
      return;
    }
    if (cell.column.id !== "Planning Periods") {
      setGradesData(row.original[cell.column.id].originalValue);
      setAddGradeOpen(true);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSticky);

  const EmptyCourseInfo = () => {
    return (
      <div className="center bg-white p-4x text-bold">
        No course found for this student. Add a course from academic section.
      </div>
    );
  };

  if (!isPlanningFetched || fetchCoursesLoading || fetchGradeLoading) {
    return (
      <div>
        <Loader type="ThreeDots" />
      </div>
    );
  }

  if (courses.length === 0) {
    return <EmptyCourseInfo />;
  }

  const tableValues: IAddUpdateGradeData = {
    ...gradesData,
    ...updateGradeData,
  };

  return (
    <>
      <div className="table-responsive table-sticky-head scroll-auto">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="head__row">
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps()}
                    className="table__col table-col-relative"
                  >
                    <span className="item-container">
                      <span className="item">{column.render("Header")}</span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table__row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="table__col table-col-relative"
                        onClick={() => {
                          handleOnCellClick(cell, row);
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddGradeOpen}
        onRequestClose={() => setAddGradeOpen(false)}
      >
        <AddGrades
          closeModal={() => setAddGradeOpen(false)}
          tableValues={tableValues}
          fetchStudentGradesAndCourses={fetchStudentGradesAndCourses}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  courses: state.courses.fetchCourses,
  fetchCoursesLoading: state.courses.fetchCoursesLoading,
  studentGrades: state.grades.fetchGrade,
  fetchGradeLoading: state.grades.fetchGradeLoading,
});

const mapDispatchToProps = {
  fetchCourses,
  fetchGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(GradeTable);
