import config from "configs";
import { http } from "utils/http";

import { INotifyCall } from "commons/types/call";

export const notifyCall = async (payload: INotifyCall) => {
  const { data } = await http.post(config.endpoints.users.call, payload);

  return data;
};
