import React, { useEffect, useState } from "react";
import "boxicons";
import { connect } from "react-redux";

import LeftPanel from "pages/layouts/mentor/leftPanel";
import { IUser } from "commons/types/users";
import { getCurrentEnrollment } from "utils/user";
import { fetchStudent } from "store/actions/data/students";
import TermNotEnrolledError from "pages/layouts/mentor/TermNotEnrolledError";
import Grades from "./Grades";
import GradeTable from "./GradeTable";
import { IGrade } from "./grade";
import Loader from "commons/components/Loader";

type StudentGradesProps = {
  student: IUser;
  match: { params: { id: string } };
  fetchStudentLoading: boolean;
  fetchStudent: (id: string) => Promise<IUser>;
  initialValues: IGrade;
};

const StudentGrades: React.FC<StudentGradesProps> = ({
  match: {
    params: { id },
  },
  student,
  fetchStudentLoading,
  fetchStudent,
  initialValues,
}) => {
  const [selectedTerm, setSelectedTerm] = useState<string | any>();

  useEffect(() => {
    fetchStudent(id);
  }, [id, fetchStudent]);

  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);

  return (
    <>
      <LeftPanel activeTab={"Grades"} student={student} />
      <div className="content-panel-grades">
        {isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : null}

        {fetchStudentLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <>
            <Grades
              setSelectedTerm={setSelectedTerm}
              id={student?.id}
              student={student}
            />

            <GradeTable
              selectedTerm={selectedTerm}
              student={student}
              initialValues={initialValues}
            />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  student: state.students.fetchStudent,
  fetchStudentLoading: state.students.fetchStudentLoading,
});

const mapDispatchToProps = {
  fetchStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentGrades);
