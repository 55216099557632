import { ISponsor, IUser } from "commons/types/users";
import { ISurveyInitaiteResponders } from "commons/types/surveys";

interface Props {
  student: IUser & { mapId: number };
  totalStudentsResponders: string[];
  handleStudentCheck: (student: IUser & { mapId: number }, event: any) => void;
  handleParentsCheck: (
    sponsor: ISponsor,
    currentStudent: IUser & { mapId: number },
    event: any,
  ) => void;
  responders: ISurveyInitaiteResponders[];
}

const StudentParentCheckSection: React.FC<Props> = ({
  student,
  totalStudentsResponders,
  handleStudentCheck,
  responders,
  handleParentsCheck,
}) => {
  const ParentsCheckItem = ({ sponsor }: { sponsor: ISponsor }) => {
    const currentStudent = student;

    const respectiveResponder = responders.find(
      (responder: ISurveyInitaiteResponders) =>
        responder.mapId === currentStudent.mapId,
    );

    const checkStatus = respectiveResponder?.sponsors.includes(
      sponsor.userId._id || "",
    );

    return (
      <div key={sponsor.userId._id}>
        <div className="ml-2x mb-2x">
          <input
            type="checkbox"
            name="checkbox"
            value="Checkbox Inline 2"
            checked={checkStatus}
            id={`${sponsor.userId._id}${currentStudent.mapId}`}
            onChange={(event: any) =>
              handleParentsCheck(sponsor, currentStudent, event)
            }
            className="form-group__radio mr-10"
          />
          <label
            htmlFor={`${sponsor.userId._id}${currentStudent.mapId}`}
            className="box-links__nodes"
          >
            {sponsor?.userId?.firstName} {sponsor?.userId?.lastName}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div key={student.mapId} className="row">
      <div key={student._id || ""} className={`col-6`}>
        <div className={" mr-2x"}>
          <input
            type="checkbox"
            name="checkbox"
            value="Checkbox Inline 2"
            checked={totalStudentsResponders.includes(student._id || "")}
            id={student._id || ""}
            onChange={(event: any) => handleStudentCheck(student, event)}
            className="form-group__radio mr-10"
          />
          <label htmlFor={student._id || ""} className="box-links__nodes">
            {student.firstName} {student.lastName}
          </label>
        </div>
      </div>
      <div className="col-6">
        {student.relatedUsers?.sponsors &&
        student.relatedUsers?.sponsors?.length > 0 ? (
          student.relatedUsers?.sponsors?.map((sponsor) => (
            <ParentsCheckItem sponsor={sponsor} />
          ))
        ) : (
          <span className="fs-small ml-2x">No parents assigned</span>
        )}
      </div>
    </div>
  );
};

export default StudentParentCheckSection;
