import {
  AddAssignmentActionType,
  DeleteAssignmentActionType,
  FetchAssignmentsActionType,
  UpdateAssignmentActionType,
} from "store/actions/types/assignments";

const INITIAL_STATE = {
  addAssignment: {},
  addAssignmentLoading: false,
  addAssignmentError: null,
  fetchAssignment: {},
  fetchAssignmentLoading: false,
  fetchAssignmentError: null,
  updateAssignment: {},
  updateAssignmentLoading: false,
  updateAssignmentError: null,
  fetchAssignments: [],
  fetchAssignmentsLoading: false,
  fetchAssignmentsError: null,
  deleteAssignment: {},
  deleteAssignmentLoading: false,
  deleteAssignmentError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddAssignmentActionType.ADD_ASSIGNMENT_PENDING:
      return { ...state, addAssignmentLoading: true, addAssignmentError: null };

    case AddAssignmentActionType.ADD_ASSIGNMENT_FULFILLED:
      return {
        ...state,
        addAssignment: action.data,
        addAssignmentLoading: false,
        addAssignmentError: null,
      };
    case AddAssignmentActionType.ADD_ASSIGNMENT_REJECTED:
      return {
        ...state,
        addAssignmentLoading: false,
        addAssignmentError: action.data,
      };
    case FetchAssignmentsActionType.FETCH_ASSIGNMENT_PENDING:
      return {
        ...state,
        fetchAssignmentsLoading: true,
        fetchAssignmentsError: null,
      };
    case FetchAssignmentsActionType.FETCH_ASSIGNMENT_FULFILLED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        fetchAssignmentsError: null,
        fetchAssignments: action.data,
      };

    case FetchAssignmentsActionType.FETCH_ASSIGNMENT_REJECTED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        fetchAssignmentsError: action.data,
      };
    case UpdateAssignmentActionType.UPDATE_ASSIGNMENT_PENDING:
      return {
        ...state,
        updateAssignmentLoading: true,
        updateAssignmentError: null,
      };
    case UpdateAssignmentActionType.UPDATE_ASSIGNMENT_FULFILLED:
      return {
        ...state,
        updateAssignmentLoading: false,
        updateAssignment: action.data,
        updateAssignmentError: null,
      };
    case UpdateAssignmentActionType.UPDATE_ASSIGNMENT_REJECTED:
      return {
        ...state,
        updateAssignmentLoading: false,
        updateAssignmentError: action.data,
      };
    case DeleteAssignmentActionType.DELETE_ASSIGNMENT_PENDING:
      return {
        ...state,
        deleteAssignmentLoading: true,
        deleteAssignmentError: null,
      };
    case DeleteAssignmentActionType.DELETE_ASSIGNMENT_FULFILLED:
      return {
        ...state,
        deleteAssignment: action.data,
        deleteAssignmentLoading: false,
        deleteAssignmentError: null,
      };
    case DeleteAssignmentActionType.DELETE_ASSIGNMENT_REJECTED:
      return {
        ...state,
        deleteAssignmentLoading: false,
        deleteAssignmentError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
