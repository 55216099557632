export enum AddGradesActionType {
  ADD_GRADES_PENDING = "ADD_GRADES_PENDING",
  ADD_GRADES_REJECTED = "ADD_GRADES_REJECTED",
  ADD_GRADES_FULFILLED = "ADD_GRADES_FULFILLED",
}

export enum FetchGradesActionType {
  FETCH_GRADES_PENDlING = "FETCH_GRADES_PENDING",
  FETCH_GRADES_REJECTED = "FETCH_GRADES_REJECTED",
  FETCH_GRADES_FULFILLED = "FETCH_GRADES_FULFILLED",
}

export enum UpdateGradesActionType {
  UPDATE_GRADES_PENDING = "UPDATE_GRADES_PENDING",
  UPDATE_GRADES_REJECTED = "UPDATE_GRADES_REJECTED",
  UPDATE_GRADES_FULFILLED = "UPDATE_GRADES_FULFILLED",
}

export enum DeleteGradesActionType {
  DELETE_GRADES_PENDING = "DELETE_GRADES_PENDING",
  DELETE_GRADES_REJECTED = "DELETE_GRADES_REJECTED",
  DELETE_GRADES_FULFILLED = "DELETE_GRADES_FULFILLED",
}
