import React from "react";

import Form from "../form";
import { ISchoolDistrict } from "commons/types/schoolDistrict";

interface IEditSchoolDistrict {
  closeModal: () => void;
  initialValues: ISchoolDistrict;
  updateSchoolDistrict: (
    id: string,
    schoolDistrict: ISchoolDistrict,
  ) => Promise<void>;
}

const EditSchoolDistrict: React.FC<IEditSchoolDistrict> = ({
  initialValues,
  closeModal,
  updateSchoolDistrict,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Edit School District</h3>
        <span className="link-item" onClick={() => closeModal()}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={{
          _id: initialValues._id || "",
          name: initialValues.name,
        }}
        isEdit={true}
        closeModal={closeModal}
        updateSchoolDistrict={updateSchoolDistrict}
      />
    </div>
  );
};

export default EditSchoolDistrict;
