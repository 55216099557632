import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { TextInput } from "commons/components/form-fields";
import { Form, FormikProvider, useFormik } from "formik";
import Modal from "react-modal";

import { error, success } from "utils/toast";
import { useHistory } from "react-router-dom";
import config from "configs";
import { ITenantDetail } from "store/actions/types/multitenancy";
import { addTenant, updateTenant } from "services/multitenancy";
import { validateAvatar } from "utils/user";
import DeleteConfirm from "commons/components/DeleteConfirm";
import AddEditTenantSchema from "./schema/addEditTenantSchema";
import CompanyDummyLogo from "assets/images/company-dummy.jpg";

interface AddEditTenantFormProps {
  initialValues?: ITenantDetail;
  isTenantNew: boolean;
}

const AddEditTenantForm: React.FC<AddEditTenantFormProps> = ({
  initialValues,
  isTenantNew,
}) => {
  const history = useHistory();
  const inputPictureRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isDeleteAvatarOpen, setDeleteAvatarOpen] = useState<boolean>(false);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [avatarValidationError, setAvatarValidationError] =
    useState<string>("");

  const formik = useFormik<any>({
    initialValues: initialValues,
    onSubmit: (values: any, actions) => {
      var form_data = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === "tenantAdminName") {
          form_data.append("tenantAdminName", "tenantAdminName");
          return;
        }
        if (key === "tenantLogo" && selectedImageFile) {
          form_data.append("tenantLogo", selectedImageFile);
        } else if (typeof values[key] === "object" && values[key] !== null) {
          form_data.append(key, JSON.stringify(values[key]));
        } else {
          form_data.append(key, values[key]);
        }
      });

      saveTenant(form_data, actions);
    },
    validationSchema: AddEditTenantSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (initialValues?.tenantLogo) {
      formik.setFieldValue("tenantLogo", initialValues?.tenantLogo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues?.tenantLogo]);

  const saveTenant = (values: any, actions: any) => {
    isTenantNew
      ? addTenant(values)
          .then((response) => {
            history.push(config.uiPath.superAdmin.tenantList);
            success("Tenant added");
          })
          .catch((err) => {
            console.log("err", err);
            const { message } = err.response.data;
            if (Array.isArray(message)) {
              message.forEach((e: any) => error(e));
            } else {
              error(message);
            }
            actions.setSubmitting(false);
          })
      : updateTenant(values, formik.values._id)
          .then((response) => {
            history.push(config.uiPath.superAdmin.tenantList);
            success("Tenant updated");
            actions.setSubmitting(false);
          })
          .catch((err) => {
            console.log("err", err);
            const { message } = err.response.data;
            if (Array.isArray(message)) {
              message.forEach((e: any) => error(e));
            } else {
              error(message);
            }
            actions.setSubmitting(false);
          });
  };

  const handlePictureChange = async (event: any) => {
    let file = event.target.files[0];
    if (validateAvatar(file)) {
      setSelectedImageFile(file);
      formik.setFieldValue("tenantLogo", URL.createObjectURL(file));
      setAvatarValidationError("");
      inputPictureRef.current.value = "";
    } else {
      setAvatarValidationError("Please use supported file format and size");
    }
  };

  const handleAvatarDelete = async () => {
    setSelectedImageFile(null);
    formik.setFieldValue("tenantLogo", "");
    setDeleteAvatarOpen(false);
  };

  const toTrimLowercase = (value: string) => value.toLowerCase().trim();

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="modal-wrap__body tw-mt-6">
          <div className="tw-card">
            <div>
              <img
                src={
                  formik.values.tenantLogo
                    ? formik.values.tenantLogo
                    : CompanyDummyLogo
                }
                alt="company-logo"
                className="tw-w-32 tw-h-32 tw-object-contain tw-rounded-lg tw-mb-4 tw-bg-slate-100"
              />
              <div className="card-text">
                <h3>Company Logo</h3>
                <div className="d-flex link-text fs-small">
                  <input
                    type="file"
                    ref={inputPictureRef}
                    onChange={handlePictureChange}
                    className={"input-avatar"}
                  />
                  <span
                    onClick={() => {
                      inputPictureRef && inputPictureRef.current.click();
                    }}
                  >
                    Upload new photo
                  </span>
                  <span
                    className="status status--error ml-2x"
                    onClick={() => setDeleteAvatarOpen(true)}
                  >
                    Remove
                  </span>
                </div>
                <div className="d-flex mt-2x">
                  <span className="text-light-sm">
                    File size should be limited to 10MB.
                  </span>
                </div>
                <div className="d-flex mb-0x">
                  <span className="text-light-sm">
                    The supported file formats are jpg, jpeg and png.
                  </span>
                </div>

                <div className="d-flex">
                  {avatarValidationError.length ? (
                    <span className="input__error avatar-error">
                      <box-icon name="error-circle" size="xs" /> Please use the
                      supported file format and size
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="tw-flex tw-mt-4">
              <div className="col-4 mt-4x">
                <TextInput
                  required={true}
                  label="Tenant Name"
                  name="name"
                  placeholder="Tenant name"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue("name", e.target.value);
                  }}
                />
              </div>
              <div className="col-4 mt-4x">
                <TextInput
                  required={true}
                  label="Tenant Subdomain"
                  name="subDomain"
                  placeholder="Tenant subdomain"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      "subDomain",
                      toTrimLowercase(e.target.value),
                    );
                  }}
                />
              </div>
              <div className="col-4 mt-4x">
                <TextInput
                  required={true}
                  label="Tenant Location"
                  name="location"
                  placeholder="Tenant location"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      "location",
                      toTrimLowercase(e.target.value),
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="tw-card tw-mt-4">
            <h2>Primary Contact</h2>
            <div className="tw-flex tw-mt-2">
              <div className="col-4 mt-4x">
                <TextInput
                  required={true}
                  label="Phone number"
                  name="phone"
                  placeholder="Phone number"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                />
              </div>
              <div className="col-4 mt-4x">
                <TextInput
                  required={true}
                  label="Tenant Email"
                  name="email"
                  placeholder="Email"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                />
              </div>
              <div className="col-4 mt-4x">
                <TextInput
                  label="Tenant Friendly Name"
                  name="tenantFriendlyName"
                  placeholder="Tenant location"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue("tenantFriendlyName", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="tw-card tw-mt-4">
            <h2 className="mb-5x">Services</h2>
            <div>
              <h3>Auth0 Credentials</h3>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Auth0 Domain"
                    name="services.auth0.auth0Domain"
                    placeholder="Auth0 domain"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.auth0Domain",
                        e.target.value,
                      );
                    }}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Auth0 Audience"
                    name="services.auth0.audience"
                    placeholder="Auth0 audience"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.audience",
                        e.target.value,
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Auth0 custom domain"
                    name="services.auth0.auth0CustomDomain"
                    placeholder="Custom domain"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.auth0CustomDomain",
                        e.target.value,
                      );
                    }}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Auth0 DB Connection "
                    name="services.auth0.auth0DbConnection"
                    placeholder="Auth0 DB Connection"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.auth0DbConnection",
                        e.target.value,
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3>Machine to Machine</h3>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Auth0 Client Id"
                    name="services.auth0.machineToMachine.auth0ClientId"
                    placeholder="Auth0 Client Id"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.machineToMachine.auth0ClientId",
                        e.target.value,
                      );
                    }}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Auth0 Client Secret"
                    name="services.auth0.machineToMachine.auth0ClientSecret"
                    placeholder="Auth0 Client Secret"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.machineToMachine.auth0ClientSecret",
                        e.target.value,
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3>Single Page</h3>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Client Id"
                    name="services.auth0.singlePage.auth0ClientId"
                    placeholder="Client Id"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.singlePage.auth0ClientId",
                        e.target.value,
                      );
                    }}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Client Secret"
                    name="services.auth0.singlePage.auth0ClientSecret"
                    placeholder="Auth0 Client Secret"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.singlePage.auth0ClientSecret",
                        e.target.value,
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <h3>Mobile</h3>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    label="Client Id"
                    name="services.auth0.mobile.auth0ClientId"
                    placeholder="Client Id"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={(e: any) => {
                      formik.setFieldValue(
                        "services.auth0.mobile.auth0ClientId",
                        e.target.value,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-card tw-mt-4">
            <h2 className="mb-5x">Pusher</h2>
            <div className="row">
              <div className="col-3 mt-4x">
                <TextInput
                  label="Pusher App Key"
                  name="services.pusher.pusherAppKey"
                  placeholder="Pusher app key"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: any) => {
                    formik.setFieldValue(
                      "services.pusher.pusherAppKey",
                      e.target.value,
                    );
                  }}
                />
              </div>
              <div className="col-3 mt-4x">
                <TextInput
                  label="Pusher Cluster"
                  name="services.pusher.pusherCluster"
                  placeholder="Pusher cluster"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: any) => {
                    formik.setFieldValue(
                      "services.pusher.pusherCluster",
                      e.target.value,
                    );
                  }}
                />
              </div>
              <div className="col-3 mt-4x">
                <TextInput
                  label="Pusher Secret"
                  name="services.pusher.pusherSecret"
                  placeholder="Pusher secret"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: any) => {
                    formik.setFieldValue(
                      "services.pusher.pusherSecret",
                      e.target.value,
                    );
                  }}
                />
              </div>
              <div className="col-3 mt-4x">
                <TextInput
                  label="Pusher App Id"
                  name="services.pusher.pusherAppId"
                  placeholder="Pusher app id"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={(e: any) => {
                    formik.setFieldValue(
                      "services.pusher.pusherAppId",
                      e.target.value,
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="tw-card tw-mt-4">
            <h2 className="mb-5x">Features</h2>
            <h3>Communication</h3>
            <div className="tw-flex tw-space-x-6 tw-mt-4">
              <div className="input-wrap">
                <input
                  type="checkbox"
                  id="check1"
                  className="form-group__radio mr-10"
                  name="audiocall"
                  checked={formik.values.features.communication.audiocall}
                  onChange={(e: any) =>
                    formik.setFieldValue(
                      "features.communication.audiocall",
                      !formik.values.features.communication.audiocall,
                    )
                  }
                />
                <label htmlFor="check1" className="box-links__nodes">
                  Audio Call
                </label>
              </div>
              <div className="input-wrap">
                <input
                  type="checkbox"
                  id="check2"
                  className="form-group__radio mr-10"
                  name="videocall"
                  checked={formik.values.features.communication.videocall}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "features.communication.videocall",
                      !formik.values.features.communication.videocall,
                    )
                  }
                />
                <label htmlFor="check2" className="box-links__nodes">
                  Video Call
                </label>
              </div>
              <div className="input-wrap">
                <input
                  type="checkbox"
                  id="check3"
                  className="form-group__radio mr-10"
                  name="chat"
                  checked={formik.values.features.communication.chat}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "features.communication.chat",
                      !formik.values.features.communication.chat,
                    )
                  }
                />
                <label htmlFor="check3" className="box-links__nodes">
                  Chat
                </label>
              </div>
            </div>
            <h3>Status</h3>
            <label className="toggle tw-mt-2">
              <input
                name="status"
                type="checkbox"
                className="toggle-checkbox"
                checked={formik.values.status === "Active"}
                onChange={() =>
                  formik.setFieldValue(
                    "status",
                    formik.values.status === "Active" ? "Inactive" : "Active",
                  )
                }
              />
              <div className="toggle-switch"></div>
            </label>
          </div>
          <div className="modal-wrap__footer pt-0x d-flex mt-8x">
            <div className="row">
              <div className="col-6 ">
                <span>
                  {formik.isSubmitting ? (
                    <button className="btn btn--primary" disabled={true}>
                      Submitting
                    </button>
                  ) : (
                    <div className="d-flex">
                      <button className="btn btn--primary mr-4x" type="submit">
                        Save
                      </button>
                      <button
                        disabled={formik.isSubmitting}
                        onClick={() =>
                          history.push(config.uiPath.superAdmin.tenantList)
                        }
                        className="btn txt-primary-color"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        className="modal-block"
        isOpen={isDeleteAvatarOpen}
        onRequestClose={() => setDeleteAvatarOpen(false)}
      >
        <DeleteConfirm
          closeModal={() => setDeleteAvatarOpen(false)}
          deleteResource={handleAvatarDelete}
          message={{
            header: "Do you want to delete this profile picture?",
            body: "This action is not reversible",
          }}
        />
      </Modal>
    </FormikProvider>
  );
};

export default AddEditTenantForm;
