import * as Yup from "yup";

import { ObjectShape } from "yup/lib/object";
import { NAME_LENGTH, TITLE_LENGTH } from "constants/index";

const courseSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(TITLE_LENGTH, `Title must be less than ${TITLE_LENGTH} characters`)
    .required("Please enter the title"),
  teacher: Yup.string().max(
    NAME_LENGTH,
    `Name must be less than ${NAME_LENGTH} characters`,
  ),
  time: Yup.string().required("Please select a time"),
  sessionDuration: Yup.number()
    .typeError("Session duration must be a number")
    .max(24, "Duration must be less than 24 hours")
    .min(0, "Duration cannot be negative")
    .required("Duration is required"),
  firstDay: Yup.date().required("Please select this date"),
  lastDay: Yup.date().required("Please select this date"),
  daysOfWeek: Yup.array().of(Yup.string()).min(1, "Please select a weekday"),
  grade: Yup.string().max(
    NAME_LENGTH,
    `Grade must be less than ${NAME_LENGTH} characters`,
  ),
});

export default courseSchema;
