import Form from "./Form";

const AddTerm = () => {
  return (
    <div className="content-wrap pt-8x">
      <div className="container">
        <div className="page-heading">
          <h2>Set up a New Term</h2>
        </div>
        <div className="content-7x">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default AddTerm;
