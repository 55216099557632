import React from "react";
import { useFormik } from "formik";
import { IUser } from "commons/types/users";
import { TextInput } from "commons/components/form-fields";
import { externalResourceSchema } from "commons/schemas/externalResource";

interface ILoginCredential {
  name?: string;
  url?: string;
  userName?: string;
  password?: string;
}

interface ILoginCredentialFormProps {
  isEdit: boolean;
  student: IUser;
  updateUser: (payload: IUser) => void;
  closeModal: () => void;
  initialValues?: ILoginCredential;
}
const LoginCredentialForm: React.FC<ILoginCredentialFormProps> = ({
  isEdit,
  student,
  updateUser,
  closeModal,
  initialValues,
}) => {
  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          name: "",
          url: "",
          userName: "",
          password: "",
        },
    onSubmit: async (values) => {
      if (!isEdit) {
        if (student.userData?.externalResources) {
          student.userData?.externalResources.push(values);
        } else if (student.userData && !student.userData.externalResources) {
          student.userData.externalResources = [values];
        }
      } else {
        const externalResources = student.userData?.externalResources?.map(
          (resource) => {
            return resource === initialValues ? values : resource;
          },
        );
        if (student.userData) {
          student.userData.externalResources = externalResources;
        }
      }

      try {
        await updateUser(student);
        closeModal();
      } catch (err: any) {}
    },
    validationSchema: externalResourceSchema,
  });
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>{isEdit ? "Edit login credential" : "Add login credential"}</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body">
          <div className="row">
            <div className="col-12">
              <TextInput
                label="Portal name"
                placeholder=""
                values={formik.values}
                name="name"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                label="Url of the portal"
                placeholder="https://"
                values={formik.values}
                name="url"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                label="Student's username or email"
                placeholder=""
                values={formik.values}
                name="userName"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                label="Student's Password"
                placeholder=""
                values={formik.values}
                name="password"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="modal-wrap__footer">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={
                  formik.isSubmitting || formik.values === formik.initialValues
                }
              >
                {isEdit ? "Save" : "Add"}
              </button>
              <button
                disabled={formik.isSubmitting}
                onClick={closeModal}
                className="btn txt-primary-color"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginCredentialForm;
