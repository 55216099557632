import "boxicons";
import dayjs from "dayjs";
import * as React from "react";
import { useFormik } from "formik";

import {
  DateInput,
  TextAreaInput,
  TextInput,
} from "commons/components/form-fields";
import { error, success } from "utils/toast";
import Loader from "commons/components/Loader";
import MessageTemplates from "./MessageTemplates";
import useMountedRef from "commons/hooks/useMountedRef";

import { details, schedule, update } from "services/scheduledMessages";

import {
  IScheduleMessageData,
  Steps,
  SubSteps,
} from "commons/types/scheduledMessages";
import { ComponentStatus } from "commons/types/status";

import ScheduleMessageSchema from "commons/schemas/scheduleMessageSchema";

interface ScheduleMessageProps {
  onClose: () => void;
  goToHandler: (step: Steps) => void;
  data: {
    studentId: string;
    selectedId: string;
    template: string;
    subStep: number;
  };
}

const DEFAULT_STATE = {
  scheduledFor: new Date().toISOString(),
  message: "",
  _id: "",
  note: "",
};

const MIN_DATE = new Date();

const ScheduleMessage: React.FC<ScheduleMessageProps> = ({
  onClose,
  goToHandler,
  data,
}) => {
  const isMounted = useMountedRef();

  const [componentState, setComponentState] =
    React.useState<IScheduleMessageData>({
      status: data.selectedId ? ComponentStatus.Loading : ComponentStatus.Idle,
      data: DEFAULT_STATE,
      subStep: data.subStep || 1,
    });

  const isEdit = !!data.selectedId;

  const defaultScheduledDate = isEdit
    ? new Date(componentState.data.scheduledFor)
    : "";
  const defaultScheduledTime = isEdit
    ? dayjs(defaultScheduledDate).format("HH:mm")
    : "";

  const initialValues = {
    message: componentState.data?.message || data?.template || "",
    scheduledDate: defaultScheduledDate,
    scheduledTime: defaultScheduledTime,
    note: componentState.data?.note || "",
  };

  const fetchScheduleDetails = React.useCallback(
    async (id: string) => {
      try {
        const response = await details(id);
        if (isMounted) {
          setComponentState({
            status: ComponentStatus.Loaded,
            data: response.data,
            subStep: 1,
          });
        }
      } catch (err: any) {
        if (isMounted) {
          setComponentState({
            status: ComponentStatus.Error,
            data: DEFAULT_STATE,
            subStep: 1,
          });
          error(err?.message || "Error in fetching schedule message details");
        }
      }
    },
    [setComponentState, isMounted],
  );

  React.useEffect(() => {
    if (isEdit) {
      fetchScheduleDetails(data.selectedId);
    }
  }, [data.selectedId, fetchScheduleDetails, isEdit]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const [hours, minutes] = values.scheduledTime.split(":");
      const scheduledFor = new Date(values.scheduledDate);
      scheduledFor.setHours(parseInt(hours, 10), parseInt(minutes, 10));

      const payload = {
        receiver: data.studentId,
        message: values.message,
        scheduledFor: scheduledFor.toISOString(),
        note: values.note,
      };

      setComponentState({
        status: ComponentStatus.Loading,
        data: DEFAULT_STATE,
        subStep: 1,
      });
      try {
        const response = isEdit
          ? await update(data.selectedId, payload)
          : await schedule(payload);

        success(response.data?.message || "Message scheduled successfully");
        if (isMounted) {
          goToHandler(1);
          setComponentState({
            status: ComponentStatus.Loaded,
            data: DEFAULT_STATE,
            subStep: 1,
          });
        }
      } catch (err: any) {
        error(err?.message || "Failed to schedule message");
        if (isMounted) {
          setComponentState({
            status: ComponentStatus.Error,
            data: DEFAULT_STATE,
            subStep: 1,
          });
        }
      }
    },
    validationSchema: ScheduleMessageSchema,
  });

  const changeSubStep = (subStep: number) => {
    setComponentState((prevState) => ({
      ...prevState,
      subStep,
    }));
  };

  const selectTemplate = (body: string) => {
    const currentValues = formik.values;
    setComponentState((prevState) => ({
      ...prevState,
      subStep: 1,
    }));
    formik.setValues({
      ...currentValues,
      message: body,
    });
  };

  const getComponentBody = () => {
    switch (componentState.status) {
      case ComponentStatus.Loaded:
      case ComponentStatus.Error:
      case ComponentStatus.Idle:
        return (
          <div className="modal-wrap__body schedule-message">
            <section className="section mb-0x">
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <TextAreaInput
                        label="Your message"
                        name="message"
                        placeholder=""
                        values={formik.values.message}
                        touched={formik.touched}
                        handleChange={formik.handleChange}
                        errors={formik.errors}
                      />
                      <div className="d-block subtext">
                        <span
                          className="link-item link-item--blue"
                          onClick={() => changeSubStep(2)}
                        >
                          Select message from template
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="input-wrap">
                      <label htmlFor="scheduledDate" className="input__label">
                        Send this on
                      </label>
                      <DateInput
                        name="dueDate"
                        minDate={MIN_DATE}
                        isValid={
                          !(
                            formik.touched.scheduledDate &&
                            formik.errors.scheduledDate
                          )
                        }
                        dateFormat="MMM d, yyyy"
                        selected={formik.values.scheduledDate}
                        onChange={(date) =>
                          formik.setFieldValue("scheduledDate", date)
                        }
                        onBlur={formik.handleBlur}
                      />
                      <label className="input__error">
                        {formik.touched.scheduledDate &&
                          formik.errors.scheduledDate}
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-wrap">
                      <label htmlFor="scheduledTime" className="input__label">
                        &nbsp;
                      </label>
                      <input
                        type="time"
                        className={
                          formik.touched.scheduledTime &&
                          formik.errors.scheduledTime
                            ? "input input--error"
                            : "input"
                        }
                        placeholder="Please select"
                        name="scheduledTime"
                        value={formik.values.scheduledTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label className="input__error">
                        {formik.touched.scheduledTime &&
                          formik.errors.scheduledTime}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <TextInput
                        label="Notes"
                        name="note"
                        placeholder=""
                        touched={formik.touched}
                        errors={formik.errors}
                        values={formik.values}
                        handleChange={formik.handleChange}
                      />
                      <span className="mt-neg-4x d-block subtext">
                        Note is only visible to you
                      </span>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );

      case ComponentStatus.Loading:
        return <Loader type="ThreeDots" />;

      default:
        return <div>Idle status</div>;
    }
  };

  return (
    <React.Fragment>
      <div className="modal-wrap modal-wrap--lg scheduled-messages--modal-width">
        <div className="modal-wrap__header">
          <div className="d-flex align-items-center">
            <span className="link-item">
              <button
                type="button"
                className="btn p-0x"
                onClick={() => {
                  if (componentState.subStep === SubSteps.MessageForm) {
                    goToHandler(Steps.ScheduledMessages);
                  } else {
                    changeSubStep(SubSteps.MessageForm);
                  }
                }}
              >
                <box-icon name="arrow-back"></box-icon>
              </button>
            </span>
            <h3 className="ml-2x">
              {componentState.subStep === SubSteps.MessageForm
                ? isEdit
                  ? "Edit Scheduled Message"
                  : "Schedule a Message"
                : "Select from a template"}
            </h3>
          </div>
          <span className="link-item">
            <button
              type="button"
              className="btn p-0x"
              onClick={onClose}
              title="Close"
            >
              <box-icon name="x"></box-icon>
            </button>
          </span>
        </div>
        {componentState.subStep === SubSteps.MessageForm ? (
          <form onSubmit={formik.handleSubmit}>
            {getComponentBody()}
            <div className="modal-wrap__footer">
              <div className="row">
                <div className="col-12 d-flex">
                  <button className="btn btn--primary mr-4x" type="submit">
                    Schedule message
                  </button>
                  <button
                    className="btn txt-primary-color"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <MessageTemplates selectTemplateHandler={selectTemplate} />
        )}
      </div>
    </React.Fragment>
  );
};

export default ScheduleMessage;
