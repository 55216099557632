import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as gradeTypeService from "services/gradeType";
import {
  IGradeType,
  IUpdateGradeType,
  IGradeTypeParams,
} from "commons/types/gradeTypes";
import {
  AddGradeTypeActionType,
  FetchGradeTypesActionType,
  UpdateGradeTypeActionType,
  DeleteGradeTypeActionType,
} from "store/actions/types/gradeTypes";

export const addGradeType =
  (payload: IGradeType) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddGradeTypeActionType.ADD_GRADE_TYPE_PENDING,
    });
    try {
      const { data } = await gradeTypeService.add(payload);
      dispatch({
        type: AddGradeTypeActionType.ADD_GRADE_TYPE_FULFILLED,
        data: data,
      });
      success("Saved grade type");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddGradeTypeActionType.ADD_GRADE_TYPE_REJECTED,
        data: err,
      });

      error("Grade type failed to save");
    }
  };

export const fetchGradeTypes =
  (params: IGradeTypeParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchGradeTypesActionType.FETCH_GRADE_TYPES_PENDING,
    });
    try {
      const { data } = await gradeTypeService.fetchAll(params);
      dispatch({
        type: FetchGradeTypesActionType.FETCH_GRADE_TYPES_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchGradeTypesActionType.FETCH_GRADE_TYPES_REJECTED,
        data: err,
      });

      error("Grade types failed to load");
    }
  };

export const updateGradeType =
  (id: string, payload: IUpdateGradeType) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateGradeTypeActionType.UPDATE_GRADE_TYPE_PENDING,
    });
    try {
      const { data } = await gradeTypeService.update(id, payload);
      dispatch({
        type: UpdateGradeTypeActionType.UPDATE_GRADE_TYPE_FULFILLED,
        data: data,
      });
      success("Grade type updated successfully");

      return data;
    } catch (err: any) {
      dispatch({
        type: UpdateGradeTypeActionType.UPDATE_GRADE_TYPE_REJECTED,
        data: err,
      });

      error("Grade type failed to update");
    }
  };

export const deleteGradeType = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DeleteGradeTypeActionType.DELETE_GRADE_TYPE_PENDING,
  });
  try {
    const { data } = await gradeTypeService.deleteOne(id);
    dispatch({
      type: DeleteGradeTypeActionType.DELETE_GRADE_TYPE_FULFILLED,
      data: data,
    });
    success("Grade type deleted successfully");
  } catch (err: any) {
    dispatch({
      type: DeleteGradeTypeActionType.DELETE_GRADE_TYPE_REJECTED,
      data: err,
    });

    error("Grade type failed to delete");
  }
};
