import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import { IResendSurveyPayload, ISurveysParams } from "commons/types/surveys";

export const sendSurveyInfo = async (payload: any) => {
  const url = config.endpoints.survey.initiate;
  const { data } = await http.post(url, payload);
  return data;
};

export const getSurveyCategories = async () => {
  const url = config.endpoints.survey.getCategories;
  const { data } = await http.get(url);

  return data;
};

export const getSurveyById = async (id: string) => {
  const url = pinterpolate(config.endpoints.survey.detail, { id: id });
  const { data } = await http.get(url);

  return data;
};

export const checkIsResponseSubmitted = async (
  surveyId: string,
  responderId: string,
) => {
  const url = pinterpolate(config.endpoints.survey.checkResponseIsSubmitted, {
    surveyId,
    responderId,
  });
  const { data } = await http.get(url);

  return data;
};

export const sendSurveyResponse = async (payload: any) => {
  const url = config.endpoints.survey.responses;
  const { data } = await http.post(url, payload);

  return data;
};

export const getSurveyResponseById = async (id: string, role: string) => {
  const url = stringify(
    pinterpolate(config.endpoints.survey.response, { id: id }),
    {
      role: role,
    },
  );
  const { data } = await http.get(url);

  return data.data[0];
};

export const getSurveyAggregation = async (surveyId: string) => {
  const url = pinterpolate(config.endpoints.survey.aggregation, { surveyId });

  const { data } = await http.get(url);

  return data.data;
};

export const fetchAllIndividualSurveys = async (params: ISurveysParams) => {
  const url = stringify(config.endpoints.survey.all, {
    ...params,
  });

  const { data } = await http.get(url);

  return data;
};

export const fetchIndividualSurveyDetail = async (surveyId: string) => {
  const url = pinterpolate(config.endpoints.survey.detail, { id: surveyId });

  const { data } = await http.get(url);

  return data;
};

export const fetchSurveyResponsesForStudent = async (
  studentId: string,
  termId?: string,
) => {
  const url = pinterpolate(config.endpoints.responses.forStudent, {
    id: studentId,
  });

  const { data } = await http.get(url, { params: { termId } });

  return data;
};

export const fetchEarlySurveyResponsesForStudent = async (
  studentId: string,
  termId?: string,
) => {
  const url = pinterpolate(config.endpoints.responses.forStudentEarly, {
    id: studentId,
  });

  const { data } = await http.get(url, {
    params: { termId, role: "Practitioner" },
  });

  return data;
};

export const resendSurveyEmail = async (
  surveyId: string,
  payload: IResendSurveyPayload,
) => {
  const url = pinterpolate(config.endpoints.survey.resendEmail, {
    id: surveyId,
  });

  const response = await http.put(url, payload);

  return response;
};
