import React from "react";
import { connect } from "react-redux";

import Form from "../Form";
import { IUser } from "commons/types/users";
import { getFullName } from "utils/names";
import { getCurrentEnrollment } from "utils/user";
import { addCourse, fetchCourses } from "store/actions/data/courses";
import { ICourses, ICoursesInput, ICoursesParams } from "commons/types/courses";
import { success } from "utils/toast";
import { isStudentLoggedIn } from "utils/window";

interface IAddCourseProps {
  student: IUser;
  closeModal: () => void;
  addCourse: (payload: ICoursesInput) => any;
  fetchCourses: (params: ICoursesParams) => Promise<ICourses[]>;
}

const AddCourse: React.FC<IAddCourseProps> = ({
  student,
  closeModal,
  addCourse,
  fetchCourses,
}) => {
  const currentEnrollment = getCurrentEnrollment(student);

  const initialValues: ICoursesInput = {
    term: currentEnrollment?.enrolledTerm?._id,
    user: student._id || "",
    name: "",
    time: "",
    sessionDuration: "",
    firstDay: new Date(),
    lastDay: new Date(),
    daysOfWeek: [],
    teacher: "",
  };

  const saveCourse = async (payload: ICoursesInput) => {
    await addCourse(payload);
    fetchCourses({
      userId: student._id,
      termId: currentEnrollment?.enrolledTerm?._id,
    });
    success("Course added successfully");
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        {isStudentLoggedIn() ? (
          <h3>Add a course</h3>
        ) : (
          <h3>Add a course for {getFullName(student)}</h3>
        )}
        <span className="link-item" onClick={() => closeModal()}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={initialValues}
        saveCourse={saveCourse}
        isEdit={false}
        closeModal={closeModal}
      />
    </div>
  );
};
interface State {}
const mapStateToProps = (state: State) => ({});
const mapDispatchToProps = {
  addCourse,
  fetchCourses,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCourse);
