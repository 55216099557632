import config from "configs";
import { http } from "../utils/http";

export const getTwilioVideoConfig = async (
  identity: string,
  roomName: string,
  peer?: string | null,
) => {
  const { data } = await http.post(config.endpoints.communication.video_token, {
    identity: identity,
    room: roomName,
    peer: peer,
  });

  return data;
};
