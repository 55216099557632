import { useFormik } from "formik";
import { connect } from "react-redux";
import { FC, useEffect } from "react";

// Types
import { IUser } from "commons/types/users";
import { ICourses, ICoursesParams } from "commons/types/courses";
import {
  IAssignment,
  IAssignmentParams,
  IUpdateAssignment,
} from "commons/types/assignments";

// Schema
import AssignmentSchema from "commons/schemas/assignment";

// Actions
import { fetchCourses } from "store/actions/data/courses";
import { fetchAssignments } from "store/actions/data/assignments";
// Components
import { error } from "utils/toast";
import {
  DateInput,
  CustomSelect,
  OptionsInput,
  TextInput,
} from "commons/components/form-fields";
import { getCurrentEnrollment } from "utils/user";

type FormProps = {
  closeModal: () => void;
  student: IUser;
  fetchAssignments: (params: IAssignmentParams) => Promise<IAssignment[]>;
  saveAssignment: (payload: any) => any;
  initialValues: IAssignment | IUpdateAssignment;
  courses: ICourses[];
  fetchCourses: (params: ICoursesParams) => Promise<ICourses[]>;
};

const Form: FC<FormProps> = ({
  closeModal,
  student,
  saveAssignment,
  fetchAssignments,
  initialValues,
  courses,
  fetchCourses,
}): JSX.Element => {
  const isEdit: boolean = !!initialValues.name;
  const courseOptions = courses.map((course) => ({
    label: course.name,
    value: course._id,
  }));

  useEffect(() => {
    fetchCourses({
      userId: student?._id,
      termId: getCurrentEnrollment(student)?.enrolledTerm?._id,
    });
  }, [fetchCourses, student]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        await saveAssignment(values);
        if (student._id) {
          fetchAssignments({ subject: student._id });
        }

        closeModal();
      } catch (err: any) {
        error("Failed to save assignment");
      }
    },
    validationSchema: AssignmentSchema,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body">
        <div className="row">
          <div className="col-12">
            <TextInput
              label={"Title of the Assignment"}
              name={"name"}
              placeholder={"Title"}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <CustomSelect
              label="Course"
              name="course"
              options={courseOptions}
              required={true}
              value={
                courseOptions.find(
                  (course: any) => course.value === formik.values.course,
                ) || { label: "", value: "" }
              }
              handleChange={(value: any) => {
                formik.setFieldValue("course", value.value);
              }}
              touched={formik.touched}
              errors={formik.errors}
            />
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <label htmlFor="dueDate" className="input__label required">
                Due Date
              </label>
              <DateInput
                id="dueDate"
                name="submissionDate"
                isValid={
                  !(
                    formik.touched.submissionDate &&
                    formik.errors.submissionDate
                  )
                }
                selected={formik.values?.submissionDate || null}
                onChange={(date) => {
                  formik.setFieldValue("submissionDate", date);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
            {formik.errors.submissionDate && formik.touched.submissionDate ? (
              <p className="input__error">{formik.errors.submissionDate}</p>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <OptionsInput
              type={"checkbox"}
              groupName={"isPrintable"}
              options={[
                {
                  id: "check2",
                  label: "include in Report",
                  value: "true",
                  isChecked: formik.values.isPrintable || false,
                },
              ]}
              handleChange={(event) => {
                formik.setFieldValue("isPrintable", !formik.values.isPrintable);
              }}
              errors={formik.errors}
              touched={formik.touched}
            />
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={
                formik.isSubmitting ||
                (isEdit && formik.values === formik.initialValues)
              }
            >
              {isEdit ? "Save" : "Add Missing Assignment"}
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

interface State {
  courses: { fetchCourses: ICourses[] };
}

const mapStateToProps = (state: State) => ({
  courses: state.courses.fetchCourses,
});

const mapDispatchToProps = {
  fetchAssignments,
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
