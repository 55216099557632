import Modal from "react-modal";

import Loader from "commons/components/Loader";

interface Props {
  sendConfirmOpen: boolean;
  setSendConfirmOpen: (argument: boolean) => void;
  surveyInitiateLoading: boolean;
  handleFormSubmit: () => void;
}

const SurveySendConfirm: React.FC<Props> = ({
  sendConfirmOpen,
  setSendConfirmOpen,
  surveyInitiateLoading,
  handleFormSubmit,
}) => {
  return (
    <Modal className="modal-block" isOpen={sendConfirmOpen}>
      <div className="modal-wrap">
        <div className="modal-wrap__header">
          <h3>Confirmation</h3>
          <span className="link-item">
            <box-icon onClick={() => setSendConfirmOpen(false)} name="x" />
          </span>
        </div>
        <div className="modal-wrap__body ">
          Are you sure you want to send the survey?
        </div>
        <div className="modal-wrap__footer">
          <div className="row">
            <div className="col-12 d-flex">
              {surveyInitiateLoading ? (
                <Loader type="ThreeDots" />
              ) : (
                <button
                  onClick={handleFormSubmit}
                  className="btn btn--green mr-4x"
                  type="button"
                >
                  Send
                </button>
              )}

              <button
                onClick={() => setSendConfirmOpen(false)}
                className="btn"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SurveySendConfirm;
