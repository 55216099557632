import { Formik } from "formik";
import { FormEvent } from "react";

import GoalSchema from "commons/schemas/goal";
import { IGoal } from "commons/types/goals";
import {
  TextInput,
  DateInput,
  OptionsInput,
} from "commons/components/form-fields";

type AddGoalFormProps = {
  closeModal: () => void;
  saveGoal: (goal: IGoal) => void;
  initialValues: IGoal;
};

interface FormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any) => void;
  values: IGoal;
  touched: IGoal;
  errors: IGoal;
  isSubmitting: boolean;
}

const AddGoalForm: React.FC<AddGoalFormProps> = ({
  initialValues,
  closeModal,
  saveGoal,
}) => {
  const isEdit: boolean = !!initialValues.name.length;

  const getGoalTypeOptions = (props: FormikProps) => [
    {
      id: "academic",
      label: "Academic",
      value: "Academic",
      isChecked: props.values.workData?.workType === "Academic",
    },
    {
      id: "non-academic",
      label: "Non-Academic",
      value: "Non-Academic",
      isChecked: props.values.workData?.workType === "Non-Academic",
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={saveGoal}
      validationSchema={GoalSchema}
      enableReinitialize={true}
    >
      {(props: FormikProps) => {
        return (
          <form onSubmit={props.handleSubmit}>
            <div className="modal-wrap__body">
              <div className="row">
                <div className="col-12">
                  <TextInput
                    label="Title of the Goal"
                    placeholder="Enter title of the goal"
                    name="name"
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    values={props.values}
                    touched={props.touched}
                    errors={props.errors}
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3x required">Type of Goal</div>
                  <OptionsInput
                    type="radio"
                    groupName="workData.workType"
                    options={getGoalTypeOptions(props)}
                    handleChange={props.handleChange}
                    errors={props.errors}
                    touched={props.touched}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="input-wrap">
                    <label className="input__label required">Due Date</label>
                    <DateInput
                      placeholder="Please select"
                      name="requiredDeadline"
                      onChange={(date) =>
                        props.setFieldValue("requiredDeadline", date)
                      }
                      onBlur={props.handleBlur}
                      selected={props.values.requiredDeadline}
                      isValid={
                        !(
                          props.touched.requiredDeadline &&
                          props.errors.requiredDeadline
                        )
                      }
                      minDate={isEdit ? null : new Date()}
                    />
                    <label className="input__error">
                      {props.touched.requiredDeadline &&
                        props.errors.requiredDeadline}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <TextInput
                    label="Metric Type"
                    placeholder="Enter type of the metric"
                    name="workData.metrics.metricsType"
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    values={props.values}
                    touched={props.touched}
                    errors={props.errors}
                    required={false}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    label="Metric Baseline Value"
                    placeholder="Enter baseline of the metric"
                    name="workData.metrics.baselineMetric"
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    values={props.values}
                    touched={props.touched}
                    errors={props.errors}
                    required={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <TextInput
                    disabled={!isEdit}
                    label="Achieved Metric Value"
                    placeholder="Enter achieved value of the metric"
                    name="workData.metrics.achievedMetric"
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    values={props.values}
                    touched={props.touched}
                    errors={props.errors}
                    required={false}
                  />
                </div>
                <div className="col-6">
                  <div className="input-wrap">
                    <label className="input__label ">Baseline Date</label>
                    <DateInput
                      placeholder="Please select"
                      name="workData.metrics.baselineDate"
                      onChange={(date) =>
                        props.setFieldValue(
                          "workData.metrics.baselineDate",
                          date?.toString(),
                        )
                      }
                      onBlur={props.handleBlur}
                      selected={props.values.workData?.metrics?.baselineDate}
                      isValid={
                        !(
                          props.values.workData?.metrics?.baselineDate &&
                          props.errors.workData?.metrics?.baselineDate
                        )
                      }
                      minDate={isEdit ? null : new Date()}
                    />
                    <label className="input__error">
                      {props.values.workData?.metrics?.baselineDate &&
                        props.errors.workData?.metrics?.baselineDate}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-wrap__footer pt-0x">
              <div className="row">
                <div className="col-12 d-flex">
                  <button
                    disabled={props.isSubmitting}
                    className="btn btn--primary mr-4x"
                    type="submit"
                  >
                    {isEdit ? "Save" : "Add Goal"}
                  </button>
                  <button
                    disabled={props.isSubmitting}
                    onClick={closeModal}
                    className="btn txt-primary-color"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddGoalForm;
