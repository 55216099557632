import { connect } from "react-redux";

import { setSurveyRespondResult } from "store/actions/data/surveys";
import {
  SurveySkillSet,
  SurveyQuestions,
  IResponseOption,
  ISurveyRespondResult,
  AnswerType,
} from "commons/types/surveys";

interface IFieldProps {
  id: string;
  label: string;
  value: string;
  answerId: string;
  isChecked: boolean;
  disabled?: boolean;
}

const SingleForm = ({
  role,
  questionGroup,
  surveyRespondResult,
  setSurveyRespondResult,
}: {
  id: string;
  role: string;
  questionGroup: SurveySkillSet;
  surveyRespondResult: ISurveyRespondResult[];
  setSurveyRespondResult: (
    newSurveyRespondResult: ISurveyRespondResult[],
  ) => void;
}) => {
  const handleChange = (
    event: any,
    answerId: string,
    value: string,
    type: string,
  ) => {
    const newSurveyRespondResultElement = {
      type: type,
      question: event.target.name,
      answer: event.target.checked
        ? type === AnswerType.SINGLE
          ? { answer: answerId, value: value }
          : [{ answer: answerId, value: value }]
        : [],
    };
    const newSurveyRespondResult = surveyRespondResult.filter(
      (result: ISurveyRespondResult) =>
        result.question !== newSurveyRespondResultElement.question,
    );
    if (event.target.checked)
      newSurveyRespondResult.push(newSurveyRespondResultElement);

    setSurveyRespondResult(newSurveyRespondResult);
  };

  const getRadioQuestion = (questionData: SurveyQuestions, index: number) => {
    const options = questionData.data.options?.map(
      (optionData: IResponseOption) => {
        const answeredResult = surveyRespondResult.find(
          (res: { question: string }) =>
            res.question === questionData.question._id,
        );

        return {
          id: `${questionData.question._id}.${optionData._id}` || "",
          label: optionData.label || "",
          answerId: optionData._id || "",
          value: optionData.value || "",
          isChecked:
            (Array.isArray(answeredResult?.answer) &&
              answeredResult?.answer?.length &&
              answeredResult?.answer?.find(
                (answerData) => answerData.answer === optionData._id,
              )) ||
            (answeredResult?.answer &&
              "answer" in answeredResult?.answer &&
              answeredResult?.answer?.answer === optionData._id)
              ? true
              : false,
        };
      },
    );

    return (
      <div
        key={questionData?.question?._id}
        className="card align-items-center"
      >
        <div className="card-content">
          <div className="card-text card-text--no-margin">
            <div className="text-light-md mb-2x">
              {questionData.question?.skill?.name}
            </div>
            <div className="card-head">
              <h3 className="break-word">{`${index}. ${
                questionData.question.title[role] ||
                questionData.question.title["Subject"]
              }`}</h3>
            </div>
            <div className={`radio-inline mb-0x mt-4x`}>
              <div className="radio-options">
                {options.map(
                  (
                    { label, id, answerId, value, isChecked }: IFieldProps,
                    index: number,
                  ) => (
                    <div key={`checkbox-${index}`} className="input-wrap">
                      <input
                        className="box-links__nodes"
                        type="radio"
                        name={questionData.question._id}
                        value={value}
                        checked={isChecked}
                        onChange={(event) =>
                          handleChange(
                            event,
                            answerId,
                            value,
                            questionData.answerType.type,
                          )
                        }
                        id={id}
                      />
                      <label className={`input__label`} htmlFor={id}>
                        {label}
                      </label>
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="section">
      <div className="section__title">
        <h3 className="txt-darkgrey-color pl-2x">
          {questionGroup.skillset.name}
        </h3>
      </div>
      <div className="section__content">
        {questionGroup.questions.map(
          (questionData: SurveyQuestions, index: number) => {
            return getRadioQuestion(questionData, index + 1);
          },
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state: {
  surveys: { surveyRespondResult: ISurveyRespondResult[] };
}) => ({
  surveyRespondResult: state.surveys.surveyRespondResult,
});

const mapDispatchToProps = {
  setSurveyRespondResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleForm);
