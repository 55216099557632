export enum TermStatus {
  Enrolled = "Enrolled",
  NotEnrolled = "NotEnrolled",
  PreBooking = "PreBooking",
  DropInProgress = "DropInProgress",
  Dropped = "Dropped",
  Draft = "Draft",
  Graduated = "Graduated",
}

export const TERM_STATUS_OPTIONS = [
  { value: TermStatus.Enrolled, label: "Enrolled" },
  { value: TermStatus.PreBooking, label: "Pre-Booking" },
  { value: TermStatus.Graduated, label: "Graduated" },
  { value: TermStatus.Dropped, label: "Dropped" },
  { value: TermStatus.Draft, label: "Draft" },
  { value: TermStatus.NotEnrolled, label: "Not Enrolled" },
  { value: TermStatus.DropInProgress, label: "Drop In Progress" },
];

export const TERM_STATUS_NOT_DROPPED_OPTIONS = [
  { value: TermStatus.Enrolled, label: "Enrolled" },
  { value: TermStatus.PreBooking, label: "Pre-Booking" },
  { value: TermStatus.Graduated, label: "Graduated" },
  { value: TermStatus.Draft, label: "Draft" },
  { value: TermStatus.NotEnrolled, label: "Not Enrolled" },
  { value: TermStatus.DropInProgress, label: "Drop In Progress" },
];

export const TERM_STATUS_DROP_IN_PROGRESS_OPTIONS = [
  { value: TermStatus.Enrolled, label: "Enrolled" },
  { value: TermStatus.PreBooking, label: "Pre-Booking" },
  { value: TermStatus.Graduated, label: "Graduated" },
  { value: TermStatus.Draft, label: "Draft" },
  { value: TermStatus.NotEnrolled, label: "Not Enrolled" },
  { value: TermStatus.Dropped, label: "Dropped" },
];
