import React from "react";

import Form from "../form";
import { ISchoolDistrict } from "commons/types/schoolDistrict";

interface IAddSchoolDistrictProps {
  closeModal: () => void;
  saveSchoolDistrict: (schoolDistrict: ISchoolDistrict) => Promise<void>;
}

const AddSchoolDistrict = ({
  closeModal,
  saveSchoolDistrict,
}: IAddSchoolDistrictProps) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add a School District</h3>
        <span className="link-item" onClick={() => closeModal()}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={{
          _id: "",
          name: "",
        }}
        closeModal={closeModal}
        saveSchoolDistrict={saveSchoolDistrict}
      />
    </div>
  );
};

export default AddSchoolDistrict;
