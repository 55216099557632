import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";
import tasks from "assets/images/tasks.svg";
import "boxicons";

const MentorGoalsEmpty = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <ul className="navbar-list">
                <li>
                  <span className="text-dropdown">
                    EN
                    <span className="action-links">
                      <box-icon name="chevron-down"></box-icon>
                    </span>
                  </span>
                </li>
                <li>
                  <a href="#/" className="action-links user-dropdown">
                    <span className="action-links">
                      <box-icon name="chat"></box-icon>
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#/" className="action-links user-dropdown">
                    <span className="action-links">
                      <box-icon name="user"></box-icon>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="arrow-back"></box-icon>
                    Go Back
                  </span>
                </li>
                <li className="nav-left__item--title">Samantha Doe</li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Plans
                  </span>
                </li>
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="bullseye"></box-icon>
                    Goals
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="book-open"></box-icon>
                    Assignments
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar"></box-icon>
                    Sessions
                  </span>
                </li>
                <li className="nav-left__item--title">
                  Additional information
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="user"></box-icon>
                    Personal Info
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="graduation" type="solid"></box-icon>
                    Academic Info
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="alert alert__danger">
                <span className="status link-item">
                  <box-icon name="error-alt"></box-icon>
                </span>
                <div className="alert__content">
                  <strong>Jacob Fox is not enrolled in current term.!</strong>
                  Contact Intake manager to enroll.
                </div>
                <span className="close link-item">
                  <box-icon name="x"></box-icon>
                </span>
              </div>
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="mt-6x">
                    <box-icon name="arrow-back"></box-icon>
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-sm">Goals</span>
                    <h2>Learn Roman History</h2>
                    <ul className="heading-info">
                      <li>
                        <box-icon name="graduation" type="solid"></box-icon>
                        Academic goal
                      </li>
                      <li>Created by you on Mar 13, 2021</li>
                    </ul>
                  </div>
                </div>
                <div className="page-heading__right  align-items-center">
                  <button className="btn btn--primary mr-2x" type="button">
                    Add Task
                  </button>
                  <box-icon name="dots-horizontal-rounded"></box-icon>
                </div>
              </div>
              <div className="empty-section">
                <div className="empty-section__content">
                  <img src={tasks} alt="Tasks" />
                  <h3>You currently do not have any plans.</h3>
                  <p>
                    Go out and have some fun, or create a new task to get some
                    work done.
                  </p>
                  <button className="btn btn--primary mr-2x" type="button">
                    Add Task
                  </button>
                </div>
              </div>
            </div>
            <div className="right-panel collapse">
              <div className="message">
                <div className="message__title">
                  <h3>Messages</h3>
                  <div className="message-actions">
                    <span className="action-links snooze active">
                      <span>Available</span>
                      <box-icon name="alarm-snooze"></box-icon>
                    </span>
                    <span className="action-links add-member default">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content">
                  <div className="message-list unread">
                    <div className="avatar-wrap active">
                      <div className="avatar mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Samantha Doe <span>(Grade 7)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">Just now</span>
                        <span className="msg-preview text-ellipsis">
                          Hi! researched but I dont know the answer.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">BP</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Brendan Pollock <span>(Homework Center)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Yes thanks! I will ask the student.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">LD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Lori Denver <span>(Grade 9)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">Okay</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">JD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        John Doe <span>(Samantha's Parent)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Sure. I will ask Sam about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorGoalsEmpty;
