import { Dispatch } from "redux";

import { error } from "utils/toast";
import { FetchMentorsActionType } from "store/actions/types/mentors";
import { fetchMentors as fetchMentorsService } from "services/mentors";

export const fetchMentors = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchMentorsActionType.FETCH_MENTORS_PENDING,
  });
  try {
    const mentors = await fetchMentorsService();

    dispatch({
      type: FetchMentorsActionType.FETCH_MENTORS_FULFILLED,
      data: mentors,
    });

    return mentors;
  } catch (ex) {
    dispatch({
      type: FetchMentorsActionType.FETCH_MENTORS_REJECTED,
      data: ex,
    });

    error("Failed to fetch mentors");
  }
};
