import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";
import "boxicons";

const MentorMissingAssignment = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="link-item user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="arrow-back"></box-icon>
                    Go Back
                  </span>
                </li>
                <li className="nav-left__item--title">Samantha Doe</li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Plans
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="bullseye"></box-icon>
                    Goals
                  </span>
                </li>
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="book-open"></box-icon>
                    Missing Assignments
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar"></box-icon>
                    Sessions
                  </span>
                </li>
                <li className="nav-left__item--title">
                  Additional information
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="user"></box-icon>
                    Personal Info
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="graduation" type="solid"></box-icon>
                    Academic Info
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="avatar mt-3x">
                    <img src={student1} alt="Student" />
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-sm">Student Profile</span>
                    <h2>Samantha Doe</h2>
                    <ul className="heading-info">
                      <li className="">Grade 7</li>
                      <li>
                        <span className="link-item">
                          {" "}
                          Chicago Public School
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="page-heading__right">
                  <button className="btn btn--primary mr-2x" type="button">
                    Add Missing Assignments
                  </button>
                </div>
              </div>
              <div className="section-heading">
                <h3>Missing Assignments</h3>
              </div>
              <div className="accordion">
                <div className="accordion-item">
                  <div className="accordion-item__content">
                    <div className="card align-items-center">
                      <div className="card-content">
                        <div className="card-text">
                          <div className="card-head">
                            <h3>Roman History Assignment</h3>
                          </div>
                          <ul className="card-info">
                            <li>Course: History 403</li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due May 30,
                              2021
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                    <div className="card card--success align-items-center">
                      <div className="card-content">
                        <div className="card-text">
                          <div className="card-head">
                            <h3>Roman History Assignment</h3>
                            <span className="status status--success">
                              <box-icon name="check"></box-icon> Submitted
                            </span>
                          </div>
                          <ul className="card-info">
                            <li>Course: History 403</li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due April 1,
                              2021
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                    <div className="card align-items-center">
                      <div className="card-content">
                        <div className="card-text">
                          <div className="card-head">
                            <h3>Roman History Assignment</h3>
                            <span className="status status--error">
                              <box-icon name="error-alt"></box-icon> Missed
                            </span>
                          </div>
                          <ul className="card-info">
                            <li>Course: History 403</li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due March 3,
                              2021
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <h3>Messages</h3>
                  <div className="message-actions">
                    <span className="action-links active">
                      <span>Available</span>
                      <box-icon name="alarm-snooze"></box-icon>
                    </span>
                    <span className="action-links default">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content">
                  <div className="message-list unread">
                    <div className="avatar-wrap active">
                      <div className="avatar mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Samantha Doe <span>(Grade 7)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">Just now</span>
                        <span className="msg-preview text-ellipsis">
                          Hi! researched but I dont know the answer.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">BP</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Brendan Pollock <span>(Homework Center)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Yes thanks! I will ask the student.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">LD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Lori Denver <span>(Grade 9)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">Okay</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">JD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        John Doe <span>(Samantha's Parent)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Sure. I will ask Sam about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorMissingAssignment;
