import Form from "../Form";
import { IAddAccommodationProps } from "./addAccommodation.interface";

const AddAccommodation: React.FC<IAddAccommodationProps> = ({
  closeModal,
  saveAccommodation,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add an Accommodation</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={{
          _id: "",
          name: "",
        }}
        closeModal={closeModal}
        saveAccommodation={saveAccommodation}
      />
    </div>
  );
};

export default AddAccommodation;
