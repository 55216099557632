import { FetchResponseRevisionsActionType } from "store/actions/types/responseRevision";

const INITIAL_STATE = {
  fetchResponseRevisions: [],
  fetchResponseRevisionsLoading: false,
  fetchResponseRevisionError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchResponseRevisionsActionType.FETCH_RESPONSE_REVISIONS_PENDING:
      return {
        ...state,
        fetchResponseRevisionsLoading: true,
      };
    case FetchResponseRevisionsActionType.FETCH_RESPONSE_REVISIONS_FULFILLED: {
      return {
        ...state,
        fetchResponseRevisionsLoading: false,
        fetchResponseRevisions: action.data,
      };
    }
    case FetchResponseRevisionsActionType.FETCH_RESPONSE_REVISIONS_REJECTED: {
      return {
        ...state,
        fetchResponseRevisionsLoading: false,
        fetchResponseRevisionError: action.data,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default reducer;
