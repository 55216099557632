import { IUser } from "commons/types/users";

export enum FetchMentorsActionType {
  FETCH_MENTORS_PENDING = "FETCH_MENTORS_PENDING",
  FETCH_MENTORS_REJECTED = "FETCH_MENTORS_REJECTED",
  FETCH_MENTORS_FULFILLED = "FETCH_MENTORS_FULFILLED",
}

export interface FetchMentorsAction {
  type: FetchMentorsActionType;
  data: IUser;
}
