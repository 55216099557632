import { FetchEarlySurveyResponsesActionType } from "./../../actions/types/surveys";
import {
  SendSurveysActionType,
  FetchSurveyCategoriesType,
  SetSurveyRespondResultActionType,
  FetchIndividualSurveysActionType,
  FetchIndividualSurveyDetailActionType,
  FetchSurveyResponsesActionType,
} from "store/actions/types/surveys";

const INITIAL_STATE = {
  sendData: {},
  surveyCategories: [],
  surveyInitiateSuccess: false,
  surveyInitiateLoading: false,
  surveyRespondResult: [],
  individualSurveys: [],
  fetchIndividualSurveyLoading: false,
  fetchSurveysError: null,
  surveyDetail: {},
  fetchIndividualSurveyDetailLoading: true,
  fetchSurveyDetailError: null,
  surveyResponses: [],
  fetchSurveyResponsesLoading: false,
  fetchsurveyResponsesError: null,
  fetchSurveyCategoriesLoading: true,
  fetchEarlySurveyResponsesLoading: false,
  fetchEarlySurveyResponsesError: null,
  surveyEarlyResponses: [],
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SendSurveysActionType.SEND_SURVEY_PENDING:
      return {
        ...state,
        surveyInitiateLoading: true,
      };
    case SendSurveysActionType.SEND_SURVEY_SUCCESSFULL: {
      return {
        ...state,
        surveyInitiateLoading: false,
        sendData: action.data,
        surveyInitiateSuccess: true,
      };
    }
    case SendSurveysActionType.SEND_SURVEY_FAILED: {
      return {
        ...state,
        surveyInitiateLoading: false,
        surveyInitiateSuccess: false,
        sendData: action.payload,
      };
    }
    case FetchSurveyCategoriesType.GET_SURVEYS_PENDING: {
      return {
        ...state,
        surveyInitiateSuccess: false,
        fetchSurveyCategoriesLoading: true,
      };
    }

    case FetchSurveyCategoriesType.GET_SURVEYS_SUCCESSFULL: {
      return {
        ...state,
        surveyCategories: action.payload,
        fetchSurveyCategoriesLoading: false,
      };
    }

    case FetchSurveyCategoriesType.GET_SURVEYS_FAILED: {
      return {
        ...state,
        surveyCategories: [],
      };
    }

    case SetSurveyRespondResultActionType.SET_SURVEY_RESPOND_RESULT_SUCCESSFUL: {
      return {
        ...state,
        surveyRespondResult: action.data,
      };
    }

    case FetchIndividualSurveysActionType.FETCH_INDIVIDUAL_SURVEYS_PENDING:
      return {
        ...state,
        fetchIndividualSurveyLoading: true,
        fetchSurveysError: null,
      };
    case FetchIndividualSurveysActionType.FETCH_INDIVIDUAL_SURVEYS_FULFILLED:
      return {
        ...state,
        individualSurveys: action.data,
        fetchIndividualSurveyLoading: false,
        fetchSurveysError: null,
      };
    case FetchIndividualSurveysActionType.FETCH_INDIVIDUAL_SURVEYS_REJECTED:
      return {
        ...state,
        fetchIndividualSurveyLoading: false,
        fetchSurveysError: action.data,
      };

    case FetchSurveyResponsesActionType.FETCH_SURVEY_RESPONSE_PENDING:
      return {
        ...state,
        fetchSurveyResponsesLoading: true,
        fetchsurveyResponsesError: null,
      };
    case FetchSurveyResponsesActionType.FETCH_SURVEY_RESPONSE_FULFILLED:
      return {
        ...state,
        surveyResponses: action.data,
        fetchSurveyResponsesLoading: false,
        fetchsurveyResponsesError: null,
      };
    case FetchSurveyResponsesActionType.FETCH_SURVEY_RESPONSE_REJECTED:
      return {
        ...state,
        fetchSurveyResponsesLoading: false,
        fetchsurveyResponsesError: action.data,
      };

    case FetchEarlySurveyResponsesActionType.FETCH_EARLY_SURVEY_RESPONSE_PENDING:
      return {
        ...state,
        fetchEarlySurveyResponsesLoading: true,
        fetchEarlySurveyResponsesError: null,
      };
    case FetchEarlySurveyResponsesActionType.FETCH_EARLY_SURVEY_RESPONSE_FULFILLED:
      return {
        ...state,
        surveyEarlyResponses: action.data,
        fetchEarlySurveyResponsesLoading: false,
        fetchEarlySurveyResponsesError: null,
      };
    case FetchEarlySurveyResponsesActionType.FETCH_EARLY_SURVEY_RESPONSE_REJECTED:
      return {
        ...state,
        fetchEarlySurveyResponsesLoading: false,
        fetchEarlySurveyResponsesError: action.data,
      };

    case FetchIndividualSurveyDetailActionType.FETCH_INDIVIDUAL_SURVEY_DETAIL_PENDING:
      return {
        ...state,
        fetchIndividualSurveyDetailLoading: true,
        fetchSurveyDetailError: null,
      };

    case FetchIndividualSurveyDetailActionType.FETCH_INDIVIDUAL_SURVEY_DETAIL_FULFILLED:
      return {
        ...state,
        surveyDetail: action.data,
        fetchIndividualSurveyDetailLoading: false,
        fetchSurveyDetailError: null,
      };

    case FetchIndividualSurveyDetailActionType.FETCH_INDIVIDUAL_SURVEY_DETAIL_REJECTED:
      return {
        ...state,
        fetchIndividualSurveyDetailLoading: false,
        fetchSurveyDetailError: action.data,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default reducer;
