import React, { useState } from "react";

import { IAvailability } from "commons/types/availability";
import { isStudentLoggedIn } from "utils/window";

type ToggleAvailabilityProps = {
  closeModal: () => void;
  updateAvailability: (isAvailable: boolean, isNotifyOffline: boolean) => void;
  availability: IAvailability;
};

const ToggleAvailability: React.FC<ToggleAvailabilityProps> = ({
  closeModal,
  updateAvailability,
  availability,
}: ToggleAvailabilityProps) => {
  const [isAvailable, setAvailable] = useState<boolean>(
    availability.isAvailable,
  );
  // TODO: Use state for notify when offline on phase 2
  // const [isNotifyWhenOffline, setNotifyWhenOffline] = useState<boolean>(
  //   availability.isNotifyWhenOffline,
  // );
  const isNotifyWhenOffline = availability.isNotifyWhenOffline;

  const handleDone = () => {
    if (
      !(
        isAvailable === availability.isAvailable &&
        isNotifyWhenOffline === availability.isNotifyWhenOffline
      )
    ) {
      updateAvailability(isAvailable, isNotifyWhenOffline);
    }
    closeModal();
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Availability</h3>
        <span className="link-item" onClick={closeModal}>
          {" "}
          <box-icon name="x" />
        </span>
      </div>
      <div className="modal-wrap__body">
        <div className="status-toggle">
          <h2 className="fs-body1">
            You are currently {isAvailable ? "available" : "unavailable"}
          </h2>
          <label className="toggle">
            <input
              type="checkbox"
              className="toggle-checkbox"
              checked={isAvailable}
              onChange={() => setAvailable(!isAvailable)}
            />
            <div className="toggle-switch"></div>
          </label>
        </div>
        {/* <section className="section"> */}
        <section className="section__content">
          <p className="fs-medium">
            {`${isStudentLoggedIn() ? "Mentors" : "Students"} will ${
              isAvailable ? "" : "not "
            }be able to see that you are online.`}
          </p>
        </section>
        {/* </section> */}

        {/* <div className="row">
          <div className="col-12">
            <div className="radio-inline">
              <div className="input-wrap">
                <input
                  id="checkbox1"
                  type="checkbox"
                  className="box-link__nodes"
                  checked={isNotifyWhenOffline}
                  onChange={() => setNotifyWhenOffline(!isNotifyWhenOffline)}
                />
                <label htmlFor="checkbox1" className="input__label">
                  {" "}
                  Send me notifications during off hours.
                </label>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="button"
              onClick={handleDone}
            >
              Done
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleAvailability;
