import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import { INote, INoteParams } from "commons/types/notes";

/**
 * Add note
 * @param payload Note
 * @returns note
 */
export const add = async (payload: INote) => {
  const url = config.endpoints.notes.all;

  const { data } = await http.post(url, {
    ...payload,
  });

  return data;
};

/**
 * Update note
 * @param id
 * @param payload
 * @returns updated note
 */
export const update = async (id: string, payload: Partial<INote>) => {
  const url = pinterpolate(config.endpoints.notes.one, { id: id });

  const { data } = await http.patch(url, payload);

  return data;
};

/**
 * Fetch notes
 * @param params
 * @returns notes list
 */
export const fetchAll = async (params: INoteParams) => {
  const url = stringify(config.endpoints.notes.all, {
    ...params,
  });

  const { data } = await http.get(url);

  return data;
};

/**
 * Soft delete note
 * @param id
 * @returns deleted data
 */
export const deleteNote = async (id: string) => {
  const url = pinterpolate(config.endpoints.notes.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
