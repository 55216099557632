import { FetchPlanningPeriodsActionType } from "store/actions/types/planningPeriod";

const INITIAL_STATE = {
  fetchPlanningPeriods: [],
  fetchPlanningPeriodsLoading: true,
  FetchPlanningPeriodsError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchPlanningPeriodsActionType.FETCH_PLANNING_PERIOD_PENDING:
      return {
        ...state,
        fetchPlanningPeriodsLoading: true,
        fetchPlanningPeriodsError: null,
      };
    case FetchPlanningPeriodsActionType.FETCH_PLANNING_PERIOD_FULFILLED:
      return {
        ...state,
        fetchPlanningPeriods: action.data,
        fetchPlanningPeriodsLoading: false,
        fetchPlanningPeriodsError: null,
      };

    case FetchPlanningPeriodsActionType.FETCH_PLANNING_PERIOD_REJECTED:
      return {
        ...state,
        fetchPlanningPeriodsLoading: false,
        fetchPlanningPeriodsError: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
