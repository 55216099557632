import React from "react";
import { connect } from "react-redux";
import { Column, useTable } from "react-table";

import CentersActionCell from "./CentersActionCell";
import { ICenter, ICentersParams } from "../../../commons/types/centers";
import {
  deleteCenter,
  fetchCenters,
  updateCenter,
} from "../../../store/actions/data/centers";

const CentersList = ({
  centers,
  onAddButtonClicked,
  deleteCenter,
  fetchCenters,
  updateCenter,
}: {
  centers: Array<ICenter>;
  onAddButtonClicked: () => void;
  deleteCenter: (id: string) => void;
  fetchCenters: (params: ICentersParams) => void;
  updateCenter: (id: string, center: ICenter) => Promise<void>;
}) => {
  const handleDeleteCenter = React.useMemo(
    () => async (id: string) => {
      await deleteCenter(id);

      fetchCenters({});
    },
    [deleteCenter, fetchCenters],
  );
  const handleUpdateCenters = React.useMemo(
    () => async (id: string, center: ICenter) => {
      await updateCenter(id, center);
      fetchCenters({});
    },
    [updateCenter, fetchCenters],
  );

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ value }: { value: string }) => {
            return value || "-";
          },
        },
        {
          Header: "Street Address",
          accessor: "address",
          Cell: ({ value }: { value: string }) => {
            return value || "-";
          },
        },
        {
          Header: "City",
          accessor: "city",
          Cell: ({ value }: { value: string }) => {
            return value || "-";
          },
        },
        {
          Header: "State",
          accessor: "state",
          Cell: ({ value }: { value: string }) => {
            return value || "-";
          },
        },
        {
          Header: "Zip Code",
          accessor: "zipCode",
          Cell: ({ value }: { value: string }) => {
            return value || "-";
          },
        },
        {
          Header: "Actions",
          accessor: "_id",
          Cell: ({ row }: any) => {
            return (
              <CentersActionCell
                row={row}
                onEditCenter={handleUpdateCenters}
                onDeleteCenter={handleDeleteCenter}
              />
            );
          },
        },
      ] as Column<ICenter>[],
    [handleDeleteCenter, handleUpdateCenters],
  );

  const tableInstance = useTable({ columns, data: centers });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <div className="content-panel">
        <div className="page-heading">
          <h1>Centers</h1>
          <div className="page-heading__right align-items-center">
            <button
              className="btn btn--primary"
              type="button"
              onClick={onAddButtonClicked}
            >
              Add
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="head-row">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="table__col">
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="table__row">
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="table__col">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="right-gap" />
    </>
  );
};

const mapDispatchToProps = {
  deleteCenter,
  fetchCenters,
  updateCenter,
};

export default connect(null, mapDispatchToProps)(CentersList);
