import React from "react";
import Modal from "react-modal";

import EditSchool from "./edit-school";
import { ISchoolCreate, ISchoolGradeType } from "commons/types/school";
import DeleteConfirm from "commons/components/DeleteConfirm";

const SchoolActionCell = ({
  row,
  handleUpdateSchool,
  handleDeleteSchool,
}: any) => {
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false);

  const toggleModal = () => {
    setEditOpen(!isEditOpen);
  };

  React.useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const getInitialValues: () => ISchoolCreate = () => {
    return {
      _id: row.original._id,
      name: row.original.name,
      type: row.original.type,
      district: row.original.district?._id || "",
      gradeTypes: row.original.gradeTypes.map(
        (gradeType: ISchoolGradeType) => ({
          isDefault: gradeType.isDefault,
          grade: gradeType?.grade?._id || "",
        }),
      ),
      description: row.original.description,
    };
  };

  return (
    <>
      <div className="action-group">
        <span className="link-item mr-2x" onClick={toggleModal}>
          <box-icon name="edit-alt" color="#959FAE"></box-icon>
        </span>
        <span
          className="link-item mr-2x"
          onClick={() => {
            setIsDeleteConfirmationOpen(true);
          }}
        >
          <box-icon name="trash-alt" color="#959FAE"></box-icon>
        </span>
      </div>
      <Modal isOpen={isEditOpen} className="modal-block">
        <EditSchool
          initialValues={getInitialValues()}
          closeModal={() => setEditOpen(false)}
          updateSchool={handleUpdateSchool}
        />
      </Modal>
      <Modal isOpen={isDeleteConfirmationOpen} className="modal-block">
        <DeleteConfirm
          closeModal={() => setIsDeleteConfirmationOpen(false)}
          message={{
            header: "Do you want to delete this school?",
            body: "Deleting a school will delete permanently. This action is not reversible.",
          }}
          deleteResource={() => {
            handleDeleteSchool(row.original._id);
            setIsDeleteConfirmationOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
export default SchoolActionCell;
