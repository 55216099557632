export enum Status {
  DRAFT = "Draft",
  ACTIVE = "Active",
  INACTIVE = "InActive",
}

export const StatusOptions = ["Draft", "Active", "Inactive"];

export enum GoalStatus {
  CREATED = "Created",
  MET = "Met",
  NOT_MET = "Not-Met",
  PROGRESS = "In-Progress",
}
