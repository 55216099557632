import { IAccommodation } from "commons/types/accommodation";

export enum AddAccommodationActionType {
  ADD_ACCOMMODATION_PENDING = "ADD_ACCOMMODATION_PENDING",
  ADD_ACCOMMODATION_REJECTED = "ADD_ACCOMMODATION_REJECTED",
  ADD_ACCOMMODATION_FULFILLED = "ADD_ACCOMMODATION_FULFILLED",
}
export interface AddAccommodationAction {
  type: AddAccommodationActionType;
  data: IAccommodation;
}

export enum FetchAccommodationsActionType {
  FETCH_ACCOMMODATION_PENDING = "FETCH_ACCOMMODATION_PENDING",
  FETCH_ACCOMMODATION_REJECTED = "FETCH_ACCOMMODATION_REJECTED",
  FETCH_ACCOMMODATION_FULFILLED = "FETCH_ACCOMMODATION_FULFILLED",
}
export interface FetchAccommodationsAction {
  type: FetchAccommodationsActionType;
  data: IAccommodation[];
}

export enum FetchAccommodationActionType {
  FETCH_ACCOMMODATION_PENDING = "FETCH_ACCOMMODATION_PENDING",
  FETCH_ACCOMMODATION_REJECTED = "FETCH_ACCOMMODATION_REJECTED",
  FETCH_ACCOMMODATION_FULFILLED = "FETCH_ACCOMMODATION_FULFILLED",
}
export interface FetchAccommodationAction {
  type: FetchAccommodationActionType;
  data: IAccommodation;
}

export enum UpdateAccommodationActionType {
  UPDATE_ACCOMMODATION_PENDING = "UPDATE_ACCOMMODATION_PENDING",
  UPDATE_ACCOMMODATION_REJECTED = "UPDATE_ACCOMMODATION_REJECTED",
  UPDATE_ACCOMMODATION_FULFILLED = "UPDATE_ACCOMMODATION_FULFILLED",
}
export interface UpdateAccommodationAction {
  type: UpdateAccommodationActionType;
  data: IAccommodation;
}

export enum DeleteAccommodationActionType {
  DELETE_ACCOMMODATION_PENDING = "DELETE_ACCOMMODATION_PENDING",
  DELETE_ACCOMMODATION_FULFILLED = "DELETE_ACCOMMODATION_FULFILLED",
  DELETE_ACCOMMODATION_REJECTED = "DELETE_ACCOMMODATION_REJECTED",
}

export interface DeleteAccommodationAction {
  type: DeleteAccommodationActionType;
  data: IAccommodation;
}
