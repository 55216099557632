import { ChangeEventHandler, FC, ReactElement } from "react";
import MaskedInput from "react-text-mask";

import { getFieldValue } from "utils/arrays";

interface IPhoneNumberProps {
  label: string | React.ReactElement;
  name: string;
  type?: string;
  disabled?: boolean;
  placeholder: string;
  errors: any;
  touched: any;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleBlur?: ChangeEventHandler<HTMLInputElement>;
  values: any;
  options?: any;
  helper?: string;
  required?: boolean;
}

const PhoneNumber: FC<IPhoneNumberProps> = ({
  label,
  type,
  placeholder,
  name,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  options,
  helper,
  required,
  disabled = false,
}): ReactElement => {
  return (
    <div className="input-wrap">
      <label className={`input__label ${required ? "required" : null}`}>
        {label}
      </label>
      <MaskedInput
        mask={[
          "+",
          "1",
          " ",
          /[1-9]/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        guide={false}
        className={
          getFieldValue(touched, name) && getFieldValue(errors, name)
            ? "input input--error"
            : "input"
        }
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={getFieldValue(values, name)}
        {...options}
      />
      {getFieldValue(touched, name) && getFieldValue(errors, name) && (
        <label className="input__error">{getFieldValue(errors, name)}</label>
      )}
      {helper && <p className="input__helper">{helper}</p>}
    </div>
  );
};

export default PhoneNumber;
