import "rc-slider/assets/index.css";
import classNames from "classnames";
import { connect } from "react-redux";
import pinterpolate from "pinterpolate";
import * as queryString from "query-string";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import Notes from "./Notes";
import config from "../../../configs";
import SkillSetItem from "./SkillSetItem";
import { RootState } from "store/reducers";
import { getDateInFormat } from "utils/dates";
import Loader from "commons/components/Loader";
import { IProfile, IUser } from "commons/types/users";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { fetchUserProfile } from "store/actions/data/profile";
import { fetchStudent } from "../../../store/actions/data/students";
import {
  SurveyListItem,
  SurveyResponse,
  IResponseAggregate,
} from "commons/types/surveys";
import { fetchIndividualSurveyDetail } from "store/actions/data/surveys";
import { createResponseRevision } from "store/actions/data/responseRevision";
import {
  ICreateResponseRevision,
  ResponseRevision,
} from "commons/types/responseRevision";
import useMountedRef from "commons/hooks/useMountedRef";
import { getTenantSpecificUserString } from "tenant/tenant.utils";
import { getSurveyAggregation, getSurveyResponseById } from "services/surveys";

interface IIndividualAssessmentDetailsProps {
  student: IUser;
  profile: IProfile;
  fetchProfileLoading: boolean;
  surveyDetailLoading: boolean;
  surveyDetail: SurveyListItem;
  surveyResponses: SurveyResponse;
  location: { search: string };
  fetchUserProfile: () => Promise<void>;
  responseRevisions: ResponseRevision[];
  fetchStudent: (id: string) => Promise<IUser>;
  match: { params: { id: string; responseId: string } };
  fetchIndividualSurveyDetail: (responseId: string) => Promise<any>;
  createResponseRevision: (
    responseId: string,
    payload: ICreateResponseRevision,
  ) => Promise<any>;
}

const TABS = {
  RESPONSES: "RESPONSES",
  NOTES: "NOTES",
};

const IndividualAssessmentDetail: React.FC<IIndividualAssessmentDetailsProps> =
  ({
    match: {
      params: { id, responseId },
    },
    location: { search },
    profile,
    student,
    fetchStudent,
    surveyDetail,
    fetchUserProfile,
    fetchProfileLoading,
    surveyDetailLoading,
    createResponseRevision,
    fetchIndividualSurveyDetail,
  }) => {
    const isMounted = useMountedRef();

    const role: any = queryString.parse(search).role || "Practitioner";
    const isAggregated: any =
      Boolean(queryString.parse(search).isAggregated) || false;

    const [activeTab, setActiveTab] = useState(TABS.RESPONSES);
    const [isLoading, setIsLoading] = useState(true);
    const hasMulti: any = Boolean(queryString.parse(search).hasMulti) || false;

    React.useEffect(() => {
      fetchStudent(id);
    }, [id, fetchStudent]);

    React.useEffect(() => {
      fetchUserProfile();
    }, [fetchUserProfile]);

    React.useEffect(() => {
      fetchIndividualSurveyDetail(responseId);
    }, [responseId, fetchIndividualSurveyDetail]);

    const history = useHistory();

    useEffect(() => {
      const unListen = history.listen((location, action) => {
        if (action === "POP") {
          const studentAssessmentsPath = pinterpolate(
            config.uiPath.students.assessment,
            { id },
          );

          history.replace(studentAssessmentsPath);
        }
      });

      return unListen();
    }, [history, id]);

    const [responseData, setResponseData] = useState<SurveyResponse | never>();

    const fetchAndSetResponse = useCallback(async () => {
      setIsLoading(true);
      if (responseId && role) {
        const data = await getSurveyResponseById(
          responseId,
          isAggregated ? "" : role,
        );
        if (isMounted) setResponseData(data);
      }
      setIsLoading(false);
    }, [responseId, role, isAggregated, isMounted]);

    useEffect(() => {
      fetchAndSetResponse();
    }, [fetchAndSetResponse]);

    useEffect(() => {
      return () => {
        window.skillSetsMap = {};
        window.optionsMap = {};
        window.questionsMap = {};
      };
    }, []);

    const updateResponse = async (
      id: string,
      payload: ICreateResponseRevision,
    ) => {
      const updatedResponse = await createResponseRevision(id, payload);

      if (updatedResponse && isMounted) fetchAndSetResponse();

      return updatedResponse;
    };

    const [aggregatedValues, setAggregatedValues] = useState<
      IResponseAggregate | never
    >();

    useEffect(() => {
      const fetchAndSetAggregation = async (roleBased: string) => {
        const aggregation = await getSurveyAggregation(responseId);
        if (roleBased && isMounted) {
          const roleBasedAggregation: { [questionId: string]: any } = {};
          Object.keys(aggregation).forEach((questionId: string) => {
            roleBasedAggregation[questionId] = {};
            roleBasedAggregation[questionId][roleBased] =
              aggregation[questionId][roleBased];
          });

          setAggregatedValues(roleBasedAggregation);
        } else if (isMounted) setAggregatedValues(aggregation);
      };

      if (isAggregated || hasMulti)
        fetchAndSetAggregation(hasMulti ? role : "");
    }, [isAggregated, responseId, hasMulti, role, isMounted]);

    const generateUserName = (student: IUser) => {
      const { firstName, lastName } = student;
      return `${firstName} ${lastName} - `;
    };

    return (
      <>
        <LeftPanel student={student} activeTab="AssessmentDetails" />
        <div className="content-panel">
          {surveyDetailLoading || fetchProfileLoading ? (
            <Loader type="ThreeDots" />
          ) : (
            <>
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="mt-6x" onClick={() => history.goBack()}>
                    <box-icon name="arrow-back" />
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-md">
                      {generateUserName(student)}
                      {`${surveyDetail.term.name} - `}
                      <span className="status--warning-normal">
                        {surveyDetail.surveyType} Survey
                      </span>
                    </span>
                    <h2>
                      {isAggregated
                        ? "Student"
                        : getTenantSpecificUserString(role)}{" "}
                      {surveyDetail.title}
                      {isAggregated ? " - Aggregated" : ""}
                    </h2>
                    <ul className="heading-info ">
                      <li>
                        <box-icon name="calendar" size="xs" />
                      </li>
                      <li className="mt-2x">Created on</li>
                      <li className="mt-2x">
                        <strong>
                          {getDateInFormat(surveyDetail.createdDate)}
                        </strong>
                      </li>
                      <li className="m-1x grey-dot">
                        <box-icon
                          size="xs"
                          type="solid"
                          name="circle"
                          color="#959FAE"
                        />
                      </li>
                      <li className="mt-2x">
                        {" "}
                        {`Questions ${surveyDetail.noOfQuestion}`}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-wrapper">
                <ul className="tab">
                  <li
                    className={classNames({
                      "tab__item tab__item--active":
                        activeTab === TABS.RESPONSES,
                      tab__item: activeTab !== TABS.RESPONSES,
                    })}
                    onClick={() => isMounted && setActiveTab(TABS.RESPONSES)}
                  >
                    <span>Responses</span>
                  </li>
                  {role === "Practitioner" ? (
                    <li
                      className={classNames({
                        "tab__item tab__item--active": activeTab === TABS.NOTES,
                        tab__item: activeTab !== TABS.NOTES,
                      })}
                      onClick={() =>
                        isMounted && isMounted && setActiveTab(TABS.NOTES)
                      }
                    >
                      <span>All Notes</span>
                    </li>
                  ) : null}
                </ul>
              </div>
              {surveyDetail.noOfResponses > 0 && responseData ? (
                activeTab === TABS.NOTES ? (
                  <>
                    <div className="section-heading border-bottom mt-4x pb-4x">
                      <h3>Notes</h3>
                    </div>
                    <Notes responseData={responseData} />
                  </>
                ) : (
                  <>
                    <div className="border-bottom mb-3x mt-4x">
                      <div className="row section-heading">
                        <h3 className="col-6 txt-black-color">Responses</h3>
                        {!aggregatedValues ? (
                          <span className="col-6 text-right">
                            Responder :{" "}
                            <strong className="txt-black-color">
                              {`${responseData.submittedBy.firstName}
                          ${responseData.submittedBy.lastName}`}
                            </strong>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {isLoading ? (
                      <Loader type="ThreeDots" />
                    ) : (
                      surveyDetail?.skillsets?.map((skillset) => {
                        window.skillSetsMap[skillset.skillset._id] =
                          skillset.skillset;

                        return (
                          <SkillSetItem
                            key={skillset.skillset._id}
                            skillSet={skillset}
                            isEditable={
                              profile._id === responseData.submittedBy._id &&
                              !isAggregated
                            }
                            showRevised={() =>
                              isMounted && setActiveTab(TABS.NOTES)
                            }
                            profile={profile}
                            responseData={responseData}
                            aggregatedValues={aggregatedValues}
                            createResponseRevision={updateResponse}
                          />
                        );
                      })
                    )}
                  </>
                )
              ) : (
                <div className="accordion">
                  <div className="accordion-item">
                    <div className="card empty-card">
                      <div className="card-content">
                        <div className="tasks ml-2x">
                          <box-icon name="info-circle" type="solid" />
                        </div>
                        <div className="card-text">
                          <div className="title-left">
                            <h3 className="mb-2x">No response provided yet.</h3>
                          </div>
                          <p className="card-info">
                            Please wait for the responses.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

const mapStateToProps = (state: RootState) => ({
  profile: state.profile.fetchProfile,
  student: state.students.fetchStudent,
  surveyDetail: state.surveys.surveyDetail,
  fetchProfileLoading: state.profile.fetchProfileLoading,
  fetchStudentLoading: state.students.fetchStudentLoading,
  surveyDetailLoading: state.surveys.fetchIndividualSurveyDetailLoading,
  surveyResponses: state.surveys.surveyResponses,
});

const mapDispatchToProps = {
  fetchStudent,
  fetchUserProfile,
  createResponseRevision,
  fetchIndividualSurveyDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndividualAssessmentDetail);
