/**
 * Toggles item in array (Put if its not there, remove otherwise)
 * @param {Array} list
 * @param {any} element
 * @returns {Array} new list
 */
export const toggleInArray = (list: any[], element: any): any[] => {
  if (list.includes(element)) {
    list.splice(list.indexOf(element), 1);
  } else {
    list.push(element);
  }

  return list;
};

/**
 * Gets nested object value by field names separated by "."
 * @param objectData key value pair
 * @param keyName field key name
 * @returns string
 */
export const getFieldValue = (objectData: any, keyName: string): string => {
  if (!keyName.includes(".")) return objectData[keyName];

  const key = keyName.split(".")[0];
  const value = objectData[key] || "";

  if (typeof value === "string") return value;

  return getFieldValue(value, keyName.substring(keyName.indexOf(".") + 1));
};

/**
 * chunkArray break the array in to small chunks
 *
 * @example
 * chunkArray([1, 2, 3, 4, 5], 2)
 * => [[1,2], [3,4], [5]]
 * @param array
 * @param size
 * @returns array of chunks that is also an array
 */
export function chunkArray<T>(array: T[], size: number): T[][] {
  if (!array.length) {
    return [];
  }

  let chunkList: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    chunkList.push(chunk);
  }
  return chunkList;
}
