import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import Select, { OptionTypeBase, StylesConfig, components } from "react-select";

import { IUser } from "commons/types/users";
import { getFullName, getNameInitials } from "utils/names";
import {
  fetchAssignments,
  updateAssignment,
  deleteAssignment,
} from "store/actions/data/assignments";
import { IAssignment } from "commons/types/assignments";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { fetchPlanningPeriods } from "services/planningPeriods";
import { isDateInRange } from "utils/dates";
import { error } from "utils/toast";
import useMountedRef from "commons/hooks/useMountedRef";
import MissingPlanningPeriodsError from "commons/components/errors/missing-planning-periods-error";
import { subdomainName } from "utils/http";

const { Option } = components;

type AssignmentsProps = {
  id: string | any;
  student: IUser;
  setSelectedTerm: (selectedTerm: string) => void;
};

const DEFAULT_PLANNING_PERIOD = {
  _id: "",
  startDate: new Date(),
  endDate: new Date(),
};

const customFilterStyle: StylesConfig<OptionTypeBase, false> = {
  control: () => ({
    display: "flex",
    padding: 0,
    justifyContent: "space-between",
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  valueContainer: () => ({
    width: 120,
    position: "relative",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
  indicatorSeparator: (styles) => ({ display: "none" }),
};

const Grades: React.FC<AssignmentsProps> = ({
  id,
  student,
  setSelectedTerm,
}) => {
  const isMounted = useMountedRef();

  const [activeTerm, setActiveTerm] = React.useState("");
  const [planningPeriods, setPlanningPeriods] = useState<IPlanningPeriod[]>([]);
  const [activePlanningPeriod, setActivePlanningPeriod] =
    React.useState<IPlanningPeriod>(DEFAULT_PLANNING_PERIOD);
  const [showMissingPlanningPeriodsError, setShowMissingPlanningPeriodsError] =
    useState(false);

  const currentPlanIndex = planningPeriods.findIndex(
    (planningPeriod) =>
      planningPeriod?.startDate.toString() ===
      activePlanningPeriod?.startDate.toString(),
  );

  const activeEnrollment = student.userData?.enrollments?.filter(
    (enrollment) => enrollment.status !== "Draft",
  );

  const fetchPlans = useCallback(
    async (term) => {
      if (student?._id && term) {
        try {
          const planningPeriod = await fetchPlanningPeriods({
            user: student._id,
            term: term,
          });
          if (isMounted) {
            setPlanningPeriods(planningPeriod);
            if (!planningPeriod.length)
              setShowMissingPlanningPeriodsError(true);
            else setShowMissingPlanningPeriodsError(false);

            const activePlanningPeriod: IPlanningPeriod =
              planningPeriod.find((planningPeriod: IPlanningPeriod) =>
                isDateInRange(
                  planningPeriod.startDate,
                  planningPeriod.endDate,
                  new Date(),
                ),
              ) || DEFAULT_PLANNING_PERIOD;

            setActivePlanningPeriod(activePlanningPeriod);
          }
        } catch (err: any) {
          error(err);
        }
      }
    },
    [student._id, isMounted],
  );

  const generateTermOption = () => {
    if (activeEnrollment) {
      const activeEnrollmentWithTerms = activeEnrollment.filter(
        (enrollment) => enrollment.enrolledTerm,
      );
      return activeEnrollmentWithTerms.map((enrollment) => {
        return {
          label: enrollment.enrolledTerm.name,
          value: enrollment.enrolledTerm._id,
        };
      });
    }
    return [];
  };

  const generateOption = () => {
    if (activeEnrollment) {
      const activeEnrollmentWithTerms = activeEnrollment.filter(
        (enrollment) => enrollment.enrolledTerm,
      );
      return activeEnrollmentWithTerms.map((enrollment) => {
        return {
          label: enrollment.enrolledTerm.name,
          value: enrollment.enrolledTerm._id,
        };
      });
    }
    return [];
  };

  const getActiveTermOption = () => {
    if (activeEnrollment && activeTerm) {
      return generateOption().find((option) => option.value === activeTerm);
    }
    return { label: "Please select", value: "" };
  };

  useEffect(() => {
    if (isMounted) {
      fetchPlans(activeTerm);
    }
  }, [fetchPlans, activeTerm, isMounted]);

  useEffect(() => {
    if (student.userData?.enrollments?.length && student._id === id) {
      const currentEnrollment = student.userData?.enrollments.find(
        (enrollment) => enrollment.enrolledTerm?.isCurrent,
      );
      if (currentEnrollment?.enrolledTerm?._id) {
        setSelectedTerm(currentEnrollment.enrolledTerm?._id);
        setActiveTerm(currentEnrollment.enrolledTerm?._id);
        return;
      }
    }
  }, [student.userData?.enrollments, id, student._id]);

  const TermAndPlanningPeriod = () => {
    return (
      <div className="filters border-bottom ">
        {showMissingPlanningPeriodsError ? (
          <MissingPlanningPeriodsError student={student} />
        ) : null}

        <div className="term-select z-1000">
          <ul className="filters__list">
            <li>
              <Select
                name="term"
                onChange={(selected: any) => {
                  setActiveTerm(selected.value);
                }}
                value={getActiveTermOption()}
                isSearchable={false}
                onBlur={() => {}}
                options={generateTermOption()}
                className="filter-item link-item"
                classNamePrefix="react-select"
                styles={customFilterStyle}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
                components={{
                  Option: (props) => {
                    return (
                      <Option {...props}>
                        <span>{props.label}</span>
                      </Option>
                    );
                  },
                }}
              />
            </li>
          </ul>
          <ul className="filters__list mt-1x ml-3x">
            <li
              className="link-item mt-1x"
              onClick={() => {
                if (planningPeriods.length && currentPlanIndex > 0) {
                  setActivePlanningPeriod(
                    planningPeriods[currentPlanIndex - 1],
                  );
                }
              }}
            ></li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="page-heading">
        <div className="page-heading__left">
          <div className="avatar mt-3x">
            {student.avatar ? (
              <img src={student.avatar} alt="Student" />
            ) : (
              getNameInitials(student)
            )}
          </div>
          <div className="heading-detail">
            <span className="text-light-sm fs-14">
              Student Profile | {subdomainName} Learning
            </span>
            <h2>{getFullName(student)}</h2>

            <ul
              className={classNames("heading-info", {
                "round round--horizontal":
                  student?.userData?.schoolInfo?.grade &&
                  student?.userData?.schoolInfo?.institution?.name,
              })}
            >
              <li className="">
                {student?.userData?.schoolInfo?.grade
                  ? `Grade ${student?.userData?.schoolInfo?.grade}`
                  : null}
              </li>
              <li>
                <span className="link-item link-text">
                  {student?.userData?.schoolInfo?.institution?.name}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-heading border-bottom mb-0x">
        <h3>Grade</h3>
      </div>
      <TermAndPlanningPeriod />
    </>
  );
};

interface State {
  assignments: {
    fetchAssignments: IAssignment[];
    fetchAssignmentsLoading: boolean;
    updateAssignmentLoading: boolean;
  };
}

const mapStateToProps = (state: State) => ({
  assignments: state.assignments.fetchAssignments,
  fetchAssignmentsLoading: state.assignments.fetchAssignmentsLoading,
  updateAssignmentLoading: state.assignments.updateAssignmentLoading,
});

const mapDispatchToProps = {
  fetchAssignments,
  updateAssignment,
  deleteAssignment,
};
export default connect(mapStateToProps, mapDispatchToProps)(Grades);
