import Modal from "react-modal";
import React, { useEffect, useState } from "react";

import { error } from "../../../../utils/toast";
import { getCurrentEnrollment } from "../../../../utils/user";
import * as surveyService from "../../../../services/surveys";
import { IStudentEnrollment, IUser } from "../../../../commons/types/users";
import SendIndividualAssessment from "pages/student-profile/assessment/SendIndividualAssessment";
import AssessmentItems from "../../../student-profile/assessment/AssessmentItems";

import {
  IAssessmentItem,
  ISurveysParams,
} from "../../../../commons/types/surveys";

import {
  getIndividualAssessments,
  getAggregatedAssessments,
} from "services/assessments";

interface StudentAssessmentsProps {
  student: IUser;
}

const StudentAssessments = (props: StudentAssessmentsProps) => {
  const { student } = props;

  // @ts-ignore
  const studentId: string = student._id;

  const currentEnrollment = getCurrentEnrollment(student) || null;

  const [showSendIndividualAssessmentForm, setShowIndividualAssessmentForm] =
    useState<boolean>(false);

  const [isAssessmentsLoading, setIsAssessmentsLoading] =
    useState<boolean>(true);

  const [individualAggregatedAssessments, setIndividualAggregatedAssessments] =
    useState<Array<IAssessmentItem>>([]);

  useEffect(() => {
    if (!currentEnrollment) {
      setIsAssessmentsLoading(false);

      return;
    }

    fetchAssessments(studentId, currentEnrollment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAssessments = async (
    studentId: string,
    currentEnrollment: IStudentEnrollment,
  ) => {
    const fetchSurveysParams: ISurveysParams = {
      surveyFor: studentId,
      term: currentEnrollment.enrolledTerm._id,
    };
    setIsAssessmentsLoading(true);

    try {
      const { data: surveys } = await surveyService.fetchAllIndividualSurveys(
        fetchSurveysParams,
      );

      const { data: surveyResponses } =
        await surveyService.fetchSurveyResponsesForStudent(
          studentId,
          currentEnrollment.enrolledTerm._id,
        );

      const assessmentIndividual = getIndividualAssessments(
        surveys,
        surveyResponses,
      );

      const assessmentAggregated = getAggregatedAssessments(
        surveys,
        surveyResponses,
      );

      const individualAggregatedAssessments =
        assessmentAggregated.concat(assessmentIndividual);

      setIndividualAggregatedAssessments(individualAggregatedAssessments);
    } catch (ex) {
      error("Failed to fetch student assessments");
    } finally {
      setIsAssessmentsLoading(false);
    }
  };

  const toggleIndividualAssessmentForm = () => {
    setShowIndividualAssessmentForm((prevState) => !prevState);
  };

  return (
    <div className="accordion--with-border mb-2x">
      <div className="assessment-title pr-4x pl-4x pt-2x pb-2x">
        <h3>Assessment</h3>
        <button
          onClick={toggleIndividualAssessmentForm}
          className="btn btn--primary"
        >
          Send Survey
        </button>
      </div>
      <AssessmentItems
        student={student}
        assessments={individualAggregatedAssessments}
        isAssessmentItemsLoading={isAssessmentsLoading}
        onResendAssessmentSuccess={() =>
          currentEnrollment && fetchAssessments(studentId, currentEnrollment)
        }
      />
      <Modal
        ariaHideApp={false}
        isOpen={showSendIndividualAssessmentForm}
        className="modal-block-form"
        onRequestClose={toggleIndividualAssessmentForm}
      >
        <SendIndividualAssessment
          closeModal={toggleIndividualAssessmentForm}
          onAssessmentSentSuccess={() =>
            currentEnrollment && fetchAssessments(studentId, currentEnrollment)
          }
          student={student}
        />
      </Modal>
    </div>
  );
};

export default StudentAssessments;
