import dayjs from "dayjs";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { MESSAGE_LENGTH } from "constants/index";

const ScheduleMessageSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape(
  {
    message: Yup.string()
      .max(
        MESSAGE_LENGTH,
        `Message must be less than or equal to ${MESSAGE_LENGTH} characters.`,
      )
      .required("Please enter the message."),
    scheduledDate: Yup.date().required("Please select the scheduled date."),
    scheduledTime: Yup.string()
      .required("Time is required.")
      .when("scheduledDate", {
        is: (value: any) => dayjs().isSame(value, "day"),
        then: Yup.string().test(
          "is-valid",
          "Valid future time is required.",
          (value: any) => {
            if (!value) {
              return false;
            }
            const [hour, minute] = value.split(":");
            if (!hour || !minute) {
              return false;
            }
            return !dayjs()
              .hour(hour)
              .minute(minute)
              .isBefore(dayjs(), "minute");
          },
        ),
      }),
    notes: Yup.string(),
  },
);
export default ScheduleMessageSchema;
