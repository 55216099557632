import { connect } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import AssessmentItems from "./AssessmentItems";
import { RootState } from "../../../store/reducers";
import {
  IAssessmentItem,
  SurveyListItem,
  SurveyResponse,
} from "../../../commons/types/surveys";
import { useEffect, useState } from "react";
import { IUser } from "../../../commons/types/users";
import { getIndividualAssessments } from "services/assessments";

interface IndividualAssessmentProps {
  student: IUser;
  individualAssessments: Array<SurveyListItem>;
  fetchIndividualAssessmentLoading: boolean;
  surveyResponses: SurveyResponse[];
  onResendAssessmentSuccess: () => void;
}

const IndividualAssessments = (props: IndividualAssessmentProps) => {
  const {
    student,
    individualAssessments,
    fetchIndividualAssessmentLoading,
    surveyResponses,
  } = props;

  const [assessments, setAssessments] = useState<IAssessmentItem[]>([]);

  useEffect(() => {
    const assessmentIndividual = getIndividualAssessments(
      individualAssessments,
      surveyResponses,
    );
    setAssessments(assessmentIndividual);
  }, [individualAssessments, surveyResponses]);

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="accordion"
      preExpanded={["personal-info-individual-assessment"]}
    >
      <AccordionItem
        uuid={"personal-info-individual-assessment"}
        className="accordion-item"
      >
        <AccordionItemState>
          {({ expanded }) => (
            <>
              <AccordionItemHeading className="p-3x">
                <AccordionItemButton className="accordion-item__title pl-0x">
                  <div className="title-left">
                    <div className="section__title">
                      <h3 className="txt-darkgrey-color">Individual</h3>
                    </div>
                  </div>
                  <div className="title-right">
                    <span>
                      <box-icon
                        name={!expanded ? "chevron-down" : "chevron-up"}
                      />
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-item__content">
                <AssessmentItems
                  student={student}
                  assessments={assessments}
                  isAssessmentItemsLoading={fetchIndividualAssessmentLoading}
                  onResendAssessmentSuccess={props.onResendAssessmentSuccess}
                />
              </AccordionItemPanel>
            </>
          )}
        </AccordionItemState>
      </AccordionItem>
    </Accordion>
  );
};

const mapStateToProps = (state: RootState) => ({
  fetchIndividualAssessmentLoading: state.surveys.fetchIndividualSurveyLoading,
  individualAssessments: state.surveys.individualSurveys,
  surveyResponses: state.surveys.surveyResponses,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndividualAssessments);
