import "boxicons";
import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import Goals from "./Goals";
import AddGoal from "../add";
import GoalDetail from "pages/goals/detail";
import { IUser } from "commons/types/users";
import ProtectedRoute from "protected-route";
import { getCurrentEnrollment } from "utils/user";
import { fetchStudent } from "store/actions/data/students";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import TermNotEnrolledError from "pages/layouts/mentor/TermNotEnrolledError";
import Loader from "commons/components/Loader";

type MentorGoalsProps = {
  student: IUser;
  match: { params: { id: string } };
  fetchStudentLoading: boolean;
  fetchStudent: (id: string) => Promise<IUser>;
};

const MentorGoals: React.FC<MentorGoalsProps> = ({
  match: {
    params: { id },
  },
  student,
  fetchStudentLoading,
  fetchStudent,
}): JSX.Element => {
  const { path } = useRouteMatch();

  const fetchStudentInfo = useCallback(() => {
    fetchStudent(id);
  }, [fetchStudent, id]);

  useEffect(() => {
    fetchStudentInfo();
  }, [fetchStudentInfo]);

  const [isAddGoalModalOpen, setIsAddGoalModalOpen] = useState(false);

  const openAddGoalModal = () => {
    setIsAddGoalModalOpen(true);
  };

  const closeAddGoalModal = () => {
    setIsAddGoalModalOpen(false);
  };

  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);

  return (
    <>
      <LeftPanel activeTab="Goals" student={student} />
      <div className="content-panel">
        {isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : null}
        <Switch>
          <Route exact path={`${path}`}>
            <Goals
              id={id}
              student={student}
              openAddGoalModal={openAddGoalModal}
            />
          </Route>
          <ProtectedRoute
            exact
            path={`${path}/:goalId`}
            component={GoalDetail}
          />
        </Switch>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddGoalModalOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        ariaHideApp={false}
      >
        <AddGoal closeModal={closeAddGoalModal} student={student} />
      </Modal>
    </>
  );
};

interface State {
  students: { fetchStudent: IUser; fetchStudentLoading: boolean };
}

const mapStateToProps = (state: State) => ({
  student: state.students.fetchStudent,
  fetchStudentLoading: state.students.fetchStudentLoading,
});

const mapDispatchToProps = {
  fetchStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentorGoals);
