import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

const SchoolDistrictSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(
      TITLE_LENGTH,
      `School District must be less tha ${TITLE_LENGTH} characters`,
    )
    .required("Please enter school district"),
});

export default SchoolDistrictSchema;
