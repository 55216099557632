import "boxicons";
import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";

const SurveyNotes = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="link-item user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                {/* <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Dashboard
                  </span>
                </li> */}
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="group"></box-icon>
                    Students
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="mt-6x">
                    <box-icon name="arrow-back"></box-icon>
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-sm">
                      Assessment - Summer 2021
                    </span>
                    <h2>Student Assessment Survey- Aggregated</h2>
                    <ul className="heading-info">
                      <li>
                        <box-icon name="graduation" type="solid"></box-icon>
                        Created on April 30, 2021
                      </li>
                      <li>Questions 21</li>
                    </ul>
                  </div>
                </div>
                <div className="page-heading__right  align-items-center">
                  <button className="btn btn--primary mr-2x" type="button">
                    Add Task
                  </button>
                  <box-icon name="dots-horizontal-rounded"></box-icon>
                </div>
              </div>
              <div className="tab-wrapper">
                <ul className="tab">
                  <li className="tab__item">
                    <span>Responses</span>
                  </li>
                  <li className="tab__item tab__item--active">
                    <span>All Notes</span>
                  </li>
                </ul>
              </div>
              <div className="section-heading">
                <h3>Notes</h3>
              </div>
              <div className="content-list">
                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-item__title p-0x">
                      <div className="title-left">
                        <h3>Organization</h3>
                      </div>
                      <div className="title-right">
                        <div className="actions d-flex align-items-center">
                          <span className="link-item">
                            <box-icon name="chevron-up"></box-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item__content">
                      <div className="card align-items-center card-notes">
                        <div className="card-content">
                          <div className="card-text">
                            <div className="card-info">
                              <h4>Physical organization</h4>
                              <span className="d-block text-lightgray">
                                Updated Skill Score: 4 (Sometimes)
                              </span>
                            </div>
                            <div className="card-notes__details">
                              <ul>
                                <li>
                                  <div className="card-notes__icon mr-2x">
                                    <box-icon name="calendar-check"></box-icon>
                                  </div>
                                  <div className="card-notes__content">
                                    <span className="text-lightgray">
                                      Mon, May 13, 2021, Previous skill score: 3
                                    </span>
                                    <span className="txt-black-color">
                                      {" "}
                                      This is the reason why I have changed
                                      skill score value for Physical
                                      organization.
                                    </span>
                                  </div>
                                  <div className="ml-2x">
                                    <label className="toggle">
                                      <span className="toggle-label">
                                        Included in Report
                                      </span>
                                      <input
                                        className="toggle-checkbox"
                                        type="checkbox"
                                      />
                                      <div className="toggle-switch"></div>
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="card-notes__icon mr-2x">
                                    <box-icon name="calendar-check"></box-icon>
                                  </div>
                                  <div className="card-notes__content">
                                    <span className="text-lightgray">
                                      Mon, May 13, 2021, Previous skill score: 3
                                    </span>
                                    <span className="txt-black-color">
                                      This is the reason why I have changed
                                      skill score value for Physical
                                      organization.
                                    </span>
                                  </div>
                                  <div className="ml-2x">
                                    <label className="toggle">
                                      <span className="toggle-label">
                                        Included in Report
                                      </span>
                                      <input
                                        className="toggle-checkbox"
                                        type="checkbox"
                                      />
                                      <div className="toggle-switch"></div>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card align-items-center card-notes">
                        <div className="card-content">
                          <div className="card-text">
                            <div className="card-info">
                              <h4>Workspace</h4>
                              <span className="d-block text-lightgray">
                                Updated Skill Score: 4 (Sometimes)
                              </span>
                            </div>
                            <div className="card-notes__details">
                              <div className="card-notes__icon mr-2x">
                                <box-icon name="calendar-check"></box-icon>
                              </div>
                              <div className="card-notes__content">
                                <span className="text-lightgray">
                                  Mon, May 13, 2021, Previous skill score: 3
                                </span>
                                <span className="txt-black-color">
                                  This is the reason why I have changed skill
                                  score value for Physical organization.
                                </span>
                              </div>
                              <div className="ml-2x">
                                <label className="toggle">
                                  <span className="toggle-label">
                                    Included in Report
                                  </span>
                                  <input
                                    className="toggle-checkbox"
                                    type="checkbox"
                                  />
                                  <div className="toggle-switch"></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card align-items-center card-notes">
                        <div className="card-content">
                          <div className="card-text">
                            <div className="card-info">
                              <h4>Personal space</h4>
                              <span className="d-block text-lightgray">
                                Updated Skill Score: 4 (Sometimes)
                              </span>
                            </div>
                            <div className="card-notes__details">
                              <div className="card-notes__icon mr-2x">
                                <box-icon name="calendar-check"></box-icon>
                              </div>
                              <div className="card-notes__content">
                                <span className="text-lightgray">
                                  Mon, May 13, 2021, Previous skill score: 3
                                </span>
                                <span>
                                  This is the reason why I have changed skill
                                  score value for Physical organization.
                                </span>
                              </div>
                              <div className="ml-2x">
                                <label className="toggle">
                                  <span className="toggle-label">
                                    Included in Report
                                  </span>
                                  <input
                                    className="toggle-checkbox"
                                    type="checkbox"
                                  />
                                  <div className="toggle-switch"></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <h3>Messages</h3>
                  <div className="message-actions">
                    <span className="action-links active">
                      <span>Available</span>
                      <box-icon name="alarm-snooze"></box-icon>
                    </span>
                    <span className="action-links default">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content">
                  <div className="message-list unread">
                    <div className="avatar-wrap active">
                      <div className="avatar mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Samantha Doe <span>(Grade 7)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">Just now</span>
                        <span className="msg-preview text-ellipsis">
                          Hi! researched but I dont know the answer.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">BP</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Brendan Pollock <span>(Homework Center)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Yes thanks! I will ask the student.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">LD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Lori Denver <span>(Grade 9)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">Okay</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">JD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        John Doe <span>(Samantha's Parent)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Sure. I will ask Sam about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyNotes;
