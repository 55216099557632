import { Dispatch } from "redux";

import { getProfile } from "services/users";
import { FetchProfileActionType } from "../types/profile";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "services/localStorage";
import { error } from "utils/toast";

export const fetchUserProfile = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchProfileActionType.FETCH_PROFILE_PENDING,
  });
  try {
    const currentUser = getDataFromLocalStorage("profile");
    const profile = await getProfile(currentUser?.authToken);

    setDataToLocalStorage("profile", {
      ...currentUser,
      ...profile.data,
    });
    dispatch({
      type: FetchProfileActionType.FETCH_PROFILE_FULFILLED,
      data: profile.data,
    });
  } catch (err: any) {
    error("Failed to load user profile");

    dispatch({
      type: FetchProfileActionType.FETCH_PROFILE_REJECTED,
      data: err,
    });
  }
};
