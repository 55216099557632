import { Formik } from "formik";
import { FormEvent, useEffect, useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import config from "configs";
import { error } from "utils/toast";
import { IMentor } from "commons/types";
import { Status } from "constants/status";
import { fetchMentors } from "services/mentors";
import { IEnrollment, IUser } from "commons/types/users";
import { addMentorSchema } from "commons/schemas/student";
import { TextAreaInput } from "commons/components/form-fields";
import Loader from "commons/components/Loader";

interface LabelValue {
  label: string;
  value: string;
}

interface Props {
  initialValues: any;
  closeDialog: () => void;
  saveData: (data: IEnrollment) => Promise<IUser>;
}

interface FormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, value: any) => void;
  values: IEnrollment;
  touched: IEnrollment;
  errors: IEnrollment;
  initialValues: any;
  isSubmitting: boolean;
}

const AddMentor: React.FC<Props> = ({
  saveData,
  closeDialog,
  initialValues,
}) => {
  const history = useHistory();

  const [mentorsOption, setMentorsOptions] = useState<LabelValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAndSetMentors = async () => {
      setIsLoading(true);
      const mentors = await fetchMentors();
      const mentorsOption: LabelValue[] = mentors
        .filter((record: IUser) => record.userData?.status === Status.ACTIVE)
        .map((record: IMentor) => ({
          label: `${record.firstName} ${record.lastName}`,
          value: record._id,
        }));

      setMentorsOptions(mentorsOption);
      setIsLoading(false);
    };

    fetchAndSetMentors();
  }, []);

  const onSubmitHandler = async (data: IEnrollment) => {
    try {
      const savedData = await saveData(data);

      if (savedData) history.push(config.uiPath.students.list);
    } catch (err: any) {
      error("Failed to save mentor");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
      validationSchema={addMentorSchema}
    >
      {(props: FormikProps) => {
        return isLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <form onSubmit={props.handleSubmit}>
            <div className="modal-wrap__body pb-0x">
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label required">
                        Potential Primary Mentor
                      </label>
                      <Select
                        className={
                          props.touched.userId && props.errors.userId
                            ? "input--error"
                            : ""
                        }
                        placeholder="Please select potential primary mentor"
                        name="userId"
                        onChange={(selected: any) => {
                          props.setFieldValue("userId", selected.value);
                          props.setFieldValue("status", "Draft");
                        }}
                        value={mentorsOption.filter(
                          (option) => option.value === props.values.userId,
                        )}
                        onBlur={props.handleBlur}
                        classNamePrefix="react-select"
                        options={mentorsOption}
                      />
                      <label className="input__error">
                        {props.touched.userId && props.errors.userId}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TextAreaInput
                      label="Additional Notes for the Mentor"
                      placeholder="Please write here if you have any specific notes for the mentor(s)"
                      name="mentorshipNote"
                      values={props.values.mentorshipNote}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      touched={props.touched}
                      errors={props.errors}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-wrap__footer pt-0x">
              <div className="row">
                <div className="col-12 d-flex">
                  <button
                    disabled={props.isSubmitting}
                    className="btn btn--primary mr-4x"
                    type="submit"
                  >
                    Save Information
                  </button>
                  <span
                    className="btn btn-text txt-primary-color"
                    onClick={closeDialog}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddMentor;
