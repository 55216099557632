import * as Yup from "yup";

import { ObjectShape } from "yup/lib/object";
import { TITLE_LENGTH } from "constants/index";

const AssignmentSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(TITLE_LENGTH, `Title must be less than ${TITLE_LENGTH} characters`)
    .required("Please enter the title"),
  submissionDate: Yup.date().required("Please select due date"),
  course: Yup.string().required("Please select a course"),
});

export default AssignmentSchema;
