import {
  AddAcademicYearActionType,
  FetchAcademicYearsActionType,
  UpdateAcademicYearActionType,
  DeleteAcademicYearActionType,
} from "store/actions/types/academicYear";

const INITIAL_STATE = {
  addAcademicYear: {},
  addAcademicYearLoading: false,
  addAcademicYearError: null,
  fetchAcademicYears: [],
  fetchAcademicYearsLoading: false,
  fetchAcademicYearsError: null,
  updateAcademicYear: {},
  updateAcademicYearLoading: false,
  updateAcademicYearError: null,
  deleteAcademicYear: {},
  deleteAcademicYearLoading: false,
  deleteAcademicYearError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddAcademicYearActionType.ADD_ACADEMIC_YEAR_PENDING:
      return { ...state, addAssignmentLoading: true, addAssignmentError: null };

    case AddAcademicYearActionType.ADD_ACADEMIC_YEAR_FULFILLED:
      return {
        ...state,
        addAcademicYear: action.data,
        addAcademicYearLoading: false,
        addAcademicYearError: null,
      };
    case AddAcademicYearActionType.ADD_ACADEMIC_YEAR_REJECTED:
      return {
        ...state,
        addAssignmentLoading: false,
        addAssignmentError: action.data,
      };
    case FetchAcademicYearsActionType.FETCH_ACADEMIC_YEAR_PENDING:
      return {
        ...state,
        fetchAcademicYearsLoading: true,
        fetchAcademicYearsError: null,
      };
    case FetchAcademicYearsActionType.FETCH_ACADEMIC_YEAR_FULFILLED:
      return {
        ...state,
        fetchAcademicYearsLoading: false,
        fetchAcademicYearsError: null,
        fetchAcademicYears: action.data,
      };

    case FetchAcademicYearsActionType.FETCH_ACADEMIC_YEAR_REJECTED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        fetchAssignmentsError: action.data,
      };
    case UpdateAcademicYearActionType.UPDATE_ACADEMIC_YEAR_PENDING:
      return {
        ...state,
        updateAcademicYearLoading: true,
        updateAcademicYearError: null,
      };
    case UpdateAcademicYearActionType.UPDATE_ACADEMIC_YEAR_FULFILLED:
      return {
        ...state,
        updateAcademicYearLoading: false,
        updateAcademicYear: action.data,
        updateAcademicYearError: null,
      };
    case UpdateAcademicYearActionType.UPDATE_ACADEMIC_YEAR_REJECTED:
      return {
        ...state,
        updateAcademicYearLoading: false,
        updateAcademicYearError: action.data,
      };
    case DeleteAcademicYearActionType.DELETE_ACADEMIC_YEAR_PENDING:
      return {
        ...state,
        deleteAcademicYearLoading: true,
        deleteAcademicYearError: null,
      };
    case DeleteAcademicYearActionType.DELETE_ACADEMIC_YEAR_FULFILLED:
      return {
        ...state,
        deleteAcademicYear: action.data,
        deleteAcademicYearLoading: false,
        deleteAcademicYearError: null,
      };
    case DeleteAcademicYearActionType.DELETE_ACADEMIC_YEAR_REJECTED:
      return {
        ...state,
        deleteAcademicYearLoading: false,
        deleteAcademicYearError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
