export const setDataToLocalStorage = async (key: string, data: object) => {
  localStorage.setItem(key, JSON.stringify(data));
  return data;
};

export const getDataFromLocalStorage: any = (key: string) => {
  const data: any = localStorage.getItem(key);
  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (err: any) {
    parsedData = data;
  }
  return parsedData;
};

export const clearLocalDataStorage = () => localStorage.clear();
