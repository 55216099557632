import Modal from "react-modal";
import { useState } from "react";
import { connect } from "react-redux";

import { MultiStep } from "@loft/multistep-form";

import SingleForm from "./SingleForm";
import Loader from "commons/components/Loader";
import Confirm from "commons/components/Confirm";
import { setSurveyRespondResult } from "store/actions/data/surveys";
import {
  SurveyListItem,
  SurveySkillSet,
  SurveyQuestions,
  ISurveyRespondResult,
} from "commons/types/surveys";
import { error, success } from "utils/toast";
import { sendSurveyResponse } from "services/surveys";
import { getTenantSpecificUserString } from "tenant/tenant.utils";
import { TenantRole } from "tenant/tenant.types";

const MultiStepForm = ({
  role,
  data,
  responderId,
  setIsSucceed,
  surveyRespondResult,
  setSurveyRespondResult,
}: {
  role: string;
  responderId: string;
  data: SurveyListItem;
  setIsSucceed: (isSucceed: boolean) => void;
  surveyRespondResult: ISurveyRespondResult[];
  setSurveyRespondResult: (newResult: ISurveyRespondResult[]) => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showClearInfo, setShowClearInfo] = useState(false);

  const steps = [
    ...data.skillsets?.map((questionGroup: SurveySkillSet, index: number) => {
      return {
        id: (index + 1).toString(),
        Component: () => (
          <SingleForm
            role={role}
            questionGroup={questionGroup}
            id={questionGroup?.skillset?._id}
          />
        ),
      };
    }),
  ];

  const clearCurrentForm = (page: number) => {
    const currentPageQuestions = data.skillsets[page - 1]?.questions.map(
      (questionData: { question: { _id: string } }) =>
        questionData.question._id,
    );
    setSurveyRespondResult(
      surveyRespondResult.filter(
        (result) => !currentPageQuestions.includes(result.question),
      ),
    );
    setShowClearInfo(false);
  };

  const handleSubmit = async () => {
    if (data?._id)
      try {
        setIsSubmitting(true);
        let results: {
          skillset: string;
          responses: Array<ISurveyRespondResult>;
        }[] = [];
        surveyRespondResult.map((result: ISurveyRespondResult) => {
          let skillset = "";
          data.skillsets.map((skillsetData: SurveySkillSet) => {
            if (
              skillsetData.questions.find(
                (questionData: SurveyQuestions) =>
                  questionData.question._id === result.question,
              )
            ) {
              skillset = skillsetData.skillset._id;
            }

            return skillset;
          });

          const existingRecord = results.find(
            (result) => result.skillset === skillset,
          );
          results = existingRecord
            ? results.map((currentResult) =>
                skillset === currentResult.skillset
                  ? {
                      skillset: currentResult.skillset,
                      responses: [...currentResult.responses, result],
                    }
                  : currentResult,
              )
            : [
                ...results,
                {
                  skillset: skillset,
                  responses: [result],
                },
              ];

          return results;
        });

        await sendSurveyResponse({
          survey: data._id,
          result: results,
          submittedBy: responderId,
          submittedDate: new Date().toISOString(),
        });

        success("Survey response saved");
        setIsSucceed(true);
      } catch (ex) {
        error("Failed to save survey response");
      }

    setIsSubmitting(false);
  };

  const getMultiStepForm = (steps: any) => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <MultiStep steps={[...steps]}>
          {({
            next,
            jumpTo,
            previous,
            progress: { current, first, last },
            step: { id, Component, nextStepId },
          }) => {
            let currentFormFilled = false;

            if (data.skillsets.length && current) {
              const answeredQuestions = surveyRespondResult.map(
                (result: ISurveyRespondResult) => result.question,
              );
              currentFormFilled = data.skillsets[current - 1]?.questions?.every(
                (questionGroup: SurveyQuestions) =>
                  answeredQuestions.includes(questionGroup.question._id),
              );
            }

            return (
              <div id={id} className="content-wrap">
                <div className="progress-bar">
                  <div className="container">
                    <ul className="progress-bar__steps">
                      {data.skillsets.map(
                        (questionGroup: SurveySkillSet, index: number) => {
                          return (
                            <li
                              key={index}
                              className={
                                current === index + 1
                                  ? "active link-item"
                                  : current > index + 1
                                  ? "done link-item"
                                  : ""
                              }
                              onClick={() => {
                                if (current > index + 1)
                                  jumpTo((index + 1).toString());
                              }}
                            >
                              <span className="progress-bar__icon">
                                {index + 1}
                              </span>
                              {questionGroup.skillset.name}
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                </div>
                <div className="container">
                  <div className="content-7x">
                    <div className="content-panel">
                      <div className="page-heading mt-4x">
                        <div className="page-heading__left">
                          <h2>{`${getTenantSpecificUserString(
                            role as TenantRole,
                          )} ${data.title}`}</h2>
                        </div>
                      </div>

                      {first === current && (
                        <section className="section">
                          <div className="section__title">
                            <h3>Student Information</h3>
                          </div>
                          <div className="section__content">
                            <div className="row">
                              <div className="col-6">
                                <div className="input-wrap">
                                  <label className="input__label">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className="input"
                                    value={data?.createdFor?.firstName}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="input-wrap">
                                  <label className="input__label">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    value={data?.createdFor?.lastName}
                                    disabled
                                    className="input"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}

                      <Component />

                      <section className="section">
                        <div className="section__content">
                          <div className="row">
                            <div className="col-12 d-flex">
                              {current !== first && (
                                <button
                                  type="button"
                                  className="btn btn--outlined-primary mr-4x"
                                  onClick={previous}
                                >
                                  Back
                                </button>
                              )}
                              {nextStepId && (
                                <button
                                  type="button"
                                  className="btn btn--primary mr-4x"
                                  onClick={next}
                                  disabled={!currentFormFilled}
                                >
                                  Next Step
                                </button>
                              )}
                              {last === current && (
                                <button
                                  className="btn btn--primary mr-4x"
                                  type="submit"
                                  disabled={!currentFormFilled || isSubmitting}
                                >
                                  Submit
                                </button>
                              )}
                              <button
                                onClick={() => {
                                  setShowClearInfo(true);
                                }}
                                className="btn link-item txt-primary-color"
                                type="button"
                              >
                                Clear Form
                              </button>
                              <Modal
                                className="modal-block"
                                isOpen={showClearInfo}
                                ariaHideApp={false}
                              >
                                <Confirm
                                  message={{
                                    header: "Clear form?",
                                    body: "This will remove your answers from all questions, and cannot be undone.",
                                  }}
                                  okText="Clear form"
                                  handleOk={() => {
                                    clearCurrentForm(current);
                                  }}
                                  closeModal={() => setShowClearInfo(false)}
                                />
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </MultiStep>
      </form>
    );
  };

  return !steps.length ? <Loader type="ThreeDots" /> : getMultiStepForm(steps);
};

const mapStateToProps = (state: {
  surveys: { surveyRespondResult: ISurveyRespondResult[] };
}) => ({
  surveyRespondResult: state.surveys.surveyRespondResult,
});

const mapDispatchToProps = { setSurveyRespondResult };

export default connect(mapStateToProps, mapDispatchToProps)(MultiStepForm);
