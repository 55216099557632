interface IConfirmProps {
  handleOk?: () => void;
  closeModal: () => void;
  okText?: string;
  isInfo?: boolean;
  cancelText?: string;
  disabled?: boolean;
  message: {
    header: string;
    body: string;
  };
}

const Confirm: React.FC<IConfirmProps> = ({
  okText,
  message,
  handleOk,
  closeModal,
  cancelText,
  isInfo = false,
  disabled = false,
}) => {
  return (
    <div
      className={`modal-wrap modal-wrap--sm confirm ${
        isInfo ? "confirm--success" : "confirm--error"
      }`}
    >
      <div className="modal-wrap__body">
        <box-icon
          name={isInfo ? "check-circle" : "error-circle"}
          type="solid"
        />
        <h3>{message.header}</h3>
        <p>{message.body}</p>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            {handleOk && (
              <button
                className="btn btn--primary mr-4x"
                type="button"
                onClick={handleOk}
                disabled={disabled}
              >
                {okText ? okText : "Okay"}
              </button>
            )}
            <button
              type="button"
              className="btn btn-text txt-primary-color"
              onClick={closeModal}
            >
              {cancelText ? cancelText : "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
