import * as Yup from "yup";

import { MAX_NOTES_LIMIT } from "constants/index";

export const NoteSchema = Yup.object().shape({
  notes: Yup.string()
    .trim()
    .required("Notes is required")
    .max(
      MAX_NOTES_LIMIT,
      `Notes must be less than ${MAX_NOTES_LIMIT} characters`,
    ),
});
