import { SurveyListItem } from "commons/types/surveys";

export enum SendSurveysActionType {
  SEND_SURVEY_PENDING = "SEND_SURVEY_PENDING",
  SEND_SURVEY_SUCCESSFULL = "SEND_SURVEY_SUCCESSFULL",
  SEND_SURVEY_FAILED = "SEND_SURVEY_FAILED",
}

export enum FetchSurveyCategoriesType {
  GET_SURVEYS_PENDING = "GET_SURVEYS_PENDING",
  GET_SURVEYS_SUCCESSFULL = "GET_SURVEYS_SUCCESSFULL",
  GET_SURVEYS_FAILED = "GET_SURVEYS_FAILED",
}

export enum SetSurveyRespondResultActionType {
  SET_SURVEY_RESPOND_RESULT_PENDING = "SET_SURVEY_RESPOND_RESULT_PENDING",
  SET_SURVEY_RESPOND_RESULT_SUCCESSFUL = "SET_SURVEY_RESPOND_RESULT_SUCCESSFUL",
  SET_SURVEY_RESPOND_RESULT_FAILED = "SET_SURVEY_RESPOND_RESULT_FAILED",
}

export enum FetchSurveyResponsesActionType {
  FETCH_SURVEY_RESPONSE_PENDING = "FETCH_SURVEY_RESPONSE_PENDING",
  FETCH_SURVEY_RESPONSE_FULFILLED = "FETCH_SURVEY_RESPONSE_FULFILLED",
  FETCH_SURVEY_RESPONSE_REJECTED = "FETCH_SURVEY_RESPONSE_REJECTED",
}

export enum FetchEarlySurveyResponsesActionType {
  FETCH_EARLY_SURVEY_RESPONSE_PENDING = "FETCH_EARLY_SURVEY_RESPONSE_PENDING",
  FETCH_EARLY_SURVEY_RESPONSE_FULFILLED = "FETCH_EARLY_SURVEY_RESPONSE_FULFILLED",
  FETCH_EARLY_SURVEY_RESPONSE_REJECTED = "FETCH_EARLY_SURVEY_RESPONSE_REJECTED",
}

export enum FetchIndividualSurveysActionType {
  FETCH_INDIVIDUAL_SURVEYS_PENDING = "FETCH_INDIVIDUAL_SURVEYS_PENDING",
  FETCH_INDIVIDUAL_SURVEYS_REJECTED = "FETCH_INDIVIDUAL_SURVEYS_REJECTED",
  FETCH_INDIVIDUAL_SURVEYS_FULFILLED = "FETCH_INDIVIDUAL_SURVEYS_FULFILLED",
}

export interface FetchIndividualSurveysAction {
  type: FetchIndividualSurveysActionType;
  data: Array<SurveyListItem>;
}

export enum FetchIndividualSurveyDetailActionType {
  FETCH_INDIVIDUAL_SURVEY_DETAIL_PENDING = "FETCH_INDIVIDUAL_SURVEY_DETAIL_PENDING",
  FETCH_INDIVIDUAL_SURVEY_DETAIL_REJECTED = "FETCH_INDIVIDUAL_SURVEY_DETAIL_REJECTED",
  FETCH_INDIVIDUAL_SURVEY_DETAIL_FULFILLED = "FETCH_INDIVIDUAL_SURVEY_DETAIL_FULFILLED",
}

export interface FetchIndividualSurveyDetailAction {
  type: FetchIndividualSurveyDetailActionType;
  data: SurveyListItem;
}
