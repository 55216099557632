import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

export const externalResourceSchema: Yup.ObjectSchema<ObjectShape> =
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(
        TITLE_LENGTH,
        `Portal name must be less than ${TITLE_LENGTH} characters`,
      )
      .required("Please enter the portal name"),
    url: Yup.string().url().required("Please enter the url"),
    userName: Yup.string()
      .trim()
      .max(
        TITLE_LENGTH,
        `Username must be less than ${TITLE_LENGTH} characters`,
      )
      .required("Please enter the username"),
    password: Yup.string().trim().required("Please enter the password"),
  });
