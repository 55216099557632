import { ISatelliteLocation } from "commons/types/satelliteLocations";

export enum AddSatelliteLocationActionType {
  ADD_SATELLITE_LOCATION_PENDING = "ADD_SATELLITE_LOCATION_PENDING",
  ADD_SATELLITE_LOCATION_REJECTED = "ADD_SATELLITE_LOCATION_REJECTED",
  ADD_SATELLITE_LOCATION_FULFILLED = "ADD_SATELLITE_LOCATION_FULFILLED",
}

export interface AddSatelliteLocationAction {
  type: AddSatelliteLocationActionType;
  data: ISatelliteLocation;
}

export enum FetchSatelliteLocationsActionType {
  FETCH_SATELLITE_LOCATIONS_PENDING = "FETCH_SATELLITE_LOCATIONS_PENDING",
  FETCH_SATELLITE_LOCATIONS_REJECTED = "FETCH_SATELLITE_LOCATIONS_REJECTED",
  FETCH_SATELLITE_LOCATIONS_FULFILLED = "FETCH_SATELLITE_LOCATIONS_FULFILLED",
}
export interface FetchSatelliteLocationsAction {
  type: FetchSatelliteLocationsActionType;
  data: ISatelliteLocation[];
}

export enum FetchSatelliteLocationActionType {
  FETCH_SATELLITE_LOCATION_PENDING = "FETCH_SATELLITE_LOCATION_PENDING",
  FETCH_SATELLITE_LOCATION_REJECTED = "FETCH_SATELLITE_LOCATION_REJECTED",
  FETCH_SATELLITE_LOCATION_FULFILLED = "FETCH_SATELLITE_LOCATION_FULFILLED",
}
export interface FetchSatelliteLocationAction {
  type: FetchSatelliteLocationActionType;
  data: ISatelliteLocation;
}

export enum UpdateSatelliteLocationActionType {
  UPDATE_SATELLITE_LOCATION_PENDING = "UPDATE_SATELLITE_LOCATION_PENDING",
  UPDATE_SATELLITE_LOCATION_REJECTED = "UPDATE_SATELLITE_LOCATION_REJECTED",
  UPDATE_SATELLITE_LOCATION_FULFILLED = "UPDATE_SATELLITE_LOCATION_FULFILLED",
}
export interface UpdateSatelliteLocationAction {
  type: UpdateSatelliteLocationActionType;
  data: ISatelliteLocation;
}

export enum DeleteSatelliteLocationActionType {
  DELETE_SATELLITE_LOCATION_PENDING = "DELETE_SATELLITE_LOCATION_PENDING",
  DELETE_SATELLITE_LOCATION_FULFILLED = "DELETE_SATELLITE_LOCATION_FULFILLED",
  DELETE_SATELLITE_LOCATION_REJECTED = "DELETE_SATELLITE_LOCATION_REJECTED",
}

export interface DeleteSatelliteLocationAction {
  type: DeleteSatelliteLocationActionType;
  data: ISatelliteLocation;
}
