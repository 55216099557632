import { combineReducers } from "redux";

import chat from "./data/chat";
import grades from "./data/grades";
import terms from "./data/terms";
import goals from "./data/goals";
import tasks from "./data/tasks";
import notes from "./data/notes";
import twilio from "./data/twilio";
import schools from "./data/schools";
import profile from "./data/profile";
import courses from "./data/courses";
import mentors from "./data/mentors";
import centers from "./data/centers";
import surveys from "./data/surveys";
import sponsors from "./data/sponsors";
import students from "./data/students";
import multitenancy from "./data/multitenancy";
import gradeTypes from "./data/gradeTypes";
import assignments from "./data/assignments";
import institutions from "./data/institutions";
import availability from "./data/availability";
import communication from "./data/communication";
import academicYears from "./data/academicYears";
import accommodations from "./data/accommodations";
import planningPeriods from "./data/planningPeriods";
import schoolDistricts from "./data/schoolDistricts";
import responseRevision from "./data/responseRevision";
import satelliteLocations from "./data/satelliteLocation";

export const rootReducer = combineReducers({
  terms,
  goals,
  tasks,
  notes,
  twilio,
  mentors,
  sponsors,
  students,
  assignments,
  institutions,
  courses,
  planningPeriods,
  chat,
  communication,
  profile,
  gradeTypes,
  availability,
  schoolDistricts,
  accommodations,
  academicYears,
  schools,
  centers,
  satelliteLocations,
  surveys,
  responseRevision,
  grades,
  multitenancy,
});

export type RootState = ReturnType<typeof rootReducer>;
