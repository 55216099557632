import { FC, ReactElement } from "react";
import ProtectedRoute from "protected-route";
import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

import TopNavbar from "./TopNavbar";
import MentorGoals from "pages/goals/list";
import Plans from "pages/plans/list/Plans";
import MentorProfile from "pages/mentor-profile";
import MentorAssignments from "pages/assignments/list";
import ListMentorStudents from "pages/students/list/mentor";
import MessageList from "pages/mentor-dashboard/MessageList";
import StudentAcademicInfo from "pages/student-profile/academic-info";
import StudentPersonalInfo from "pages/student-profile/personal-info";
import StudentAssessmentInfo from "../../../pages/student-profile/assessment";
import IncomingCallModal from "pages/mentor-dashboard/incoming-call/IncomingCallModal";
import IndividualAssessmentDetail from "pages/student-profile/assessment-details";
import StudentGrades from "pages/grades";

interface IPractitionerDashboardProps {}

const PractitionerDashboard: FC<IPractitionerDashboardProps> =
  (): ReactElement => {
    const { path } = useRouteMatch();

    return (
      <main>
        <TopNavbar options={[]} />
        <main>
          <div className="content-wrap">
            <div className="container-fluid">
              <div className="main-content">
                <Router>
                  <Switch>
                    <ProtectedRoute
                      exact
                      path={`${path}`}
                      component={ListMentorStudents}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/students`}
                      component={ListMentorStudents}
                    />
                    {/* <ProtectedRoute
                      exact
                      path={`${path}/assessment`}
                      component={ListMentorStudents}
                    /> */}
                    <ProtectedRoute
                      path={`${path}/students/:id/goals`}
                      component={MentorGoals}
                    />
                    <ProtectedRoute
                      path={`${path}/students/:id/assignments`}
                      component={MentorAssignments}
                      exact
                    />
                    <ProtectedRoute
                      path={`${path}/students/:id/grades`}
                      component={StudentGrades}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/students/:id/plans`}
                      component={Plans}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/students/:id/personal-info`}
                      component={StudentPersonalInfo}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/students/:id/academic-info`}
                      component={StudentAcademicInfo}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/students/:id/assessment`}
                      component={StudentAssessmentInfo}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/students/:id/assessment-detail/:responseId`}
                      component={IndividualAssessmentDetail}
                    />
                    <ProtectedRoute
                      exact
                      path={`${path}/profile`}
                      component={MentorProfile}
                    />
                    <Route render={() => <Redirect to="/" />} />
                  </Switch>
                </Router>
                <MessageList />
                <IncomingCallModal />
              </div>
            </div>
          </div>
        </main>
      </main>
    );
  };

export default PractitionerDashboard;
