import { IAcademicYear } from "commons/types/academicYear";

export enum AddAcademicYearActionType {
  ADD_ACADEMIC_YEAR_PENDING = "ADD_ACADEMIC_YEAR_PENDING",
  ADD_ACADEMIC_YEAR_REJECTED = "ADD_ACADEMIC_YEAR_REJECTED",
  ADD_ACADEMIC_YEAR_FULFILLED = "ADD_ACADEMIC_YEAR_FULFILLED",
}
export interface AddAddacademicYearAction {
  type: AddAcademicYearActionType;
  data: IAcademicYear;
}

export enum FetchAcademicYearsActionType {
  FETCH_ACADEMIC_YEAR_PENDING = "FETCH_ACADEMIC_YEAR_PENDING",
  FETCH_ACADEMIC_YEAR_REJECTED = "FETCH_ACADEMIC_YEAR_REJECTED",
  FETCH_ACADEMIC_YEAR_FULFILLED = "FETCH_ACADEMIC_YEAR_FULFILLED",
}
export interface FetchAddacademicYearsAction {
  type: FetchAcademicYearsActionType;
  data: IAcademicYear[];
}

export enum FetchAddacademicYearType {
  FETCH_ACADEMIC_YEAR_PENDING = "FETCH_ACADEMIC_YEAR_PENDING",
  FETCH_ACADEMIC_YEAR_REJECTED = "FETCH_ACADEMIC_YEAR_REJECTED",
  FETCH_ACADEMIC_YEAR_FULFILLED = "FETCH_ACADEMIC_YEAR_FULFILLED",
}
export interface FetchAddacademicYearAction {
  type: FetchAcademicYearsActionType;
  data: IAcademicYear;
}

export enum UpdateAcademicYearActionType {
  UPDATE_ACADEMIC_YEAR_PENDING = "UPDATE_ACADEMIC_YEAR_PENDING",
  UPDATE_ACADEMIC_YEAR_REJECTED = "UPDATE_ACADEMIC_YEAR_REJECTED",
  UPDATE_ACADEMIC_YEAR_FULFILLED = "UPDATE_ACADEMIC_YEAR_FULFILLED",
}
export interface UpdateAddacademicYearAction {
  type: UpdateAcademicYearActionType;
  data: IAcademicYear;
}

export enum DeleteAcademicYearActionType {
  DELETE_ACADEMIC_YEAR_PENDING = "DELETE_ACADEMIC_YEAR_PENDING",
  DELETE_ACADEMIC_YEAR_FULFILLED = "DELETE_ACADEMIC_YEAR_FULFILLED",
  DELETE_ACADEMIC_YEAR_REJECTED = "DELETE_ACADEMIC_YEAR_REJECTED",
}

export interface DeleteAddacademicYearAction {
  type: DeleteAcademicYearActionType;
  data: IAcademicYear;
}
