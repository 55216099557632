import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Select, { components, OptionTypeBase, StylesConfig } from "react-select";

import { IUser } from "../../../commons/types/users";
import LeftPanel from "../../layouts/mentor/leftPanel";
import { getCurrentEnrollment } from "../../../utils/user";
import AggregatedAssessments from "./AggregatedAssessments";
import IndividualAssessments from "./IndividualAssessments";
import { fetchStudent } from "../../../store/actions/data/students";
import { getFullName, getNameInitials } from "../../../utils/names";
import TermNotEnrolledError from "../../layouts/mentor/TermNotEnrolledError";
import {
  fetchIndividualSurveysForStudent,
  fetchSurveyResponsesForStudent,
  fetchEarlySurveyResponsesForStudent,
} from "store/actions/data/surveys";
import { ITerm } from "commons/types/terms";
import { fetchTerms } from "store/actions/data/terms";
import { identifyIsTermCurrentPastOrFuture } from "utils/term";
import SendIndividualAssessment from "./SendIndividualAssessment";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { SurveyResponse } from "commons/types/surveys";
import groupBy from "lodash/groupBy";
import { Role } from "../../../constants/roles";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

interface AssessmentProps {
  match: { params: { id: string } };
  onAssessmentSentSuccess?: () => void;
  student: IUser;
  fetchStudent: (id: string) => Promise<IUser>;
  fetchStudentLoading: boolean;
  fetchIndividualSurveysForStudent: (studentId: string, termId: string) => void;
  fetchIndividualAssessmentLoading: boolean;
  fetchSurveyResponsesForStudent: (studentId: string, termId: string) => void;
  fetchEarlySurveyResponsesForStudent: (
    studentId: string,
    termId: string,
  ) => void;
  terms: ITerm[];
  fetchTerms: () => void;
  surveyResponses: SurveyResponse[];
  surveyEarlyResponses: SurveyResponse[];
}

const Assessment = (props: AssessmentProps) => {
  const {
    match: {
      params: { id },
    },
    fetchStudent,
    fetchStudentLoading,
    student,
    fetchIndividualSurveysForStudent,
    fetchSurveyResponsesForStudent,
    fetchEarlySurveyResponsesForStudent,
    fetchTerms,
    terms,
    surveyResponses,
    surveyEarlyResponses,
  } = props;
  const [showAssessmentForm, setShowAssessmentForm] = useState<boolean>(false);
  const currentEnrollment = getCurrentEnrollment(student);
  const [activeTermId, setActiveTermId] = React.useState(
    currentEnrollment?.enrolledTerm._id || "",
  );

  const fetchSurveyAndResponses = React.useCallback(() => {
    fetchIndividualSurveysForStudent(id, activeTermId);
    fetchSurveyResponsesForStudent(id, activeTermId);
    fetchEarlySurveyResponsesForStudent(id, activeTermId);
  }, [id, activeTermId]);

  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);

  useEffect(() => {
    fetchSurveyAndResponses();
  }, [fetchSurveyAndResponses]);

  useEffect(() => {
    fetchStudent(id);
  }, [id, fetchStudent]);

  useEffect(() => {
    fetchTerms();
  }, []);

  const toggleAssessmentForm = () => {
    setShowAssessmentForm((prevState) => !prevState);
  };

  const AssessmentHeader = () => (
    <div className="page-heading">
      <div className="page-heading__left">
        <div className="avatar avatar--sm">
          {student.avatar ? (
            <img src={student.avatar} alt="Student" />
          ) : (
            getNameInitials(student)
          )}
        </div>

        <div className="heading-detail">
          <span className="text-light-sm fs-14">{getFullName(student)}</span>
          <h2>Assessment</h2>
        </div>
      </div>
      <div className="page-heading__right">
        <button
          onClick={toggleAssessmentForm}
          className="btn btn--primary mr-4x"
        >
          Send Survey
        </button>
      </div>
    </div>
  );

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
        <div className="text-small">{props.data.subLabel}</div>
      </components.Option>
    );
  };

  const TermSelect = () => {
    const customFilterStyle: StylesConfig<OptionTypeBase, false> = {
      control: () => ({
        display: "flex",
        padding: 0,
        justifyContent: "space-between",
      }),
      dropdownIndicator: () => ({
        padding: 0,
      }),
      valueContainer: () => ({
        width: 120,
        position: "relative",
      }),
      menuList: (provided) => ({
        ...provided,
      }),
      indicatorSeparator: () => ({ display: "none" }),
    };

    const termOptions = (() => {
      const activeEnrollment = student.userData?.enrollments?.filter(
        (enrollment) => enrollment.status !== "Draft",
      );

      if (activeEnrollment) {
        const activeEnrollmentWithTerms = activeEnrollment.filter(
          (enrollment) => enrollment.enrolledTerm,
        );
        return activeEnrollmentWithTerms.map((enrollment) => {
          return {
            label: enrollment.enrolledTerm.name,
            subLabel: identifyIsTermCurrentPastOrFuture(
              enrollment.enrolledTerm,
            ),
            value: enrollment.enrolledTerm._id,
          };
        });
      }
      return [];
      // return terms.map((enrollment) => {
      //   if (
      //     enrollment._id === currentEnrollment?.enrolledTerm._id &&
      //     !activeTermId
      //   ) {
      //     setActiveTermId(enrollment._id);
      //   }

      //   return {
      //     label: enrollment.name,
      //     subLabel: identifyIsTermCurrentPastOrFuture(enrollment),
      //     value: enrollment._id,
      //   };
      // });
    })();

    const selectedTerm = (() => {
      if (activeTermId) {
        return termOptions.find((option) => option.value === activeTermId);
      }

      return { label: "Please select", value: "", subLabel: "" };
    })();

    const onTermSelection = (selectedOption: OptionTypeBase | null) =>
      setActiveTermId(selectedOption?.value || "");

    return (
      <div className="filters border-bottom">
        <ul className="filters__list">
          <li>
            <Select
              name="term"
              onChange={onTermSelection}
              value={selectedTerm}
              isSearchable={false}
              options={termOptions}
              className="filter-item link-item"
              classNamePrefix="react-select"
              components={{ Option }}
              styles={customFilterStyle}
            />
          </li>
        </ul>
      </div>
    );
  };

  let initialOutput: Record<string, number> = {};

  let chartData: any = {
    labels: [],
    datasets: [],
  };

  if (surveyEarlyResponses?.length > 0) {
    const initialResponses = surveyEarlyResponses.filter(
      (surveyResponse) => surveyResponse.survey.surveyType === "Initial",
    );

    initialResponses.forEach((survey) => {
      survey.result.forEach((result) => {
        const skillset = result.skillset.name;
        const value = result.responses.reduce(
          (sum, current) => sum + Number(current.answer.value),
          0,
        );
        initialOutput[skillset] = (initialOutput[skillset] || 0) + value;
      });
    });

    const numResponses =
      initialResponses.length * initialResponses[0].result[0].responses.length;

    Object.keys(initialOutput).forEach((categoryKey) => {
      initialOutput[categoryKey] = parseFloat(
        (initialOutput[categoryKey] / numResponses).toFixed(2),
      );
    });

    chartData.labels = Object.keys(initialOutput);
    chartData.datasets.push({
      label: "Initial",
      data: Object.values(initialOutput),
      backgroundColor: "rgba(0, 112, 243, 0.2)",
      borderColor: "rgba(0, 112, 243, 1)",
      borderWidth: 1,
    });
  }

  let output: Record<string, Record<string, number>> = {
    Initial: {},
    Final: {},
  };

  const groupBySurveyType: Record<string, SurveyResponse[]> = groupBy(
    surveyResponses,
    "survey.surveyType",
  );

  output = Object.keys(groupBySurveyType).reduce((acc, surveyType) => {
    groupBySurveyType[surveyType].forEach((survey) => {
      survey.result.forEach((result) => {
        const skillset = result.skillset.name;
        const value = result.responses.reduce(
          (sum, current) => sum + Number(current.answer.value),
          0,
        );
        acc[surveyType][skillset] = (acc[surveyType][skillset] || 0) + value;
      });
    });
    return acc;
  }, output);

  Object.keys(output).forEach((surveyType) => {
    if (Object.keys(output[surveyType]).length) {
      const numResponses =
        groupBySurveyType[surveyType].length *
        groupBySurveyType[surveyType][0].result[0].responses.length;
      Object.keys(output[surveyType]).forEach((categoryKey) => {
        output[surveyType][categoryKey] = parseFloat(
          (output[surveyType][categoryKey] / numResponses).toFixed(2),
        );
      });
    }
  });

  if (Object.values(output.Final).length) {
    chartData.datasets.push({
      label: "Final",
      data: Object.values(output.Final),
      backgroundColor: "rgba(248, 43, 96, 0.2)",
      borderColor: "rgba(248, 43, 96, 1)",
      borderWidth: 1,
    });
  } else {
    const initialPractionerResponse = surveyResponses.find(
      (survey) =>
        survey.role === Role.PRACTITIONER &&
        survey.survey.surveyType === "Initial",
    );
    const checkProgress: boolean | undefined =
      initialPractionerResponse?.result.some((result) => {
        return result.responses.some((r) => {
          return Number(r.revisions?.length) > 0;
        });
      });
    if (checkProgress) {
      chartData.datasets.push({
        label: "Current Progress",
        data: Object.values(output.Initial),
        backgroundColor: "rgba(84, 186, 85, 0.2)",
        borderColor: "rgba(84, 186, 85, 1)",
        borderWidth: 1,
      });
    }
  }

  return (
    <>
      <LeftPanel student={student} activeTab="Assessment" />
      <div className="content-panel">
        {isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : null}
        <AssessmentHeader />
        {chartData.datasets.length > 0 && (
          <div className="tw-bg-white tw-mb-4">
            <div className="tw-bg-secondary tw-text-white tw-font-bold tw-px-2 tw-py-1 tw-text-xl">
              Skillset Chart
            </div>
            <div className="tw-px-48 tw-pt-4">
              <Radar
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        font: {
                          size: 16,
                        },
                      },
                    },
                  },
                  scales: {
                    r: {
                      suggestedMin: 0,
                      suggestedMax: 5,
                      pointLabels: {
                        font: {
                          size: 12,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
        <TermSelect />
        <section className="section">
          <AggregatedAssessments student={student} />
        </section>
        <section className="section">
          <IndividualAssessments
            student={student}
            onResendAssessmentSuccess={fetchSurveyAndResponses}
          />
        </section>
        <Modal
          ariaHideApp={false}
          isOpen={showAssessmentForm}
          className="modal-block-form"
          onRequestClose={toggleAssessmentForm}
        >
          <SendIndividualAssessment
            onAssessmentSentSuccess={fetchSurveyAndResponses}
            student={student}
            closeModal={toggleAssessmentForm}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  student: state.students.fetchStudent,
  fetchStudentLoading: state.students.fetchStudentLoading,
  terms: state.terms.fetchTerms,
  surveyResponses: state.surveys.surveyResponses,
  surveyEarlyResponses: state.surveys.surveyEarlyResponses,
});

const mapDispatchToProps = {
  fetchTerms,
  fetchStudent,
  fetchIndividualSurveysForStudent,
  fetchSurveyResponsesForStudent,
  fetchEarlySurveyResponsesForStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
