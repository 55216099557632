import IRole from "commons/types/role";

/**
 * Returns full name of user by firstName and lastName
 * @param user IUser
 * @returns boolean
 */
export const checkRole = (roles: IRole[], role: string) => {
  return roles?.some((x) => x.name === role);
};

export const getRole = (roles: IRole[], roleNameToFind: string) => {
  if (!Array.isArray(roles)) {
    return;
  }
  return roles.find((r) => r.name === roleNameToFind);
};
