import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH, METRIC_TITLE, METRIC_VALUE } from "constants/index";

const GoalSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Goal title is required")
    .max(TITLE_LENGTH, `Title must be less than ${TITLE_LENGTH} characters`),
  requiredDeadline: Yup.date().required("Please select due date"),
  workData: Yup.object().shape({
    workType: Yup.string().required("Goal type is required"),
    metrics: Yup.object().shape({
      metricsType: Yup.string().max(
        METRIC_TITLE,
        `Metric Type must be less than ${METRIC_TITLE} characters`,
      ),
      baselineMetric: Yup.string().max(
        METRIC_VALUE,
        `Baseline Value must be less than ${METRIC_VALUE} characters`,
      ),
      achievedMetric: Yup.string().max(
        METRIC_VALUE,
        `Achieved value must be less than ${METRIC_VALUE} characters`,
      ),
    }),
  }),
});
export default GoalSchema;
