import React from "react";

import Form from "../form";
import { error } from "utils/toast";
import { SCHOOL_TYPES } from "constants/index";
import { fetchDistricts } from "services/tenant";
import { ISchoolCreate } from "commons/types/school";
import { IGradeType } from "commons/types/gradeTypes";
import * as gradeTypeService from "services/gradeType";
import { IDistrict } from "commons/types/institutions";

interface IAddSchoolProps {
  closeModal: () => void;
  saveSchool: (school: ISchoolCreate) => Promise<void>;
}

const AddSchool: React.FC<IAddSchoolProps> = ({ closeModal, saveSchool }) => {
  const [districts, setDistricts] = React.useState<IDistrict[]>([]);
  const [gradeTypes, setGradeTypes] = React.useState<IGradeType[]>([]);

  React.useEffect(() => {
    const populateDistrictDropDown = async () => {
      try {
        const { data } = await fetchDistricts();
        setDistricts(data);
      } catch (ex) {
        error("Failed to fetch school districts");
      }
    };
    const populateGradeTypeDropDown = async () => {
      try {
        const { data } = await gradeTypeService.fetchAll({});
        setGradeTypes(data);
      } catch (ex) {
        error("Failed to fetch grade types");
      }
    };
    Promise.allSettled([
      populateDistrictDropDown(),
      populateGradeTypeDropDown(),
    ]);
  }, []);

  const initialValue = {
    name: "",
    district: "",
    type: SCHOOL_TYPES.SCHOOL,
    gradeTypes: [],
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add a School</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        gradeTypes={gradeTypes}
        saveSchool={saveSchool}
        initialValues={initialValue}
        districts={districts}
        closeModal={closeModal}
      />
    </div>
  );
};

export default AddSchool;
