import { Channel } from "twilio-chat/lib/channel";

import { ToggleChatActionType, ChatStatusType } from "store/actions/types/chat";

interface IState {
  isChatOpen: boolean;
  unreadMessageChannels: Channel[];
}

const INITIAL_STATE: IState = {
  isChatOpen: true,
  unreadMessageChannels: [],
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ToggleChatActionType.TOGGLE_CHAT:
      return { ...state, isChatOpen: !state.isChatOpen };
    case ChatStatusType.SET_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessageChannels: state.unreadMessageChannels
          .filter(
            (channel: Channel) => channel.uniqueName !== action.data.uniqueName,
          )
          .concat(action.data),
      };
    case ChatStatusType.SET_READ_MESSAGE:
      return {
        ...state,
        unreadMessageChannels: state.unreadMessageChannels.filter(
          (channel: Channel) => channel.uniqueName !== action.data.uniqueName,
        ),
      };
    default:
      return state;
  }
};

export default reducer;
