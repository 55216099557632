import * as queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";

import { error } from "utils/toast";
import done from "assets/images/done.svg";
import MultiStepForm from "./MultiStepForm";
import Loader from "commons/components/Loader";
import doneGray from "assets/images/done-gray.svg";
import ResponseCompleted from "./ResponseCompleted";
import { SurveyListItem } from "commons/types/surveys";
import EmptyState from "commons/components/EmptyState";
import { checkIsResponseSubmitted, getSurveyById } from "services/surveys";
import { getDataFromLocalStorage } from "services/localStorage";

interface Props {
  match: {
    params: { id: string; responderId: string };
  };
  location: { search: string };
}

const RespondForm: React.FC<Props> = ({
  match: {
    params: { id, responderId },
  },
  location: { search },
}): JSX.Element => {
  const role: any = queryString.parse(search).role || "";
  const [data, setData] = useState<SurveyListItem>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isResponseAlreadySubmitted, setIsResponseAlreadySubmitted] =
    useState(false);
  const tenantLogo = getDataFromLocalStorage("tenantLogo");

  const fetchIsResponseSubmitted = useCallback(async () => {
    try {
      const {
        data: { isSubmitted },
      } = await checkIsResponseSubmitted(id, responderId);

      setIsResponseAlreadySubmitted(isSubmitted);

      return isSubmitted;
    } catch (err: any) {
      error("Failed to check survey response");
    }
    setIsLoading(false);
  }, [id, responderId]);

  const fetchAndSetSurveyData = useCallback(async () => {
    try {
      const { data } = await getSurveyById(id);
      setData(data);
    } catch (err: any) {
      error("Failed to fetch survey questions");
    }
  }, [id]);

  useEffect(() => {
    const initialize = async () => {
      const doesResponseExist = await fetchIsResponseSubmitted();

      if (doesResponseExist) {
        setIsLoading(false);

        return;
      }

      await fetchAndSetSurveyData();
      setIsLoading(false);
    };

    initialize();
  }, [fetchIsResponseSubmitted, fetchAndSetSurveyData]);

  const SurveyResponseForm = () => {
    if (isLoading) {
      return <Loader type="ThreeDots" />;
    }

    if (isResponseAlreadySubmitted) {
      return (
        <ResponseCompleted
          title={"You have already responded"}
          description={"You can only fill out this form once."}
          image={doneGray}
        />
      );
    }

    if (isSucceed) {
      return (
        <ResponseCompleted
          title={"Thank you !"}
          description={"Your submission has been sent."}
          image={done}
        />
      );
    }

    return data?._id ? (
      <MultiStepForm
        data={data}
        role={role}
        responderId={responderId}
        setIsSucceed={setIsSucceed}
      />
    ) : (
      <EmptyState title="Failed to get survey questions" image={tenantLogo} />
    );
  };

  return (
    <>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img
                  src={tenantLogo}
                  alt="logo"
                  className="tw-w-50 tw-h-10 tw-object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <SurveyResponseForm />
    </>
  );
};

export default RespondForm;
