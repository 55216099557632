import QualitativeGrades from "./qualitative-grades";
import QuantitativeGrades from "./quantitative-grades";
import SettingsPanel from "pages/layouts/intake/settingsPanel";

const GradeTypes = () => {
  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="Grade Type" />
          <div className="content-panel">
            <h1>Grade Types</h1>
            <QuantitativeGrades />
            <QualitativeGrades />
          </div>
          <div className="right-gap" />
        </div>
      </div>
    </div>
  );
};

export default GradeTypes;
