import Modal from "react-modal";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { FormEvent, useEffect, useState } from "react";

import config from "configs";
import { Status } from "constants/status";
import { ITerm } from "commons/types/terms";
import { getDayFromDate, getDateInFormat } from "utils/dates";
import Confirm from "commons/components/Confirm";
import { IPrograms } from "commons/types/programs";
import { error, success, warn } from "utils/toast";
import { ILocation, IMentor } from "commons/types";
import { IEnrollment, IUser } from "commons/types/users";
import { checkEndDateBeforeStartDate } from "utils/dates";
import { fetchSatelliteLocations } from "services/tenant";
import { enrollmentSchema } from "commons/schemas/student";
import { DAYS_OF_WEEK, MILLISECONDS_PER_DAY } from "constants/index";
import { DateInput, TextAreaInput } from "commons/components/form-fields";
import { subdomainName } from "utils/http";

interface LabelValue {
  label: string;
  value: string;
}

interface IConfirm {
  active: boolean;
  data?: IEnrollment;
  formikProps?: FormikHelpers<IEnrollment>;
  message: {
    header: string;
    body: string;
  };
}

interface Props {
  student: IUser;
  isUpdate?: boolean;
  mentors: IMentor[];
  initialValues: any;
  saveEnrollment: (data: IEnrollment) => void;
  terms: ITerm[];
  programs: IPrograms[];
  institutions: ILocation[];
}

interface FormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, value: any) => void;
  values: IEnrollment;
  touched: IEnrollment;
  errors: IEnrollment;
  initialValues: any;
  isSubmitting: boolean;
}

const EnrollForm: React.FC<Props> = ({
  student,
  terms,
  mentors,
  isUpdate,
  programs,
  saveEnrollment,
  institutions,
  initialValues,
}) => {
  const history = useHistory();

  const [hasToConfirm, setHasToConfirm] = useState<IConfirm>({
    active: false,
    data: undefined,
    message: {
      header: "",
      body: "",
    },
  });

  const [mentorshipSchedule, setMentorshipSchedule] = useState<Date[]>([]);
  const [satelliteLocations, setSatelliteLocations] = useState<any[]>([]);
  const [learningCenter, setLearningCenter] = useState<string | undefined>();
  const [disableButton, setDisableButton] = useState(false);

  const fetchLearningCentersLocations = async (centerId: string) => {
    try {
      const { data } = await fetchSatelliteLocations({ locationId: centerId });
      setSatelliteLocations(data);
    } catch (ex) {
      error("Failed to fetch satellite locations");
    }
  };

  useEffect(() => {
    if (initialValues?.learningCenter)
      fetchLearningCentersLocations(initialValues?.learningCenter);
  }, [initialValues.learningCenter]);

  useEffect(() => {
    const term = terms.filter((term) => {
      return term._id === initialValues.enrolledTerm;
    })[0];

    setHighlightedSchedule(
      initialValues.mentorshipSchedule?.latest?.day || "",
      term,
    );
  }, [initialValues.enrolledTerm, initialValues.mentorshipSchedule, terms]);

  const setHighlightedSchedule = (date: string, term: ITerm) => {
    if (date && term) {
      const endDate = new Date(term.endDate);
      const selectedDate = new Date(date.toString());
      let highlightedDates: any[] = [selectedDate];

      // After selected
      let iteration = 1;
      while (true) {
        const nextDate = new Date();
        nextDate.setTime(
          selectedDate.getTime() + MILLISECONDS_PER_DAY * (iteration * 7),
        );
        if (endDate.getTime() < nextDate.getTime()) break;
        iteration += 1;
        highlightedDates.push(nextDate);
      }
      setMentorshipSchedule(highlightedDates);
    }
  };

  const mentorsOption: LabelValue[] = mentors
    .filter((record: IUser) => record.userData?.status === Status.ACTIVE)
    .map((record: IMentor) => ({
      label: `${record.firstName} ${record.lastName}`,
      value: record._id,
    }));

  const institutionOptions: LabelValue[] = institutions.map(
    (record: ILocation) => ({
      label: record.name,
      value: record._id,
    }),
  );

  const satelliteLocationOptions: LabelValue[] = satelliteLocations.map(
    (record) => ({
      label: record.address,
      value: record._id,
    }),
  );

  const termOptions: LabelValue[] = terms
    .filter((term) => {
      if (learningCenter) {
        if (term?.centers && Array.isArray(term?.centers)) {
          const termCenterIds = term?.centers.map((center) => center?._id);
          if (!termCenterIds.includes(learningCenter)) {
            return false;
          }
        }
      }
      return true;
    })
    .map((record: ITerm) => ({
      label: `${record.name}${record.isCurrent ? " | Current" : ""}`,
      value: record._id,
    }));

  const programOptions: LabelValue[] = programs.map((program: IPrograms) => ({
    label: program.name,
    value: program._id,
  }));

  const dayOfWeekElements = (selectedDate: string) => (
    <div className="week-list week-list--disabled">
      <ul>
        {DAYS_OF_WEEK.map((day: string) => (
          <li
            key={day}
            {...(selectedDate && getDayFromDate(selectedDate) === day
              ? { className: "selected" }
              : null)}
          >
            <span className="item">{day[0].toString().toUpperCase()}</span>
          </li>
        ))}
      </ul>
    </div>
  );

  const getTermById = (id: string) => {
    const matchedTerms = terms.filter((term) => term._id === id);

    return matchedTerms.length ? matchedTerms[0] : null;
  };

  const checkTermChanged = (data: IEnrollment) => {
    return (
      isUpdate &&
      initialValues.enrolledTerm &&
      data.enrolledTerm !== initialValues.enrolledTerm
    );
  };

  const checkMentorshipScheduledDateChangedToPast = (data: IEnrollment) => {
    const today = new Date(new Date().setHours(0, 0, 0, 0));

    return (
      (isUpdate &&
        data.mentorshipSchedule?.latest?.day &&
        data.mentorshipSchedule?.latest?.day !==
          initialValues.mentorshipSchedule?.latest?.day &&
        checkEndDateBeforeStartDate(
          today,
          data.mentorshipSchedule?.latest?.day,
        )) ||
      (!isUpdate &&
        data.mentorshipSchedule?.latest?.day &&
        checkEndDateBeforeStartDate(
          today,
          data.mentorshipSchedule?.latest?.day,
        ))
    );
  };

  const checkNeedConfirmation = (
    data: IEnrollment,
    formikProps: FormikHelpers<IEnrollment>,
  ) => {
    let message = { header: "", body: "" };
    let needToConfirm = false;

    if (data.status === "Enrolled") {
      if (checkTermChanged(data)) {
        message = {
          header: "Are you sure you want to change the term?",
          body: "Changing term will affect the student's planning periods.",
        };

        if (checkMentorshipScheduledDateChangedToPast(data)) {
          message = {
            header:
              "Are you sure you want to change term with planning period in past date?",
            body: "Changing term will affect the student's planning periods and selecting past planning period date might not generate planning periods.",
          };
        }

        needToConfirm = true;
      } else if (checkMentorshipScheduledDateChangedToPast(data)) {
        message = {
          header:
            "Are you sure you want to set the planning period date to the past date?",
          body: "Selecting past planning period date might not generate planning periods.",
        };

        needToConfirm = true;
      }
    }

    setHasToConfirm({
      active: needToConfirm,
      data: data,
      message,
      formikProps,
    });

    return needToConfirm;
  };

  const onSubmitHandler = async (
    data: IEnrollment,
    formikProps: FormikHelpers<IEnrollment>,
  ) => {
    let payload = { ...data };
    if (payload.mentorshipSchedule?.latest?.day) {
      payload.mentorshipSchedule.latest.day = getDateInFormat(
        payload.mentorshipSchedule.latest.day,
        "YYYY-MM-DD",
      );
    }
    const mentorship = data;
    const initialMentorshipSchedule = initialValues.mentorshipSchedule;

    if (
      mentorship?.mentorshipSchedule?.latest?.day &&
      initialMentorshipSchedule?.latest?.day &&
      initialMentorshipSchedule?.latest !==
        mentorship?.mentorshipSchedule?.latest
    ) {
      mentorship.mentorshipSchedule.old?.push(initialMentorshipSchedule.latest);
    }

    try {
      if (!checkNeedConfirmation(payload, formikProps)) {
        await saveEnrollment(payload);
        if (isUpdate) return success("Student enrollment information updated");

        return success("Student enrolled");
      }
    } catch (err: any) {
      error("Failed to save enrollment data");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      enableReinitialize={true}
      validationSchema={enrollmentSchema}
    >
      {(props: FormikProps) => {
        const handleScheduleSelect = (date: any) => {
          setHighlightedSchedule(date, term);
          props.setFieldValue(
            "mentorshipSchedule.latest.day",
            date ? date : "",
          );
        };

        const term = terms.filter((term) => {
          return term._id === props.values.enrolledTerm;
        })[0];

        return (
          <form onSubmit={props.handleSubmit}>
            <section className="section">
              <div className="section__title">
                <h3>Suggest a Mentor</h3>
              </div>
              <div className="section__subtitle">
                <span className="text-small">
                  You can skip this section for now if you don’t have adequate
                  information to make this decision right away. You can assign
                  mentor(s) and add notes at the time of enrollment.
                </span>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">
                        Potential Primary Mentor
                      </label>
                      <Select
                        placeholder="Please select potential primary mentor"
                        name="userId"
                        onChange={(selected: any) => {
                          props.setFieldValue("userId", selected.value);
                          props.setFieldValue("status", "Draft");
                        }}
                        value={mentorsOption.filter(
                          (option) => option.value === props.values.userId,
                        )}
                        onBlur={props.handleBlur}
                        classNamePrefix="react-select"
                        options={mentorsOption}
                      />
                      <label className="input__error">
                        {props.touched.userId && props.errors.userId}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TextAreaInput
                      label="Registration Notes"
                      placeholder="Please write here if you have any specific notes for the mentor(s)"
                      name="mentorshipNote"
                      values={props.values.mentorshipNote}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      touched={props.touched}
                      errors={props.errors}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="section__title">
                <h3>Location</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">
                        {subdomainName} Learning Center
                      </label>
                      <Select
                        placeholder="Please select learning location"
                        name="learningCenter"
                        onChange={(selected: any) => {
                          props.setFieldValue("learningCenter", selected.value);
                          props.setFieldValue("satelliteLocation", null);
                          fetchLearningCentersLocations(selected.value);
                          setLearningCenter(selected.value);
                        }}
                        value={institutionOptions.filter(
                          (option) =>
                            option.value === props.values.learningCenter,
                        )}
                        options={institutionOptions}
                        classNamePrefix="react-select"
                        onBlur={props.handleBlur}
                      />
                      <label className="input__error">
                        {props.touched.learningCenter &&
                          props.errors.learningCenter}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Satellite Location</label>
                      <Select
                        placeholder="Please select satellite location"
                        name="satelliteLocation"
                        options={satelliteLocationOptions}
                        onChange={(selected: any) =>
                          props.setFieldValue(
                            "satelliteLocation",
                            selected.value,
                          )
                        }
                        value={satelliteLocationOptions.filter(
                          (option) =>
                            option.value === props.values.satelliteLocation,
                        )}
                        onBlur={props.handleBlur}
                        classNamePrefix="react-select"
                      />
                      <label className="input__error">
                        {props.touched.satelliteLocation &&
                          props.errors.satelliteLocation}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="section__title">
                <h3>Program Information</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">
                        Enroll Student into {subdomainName} Term
                      </label>
                      <Select
                        placeholder={`Please select ${subdomainName} term`}
                        classNamePrefix="react-select"
                        name="enrolledTerm"
                        onChange={(selected: any) => {
                          props.setFieldValue("enrolledTerm", selected.value);
                          setMentorshipSchedule([]);
                          props.setFieldValue(
                            "mentorshipSchedule.latest.day",
                            null,
                          );

                          const selectedTerm = getTermById(selected.value);
                          if (selectedTerm && !selectedTerm.isCurrent) {
                            props.setFieldError(
                              "enrolledTerm",
                              "Selected term is not current",
                            );
                            warn("Selected term is not current");
                          }
                          if (
                            isUpdate &&
                            initialValues.enrolledTerm &&
                            selected.value !== initialValues.enrolledTerm
                          ) {
                            warn(
                              "Changing term will affect the student's planning periods",
                            );
                          }
                        }}
                        value={termOptions.filter(
                          (option) =>
                            option.value === props.values.enrolledTerm,
                        )}
                        options={termOptions}
                        onBlur={props.handleBlur}
                      />
                      <label className="input__error">
                        {props.touched.enrolledTerm &&
                          props.errors.enrolledTerm}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Type of Program</label>
                      <Select
                        classNamePrefix="react-select"
                        name="mentorshipSchedule.latest.program"
                        onChange={(selected: any) => {
                          props.setFieldValue(
                            "mentorshipSchedule.latest.program",
                            selected.value,
                          );
                        }}
                        placeholder="Please select program"
                        value={programOptions.filter(
                          (option) =>
                            option.value ===
                            props.values.mentorshipSchedule?.latest?.program,
                        )}
                        onBlur={props.handleBlur}
                        options={programOptions}
                      />
                      <label className="input__error">
                        {props.touched.mentorshipSchedule?.latest?.cadence &&
                          props.errors.mentorshipSchedule?.latest?.cadence}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Weekly Planning Period</h3>
              </div>
              <div className="section__subtitle">
                <span className="text-small">
                  Please help mentors and students get started by setting up a
                  session schedule. Please note mentors can change this later
                  based on theirs and the students’ availability.
                </span>
              </div>
              <div className="section__content">
                <span>What day of the week?</span>
                <div className="row mt-4x">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Select a Date</label>
                      <DateInput
                        name="mentorshipSchedule.latest.day"
                        isValid={
                          !(
                            props.touched.mentorshipSchedule?.latest?.day &&
                            props.errors.mentorshipSchedule?.latest?.day
                          )
                        }
                        disabled={term ? false : true}
                        selected={
                          props.values.mentorshipSchedule?.latest?.day || ""
                        }
                        minDate={new Date(term?.startDate)}
                        maxDate={new Date(term?.endDate)}
                        onChange={handleScheduleSelect}
                        placeholder="Please select"
                        highlightDates={mentorshipSchedule}
                        onBlur={props.handleBlur}
                        dateFormat="MM/dd/yyyy"
                      />
                      <label className="input__error">
                        {props.touched.mentorshipSchedule?.latest?.day &&
                          props.errors.mentorshipSchedule?.latest?.day}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    {dayOfWeekElements(
                      props.values.mentorshipSchedule?.latest?.day || "",
                    )}
                  </div>
                  <div className="col-12">
                    {props.values.mentorshipSchedule?.latest?.day && (
                      <span className="text-bold">
                        Mentorship session will be on every{" "}
                        {getDayFromDate(
                          props.values.mentorshipSchedule.latest.day,
                        )}{" "}
                        after formally enrolled.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="section__content">
                <div className="row">
                  <div className="col-12 d-flex">
                    <button
                      disabled={
                        props.isSubmitting ||
                        !props.values.userId ||
                        !props.values.enrolledTerm ||
                        !props.values.mentorshipSchedule?.latest?.day
                          ? true
                          : false
                      }
                      className="btn btn--green"
                      type="submit"
                      onClick={() => {
                        props.setFieldValue(
                          "enrollmentDate",
                          new Date().toISOString(),
                        );
                        props.setFieldValue("status", "Enrolled");
                      }}
                    >
                      {isUpdate ? "Update" : "Enroll and Confirm"}
                    </button>
                    <button
                      disabled={
                        props.isSubmitting ||
                        props.values === props.initialValues
                      }
                      className="btn btn--outlined-primary mr-4x ml-4x"
                      type="submit"
                    >
                      Save As Draft
                    </button>
                    <span
                      className="btn btn-text txt-primary-color"
                      onClick={() => {
                        history.push(config.uiPath.students.list);
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <Modal
              className="modal-block"
              isOpen={hasToConfirm.active}
              ariaHideApp={false}
              onRequestClose={() =>
                setHasToConfirm({
                  active: false,
                  data: undefined,
                  message: { header: "", body: "" },
                })
              }
            >
              <Confirm
                disabled={disableButton}
                closeModal={() =>
                  setHasToConfirm({
                    active: false,
                    data: undefined,
                    message: { header: "", body: "" },
                  })
                }
                handleOk={async () => {
                  try {
                    if (hasToConfirm.data) {
                      setDisableButton(true);
                      await saveEnrollment(hasToConfirm.data);
                      setDisableButton(false);
                      if (isUpdate) {
                        success("Student enrollment information updated");
                      } else {
                        success("Student enrolled");
                      }
                    }
                  } catch (err: any) {
                    error("Failed to save enrollment");
                  }

                  setHasToConfirm({
                    active: false,
                    data: undefined,
                    message: { header: "", body: "" },
                  });
                }}
                message={hasToConfirm.message}
              />
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default EnrollForm;
