import { useFormik } from "formik";
import React, { useState, useEffect } from "react";

import { error } from "utils/toast";
import { IUser } from "commons/types/users";
import { getDayFromDate } from "utils/dates";
import { fetchPrograms } from "services/tenant";
import { getStudentEnrollments } from "utils/user";
import { DAYS_OF_WEEK } from "constants/index";
import { IPrograms } from "commons/types/programs";
import { IMentorshipSchedule } from "commons/types/mentorshipSchedule";
import { CustomSelect, DateInput } from "commons/components/form-fields";

interface IUpdateMentorScheduleProps {
  closeModal: () => void;
  student: IUser;
  editMentorshipSchedule: (payload: IMentorshipSchedule) => any;
  selectedTerm: number;
}
const UpdateMentorSchedule: React.FC<IUpdateMentorScheduleProps> = ({
  closeModal,
  student,
  editMentorshipSchedule,
  selectedTerm,
}) => {
  const [programOptions, setProgramOptions] = useState([]);
  useEffect(() => {
    const fetchProgramOptions = async () => {
      try {
        const { data } = await fetchPrograms();
        setProgramOptions(
          data.map((program: IPrograms) => ({
            label: program.name,
            value: program._id,
          })),
        );
      } catch (err: any) {
        error("Failed to load programs");
      }
    };
    fetchProgramOptions();
  }, []);

  const studentEnrollments = getStudentEnrollments(student) || [];

  const formik = useFormik({
    initialValues: {
      enrolledTerm: studentEnrollments[selectedTerm]?.enrolledTerm?._id,
      cadence:
        studentEnrollments[selectedTerm]?.mentorshipSchedule?.latest?.cadence,
      day: studentEnrollments[selectedTerm]?.mentorshipSchedule?.latest?.day,
      decisionDate: new Date(),
      program:
        studentEnrollments[selectedTerm]?.mentorshipSchedule?.latest?.program
          ?._id,
    },
    onSubmit: async (values) => {
      await editMentorshipSchedule(values);
    },
  });

  return (
    <>
      <div className="modal-wrap modal-wrap--lg">
        <div className="modal-wrap__header">
          <h3>Mentor Planning Period</h3>
          <span className="link-item" onClick={closeModal}>
            <box-icon name="x" />
          </span>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="modal-wrap__body">
            <div className="row">
              <div className="col-12">
                <div className="input-wrap">
                  <label className="input__label">Term Name</label>
                  <input
                    disabled={true}
                    type="text"
                    placeholder={
                      studentEnrollments[selectedTerm].enrolledTerm?.name
                    }
                    className="input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <CustomSelect
                  label="Type of Program"
                  name="program"
                  options={programOptions}
                  value={
                    programOptions.find(
                      (option: any) =>
                        formik.values.program &&
                        option.value === formik.values.program,
                    ) || { label: "", value: "" }
                  }
                  handleChange={(value: any) => {
                    formik.setFieldValue("program", value.value);
                  }}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </div>
            </div>
            <div className="row mb-3x">
              <span className="input__label ml-5x">
                When does the first planning day start?
              </span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input__wrap">
                  <label htmlFor="day" className="input__label">
                    Start Date
                  </label>
                  <DateInput
                    selected={formik.values.day}
                    id="day"
                    name="day"
                    isValid={!(formik.touched.day && formik.errors.day)}
                    onChange={(date) => {
                      formik.setFieldValue("day", date);
                    }}
                    onBlur={formik.handleBlur}
                    minDate={new Date()}
                    maxDate={
                      new Date(
                        studentEnrollments[selectedTerm]?.enrolledTerm?.endDate,
                      )
                    }
                  />
                </div>
                {formik.errors.day && formik.touched.day ? (
                  <p className="input__error">{formik.errors.day}</p>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input__wrap">
                  <div className="week-list">
                    <ul>
                      {DAYS_OF_WEEK.map((day: string) => (
                        <li
                          key={day}
                          className={`${
                            formik.values.day &&
                            getDayFromDate(formik.values.day) === day
                              ? "selected"
                              : null
                          }`}
                        >
                          <span className="item">
                            {day[0].toString().toUpperCase()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <span className="input__label">
                  Please note that mentorship begins on the selected day of the
                  week
                </span>
              </div>
            </div>
          </div>
          <div className="modal-wrap__footer">
            <div className="row">
              <div className="col-12 d-flex">
                <button
                  className="btn btn--primary mr-4x"
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    formik.values === formik.initialValues
                  }
                >
                  Save
                </button>
                <button
                  disabled={formik.isSubmitting}
                  onClick={closeModal}
                  className="btn txt-primary-color"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateMentorSchedule;
