import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Column, useTable } from "react-table";

import { uuid } from "utils/uuid";
import AddSchool from "./add-school";
import { RootState } from "store/reducers";
import Loader from "commons/components/Loader";
import ReadingImage from "assets/images/reading.svg";
import EmptyState from "commons/components/EmptyState";
import SettingsPanel from "pages/layouts/intake/settingsPanel";
import {
  addSchool,
  deleteSchool,
  updateSchool,
  fetchSchools,
} from "store/actions/data/school";
import {
  ISchool,
  ISchoolCreate,
  ISchoolGradeType,
  ISchoolParams,
} from "commons/types/school";
import SchoolActionCell from "./school-action-cell";

interface ISchoolProps {
  schools: ISchool[];
  schoolsLoading: boolean;
  deleteSchool: (schoolId: string) => Promise<ISchool>;
  addSchool: (school: ISchoolCreate) => Promise<ISchool>;
  fetchSchools: (params: ISchoolParams) => Promise<ISchool[]>;
  updateSchool: (id: string, school: ISchoolCreate) => Promise<void>;
}

const School: React.FC<ISchoolProps> = ({
  schools,
  addSchool,
  updateSchool,
  fetchSchools,
  deleteSchool,
  schoolsLoading,
}) => {
  const [isAddSchoolOpen, setIsAddSchoolOpen] = React.useState(false);

  const handleAddSchool = async (school: ISchoolCreate) => {
    const addedSchool = await addSchool(school);
    setIsAddSchoolOpen(false);

    if (addedSchool) {
      fetchSchools({});
    }
  };

  const handleUpdateSchool = React.useCallback(
    async (schoolId: string, school: ISchoolCreate) => {
      await updateSchool(schoolId, school);
      fetchSchools({});
    },
    [updateSchool, fetchSchools],
  );

  const handleDeleteSchool = React.useCallback(
    async (schoolId: string) => {
      await deleteSchool(schoolId);
      fetchSchools({});
    },
    [deleteSchool, fetchSchools],
  );

  React.useEffect(() => {
    fetchSchools({});
  }, [fetchSchools]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ value }: { value: string }) => {
            return value;
          },
        },
        {
          Header: "School District",
          accessor: "district.name",
          Cell: ({ value }: { value: any }) => (value ? value : "-"),
        },
        {
          Header: "School Type",
          accessor: "type",
          Cell: ({ value }: { value: string }) => {
            return value.toLowerCase();
          },
        },
        {
          Header: "Grade Type",
          accessor: "gradeTypes",
          Cell: ({ value }: { value?: ISchoolGradeType[] }) => {
            const id = uuid();
            if (value && value.length > 0) {
              return (
                <>
                  <p style={{ display: "inline" }}>
                    {value[0]?.grade?.name || ""}
                  </p>
                  {value?.length > 1 && value[0]?.grade && (
                    <>
                      <p
                        style={{ display: "inline" }}
                        className="tooltip-info tooltip-info__link"
                        data-tip
                        data-for={id}
                      >{`(${value.length - 1} more)`}</p>

                      <ReactTooltip
                        multiline={true}
                        id={id}
                        place="bottom"
                        effect="solid"
                      >
                        {value.slice(1).map(
                          (grade) =>
                            grade.grade && (
                              <React.Fragment key={grade._id}>
                                <span>
                                  {`${grade.grade.name} `}
                                  {grade.grade.data.range &&
                                    `(Range ${grade.grade.data.range?.minimum} - ${grade.grade.data.range?.maximum})`}
                                </span>
                                <br />
                              </React.Fragment>
                            ),
                        )}
                      </ReactTooltip>
                    </>
                  )}
                </>
              );
            }
            return "-";
          },
        },
        {
          Header: "Actions",
          accessor: "_id",
          Cell: ({ row }: any) => {
            return (
              <SchoolActionCell
                row={row}
                handleUpdateSchool={handleUpdateSchool}
                handleDeleteSchool={handleDeleteSchool}
              />
            );
          },
        },
      ] as Column<ISchool>[],
    [handleUpdateSchool, handleDeleteSchool],
  );

  const tableInstance = useTable({ columns, data: schools });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (schoolsLoading) {
    return <Loader type="ThreeDots" />;
  }

  if (schools && schools.length < 1) {
    return (
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <SettingsPanel activeTab="Schools" />
            <div className="content-panel">
              <h1>Schools</h1>
              <EmptyState
                image={ReadingImage}
                title="You currently do not have any School."
                description="Please add some school to get some work done."
                alt="school"
              >
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setIsAddSchoolOpen(true)}
                >
                  Add School
                </button>
              </EmptyState>
            </div>
          </div>
        </div>
        <Modal
          className="modal-block"
          isOpen={isAddSchoolOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsAddSchoolOpen(false)}
        >
          <AddSchool
            saveSchool={handleAddSchool}
            closeModal={() => setIsAddSchoolOpen(false)}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="Schools" />
          <div className="content-panel">
            <div className="page-heading">
              <h1>Schools</h1>
              <div className="page-heading__right align-items-center">
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setIsAddSchoolOpen(true)}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table {...getTableProps()} className="table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="head-row"
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="table__col">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="table__row">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="table__col">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="right-gap" />
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddSchoolOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsAddSchoolOpen(false)}
      >
        <AddSchool
          saveSchool={handleAddSchool}
          closeModal={() => setIsAddSchoolOpen(false)}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  schools: state.schools.fetchSchools,
  schoolsLoading: state.schools.fetchSchoolsLoading,
});

const mapDispatchToProps = {
  addSchool,
  updateSchool,
  fetchSchools,
  deleteSchool,
};

export default connect(mapStateToProps, mapDispatchToProps)(School);
