import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Column, useTable } from "react-table";

import AddSchoolDistrict from "./add";
import { RootState } from "store/reducers";
import Loader from "commons/components/Loader";
import SchoolImage from "assets/images/school.svg";
import EmptyState from "commons/components/EmptyState";
import SettingsPanel from "pages/layouts/intake/settingsPanel";
import {
  ISchoolDistrict,
  ISchoolDistrictParams,
} from "commons/types/schoolDistrict";
import {
  addSchoolDistrict,
  fetchSchoolDistricts,
  updateSchoolDistrict,
  deleteSchoolDistrict,
} from "store/actions/data/schoolDistricts";
import SchoolDistrictActionCell from "./schoolDistrictActionCell";

interface ISchoolDistrictProps {
  schoolDistricts: Array<ISchoolDistrict>;
  schoolDistrictsLoading: boolean;
  fetchSchoolDistricts: (
    params: ISchoolDistrictParams,
  ) => Promise<Array<ISchoolDistrict>>;
  addSchoolDistrict: (
    schoolDistrict: ISchoolDistrict,
  ) => Promise<ISchoolDistrict>;
  deleteSchoolDistrict: (id: string) => Promise<void>;
  updateSchoolDistrict: (
    id: string,
    schoolDistrict: ISchoolDistrict,
  ) => Promise<void>;
}

const SchoolDistrict: React.FC<ISchoolDistrictProps> = ({
  schoolDistricts,
  addSchoolDistrict,
  fetchSchoolDistricts,
  schoolDistrictsLoading,
  deleteSchoolDistrict,
  updateSchoolDistrict,
}) => {
  const [addSchoolDistrictOpen, setAddSchoolDistrictOpen] =
    React.useState<boolean>(false);

  const handleAddSchoolDistrict = async (schoolDistrict: ISchoolDistrict) => {
    const addedSchoolDistrict = await addSchoolDistrict({
      name: schoolDistrict.name,
    });
    setAddSchoolDistrictOpen(false);

    if (addedSchoolDistrict) {
      fetchSchoolDistricts({});
    }
  };

  const handleUpdateSchoolDistrict = React.useCallback(
    async (id: string, schoolDistrict: ISchoolDistrict) => {
      await updateSchoolDistrict(id, schoolDistrict);

      fetchSchoolDistricts({});
    },
    [updateSchoolDistrict, fetchSchoolDistricts],
  );

  /**
   * Deleting school district will delete from school district list, but existing student data will contain the old school district
   */
  const handleDeleteSchoolDistrict = React.useCallback(
    async (id: string) => {
      await deleteSchoolDistrict(id);

      fetchSchoolDistricts({});
    },
    [deleteSchoolDistrict, fetchSchoolDistricts],
  );

  React.useEffect(() => {
    fetchSchoolDistricts({});
  }, [fetchSchoolDistricts]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ value }: { value: string }) => {
            return value;
          },
        },
        {
          Header: "Actions",
          accessor: "_id",
          Cell: ({ row }: any) => {
            return (
              <SchoolDistrictActionCell
                row={row}
                handleUpdateSchoolDistrict={handleUpdateSchoolDistrict}
                handleDeleteSchoolDistrict={handleDeleteSchoolDistrict}
              />
            );
          },
        },
      ] as Column<ISchoolDistrict>[],
    [handleDeleteSchoolDistrict, handleUpdateSchoolDistrict],
  );

  const tableInstance = useTable({ columns, data: schoolDistricts });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (schoolDistrictsLoading) {
    return <Loader type="ThreeDots" />;
  }

  if (schoolDistricts && schoolDistricts.length < 1) {
    return (
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <SettingsPanel activeTab="SchoolDistrict" />
            <div className="content-panel">
              <h1>School Districts</h1>
              <EmptyState
                image={SchoolImage}
                title="You currently do not have any School Districts."
                description=""
                alt="school"
              >
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setAddSchoolDistrictOpen(true)}
                >
                  Add School District
                </button>
              </EmptyState>
            </div>
          </div>
        </div>
        <Modal
          className="modal-block"
          isOpen={addSchoolDistrictOpen}
          ariaHideApp={false}
          onRequestClose={() => setAddSchoolDistrictOpen(false)}
        >
          <AddSchoolDistrict
            closeModal={() => setAddSchoolDistrictOpen(false)}
            saveSchoolDistrict={handleAddSchoolDistrict}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="SchoolDistrict" />
          <div className="content-panel">
            <div className="page-heading">
              <h1>School Districts</h1>
              <div className="page-heading__right align-items-center">
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setAddSchoolDistrictOpen(true)}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table {...getTableProps()} className="table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="head-row"
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="table__col">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="table__row">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="table__col">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="right-gap" />
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={addSchoolDistrictOpen}
        ariaHideApp={false}
        onRequestClose={() => setAddSchoolDistrictOpen(false)}
      >
        <AddSchoolDistrict
          closeModal={() => setAddSchoolDistrictOpen(false)}
          saveSchoolDistrict={handleAddSchoolDistrict}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  schoolDistricts: state.schoolDistricts.fetchSchoolDistricts,
  schoolDistrictsLoading: state.schoolDistricts.fetchSchoolDistrictsLoading,
});

const mapDispatchToProps = {
  addSchoolDistrict,
  fetchSchoolDistricts,
  deleteSchoolDistrict,
  updateSchoolDistrict,
};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolDistrict);
