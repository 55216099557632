import axios from "axios";
import config from "../configs";
import { http } from "utils/http";
import pinterpolate from "pinterpolate";
import { IMentorshipSchedule } from "commons/types/mentorshipSchedule";
import { subdomain } from "../utils/http";
import * as usersService from "services/users";

// NOTE: This api will be called before new token set to the localstore.
//Thats why We are not using https.get here because it has interceptors
// which will use old token.
export const getProfile = async (token: string) => {
  const newAxios = axios.create({
    baseURL: config.serverBaseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant-id": subdomain,
    },
  });
  const { data } = await newAxios.get(config.endpoints.users.profile);

  return data;
};

export const updateAvailabilityStatus = async (id: string, payload: any) => {
  const url = pinterpolate(config.endpoints.users.availability, { id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const getAvailabilityStatus = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.availability, { id });
  const { data } = await http.get(url);
  return data;
};

export const sendWelcomeEmail = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.welcome, { id });
  const { data } = await http.get(url);

  return data;
};

export const sendPasswordReset = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.reset, {
    id,
  });
  const { data } = await http.post(url, {});
  return data;
};

export const addAvatar = async (id: string, file: any) => {
  const avatar = new FormData();
  avatar.append("file", file);
  const url = pinterpolate(config.endpoints.users.avatar, { id });
  const { data } = await http.patch(url, avatar);
  return data;
};

export const removeAvatar = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.avatar, { id });
  const { data } = await http.delete(url);
  return data;
};

export const changeMentorShipSchedule = async (
  id: string,
  payload: IMentorshipSchedule,
) => {
  const url = pinterpolate(config.endpoints.users.schedule, { id });
  const { data } = await http.put(url, payload);
  return data;
};

export const fetchBasicInfoByAuth0Id = async (auth0Id: string) => {
  const url = pinterpolate(config.endpoints.users.basicInfoByAuth0Id, {
    auth0Id: auth0Id,
  });
  const { data } = await http.get(url);

  return data;
};

export const updateUserEmail = async (userId: string, newEmail: string) => {
  const url = `/users/${userId}`;

  const payload = {
    email: newEmail,
  };

  const { data } = await http.patch(url, payload);

  return data;
};
