import "boxicons";
import videocallpic from "assets/images/videocall-pic.jpg";

const DesignKitPage = () => {
  return (
    <div className="page-content">
      <div className="container design-kit-container">
        <div className="design-kit">Design Kit </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Colors</h3>
          </div>
          <div className="kit-content">
            <ul>
              <li>
                <div className="color">
                  <div className="swatch swatch-01"></div>
                  <div className="title">Brand</div>
                  <div className="hex-value">#2CABE2</div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="color">
                  <div className="swatch swatch-02"></div>
                  <div className="title">Secondary/Red</div>
                  <div className="hex-value">#FF1154</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-03"></div>
                  <div className="title">Secondary/Green</div>
                  <div className="hex-value">#39D300</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-04"></div>
                  <div className="title">Secondary/Gold</div>
                  <div className="hex-value">#F9C339</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-05"></div>
                  <div className="title">Secondary/Link</div>
                  <div className="hex-value">#1D70B8</div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="color">
                  <div className="swatch swatch-06"></div>
                  <div className="title">Grayscale/Black</div>
                  <div className="hex-value">#191919</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-07"></div>
                  <div className="title">Grayscale/DarkGray</div>
                  <div className="hex-value">#666666</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-08"></div>
                  <div className="title">Grayscale/LightGray</div>
                  <div className="hex-value">#959FAE</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-09"></div>
                  <div className="title">Grayscale/White</div>
                  <div className="hex-value">#FFFFFF</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-10"></div>
                  <div className="title">Grayscale/Border</div>
                  <div className="hex-value">#E2E1DF</div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="color">
                  <div className="swatch swatch-11"></div>
                  <div className="title">Background/Gray</div>
                  <div className="hex-value">#F3F2EF</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-12"></div>
                  <div className="title">Background/Blue</div>
                  <div className="hex-value">#E6F0FD</div>
                </div>
              </li>
              <li>
                <div className="color">
                  <div className="swatch swatch-13"></div>
                  <div className="title">Background/Green</div>
                  <div className="hex-value">#E8F8E2</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Typography</h3>
          </div>
          <div className="kit-content">
            <ul className="d-block">
              <li>
                <h1>Heading 1 (Bold/text/heading-size24)</h1>
                <p></p>
              </li>
              <li>
                <h2>Heading 2 (Bold/text/large-size18)</h2>
              </li>
              <li>
                <h3>Heading 3 (Bold/text/body-size16)</h3>
              </li>
              <li>
                <h4>Heading 4 (Bold/text/small-size13)</h4>
              </li>
              <li>
                <p className="text-large">
                  Paragraph regular large (Regular/text/large-size18)
                </p>
              </li>
              <li>
                <p className="text-regular">
                  Paragraph regular body (Regular/text/body-size16)
                </p>
              </li>
              <li>
                <p className="text-small">
                  Paragraph regular small (Regular/text/small-size13)
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Navigation</h3>
          </div>
          <div className="kit-content">
            <ul className="nav">
              <li className="nav__node">
                <span className="link-item nav__link--active">
                  Navigation menu
                </span>
              </li>
              <li className="nav__node">
                <span className="link-item nav__link">Navigation menu</span>
              </li>
              <li className="nav__node">
                <span className="link-item nav__link">Navigation menu</span>
              </li>
              <li className="nav__node">
                <span className="link-item nav__link">Navigation menu</span>
              </li>
              <li className="nav__node">
                <span className="link-item nav__link">Navigation menu</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Form elements</h3>
          </div>
          <div className="kit-content">
            <ul>
              <li>
                <div className="input-wrap">
                  <label className="input__label">Resting label</label>
                  <input
                    type="text"
                    placeholder="Resting placeholder"
                    className="input"
                  />
                </div>
              </li>
              <li>
                <div className="input-wrap">
                  <label className="input__label">Error label</label>
                  <input
                    type="text"
                    placeholder="Resting placeholder"
                    className="input input--error"
                  />
                  <p className="input__error">
                    There seems to be some error with this.
                  </p>
                </div>
              </li>
              <li>
                <div className="input-wrap">
                  <label className="input__label">Resting label</label>
                  <input
                    type="text"
                    placeholder="Resting placeholder"
                    className="input"
                  />
                  <p className="input__helper">Input with some description</p>
                </div>
              </li>
            </ul>
            <ul className="d-block">
              <li>
                <div className="input-wrap input-wrap--icon-left">
                  <input
                    type="text"
                    placeholder="Search user by name or email address"
                    className="input"
                  />
                  <span className="form-icon">
                    <box-icon
                      name="search"
                      size="sm"
                      color="#959FAE"
                    ></box-icon>
                  </span>
                </div>
              </li>
              <li>
                <div className="input-wrap input-wrap--icon-right">
                  <input
                    type="text"
                    placeholder="Resting placeholder"
                    className="input"
                  />
                  <span className="form-icon">
                    <box-icon
                      name="search"
                      size="sm"
                      color="#959FAE"
                    ></box-icon>
                  </span>
                </div>
              </li>
            </ul>
            <div className="input-wrap">
              <input
                type="radio"
                id="radio1"
                name="hello"
                className="box-links__nodes"
              />
              <label htmlFor="radio1" className="input__label">
                Radio 1
              </label>
            </div>
            <div className="input-wrap">
              <input
                type="radio"
                id="radio2"
                name="hello"
                className="box-links__nodes"
              />
              <label htmlFor="radio2" className="input__label">
                Radio 2
              </label>
            </div>
            <div className="input-wrap">
              <input
                type="checkbox"
                name="checkbox"
                value="Checkbox Inline 2"
                id="check2"
                className="form-group__radio mr-10"
              />
              <label htmlFor="check2" className="box-links__nodes">
                Checkbox Inline 2
              </label>
            </div>
            <div className="switch-wrap">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Buttons</h3>
          </div>
          <div className="kit-content">
            <ul>
              <li>
                <button className="btn btn--primary mr-2x" type="button">
                  Primary Button
                </button>
              </li>
              <li>
                <button className="btn btn--outlined-primary">
                  Primary Outline Button
                </button>
              </li>
              <li>
                <button className="btn btn--primary" disabled>
                  Primary Disabled Button
                </button>
              </li>
              <li>
                <button
                  className="btn btn--primary btn__icon btn__icon--right mr-2x"
                  type="button"
                >
                  Primary Button with icon <box-icon name="printer"></box-icon>
                </button>
              </li>
            </ul>
            <ul>
              <li>
                <button className="btn btn--secondary mr-2x" type="button">
                  Secondary button
                </button>
              </li>
              <li>
                <button className="btn btn--outlined-secondary">
                  Secondary Outline Button
                </button>
              </li>
              <li>
                <button className="btn btn--secondary" disabled>
                  Secondary Disabled Button
                </button>
              </li>
              <li>
                <button
                  className="btn btn--secondary btn__icon btn__icon--left mr-2x"
                  type="button"
                >
                  <box-icon name="printer"></box-icon>
                  Secondary Button with icon
                </button>
              </li>
            </ul>
            <ul>
              <li>
                <button
                  className="btn btn--green btn--small mr-2x"
                  type="button"
                >
                  Green Button
                </button>
              </li>
              <li>
                <button className="btn btn--outlined-green btn--small">
                  Green Outline Button
                </button>
              </li>
              <li>
                <button className="btn btn--green btn--small" disabled>
                  Green Disabled Button
                </button>
              </li>
              <li>
                <button
                  className="btn btn--red btn--small btn__icon btn__icon--left mr-2x"
                  type="button"
                >
                  <box-icon name="printer"></box-icon>
                  Red Button with icon
                </button>
              </li>
            </ul>
            <ul>
              <li>
                <button className="btn btn--grey mr-2x" type="button">
                  Gray Button
                </button>
              </li>
              <li>
                <button className="btn btn--outlined-grey">
                  Gray Outline Button
                </button>
              </li>
              <li>
                <button className="btn btn--grey" disabled>
                  Grey Disabled Button
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Video call</h3>
          </div>
          <div className="kit-content">
            <div className="video-call">
              <div className="video-call__header">
                <div className="controls">
                  <button className="btn btn__with-icon btn--red">
                    <box-icon name="x"></box-icon>
                  </button>
                  <button className="btn btn__with-icon btn--yellow">
                    <box-icon name="minus"></box-icon>
                  </button>
                  <button className="btn btn__with-icon btn--green">
                    <box-icon name="expand-alt"></box-icon>
                  </button>
                </div>
              </div>
              <div className="video-call__body">
                <div className="user">
                  <div className="user-info">
                    <div className="user-content">
                      <span className="volume">
                        <box-icon name="volume-full"></box-icon>
                      </span>
                      <span className="name">Matt Rawson</span>
                    </div>
                  </div>
                  <img src={videocallpic} alt="logo" />
                </div>
                <div className="user">
                  <div className="user-info">
                    <div className="user-content">
                      <span className="volume">
                        <box-icon name="volume-full"></box-icon>
                      </span>
                      <span className="name">Matt Rawson</span>
                    </div>
                  </div>
                  <img src={videocallpic} alt="logo" />
                </div>
              </div>
              <div className="video-call__footer">
                <div className="button-group">
                  <button className="btn btn__with-icon btn--outlined-grey">
                    <box-icon name="microphone-off"></box-icon>
                  </button>
                  <button className="btn btn__with-icon btn--red">
                    <box-icon name="phone-call"></box-icon>
                  </button>
                  <button className="btn btn__with-icon btn--green">
                    <box-icon name="video" animation="tada-hover"></box-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kit-block">
          <div className="kit-title">
            <h3>Confirmation dialog box</h3>
          </div>
          <div className="kit-content">
            <div className="modal-wrap confirm confirm--error">
              <div className="modal-wrap__body">
                <box-icon name="error-alt"></box-icon>
                <h3>Do you want to delete this goal? </h3>
                <p>
                  Deleting a goal will not delete the tasks. This action is not
                  reversible.
                </p>
              </div>
              <div className="modal-wrap__footer">
                <div className="row">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn--red btn--small mr-4x"
                      type="button"
                    >
                      Delete
                    </button>
                    <button className="btn btn--text btn--small" type="button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-wrap confirm confirm--success">
              <div className="modal-wrap__body">
                <box-icon name="check-circle" type="solid"></box-icon>
                <h3>Task marked as completed</h3>
                <p>
                  You have marked this task as completed for Samantha Doe. If
                  you want to see it later, find it by going to the plan for the
                  corresponding week.
                </p>
              </div>
              <div className="modal-wrap__footer">
                <div className="row">
                  <div className="col-12 d-flex">
                    <button className="btn btn--text btn--small" type="button">
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kit-block">
          <div className="kit-title">
            <h3>Switch</h3>
          </div>
          <div className="kit-content">
            <label className="toggle">
              <span className="toggle-label">Included in Report</span>
              <input className="toggle-checkbox" type="checkbox" />
              <div className="toggle-switch"></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignKitPage;
