import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

const SatelliteLocationSchema: Yup.ObjectSchema<ObjectShape> =
  Yup.object().shape({
    address: Yup.string()
      .trim()
      .max(TITLE_LENGTH, `Address must be less tha ${TITLE_LENGTH} characters`)
      .required("Please enter address"),
    location: Yup.string().trim().required("Please select center"),
  });

export default SatelliteLocationSchema;
