import qs from "qs";
import axios from "axios";

import config from "configs";
import { getDataFromLocalStorage } from "services/localStorage";
import i18next from "i18next";
import store from "../store";
import { FetchProfileActionType } from "store/actions/types/profile";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

let tempSubdomain = window.location.hostname.split(".")[1]
  ? window.location.host.split(".")[0]
  : "";

const isLocalhost = window.location.hostname === "localhost";

if (isLocalhost) {
  tempSubdomain = "taslima";
  // tempSubdomain = "admin";
}

export const subdomain = tempSubdomain;
export const subdomainName = capitalizeFirstLetter(subdomain);
export const isAdmin = subdomain === "admin";

export const getAccessToken = () => {
  return getDataFromLocalStorage("profile")?.authToken;
};

export const http = axios.create({
  baseURL: config.serverBaseUrl,
  headers: {
    "Content-Type": "application/json",
    "x-tenant-id": subdomain,
  },
});

/**
 * Adds request interceptor
 * This interceptor adds Authorization header in each request if the access token is available.
 * @return Request Object
 */
http.interceptors.request.use((request: any) => {
  if (getAccessToken()) {
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }

  request.headers["x-custom-lang"] =
    i18next.language?.toLocaleLowerCase() || "en";

  return request;
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 403 &&
      error.response.data?.isActive === false
    ) {
      store.dispatch({
        type: FetchProfileActionType.MENTOR_INACTIVE_ERROR_MESSAGE,
        data: error.response.data.message,
      });
    }
  },
);

export const stringify = (route: string, params: Object | null) => {
  if (
    params === null ||
    !(params instanceof Object) ||
    Object.keys(params).length < 1
  )
    return route;

  return `${route}?${qs.stringify(params)}`;
};

/**
 * Parse query string to JSON
 * @param search
 * @param params
 */
export const parse = (search: string) => {
  if (!search) return {};
  const query = search[0] === "?" ? search.slice(1) : search;

  return qs.parse(query);
};
