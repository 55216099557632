import "boxicons";
import classNames from "classnames";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";
import Select, { components, OptionsType } from "react-select";
import { connect, RootStateOrAny } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";

import config from "configs";
import { error } from "utils/toast";
import { ILocation } from "commons/types";
import { getFullName } from "utils/names";
import { downloadFile } from "utils/files";
import { ITerm } from "commons/types/terms";
import { Params } from "commons/types/urls";
import { fetchAll } from "services/students";
import Loader from "commons/components/Loader";
import ListStudentsTable from "./StudentsTable";
import { DEBOUNCE_TIME } from "constants/index";
import { fetchLocations } from "services/tenant";
import { STUDENT_STATUS } from "constants/student";
import { TERM_STATUS_OPTIONS } from "constants/term";
import { fetchTerms } from "store/actions/data/terms";
import { IUser, PagedIUser } from "commons/types/users";
import { getDateInFormat, getDayFromDate } from "utils/dates";
import {
  setTermInFilter,
  resetTermInFilter,
  fetchPagedStudents,
  saveStudentFilters,
  resetStudentFilters,
  setTermStatusInFilter,
  setUserStatusInFilter,
  resetTermStatusInFilter,
  resetUserStatusInFilter,
  setLearningCenterInFilter,
  resetLearningCenterInFilter,
  saveStudentsPagination,
  resetStudentsPagination,
} from "store/actions/data/students";
import { subdomainName } from "utils/http";

interface IStudentProps {
  students: PagedIUser;
  terms: ITerm[];
  fetchTerms: () => {};
  fetchStudentsLoading: boolean;
  newFilters: IFilter;
  fetchPagedStudents: (params?: Params) => Promise<PagedIUser>;
  saveStudentFilters: (filters?: IFilter) => {};
  resetStudentFilters: () => void;
  setLearningCenterInFilter: (learningCenter: Array<string>) => void;
  resetLearningCenterInFilter: () => void;
  termFilter: Array<string>;
  learningCenterFilter: string[];
  setTermInFilter: (terms: Array<string>) => void;
  resetTermInFilter: () => void;
  setTermStatusInFilter: (termStatus: Array<string>) => void;
  resetTermStatusInFilter: () => void;
  termStatusFilter: Array<string>;
  userStatusFilter: Array<string>;
  resetUserStatusInFilter: () => void;
  setUserStatusInFilter: (userStatus: Array<string>) => void;
  saveStudentsPagination: (offset: number) => void;
  resetStudentsPagination: () => void;
  studentsListOffset: number;
}

interface IFilter {
  learningCenter?: string[];
  term?: string[];
  termStatus?: string[];
  userStatus?: string[];
}

interface IFilterString {
  learningCenter?: string;
  term?: string;
  termStatus?: string;
  userStatus?: string;
  enrollmentWise: boolean;
}

interface LabelValue {
  label: string;
  value: string;
}

const styles = {
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    width: "92%",
    justifyContent: "space-between",
  }),
  control: (provided: any) => ({
    ...provided,
    maxHeight: "100px",
    overflowY: "auto",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "0px",
    margin: "0px",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: "0px",
    margin: "0px",
  }),
  input: (provided: any) => ({
    ...provided,
    width: "1px",
    display: "inline",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    display: "flex",
    padding: "0px 3px 0px 0px",
    justifyContent: "center",
    flexDirection: "column",
  }),
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.label}</label>
    </components.Option>
  );
};

const ListStudents: React.FC<IStudentProps> = ({
  students,
  terms,
  fetchPagedStudents,
  fetchTerms,
  fetchStudentsLoading = true,
  newFilters,
  resetStudentFilters,
  learningCenterFilter,
  setLearningCenterInFilter,
  resetLearningCenterInFilter,
  setTermInFilter,
  resetTermInFilter,
  setTermStatusInFilter,
  termFilter,
  termStatusFilter,
  userStatusFilter,
  setUserStatusInFilter,
  resetTermStatusInFilter,
  resetUserStatusInFilter,
  saveStudentsPagination,
  resetStudentsPagination,
  studentsListOffset,
}): JSX.Element => {
  const history = useHistory();
  const [institutionLocations, setInstitutionLocation] = useState<ILocation[]>(
    [],
  );
  const paginationLimit = 20;

  const [search, setSearch] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  const studentPage = students.page;

  /**
   * The function below returns the selected filters that needs to be applied while making api call.
   */

  const filtersToApply = (appliedFilters: IFilter = newFilters) => {
    let filterToApply: IFilterString = { enrollmentWise: true };
    Object.keys(appliedFilters).map((filterName: string) => {
      if (
        Object.prototype.hasOwnProperty.call(appliedFilters, filterName) &&
        ("learningCenter" === filterName ||
          "term" === filterName ||
          "termStatus" === filterName ||
          "userStatus" === filterName) &&
        appliedFilters[filterName] &&
        appliedFilters[filterName]?.length
      ) {
        filterToApply[filterName] = appliedFilters[filterName]?.toString();
      }
      return filterName;
    });

    return filterToApply;
  };

  const applyFilter = () => {
    fetchPagedStudents({
      q: search,
      offset: 1,
      limit: paginationLimit,
      ...filtersToApply(),
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyPreviousFilter = useCallback(
    debounce((search: string, newFilters: IFilter) => {
      fetchPagedStudents({
        q: search,
        offset: studentsListOffset,
        limit: paginationLimit,
        ...filtersToApply(newFilters),
      });
    }, DEBOUNCE_TIME),
    [],
  );

  const resetFilter = async () => {
    setSearch("");
    resetStudentFilters();
    resetStudentsPagination();
    fetchPagedStudents({
      q: search,
      offset: 1,
      limit: paginationLimit,
      enrollmentWise: true,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      fetchPagedStudents({
        q: searchValue,
        offset: studentsListOffset,
        limit: paginationLimit,
        ...filtersToApply(),
      });
    }, DEBOUNCE_TIME),
    [],
  );

  //Persistent UI filters

  useEffect(() => {
    if (Object.keys(newFilters).length !== 0) {
      applyPreviousFilter(search, newFilters);
    } else {
      debouncedSearch(search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, debouncedSearch]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const populateLocationDropDown = async () => {
        try {
          const { data } = await fetchLocations();
          setInstitutionLocation(data);
        } catch (ex) {
          error("Failed to fetch location");
        }
      };

      Promise.allSettled([populateLocationDropDown(), fetchTerms()]);
    };
    fetchDropdownOptions();
  }, [fetchTerms]);

  const institutionLocationOptions: LabelValue[] = institutionLocations.map(
    (record: ILocation) => ({
      label: record.name || "-",
      value: record._id,
    }),
  );

  const termOptions: LabelValue[] = terms.map((record: ITerm) => ({
    label: record.name,
    value: record._id,
  }));

  const loadPreviousPage = () => {
    if (studentPage.currentPage > 1 && !fetchStudentsLoading) {
      saveStudentsPagination(studentPage.currentPage - 1);
      fetchPagedStudents({
        q: search,
        offset: studentPage.currentPage - 1,
        limit: paginationLimit,
        ...filtersToApply(),
      });
    }
  };

  const loadNextPage = () => {
    if (
      studentPage.currentPage < studentPage.totalPage &&
      !fetchStudentsLoading
    ) {
      saveStudentsPagination(studentPage.currentPage + 1);
      fetchPagedStudents({
        q: search,
        offset: studentPage.currentPage + 1,
        limit: paginationLimit,
        ...filtersToApply(),
      });
    }
  };

  const getPageInformation = () => {
    if (studentPage.currentPage === 0) {
      return "0 - 0 of 0";
    }
    if (studentPage.currentPage === 1) {
      return `1 - ${studentPage.itemCountInPage} of ${studentPage.totalItem}`;
    }
    if (studentPage.currentPage === studentPage.totalPage)
      return `${(studentPage.currentPage - 1) * paginationLimit + 1} - ${
        studentPage.totalItem
      } of ${studentPage.totalItem}`;

    return `${(studentPage.currentPage - 1) * paginationLimit + 1} - ${
      (studentPage.currentPage - 1) * paginationLimit +
      studentPage.itemCountInPage
    } of ${studentPage.totalItem}`;
  };

  /**
   * This will only generate required headers for the CSV.
   */
  const generateCsvHeaders = () => {
    return [
      "Student's Last Name",
      "Student's First Name",
      "Student Status",
      "Date Of Birth",
      "Center",
      "Current Mentor",
      "Program",
      "Terms Enrolled",
      "Learning Environment",
      "Mentorship Start Date",
      "Current Weekly Planning Period Day",
      "School / University / 2 Year College",
      "School District",
      "Name of School",
      "Current Grade",
      "Student Diagnosis",
      "Accommodation(s)",
      "On Academic Probation",
      "Drop in progress reason",
    ].join(",");
  };

  /**
   * This will generate remaining rows of data for the CSV
   */
  const generateCsvContents = (students: IUser[], csvStudents: any) => {
    students.forEach((student) => {
      let mentorNames: string = "";
      student.userData?.enrollment?.practitioners?.forEach(
        ({ practitioner }: { practitioner: IUser }) => {
          if (mentorNames) {
            mentorNames = `${mentorNames}, ${getFullName(practitioner)}`;
          } else {
            mentorNames = getFullName(practitioner);
          }
        },
      );

      csvStudents.push(
        [
          JSON.stringify(student.lastName),
          JSON.stringify(student.firstName),
          JSON.stringify(student.userData?.status),
          student.dateOfBirth
            ? JSON.stringify(getDateInFormat(new Date(student.dateOfBirth)))
            : null,
          JSON.stringify(student.userData?.enrollment?.learningCenter?.name),
          JSON.stringify(mentorNames),
          JSON.stringify(
            student.userData?.enrollment?.mentorshipSchedule?.latest?.program
              ?.name,
          ),
          JSON.stringify(student.userData?.enrollment?.enrolledTerm?.name),
          JSON.stringify(student.userData?.learningEnvironment),
          student.userData?.enrollment?.mentorshipSchedule?.latest?.day
            ? JSON.stringify(
                getDateInFormat(
                  new Date(
                    student.userData?.enrollment?.mentorshipSchedule?.latest?.day,
                  ),
                ),
              )
            : null,
          student.userData?.enrollment?.mentorshipSchedule?.latest?.day
            ? JSON.stringify(
                getDayFromDate(
                  student.userData?.enrollment?.mentorshipSchedule?.latest?.day,
                ),
              )
            : null,
          JSON.stringify(student.userData?.schoolInfo?.schoolType),
          JSON.stringify(student.userData?.schoolInfo?.schoolDistrict?.name),
          JSON.stringify(student.userData?.schoolInfo?.institution?.name),
          JSON.stringify(student.userData?.schoolInfo?.grade),
          JSON.stringify(student.userData?.diagnosis),
          JSON.stringify(student.userData?.schoolInfo?.accommodation?.name),
          JSON.stringify(student.userData?.schoolInfo?.isOnAcademicProbation),
          JSON.stringify(student.userData?.enrollment?.dropMessage),
        ].join(","),
      );
    });

    return csvStudents.join("\n");
  };

  /**
   * Returns csv data of students
   */
  const getStudentsCSV = (students: IUser[]) => {
    let csvStudentsHeader: any = [generateCsvHeaders()];

    return generateCsvContents(students, csvStudentsHeader);
  };

  /**
   * Downloads student data as csv
   */
  const downloadStudents = async () => {
    setIsDownloading(true);

    const students: IUser[] = await fetchAll({
      q: search,
      ...filtersToApply(),
    });

    const csvStudents = getStudentsCSV(students);

    downloadFile(
      [csvStudents],
      `${subdomainName}_Student_Enrollment__${getDateInFormat(new Date())}.csv`,
      "text/csv",
    );

    setIsDownloading(false);
  };

  const onLearningCenterChange = async (
    allSelected: OptionsType<LabelValue>,
  ) => {
    if (allSelected.length) {
      setLearningCenterInFilter(allSelected.map((selected) => selected.value));
    } else {
      await resetLearningCenterInFilter();
      fetchPagedStudents({
        q: search,
        offset: 1,
        limit: paginationLimit,
        ...filtersToApply(),
        learningCenter: undefined,
      });
    }
  };

  const onTermChange = async (allSelected: OptionsType<LabelValue>) => {
    if (allSelected.length) {
      setTermInFilter(allSelected.map((selected) => selected.value));
    } else {
      await resetTermInFilter();
      fetchPagedStudents({
        q: search,
        offset: 1,
        limit: paginationLimit,
        ...filtersToApply(),
        term: undefined,
      });
    }
  };

  const onTermStatusChange = async (allSelected: OptionsType<LabelValue>) => {
    if (allSelected.length) {
      setTermStatusInFilter(allSelected.map((selected) => selected.value));
    } else {
      await resetTermStatusInFilter();
      fetchPagedStudents({
        q: search,
        offset: 1,
        limit: paginationLimit,
        ...filtersToApply(),
        termStatus: undefined,
      });
    }
  };

  const onStudentStatusChange = async (
    allSelected: OptionsType<LabelValue>,
  ) => {
    if (allSelected.length) {
      setUserStatusInFilter(allSelected.map((selected) => selected.value));
    } else {
      await resetUserStatusInFilter();
      fetchPagedStudents({
        q: search,
        offset: 1,
        limit: paginationLimit,
        ...filtersToApply(),
        userStatus: undefined,
      });
    }
  };

  return (
    <div className="content-wrap pt-8x pb-8x">
      <div className="container-fluid">
        <div className="page-heading">
          <h2>Students</h2>
          <div className="page-heading__right">
            <div className="filter-group align-items-center">
              <button
                className="btn btn--primary"
                type="button"
                onClick={() => {
                  history.push(config.uiPath.students.add);
                }}
              >
                Add Student
              </button>
            </div>
            <div className="filter-group align-items-center">
              <button
                className="btn ml-4x btn--outlined-primary"
                type="button"
                disabled={isDownloading}
                onClick={downloadStudents}
              >
                <p className="mr-2x">Download</p>
                <box-icon name="cloud-download" color="#1d70b8" />
              </button>
            </div>
          </div>
        </div>
        <form onReset={resetFilter} onSubmit={(e) => e.preventDefault()}>
          <div className="filter-bar p-4x">
            <div className="filter-bar__first">
              <label className={`input__label`}>Search</label>
              <div className="input-wrap--icon-right">
                <input
                  type="text"
                  placeholder="Search a student"
                  value={search}
                  className="input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(e.target.value);
                  }}
                />
                <span className="form-icon mr-1x">
                  <box-icon name="search" size="sm" color="#959FAE" />
                </span>
              </div>
            </div>
            <div className="filter-bar__item">
              <label className={`input__label`}>Location</label>
              <Select
                name="learningCenter"
                placeholder="All Locations"
                classNamePrefix="react-select"
                isMulti={true}
                styles={styles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={onLearningCenterChange}
                value={institutionLocationOptions.filter((learningCenter) =>
                  learningCenterFilter?.includes(learningCenter.value),
                )}
                allowSelectAll={true}
                components={{ Option }}
                options={institutionLocationOptions}
              />
            </div>
            <div className="filter-bar__item">
              <label className={`input__label`}>Term</label>
              <Select
                name="term"
                placeholder="All Terms"
                options={termOptions}
                classNamePrefix="react-select"
                isMulti={true}
                styles={styles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={onTermChange}
                value={termOptions.filter((term) =>
                  termFilter?.includes(term.value),
                )}
                allowSelectAll={true}
                components={{ Option }}
              />
            </div>
            <div className="filter-bar__item">
              <label className={`input__label`}>Term Status</label>
              <Select
                name="termStatus"
                placeholder="All Term Status"
                classNamePrefix="react-select"
                options={TERM_STATUS_OPTIONS}
                isMulti={true}
                styles={styles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={onTermStatusChange}
                value={TERM_STATUS_OPTIONS.filter((termStatus) =>
                  termStatusFilter?.includes(termStatus.value),
                )}
                allowSelectAll={true}
                components={{ Option }}
              />
            </div>
            <div className="filter-bar__item">
              <label className={`input__label`}>Student Status</label>
              <Select
                name="studentStatus"
                placeholder="All Student Status"
                options={STUDENT_STATUS}
                classNamePrefix="react-select"
                isMulti={true}
                styles={styles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={onStudentStatusChange}
                value={STUDENT_STATUS.filter((status) =>
                  userStatusFilter?.includes(status.value),
                )}
                allowSelectAll={true}
                components={{ Option }}
              />
            </div>
            <div className="filter-bar__item">
              <div className="action-wrapper">
                <div className="action-container">
                  <button
                    className="btn btn--outlined-primary"
                    type="submit"
                    onClick={applyFilter}
                  >
                    Apply Filter
                  </button>
                  <input
                    type="reset"
                    className="btn btn-text txt-primary-color"
                    value="Reset"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="page-control-bar">
          <div className="text-wrapper">
            <h4>{getPageInformation()}</h4>
          </div>
          <div className="action-group">
            <div
              className={classNames({
                "button-wrapper--active": studentPage.currentPage > 1,
                "button-wrapper--disabled": studentPage.currentPage <= 1,
              })}
            >
              <span className="mr-2x link-item">
                <box-icon name="chevron-left" onClick={loadPreviousPage} />
              </span>
            </div>
            <div
              className={classNames({
                "button-wrapper--active":
                  studentPage.currentPage < studentPage.totalPage,
                "button-wrapper--disabled":
                  studentPage.currentPage >= studentPage.totalPage,
              })}
            >
              <span className="mr-2x link-item">
                <box-icon name="chevron-right" onClick={loadNextPage} />
              </span>
            </div>
          </div>
        </div>

        {fetchStudentsLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <ListStudentsTable students={students.data} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  terms: state.terms.fetchTerms,
  students: state.students.fetchPagedStudents,
  fetchStudentsLoading: state.students.fetchPagedStudentsLoading,
  newFilters: state.students.studentFilters,
  studentsListOffset: state.students.studentsListOffset,
  learningCenterFilter: state.students.studentFilters.learningCenter,
  termFilter: state.students.studentFilters.term,
  termStatusFilter: state.students.studentFilters.termStatus,
  userStatusFilter: state.students.studentFilters.userStatus,
});

const mapDispatchToProps = {
  fetchTerms,
  setTermInFilter,
  resetTermInFilter,
  fetchPagedStudents,
  saveStudentFilters,
  resetStudentFilters,
  setTermStatusInFilter,
  resetTermStatusInFilter,
  setLearningCenterInFilter,
  resetLearningCenterInFilter,
  setUserStatusInFilter,
  resetUserStatusInFilter,
  saveStudentsPagination,
  resetStudentsPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListStudents);
