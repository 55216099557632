import React from "react";

import Form from "../Form";
import { IAddAcademicYearProps } from "./addAcademicYear.interface";

const AddAcademicYear: React.FC<IAddAcademicYearProps> = ({
  closeModal,
  saveAcademicYear,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add an Academic Year</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={{
          _id: "",
          year: "",
        }}
        closeModal={closeModal}
        saveAcademicYear={saveAcademicYear}
      />
    </div>
  );
};

export default AddAcademicYear;
