import { CSVLink } from "react-csv";
import AsyncSelect from "react-select/async";
import React, { useEffect, useState } from "react";
import { connect, RootStateOrAny } from "react-redux";
import Select from "react-select";

import { error, info, success } from "utils/toast";
import { selectBoxStyle } from "./surveyDownload.constants";
import {
  downloadAggregatedSurveyResponses,
  generateFileName,
} from "./surveyDownload.service";
import {
  DownloadSurveyData,
  DownloadSurveyParams,
  SelectOption,
  ISurveyFileNameParams,
} from "./surveyDownload.types";

interface IAggregatedSurveyDownloadProps {
  fetchTermsLoading: boolean;
  fetchSurveyCategoriesLoading: boolean;
  surveyCategoriesOptions: Array<SelectOption>;
  termOptions: Array<SelectOption>;
  loadStudentOptions: (
    inputValue: string,
    callback: (options: ReadonlyArray<SelectOption>) => void,
  ) => void;
}

const AggregatedSurveyDownload = (props: IAggregatedSurveyDownloadProps) => {
  const { surveyCategoriesOptions, termOptions, loadStudentOptions } = props;

  const csvLinkDownloadSurveyRef = React.useRef(null);

  const [surveyCategory, setSurveyCategory] = useState<SelectOption | null>(
    null,
  );
  const [term, setTerm] = useState<SelectOption | null>(null);
  const [selectedStudentOption, setSelectedStudentOption] =
    useState<SelectOption | null>(null);

  const [isDownloading, setIsDownloading] = useState(false);
  const [csvDownloadData, setCsvDownloadData] = useState<DownloadSurveyData>();

  useEffect(() => {
    if (!csvDownloadData) {
      return;
    }

    // Click on CSVLink component
    // @ts-ignore
    csvLinkDownloadSurveyRef?.current?.link.click();
    success("Aggregated survey responses downloaded");

    setCsvDownloadData(undefined);
  }, [csvDownloadData]);

  useEffect(() => {
    if (surveyCategoriesOptions && surveyCategoriesOptions.length > 0) {
      setSurveyCategory(surveyCategoriesOptions[0]);
    }
  }, [surveyCategoriesOptions]);

  const onDownloadClick = async () => {
    if (!surveyCategory) {
      return;
    }

    const surveyDownloadParams: DownloadSurveyParams = {
      surveyCategory: surveyCategory.value,
      term: term?.value,
      surveyFor: selectedStudentOption?.value || undefined,
    };

    setIsDownloading(true);

    try {
      const surveyDownloadData = await downloadAggregatedSurveyResponses(
        surveyDownloadParams,
      );

      if (surveyDownloadData.csvData.length <= 0) {
        info(
          "There are no aggregated survey responses for the selected options",
        );

        return;
      }
      setCsvDownloadData(surveyDownloadData);
    } catch (e) {
      error("Error downloading aggregated survey responses");
    } finally {
      setIsDownloading(false);
    }
  };

  const fileNameParams: ISurveyFileNameParams = {
    term: term?.label || "",
    student: selectedStudentOption?.label || "",
    isAggregatedSurvey: true,
  };

  return (
    <>
      <h3 className="mb-4x text-color-darkgray">Aggregated Survey Download</h3>

      <div className="filter-bar p-4x">
        <div className="filter-bar__item">
          <label className={`input__label`}>Survey Name</label>
          <Select
            name="surveyName"
            placeholder="Select Survey"
            classNamePrefix="react-select"
            styles={selectBoxStyle}
            isLoading={props.fetchSurveyCategoriesLoading}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={(option) => {
              if (option) setSurveyCategory(option);
            }}
            value={surveyCategoriesOptions.find(
              (option) => option.value === surveyCategory?.value,
            )}
            options={surveyCategoriesOptions}
          />
        </div>
        <div className="filter-bar__item">
          <label className={`input__label`}>Term</label>
          <Select
            name="term"
            placeholder="All Terms"
            options={termOptions}
            classNamePrefix="react-select"
            styles={selectBoxStyle}
            hideSelectedOptions={false}
            isLoading={props.fetchTermsLoading}
            value={term}
            onChange={setTerm}
            allowSelectAll={true}
            isClearable={true}
          />
        </div>
        <div className="filter-bar__item-wide">
          <label className={`input__label`}>Student</label>
          <AsyncSelect
            cacheOptions
            name="students"
            placeholder="All Students"
            classNamePrefix="react-select"
            styles={selectBoxStyle}
            hideSelectedOptions={false}
            isClearable={true}
            loadOptions={loadStudentOptions}
            noOptionsMessage={() => "Type to search"}
            onChange={(option) => setSelectedStudentOption(option)}
          />
        </div>
        <div className="filter-bar__item">
          <div className="action-wrapper">
            <div className="action-container">
              {isDownloading ? (
                <div className="align-content-center">
                  <button
                    className="btn btn--primary"
                    type="submit"
                    onClick={onDownloadClick}
                    disabled={true}
                  >
                    Downloading...
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn--primary"
                  type="submit"
                  onClick={onDownloadClick}
                  disabled={!surveyCategory}
                >
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <CSVLink
        headers={csvDownloadData?.csvHeaders}
        data={csvDownloadData?.csvData || []}
        filename={generateFileName(fileNameParams)}
        ref={csvLinkDownloadSurveyRef}
      />
    </>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  fetchTermsLoading: state.terms.fetchTermsLoading,
  fetchSurveyCategoriesLoading: state.surveys.fetchSurveyCategoriesLoading,
});

export default connect(mapStateToProps)(AggregatedSurveyDownload);
