import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";

import Loader from "commons/components/Loader";
import { AUTO_SAVE_DELAY } from "constants/index";
import { IGradeType } from "commons/types/gradeTypes";
import * as gradeTypeService from "services/gradeType";
import { TextInput } from "commons/components/form-fields";
import { IQuantitativeTypes } from "./quantitativeGrades.interface";
import { QuantitativeGradeTypeSchema } from "commons/schemas/gradeType";

const QuantitativeGrades: React.FC<IQuantitativeTypes> = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [activeTimeout, setActiveTimeout] = useState<any>(null);
  const [gradeType, setGradeType] = useState<IGradeType | never>();
  const [fetchingGradeType, setFetchingGradeType] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const formik: any = useFormik({
    initialValues: gradeType || {
      type: "Quantitative",
      name: "Quantitative Grade",
      data: {
        type: "Quantitative",
        percentage: {
          minimum: "",
          maximum: "",
        },
        gradeScale: {
          minimum: "",
          maximum: "",
        },
      },
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: QuantitativeGradeTypeSchema,
  });

  const fetchAndSetGradeType = useCallback(async () => {
    setFetchingGradeType(true);
    const { data } = await gradeTypeService.fetchAll({
      type: "Quantitative",
      order: 1,
    });
    data.length && setGradeType(data[0]);
    setFetchingGradeType(false);
  }, []);

  useEffect(() => {
    fetchAndSetGradeType();
  }, [fetchAndSetGradeType]);

  const saveCurrentState = useCallback(async () => {
    setIsSaving(true);
    if (gradeType?._id) {
      const { data } = await gradeTypeService.update(
        gradeType._id,
        formik.values,
      );
      if (data) {
        setGradeType(data);
      }
    } else {
      const { data } = await gradeTypeService.add(formik.values);
      if (data) {
        setGradeType(data);
      }
    }
    setIsSaving(false);
  }, [formik.values, gradeType?._id]);

  useEffect(() => {
    if (activeTimeout) {
      clearTimeout(activeTimeout);
    }
    if (hasUnsavedChanges && !isSaving) {
      setActiveTimeout(
        setTimeout(async () => {
          setActiveTimeout(null);
          await saveCurrentState();
        }, AUTO_SAVE_DELAY),
      );
    }
  }, [hasUnsavedChanges, saveCurrentState, isSaving]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!formik.touched || fetchingGradeType || gradeType === formik.values) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [gradeType, formik.values, fetchingGradeType, isSaving, formik.touched]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mt-10x mb-4x">
        <h3>
          Quantitative Grade
          {isSaving ? (
            <span className="ml-2x txt-primary-color"> (Saving...)</span>
          ) : hasUnsavedChanges ? (
            <span className="ml-2x txt-red-color"> (Unsaved Changes)</span>
          ) : (
            ""
          )}
        </h3>
      </div>
      {fetchingGradeType ? (
        <Loader type="ThreeDots" />
      ) : (
        <section className="section">
          <div className="section__content pl-4x pt-4x bg-white">
            <div className="txt-black-color mb-2x">Percentage</div>
            <div className="row">
              <div className="col-5">
                <TextInput
                  label="Minimum"
                  name="data.percentage.minimum"
                  placeholder="Enter minimum percentage"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  disabled={isSaving}
                />
              </div>
              <div className="col-5">
                <TextInput
                  label="Maximum"
                  name="data.percentage.maximum"
                  placeholder="Enter maximum percentage"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  disabled={isSaving}
                />
              </div>
            </div>
          </div>
          <div className="section__content pl-4x pt-4x bg-white mt-1x">
            <div className="txt-black-color mb-2x">Grading Scale</div>
            <div className="row">
              <div className="col-5">
                <TextInput
                  label="Minimum"
                  name="data.gradeScale.minimum"
                  placeholder="Enter minimum grade"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  disabled={isSaving}
                />
              </div>
              <div className="col-5">
                <TextInput
                  label="Maximum"
                  name="data.gradeScale.maximum"
                  placeholder="Enter maximum grade"
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  disabled={isSaving}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </form>
  );
};

export default QuantitativeGrades;
