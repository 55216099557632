import { connect } from "react-redux";
import { useEffect, useCallback, useMemo, useState } from "react";

import AddTaskForm from "./AddTaskForm";
import { getFullName } from "utils/names";
import { IGoal } from "commons/types/goals";
import { isDateInRange } from "utils/dates";
import { IPriority } from "commons/types/plans";
import { getCurrentEnrollment } from "utils/user";
import { fetchGoals } from "store/actions/data/goals";
import { IAddTaskParams } from "./addTask.interface";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { fetchPlanningPeriods } from "store/actions/data/planningPeriods";
import { isStudentLoggedIn } from "utils/window";

const AddTask: React.FC<IAddTaskParams> = ({
  goal,
  fetchGoals,
  fetchPlanningPeriods,
  planningPeriods,
  student,
  ...props
}): JSX.Element => {
  const propsData = useMemo(() => ({ ...props }), [props]);

  const [initialPlanningPeriod, setInitialPlanningPeriod] = useState("");

  const fetchFormDataInfo = useCallback(async () => {
    fetchPlanningPeriods &&
      fetchPlanningPeriods({
        user: student._id,
        term: getCurrentEnrollment(student)?.enrolledTerm._id,
        endAfter: new Date().toString(),
      });
    fetchGoals({ assignee: student._id });
  }, [fetchGoals, fetchPlanningPeriods, student]);

  useEffect(() => {
    fetchFormDataInfo();
  }, [fetchFormDataInfo]);

  useEffect(() => {
    planningPeriods.forEach((planningPeriod) => {
      if (
        isDateInRange(
          planningPeriod.startDate,
          planningPeriod.endDate,
          props.quickTask?.requiredDeadline
            ? new Date(props.quickTask?.requiredDeadline)
            : new Date(),
          true,
        )
      )
        setInitialPlanningPeriod(planningPeriod.startDate.toString());
    });
  }, [planningPeriods, props.quickTask?.requiredDeadline]);

  const getInitialValues = (task: any) => {
    const taskClone = { ...task };
    if (!task) return false;
    delete taskClone["_id"];
    return {
      ...taskClone,
      assignee: task.assignee?._id,
      childOf: task.childOf?._id,
      isCompleted: false,
      isPublished: true,
      planningPeriods: initialPlanningPeriod,
    };
  };

  const initialValues = getInitialValues(propsData.duplicate) || {
    assignee: goal?.assignee?._id,
    name: "",
    requiredDeadline: props.quickTask?.requiredDeadline || "",
    isCompleted: false,
    isPublished: true,
    uowType: "Task",
    childOf: goal?._id,
    goalDeadline: goal?.requiredDeadline,
    planningPeriods: initialPlanningPeriod,
    workData: {
      uowType: "Task",
      priority: IPriority.notPriority,
      optionalDeadline: null,
    },
    termId: goal?.term?._id || getCurrentEnrollment(student)?.enrolledTerm._id,
    termEndDate:
      goal?.term?.endDate ||
      getCurrentEnrollment(student)?.enrolledTerm.endDate,
    recurrenceType: "Non-Repeat",
    recurrenceEndDate: null,
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        {isStudentLoggedIn() ? (
          <h3>Add a Task</h3>
        ) : (
          <h3>
            Add a Task for
            {student ? getFullName(student) : getFullName(goal?.assignee)}
          </h3>
        )}
        <span className="link-item" onClick={propsData.closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <AddTaskForm
        goal={goal}
        student={student}
        goals={props.goals}
        fetchGoals={fetchGoals}
        saveTask={props.saveTask}
        initialValues={initialValues}
        closeModal={props.closeModal}
        planningPeriods={planningPeriods}
      />
    </div>
  );
};

interface State {
  goals: {
    fetchGoals: IGoal[];
  };
  planningPeriods: {
    fetchPlanningPeriods: IPlanningPeriod[];
  };
}

const mapStateToProps = (state: State) => ({
  goals: state.goals.fetchGoals,
  planningPeriods: state.planningPeriods.fetchPlanningPeriods,
});

const mapDispatchToProps = {
  fetchGoals,
  fetchPlanningPeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
