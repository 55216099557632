import React from "react";
import Select from "react-select";

import { DropReasonSchema } from "commons/schemas/student";
import { TextAreaInput } from "commons/components/form-fields";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";

interface FormValues {
  dropReason: string;
  otherReason: string;
}

interface IForm {
  initialValues: FormValues;
  closeModal: () => void;
  dropConfirm: (dropReason: string) => void;
}

const options = [
  {
    value: "Student unresponsive",
    label: "Student unresponsive",
  },
  {
    value: "Student needs more academic support",
    label: "Student needs more academic support",
  },
  {
    value: "Student needs more intensive EF support",
    label: "Student needs more intensive EF support",
  },
  { value: "Medical reasons", label: "Medical reasons" },
  { value: "Family reasons", label: "Family reasons" },
  { value: "Geographic move", label: "Geographic move" },
  {
    value: "Schedule conflict, could not resolve",
    label: "Schedule conflict, could not resolve",
  },
  { value: "Other", label: "Other" },
];

const DropStudentForm: React.FC<IForm> = ({
  initialValues,
  closeModal,
  dropConfirm,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: DropReasonSchema,
    onSubmit: (values) => {
      const { dropReason, otherReason } = values;

      if (dropReason === "Other") {
        dropConfirm(otherReason);
      } else {
        dropConfirm(values.dropReason);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body pb-0x">
          <div className="row">
            <div className="col-12">
              <div className="input-wrap">
                <label className="input__label required">
                  Please specify the reason of dropping this student
                </label>
                <Field
                  name="dropReason"
                  component={({ field, form }: { field: any; form: any }) => (
                    <>
                      <Select
                        placeholder="Please select drop reason"
                        onChange={(option) => {
                          if (option) {
                            form.setFieldValue(field.name, option.value);
                          }
                        }}
                        options={options}
                        value={options?.find(
                          (option) => option.value === field.value,
                        )}
                        classNamePrefix="react-select"
                      />
                      <label className="input__error">
                        <ErrorMessage name="dropReason" />
                      </label>
                    </>
                  )}
                />
              </div>
            </div>
            {formik.values.dropReason === "Other" && (
              <div className="col-12">
                <TextAreaInput
                  label="Other reasons for dropping student"
                  name="otherReason"
                  placeholder="Please write here if you have any other reasons for dropping this student."
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values.otherReason}
                  handleChange={formik.handleChange}
                />
              </div>
            )}
          </div>
        </div>
        <div className="modal-wrap__footer pt-0x">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Drop in progress
              </button>
              <span className="btn txt-primary-color" onClick={closeModal}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default DropStudentForm;
