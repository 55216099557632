export enum Role {
  PRACTITIONER = "Practitioner",
  SUBJECT = "Subject",
  SPONSOR = "Sponsor",
  INTAKE_MANAGER = "Intake Manager",
  SUPER_ADMIN = "Super Admin",
  TENANT_ADMIN = "Tenant Admin",
}

const rolePaths: any = {
  "Intake Manager": [
    "/profile",
    "/dashboard",
    "/dashboard/mentors",
    "/dashboard/mentors/add",
    "/dashboard/students",
    "/dashboard/students/add",
    "/dashboard/students/:id/enroll/:index",
    "/dashboard/students/:id/edit",
    "/dashboard/terms",
    "/dashboard/terms/add",
    "/dashboard/settings/centers",
    "/dashboard/settings/accommodations",
    "/dashboard/settings/school-district",
    "/dashboard/settings/academic-year",
    "/dashboard/settings/schools",
    "/dashboard/settings/grade-type",
    "/dashboard/settings/satellite-location",
    "/dashboard/survey/download",
  ],
  Practitioner: [
    "/profile",
    "/videochat",
    "/dashboard",
    "/dashboard/assessment",
    "/dashboard/students",
    "/dashboard/students/:id/plans",
    "/dashboard/students/:id/goals",
    "/dashboard/students/:id/goals/:goalId",
    "/dashboard/students/:id/assignments",
    "/dashboard/students/:id/personal-info",
    "/dashboard/students/:id/academic-info",
    "/dashboard/students/:id/assessment",
    "/dashboard/students/:id/assessment-detail/:responseId",
    "/dashboard/profile",
    "/dashboard/students/:id/grades",
  ],
  SuperAdmin: ["/dashboard/tenants"],
};

export default rolePaths;
