import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import Modal from "react-modal";
import classNames from "classnames";

import Menu from "../update/Menu";
import { IUser } from "commons/types/users";
import tasksImage from "assets/images/tasks.svg";
import { checkDropDownPosition } from "utils/dropdown";
import UpdateAssignment from "pages/assignments/update";
import DeleteConfirm from "commons/components/DeleteConfirm";
import MarkCompleted from "pages/assignments/update/MarkCompleted";
import { ASSIGNMENT_STATUS, COURSE_CLIP_LENGTH } from "constants/index";
import { IAssignment, IUpdateAssignment } from "commons/types/assignments";

interface AssignmentListProps {
  assignments: IAssignment[];
  updateAssignment: (id: string, payload: IUpdateAssignment) => any;
  fetchAssignments: any;
  student: IUser;
  deleteAssignment: (id: string) => any;
}

const AssignmentList: React.FC<AssignmentListProps> = ({
  assignments,
  updateAssignment,
  fetchAssignments,
  student,
  deleteAssignment,
}): JSX.Element => {
  const [openMenu, setOpenMenu] = useState<boolean>();
  const [actionAssignment, setActionAssignment] = useState<any>();
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [isMarkCompletedOpen, setMarkCompletedOpen] = useState<boolean>(false);
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isMenuTop, setMenuTop] = useState<boolean>(false);

  const closeMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  useEffect(() => {
    if (openMenu) {
      document.addEventListener("click", closeMenu);
    }
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [openMenu, closeMenu]);

  const handleCheck = async (assignment: any) => {
    await updateAssignment(assignment._id, {
      isPrintable: !assignment.isPrintable,
    });
    fetchAssignments();
  };

  const handleDeleteAssignment = async () => {
    await deleteAssignment(actionAssignment._id);
    setDeleteOpen(false);
    fetchAssignments();
  };

  const getCourseName = (assignment: any) => {
    if (assignment.course?.name?.length > COURSE_CLIP_LENGTH) {
      return `${assignment.course?.name?.slice(0, COURSE_CLIP_LENGTH)}...`;
    } else {
      return assignment.course?.name;
    }
  };

  return (
    <>
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-item__content">
            {!assignments || !assignments.length ? (
              <div className="empty-section">
                <div className="empty-section__content">
                  <img src={tasksImage} alt="Tasks" />
                  <h3>You currently do not have any assignments.</h3>
                  <p>
                    Go out and have some fun, or create a new assignment to get
                    some work done.
                  </p>
                </div>
              </div>
            ) : (
              assignments.map((assignment: any) => (
                <div
                  key={assignment._id}
                  className={classNames("card align-items-center", {
                    "card--success":
                      assignment.status === ASSIGNMENT_STATUS.Submitted,
                  })}
                >
                  <div className="card-content flex-grow">
                    <div className="card-text">
                      <div className="card-head">
                        <h3>{assignment.name}</h3>
                        {assignment.status === ASSIGNMENT_STATUS.Created ? (
                          <span />
                        ) : assignment.status ===
                          ASSIGNMENT_STATUS.Submitted ? (
                          <span className="status status--success assignment-status">
                            <box-icon name="check" />
                            Submitted
                          </span>
                        ) : assignment.status === ASSIGNMENT_STATUS.Missed ? (
                          <span className="status status--error assignment-status">
                            <box-icon name="error-alt" />
                            Missed
                          </span>
                        ) : (
                          <span />
                        )}
                      </div>
                      <ul className="card-info">
                        <li>
                          {`Course:
                          ${
                            getCourseName(assignment)
                              ? getCourseName(assignment)
                              : "-"
                          }`}
                        </li>
                        <li>
                          <box-icon name="calendar" />
                          Due{" "}
                          {dayjs(assignment.submissionDate).format(
                            "MMM D, YYYY",
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span className="link-item">
                      <label className="toggle">
                        {assignment.isPrintable ? (
                          <span className="toggle-label">
                            Included in report
                          </span>
                        ) : (
                          <span className="toggle-label" />
                        )}
                        <input
                          type="checkbox"
                          className="toggle-checkbox"
                          value={"true"}
                          checked={assignment.isPrintable === true}
                          onChange={(event) => {
                            handleCheck(assignment);
                          }}
                        />
                        <div className="toggle-switch"></div>
                      </label>
                    </span>
                    <span
                      className="link-item dropdown-button"
                      onClick={(event) => {
                        setMenuTop(checkDropDownPosition(event));
                        setOpenMenu(true);
                        setActionAssignment(assignment);
                      }}
                    >
                      <box-icon name="dots-horizontal-rounded" />
                      {openMenu && assignment._id === actionAssignment?._id ? (
                        <Menu
                          editMenu={() => setEditOpen(true)}
                          markCompleted={() => {
                            setMarkCompletedOpen(true);
                          }}
                          deleteOpen={() => setDeleteOpen(true)}
                          isMenuTop={isMenuTop}
                        />
                      ) : (
                        <span />
                      )}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Modal className="modal-block" isOpen={isEditOpen} ariaHideApp={false}>
        <UpdateAssignment
          assignment={actionAssignment}
          closeModal={() => {
            setEditOpen(false);
          }}
          student={student}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isMarkCompletedOpen}
        ariaHideApp={false}
      >
        <MarkCompleted
          assignment={actionAssignment}
          closeModal={() => {
            setMarkCompletedOpen(false);
          }}
          student={student}
        />
      </Modal>
      <Modal className="modal-block" isOpen={isDeleteOpen} ariaHideApp={false}>
        <DeleteConfirm
          closeModal={() => {
            setDeleteOpen(false);
          }}
          message={{
            header: "Do you want to delete this assignment?",
            body: "This action is not reversible",
          }}
          deleteResource={handleDeleteAssignment}
        />
      </Modal>
    </>
  );
};

export default AssignmentList;
