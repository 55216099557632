import config from "../configs";
import { http } from "utils/http";

export const fetchRoles = async () => {
  const { data } = await http.get(config.endpoints.roles.all, {});

  return data;
};

export const searchRolesByName = async (roleName: string) => {
  const { data } = await http.get(config.endpoints.roles.all, {
    params: {
      name: roleName,
    },
  });

  return data;
};
