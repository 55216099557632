import Modal from "react-modal";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemHeading,
} from "react-accessible-accordion";

import AddQualitativeGrades from "./add";
import EditQualitativeGrades from "./edit";
import { RootState } from "store/reducers";
import Loader from "commons/components/Loader";
import { IQualitativeGradeTypes } from "./qualitativeGrades.interface";
import {
  addGradeType,
  updateGradeType,
  fetchGradeTypes,
  deleteGradeType,
} from "store/actions/data/gradeTypes";
import { IGradeType, IUpdateGradeType } from "commons/types/gradeTypes";

const QualitativeGrades: React.FC<IQualitativeGradeTypes> = ({
  gradeTypes,
  addGradeType,
  updateGradeType,
  fetchGradeTypes,
  gradeTypeLoading,
}) => {
  const [isAddGradeOpen, setIsAddGradeOpen] = useState(false);
  const [editGradeOpen, setEditGradeOpen] = useState<IGradeType | null>(null);

  useEffect(() => {
    fetchGradeTypes({ type: "Qualitative" });
  }, [fetchGradeTypes]);

  const saveQualitativeGradeType = async (gradeType: IGradeType) => {
    const newGradeType = await addGradeType(gradeType);
    if (newGradeType) fetchGradeTypes({ type: "Qualitative" });

    return newGradeType;
  };

  const updateQualitativeGradeType = async (
    id: string,
    gradeType: IUpdateGradeType,
  ) => {
    const updatedGradeType = await updateGradeType(id, gradeType);
    if (updatedGradeType) fetchGradeTypes({ type: "Qualitative" });

    return updatedGradeType;
  };

  const preExpanded = gradeTypes.map((gradeType) => gradeType._id || "");

  return (
    <section className="section">
      <div className="section__title mb-2x">
        <div className="">
          <h3>Qualitative Grade</h3>
        </div>
        <div className="action add-new pt-0x">
          <span className="link-item" onClick={() => setIsAddGradeOpen(true)}>
            <box-icon name="plus-circle" type="solid" />
          </span>
        </div>
      </div>
      {gradeTypes.length ? (
        <Accordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          className="accordion"
          preExpanded={[...preExpanded]}
        >
          {gradeTypes.map((gradeType) => (
            <AccordionItem
              uuid={gradeType._id}
              key={gradeType._id}
              className="accordion-item"
            >
              <AccordionItemState>
                {({ expanded }) => (
                  <>
                    <AccordionItemHeading className="">
                      <AccordionItemButton className="p-0x accordion-item__title pt-3x">
                        <div className="title-left">
                          <h3>{gradeType.name} </h3>
                          {gradeType.data?.range?.minimum &&
                            gradeType.data?.range?.minimum && (
                              <span className="ml-2x">
                                (Range {gradeType.data.range.minimum} -{" "}
                                {gradeType.data.range.maximum})
                              </span>
                            )}
                        </div>
                        <div className="title-right">
                          <span
                            className="link-item mr-3x plus-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditGradeOpen(gradeType);
                            }}
                          >
                            <box-icon name="edit-alt" type="solid" />
                          </span>
                          <span>
                            <box-icon
                              name={!expanded ? "chevron-down" : "chevron-up"}
                            ></box-icon>
                          </span>
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-item__content">
                      {gradeType.data?.options &&
                        gradeType.data.options.map(
                          (option: {
                            key: string;
                            greaterThanEqual: string;
                            lessThan: string;
                          }) => (
                            <div
                              key={option.key}
                              className="section__content p-4x mt-1x bg-white"
                            >
                              <div className="txt-black-color text-bold">
                                {option.key}
                              </div>
                              <div>
                                {option.greaterThanEqual
                                  ? `Greater than or equal to ${option.greaterThanEqual}`
                                  : ""}
                                {option.lessThan && option.greaterThanEqual
                                  ? ` and less than ${option.lessThan}`
                                  : option.lessThan
                                  ? `Less than ${option.lessThan}`
                                  : ""}
                              </div>
                            </div>
                          ),
                        )}
                    </AccordionItemPanel>
                  </>
                )}
              </AccordionItemState>
            </AccordionItem>
          ))}
        </Accordion>
      ) : gradeTypeLoading ? (
        <Loader type="ThreeDots" />
      ) : (
        <div className="section__content pl-4x pt-4x bg-white">
          Please add Qualitative grade
          <div
            className="pb-2x link-text pt-2x add-new"
            onClick={() => setIsAddGradeOpen(true)}
          >
            <box-icon name="plus" />
            <span className="">Add Grades</span>
          </div>
        </div>
      )}

      <Modal
        className="modal-block"
        isOpen={isAddGradeOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsAddGradeOpen(false)}
      >
        <AddQualitativeGrades
          saveGradeType={saveQualitativeGradeType}
          closeModal={() => setIsAddGradeOpen(false)}
        />
      </Modal>

      <Modal
        className="modal-block"
        isOpen={!!editGradeOpen}
        ariaHideApp={false}
        onRequestClose={() => setEditGradeOpen(null)}
      >
        {editGradeOpen && (
          <EditQualitativeGrades
            initialValues={editGradeOpen}
            updateGradeType={updateQualitativeGradeType}
            closeModal={() => setEditGradeOpen(null)}
          />
        )}
      </Modal>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  gradeTypes: state.gradeTypes.fetchGradeTypes,
  gradeTypeLoading: state.gradeTypes.fetchGradeTypesLoading,
});

const mapDispatchToProps = {
  addGradeType,
  updateGradeType,
  fetchGradeTypes,
  deleteGradeType,
};

export default connect(mapStateToProps, mapDispatchToProps)(QualitativeGrades);
