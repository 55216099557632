import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  ISchoolCreate,
  ISchoolParams,
  IUpdateSchool,
} from "commons/types/school";

export const add = async (payload: ISchoolCreate) => {
  const url = config.endpoints.school.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: ISchoolParams) => {
  const url = stringify(config.endpoints.school.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: IUpdateSchool) => {
  const url = pinterpolate(config.endpoints.school.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.school.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
