import { TenantRole } from "../../tenant/tenant.types";

export enum SurveyKinds {
  TEMPLATE = "Template",
  ACTUAL = "Actual",
}

export enum AnswerType {
  SINGLE = "Single",
  MULTI = "Multi",
}

export enum SurveyTypes {
  INITIAL = "Initial",
  FINAL = "Final",
  INITIAL_SURVEY = "Initial Survey",
  FINAL_SURVEY = "Final Survey",
}

export enum SurveyRoles {
  SUBJECT = "Subject",
  SPONSOR = "Sponsor",
  PRACTIONER = "Practitioner",
}

export enum SurveyTemplateStatus {
  DRAFT = "Draft",
  READY_FOR_USE = "Ready_for_use",
  ARCHIEVED = "Archieved",
}

export interface ISurvey {
  isDeleted: boolean;
  isActive: boolean;
  programs: any[];
  _id: string;
  yearId: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISurveyInitaiteResponders {
  sponsors: string[];
  subject: string;
  practitioners: string[];
  mapId?: number;
}

export interface ISurveyRespondResult {
  type: string;
  question: string;
  answer: IAnswerValue | IAnswerValue[];
}

export interface IAnswerValue {
  answer: string;
  value: string;
}

export interface ISurveyInitiate {
  surveyType: string;
  term: string;
  role?: string;
  sendToMentor?: boolean;
  category: string;
  emailMessage?: string;
  thankYouMessage?: string;
  createdDate?: Date;
  createdBy?: string;
  responders?: {
    subject: string;
    practitioners: string[];
    sponsors: string[];
  }[];
}

export interface ISurveysParams {
  surveyFor?: string;
  category?: string;
  term?: string;
  type?: string;
  templateStatus?: string;
  isActive?: string;
}

export interface SurveyListItem {
  _id: string;
  title: string;
  nickname: string;
  description: string;
  createdDate: string;
  status: SurveyTemplateStatus;
  type: SurveyKinds;
  surveyType?: SurveyTypes;
  noOfResponses: number;
  noOfQuestion: number;
  responderRoles: Array<TenantRole>;
  skillsets: Array<SurveySkillSet>;
  responses: Array<SurveyResponse>;
  category: SurveyItemCategory;
  term: SurveyItemTerm;
  createdFor?: {
    _id: string;
    firstName: string;
    lastName: string;
  };
}

export interface SurveyItemCategory {
  description: string;
  name: string;
  _id: string;
}

export interface SurveyItemTerm {
  endDate: string;
  name: string;
  startDate: string;
  _id: string;
}

export interface SkillSet {
  _id: string;
  name: string;
  description: string;
}

export interface SurveySkillSet {
  skillset: SkillSet;
  questions: Array<SurveyQuestions>;
}

// TODO Add SurveyQuestions type
export interface SurveyQuestions {
  question: {
    _id: string;
    title: any;
    description: string;
    isPositive: boolean;
    role: string;
    skill: {
      _id: string;
      name: string;
    };
  };
  data: ISurveyQuestionsData;
  answerType: {
    type: string;
  };
}

export interface ISurveyQuestionsData {
  type: string;
  options: Array<IResponseOption>;
}

export interface IResponseOption {
  _id: string;
  label: string;
  type: string;
  value: string;
  order: number;
}

export interface SurveyResponse {
  _id: string;
  role: string;
  submittedBy: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  survey: Partial<SurveyListItem>;
  result: Array<SurveyResponseResult>;
  createdAt: string;
  updatedAt: string;
}

export interface ResponseAnswer {
  date: string;
  answer: Answer | [Answer];
  notes: [SurveyNote];
}

export interface Answer {
  _id: string;
  order: number;
  type: string;
  label: string;
  value: string;
}

export interface SurveyNote {
  createdAt: string;
  note: string;
}

export interface Question {
  _id: string;
  title: {
    Subject: string;
    Practitioner: string;
    Sponsor: string;
  };
  description: string;
  isPositive: boolean;
  role: string;
  skill: {
    _id: string;
    name: string;
    description: string;
  };
}

export interface SurveyResponseResult {
  skillset: SkillSet;
  responses: Array<SurveyResponseObject>;
}

export interface INote {
  createdAt: string;
  note: string;
}

export interface IQuestion {
  _id: string;
  title: {
    Subject: string;
    Practitioner: string;
    Sponsor: string;
  };
  description: string;
  isPositive: boolean;
  role: string;
  skill?: {
    _id: string;
    name: string;
    description: string;
  };
}

export interface IAnswer {
  date?: string;
  answer: IResponseOption;
  notes?: INote[];
}

export interface SurveyResponseObject {
  answer: IRAnswer;
  type: string;
  question: string;
  revisions?: {
    answer: IRAnswer;
    notes: INote[];
    type?: string;
    date?: string;
  }[];
}

export interface IRAnswer {
  date?: string;
  answer: string;
  value: string;
  notes?: INote[];
}

export interface IAssessmentItem {
  _id: string;
  title: string;
  responderRole?: TenantRole;
  totalQuestions: number;
  totalResponses: number;
  surveyType?: SurveyTypes;
  createdAt: string;
  category: SurveyItemCategory;
  term: SurveyItemTerm;
  assessmentType: "INDIVIDUAL" | "AGGREGATED";
}

export interface IResponseAggregate {
  [key: string]: { [key: string]: { _id: string; data: ISelectOption }[][] };
}

export interface ISelectOption {
  label: string;
  value: string;
  weight: number;
  type?: string;
}

export interface IResendSurveyPayload {
  subject: string;
  sponsors: Array<string>;
  practitioners: Array<string>;
}
