import { useHistory, useRouteMatch } from "react-router-dom";
import pinterpolate from "pinterpolate";

import config from "configs";
import { IGoal } from "commons/types/goals";
import { GoalStatus } from "constants/status";
import tasksImage from "assets/images/tasks.svg";
import emptyGoalsImage from "assets/images/empty-goals.svg";
import { getDateInFormat } from "utils/dates";
import { isStudentLoggedIn } from "utils/window";

interface GoalsListProps {
  goals: IGoal[];
  setAddTaskForGoal: (goal: IGoal) => void;
}

const GoalsList: React.FC<GoalsListProps> = ({ goals, setAddTaskForGoal }) => {
  const history = useHistory();
  const { params } = useRouteMatch();

  const getStatus = (goal: IGoal) => {
    if (goal.workData?.status === GoalStatus.PROGRESS)
      return (
        <span className="status status--warning">
          <box-icon name="trending-up" />
          Made Progress
        </span>
      );
    else if (goal.workData?.status === GoalStatus.MET)
      return (
        <span className="status status--success">
          <box-icon name="check" />
          {goal.workData?.status}
        </span>
      );
    else if (goal.workData?.status === GoalStatus.NOT_MET)
      return (
        <span className="status status--error">
          <box-icon name="error-alt" />
          Not Met
        </span>
      );
  };

  const goalTasks = (goal: IGoal) => {
    return goal.parentOf && goal.parentOf.length > 0
      ? `${goal.parentOf.filter((goal) => goal.isCompleted).length}/${
          goal.parentOf.length
        } tasks`
      : "No tasks";
  };

  const getGoalDetailPath = (id: string, goalId: string) => {
    const goalDetailpath = pinterpolate(
      isStudentLoggedIn()
        ? config.uiPath.goals.studentDetails
        : config.uiPath.goals.details,
      {
        id,
        goalId,
      },
    );

    return goalDetailpath;
  };

  const goToGoal = (goal: IGoal) => {
    const { id }: any = params;
    history.push(getGoalDetailPath(id, goal._id || ""));
  };

  return (
    <>
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-item__content">
            {!goals || goals?.length < 1 ? (
              <div className="empty-section">
                <div className="empty-section__content">
                  {isStudentLoggedIn() ? (
                    <div>
                      <img
                        src={emptyGoalsImage}
                        alt="empty goals"
                        className=" tw-w-20"
                      />
                      <h3>You currently do not have any goals.</h3>
                    </div>
                  ) : (
                    <div>
                      <img src={tasksImage} alt="Tasks" />
                      <h3>You have not added any goals yet.</h3>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              goals.map((goal) => (
                <div key={goal._id || ""} className="card align-items-center">
                  <div className="card-content" onClick={() => goToGoal(goal)}>
                    <div className="card-text">
                      <div className="card-head">
                        <h3
                          className="link-item"
                          onClick={() => {
                            const { id }: any = params;
                            history.push(getGoalDetailPath(id, goal._id || ""));
                          }}
                        >
                          {goal.name}
                        </h3>
                        {getStatus(goal)}
                      </div>
                      {goal.workData?.metrics?.metricsType && (
                        <ul className="card-info mb-3x">
                          <li>
                            Metric Type:
                            <span className="ml-1x">
                              <b>{goal.workData?.metrics?.metricsType}</b>
                            </span>
                          </li>
                          <li>
                            Baseline:
                            <span className="ml-1x">
                              <b>{goal.workData?.metrics?.baselineMetric}</b>
                            </span>
                          </li>
                          <li>
                            Achieved:
                            <span className="ml-1x">
                              {goal.workData?.metrics?.achievedMetric ? (
                                <b> {goal.workData?.metrics?.achievedMetric}</b>
                              ) : (
                                "-"
                              )}
                            </span>
                          </li>
                        </ul>
                      )}
                      <ul className="card-info">
                        <li>
                          <box-icon name="bullseye" type="solid" />
                          {goal.workData?.workType}
                        </li>
                        <li>
                          <box-icon name="calendar-check" color="#959FAE" />
                          {goalTasks(goal)}
                        </li>
                        {goal.requiredDeadline && (
                          <li>
                            <box-icon name="calendar" color="#959FAE" />
                            Due {getDateInFormat(goal.requiredDeadline)}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span
                      onClick={() => setAddTaskForGoal(goal)}
                      className="link-item"
                    >
                      <box-icon name="plus-circle" type="solid" />
                    </span>
                    <span
                      className="link-item link-item"
                      onClick={() => goToGoal(goal)}
                    >
                      <box-icon name="chevron-right" />
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalsList;
