import React from "react";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";

interface ITermNotEnrolledErrorProps {
  student: IUser;
}

const TermNotEnrolledError: React.FC<ITermNotEnrolledErrorProps> = ({
  student,
}) => {
  return (
    <div className="alert alert__danger">
      <span className="status link-item">
        <box-icon name="error-alt"></box-icon>
      </span>
      <div className="alert__content">
        <strong>{getFullName(student)} is not enrolled in current term!</strong>
        Please contact admin to enroll the student.
      </div>
    </div>
  );
};

export default TermNotEnrolledError;
