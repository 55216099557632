import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as responseRevision from "services/responseRevision";
import {
  ICreateResponseRevision,
  IResponseRevisionParams,
} from "commons/types/responseRevision";
import {
  AddResponseRevisionActionType,
  FetchResponseRevisionsActionType,
} from "../types/responseRevision";

export const createResponseRevision =
  (responseId: string, payload: ICreateResponseRevision) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: AddResponseRevisionActionType.ADD_RESPONSE_REVISION_PENDING,
    });
    try {
      const response = await responseRevision.createResponseRevision(
        responseId,
        payload,
      );
      dispatch({
        type: AddResponseRevisionActionType.ADD_RESPONSE_REVISION_FULFILLED,
        data: response,
      });
      success("Response revised");

      return response;
    } catch (ex) {
      dispatch({
        type: AddResponseRevisionActionType.ADD_RESPONSE_REVISION_REJECTED,
        data: ex,
      });

      error("Failed to save changes");
    }
  };

export const fetchResponseRevision =
  (params: IResponseRevisionParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchResponseRevisionsActionType.FETCH_RESPONSE_REVISIONS_PENDING,
    });
    try {
      const response = await responseRevision.fetchResponseRevision(params);
      dispatch({
        type: FetchResponseRevisionsActionType.FETCH_RESPONSE_REVISIONS_FULFILLED,
        data: response.data,
      });

      return response;
    } catch (ex) {
      dispatch({
        type: FetchResponseRevisionsActionType.FETCH_RESPONSE_REVISIONS_REJECTED,
        data: ex,
      });

      error("Failed to fetch response revision");
    }
  };
