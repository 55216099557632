import Modal from "react-modal";
import { useFormik } from "formik";
import React, { useState, useEffect, useCallback } from "react";

import { ILocation } from "commons/types";
import { success, error } from "utils/toast";
import TermSchema from "commons/schemas/term";
import Confirm from "commons/components/Confirm";
import { fetchLocations } from "services/tenant";
import { checkEndDateBeforeStartDate } from "utils/dates";
import { fetchAcademicYear, updateTerm } from "services/terms";
import { IAcademicYearResponse } from "commons/types/academicYear";
import { ITermEditParam, ITermInitValues } from "./editparam.interface";
import {
  DateInput,
  TextInput,
  CustomSelect,
} from "commons/components/form-fields";
import { subdomainName } from "utils/http";

interface IOptions {
  value: string;
  label: string;
}

const Form: React.FC<ITermEditParam> = ({
  initialValues,
  closeModal,
  id,
  loadTerms,
  isTermUsed,
}: ITermEditParam) => {
  const [centers, setCenters] = useState<IOptions[]>([]);
  const [center, setCenter] = useState<any>(null);
  const [academicYears, setAcademicYears] = useState<IOptions[]>([]);
  const [academicYear, setAcademicYear] = useState<any>();
  const [isConfirmChange, setConfirmChange] = useState<boolean>(false);

  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (!checkNeedToConfirm(values)) {
        await submitTermInfo(values);
      } else {
        setConfirmChange(true);
      }
    },
    validationSchema: TermSchema,
  });
  const { setFieldValue } = formik;

  const checkNeedToConfirm = (values: ITermInitValues) => {
    return (
      isTermUsed &&
      !(
        values.startDate === initialValues.startDate &&
        values.endDate === initialValues.endDate
      )
    );
  };

  const submitTermInfo = async (values: ITermInitValues) => {
    try {
      await updateTerm({
        ...values,
        id,
      });
      success("Term Edited Successfully");
      loadTerms();
      closeModal();
    } catch (err: any) {
      error("Failed to edit");
    } finally {
    }
  };

  const setLearningCenters = useCallback(async () => {
    const { data } = await fetchLocations();
    const centerOptions = data.map((option: ILocation) => {
      return { label: option.name, value: option._id };
    });
    setCenters(centerOptions);
    const activeCenter = centerOptions.filter((data: IOptions) =>
      initialValues?.learningCenters
        ?.map((center: any) => center._id)
        ?.includes(data.value),
    );
    setCenter(activeCenter);

    setFieldValue(
      "learningCenters",
      activeCenter.map((data: IOptions) => data.value),
    );
  }, [initialValues, setFieldValue]);

  const populateAcademicYear = React.useCallback(async () => {
    const { data } = await fetchAcademicYear();
    const academicYearOption = data.map((option: IAcademicYearResponse) => {
      return { label: option.name, value: option._id };
    });
    setAcademicYears(academicYearOption);
    setAcademicYear(
      academicYearOption.find((option: IOptions) => {
        return option.value === initialValues.academicYear;
      }),
    );
  }, [initialValues.academicYear]);

  useEffect(() => {
    setLearningCenters();
    populateAcademicYear();
  }, [initialValues, setLearningCenters, populateAcademicYear]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body">
          <div className="row">
            <div className="col-12">
              <CustomSelect
                name="academicYear"
                label={"Academic Year"}
                options={academicYears}
                value={academicYear}
                handleChange={(value: any) => {
                  formik.setFieldValue("academicYear", value.value);
                  setAcademicYear(value);
                }}
                touched={formik.touched}
                errors={formik.errors}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                label={"Title of the term"}
                name={"termTitle"}
                placeholder={"Title"}
                touched={formik.touched}
                errors={formik.errors}
                values={formik.values}
                handleChange={formik.handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label required" htmlFor="startDate">
                  Start Date
                </label>
                <DateInput
                  id="startDate"
                  name="startDate"
                  isValid={
                    !(formik.touched.startDate && formik.errors.startDate)
                  }
                  selected={formik.values.startDate}
                  onChange={(date) => {
                    checkEndDateBeforeStartDate(date, formik.values.endDate) &&
                      formik.setFieldValue("endDate", date);
                    formik.setFieldValue("startDate", date);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.startDate && formik.touched.startDate ? (
                  <p className="input__error">{formik.errors.startDate}</p>
                ) : null}
              </div>
            </div>

            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label required" htmlFor="endDate">
                  End Date
                </label>
                <DateInput
                  id="endDate"
                  name="endDate"
                  isValid={!(formik.touched.endDate && formik.errors.endDate)}
                  selected={formik.values.endDate}
                  onChange={(date) => formik.setFieldValue("endDate", date)}
                  onBlur={formik.handleBlur}
                  minDate={new Date(formik.values.startDate)}
                />
                {formik.errors.endDate && formik.touched.endDate ? (
                  <p className="input__error">{formik.errors.endDate}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 multi-select">
              <CustomSelect
                label={`${subdomainName} Learning Center`}
                name={"learningCenters"}
                isMulti={true}
                options={centers}
                value={center}
                handleChange={(value: IOptions, formikFieldValue: any) => {
                  formik.setFieldValue("learningCenters", formikFieldValue);
                  setCenter(value);
                }}
                touched={formik.touched}
                errors={formik.errors}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-wrap">
                <input
                  type="checkbox"
                  id="check3"
                  className="form-group__radio mr-10"
                  name="isCurrent"
                  checked={formik.values.isCurrent || false}
                  onChange={(e) =>
                    formik.setFieldValue("isCurrent", e.target.checked)
                  }
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="check3" className="box-links__nodes">
                  Is current term
                </label>
                <label className="input__error">
                  {formik.touched.isCurrent && formik.errors.isCurrent}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-wrap__footer">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={formik.isSubmitting || !academicYear}
              >
                Save Information
              </button>
              <span className="btn txt-primary-color" onClick={closeModal}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={isConfirmChange}
        className="modal-block"
        onRequestClose={() => setConfirmChange(false)}
      >
        <Confirm
          handleOk={async () => {
            await submitTermInfo(formik.values);
            setConfirmChange(false);
          }}
          closeModal={() => setConfirmChange(false)}
          message={{
            header: "Are you sure you want to edit the term date?",
            body: "Editing term date will not affect the planning period that are already generated.",
          }}
        />
      </Modal>
    </>
  );
};

export default Form;
