import "boxicons";
import Modal from "react-modal";
import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";

const MentorPlans = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="nav__link nav__link--active">Student</span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="arrow-back"></box-icon>
                    Go Back
                  </span>
                </li>
                <li className="nav-left__item--title">Samantha Doe</li>
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Plans
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="bullseye"></box-icon>
                    Goals
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="book-open"></box-icon>
                    Assignments
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar"></box-icon>
                    Sessions
                  </span>
                </li>
                <li className="nav-left__item--title">
                  Additional information
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="user"></box-icon>
                    Personal Info
                  </span>
                </li>
                <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="graduation" type="solid"></box-icon>
                    Academic Info
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="avatar mt-3x">
                    <img src={student1} alt="Student" />
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-sm">Student Profile</span>
                    <h2>Samantha Doe</h2>
                    <ul className="heading-info">
                      <li className="">Grade 7</li>
                      <li>
                        <span className="link-item">Chicago Public School</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="page-heading__right">
                  <button className="btn btn--primary mr-2x" type="button">
                    Add Goal
                  </button>
                </div>
              </div>
              <div className="section-heading border-bottom mb-0x">
                <h3>Plans</h3>
              </div>

              <div className="filters border-bottom">
                <h4>Plans for Samantha</h4>

                <ul className="filters__list">
                  <li>
                    <span className="link-item filter-item">
                      To-do Date
                      <box-icon name="chevron-down"></box-icon>
                    </span>
                  </li>
                  <li>
                    <span className="link-item filter-item-icon">
                      <box-icon name="list-ul"></box-icon>
                    </span>
                  </li>
                  <li>
                    <span className="link-item filter-item-icon">
                      <box-icon name="calendar-week"></box-icon>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="accordion">
                <div className="accordion-item">
                  <div className="accordion-item__title">
                    <div className="title-left">
                      <div className="input-wrap mb-8x">
                        <input
                          type="checkbox"
                          name="checkbox"
                          value="Checkbox Inline 2"
                          id="check2"
                          className="form-group__radio mr-10"
                        />
                        <label
                          htmlFor="check2"
                          className="box-links__nodes"
                        ></label>
                      </div>
                      <span>Overdue</span>
                    </div>
                    <div className="title-right">
                      <box-icon name="chevron-down"></box-icon>
                    </div>
                  </div>
                  <div className="accordion-item__content">
                    <div className="card card--error">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Samantha Doe</span>{" "}
                            (Grade 7)
                          </span>
                          <h3>Research about the Romans</h3>
                          <ul className="card-info">
                            <li className="duedate">
                              <box-icon name="error-alt"></box-icon>Yesterday
                            </li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due
                              <strong> April 3, 2021</strong>
                            </li>
                            <li>
                              <box-icon
                                type="solid"
                                name="graduation"
                              ></box-icon>
                              Learn Roman history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>

                    <div className="card card--warning">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Samantha Doe</span>{" "}
                            (Grade 7)
                          </span>
                          <h3>Research about the Romans</h3>
                          <ul className="card-info">
                            <li className="duedate">
                              <box-icon name="error-alt"></box-icon>Yesterday
                            </li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due{" "}
                              <strong> April 3, 2021</strong>
                            </li>
                            <li>
                              <box-icon
                                type="solid"
                                name="graduation"
                              ></box-icon>
                              Learn Roman history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-item__title">
                    <div className="title-left">
                      <div className="input-wrap mb-8x">
                        <input
                          type="checkbox"
                          name="checkbox"
                          value="Checkbox Inline 2"
                          id="check2"
                          className="form-group__radio mr-10"
                        />
                        <label
                          htmlFor="check2"
                          className="box-links__nodes"
                        ></label>
                      </div>
                      <span>Monday</span>
                    </div>
                    <div className="title-right">
                      <box-icon name="chevron-down"></box-icon>
                    </div>
                  </div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Lori Denver</span>{" "}
                            (Grade 9)
                          </span>
                          <h3>Complet lacrosse team sign-up</h3>
                          <ul className="card-info">
                            <li className="duedate">Monday</li>
                            <li>
                              <box-icon name="user"></box-icon>Due Enroll in a
                              sports club
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-item__title">
                    <div className="title-left">
                      <div className="input-wrap mb-8x">
                        <input
                          type="checkbox"
                          name="checkbox"
                          value="Checkbox Inline 2"
                          id="check2"
                          className="form-group__radio mr-10"
                        />
                        <label
                          htmlFor="check2"
                          className="box-links__nodes"
                        ></label>
                      </div>
                      <span>On the horizon</span>
                    </div>
                    <div className="title-right">
                      <box-icon name="chevron-down"></box-icon>
                    </div>
                  </div>
                  <div className="accordion-item__content">
                    <div className="card">
                      <div className="card-content">
                        <div className="input-wrap">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id="check2"
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor="check2"
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="avatar ml-2x">SD</div>
                        <div className="card-text">
                          <span className="name">
                            <span className="link-item">Samantha Doe</span>{" "}
                            (Grade 7)
                          </span>
                          <h3>Research about the Romans</h3>
                          <ul className="card-info">
                            <li className="duedate">
                              <box-icon name="error-alt"></box-icon>Yesterday
                            </li>
                            <li>
                              <box-icon name="calendar"></box-icon>Due{" "}
                              <strong> April 3, 2021</strong>
                            </li>
                            <li>
                              <box-icon
                                type="solid"
                                name="graduation"
                              ></box-icon>
                              Learn Roman history
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        <span className="link-item">
                          <box-icon name="chat"></box-icon>
                        </span>
                        <span className="link-item">
                          <box-icon name="dots-horizontal-rounded"></box-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="link-add-new">
                <box-icon name="plus"></box-icon>
                <span className="link-text">Add New Task</span>
              </div>
            </div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <h3>Messages</h3>
                  <div className="message-actions">
                    <span className="action-links active">
                      <span>Available</span>
                      <box-icon name="alarm-snooze"></box-icon>
                    </span>
                    <span className="action-links default">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content">
                  <div className="message-list unread">
                    <div className="avatar-wrap active">
                      <div className="avatar mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Samantha Doe <span>(Grade 7)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">Just now</span>
                        <span className="msg-preview text-ellipsis">
                          Hi! researched but I dont know the answer.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">BP</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Brendan Pollock <span>(Homework Center)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Yes thanks! I will ask the student.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">LD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Lori Denver <span>(Grade 9)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">Okay</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">JD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        John Doe <span>(Samantha's Parent)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Sure. I will ask Sam about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-block"
        isOpen={true}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div className="modal-wrap">
          <div className="modal-wrap__header">
            <h3>Task Details</h3>
            <span className="link-item">
              <box-icon name="x"></box-icon>
            </span>
          </div>
          <div className="modal-wrap__body p-0x">
            <div className="task">
              <div className="task__info">
                <h3>Research about the Romans</h3>
                <ul className="task-date">
                  <li>
                    <box-icon name="calendar"></box-icon>
                    <span>Mar 27 - Apr 3, 2021</span>
                  </li>
                  <li className="status status--error">
                    <box-icon name="error-alt"></box-icon>
                    Today
                  </li>
                </ul>
              </div>
              <div className="task__details">
                <h4>More information</h4>
                <ul className="task-list">
                  <li>
                    <box-icon name="bullseye"></box-icon>
                    <div className="task-list__content">
                      <span>Goal</span>
                      Learn Roman History
                    </div>
                  </li>
                  <li>
                    <box-icon name="error-alt"></box-icon>
                    <div className="task-list__content">
                      <span>Priority</span>
                      Not Urgent
                    </div>
                  </li>
                  <li>
                    <box-icon name="graduation" type="solid"></box-icon>
                    <div className="task-list__content">
                      <span>Goal Type</span>
                      Academic
                    </div>
                  </li>
                  <li>
                    <box-icon name="user"></box-icon>
                    <div className="task-list__content">
                      <span>Created by</span>
                      Matt Rawson on Feb 22, 2021 9:40 am
                    </div>
                  </li>
                  <li>
                    <div className="row">
                      <div className="col-6 d-flex">
                        <box-icon name="calendar"></box-icon>
                        <div className="task-list__content">
                          <span>To-do date</span>
                          Apr 1, 2021
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="task-list__content">
                          <span>Due date</span>
                          Apr 3, 2021
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="modal-wrap__footer">
            <div className="row">
              <div className="col-12 d-flex">
                <button className="btn btn--green mr-4x" type="button">
                  Mark as Completed
                </button>
                <button className="btn" type="button">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MentorPlans;
