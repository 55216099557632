import { ITerm } from "commons/types/terms";

/**
 * Returns a string identifying if a term is current, past or of future.
 * @param term - is term that is passed to identify of its timing.
 * @returns {string} - is for telling whether term is current, past or future.
 */

export const identifyIsTermCurrentPastOrFuture = (term: ITerm) => {
  const currentDate = new Date();

  if (term.isCurrent) {
    return "Current term";
  }

  if (new Date(term.startDate) < currentDate) {
    return "Past term";
  }

  if (new Date(term.startDate) > currentDate) {
    return "Future term";
  }
};
