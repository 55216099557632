import pinterpolate from "pinterpolate";

import config from "configs";
import { http } from "utils/http";

export const fetchAll = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.notification, { id: id });
  const { data } = await http.get(url);

  return data;
};

export const fetchUnseenNotifications = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.unseenNotifications, {
    id: id,
  });
  const { data } = await http.get(url);

  return data;
};

export const updateMany = async (payload: {
  ids: string[];
  isRead: boolean;
}) => {
  const { data } = await http.patch(
    config.endpoints.notifications.all,
    payload,
  );

  return data;
};

export const updateSeen = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.seenNotifications, {
    id: id,
  });

  const { data } = await http.post(url);

  return data;
};
