import React from "react";

interface IEmptyStateProps {
  image: string;
  title: string;
  description?: string;
  alt?: string;
  children?: React.ReactNode;
}

const EmptyState: React.FC<IEmptyStateProps> = ({
  image,
  title,
  description,
  alt,
  children,
}) => {
  return (
    <div className="empty-page">
      <div className="empty-page__content">
        <img src={image} alt={alt} />
        <h2 className="txt-darkgrey-color">{title}</h2>
        {description && (
          <div className="empty-page__content__body">
            <p className="txt-darkgrey-color">{description}</p>
          </div>
        )}
      </div>
      <div className="empty-page__children">{children}</div>
    </div>
  );
};

export default EmptyState;
