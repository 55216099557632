import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as accommodationService from "services/accommodation";
import {
  IUpdateAccommodation,
  IAccommodationParams,
  IAccommodationCreate,
} from "commons/types/accommodation";
import {
  AddAccommodationActionType,
  FetchAccommodationsActionType,
  UpdateAccommodationActionType,
  DeleteAccommodationActionType,
} from "store/actions/types/accommodation";

export const addAccommodation =
  (payload: IAccommodationCreate) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddAccommodationActionType.ADD_ACCOMMODATION_PENDING,
    });
    try {
      const { data } = await accommodationService.add(payload);
      dispatch({
        type: AddAccommodationActionType.ADD_ACCOMMODATION_FULFILLED,
        data: data,
      });
      success("Saved accommodation");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddAccommodationActionType.ADD_ACCOMMODATION_REJECTED,
        data: err,
      });

      error("Accommodation failed to save");
    }
  };

export const fetchAccommodations =
  (params: IAccommodationParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchAccommodationsActionType.FETCH_ACCOMMODATION_PENDING,
    });
    try {
      const { data } = await accommodationService.fetchAll(params);
      dispatch({
        type: FetchAccommodationsActionType.FETCH_ACCOMMODATION_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchAccommodationsActionType.FETCH_ACCOMMODATION_REJECTED,
        data: err,
      });

      error("Accommodations failed to load");
    }
  };

export const updateAccommodation =
  (id: string, payload: IUpdateAccommodation) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateAccommodationActionType.UPDATE_ACCOMMODATION_PENDING,
    });
    try {
      const { data } = await accommodationService.update(id, payload);
      dispatch({
        type: UpdateAccommodationActionType.UPDATE_ACCOMMODATION_FULFILLED,
        data: data,
      });
      success("Accommodation updated successfully");
    } catch (err: any) {
      dispatch({
        type: UpdateAccommodationActionType.UPDATE_ACCOMMODATION_REJECTED,
        data: err,
      });

      error("Accommodation failed to update");
    }
  };

export const deleteAccommodation =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: DeleteAccommodationActionType.DELETE_ACCOMMODATION_PENDING,
    });
    try {
      const { data } = await accommodationService.deleteOne(id);
      dispatch({
        type: DeleteAccommodationActionType.DELETE_ACCOMMODATION_FULFILLED,
        data: data,
      });
      success("Accommodation deleted successfully");
    } catch (err: any) {
      dispatch({
        type: DeleteAccommodationActionType.DELETE_ACCOMMODATION_REJECTED,
        data: err,
      });

      error("Accommodation failed to delete");
    }
  };
