import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH, ZIP_CODE_MAX, ZIP_CODE_MIN } from "constants/index";

const CenterSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Center name is required")
    .max(TITLE_LENGTH, `Name must be less than ${TITLE_LENGTH} characters`),
  address: Yup.string()
    .trim()
    .required("Street address is required")
    .max(
      TITLE_LENGTH,
      `Street address must be less than ${TITLE_LENGTH} characters`,
    ),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().trim().required("State is required"),
  zipCode: Yup.string()
    .required("Zip code is required")
    .matches(/[0-9]+/, "Zip code can not contain characters other than number")
    .min(ZIP_CODE_MIN, `Zip code must be ${ZIP_CODE_MIN} digits or more`)
    .max(ZIP_CODE_MAX, `Zip code must be ${ZIP_CODE_MAX} digits or less`),
});

export default CenterSchema;
