import Select from "react-select";
import { useState, useEffect } from "react";

import { Channel } from "twilio-chat/lib/channel";

import { error } from "utils/toast";
import eventBus from "utils/event-bus";
import Conversation from "../conversation";
import { IUser } from "commons/types/users";
import Loader from "commons/components/Loader";
import { INewMessageProps } from "./newMessage.interface";
import { getFullName, getNameInitials } from "utils/names";
import { fetchAll as fetchStudents } from "services/students";
import { CHAT_MESSAGE_COUNT_BEFORE_SCROLL } from "constants/index";

const NewMessage: React.FC<INewMessageProps> = ({
  closeModal,
  showChatHistoryOfAChannel,
}) => {
  const [searched, setSearched] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [students, setStudents] = useState<IUser[]>([]);
  const [channel, setChannel] = useState<Channel | never>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReceiver, setSelectedReceiver] = useState<IUser | never>();

  const setCurrentChannel = async (data: any) => {
    setChannel(data.channel);
  };

  useEffect(() => {
    eventBus.on("chatChannelChanged", setCurrentChannel);

    return () => {
      eventBus.remove("chatChannelChanged", setCurrentChannel);
    };
  }, []);

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
    setSearched(false);
  };

  const searchSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const students = await fetchStudents({ q: searchValue });
      setStudents(students);
    } catch (ex) {
      error("Failed to fetch students");
    }

    setSearched(true);
    setIsLoading(false);
  };

  const handleChatSelect = async (student: IUser) => {
    if (student.auth0UserId) {
      try {
        await showChatHistoryOfAChannel(student.auth0UserId, student);
        setSelectedReceiver(student);
      } catch (ex) {
        error("Failed to start chat with the user! Please try again...");
      }
    }
  };

  const noUsersFound = () => (
    <div className="center">
      <div className="margin-auto">
        <box-icon size="32px" color="#959FAE" name="error-alt" />
      </div>
      <p className="m-2x">
        We could not find any student matching your request.
      </p>
    </div>
  );

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Send a new message</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <div className="modal-wrap__body pt-2x">
        {channel && selectedReceiver ? (
          <>
            <div className="row border-bottom pb-2x mb-2x">
              <label className="col-2 mt-1x txt-darkgrey-color">
                Recipients:
              </label>
              <div className="pl-0x">
                <Select
                  value={{
                    label: getFullName(selectedReceiver),
                    value: selectedReceiver._id || "",
                  }}
                  onChange={() => {
                    setSelectedReceiver(undefined);
                  }}
                  isMulti={true}
                  options={[]}
                  isSearchable={false}
                  styles={{
                    control: () => ({
                      display: "flex",
                      padding: 0,
                      justifyContent: "space-between",
                    }),
                    dropdownIndicator: () => ({
                      display: "none",
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    indicatorsContainer: () => ({
                      display: "none",
                    }),
                    menu: () => ({ display: "none" }),
                    multiValue: () => ({
                      background: "#C7EEF9",
                      display: "flex",
                      padding: "2px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }),
                  }}
                />
              </div>
            </div>
            <Conversation
              twilioChannel={channel}
              maxMessageCount={CHAT_MESSAGE_COUNT_BEFORE_SCROLL}
              studentData={selectedReceiver}
            />
          </>
        ) : (
          <form onSubmit={searchSubmit}>
            <section className="section">
              <div className="section__content">
                <div className="input-wrap input-wrap--icon-left align-items-center">
                  <input
                    type="text"
                    className="input"
                    placeholder="Search"
                    onChange={handleSearchChange}
                    value={searchValue}
                  />
                  <span className="form-icon margin-top-02">
                    <box-icon
                      name="search"
                      size="sm"
                      color="#959FAE"
                    ></box-icon>
                  </span>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="section__content scroll-section">
                {isLoading ? (
                  <Loader type="ThreeDots" />
                ) : !students.length && searched ? (
                  noUsersFound()
                ) : (
                  students.map((student: IUser) => {
                    return (
                      <div
                        key={student._id}
                        onClick={() => handleChatSelect(student)}
                        className="card link-item card-addinfo border-bottom"
                      >
                        <div className="card-content">
                          <div
                            className={`avatar-wrap mr-2x ${
                              student?.availability.isAvailable && "active"
                            }`}
                          >
                            <div className="avatar mr-2x">
                              {student?.avatar ? (
                                <img src={student.avatar} alt="avatar" />
                              ) : (
                                getNameInitials(student)
                              )}
                            </div>
                            <span className="avatar-status">active</span>
                          </div>
                          <div className="card-text">
                            <h3>{getFullName(student)}</h3>
                            <span className="card-address">
                              {student?.userData?.schoolInfo?.grade
                                ? `Grade ${student?.userData?.schoolInfo?.grade}`
                                : student.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </section>
          </form>
        )}
      </div>
    </div>
  );
};

export default NewMessage;
