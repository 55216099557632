import { ITerm } from "commons/types/terms";

export enum TermsActionType {
  FETCH_TERMS_PENDING = "FETCH_TERMS_PENDING",
  FETCH_TERMS_REJECTED = "FETCH_TERMS_REJECTED",
  FETCH_TERMS_FULFILLED = "FETCH_TERMS_FULFILLED",

  UPDATE_TERM_STATUS_PENDING = "UPDATE_TERM_STATUS_PENDING",
  UPDATE_TERM_STATUS_REJECTED = "UPDATE_TERM_STATUS_REJECTED",
  UPDATE_TERM_STATUS_FULFILLED = "UPDATE_TERM_STATUS_FULFILLED",
}

export interface FetchTermsAction {
  type: TermsActionType;
  data: ITerm;
}
