import React from "react";
import Select, { components } from "react-select";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";

import { SCHOOL_TYPE_OPTIONS } from "constants/index";
import { IGradeType } from "commons/types/gradeTypes";
import { IDistrict } from "commons/types/institutions";
import SchoolSchema from "commons/schemas/schoolSchema";
import { TextInput } from "commons/components/form-fields";
import { ISchoolCreate, ISchoolGradeCreateType } from "commons/types/school";

interface ISchoolFormProps {
  initialValues: ISchoolCreate;
  districts: IDistrict[];
  gradeTypes: IGradeType[];
  closeModal: () => void;
  isEdit?: boolean;
  saveSchool?: (school: ISchoolCreate) => Promise<void>;
  updateSchool?: (id: string, school: ISchoolCreate) => Promise<void>;
}

interface LabelValue {
  label: string;
  value: string;
}

const Form: React.FC<ISchoolFormProps> = ({
  districts,
  closeModal,
  saveSchool,
  gradeTypes,
  initialValues,
  isEdit = false,
  updateSchool,
}) => {
  const [gradeType, setGradeType] = React.useState<ISchoolGradeCreateType[]>(
    initialValues.gradeTypes,
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SchoolSchema,
    onSubmit: async (values) => {
      if (!isEdit && saveSchool) {
        return saveSchool({
          ...values,
          gradeTypes: gradeType ? gradeType : [],
        });
      }

      if (updateSchool) {
        updateSchool(initialValues._id || "", {
          ...values,
          gradeTypes: gradeType ? gradeType : [],
        });
      }
    },
  });

  const districtOptions: LabelValue[] = districts.map((record: IDistrict) => ({
    label: record.name,
    value: record._id,
  }));

  const gradeTypeOptions: LabelValue[] = gradeTypes.map(
    (record: IGradeType) => ({
      label: record.data.range
        ? `${record.name}
          (Range ${record.data.range?.minimum} - ${record.data.range?.maximum})`
        : `${record.name}`,
      value: record._id || "",
    }),
  );

  const handleGradeTypeCheck = (event: any) => {
    if (gradeType)
      setGradeType([
        ...gradeType.map((type: ISchoolGradeCreateType) => ({
          ...type,
          isDefault: type.grade === event.target.id,
        })),
      ]);
  };

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <p>{`Selected Grade Type (${gradeType?.length})`}</p>
    </components.SingleValue>
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body pb-0x">
          <div className="row">
            <div className="col-12">
              <TextInput
                label="School Name"
                name="name"
                placeholder="Enter school name"
                touched={formik.touched}
                errors={formik.errors}
                values={formik.values}
                handleChange={formik.handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label">School District</label>
                <Field
                  name="district"
                  component={({ field, form }: { field: any; form: any }) => (
                    <Select
                      placeholder="Please select school district"
                      value={districtOptions.find(
                        (option) =>
                          districts.length && option.value === field.value,
                      )}
                      onChange={(option) => {
                        if (option)
                          form.setFieldValue(field.name, option.value);
                      }}
                      options={districtOptions}
                      classNamePrefix="react-select"
                    />
                  )}
                />
                <label className="input__error">
                  <ErrorMessage name="district" />
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label">School Type</label>
                <Field
                  name="type"
                  component={({ field, form }: { field: any; form: any }) => (
                    <Select
                      placeholder="Please select school type"
                      onChange={(option) => {
                        if (option)
                          form.setFieldValue(field.name, option.value);
                      }}
                      value={SCHOOL_TYPE_OPTIONS.find(
                        (option) => option.value === field.value,
                      )}
                      options={SCHOOL_TYPE_OPTIONS}
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-wrap">
                <label className="input__label">Grade Type</label>
                <Field
                  name="gradeTypes"
                  component={({ field, form }: { field: any; form: any }) => (
                    <Select
                      placeholder="Please select grade type"
                      onChange={(option) => {
                        if (option) {
                          if (
                            gradeType?.some(
                              (grade) => grade.grade === option.value,
                            )
                          ) {
                            return;
                          }
                          if (gradeType.length > 0) {
                            return setGradeType([
                              ...gradeType,
                              {
                                grade: option.value,
                                isDefault: false,
                              },
                            ]);
                          }
                          return setGradeType([
                            {
                              grade: option.value,
                              isDefault: true,
                            },
                          ]);
                        }
                      }}
                      value={gradeTypeOptions.filter((gradeOption) =>
                        gradeType?.some(
                          (grade) => grade.grade === gradeOption.value,
                        ),
                      )}
                      components={{ SingleValue }}
                      isSearchable={false}
                      options={gradeTypeOptions}
                      classNamePrefix="react-select"
                    />
                  )}
                />
              </div>
            </div>
            {gradeType && (
              <div className="col-12 py-2x">
                {gradeType.length > 0 && (
                  <div className="row">
                    <div className="col-10">
                      <h3 className="input__label">Selected Grade Type</h3>
                    </div>
                    <div className="col-2">
                      <p>Is default</p>
                    </div>
                  </div>
                )}
                <div className="modal-scrollable-list-view">
                  {gradeType?.map((grade: ISchoolGradeCreateType) => {
                    return (
                      <div className="row" key={grade.grade}>
                        <div className="col-10">
                          <div className="py-2x px-1x">
                            <p>
                              {
                                gradeTypes.find(
                                  (data) => data._id === grade.grade,
                                )?.name
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="py-1x px-6x">
                            <input
                              id={grade.grade}
                              type="radio"
                              className="box-links__nodes single-radio"
                              checked={grade.isDefault}
                              onChange={handleGradeTypeCheck}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-wrap__footer pt-0x">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {isEdit ? "Update" : "Save"}
              </button>
              <span className="btn txt-primary-color" onClick={closeModal}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Form;
