import { Dispatch } from "redux";

import { FetchPlanningPeriodsActionType } from "../types/planningPeriod";
import {
  fetchPlanningPeriods as fetchPlanningPeriodService,
  IPlanningPeriodsParams,
} from "services/planningPeriods";

export const fetchPlanningPeriods =
  (params: IPlanningPeriodsParams = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FetchPlanningPeriodsActionType.FETCH_PLANNING_PERIOD_PENDING,
    });
    try {
      const planningPeriods = await fetchPlanningPeriodService(params);
      dispatch({
        type: FetchPlanningPeriodsActionType.FETCH_PLANNING_PERIOD_FULFILLED,
        data: planningPeriods,
      });
    } catch (err: any) {
      dispatch({
        type: FetchPlanningPeriodsActionType.FETCH_PLANNING_PERIOD_REJECTED,
        data: err,
      });
    }
  };
