import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

const AccommodationSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(TITLE_LENGTH, `Name must be less tha ${TITLE_LENGTH} characters`)
    .required("Please enter name"),
});

export default AccommodationSchema;
