import {
  IAssessmentItem,
  SurveyListItem,
  SurveyResponse,
} from "commons/types/surveys";
import { TenantRole } from "tenant/tenant.types";

export const getAggregatedAssessments = (
  surveys: Array<SurveyListItem>,
  surveyResponses: SurveyResponse[],
) => {
  const aggregatedAssessment = surveys.reduce(
    (
      aggregatedAssessments: Array<IAssessmentItem>,
      assessment: SurveyListItem,
    ) => {
      const totalResponses = surveyResponses.filter(
        (response: any) => response.survey._id === assessment._id,
      ).length;

      if (totalResponses > 1) {
        aggregatedAssessments.push({
          _id: assessment._id,
          title: assessment.title,
          totalQuestions: assessment.skillsets.reduce((total, result) => {
            total += result.questions.length;
            return total;
          }, 0),
          totalResponses,
          assessmentType: "AGGREGATED",
          surveyType: assessment.surveyType,
          createdAt: assessment.createdDate,
          category: assessment.category,
          term: assessment.term,
        });
      }

      return aggregatedAssessments;
    },
    [],
  );
  return aggregatedAssessment;
};

export const getIndividualAssessments = (
  surveys: Array<SurveyListItem>,
  surveyResponses: SurveyResponse[],
) => {
  const individualAssessments: IAssessmentItem[] = surveys.flatMap(
    (assessment: SurveyListItem) => {
      return assessment.responderRoles.map((role: TenantRole) => ({
        _id: assessment._id,
        title: assessment.title,
        totalQuestions: assessment.skillsets.reduce((total, result) => {
          total += result.questions.length;
          return total;
        }, 0),
        totalResponses: surveyResponses.filter(
          (response: any) =>
            response.survey._id === assessment._id && response.role === role,
        ).length,
        responderRole: role,
        assessmentType: "INDIVIDUAL",
        surveyType: assessment.surveyType,
        createdAt: assessment.createdDate,
        category: assessment.category,
        term: assessment.term,
      }));
    },
  );
  return individualAssessments;
};
