import React from "react";
import Modal from "react-modal";

import EditSchoolDistrict from "./edit";
import DeleteConfirm from "commons/components/DeleteConfirm";
import { ISchoolDistrict } from "commons/types/schoolDistrict";

const SchoolDistrictActionCell = ({
  row,
  handleDeleteSchoolDistrict,
  handleUpdateSchoolDistrict,
}: any) => {
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false);

  const toggleModal = () => {
    setEditOpen(!isEditOpen);
  };

  React.useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const getInitialValues: () => ISchoolDistrict = () => {
    return {
      _id: row.original._id,
      name: row.original.name,
    };
  };

  return (
    <>
      <div className="action-group">
        <span className="link-item mr-2x" onClick={toggleModal}>
          <box-icon name="edit-alt" color="#959FAE"></box-icon>
        </span>
        <span
          className="link-item mr-2x"
          onClick={() => {
            setIsDeleteConfirmationOpen(true);
          }}
        >
          <box-icon name="trash-alt" color="#959FAE"></box-icon>
        </span>
      </div>
      <Modal isOpen={isEditOpen} className="modal-block">
        <EditSchoolDistrict
          initialValues={getInitialValues()}
          closeModal={() => setEditOpen(false)}
          updateSchoolDistrict={handleUpdateSchoolDistrict}
        />
      </Modal>
      <Modal isOpen={isDeleteConfirmationOpen} className="modal-block">
        <DeleteConfirm
          closeModal={() => setIsDeleteConfirmationOpen(false)}
          message={{
            header: "Do you want to delete this school district?",
            body: "This action is not reversible",
          }}
          deleteResource={() => {
            handleDeleteSchoolDistrict(row.original._id);
            setIsDeleteConfirmationOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
export default SchoolDistrictActionCell;
