import { connect } from "react-redux";
import React, { useEffect } from "react";
import { IUser } from "commons/types/users";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { fetchStudent } from "store/actions/data/students";
import { fetchUserProfile } from "store/actions/data/profile";
import { getNameInitials } from "utils/names";
import MentorConversation from "./MentorConversation";

export type Practitioner = {
  auth0UserId: string;
  avatar?: string;
  twilioUserSid: string;
  isAvailable: boolean;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  termName?: string;
  hasCurrentTerm?: boolean;
};

type MentorProps = {
  student: IUser;
  practitioners: Practitioner;
};

type MyMentorProps = {
  student: IUser;
  match: { params: { id: string } };
  fetchStudent: (id: string) => Promise<IUser>;
};

enum TERM_TYPE {
  CURRENT_TERM = "CURRENT_TERM",
  OTHER_TERM = "OTHER_TERM",
}

const Mentor = ({ practitioners, student }: MentorProps) => {
  return (
    <div className="section__content">
      <div className="card card-addinfo">
        <div className="card-content tw-w-full tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center tw-space-x-4">
            <div className="avatar tw-w-14 tw-h-14">
              {practitioners.avatar ? (
                <img src={practitioners.avatar} alt="Student" />
              ) : (
                getNameInitials(practitioners)
              )}{" "}
            </div>
            <div className="card-text">
              <span className="card-address">Mentor</span>
              <span className="d-block tw-font-bold tw-text-gray-600">
                {practitioners.firstName}
                {practitioners.lastName}
              </span>
              <span className="card-address">
                Term:{" "}
                {`${
                  practitioners.termName
                    ? practitioners.termName
                    : "Not Assigned"
                }`}
              </span>
            </div>
          </div>
          <MentorConversation practitioners={practitioners} student={student} />
        </div>
      </div>
      <div className="card card-addinfo">
        <div className="card-content">
          <div className="tw-flex tw-items-center tw-space-x-4">
            <div className="card-text">
              <span className="card-address">Email Address</span>
              <span className="d-block">{practitioners.email}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-addinfo">
        <div className="card-content">
          <div className="tw-flex tw-items-center tw-space-x-4">
            <div className="card-text">
              <span className="card-address">Phone number</span>
              <span className="d-block">{practitioners.phoneNumber}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MyMentor: React.FC<MyMentorProps> = ({
  match: {
    params: { id },
  },
  student,
  fetchStudent,
}) => {
  const practitioners = student?.userData?.enrollments
    ?.map((enrollment) => {
      /**
       * Currently enrollment have array of Practitioner along with the enrolled term.
       * Single Practitioner is mapped from each enrollments with the enrolled term name and status.
       * Logically each enrollment must have only one practitioner as an object instead of array.
       */
      const practitionersList = enrollment?.practitioners?.map(
        (practitionerResponse) => {
          const practitioner = {
            //   const practitioner: Practitioner = {
            ...practitionerResponse.practitioner,
            hasCurrentTerm: enrollment.enrolledTerm?.isCurrent,
            termName: enrollment.enrolledTerm?.name,
          };

          return practitioner;
        },
      );

      return practitionersList?.[0];
    })
    .filter(Boolean);

  const currentPractitioner = practitioners?.find(
    (item) => item?.hasCurrentTerm === true,
  );

  const otherPractitioners = practitioners?.filter(
    (item) => item?.hasCurrentTerm !== true,
  );

  const allPractitioners = [
    {
      type: TERM_TYPE.CURRENT_TERM,
      title: "Current term",
      practitioners: currentPractitioner,
    },
    {
      type: TERM_TYPE.OTHER_TERM,
      title: "Other term",
      practitioners: otherPractitioners,
    },
  ];

  useEffect(() => {
    fetchStudent(id);
  }, [fetchStudent, id]);

  return (
    <>
      <LeftPanel activeTab="My Mentor" student={student} />
      <div className="content-panel">
        <section className="section ml-10x">
          <h1>My Mentor</h1>
          {student &&
            allPractitioners.map((item: any) => {
              if (item.practitioners && item.type === TERM_TYPE.CURRENT_TERM) {
                return (
                  <div key={item.type}>
                    <div className="section__title mb-0x tw-mt-10">
                      <h3 className="txt-darkgrey-color">{item.title}</h3>
                    </div>
                    <Mentor
                      practitioners={item.practitioners}
                      student={student}
                    />
                  </div>
                );
              } else if (
                item.type === TERM_TYPE.OTHER_TERM &&
                item.practitioners?.length > 0
              ) {
                return (
                  <div key={item.type}>
                    <div className="section__title mb-0x tw-mt-10">
                      <h3 className="txt-darkgrey-color">{item.title}</h3>
                    </div>
                    {item.practitioners?.map((p: any) => (
                      <Mentor key={p._id} practitioners={p} student={student} />
                    ))}
                  </div>
                );
              }
            })}
        </section>
      </div>
    </>
  );
};

interface State {
  students: { fetchStudent: IUser };
}

const mapStateToProps = (state: State) => ({
  student: state.students.fetchStudent,
});

const mapDispatchToProps = {
  fetchStudent,
  fetchUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMentor);
