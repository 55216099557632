import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TERM_TITLE_LENGTH } from "constants/index";

const TermSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  academicYear: Yup.string().required("Please select the academic year"),
  termTitle: Yup.string()
    .trim()
    .max(
      TERM_TITLE_LENGTH,
      `Title must be less than ${TERM_TITLE_LENGTH} characters`,
    )
    .required("Please enter the title"),
  startDate: Yup.date().required("Please select start date"),
  endDate: Yup.date().required("Please select end date"),
  learningCenters: Yup.array().of(Yup.string()),
  isCurrent: Yup.boolean(),
});
export default TermSchema;
