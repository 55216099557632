import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as academicYearService from "services/academicYear";
import {
  IUpdateAcademicYear,
  IAcademicYearParams,
  IAcademicYearCreate,
} from "commons/types/academicYear";
import {
  AddAcademicYearActionType,
  FetchAcademicYearsActionType,
  UpdateAcademicYearActionType,
  DeleteAcademicYearActionType,
} from "store/actions/types/academicYear";

export const addAcademicYear =
  (payload: IAcademicYearCreate) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddAcademicYearActionType.ADD_ACADEMIC_YEAR_PENDING,
    });
    try {
      const { data } = await academicYearService.add(payload);
      dispatch({
        type: AddAcademicYearActionType.ADD_ACADEMIC_YEAR_FULFILLED,
        data: data,
      });
      success("Saved Academic Year");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddAcademicYearActionType.ADD_ACADEMIC_YEAR_REJECTED,
        data: err,
      });

      error("Academic Year failed to save");
    }
  };

export const fetchAcademicYears =
  (params: IAcademicYearParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchAcademicYearsActionType.FETCH_ACADEMIC_YEAR_PENDING,
    });
    try {
      const { data } = await academicYearService.fetchAll(params);
      dispatch({
        type: FetchAcademicYearsActionType.FETCH_ACADEMIC_YEAR_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchAcademicYearsActionType.FETCH_ACADEMIC_YEAR_REJECTED,
        data: err,
      });

      error("Academic Year failed to load");
    }
  };

export const updateAcademicYear =
  (id: string, payload: IUpdateAcademicYear) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateAcademicYearActionType.UPDATE_ACADEMIC_YEAR_PENDING,
    });
    try {
      const { data } = await academicYearService.update(id, payload);
      dispatch({
        type: UpdateAcademicYearActionType.UPDATE_ACADEMIC_YEAR_FULFILLED,
        data: data,
      });
      success("Academic Year updated successfully");
    } catch (err: any) {
      dispatch({
        type: UpdateAcademicYearActionType.UPDATE_ACADEMIC_YEAR_REJECTED,
        data: err,
      });

      error("Academic Year failed to update");
    }
  };

export const deleteAcademicYear =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: DeleteAcademicYearActionType.DELETE_ACADEMIC_YEAR_PENDING,
    });
    try {
      const { data } = await academicYearService.deleteOne(id);
      dispatch({
        type: DeleteAcademicYearActionType.DELETE_ACADEMIC_YEAR_FULFILLED,
        data: data,
      });
      success("Academic Year deleted successfully");
    } catch (err: any) {
      dispatch({
        type: DeleteAcademicYearActionType.DELETE_ACADEMIC_YEAR_REJECTED,
        data: err,
      });

      error("Academic Year failed to delete");
    }
  };
