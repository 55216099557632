import React from "react";
import SendAssessmentForm from "./send-assessment-form/SendAssessmentForm";

interface Props {
  toggleAssessmentForm: () => void;
  onAssessmentSentSuccess?: () => void;
}

const SendAssessment: React.FC<Props> = ({
  toggleAssessmentForm,
  onAssessmentSentSuccess,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Send an Assessment</h3>
        <span className="link-item">
          <box-icon onClick={toggleAssessmentForm} name="x" />
        </span>
      </div>
      <SendAssessmentForm
        onAssessmentSentSuccess={onAssessmentSentSuccess}
        toggleAssessmentForm={toggleAssessmentForm}
      />
    </div>
  );
};

export default SendAssessment;
