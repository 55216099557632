import React from "react";
import { useFormik } from "formik";
import { DAYS_OF_WEEK } from "constants/index";
import courseSchema from "commons/schemas/courses";
import { ICoursesInput } from "commons/types/courses";
import { checkEndDateBeforeStartDate } from "utils/dates";
import { DateInput, TextInput } from "commons/components/form-fields";
import { error } from "utils/toast";
import classNames from "classnames";

interface ICoursesFormProps {
  initialValues: ICoursesInput;
  closeModal: () => void;
  saveCourse: (payload: ICoursesInput) => any;
  isEdit: boolean;
}

const Form: React.FC<ICoursesFormProps> = ({
  initialValues,
  isEdit,
  closeModal,
  saveCourse,
}) => {
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await saveCourse(values);
        closeModal();
      } catch (err: any) {
        error("Error in updating Course");
      }
    },
    validationSchema: courseSchema,
  });

  const dayOfWeekElements = () => {
    return (
      <div className="week-list">
        <ul>
          {DAYS_OF_WEEK.map((day: string) => (
            <li
              key={day}
              className={classNames({
                selected: formik.values.daysOfWeek.includes(day),
              })}
              onClick={() => {
                formik.setFieldTouched("daysOfWeek", true);
                const indexOfDay = formik.values.daysOfWeek.indexOf(day);
                if (indexOfDay !== -1) {
                  const newDaysOfWeek = [...formik.values.daysOfWeek];
                  newDaysOfWeek.splice(indexOfDay, 1);
                  formik.setFieldValue("daysOfWeek", newDaysOfWeek);
                } else {
                  const newDaysOfWeek = [...formik.values.daysOfWeek, day];
                  formik.setFieldValue("daysOfWeek", newDaysOfWeek);
                }
              }}
            >
              <span className="item">{day[0].toString().toUpperCase()}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body scroll-modal-content pb-0x">
        <div className="row">
          <div className="col-12">
            <TextInput
              label={"Title of the course"}
              placeholder={"Title"}
              handleChange={formik.handleChange}
              touched={formik.touched}
              name={"name"}
              errors={formik.errors}
              values={formik.values}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextInput
              label={"Teacher"}
              placeholder={"Teacher"}
              handleChange={formik.handleChange}
              touched={formik.touched}
              name={"teacher"}
              errors={formik.errors}
              values={formik.values}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label required">Time</label>
              <input
                type="time"
                className={classNames("input", {
                  "input--error": formik.touched.time && formik.errors.time,
                })}
                placeholder="Please select"
                name="time"
                value={formik.values.time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="input__error">
                {formik.touched.time && formik.errors.time}
              </label>
            </div>
          </div>
          <div className="col-6">
            <TextInput
              label="Session Duration(in hours)"
              name="sessionDuration"
              handleChange={formik.handleChange}
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              placeholder="Duration"
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label htmlFor="firstDay" className="input__label">
                First Day of Class
              </label>
              <DateInput
                id="firstDay"
                name="firstDay"
                selected={formik.values.firstDay}
                onChange={(date) => {
                  formik.setFieldValue("firstDay", date);
                  if (
                    checkEndDateBeforeStartDate(date, formik.values.lastDay)
                  ) {
                    formik.setFieldValue("lastDay", date);
                  }
                }}
                onBlur={formik.handleBlur}
                dateFormat="MMM d,yyyy"
              />
            </div>
            {formik.errors.firstDay && formik.touched.firstDay ? (
              <p className="input__error">{formik.errors.firstDay}</p>
            ) : null}
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <label htmlFor="firstDay" className="input__label">
                Last Day of Class
              </label>
              <DateInput
                id="lastDay"
                name="lastDay"
                selected={formik.values.lastDay}
                onChange={(date) => {
                  formik.setFieldValue("lastDay", date);
                }}
                onBlur={formik.handleBlur}
                dateFormat="MMM d,yyyy"
                minDate={new Date(formik.values.firstDay)}
              />
            </div>
            {formik.errors.lastDay && formik.touched.lastDay ? (
              <p className="input__error">{formik.errors.lastDay}</p>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-2x">
            <span className="input__labe required">
              What days of the week will the student take the course?
            </span>
            {dayOfWeekElements()}
            {formik.touched.daysOfWeek && formik.errors.daysOfWeek ? (
              <label className="input__error">{formik.errors.daysOfWeek}</label>
            ) : null}
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={
                formik.isSubmitting ||
                (isEdit && formik.values === formik.initialValues)
              }
            >
              {isEdit ? "Save" : "Add course"}
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
