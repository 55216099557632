import { ChangeEventHandler, FC, ReactElement } from "react";

import { getFieldValue } from "utils/arrays";

interface ITextAreaInputProps {
  label: string;
  name: string;
  placeholder: string;
  errors: any;
  touched: any;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  handleBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  values: any;
}

const TextAreaInput: FC<ITextAreaInputProps> = ({
  label,
  placeholder,
  name,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
}): ReactElement => {
  return (
    <div className="input-wrap">
      <label className="input__label">{label}</label>
      <textarea
        className="textarea"
        placeholder={placeholder}
        name={name}
        value={values}
        onChange={handleChange}
        onBlur={handleBlur}
      ></textarea>
      {getFieldValue(touched, name) && getFieldValue(errors, name) && (
        <label className="input__error">{getFieldValue(errors, name)}</label>
      )}
    </div>
  );
};

export default TextAreaInput;
