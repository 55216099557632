import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { EMAIL_BODY_LENGTH, MESSAGE_LENGTH } from "constants/index";

const EmailSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  body: Yup.string().max(
    EMAIL_BODY_LENGTH,
    `Message must be less than or equal to ${EMAIL_BODY_LENGTH} characters.`,
  ),
  subject: Yup.string()
    .max(
      MESSAGE_LENGTH,
      `Subject must be less than or equal to ${MESSAGE_LENGTH} characters.`,
    )
    .required("Please enter the subject."),
  to: Yup.array().of(Yup.string()),
});
export default EmailSchema;
