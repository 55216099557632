import Modal from "react-modal";
import { connect } from "react-redux";
import { Column, useTable } from "react-table";
import { useEffect, useMemo, useState } from "react";

import AddSatelliteLocation from "./add";
import { ILocation } from "commons/types";
import EditSatelliteLocation from "./edit";
import { RootState } from "store/reducers";
import SchoolImage from "assets/images/school.svg";
import EmptyState from "commons/components/EmptyState";
import SettingsPanel from "pages/layouts/intake/settingsPanel";
import { ISatelliteLocations } from "./satelliteLocation.interface";
import {
  ISatelliteLocation,
  ISatelliteLocationResponse,
} from "commons/types/satelliteLocations";
import {
  addSatelliteLocation,
  updateSatelliteLocation,
  fetchSatelliteLocations,
  deleteSatelliteLocation,
} from "store/actions/data/satelliteLocation";
import Loader from "commons/components/Loader";
import DeleteConfirm from "commons/components/DeleteConfirm";

const SatelliteLocation: React.FC<ISatelliteLocations> = ({
  satelliteLocations,
  addSatelliteLocation,
  updateSatelliteLocation,
  fetchSatelliteLocations,
  satelliteLocationLoading,
  deleteSatelliteLocation,
}) => {
  const [isAddSatelliteLocationOpen, setIsAddSatelliteLocationOpen] =
    useState(false);
  const [activeEditSatelliteLocation, setActiveEditSatelliteLocation] =
    useState<null | ISatelliteLocation>(null);
  const [activeDeleteSatelliteLocation, setActiveDeleteSatelliteLocation] =
    useState<null | string>(null);

  const saveSatelliteLocation = async (
    satelliteLocation: ISatelliteLocation,
  ) => {
    const savedSatelliteLocation = await addSatelliteLocation(
      satelliteLocation,
    );
    if (savedSatelliteLocation) {
      setIsAddSatelliteLocationOpen(false);
      fetchSatelliteLocations({});
    }
    return satelliteLocation;
  };

  const removeSatelliteLocation = async (id: string) => {
    const deleteResult = await deleteSatelliteLocation(id);
    if (deleteResult) {
      fetchSatelliteLocations({});
      setActiveDeleteSatelliteLocation(null);
    }

    return deleteResult;
  };

  const editSatelliteLocation = async (
    id: string,
    satelliteLocation: ISatelliteLocation,
  ) => {
    const updatedSatelliteLocation = await updateSatelliteLocation(
      id,
      satelliteLocation,
    );
    if (updatedSatelliteLocation) {
      setActiveEditSatelliteLocation(null);
      fetchSatelliteLocations({});
    }
    return satelliteLocation;
  };

  useEffect(() => {
    fetchSatelliteLocations({});
  }, [fetchSatelliteLocations]);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "address",
          Cell: ({ value }: { value: string }) => value,
        },
        {
          Header: "Center",
          accessor: "location",
          Cell: ({ value }: { value: ILocation }) => value?.name || "-",
        },
        {
          Header: "Actions",
          accessor: "_id",
          Cell: ({
            row: { original },
          }: {
            row: { original: ISatelliteLocationResponse };
          }) => (
            <div className="action-group">
              <span
                onClick={() =>
                  setActiveEditSatelliteLocation({
                    _id: original._id,
                    address: original.address,
                    location: original.location?._id || "",
                  })
                }
                className="link-item mr-2x"
              >
                <box-icon name="edit-alt" color="#959FAE" />
              </span>
              <span
                onClick={() =>
                  original._id && setActiveDeleteSatelliteLocation(original._id)
                }
                className="link-item mr-2x"
              >
                <box-icon name="trash-alt" color="959FAE" />
              </span>
            </div>
          ),
        },
      ] as Column<ISatelliteLocation>[],
    [],
  );

  const tableInstance = useTable({ columns, data: satelliteLocations });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="Satellite Location" />
          <div className="content-panel">
            <div className="page-heading">
              <h1>Satellite Locations</h1>
              {!!satelliteLocations.length && (
                <div className="page-heading__right align-items-center">
                  <button
                    className="btn btn--primary"
                    type="button"
                    onClick={() => setIsAddSatelliteLocationOpen(true)}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
            {satelliteLocationLoading ? (
              <Loader type="ThreeDots" />
            ) : satelliteLocations.length ? (
              <div className="table-responsive">
                <table {...getTableProps()} className="table">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="head-row"
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className="table__col"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="table__row">
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()} className="table__col">
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Modal
                  isOpen={!!activeDeleteSatelliteLocation}
                  className="modal-block"
                  ariaHideApp={false}
                >
                  <DeleteConfirm
                    closeModal={() => setActiveDeleteSatelliteLocation(null)}
                    deleteResource={() => {
                      activeDeleteSatelliteLocation &&
                        removeSatelliteLocation(activeDeleteSatelliteLocation);
                    }}
                    message={{
                      header: "Do you want to delete this satellite location?",
                      body: "Deleting a satellite location will delete permanently. This action is not reversible.",
                    }}
                  />
                </Modal>
              </div>
            ) : (
              <EmptyState
                image={SchoolImage}
                title="You currently do not have any Satellite Locations."
                description=""
                alt="location"
              >
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => setIsAddSatelliteLocationOpen(true)}
                >
                  Add Satellite Location
                </button>
              </EmptyState>
            )}
          </div>
          <div className="right-gap" />
        </div>
      </div>
      <Modal
        isOpen={isAddSatelliteLocationOpen}
        className="modal-block"
        ariaHideApp={false}
        onRequestClose={() => setIsAddSatelliteLocationOpen(false)}
      >
        <AddSatelliteLocation
          closeModal={() => setIsAddSatelliteLocationOpen(false)}
          saveSatelliteLocation={saveSatelliteLocation}
        />
      </Modal>
      <Modal
        isOpen={!!activeEditSatelliteLocation}
        className="modal-block"
        ariaHideApp={false}
        onRequestClose={() => setActiveEditSatelliteLocation(null)}
      >
        {activeEditSatelliteLocation && (
          <EditSatelliteLocation
            closeModal={() => setActiveEditSatelliteLocation(null)}
            initialValues={activeEditSatelliteLocation}
            editSatelliteLocation={editSatelliteLocation}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  satelliteLocations: state.satelliteLocations.fetchSatelliteLocations,
  satelliteLocationLoading:
    state.satelliteLocations.fetchSatelliteLocationsLoading,
});

const mapDispatchToProps = {
  addSatelliteLocation,
  updateSatelliteLocation,
  fetchSatelliteLocations,
  deleteSatelliteLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SatelliteLocation);
