import { Dispatch } from "redux";

import { error } from "utils/toast";

import {
  FetchFinalConfigType,
  FetchInitialConfigType,
  FetchTenantsType,
  AddTenantType,
  FetchAllUsersType,
  FetchTenantUserListType,
} from "store/actions/types/multitenancy";
import {
  fetchInitialTenanatConfigs,
  fetchFinalConfigs,
  fetchTenantsList,
  addTenant,
  fetchUsers,
} from "services/multitenancy";
import * as multitenancyService from "services/multitenancy";

export const fetchTenantInitialConfig = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchInitialConfigType.FETCH_INITIAL_CONFIG_PENDING,
  });

  try {
    const initialConfigs = await fetchInitialTenanatConfigs();

    dispatch({
      type: FetchInitialConfigType.FETCH_INITIAL_CONFIG_SUCCESS,
      data: initialConfigs,
    });
  } catch (err) {
    error("Failed to get inital configs");
    dispatch({
      type: FetchInitialConfigType.FETCH_INITIAL_CONFIG_ERROR,
      data: error,
    });
  }
};

export const fetchTenantFinalConfigs = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchFinalConfigType.FETCH_FINAL_CONFIG_PENDING,
  });

  try {
    const initialConfigs = await fetchFinalConfigs();

    dispatch({
      type: FetchFinalConfigType.FETCH_FINAL_CONFIG_SUCCESS,
      data: initialConfigs,
    });
  } catch (err) {
    error("Failed to get inital configs");
    dispatch({
      type: FetchFinalConfigType.FETCH_FINAL_CONFIG_ERROR,
      data: error,
    });
  }
};

export const fetchAllTenantsList = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchTenantsType.FETCH_TENANTS_PENDING,
  });

  try {
    const tenantsList = await fetchTenantsList();

    dispatch({
      type: FetchTenantsType.FETCH_TENANTS_SUCCESS,
      data: tenantsList,
    });
  } catch (err) {
    dispatch({
      type: FetchTenantsType.FETCH_TENANTS_ERROR,
    });
  }
};

export const addNewTenant = (payload: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddTenantType.ADD_TENANT_PENDING,
  });

  try {
    const data = await addTenant(payload);

    dispatch({
      type: AddTenantType.ADD_TENANT_SUCCESS,
      data: data,
    });
  } catch (err) {
    dispatch({
      type: AddTenantType.ADD_TENANT_ERROR,
    });
  }
};

export const fetchAllUsers = () => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchAllUsersType.FETCH_USERS_PENDING,
  });

  try {
    const data = await fetchUsers();

    dispatch({
      type: FetchAllUsersType.FETCH_USERS_SUCCESS,
      data: data,
    });
  } catch (err) {
    dispatch({
      type: FetchAllUsersType.FETCH_USERS_ERROR,
    });
  }
};

export const fetchTenantUserList =
  (payload: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchTenantUserListType.FETCH_TENANT_USERLIST_PENDING,
    });

    try {
      const data = await multitenancyService.fetchTenantUserList(payload);

      dispatch({
        type: FetchTenantUserListType.FETCH_TENANT_USERLIST_SUCCESS,
        data,
      });
    } catch (err) {
      dispatch({
        type: FetchTenantUserListType.FETCH_TENANT_USERLIST_ERROR,
      });
    }
  };
