import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useState } from "react";

import AddAcademicYear from "./add";
import AcademicYearList from "./list";
import { RootState } from "../../store/reducers";
import Loader from "../../commons/components/Loader";
import educationImage from "assets/images/education.svg";
import { IAcademicYears } from "./academicYear.interface";
import SettingsPanel from "../layouts/intake/settingsPanel";
import EmptyState from "../../commons/components/EmptyState";
import { IAcademicYear } from "../../commons/types/academicYear";
import {
  addAcademicYear,
  fetchAcademicYears,
} from "../../store/actions/data/academicYear";

const AcademicYear = (props: IAcademicYears) => {
  const {
    academicYears,
    addAcademicYear,
    academicYearsLoading,
    fetchAcademicYears,
  } = props;

  const [isAddAcademicYearOpen, setAddAcademicYearOpen] = useState(false);

  React.useEffect(() => {
    fetchAcademicYears({});
  }, [fetchAcademicYears]);

  const handleAddAcademicYear = async (academicYear: IAcademicYear) => {
    const addAcademicYearResponse = await addAcademicYear({
      name: academicYear.year,
    });
    setAddAcademicYearOpen(false);

    if (addAcademicYearResponse) {
      fetchAcademicYears({});
    }
  };

  if (academicYearsLoading) {
    return <Loader type="ThreeDots" />;
  }

  const EmptyAcademicYear = () => {
    return (
      <div className="content-panel">
        <h1>Academic Years</h1>
        <EmptyState
          image={educationImage}
          title="You currently do not have any Academic Years."
          description="Please add some academic year to get some work done."
          alt="academic year"
        >
          <button
            className="btn btn--primary"
            type="button"
            onClick={() => setAddAcademicYearOpen(true)}
          >
            Add Academic Year
          </button>
        </EmptyState>
      </div>
    );
  };

  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="Academic Year" />
          {academicYears.length <= 0 ? (
            <EmptyAcademicYear />
          ) : (
            <AcademicYearList
              academicYears={academicYears}
              onAddButtonClicked={() => setAddAcademicYearOpen(true)}
            />
          )}
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddAcademicYearOpen}
        ariaHideApp={false}
        onRequestClose={() => setAddAcademicYearOpen(false)}
      >
        <AddAcademicYear
          closeModal={() => setAddAcademicYearOpen(false)}
          saveAcademicYear={handleAddAcademicYear}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  academicYears: state.academicYears.fetchAcademicYears,
  academicYearsLoading: state.academicYears.fetchAcademicYearsLoading,
});

const mapDispatchToProps = {
  addAcademicYear,
  fetchAcademicYears,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcademicYear);
