import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { error, success } from "utils/toast";
import { Status } from "constants/status";
import { ILocation } from "commons/types";
import { fetchLocations } from "services/tenant";
import mentorSchema from "commons/schemas/mentorUpdate";
import { Environment } from "constants/learningEnvironment";
import { IFormValues, IUpdateParams } from "./updateparams.interface";
import {
  CustomSelect,
  OptionsInput,
  TextInput,
} from "commons/components/form-fields";
import PhoneNumber from "commons/components/form-fields/PhoneNumber";
import useLogout from "commons/hooks/useLogout";

const Form = ({
  initialValues,
  id,
  closeModal,
  updateMentorInformation,
  isFromIntakeManager,
}: IUpdateParams) => {
  const { t } = useTranslation();
  const [center, setCenter] = useState<any>([]);
  const [centers, setCenters] = useState([]);

  const { logout } = useLogout();

  const onEmailChangeLogout = () => {
    logout();
  };

  const formik: any = useFormik({
    initialValues: {
      ...initialValues,
      // To change string of learning environment to array for checkbox as per required design
      environment:
        initialValues.environment === Environment.HYBRID
          ? [Environment.REMOTE, Environment.IN_PERSON]
          : [initialValues.environment],
    },

    onSubmit: async (values, { resetForm }) => {
      try {
        // To change array input of checkbox into string enum of Environment
        const toSubmit: IFormValues = {
          ...values,
          email: values.isEmailChangeActive
            ? values.newEmail || ""
            : values.email,
          environment:
            values.environment.length > 1
              ? Environment.HYBRID
              : values.environment[0],
        };
        await updateMentorInformation({ ...toSubmit, id });
        closeModal();

        if (formik.values.isEmailChangeActive && !isFromIntakeManager) {
          success("Email changed successfully! Logging out.");
          onEmailChangeLogout();
        }
      } catch (err: any) {
        error("Mentor Edit failed");
      } finally {
        resetForm();
        setCenter(null);
      }
    },
    validationSchema: mentorSchema,
  });

  useEffect(() => {
    const fetchLearningCenters = async () => {
      const { data } = await fetchLocations();
      const centerOptions = data.map((location: ILocation) => {
        return { value: location._id, label: location.name };
      });
      setCenters(centerOptions);
      setCenter(
        centerOptions.filter((data: any) => {
          return initialValues?.learningCenter
            ?.map((center: any) => center._id)
            ?.includes(data.value);
        }),
      );
    };
    fetchLearningCenters();
  }, [initialValues]);

  const getEnvironmentOptions = (formik: any) => [
    {
      id: "remote",
      label: t("mentorForm.learningEnv.options.remote"),
      value: Environment.REMOTE,
      isChecked: formik.values.environment.includes(Environment.REMOTE),
    },
    {
      id: "in-person",
      label: t("mentorForm.learningEnv.options.inPerson"),
      value: Environment.IN_PERSON,
      isChecked: formik.values.environment.includes(Environment.IN_PERSON),
    },
  ];

  const getStatusOptions = (formik: any) => [
    {
      id: "active",
      label: t("mentorForm.status.options.active"),
      value: Status.ACTIVE,
      isChecked: formik.values.status === Status.ACTIVE,
    },
    {
      id: "in-active",
      label: t("mentorForm.status.options.inActive"),
      value: Status.INACTIVE,
      isChecked: formik.values.status === Status.INACTIVE,
    },
  ];

  // const handleIsEmailChangeActive = () => {
  //   formik.setFieldValue(
  //     "isEmailChangeActive",
  //     !formik.values.isEmailChangeActive,
  //   );
  // };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body">
        <div className="row">
          <div className="col-6 pr-2x">
            <TextInput
              label={t("mentorForm.firstName.label")}
              name={"firstName"}
              placeholder={t("mentorForm.firstName.label")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
          <div className="col-6 pl-2x">
            <TextInput
              label={t("mentorForm.lastName.label")}
              name={"lastName"}
              placeholder={t("mentorForm.lastName.label")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <PhoneNumber
              label={t("mentorForm.phone.label")}
              name={"phone"}
              placeholder={t("mentorForm.phone.placeholder")}
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mb-3x required">
              {t("mentorForm.learningEnv.label")}
            </div>
            <OptionsInput
              type={"checkbox"}
              groupName={"environment"}
              options={getEnvironmentOptions(formik)}
              handleChange={formik.handleChange}
              errors={formik.errors}
              touched={formik.touched}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 multi-select">
            <CustomSelect
              label={t("mentorForm.learningCenter.label")}
              name={"learningCenter"}
              value={center}
              isMulti={true}
              options={centers}
              handleChange={(value: string, formikFieldValue: any) => {
                formik.setFieldValue("learningCenter", formikFieldValue);
                setCenter(value);
              }}
              touched={formik.touched}
              errors={formik.errors}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-wrap">
              <div className="justify-content-between d-flex">
                <label className="input__label">Current Email</label>
                <div className="link-text fs-small">
                  {!formik.values.isEmailChangeActive && (
                    <span
                      onClick={() =>
                        formik.setFieldValue("isEmailChangeActive", true)
                      }
                    >
                      Change
                    </span>
                  )}
                </div>
              </div>

              <input
                disabled={true}
                type="text"
                placeholder={formik.values.email}
                className="input"
                name="email"
              />
            </div>
          </div>
        </div>
        {formik.values.isEmailChangeActive && (
          <div>
            <div className="row">
              <div className="col-12">
                <div className="justify-content-between d-flex">
                  <h2 className="txt-bold text-large mt-2x">
                    Change Email Address
                  </h2>
                  <div className="link-text fs-small mt-2x">
                    <span
                      onClick={() =>
                        formik.setFieldValue("isEmailChangeActive", false)
                      }
                    >
                      Cancel
                    </span>
                  </div>
                </div>
                <label className="input__label text-small color-grey-50 mb-4x mt-1x">
                  This change will send notification to current email.
                </label>
                <TextInput
                  label={"New email address."}
                  name={"newEmail"}
                  disabled={false}
                  placeholder={"Enter new email address."}
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label={"Confirm email address."}
                  name={"newEmailConfirm"}
                  disabled={!formik.values.newEmail}
                  placeholder={t("Confirm email address.")}
                  touched={formik.touched}
                  errors={formik.errors}
                  values={formik.values}
                  handleChange={formik.handleChange}
                  required={true}
                />
              </div>
            </div>
          </div>
        )}
        {isFromIntakeManager ? (
          <div className="row">
            <div className="col-12">
              <div className="mb-3x required">
                {t("mentorForm.status.label")}
              </div>
              <OptionsInput
                type={"radio"}
                groupName={"status"}
                className="mb-0x"
                options={getStatusOptions(formik)}
                handleChange={formik.handleChange}
                errors={formik.errors}
                touched={formik.touched}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={
                formik.isSubmitting || formik.initialValues === formik.values
              }
            >
              {t("mentorForm.saveMentorBtnLabel")}
            </button>
            <span
              className="btn btn-text txt-primary-color"
              onClick={closeModal}
            >
              {t("mentorForm.cancel")}
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
