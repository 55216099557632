import React, { useEffect, useState } from "react";
import {
  // DateInput,
  OptionsInput,
  TextInput,
} from "commons/components/form-fields";
import {
  // ErrorMessage, Field,
  Form,
  useFormik,
  FormikProvider,
} from "formik";

// import Select from "react-select";
import { connect } from "react-redux";

import {
  fetchTenantInitialConfig,
  fetchAllTenantsList,
} from "store/actions/data/multitenancy";
import { ITenantUser } from "store/actions/types/multitenancy";
import Loader from "commons/components/Loader";
import { AddEditUserProps } from "./AddEditUser";
import AddEditUserSchema from "./addEditUserSchema";
// import { getFieldValue } from "utils/arrays";

interface AddEditUserFormProps extends AddEditUserProps {
  onGradeUpdateSuccess?: () => void;
  fetchAllTenantsList: () => void;
  isTenantNew?: boolean;
  tenants: any;
  fetchTenantsLoading: boolean;
  tenantId: string;
}

interface FormValues {
  // firstName: string;
  // middleName: string;
  // lastName: string;
  // tenantId: string;
}

const AddEditUserForm: React.FC<AddEditUserFormProps> = ({
  // initialValues,
  closeModal,
  fetchAllTenantsList,
  fetchTenantsLoading,
  tenants,
  handleUserSave,
  selectedRow,
  isEditing,
  tenantId,
}) => {
  const [initialValues, setInitialValues] = useState<ITenantUser>({
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    dateOfBirth: "",
    roles: ["TENANT_ADMIN"],
    tenant: tenantId,
    email: "",
  });

  useEffect(() => {
    if (isEditing) {
      setInitialValues(selectedRow);
    }
  }, [isEditing, selectedRow]);

  useEffect(() => {
    fetchAllTenantsList();
  }, [fetchAllTenantsList]);

  const formik = useFormik<any>({
    initialValues: initialValues,
    onSubmit: (formValues: FormValues) => {
      handleUserSave(formik.values);
    },
    validationSchema: AddEditUserSchema,
    enableReinitialize: true,
  });

  if (fetchTenantsLoading) {
    return <Loader type="ThreeDots" />;
  }

  // const tenantOptions = tenants.data.map((tenant: ITenantDetail) => {
  //   return {
  //     value: tenant._id,
  //     label: tenant.name,
  //   };
  // });

  return (
    <>
      {" "}
      {fetchTenantsLoading ? (
        <Loader type="ThreeDots" />
      ) : (
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="modal-wrap__body">
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="First Name"
                    name="firstName"
                    placeholder="First name"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={formik.handleChange}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <TextInput
                    label="Middle Name"
                    name="middleName"
                    placeholder="Middle name"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Last Name"
                    name="lastName"
                    placeholder="Last name"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={formik.handleChange}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Email Address"
                    name="email"
                    placeholder="Email address"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 mt-4x">
                  <TextInput
                    required
                    label="Phone Number"
                    name="phone"
                    placeholder="Phone number"
                    touched={formik.touched}
                    errors={formik.errors}
                    values={formik.values}
                    handleChange={formik.handleChange}
                  />
                </div>
                <div className="col-6 mt-4x">
                  <div className="input-wrap">
                    <label className="input__label tw-mb-1">Roles</label>
                    <OptionsInput
                      type={"checkbox"}
                      groupName={"roles"}
                      options={[
                        // {
                        //   id: "admin",
                        //   label: "Admin",
                        //   value: "ADMIN",
                        //   isChecked: formik.values.roles.includes("ADMIN"),
                        // },
                        // {
                        //   id: "superAdmin",
                        //   label: "Super admin",
                        //   value: "SUPER_ADMIN",
                        //   isChecked:
                        //     formik.values.roles.includes("SUPER_ADMIN"),
                        // },
                        {
                          id: "tentantAdmin",
                          label: "Tenant admin",
                          value: "TENANT_ADMIN",
                          isChecked: true,
                        },
                      ]}
                      handleChange={formik.handleChange}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </div>
                </div>
                {/* <div className="col-6 mt-4x">
                  <div className="input-wrap">
                    <label className={`input__label required`}>Tenant</label>
                    <Field
                      name="tenant"
                      component={() => {
                        return (
                          <>
                            <Select
                              isDisabled={true}
                              placeholder="Intake Manager"
                              onChange={(e: any) => {
                                formik.setFieldValue("tenant", e.value);
                              }}
                              value={tenantOptions.find(
                                (tenant: any) =>
                                  tenant.value === formik.values.tenant,
                              )}
                              options={tenantOptions}
                              classNamePrefix="react-select"
                            />
                            <label className="input__error">
                              <ErrorMessage name="tenantId" />
                            </label>
                          </>
                        );
                      }}
                    />
                  </div>
                </div> */}
                {/* <div className="col-6 mt-4x">
                  <label className="input__label required">Date of birth</label>
                  <DateInput
                    isValid={formik.errors.dateOfBirth === undefined}
                    name="dateOfBirth"
                    selected={formik.values.dateOfBirth || ""}
                    maxDate={new Date()}
                    onChange={(date) => {
                      formik.setFieldValue("dateOfBirth", date);
                    }}
                    placeholder="Please select"
                    onBlur={formik.handleBlur}
                    dateFormat="MM/dd/yyyy"
                  />
                  <label className="input__error">
                    {getFieldValue(formik.errors, "dateOfBirth")}
                  </label>
                </div> */}
              </div>

              <div className="modal-wrap__footer pt-0x d-flex">
                <div className="row w-100p ">
                  <div className="col-12 status-toggle ">
                    <div className="d-flex">
                      <button className="btn btn--primary mr-4x" type="submit">
                        {isEditing ? "Save" : "Add"}
                      </button>
                      <button
                        disabled={formik.isSubmitting}
                        onClick={closeModal}
                        className="btn txt-primary-color"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  tenants: state.multitenancy.tenantsList,
  fetchTenantsLoading: state.multitenancy.fetchTenantsLoading,
  addTenantLoading: state.multitenancy.addTenantLoading,
});

const mapDispatchToProps = {
  fetchTenantInitialConfig,
  fetchAllTenantsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUserForm);
