import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";
import "boxicons";

const MentorEmpty = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                <li className="nav-left__item">
                  <span>
                    <box-icon name="arrow-back"></box-icon>
                    Go Back
                  </span>
                </li>
                <li className="nav-left__item--title">Samantha Doe</li>
                <li className="nav-left__item">
                  <span>
                    <box-icon name="calendar-check"></box-icon>
                    Plans
                  </span>
                </li>
                <li className="nav-left__item--active">
                  <span>
                    <box-icon name="bullseye"></box-icon>
                    Goals
                  </span>
                </li>
                <li className="nav-left__item">
                  <span>
                    <box-icon name="book-open"></box-icon>
                    Assignments
                  </span>
                </li>
                <li className="nav-left__item">
                  <span>
                    <box-icon name="calendar"></box-icon>
                    Sessions
                  </span>
                </li>
                <li className="nav-left__item--title">
                  Additional information
                </li>
                <li className="nav-left__item">
                  <span>
                    <box-icon name="user"></box-icon>
                    Personal Info
                  </span>
                </li>
                <li className="nav-left__item">
                  <span>
                    <box-icon name="graduation" type="solid"></box-icon>
                    Academic Info
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">sd</div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <span className="d-flex">
                    <span>
                      <box-icon name="arrow-back"></box-icon>
                    </span>
                    <h3>Samantha Doe</h3>
                  </span>
                  <div className="message-actions">
                    <span className="action-links link">
                      <box-icon name="phone-call"></box-icon>
                    </span>
                    <span className="action-links link">
                      <box-icon name="video"></box-icon>
                    </span>
                    <span className="action-links">
                      <box-icon name="dots-horizontal-rounded"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content chat-box">
                  <div className="scroll">
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>
                            Hey Samantha, glad to beyou mentor. Please
                            don’thesitate to reach outwhenever you need help!
                          </p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Likewise. Thanks for your help. looking fwd.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>
                            Hey Samantha, glad to beyou mentor. Please
                            don’thesitate to reach outwhenever you need help!
                          </p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Likewise. Thanks for your help. looking fwd.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>
                            Hey Samantha, glad to beyou mentor. Please
                            don’thesitate to reach outwhenever you need help!
                          </p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Looking fwd.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Hey Samantha!</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list incoming">
                      <div className="avatar avatar--sm mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Thanks.</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble">
                          <p>Hey</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                    <div className="chat-list outgoing">
                      <div className="bubble-wrap">
                        <div className="bubble-reply">
                          <box-icon name="calendar-check"></box-icon>
                          <p>Re: Research about the Romans</p>
                        </div>
                        <div className="bubble">
                          <p>Hey</p>
                        </div>
                        <div className="msg-time">3:40 pm</div>
                      </div>
                      <div className="avatar avatar--sm ml-2x">
                        <img src={student1} alt="logo" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="message__reply status-block status-block--info">
                  <div className="message__reply--content">
                    <box-icon name="calendar-check"></box-icon>
                    Re: Research about the Romans
                  </div>
                  <box-icon name="x"></box-icon>
                </div>
                <div className="message__box">
                  <div className="input-wrap">
                    <textarea className="input"></textarea>
                  </div>
                  <button type="submit">
                    <box-icon name="send"></box-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorEmpty;
