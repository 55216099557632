import * as React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";

import Loader from "commons/components/Loader";
import { ComponentStatus } from "commons/types/status";
import useMountedRef from "commons/hooks/useMountedRef";
import { IReportModal, IReportModalProps } from "commons/types/report";

import { error } from "utils/toast";
import { get } from "services/report";
import MentorReport from "./MentorReport";
import { IStudentEnrollment } from "commons/types/users";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { fetchPlanningPeriods } from "store/actions/data/planningPeriods";
import { getCurrentEnrollment, getStudentEnrollments } from "utils/user";

const BASE_STATE = {
  status: ComponentStatus.Idle,
  data: {
    planningPeriod: {
      _id: "",
      startDate: "",
      endDate: "",
      user: {
        firstName: "",
        lastName: "",
        userData: {
          type: "Subject",
          enrollments: [],
        },
        relatedUsers: {
          practitioners: [],
          sponsors: [],
        },
      },
    },
    assignments: {},
    tasks: [],
    goals: [],
    courses: [],
    info: {
      _id: "",
      firstName: "",
      lastName: "",
      userData: {
        type: "Subject",
        enrollments: [],
        schoolInfo: {
          grade: "",
          institution: {
            _id: "",
            name: "",
          },
        },
      },
      relatedUsers: {
        practitioners: [],
        sponsors: [],
      },
    },
  },
};

const ReportModal: React.FunctionComponent<IReportModalProps> = ({
  closeModal,
  student,
  planningPeriods,
  fetchPlanningPeriods,
}) => {
  const [componentState, setComponentState] =
    React.useState<IReportModal>(BASE_STATE);
  const isMounted = useMountedRef();

  const [currentPlan, setCurrentPlan] = React.useState({
    id: "",
    startDate: "",
    endDate: "",
  });

  const currentEnrollment = getCurrentEnrollment(student);

  const studentEnrollments = getStudentEnrollments(student);

  const studentTermOptions =
    studentEnrollments
      ?.filter((term) => term.enrolledTerm)
      .map((term: IStudentEnrollment) => {
        return { label: term.enrolledTerm.name, value: term.enrolledTerm._id };
      }) || [];

  const [termId, setTermId] = React.useState(
    currentEnrollment?.enrolledTerm._id || "",
  );

  const getReportData = React.useCallback(
    async (
      id: string,
      params: { startDate: string; endDate: string; termId: string },
    ) => {
      setComponentState({
        status: ComponentStatus.Loading,
        data: {},
      });

      try {
        const { data } = await get(id, params);
        if (isMounted) {
          setComponentState({
            status: ComponentStatus.Loaded,
            data: { ...data },
          });
        }
      } catch (err: any) {
        if (isMounted) {
          setComponentState({
            status: ComponentStatus.Error,
            data: {},
          });
          error(
            err?.response?.data?.message || "Failed to load report details",
          );
        }
      }
    },
    [setComponentState, isMounted],
  );

  React.useEffect(() => {
    if (currentPlan.startDate && currentPlan.endDate && student._id) {
      getReportData(student._id, {
        startDate: currentPlan.startDate,
        endDate: currentPlan.endDate,
        termId: termId,
      });
    }
  }, [student._id, currentPlan.startDate, currentPlan.endDate, getReportData]);

  const fetchFormDataInfo = React.useCallback(async () => {
    fetchPlanningPeriods &&
      fetchPlanningPeriods({
        user: student._id,
        term: termId,
      });
  }, [fetchPlanningPeriods, student, termId]);

  React.useEffect(() => {
    fetchFormDataInfo();
  }, [fetchFormDataInfo]);

  const getComponentBlock = (componentState: IReportModal) => {
    switch (componentState.status) {
      case ComponentStatus.Loaded:
      case ComponentStatus.Idle:
        return (
          <MentorReport
            closeHandler={closeModal}
            report={componentState.data}
            student={student}
            mountStatus={isMounted}
            planningPeriods={planningPeriods}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            setTermId={setTermId}
            termId={termId}
            studentTermOptions={studentTermOptions}
          />
        );

      case ComponentStatus.Error:
        return (
          <div className="d-flex flex-column align-items-center py-4x">
            <p>Error when loading data </p>
          </div>
        );

      case ComponentStatus.Loading:
        return <Loader type="ThreeDots" />;

      default:
        return <div>Idle Status</div>;
    }
  };

  return (
    <Modal className="report-modal modal-block" isOpen={true}>
      <div className="modal-wrap">
        <div className="modal-wrap__header">
          <h3>View and Export Report</h3>
          <button
            type="button"
            className="p-0x btn"
            title="Close"
            onClick={closeModal}
          >
            <box-icon name="x"></box-icon>
          </button>
        </div>
        {getComponentBlock(componentState)}
      </div>
    </Modal>
  );
};

interface State {
  planningPeriods: {
    fetchPlanningPeriods: IPlanningPeriod[];
  };
}

const mapStateToProps = (state: State) => ({
  planningPeriods: state.planningPeriods.fetchPlanningPeriods,
});

const mapDispatchToProps = {
  fetchPlanningPeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
