import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  ICreateResponseRevision,
  IResponseRevisionParams,
} from "commons/types/responseRevision";

export const createResponseRevision = async (
  responseId: string,
  payload: ICreateResponseRevision,
) => {
  const url = config.endpoints.survey.updateResponse;
  const { data } = await http.patch(
    pinterpolate(url, { id: responseId }),
    payload,
  );

  return data;
};

export const fetchResponseRevision = async (
  params: IResponseRevisionParams,
) => {
  const url = stringify(config.endpoints.responseRevision.all, {
    ...params,
  });
  const { data } = await http.get(url);

  return data;
};
