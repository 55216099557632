import React from "react";
import Modal from "react-modal";

import DropStudentForm from "./DropStudentForm";

type DropConfirmProps = {
  isOpen: boolean;
  closeModal: () => void;
  dropConfirm: (dropReason: string) => void;
};

const DropStudentDialog: React.FC<DropConfirmProps> = ({
  isOpen,
  closeModal,
  dropConfirm,
}: DropConfirmProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} className="modal-block">
      <div className="modal-wrap modal-wrap--lg">
        <div className="modal-wrap__header">
          <h3>Student Drop in Progress</h3>
          <span className="link-item" onClick={closeModal}>
            <box-icon name="x" />
          </span>
        </div>
        <DropStudentForm
          dropConfirm={dropConfirm}
          closeModal={closeModal}
          initialValues={{ dropReason: "", otherReason: "" }}
        />
      </div>
    </Modal>
  );
};
export default DropStudentDialog;
