import { IUser } from "commons/types/users";
import { ISurveyInitaiteResponders } from "commons/types/surveys";

interface Props {
  students: IUser[];
  responders: ISurveyInitaiteResponders[];
  handleStudentBulkSelect: () => void;
  handleBulkParentsSelect: () => void;
  totalParentsResponders: string[];
  totalParents: string[];
}

const StudentParentCheckBulkSection: React.FC<Props> = ({
  students,
  responders,
  handleBulkParentsSelect,
  handleStudentBulkSelect,
  totalParents,
  totalParentsResponders,
}) => {
  return (
    <div className="send-assessment-check__header">
      <div className="row">
        <div className="col-6">
          <input
            type="checkbox"
            name="checkbox"
            value="Checkbox Inline 2"
            className="form-group__radio mr-10"
            checked={responders.length === students.length}
            disabled={true}
          />
          <label
            onClick={handleStudentBulkSelect}
            className="box-links__nodes label-title"
          >
            Student Name
          </label>
        </div>
        <div className="col-6">
          <input
            type="checkbox"
            name="checkbox"
            value="Checkbox Inline 2"
            className="form-group__radio mr-10"
            checked={totalParentsResponders.length === totalParents.length}
          />
          <label
            onClick={handleBulkParentsSelect}
            className="box-links__nodes label-title"
          >
            Parents Name
          </label>
        </div>
      </div>
    </div>
  );
};

export default StudentParentCheckBulkSection;
