import {
  RECURRENCE_OPTIONS,
  RECURRENCE_END_TYPES,
  PRIORITY_LEVEL_OPTIONS,
} from "constants/index";
import { Formik } from "formik";
import Select, { components } from "react-select";
import { useState, FormEvent, useEffect } from "react";
import dayjs from "dayjs";

import { IGoal } from "commons/types/goals";
import { getDayFromDate, getUpcomingDateByDay } from "utils/dates";
import TaskSchema from "commons/schemas/task";
import { DAYS_OF_WEEK } from "constants/index";
import { ISelectOption } from "commons/types/form";
import { IAddTaskFormParams } from "./addTask.interface";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { TextInput, DateInput } from "commons/components/form-fields";
import {
  isDateInRange,
  checkEndDateBeforeStartDate,
  getDateInFormat,
} from "utils/dates";
import { subdomainName } from "utils/http";
import { isStudentLoggedIn } from "utils/window";

interface IFormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  touched: any;
  errors: any;
  isSubmitting: boolean;
}

const AddTaskForm: React.FC<IAddTaskFormParams> = ({
  planningPeriods,
  goals,
  initialValues,
  closeModal,
  saveTask,
}): JSX.Element => {
  const [showMore, setShowMore] = useState(false);
  const [recurrenceEndType, setRecurrenceEndType] = useState(
    initialValues?.recurrenceEndDate
      ? RECURRENCE_END_TYPES.DATE
      : RECURRENCE_END_TYPES.NEVER,
  );

  const [selectedPlan, setSelectedPlan] = useState<
    IPlanningPeriod | undefined
  >();
  // Filters out past  planning period from the select options
  const planOptions: ISelectOption[] = planningPeriods
    .filter((record: IPlanningPeriod) =>
      checkEndDateBeforeStartDate(record.endDate, new Date()),
    )
    .map((record: IPlanningPeriod) => ({
      label: `${getDateInFormat(record?.startDate)} - ${getDateInFormat(
        record.endDate,
      )}`,
      value: record.startDate.toString(),
    }));

  const goalOptions = goals.map((record: IGoal) => ({
    label: record.name,
    subLabel: `Ends: ${getDateInFormat(record.requiredDeadline)}`,
    value: record._id,
  }));

  useEffect(() => {
    const initialPlan =
      planningPeriods.find(
        (period) =>
          initialValues.planningPeriod === period.startDate.toString(),
      ) ||
      planningPeriods.find((planningPeriod) =>
        isDateInRange(
          planningPeriod.startDate,
          planningPeriod.endDate,
          new Date(initialValues.requiredDeadline),
          true,
        ),
      );
    if (!selectedPlan && initialPlan) setSelectedPlan(initialPlan);
  }, [
    initialValues.planningPeriod,
    initialValues.requiredDeadline,
    planningPeriods,
    selectedPlan,
  ]);

  const getSelectedPlanningPeriod = (formikProps: IFormikProps) => {
    if (formikProps.values.planningPeriod) {
      return planOptions.filter(
        (option) => option.value === formikProps.values.planningPeriod,
      );
    }

    if (formikProps.values.requiredDeadline) {
      const planningPeriodOfToDoDate = planningPeriods.find((planningPeriod) =>
        isDateInRange(
          planningPeriod.startDate,
          planningPeriod.endDate,
          new Date(formikProps.values.requiredDeadline),
          true,
        ),
      );
      const selectedPlanningPeriod = planOptions.filter(
        (option) =>
          option.value === planningPeriodOfToDoDate?.startDate.toString(),
      );

      selectedPlanningPeriod.length &&
        formikProps.setFieldValue(
          "planningPeriod",
          selectedPlanningPeriod[0].value,
        );

      return selectedPlanningPeriod;
    }

    return [];
  };

  /**
   * Get date from student timezone
   * @param date
   * @param selectedPeriod
   * @returns date in student TZ
   */
  const getDateInStudentTimezone = (
    date: Date,
    selectedPeriod: IPlanningPeriod,
  ) => {
    let dateInStudentTz: Date = date as Date;

    const PPStartDate = getDateInFormat(selectedPeriod.startDate, "MM-DD-YYYY");
    let PPTime = new Date(selectedPeriod.endDate);

    if (PPStartDate === getDateInFormat(dateInStudentTz, "MM-DD-YYYY")) {
      PPTime = new Date(selectedPeriod.startDate);
    }

    dateInStudentTz.setHours(
      PPTime.getHours(),
      PPTime.getMinutes(),
      PPTime.getSeconds(),
      PPTime.getMilliseconds(),
    );

    return dateInStudentTz;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TaskSchema}
      onSubmit={async (data: IGoal) => await saveTask(data)}
      enableReinitialize={true}
    >
      {(formikProps: IFormikProps) => {
        const adjustRecurrenceEndDate = (
          requiredDeadline: Date | [Date, Date] | null,
        ) => {
          if (!formikProps.values.recurrenceEndDate || !requiredDeadline)
            return;
          if (formikProps.values.recurrenceEndDate < requiredDeadline)
            formikProps.setFieldValue("recurrenceEndDate", requiredDeadline);
        };

        const showGoalEndDateError = (() => {
          if (
            !selectedPlan ||
            !formikProps.values.childOf ||
            !formikProps.values.requiredDeadline
          ) {
            return false;
          }

          const isToDoDateWithinGoalEndDate = dayjs(
            formikProps.values.requiredDeadline,
          ).isSameOrBefore(dayjs(formikProps.values.goalDeadline));

          if (isToDoDateWithinGoalEndDate) {
            return false;
          }

          return true;
        })();

        const setGoalEndDate = (goalId: string) => {
          const selectedGoal = goals.find((goal: IGoal) => goal._id === goalId);
          formikProps.setFieldValue(
            "goalDeadline",
            selectedGoal?.requiredDeadline,
          );
        };

        const PlanningPeriod = () => {
          const onPlanningPeriodChange = (selected: any) => {
            formikProps.setFieldValue("planningPeriod", selected.value);
            const plan = planningPeriods.find(
              (period) => selected.value === period.startDate.toString(),
            );
            // Validate dates of planning period with todo / due date of task
            if (
              plan &&
              !isDateInRange(
                plan?.startDate,
                plan?.endDate,
                formikProps.values.requiredDeadline,
              )
            ) {
              formikProps.setFieldValue("requiredDeadline", plan?.endDate);

              if (recurrenceEndType === RECURRENCE_END_TYPES.DATE) {
                formikProps.setFieldValue("recurrenceEndDate", plan?.endDate);
              }

              if (
                formikProps.values.workData?.optionalDeadline &&
                !checkEndDateBeforeStartDate(
                  formikProps.values.workData?.optionalDeadline,
                  plan?.endDate,
                )
              ) {
                formikProps.setFieldValue(
                  "workData.optionalDeadline",
                  plan?.endDate,
                );
              }
            }
            setSelectedPlan(plan);
          };

          return (
            <div className="col-6">
              <div className="input-wrap">
                <label className="input__label required">Planning Period</label>
                <Select
                  placeholder="Select Plan"
                  name="planningPeriod"
                  onChange={onPlanningPeriodChange}
                  onBlur={formikProps.handleBlur}
                  options={planOptions}
                  value={getSelectedPlanningPeriod(formikProps)}
                  classNamePrefix="react-select"
                />
                <label className="input__error">
                  {formikProps.touched.planningPeriod &&
                    formikProps.errors.planningPeriod}
                </label>
                <p className="smaller mt-1x">
                  The plans are only visible when a student is actively enrolled
                  in a current {subdomainName} term and the mentor sessions are
                  scheduled.
                </p>
              </div>
            </div>
          );
        };

        const WeekTask = () => {
          const onWeekDayChange = (day: string) => {
            const selectedPeriod = planningPeriods.find(
              (period: IPlanningPeriod) =>
                period.startDate.toString() ===
                formikProps.values.planningPeriod,
            );
            const dateToSet = getUpcomingDateByDay(day, {
              startDate: selectedPeriod?.startDate,
              endDate: selectedPeriod?.endDate,
            });

            if (!dateToSet) return;

            const isPastWeekDay =
              dayjs(dateToSet).diff(dayjs().toDate(), "day") < 0;

            if (!isPastWeekDay) {
              formikProps.setFieldValue("requiredDeadline", dateToSet);
              adjustRecurrenceEndDate(dateToSet?.toDate() as Date);
            }
          };

          return (
            <div className="row">
              <div className="col-12">
                <div className="input-wrap">
                  <span className="input__labe required">Repeat on</span>
                  <div className="week-list">
                    <ul>
                      {DAYS_OF_WEEK.map((day: string) => (
                        <li
                          key={day}
                          className={`${
                            formikProps.values.requiredDeadline &&
                            getDayFromDate(
                              formikProps.values.requiredDeadline,
                            ) === day
                              ? "selected"
                              : null
                          }`}
                          onClick={() => onWeekDayChange(day)}
                        >
                          <span className="item">
                            {day[0].toString().toUpperCase()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        };

        const EndsOn = () => {
          const onChangeNever = () => {
            setRecurrenceEndType(RECURRENCE_END_TYPES.NEVER);
            formikProps.values.recurrenceEndDate = null;
          };
          const onChangeEndDate = () => {
            setRecurrenceEndType(RECURRENCE_END_TYPES.DATE);
            formikProps.setFieldValue(
              "recurrenceEndDate",
              formikProps.values.requiredDeadline,
            );
          };

          return (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3x required">Ends</div>
                  <input
                    className="box-links__nodes"
                    type="radio"
                    name="workData.workType"
                    id="recurrenceEndDate"
                    value={RECURRENCE_END_TYPES.NEVER}
                    checked={recurrenceEndType === RECURRENCE_END_TYPES.NEVER}
                    onChange={onChangeNever}
                  />
                  <label className="input__label" htmlFor="recurrenceEndDate">
                    Never
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-4 mt-auto mb-auto">
                  <input
                    className="box-links__nodes"
                    type="radio"
                    name="workData.workType"
                    id="recurrenceEndDate-on"
                    value={RECURRENCE_END_TYPES.DATE}
                    checked={recurrenceEndType === RECURRENCE_END_TYPES.DATE}
                    onChange={onChangeEndDate}
                  />
                  <label
                    className="input__label"
                    htmlFor="recurrenceEndDate-on"
                  >
                    On
                  </label>
                </div>
                <div className="col-6">
                  <DateInput
                    placeholder="Select recurrence end date"
                    name="workData.recurrenceEndDate"
                    isValid={!formikProps.errors.recurrenceEndDate}
                    selected={formikProps.values.recurrenceEndDate}
                    disabled={recurrenceEndType !== RECURRENCE_END_TYPES.DATE}
                    onChange={(date) =>
                      formikProps.setFieldValue("recurrenceEndDate", date)
                    }
                    onBlur={formikProps.handleBlur}
                    minDate={
                      (formikProps.values.requiredDeadline &&
                        new Date(formikProps.values.requiredDeadline)) ||
                      new Date()
                    }
                    maxDate={
                      formikProps.values.goalDeadline
                        ? new Date(formikProps.values.goalDeadline)
                        : new Date(formikProps.values.termEndDate)
                    }
                  />
                </div>
              </div>
            </>
          );
        };

        const Option = (props: any) => {
          return (
            <components.Option {...props}>
              <div>{props.data.label}</div>
              <div className="text-small">{props.data.subLabel}</div>
            </components.Option>
          );
        };

        return (
          <form onSubmit={formikProps.handleSubmit}>
            <div className="modal-wrap__body">
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <TextInput
                      label="Title of the Task"
                      name="name"
                      placeholder="Enter title of the task"
                      values={formikProps.values}
                      errors={formikProps.errors}
                      touched={formikProps.touched}
                      handleChange={formikProps.handleChange}
                      handleBlur={formikProps.handleBlur}
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <PlanningPeriod />
                  <div className="col-6">
                    <div className="input-wrap mb-0x">
                      <label className="input__label required">
                        To-Do Date
                      </label>
                      <DateInput
                        placeholder="Select to-do date"
                        name="requiredDeadline"
                        isValid={
                          !(
                            formikProps.touched.requiredDeadline &&
                            formikProps.errors.requiredDeadline
                          )
                        }
                        selected={formikProps.values.requiredDeadline}
                        onChange={(date) => {
                          if (!date) return;
                          if (recurrenceEndType === RECURRENCE_END_TYPES.DATE) {
                            formikProps.setFieldValue(
                              "recurrenceEndDate",
                              date,
                            );
                          }
                          const selectedPeriod = planningPeriods.find(
                            (period: IPlanningPeriod) =>
                              period.startDate.toString() ===
                              formikProps.values.planningPeriod,
                          );

                          if (!selectedPeriod) return;

                          const dateInStudentTz = getDateInStudentTimezone(
                            date as Date,
                            selectedPeriod,
                          );

                          formikProps.setFieldValue(
                            "requiredDeadline",
                            dateInStudentTz,
                          );
                          adjustRecurrenceEndDate(dateInStudentTz);
                          if (
                            formikProps.values.workData?.optionalDeadline &&
                            !checkEndDateBeforeStartDate(
                              formikProps.values.workData.optionalDeadline,
                              dateInStudentTz,
                            )
                          ) {
                            formikProps.setFieldValue(
                              "workData.optionalDeadline",
                              dateInStudentTz,
                            );
                          }
                        }}
                        onBlur={formikProps.handleBlur}
                        minDate={
                          selectedPlan &&
                          checkEndDateBeforeStartDate(
                            new Date(selectedPlan.startDate),
                            new Date(),
                          )
                            ? new Date(selectedPlan.startDate)
                            : new Date()
                        }
                        maxDate={selectedPlan && new Date(selectedPlan.endDate)}
                      />
                      <label className="input__error">
                        {formikProps.touched.requiredDeadline &&
                          formikProps.errors.requiredDeadline}
                      </label>
                    </div>
                  </div>
                </div>
                {showMore && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Attach a Goal</label>
                          <Select
                            placeholder="Please select goal"
                            name="childOf"
                            onChange={(selected: any) => {
                              setGoalEndDate(selected.value);
                              formikProps.setFieldValue(
                                "childOf",
                                selected.value,
                              );
                            }}
                            onBlur={formikProps.handleBlur}
                            options={goalOptions}
                            components={{ Option }}
                            classNamePrefix="react-select"
                            value={goalOptions.filter(
                              (option) =>
                                option.value === formikProps.values.childOf,
                            )}
                          />
                          <label className="input__error">
                            {showGoalEndDateError && (
                              <p>
                                You cannot select to-do-date beyond goal's end
                                date when goal is attached.
                              </p>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Task Priority</label>
                          <Select
                            placeholder="Please select priority"
                            name="workData.priority"
                            onChange={(selected: any) => {
                              formikProps.setFieldValue(
                                "workData.priority",
                                selected.value,
                              );
                            }}
                            onBlur={formikProps.handleBlur}
                            options={PRIORITY_LEVEL_OPTIONS}
                            classNamePrefix="react-select"
                            value={PRIORITY_LEVEL_OPTIONS.filter(
                              (option) =>
                                option.value ===
                                formikProps.values.workData?.priority,
                            )}
                          />
                          <label className="input__error">
                            {formikProps.touched.workData?.priority &&
                              formikProps.errors.workData?.priority}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Recurrence</label>
                          <Select
                            placeholder="Select recurrence"
                            name="recurrence"
                            onChange={(selected: any) => {
                              formikProps.setFieldValue(
                                "recurrenceType",
                                selected.value,
                              );
                            }}
                            onBlur={formikProps.handleBlur}
                            options={RECURRENCE_OPTIONS}
                            classNamePrefix="react-select"
                            value={RECURRENCE_OPTIONS.filter(
                              (option) =>
                                option.value ===
                                formikProps.values?.recurrenceType,
                            )}
                          />
                          <label className="input__error">
                            {formikProps.touched?.recurrenceType &&
                              formikProps.errors?.recurrenceType}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Due Date</label>
                          <DateInput
                            placeholder="Select due date"
                            name="workData.optionalDeadline"
                            isValid={
                              !(
                                formikProps.touched.workData
                                  ?.optionalDeadline &&
                                formikProps.errors.workData?.optionalDeadline
                              )
                            }
                            selected={
                              formikProps.values.workData?.optionalDeadline ||
                              formikProps.values.deadline
                            }
                            onChange={(date) =>
                              formikProps.setFieldValue(
                                "workData.optionalDeadline",
                                date,
                              )
                            }
                            onBlur={formikProps.handleBlur}
                            minDate={
                              (formikProps.values.requiredDeadline &&
                                new Date(
                                  formikProps.values.requiredDeadline,
                                )) ||
                              new Date()
                            }
                          />
                          <label className="input__error">
                            {formikProps.touched.workData?.optionalDeadline &&
                              formikProps.errors.workData?.optionalDeadline}
                          </label>
                        </div>
                      </div>
                    </div>

                    {formikProps.values?.recurrenceType !== "Non-Repeat" && (
                      <>
                        {formikProps.values?.recurrenceType === "Weekly" && (
                          <WeekTask />
                        )}
                        <EndsOn />
                      </>
                    )}
                  </>
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="status">
                      {showMore ? (
                        <span
                          className="status button"
                          onClick={() => setShowMore(false)}
                        >
                          <box-icon name="chevron-up" />
                          Less options
                        </span>
                      ) : (
                        <span
                          className="status button"
                          onClick={() => setShowMore(true)}
                        >
                          <box-icon name="chevron-down" />
                          More options
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-wrap__footer">
              <div className="row">
                <div className="col-12 d-flex">
                  <button
                    disabled={formikProps.isSubmitting || showGoalEndDateError}
                    className="btn btn--primary mr-4x"
                    type="submit"
                  >
                    Add Task
                  </button>
                  {!isStudentLoggedIn() && (
                    <button
                      disabled={
                        formikProps.isSubmitting || showGoalEndDateError
                      }
                      className="btn btn--outlined-primary mr-4x"
                      type="submit"
                      onClick={() => {
                        formikProps.setFieldValue("isPublished", false);
                      }}
                    >
                      Save Draft
                    </button>
                  )}
                  <button
                    type="reset"
                    disabled={formikProps.isSubmitting}
                    className="btn txt-primary-color"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddTaskForm;
