import { FetchTermsAction, TermsActionType } from "store/actions/types/terms";

const INITIAL_STATE = {
  fetchTerms: [],
  fetchTermsLoading: false,
  fetchTermsError: null,
  updateTermStatusLoading: false,
};

const reducer = (state = INITIAL_STATE, action: FetchTermsAction) => {
  switch (action.type) {
    case TermsActionType.FETCH_TERMS_PENDING:
      return {
        ...state,
        fetchTermsLoading: true,
        fetchTermsError: null,
      };
    case TermsActionType.FETCH_TERMS_FULFILLED:
      return {
        ...state,
        fetchTerms: action.data,
        fetchTermsLoading: false,
        fetchTermsError: null,
      };
    case TermsActionType.FETCH_TERMS_REJECTED:
      return {
        ...state,
        fetchTermsLoading: false,
        fetchTermsError: action.data,
      };

    case TermsActionType.UPDATE_TERM_STATUS_PENDING:
      return {
        ...state,
        fetchTermsLoading: true,
      };

    case TermsActionType.UPDATE_TERM_STATUS_FULFILLED:
      return {
        ...state,
        fetchTermsLoading: false,
        // TODO
      };

    case TermsActionType.UPDATE_TERM_STATUS_REJECTED:
      return {
        ...state,
        fetchTermsLoading: false,
        // TODO
      };
    default:
      return state;
  }
};

export default reducer;
