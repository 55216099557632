export enum dateFormat {
  Day = "dddd",
  ShortDay = "ddd",
  FullDate = "YYYY-MM-DD",
  ShortMonthDate = "MMM D",
  ShortDayMonth = "ddd, MMM Do",
  ShortMonthDateYear = "MMM D, YYYY",
  LongMonthDateYear = "ddd, MMM D, YYYY",
  ShortMonthDateYear12HourMinute = "MMM D, YYYY h:mm a",
}
