import { Formik } from "formik";
import Select, { components } from "react-select";
import { useState, FormEvent, useEffect } from "react";

import TaskSchema from "commons/schemas/task";
import { IGoal } from "commons/types/goals";
import { ISelectOption } from "commons/types/form";
import {
  DAYS_OF_WEEK,
  PRIORITY_LEVEL_OPTIONS,
  RECURRENCE_END_TYPES,
  RECURRENCE_OPTIONS,
} from "constants/index";
import { IUpdateTaskFormParams } from "./updateTask.interface";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { TextInput, DateInput } from "commons/components/form-fields";
import {
  getDateInFormat,
  isDateInRange,
  checkEndDateBeforeStartDate,
  getDayFromDate,
  getUpcomingDateByDay,
} from "utils/dates";
import dayjs from "dayjs";

interface IFormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  touched: any;
  errors: any;
  isSubmitting: boolean;
}

const UpdateTaskForm: React.FC<IUpdateTaskFormParams> = ({
  goals,
  initialValues,
  closeModal,
  saveTask,
  planningPeriods,
  student,
}): JSX.Element => {
  const formikInitialValues = {
    _id: initialValues._id,
    name: initialValues.name,
    requiredDeadline: initialValues.requiredDeadline,
    isCompleted: initialValues.isCompleted,
    isPublished: initialValues.isPublished,
    childOf: initialValues.childOf,
    uowType: initialValues.uowType,
    planningPeriod: initialValues.planningPeriod,
    goalDeadline: initialValues.goalDeadline,
    workData: {
      uowType: initialValues.workData.uowType,
      optionalDeadline: initialValues.workData.optionalDeadline,
      priority: initialValues.workData.priority,
    },
    recurrenceType: initialValues.recurrenceType,
    recurrenceEndDate: initialValues.recurrenceEndDate,
  };

  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<
    IPlanningPeriod | undefined
  >();
  const [recurrenceEndType, setRecurrenceEndType] = useState(
    initialValues.recurrenceEndDate
      ? RECURRENCE_END_TYPES.DATE
      : RECURRENCE_END_TYPES.NEVER,
  );

  const planOptions: ISelectOption[] = planningPeriods.map(
    (record: IPlanningPeriod) => ({
      label: `${getDateInFormat(record?.startDate)} - ${getDateInFormat(
        record.endDate,
      )}`,
      value: record.startDate.toString(),
    }),
  );

  const goalOptions = goals.map((record: IGoal) => ({
    label: record.name,
    subLabel: `Ends: ${getDateInFormat(record.requiredDeadline)}`,
    value: record._id,
  }));

  useEffect(() => {
    const initialPlan: IPlanningPeriod | undefined =
      planningPeriods.find(
        (period) =>
          initialValues.planningPeriod === period.startDate.toString(),
      ) ||
      planningPeriods.find((planningPeriod) =>
        isDateInRange(
          planningPeriod.startDate,
          planningPeriod.endDate,
          new Date(initialValues.requiredDeadline),
          true,
        ),
      );
    if (!selectedPlan && initialPlan) setSelectedPlan(initialPlan);
  }, [
    selectedPlan,
    planningPeriods,
    initialValues.planningPeriod,
    initialValues.requiredDeadline,
  ]);

  const getSelectedPlanningPeriod = (props: IFormikProps) => {
    if (props.values.planningPeriod)
      return planOptions.filter(
        (option) => option.value === props.values.planningPeriod,
      );

    if (props.values.requiredDeadline) {
      const planningPeriodOfToDoDate = planningPeriods.find((planningPeriod) =>
        isDateInRange(
          planningPeriod.startDate,
          planningPeriod.endDate,
          new Date(props.values.requiredDeadline),
          true,
        ),
      );
      const selectedPlanningPeriod = planOptions.filter(
        (option) =>
          option.value === planningPeriodOfToDoDate?.startDate.toString(),
      );

      selectedPlanningPeriod.length &&
        props.setFieldValue("planningPeriod", selectedPlanningPeriod[0].value);

      return selectedPlanningPeriod;
    }

    return [];
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={TaskSchema}
      onSubmit={async (data: any) => {
        await saveTask(formikInitialValues._id, data);
      }}
    >
      {(props: IFormikProps) => {
        const adjustRecurrenceEndDate = (
          requiredDeadline: Date | [Date, Date] | null,
        ) => {
          if (!props.values.recurrenceEndDate || !requiredDeadline) return;
          if (props.values.recurrenceEndDate < requiredDeadline)
            props.setFieldValue("recurrenceEndDate", requiredDeadline);
        };

        const onWeekDayChange = (day: string) => {
          const selectedPeriod = planningPeriods.find(
            (period: IPlanningPeriod) =>
              period.startDate.toString() === props.values.planningPeriod,
          );
          const dateToSet = getUpcomingDateByDay(day, {
            startDate: selectedPeriod?.startDate,
            endDate: selectedPeriod?.endDate,
          });
          if (!dateToSet) return;
          props.setFieldValue("requiredDeadline", dateToSet);
          adjustRecurrenceEndDate(dateToSet?.toDate() as Date);
        };

        const showGoalEndDateError = (() => {
          if (!selectedPlan || !props.values.childOf) {
            return false;
          }

          const isToDoDateWithinGoalEndDate = dayjs(
            props.values.requiredDeadline,
          ).isSameOrBefore(dayjs(props.values.goalDeadline));

          if (isToDoDateWithinGoalEndDate) {
            return false;
          }

          return true;
        })();

        const setGoalEndDate = (goalId: string) => {
          const selectedGoal = goals.find((goal: IGoal) => goal._id === goalId);
          props.setFieldValue("goalDeadline", selectedGoal?.requiredDeadline);
        };

        const Option = (props: any) => {
          return (
            <components.Option {...props}>
              <div>{props.data.label}</div>
              <div className="text-small">{props.data.subLabel}</div>
            </components.Option>
          );
        };

        /**
         * Get date from student timezone
         * @param date
         * @param selectedPeriod
         * @returns date in student TZ
         */
        const getDateInStudentTimezone = (
          date: Date,
          selectedPeriod: IPlanningPeriod,
        ) => {
          let dateInStudentTz: Date = date as Date;

          const PPStartDate = getDateInFormat(
            selectedPeriod.startDate,
            "MM-DD-YYYY",
          );
          let PPTime = new Date(selectedPeriod.endDate);

          if (PPStartDate === getDateInFormat(dateInStudentTz, "MM-DD-YYYY")) {
            PPTime = new Date(selectedPeriod.startDate);
          }

          dateInStudentTz.setHours(
            PPTime.getHours(),
            PPTime.getMinutes(),
            PPTime.getSeconds(),
            PPTime.getMilliseconds(),
          );

          return dateInStudentTz;
        };

        return (
          <form onSubmit={props.handleSubmit}>
            <div className="modal-wrap__body">
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <TextInput
                      label="Title of the Task"
                      name="name"
                      placeholder="Enter title of the task"
                      values={props.values}
                      errors={props.errors}
                      touched={props.touched}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">Plan for</label>
                      <Select
                        placeholder="Select Plan"
                        name="planningPeriod"
                        onChange={(selected: any) => {
                          props.setFieldValue("planningPeriod", selected.value);
                          const plan = planningPeriods.find(
                            (period) =>
                              selected.value === period.startDate.toString(),
                          );
                          if (
                            plan &&
                            !isDateInRange(
                              plan?.startDate,
                              plan?.endDate,
                              props.values.requiredDeadline,
                              true,
                            )
                          ) {
                            props.setFieldValue(
                              "requiredDeadline",
                              plan?.endDate,
                            );
                            if (
                              props.values.workData?.optionalDeadline &&
                              !checkEndDateBeforeStartDate(
                                props.values.workData?.optionalDeadline,
                                plan?.endDate,
                              )
                            ) {
                              props.setFieldValue(
                                "workData.optionalDeadline",
                                plan?.endDate,
                              );
                            }
                          }

                          setSelectedPlan(plan);
                        }}
                        onBlur={props.handleBlur}
                        classNamePrefix="react-select"
                        options={planOptions}
                        value={getSelectedPlanningPeriod(props)}
                      />
                      <label className="input__error">
                        {props.touched.planningPeriod &&
                          props.errors.planningPeriod}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">
                        To-Do Date
                      </label>
                      <DateInput
                        placeholder="Select to-do date"
                        name="requiredDeadline"
                        isValid={
                          !(
                            props.touched.requiredDeadline &&
                            props.errors.requiredDeadline
                          )
                        }
                        selected={props.values.requiredDeadline}
                        onChange={(date) => {
                          if (!date) return;

                          const selectedPeriod = planningPeriods.find(
                            (period: IPlanningPeriod) =>
                              period.startDate.toString() ===
                              props.values.planningPeriod,
                          );

                          if (!selectedPeriod) return;
                          const dateInStudentTz = getDateInStudentTimezone(
                            date as Date,
                            selectedPeriod,
                          );

                          props.setFieldValue(
                            "requiredDeadline",
                            dateInStudentTz,
                          );
                          if (recurrenceEndType === RECURRENCE_END_TYPES.DATE) {
                            props.setFieldValue("recurrenceEndDate", date);
                          }
                          if (
                            props.values.workData?.optionalDeadline &&
                            !checkEndDateBeforeStartDate(
                              props.values.workData.optionalDeadline,
                              date,
                            )
                          ) {
                            props.setFieldValue(
                              "workData.optionalDeadline",
                              date,
                            );
                          }
                        }}
                        onBlur={props.handleBlur}
                        minDate={
                          (selectedPlan && new Date(selectedPlan.startDate)) ||
                          new Date()
                        }
                        maxDate={selectedPlan && new Date(selectedPlan.endDate)}
                      />
                      <label className="input__error">
                        {props.touched.requiredDeadline &&
                          props.errors.requiredDeadline}
                      </label>
                    </div>
                  </div>
                </div>
                {showMore && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Attach a Goal</label>
                          <Select
                            placeholder="Please select goal"
                            name="childOf"
                            onChange={(selected: any) => {
                              setGoalEndDate(selected.value);
                              props.setFieldValue("childOf", selected.value);
                            }}
                            onBlur={props.handleBlur}
                            classNamePrefix="react-select"
                            components={{ Option }}
                            options={goalOptions}
                            value={goalOptions.filter(
                              (option) => option.value === props.values.childOf,
                            )}
                          />
                          <label className="input__error">
                            {showGoalEndDateError && (
                              <p>
                                You cannot select to-do-date beyond goal's end
                                date when goal is attached.
                              </p>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Task Priority</label>
                          <Select
                            placeholder="Please select priority"
                            name="workData.priority"
                            onChange={(selected: any) => {
                              props.setFieldValue(
                                "workData.priority",
                                selected.value,
                              );
                            }}
                            onBlur={props.handleBlur}
                            classNamePrefix="react-select"
                            options={PRIORITY_LEVEL_OPTIONS}
                            value={PRIORITY_LEVEL_OPTIONS.filter(
                              (option) =>
                                option.value ===
                                props.values.workData?.priority,
                            )}
                          />
                          <label className="input__error">
                            {props.touched.workData?.priority &&
                              props.errors.workData?.priority}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Recurrence</label>
                          <Select
                            placeholder="Select recurrence"
                            name="workData.recurrence"
                            onChange={(selected: any) => {
                              props.setFieldValue(
                                "recurrenceType",
                                selected.value,
                              );
                            }}
                            onBlur={props.handleBlur}
                            classNamePrefix="react-select"
                            options={RECURRENCE_OPTIONS}
                            value={RECURRENCE_OPTIONS.filter(
                              (option) =>
                                option.value === props.values.recurrenceType,
                            )}
                          />
                          <label className="input__error">
                            {props.touched.recurrenceType &&
                              props.errors.recurrenceType}
                          </label>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="input-wrap">
                          <label className="input__label">Due Date</label>
                          <DateInput
                            placeholder="Select due date"
                            name="workData.optionalDeadline"
                            isValid={
                              !(
                                props.touched.workData?.optionalDeadline &&
                                props.errors.workData?.optionalDeadline
                              )
                            }
                            selected={props.values.workData?.optionalDeadline}
                            onChange={(date) =>
                              props.setFieldValue(
                                "workData.optionalDeadline",
                                date,
                              )
                            }
                            onBlur={props.handleBlur}
                            minDate={new Date(props.values.requiredDeadline)}
                          />
                          <label className="input__error">
                            {props.touched.workData?.optionalDeadline &&
                              props.errors.workData?.optionalDeadline}
                          </label>
                        </div>
                      </div>
                    </div>

                    {props.values?.recurrenceType !== "Non-Repeat" && (
                      <>
                        {props.values?.recurrenceType === "Weekly" && (
                          <div className="row">
                            <div className="col-12">
                              <div className="input-wrap">
                                <span className="input__labe required">
                                  Repeat on
                                </span>
                                <div className="week-list">
                                  <ul>
                                    {DAYS_OF_WEEK.map((day: string) => (
                                      <li
                                        key={day}
                                        className={`${
                                          props.values.requiredDeadline &&
                                          getDayFromDate(
                                            props.values.requiredDeadline,
                                          ) === day
                                            ? "selected"
                                            : null
                                        }`}
                                        onClick={() => onWeekDayChange(day)}
                                      >
                                        <span className="item">
                                          {day[0].toString().toUpperCase()}
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-12">
                            <div className="mb-3x required">Ends</div>
                            <input
                              className="box-links__nodes"
                              type="radio"
                              name="workData.workType"
                              id="recurrenceEndDate"
                              value={RECURRENCE_END_TYPES.NEVER}
                              checked={
                                recurrenceEndType === RECURRENCE_END_TYPES.NEVER
                              }
                              onChange={() => {
                                setRecurrenceEndType(
                                  RECURRENCE_END_TYPES.NEVER,
                                );
                                props.values.recurrenceEndDate = null;
                              }}
                            />
                            <label
                              className="input__label"
                              htmlFor="recurrenceEndDate"
                            >
                              Never
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4 mt-auto mb-auto">
                            <input
                              className="box-links__nodes"
                              type="radio"
                              name="workData.workType"
                              id="recurrenceEndDate-on"
                              value={RECURRENCE_END_TYPES.DATE}
                              checked={
                                recurrenceEndType === RECURRENCE_END_TYPES.DATE
                              }
                              onChange={() => {
                                setRecurrenceEndType(RECURRENCE_END_TYPES.DATE);
                                props.setFieldValue(
                                  "recurrenceEndDate",
                                  props.values.requiredDeadline,
                                );
                              }}
                            />
                            <label
                              className="input__label"
                              htmlFor="recurrenceEndDate-on"
                            >
                              On
                            </label>
                          </div>
                          <div className="col-6">
                            <DateInput
                              placeholder="Select recurrence end date"
                              name="workData.optionalDeadline"
                              isValid={!props.errors.recurrenceEndDate}
                              selected={props.values.recurrenceEndDate}
                              disabled={
                                recurrenceEndType !== RECURRENCE_END_TYPES.DATE
                              }
                              onChange={(date) =>
                                props.setFieldValue("recurrenceEndDate", date)
                              }
                              onBlur={props.handleBlur}
                              minDate={
                                (props.values.requiredDeadline &&
                                  new Date(props.values.requiredDeadline)) ||
                                new Date()
                              }
                              maxDate={
                                props.values.goalDeadline &&
                                new Date(props.values.goalDeadline)
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="status">
                      {showMore ? (
                        <span
                          className="status button"
                          onClick={() => setShowMore(false)}
                        >
                          <box-icon name="chevron-up" />
                          Less options
                        </span>
                      ) : (
                        <span
                          className="status button"
                          onClick={() => setShowMore(true)}
                        >
                          <box-icon name="chevron-down" />
                          More options
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-wrap__footer">
              <div className="row">
                <div className="col-12 d-flex">
                  <button
                    disabled={props.isSubmitting || showGoalEndDateError}
                    className="btn btn--primary mr-4x"
                    type="submit"
                  >
                    Update Task
                  </button>
                  <button
                    type="reset"
                    disabled={props.isSubmitting}
                    className="btn txt-primary-color"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              {formikInitialValues.recurrenceType !== "Non-Repeat" && (
                <div className="row mt-4x">
                  <div className="col-12 d-flex">
                    <span>
                      Note: Upcoming tasks related to this task will also be
                      updated.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default UpdateTaskForm;
