import {
  AddCenterActionType,
  FetchCentersActionType,
  UpdateCenterActionType,
  DeleteCenterActionType,
} from "store/actions/types/centers";

const INITIAL_STATE = {
  addCenter: {},
  addCenterLoading: false,
  addCenterError: null,
  fetchCenters: [],
  fetchCentersLoading: false,
  fetchCentersError: null,
  updateCenter: {},
  updateCenterLoading: false,
  updateCenterError: null,
  deleteCenter: {},
  deleteCenterLoading: false,
  deleteCenterError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddCenterActionType.ADD_CENTER_PENDING:
      return { ...state, addAssignmentLoading: true, addAssignmentError: null };

    case AddCenterActionType.ADD_CENTER_FULFILLED:
      return {
        ...state,
        addCenter: action.data,
        addCenterLoading: false,
        addCenterError: null,
      };
    case AddCenterActionType.ADD_CENTER_REJECTED:
      return {
        ...state,
        addAssignmentLoading: false,
        addAssignmentError: action.data,
      };
    case FetchCentersActionType.FETCH_CENTERS_PENDING:
      return {
        ...state,
        fetchCentersLoading: true,
        fetchCentersError: null,
      };
    case FetchCentersActionType.FETCH_CENTERS_FULFILLED:
      return {
        ...state,
        fetchCentersLoading: false,
        fetchCentersError: null,
        fetchCenters: action.data,
      };

    case FetchCentersActionType.FETCH_CENTERS_REJECTED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        fetchAssignmentsError: action.data,
      };
    case UpdateCenterActionType.UPDATE_CENTER_PENDING:
      return {
        ...state,
        updateCenterLoading: true,
        updateCenterError: null,
      };
    case UpdateCenterActionType.UPDATE_CENTER_FULFILLED:
      return {
        ...state,
        updateCenterLoading: false,
        updateCenter: action.data,
        updateCenterError: null,
      };
    case UpdateCenterActionType.UPDATE_CENTER_REJECTED:
      return {
        ...state,
        updateCenterLoading: false,
        updateCenterError: action.data,
      };
    case DeleteCenterActionType.DELETE_CENTER_PENDING:
      return {
        ...state,
        deleteCenterLoading: true,
        deleteCenterError: null,
      };
    case DeleteCenterActionType.DELETE_CENTER_FULFILLED:
      return {
        ...state,
        deleteCenter: action.data,
        deleteCenterLoading: false,
        deleteCenterError: null,
      };
    case DeleteCenterActionType.DELETE_CENTER_REJECTED:
      return {
        ...state,
        deleteCenterLoading: false,
        deleteCenterError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
