import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

const SchoolSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(
      TITLE_LENGTH,
      `School name must be less than ${TITLE_LENGTH} characters`,
    )
    .required("Please enter school name"),

  district: Yup.string().trim().required("Please select school district"),
});

export default SchoolSchema;
