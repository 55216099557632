import { IAddUpdateGradeData } from "pages/grades/grade";
import config from "configs";
import { http } from "utils/http";

export const fetch = async (params: string) => {
  const url = config.endpoints.grades.get;
  const { data } = await http.get(url.replace("params", params));

  return data;
};

export const add = async (payload: IAddUpdateGradeData) => {
  const url = config.endpoints.grades.add;
  const { data } = await http.post(url, {
    ...payload,
  });

  return data;
};

export const update = async (payload: IAddUpdateGradeData) => {
  const url = config.endpoints.grades.update;
  const gradeId = payload?._id || "";
  const { data } = await http.patch(url.replace(":gradeId", gradeId), {
    ...payload,
  });

  return data;
};

export const remove = async (gradeId: string) => {
  const url = config.endpoints.grades.delete.replace(":gradeId", gradeId);
  const { data } = await http.delete(url);

  return data;
};
