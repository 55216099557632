import React from "react";
import { ITenantUser } from "store/actions/types/multitenancy";
import AddEditUserForm from "./AddEditUserForm";

export interface AddEditUserProps {
  isEditing: boolean;
  closeModal: () => void;
  handleUserSave: (payload: ITenantUser) => void;
  selectedRow: ITenantUser;
  tenantId: string;
}

const AddEditUser: React.FC<AddEditUserProps> = (props: AddEditUserProps) => {
  const { closeModal, isEditing } = props;

  return (
    <div>
      <div className=" modal-wrap--lg">
        <div className="modal-wrap__header">
          <h3> {isEditing ? "Edit User" : "Add User"}</h3>
          <span className="link-item" onClick={closeModal}>
            <box-icon name="x" />
          </span>
        </div>
        <AddEditUserForm {...props} />
      </div>
    </div>
  );
};

export default AddEditUser;
