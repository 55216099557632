import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  IAssignment,
  IAssignmentParams,
  IUpdateAssignment,
} from "commons/types/assignments";

export const add = async (payload: IAssignment) => {
  const url = config.endpoints.assignments.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: IAssignmentParams) => {
  const url = stringify(config.endpoints.assignments.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: IUpdateAssignment) => {
  const url = pinterpolate(config.endpoints.assignments.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.assignments.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
