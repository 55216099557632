import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import {
  ISchoolDistrict,
  ISchoolDistrictParams,
} from "commons/types/schoolDistrict";

export const add = async (payload: ISchoolDistrict) => {
  const url = config.endpoints.schoolDistrict.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const fetchAll = async (params: ISchoolDistrictParams) => {
  const url = stringify(config.endpoints.schoolDistrict.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const update = async (id: string, payload: ISchoolDistrict) => {
  const url = pinterpolate(config.endpoints.schoolDistrict.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteOne = async (id: string) => {
  const url = pinterpolate(config.endpoints.schoolDistrict.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
