import React from "react";
import Modal from "react-modal";
import { Channel as ChannelType } from "twilio-chat/lib/channel";

import { playFile } from "utils/audio";
import { getFullName, getNameInitials } from "utils/names";
import { IUser } from "commons/types/users";
import IProfile from "commons/types/profile";
import { getDateDifference } from "utils/dates";
import IncomingCall from "./incoming-call/IncomingCall";

interface IChannelProps {
  channel: any;
  onThreadClick: any;
  profile: IProfile;
  student?: IUser;
  unreadMessageChannels: ChannelType[];
  moveChannelToTop: (channel: ChannelType) => void;
  setUnreadMessage: (channel: ChannelType) => void;
}

interface IChannelState {
  newMessage: string | null;
  newMessageTime: string | null;
  calling: any;
  interval?: any;
  trigger: boolean;
  relativeLatestMessage: string;
}

const MEDIA_FILE = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/success.mp3";

class Channel extends React.Component<IChannelProps, IChannelState> {
  constructor(props: IChannelProps) {
    super(props);

    this.state = {
      calling: null,
      trigger: false,
      newMessage: null,
      interval: undefined,
      newMessageTime: null,
      relativeLatestMessage: "",
    };
  }

  cancelHandler = () => {
    this.setState({
      calling: null,
    });
  };

  answerHandler = () => {
    this.setState({
      calling: null,
    });
  };

  getRelateTimeDifference = (date: Date) => {
    const difference = getDateDifference(date);
    const hours = Math.floor(difference / 60);
    const days = Math.floor(hours / 24);
    if (days >= 1) {
      return `${days}d`;
    }
    if (hours >= 1) {
      return `${hours}h`;
    }
    return `${difference}m`;
  };

  handleMessageAdded = ({ attributes, body, state, author }: any) => {
    let trigger = false;
    if (author !== this.props.profile.auth0UserId) {
      trigger = true;
    }

    this.setState(
      {
        newMessage: body,
        newMessageTime: state.timestamp,
        trigger,
      },
      () => {
        // TODO: Replace with other audio
        if (trigger) {
          playFile(MEDIA_FILE);
          this.props.setUnreadMessage(this.props.channel);
        }
      },
    );
    this.props.moveChannelToTop(this.props.channel);
  };

  componentDidMount() {
    this.props.channel.twilioChannel.on(
      "messageAdded",
      this.handleMessageAdded,
    );
    this.refreshLatestMessageTime();
    const interval = setInterval(() => {
      this.refreshLatestMessageTime();
    }, 1000);
    this.setState({ ...this.state, interval: interval });
  }

  componentWillUnmount() {
    this.props.channel.twilioChannel.off(
      "messageAdded",
      this.handleMessageAdded,
    );
    clearInterval(this.state.interval);
  }

  getLastMessageTime = () =>
    this.state.newMessageTime
      ? this.getRelateTimeDifference(new Date(this.state.newMessageTime))
      : this.props.channel.lastMessage &&
        this.getRelateTimeDifference(
          this.props.channel.lastMessage.dateCreated,
        );

  refreshLatestMessageTime = () => {
    this.setState({
      ...this.state,
      relativeLatestMessage: this.getLastMessageTime(),
    });
  };

  render() {
    return (
      <div
        onClick={() => {
          this.props.onThreadClick(this.props.channel.twilioChannel);
        }}
        className={
          this.props.unreadMessageChannels.some(
            (channel) => channel.uniqueName === this.props.channel.uniqueName,
          )
            ? "link-item message-list unread"
            : "link-item message-list"
        }
      >
        <div className="avatar-wrap">
          <div className="avatar mr-2x">
            {this.props.student?.avatar ? (
              <img src={this.props.student.avatar} alt="avatar" />
            ) : this.props.student ? (
              getNameInitials(this.props.student)
            ) : this.props.channel.friendlyName?.length ? (
              getNameInitials(this.props.channel.friendlyName)
            ) : (
              "U"
            )}
          </div>
          <span className="avatar-status">active</span>
        </div>
        <div className="message-text">
          <h3>
            {this.props.student
              ? getFullName(this.props.student)
              : this.props.channel.friendlyName}
            <span>
              {this.props.student?.userData?.schoolInfo?.grade &&
                `(Grade ${this.props.student.userData.schoolInfo?.grade})`}
            </span>
            <span className="msg-status">unread</span>
          </h3>
          <div className="msg-preview-wrap">
            <span className="time">{this.state.relativeLatestMessage}</span>
            <span
              className={`${
                this.state.newMessage || this.props.channel.lastMessage?.body
                  ? "msg-preview"
                  : ""
              } text-ellipsis`}
            >
              {this.state.newMessage ||
                this.props.channel.lastMessage?.body || (
                  <span className="text-medium">No messages</span>
                )}
            </span>
          </div>
        </div>
        {
          <Modal
            className="modal-block"
            isOpen={!(this.state.calling === null) && this.state.trigger}
            ariaHideApp={false}
            style={{
              content: {
                borderRadius: "16px",
              },
            }}
          >
            <IncomingCall {...this.state.calling} />
          </Modal>
        }
      </div>
    );
  }
}

export default Channel;
