import {
  AddTaskActionType,
  DeleteTaskActionType,
  FetchTaskActionType,
  FetchTasksActionType,
  MarkAsIncompleteTaskType,
  UpdateTaskActionType,
} from "store/actions/types/tasks";
import { IGoal } from "commons/types/goals";

const INITIAL_STATE = {
  addTask: {},
  addTaskLoading: false,
  addTaskError: null,
  fetchTask: {},
  fetchTaskLoading: false,
  fetchTaskError: null,
  updateTask: {},
  updateTaskLoading: false,
  updateTaskError: null,
  fetchTasks: [],
  fetchTasksLoading: false,
  fetchTasksError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddTaskActionType.ADD_TASK_PENDING:
      return {
        ...state,
        addTaskLoading: true,
        addTaskError: null,
      };
    case AddTaskActionType.ADD_TASK_FULFILLED:
      return {
        ...state,
        addTask: action.data,
        addTaskLoading: false,
        addTaskError: null,
      };
    case AddTaskActionType.ADD_TASK_REJECTED:
      return {
        ...state,
        addTaskLoading: false,
        addTaskError: action.data,
      };

    case FetchTaskActionType.FETCH_TASK_PENDING:
      return {
        ...state,
        fetchTaskLoading: true,
        fetchTaskError: null,
      };
    case FetchTaskActionType.FETCH_TASK_FULFILLED:
      return {
        ...state,
        fetchTask: action.data,
        fetchTaskLoading: false,
        fetchTaskError: null,
      };
    case FetchTaskActionType.FETCH_TASK_REJECTED:
      return {
        ...state,
        fetchTaskLoading: false,
        fetchTaskError: action.data,
      };

    case FetchTasksActionType.FETCH_TASKS_PENDING:
      return {
        ...state,
        fetchTasksLoading: true,
        fetchTasksError: null,
      };
    case FetchTasksActionType.FETCH_TASKS_FULFILLED:
      return {
        ...state,
        fetchTasks: action.data,
        fetchTasksLoading: false,
        fetchTasksError: null,
      };
    case FetchTasksActionType.FETCH_TASKS_REJECTED:
      return {
        ...state,
        fetchTasksLoading: false,
        fetchTasksError: action.data,
      };

    case UpdateTaskActionType.UPDATE_TASK_PENDING:
      return {
        ...state,
        updateTaskLoading: true,
        updateTaskError: null,
      };
    case UpdateTaskActionType.UPDATE_TASK_FULFILLED:
      return {
        ...state,
        updateTask: action.data,
        fetchTasks: state.fetchTasks.map((task: IGoal) => {
          if (task._id === action.data._id) return action.data;
          return task;
        }),
        updateTaskLoading: false,
        updateTaskError: null,
      };
    case UpdateTaskActionType.UPDATE_TASK_REJECTED:
      return {
        ...state,
        updateTaskLoading: false,
        updateTaskError: action.data,
      };

    case DeleteTaskActionType.DELETE_TASK_PENDING:
      return {
        ...state,
        deleteTaskLoading: true,
        deleteTaskError: null,
      };
    case DeleteTaskActionType.DELETE_TASK_FULFILLED:
      return {
        ...state,
        deleteTask: action.data,
        fetchTasks: state.fetchTasks.filter(
          (task: IGoal) => task._id !== action.data._id,
        ),
        deleteTaskLoading: false,
        deleteTaskError: null,
      };
    case DeleteTaskActionType.DELETE_TASK_REJECTED:
      return {
        ...state,
        deleteTaskLoading: false,
        deleteTaskError: action.data,
      };
    case MarkAsIncompleteTaskType.MARK_AS_INCOMPLETE_TASK_PENDING:
      return {
        ...state,
        updateTaskLoading: true,
        updateTaskError: null,
      };
    case MarkAsIncompleteTaskType.MARK_AS_INCOMPLETE_TASK_FULFILLED:
      return {
        ...state,
        updateTask: action.data,
        fetchTasks: state.fetchTasks.map((task: IGoal) => {
          if (task._id === action.data._id) return action.data;
          return task;
        }),
        updateTaskLoading: false,
        updateTaskError: null,
      };
    case MarkAsIncompleteTaskType.MARK_AS_INCOMPLETE_TASK_REJECTED:
      return {
        ...state,
        updateTaskLoading: false,
        updateTaskError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
