import { FC } from "react";
import Spinner, { LoaderProps } from "react-loader-spinner";

const Loader: FC<LoaderProps> = ({
  type,
  color = "#1D70B8",
  height = 50,
  width = 50,
  timeout = 0,
}) => {
  return (
    <div className="loader">
      <Spinner
        type={type}
        color={color}
        height={height}
        width={width}
        timeout={timeout}
      />
    </div>
  );
};
export default Loader;
