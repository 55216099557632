import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as assignmentService from "services/assignments";
import { IAssignmentParams } from "commons/types/assignments";
import { IAssignment, IUpdateAssignment } from "commons/types/assignments";
import {
  AddAssignmentActionType,
  FetchAssignmentsActionType,
  UpdateAssignmentActionType,
  DeleteAssignmentActionType,
} from "store/actions/types/assignments";

export const addAssignment =
  (payload: IAssignment) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddAssignmentActionType.ADD_ASSIGNMENT_PENDING,
    });
    try {
      const { data } = await assignmentService.add(payload);
      dispatch({
        type: AddAssignmentActionType.ADD_ASSIGNMENT_FULFILLED,
        data: data,
      });
      success("Saved assignment");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddAssignmentActionType.ADD_ASSIGNMENT_REJECTED,
        data: err,
      });

      error("Assignment failed to save");
    }
  };

export const fetchAssignments =
  (params: IAssignmentParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchAssignmentsActionType.FETCH_ASSIGNMENT_PENDING,
    });
    try {
      const { data } = await assignmentService.fetchAll(params);
      dispatch({
        type: FetchAssignmentsActionType.FETCH_ASSIGNMENT_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchAssignmentsActionType.FETCH_ASSIGNMENT_REJECTED,
        data: err,
      });

      error("Assignments failed to load");
    }
  };

export const updateAssignment =
  (id: string, payload: IUpdateAssignment) => async (dispatch: Dispatch) => {
    dispatch({ type: UpdateAssignmentActionType.UPDATE_ASSIGNMENT_PENDING });
    try {
      const { data } = await assignmentService.update(id, payload);
      dispatch({
        type: UpdateAssignmentActionType.UPDATE_ASSIGNMENT_FULFILLED,
        data: data,
      });
      success("Assignment updated successfully");
    } catch (err: any) {
      dispatch({
        type: UpdateAssignmentActionType.UPDATE_ASSIGNMENT_REJECTED,
        data: err,
      });

      error("Assignment failed to update");
    }
  };

export const deleteAssignment = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: DeleteAssignmentActionType.DELETE_ASSIGNMENT_PENDING });
  try {
    const { data } = await assignmentService.deleteOne(id);
    dispatch({
      type: DeleteAssignmentActionType.DELETE_ASSIGNMENT_FULFILLED,
      data: data,
    });
    success("Assignment deleted successfully");
  } catch (err: any) {
    dispatch({
      type: DeleteAssignmentActionType.DELETE_ASSIGNMENT_REJECTED,
      data: err,
    });

    error("Assignment failed to delete");
  }
};
