import { IGoal as ITask } from "commons/types/goals";

export enum AddTaskActionType {
  ADD_TASK_PENDING = "ADD_TASK_PENDING",
  ADD_TASK_REJECTED = "ADD_TASK_REJECTED",
  ADD_TASK_FULFILLED = "ADD_TASK_FULFILLED",
}

export interface AddTaskAction {
  type: AddTaskActionType;
  data: ITask;
}

export enum FetchTasksActionType {
  FETCH_TASKS_PENDING = "FETCH_TASKS_PENDING",
  FETCH_TASKS_REJECTED = "FETCH_TASKS_REJECTED",
  FETCH_TASKS_FULFILLED = "FETCH_TASKS_FULFILLED",
}

export interface FetchTasksAction {
  type: FetchTasksActionType;
  data: ITask[];
}

export enum FetchTaskActionType {
  FETCH_TASK_PENDING = "FETCH_TASK_PENDING",
  FETCH_TASK_REJECTED = "FETCH_TASK_REJECTED",
  FETCH_TASK_FULFILLED = "FETCH_TASK_FULFILLED",
}

export interface fetchTaskAction {
  type: FetchTaskActionType;
  data: ITask;
}

export enum UpdateTaskActionType {
  UPDATE_TASK_PENDING = "UPDATE_TASK_PENDING",
  UPDATE_TASK_REJECTED = "UPDATE_TASK_REJECTED",
  UPDATE_TASK_FULFILLED = "UPDATE_TASK_FULFILLED",
}

export interface updateTaskAction {
  type: UpdateTaskActionType;
  data: ITask;
}

export enum DeleteTaskActionType {
  DELETE_TASK_PENDING = "DELETE_TASK_PENDING",
  DELETE_TASK_REJECTED = "DELETE_TASK_REJECTED",
  DELETE_TASK_FULFILLED = "DELETE_TASK_FULFILLED",
}

export interface DeleteTaskAction {
  type: DeleteTaskActionType;
  data: ITask;
}

export enum MarkAsIncompleteTaskType {
  MARK_AS_INCOMPLETE_TASK_PENDING = "MARK_AS_INCOMPLETE_TASK_PENDING",
  MARK_AS_INCOMPLETE_TASK_REJECTED = "MARK_AS_INCOMPLETE_TASK_REJECTED",
  MARK_AS_INCOMPLETE_TASK_FULFILLED = "MARK_AS_INCOMPLETE_TASK_TASK_FULFILLED",
}
