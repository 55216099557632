import { Formik, FormikHelpers } from "formik";
import Select from "react-select";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import React, { FormEvent, Fragment } from "react";

import {
  SCHOOL_TYPE,
  GRADES_OPTIONS,
  ACADEMIC_YEAR_OPTIONS,
  STUDENT_TYPES_OPTIONS,
  UNIVERSITY_YEAR_OPTIONS,
  DUPLICATE_USER_STATUS_CODE,
} from "constants/index";
import config from "configs";
import { IInstitution } from "commons/types";
import { ISponsor, IUser } from "commons/types/users";
import { IDistrict } from "commons/types/institutions";
import { studentSchema } from "commons/schemas/student";
import { IAccommodation } from "commons/types/accommodation";
import PhoneNumber from "commons/components/form-fields/PhoneNumber";
import {
  DateInput,
  TextInput,
  OptionsInput,
  TextAreaInput,
} from "commons/components/form-fields";
import { error } from "utils/toast";
import { Status } from "constants/status";
import { TIME_ZONE_OPTIONS } from "constants/index";

interface Props {
  isUpdate?: boolean;
  initialValues: IUser;
  saveStudent: (data: IUser) => void;
  setSponsorUpdate: (sponsor: ISponsor) => void;
  handleRemoveGuardian: (id: string) => void;
  setIsAddSponsorOpen: (state: boolean) => void;
  setIsSearchSponsorOpen: (state: boolean) => void;
  guardians: ISponsor[];
  schools: IInstitution[];
  universities: IInstitution[];
  districts: IDistrict[];
  accommodations: IAccommodation[];
}

interface FormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, value: any) => void;
  values: IUser;
  touched: IUser;
  errors: IUser;
  initialValues: IUser;
  isSubmitting: boolean;
}

interface LabelValue {
  label: string;
  value: string;
}

const StudentForm: React.FC<Props> = ({
  schools,
  guardians,
  districts,
  saveStudent,
  universities,
  initialValues,
  accommodations,
  setSponsorUpdate,
  isUpdate = false,
  setIsAddSponsorOpen,
  handleRemoveGuardian,
  setIsSearchSponsorOpen,
}): JSX.Element => {
  const history = useHistory();

  const districtOptions: LabelValue[] = districts.map((record: IDistrict) => ({
    label: record.name,
    value: record._id,
  }));

  const schoolOptions: LabelValue[] = schools.map((record: IInstitution) => ({
    label: record.name,
    value: record._id,
  }));

  const universitiesOptions: LabelValue[] = universities.map(
    (record: IInstitution) => ({
      label: record.name,
      value: record._id,
    }),
  );

  const accommodationsOptions: LabelValue[] = accommodations.map(
    (accommodation: IAccommodation) => ({
      label: accommodation.name,
      value: accommodation._id,
    }),
  );

  const noteElements = (props: FormikProps) => {
    return (
      <textarea
        className="textarea"
        placeholder="Add notes"
        name={`userData.schoolInfo.notes`}
        value={props.values.userData?.schoolInfo?.notes || ""}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
      ></textarea>
    );
  };

  const guardianCardElements = (guardians: any[]) => {
    let cards: JSX.Element[] = [];
    guardians.map((guardian: ISponsor) => {
      const relation = guardian.relationshipWithSponsor
        ? ` (${guardian.relationshipWithSponsor})`
        : "";
      cards.push(
        <div key={guardian.userId?._id} className="card card-addinfo">
          <div className="card-content">
            <div className="card-text">
              <h3>{`${guardian.userId?.firstName} ${guardian.userId?.lastName} ${relation}`}</h3>
              <span className="card-address">{`${guardian.userId?.phoneNumber} (${guardian.userId?.email})`}</span>
            </div>
          </div>
          <div className="card-actions">
            <span className="link-item">
              <box-icon
                onClick={() => setSponsorUpdate(guardian)}
                name="edit-alt"
              ></box-icon>
            </span>
            <span className="link-item">
              <box-icon
                onClick={() => handleRemoveGuardian(guardian.userId?._id || "")}
                name="trash-alt"
              ></box-icon>
            </span>
          </div>
        </div>,
      );
      return cards;
    });

    return cards;
  };

  const academicFormElements = (type: string, props: FormikProps) => {
    return type === "School" ? (
      <Fragment>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label">Name of School</label>
              <Select
                placeholder="Please select school"
                name="userData.schoolInfo.institution"
                onChange={(selected: any) =>
                  props.setFieldValue(
                    "userData.schoolInfo.institution",
                    selected.value,
                  )
                }
                value={schoolOptions.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.institution),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
                options={schoolOptions}
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.institution &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.institution}
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label">School District</label>
              <Select
                placeholder="Please select school district"
                name="userData.schoolInfo.schoolDistrict"
                options={districtOptions}
                onChange={(selected: any) =>
                  props.setFieldValue(
                    "userData.schoolInfo.schoolDistrict",
                    selected.value,
                  )
                }
                value={districtOptions.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.schoolDistrict),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.schoolDistrict &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.schoolDistrict}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label">Grade</label>
              <Select
                placeholder="Please select grade"
                name="userData.schoolInfo.grade"
                options={GRADES_OPTIONS}
                onChange={(selected: any) =>
                  props.setFieldValue(
                    "userData.schoolInfo.grade",
                    selected.value,
                  )
                }
                value={GRADES_OPTIONS.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.grade),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.grade &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.grade}
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label">Academic Year</label>
              <Select
                placeholder="Please select academic year"
                name="userData.schoolInfo.academicYear"
                options={ACADEMIC_YEAR_OPTIONS}
                onChange={(selected: any) =>
                  props.setFieldValue(
                    "userData.schoolInfo.academicYear",
                    selected.value,
                  )
                }
                value={ACADEMIC_YEAR_OPTIONS.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.academicYear),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.academicYear &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.academicYear}
              </label>
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="input-wrap">
              <label className="input__label">Name of University</label>
              <Select
                placeholder="Please select school"
                name="userData.schoolInfo.institution"
                onChange={(selected: any) => {
                  props.setFieldValue(
                    "userData.schoolInfo.institution",
                    selected.value,
                  );
                }}
                value={universitiesOptions.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.institution),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
                options={universitiesOptions}
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.institution &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.institution}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label">Student Type</label>
              <Select
                placeholder="Please select studentType"
                name="userData.schoolInfo.studentType"
                options={STUDENT_TYPES_OPTIONS}
                onChange={(selected: any) =>
                  props.setFieldValue(
                    "userData.schoolInfo.studentType",
                    selected.value,
                  )
                }
                value={STUDENT_TYPES_OPTIONS.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.studentType),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.studentType &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.studentType}
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="input-wrap">
              <label className="input__label">University Year</label>
              <Select
                placeholder="Please select academic year"
                name="userData.schoolInfo.academicYear"
                options={UNIVERSITY_YEAR_OPTIONS}
                onChange={(selected: any) =>
                  props.setFieldValue(
                    "userData.schoolInfo.academicYear",
                    selected.value,
                  )
                }
                value={UNIVERSITY_YEAR_OPTIONS.filter(
                  (option) =>
                    option.value ===
                    (props.values.userData?.schoolInfo &&
                      props.values.userData.schoolInfo.academicYear),
                )}
                onBlur={props.handleBlur}
                classNamePrefix="react-select"
              />
              <label className="input__error">
                {props.touched.userData?.schoolInfo &&
                  props.touched.userData.schoolInfo.academicYear &&
                  props.errors.userData?.schoolInfo &&
                  props.errors.userData.schoolInfo.academicYear}
              </label>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const resetSchoolInfo = (props: FormikProps) => {
    props.setFieldValue("userData.schoolInfo.institution", undefined);
    props.setFieldValue("userData.schoolInfo.schoolDistrict", undefined);
    props.setFieldValue("userData.schoolInfo.academicYear", "");
    props.setFieldValue("userData.schoolInfo.grade", "");
    props.setFieldValue("userData.schoolInfo.studentType", "");
    props.setFieldValue("userData.schoolInfo.accommodation", undefined);
  };

  const onSubmitHandler = async (
    data: IUser,
    formikProps: FormikHelpers<IUser>,
  ) => {
    let payload = { ...data };

    if (!payload.dateOfBirth) delete payload.dateOfBirth;

    try {
      await saveStudent(payload);
    } catch (err: any) {
      if (err.response?.status === DUPLICATE_USER_STATUS_CODE) {
        formikProps.setFieldError("email", "Email already exists");
        error("Email already exists");
      }
    }
  };

  const scrollToError = (errors: any) => {
    const keys = Object.keys(errors);

    if (keys.length) {
      let name = keys[0];
      let value = errors[name];
      while (typeof value === "object") {
        const innerKeys = Object.keys(value);
        if (innerKeys.length) {
          const innerName = innerKeys[0];
          value = value[innerName];
          name = name + `.${innerName}`;
        } else value = "";
      }
      const errorElements = document.getElementsByName(name);
      if (errorElements.length) errorElements[0].focus();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={studentSchema}
      enableReinitialize={true}
    >
      {(props: FormikProps) => {
        const guardianDataParse = (guardians: ISponsor[]) => {
          return guardians.map((guardian: ISponsor) => {
            return {
              userId: guardian.userId._id,
              relationshipWithSponsor: guardian.relationshipWithSponsor,
              livingScheduleWithParents: guardian.livingScheduleWithParents,
            };
          });
        };
        const parsedGuardians = guardianDataParse(guardians);
        if (
          JSON.stringify(props.values.relatedUsers?.sponsors) !==
          JSON.stringify(parsedGuardians)
        ) {
          props.setFieldValue("relatedUsers.sponsors", parsedGuardians);
        }

        const checkAndScrollToGuardianError = () => {
          Object.keys(props.touched).length > 0 &&
            props.errors.relatedUsers?.sponsors &&
            scrollToError({
              "userData.isGuardianApplicable":
                props.errors.relatedUsers?.sponsors,
            });
        };

        return (
          <form onSubmit={props.handleSubmit}>
            <section className="section">
              <div className="section__title">
                <h3
                  className={`${
                    props.values.userData?.isGuardianApplicable
                      ? "required"
                      : null
                  }`}
                >
                  Parents/Guardians Information
                </h3>
                {props.values.userData?.isGuardianApplicable && (
                  <div className="actions">
                    <span
                      className="mr-2x link-item"
                      onClick={() => setIsSearchSponsorOpen(true)}
                    >
                      <box-icon name="search"></box-icon>
                    </span>
                    <span
                      className="link-item"
                      onClick={() => setIsAddSponsorOpen(true)}
                    >
                      <box-icon type="solid" name="plus-circle"></box-icon>
                    </span>
                  </div>
                )}
              </div>
              <div className="section__content">
                <div
                  className={classNames({
                    "input-wrap":
                      props.values.userData?.isGuardianApplicable &&
                      guardians.length,
                  })}
                >
                  <input
                    type="checkbox"
                    id="check1"
                    className="form-group__radio mr-10"
                    name="userData.isGuardianApplicable"
                    checked={!props.values.userData?.isGuardianApplicable}
                    onChange={(e) =>
                      props.setFieldValue(
                        "userData.isGuardianApplicable",
                        !e.target.checked,
                      )
                    }
                    onBlur={props.handleBlur}
                  />
                  <label htmlFor="check1" className="box-links__nodes">
                    Please check this option if parents/guardians information is
                    not applicable to this student.
                  </label>
                </div>
                {props.values.userData?.isGuardianApplicable &&
                  guardianCardElements(guardians)}
                <p className="input__error">
                  {Object.keys(props.touched).length > 0 &&
                    props.errors.relatedUsers?.sponsors}
                </p>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Student Information</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <TextInput
                      label="First Name"
                      placeholder="Enter first name"
                      name="firstName"
                      values={props.values}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      errors={props.errors}
                      touched={props.touched}
                      required={true}
                    />
                  </div>
                  <div className="col-6">
                    <TextInput
                      label="Last Name"
                      placeholder="Enter last name"
                      name="lastName"
                      values={props.values}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      errors={props.errors}
                      touched={props.touched}
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <TextInput
                      label="Nickname"
                      placeholder="Enter nickname"
                      name="userData.nickName"
                      values={props.values}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      errors={props.errors}
                      touched={props.touched}
                    />
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Date of Birth</label>
                      <DateInput
                        onChangeRaw={(e: any) => {}}
                        name="dateOfBirth"
                        selected={props.values.dateOfBirth || ""}
                        maxDate={new Date()}
                        onChange={(date) =>
                          props.setFieldValue("dateOfBirth", date)
                        }
                        placeholder="Please select"
                        onBlur={props.handleBlur}
                        dateFormat="MM/dd/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <TextInput
                      label="Email Address"
                      placeholder="Enter email"
                      name="email"
                      disabled={isUpdate}
                      values={props.values}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      errors={props.errors}
                      touched={props.touched}
                      helper="Please use email address provided by the school. Do not invite students in their personal email addresses."
                      required={true}
                    />
                  </div>
                  <div className="col-6">
                    <PhoneNumber
                      label="Phone Number"
                      placeholder="+1 123-456-7890"
                      name="phoneNumber"
                      values={props.values}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      errors={props.errors}
                      touched={props.touched}
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">Time Zone</label>
                      <Select
                        placeholder="Please Select Time Zone"
                        name="timezone"
                        onChange={(selected: any) => {
                          props.setFieldValue("timezone", selected.value);
                        }}
                        onBlur={props.handleBlur}
                        options={TIME_ZONE_OPTIONS}
                        required={true}
                        errors={props.errors}
                        touched={props.touched}
                        classNamePrefix="react-select"
                        value={TIME_ZONE_OPTIONS.filter(
                          (option) => option.value === props.values?.timezone,
                        )}
                      />
                      <label className="input__error">
                        {props.errors?.timezone && props.errors.timezone}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3x required">Learning environment</div>
                    <OptionsInput
                      type="radio"
                      groupName="userData.learningEnvironment"
                      handleChange={props.handleChange}
                      errors={props.errors}
                      touched={props.touched}
                      options={[
                        {
                          id: "remote",
                          label: "Remote",
                          value: "Remote",
                          isChecked:
                            props.values.userData?.learningEnvironment ===
                            "Remote",
                        },
                        {
                          id: "in-person",
                          label: "In person",
                          value: "In-person",
                          isChecked:
                            props.values.userData?.learningEnvironment ===
                            "In-person",
                        },
                        {
                          id: "hybrid",
                          label: "Hybrid",
                          value: "Hybrid",
                          isChecked:
                            props.values.userData?.learningEnvironment ===
                            "Hybrid",
                        },
                        {
                          id: "unsure",
                          label: "Unsure",
                          value: "Unsure",
                          isChecked:
                            props.values.userData?.learningEnvironment ===
                            "Unsure",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>School Information</h3>
              </div>
              <div className="section__content">
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    id="check2"
                    className="form-group__radio mr-10"
                    name="userData.schoolInfo.isSchoolInfoApplicable"
                    checked={
                      !props.values.userData?.schoolInfo ||
                      !props.values.userData.schoolInfo.isSchoolInfoApplicable
                    }
                    onChange={(e) =>
                      props.setFieldValue(
                        "userData.schoolInfo.isSchoolInfoApplicable",
                        !e.target.checked,
                      )
                    }
                    onBlur={props.handleBlur}
                  />
                  <label htmlFor="check2" className="box-links__nodes">
                    Please check this option if school information is not
                    applicable to this student.
                  </label>
                  <label className="input__error">
                    {props.touched.userData?.schoolInfo &&
                      props.touched.userData.schoolInfo
                        .isSchoolInfoApplicable &&
                      props.errors.userData?.schoolInfo &&
                      props.errors.userData.schoolInfo.isSchoolInfoApplicable}
                  </label>
                </div>

                {props.values.userData?.schoolInfo &&
                  props.values.userData.schoolInfo.isSchoolInfoApplicable && (
                    <Fragment>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3x">
                            Does this student go to a school or a university?
                          </div>

                          <OptionsInput
                            type="radio"
                            groupName="userData.schoolInfo.schoolType"
                            handleChange={(event) => {
                              props.handleChange(event);
                              resetSchoolInfo(props);
                            }}
                            errors={props.errors}
                            touched={props.touched}
                            options={[
                              {
                                id: "school",
                                label: "School",
                                value: "School",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .schoolType === "School",
                              },
                              {
                                id: "university",
                                label: "University",
                                value: "University",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .schoolType === "University",
                              },
                              {
                                id: "high-school",
                                label: "2 year college",
                                value: "High School",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .schoolType === "High School",
                              },
                            ]}
                          />
                        </div>
                      </div>

                      {academicFormElements(
                        props.values.userData.schoolInfo.schoolType || "School",
                        props,
                      )}

                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3x">Academic Term</div>

                          <OptionsInput
                            type="radio"
                            groupName="userData.schoolInfo.academicTerm"
                            handleChange={props.handleChange}
                            errors={props.errors}
                            touched={props.touched}
                            options={[
                              {
                                id: "semester",
                                label: "Semester",
                                value: "Semester",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .academicTerm === "Semester",
                              },
                              {
                                id: "trimester",
                                label: "Trimester",
                                value: "Trimester",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .academicTerm === "Trimester",
                              },
                              {
                                id: "quarter",
                                label: "Quarter",
                                value: "Quarter",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .academicTerm === "Quarter",
                              },
                              {
                                id: "Year-round-school",
                                label: "Year-round School",
                                value: "Year-round School",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .academicTerm === "Year-round School",
                              },
                              {
                                id: "block",
                                label: "Block",
                                value: "Block",
                                isChecked:
                                  props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .academicTerm === "Block",
                              },
                            ]}
                          />
                        </div>
                      </div>
                      {props.values?.userData?.schoolInfo?.schoolType ===
                      SCHOOL_TYPE.SCHOOL ? (
                        <div className="row">
                          <div className="col-6">
                            <div className="input-wrap">
                              <label className="input__label">
                                Accommodation
                              </label>
                              <Select
                                placeholder="Please select accommodation"
                                name="userData.schoolInfo.accommodation"
                                onChange={(selected: any) => {
                                  props.setFieldValue(
                                    "userData.schoolInfo.accommodation",
                                    selected.value,
                                  );
                                }}
                                value={accommodationsOptions.filter(
                                  (option) =>
                                    option.value ===
                                    (props.values.userData?.schoolInfo &&
                                      props.values.userData.schoolInfo
                                        ?.accommodation),
                                )}
                                onBlur={props.handleBlur}
                                classNamePrefix="react-select"
                                options={
                                  props.values.userData?.schoolInfo
                                    ?.schoolType === "School"
                                    ? accommodationsOptions
                                    : []
                                }
                              />
                              <label className="input__error">
                                {props.touched.userData?.schoolInfo &&
                                  props.touched.userData.schoolInfo
                                    .accommodation?._id &&
                                  props.errors.userData?.schoolInfo &&
                                  props.errors.userData.schoolInfo.accommodation
                                    ?._id}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="row">
                        <div className="col-6">
                          <div className="input-wrap">
                            <input
                              type="checkbox"
                              id="check3"
                              className="form-group__radio mr-10"
                              name="userData.schoolInfo.isOnAcademicProbation"
                              checked={
                                (props.values.userData?.schoolInfo &&
                                  props.values.userData.schoolInfo
                                    .isOnAcademicProbation) ||
                                false
                              }
                              onChange={(e) =>
                                props.setFieldValue(
                                  "userData.schoolInfo.isOnAcademicProbation",
                                  e.target.checked,
                                )
                              }
                              onBlur={props.handleBlur}
                            />
                            <label
                              htmlFor="check3"
                              className="box-links__nodes"
                            >
                              On Academic Probation
                            </label>
                            <label className="input__error">
                              {props.touched.userData?.schoolInfo &&
                                props.touched.userData.schoolInfo
                                  .isOnAcademicProbation &&
                                props.errors.userData?.schoolInfo &&
                                props.errors.userData.schoolInfo
                                  .isOnAcademicProbation}
                            </label>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">Notes</label>
                      {noteElements(props)}
                      <label className="input__error">
                        {props.touched.userData?.schoolInfo &&
                          props.touched.userData.schoolInfo.notes &&
                          props.errors.userData?.schoolInfo &&
                          props.errors.userData.schoolInfo.notes}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Student Diagnosis</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <TextAreaInput
                      label={"Diagnosis, behavioral issues, or other concerns"}
                      name="userData.diagnosis"
                      placeholder="Please enter student’s diagnosis,
                      issues/challenges/concerns, hobbies and interest, focus
                      areas and additional information."
                      values={props.values.userData?.diagnosis}
                      handleChange={props.handleChange}
                      handleBlur={props.handleBlur}
                      touched={props.touched}
                      errors={props.errors}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__content">
                <div className="row">
                  <div className="col-12 d-flex">
                    <button
                      disabled={props.isSubmitting ? props.isSubmitting : false}
                      className="btn btn--primary mr-4x"
                      type="submit"
                      onClick={() => {
                        checkAndScrollToGuardianError();

                        if (Object.keys(props.errors).length) {
                          scrollToError(props.errors);
                          return;
                        }
                        props.setFieldValue("userData.status", Status.INACTIVE);
                      }}
                    >
                      {isUpdate &&
                      props.values.userData?.status !== Status.DRAFT
                        ? "Save Information"
                        : "Add Student"}
                    </button>
                    {initialValues.userData?.status === Status.DRAFT && (
                      <button
                        disabled={
                          props.isSubmitting ||
                          props.values === props.initialValues
                        }
                        className="btn btn--outlined-primary"
                        type="submit"
                        onClick={() => {
                          checkAndScrollToGuardianError();

                          if (Object.keys(props.errors).length)
                            scrollToError(props.errors);
                        }}
                      >
                        Save as draft
                      </button>
                    )}
                    <span
                      className="btn btn-text txt-primary-color"
                      onClick={() => {
                        history.push(config.uiPath.students.list);
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </form>
        );
      }}
    </Formik>
  );
};

export default StudentForm;
