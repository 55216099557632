import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useState, useRef, MutableRefObject, useEffect } from "react";

import { validateAvatar } from "utils/user";
import { error, success } from "utils/toast";
import MentorUpdate from "pages/mentor/update";
import Loader from "commons/components/Loader";
import { IProfile, IUser } from "commons/types/users";
import { addAvatar, removeAvatar } from "services/users";
import { getFullName, getNameInitials } from "utils/names";
import DeleteConfirm from "commons/components/DeleteConfirm";
import { fetchUserProfile } from "store/actions/data/profile";
import { LEARNING_ENVIRONMENTS_OPTIONS } from "constants/index";
import { IUpdateMentorParams, updateMentor } from "services/mentors";
import ProfileLeftPanel from "pages/layouts/mentor/leftPanel/ProfileLeftPanel";
import { subdomainName } from "utils/http";

interface IProfileProps {
  profile: IProfile;
  fetchProfileLoading: boolean;
  fetchUserProfile: () => any;
}
const Profile: React.FC<IProfileProps> = ({
  profile,
  fetchProfileLoading,
  fetchUserProfile,
}) => {
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const inputPictureRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isDeleteAvatarOpen, setDeleteAvatarOpen] = useState<boolean>(false);
  const [avatarValidationError, setAvatarValidationError] =
    useState<string>("");

  const updateMentorInformation = async (payload: IUpdateMentorParams) => {
    await updateMentor(payload);
    success("Updated Successfully");
    fetchUserProfile();
  };

  const initialEditValues = {
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    email: profile?.email || "",
    phone: profile?.phoneNumber || "",
    environment: profile?.userData?.learningEnvironment || "",
    learningCenter: profile?.userData?.learningCenter || [],
    status: profile?.userData?.status || "",
    newEmail: "",
    newEmailConfirm: "",
    isEmailChangeActive: false,
  };

  const handlePictureChange = async (event: any) => {
    if (profile?._id) {
      try {
        if (validateAvatar(event.target.files[0])) {
          setAvatarValidationError("");
          await addAvatar(profile?._id, event.target.files[0]);
          fetchUserProfile();
          success("Profile picture updated successfully");
          inputPictureRef.current.value = "";
        } else {
          setAvatarValidationError("Please use supported file format and size");
        }
      } catch (err: any) {
        error("Failed to upload profile picture");
      }
    }
  };

  const handlePictureDelete = async () => {
    if (profile?._id) {
      try {
        setAvatarValidationError("");
        await removeAvatar(profile?._id);
        fetchUserProfile();
        success("Profile picture delete successfully");
        setDeleteAvatarOpen(false);
        inputPictureRef.current.value = "";
      } catch (err: any) {
        error("Failed to delete profile picture");
      }
    }
  };

  const mentorInfo = [
    { label: "Mentor name", value: getFullName(profile) },
    {
      label: "Email address",
      value: profile?.email,
    },
    { label: "Phone number", value: profile?.phoneNumber },
    {
      label: "Learning environment",
      value: profile?.userData?.learningEnvironment
        ? LEARNING_ENVIRONMENTS_OPTIONS.find(
            (environment: any) =>
              environment.value === profile.userData?.learningEnvironment,
          )?.label || "-"
        : "-",
    },
    {
      label: `${subdomainName} location`,
      value: profile?.userData?.learningCenter?.map((center: any) => (
        <div>{center?.address}</div>
      )),
    },
  ];

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  return (
    <>
      <ProfileLeftPanel activeTab="profile" currentUser={profile} />

      <div className="content-panel">
        {!profile && fetchProfileLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <>
            <div className="page-heading">
              <div className="page-heading__left">
                <div className="avatar">
                  {profile?.avatar ? (
                    <img src={profile?.avatar} alt="Student" />
                  ) : (
                    getNameInitials(profile)
                  )}
                </div>
                <div className="heading-detail">
                  <span className="text-light-sm fs-14">Profile</span>
                  <h2>{getFullName(profile)}</h2>
                </div>
              </div>
            </div>
            <section className="section ml-10x">
              <div className="section__content">
                <div className="card card-profile">
                  <div className="card-content">
                    <div className="avatar avatar--lg mr-2x">
                      {profile?.avatar ? (
                        <img src={profile?.avatar} alt="Student" />
                      ) : (
                        getNameInitials(profile)
                      )}
                    </div>
                    <div className="card-text">
                      <h3>Profile Picture</h3>
                      <div className="d-flex link-text fs-small">
                        <input
                          type="file"
                          ref={inputPictureRef}
                          onChange={handlePictureChange}
                          className={"input-avatar"}
                        />
                        <span
                          onClick={() => {
                            inputPictureRef && inputPictureRef.current.click();
                          }}
                        >
                          Upload new photo
                        </span>
                        <span
                          className="status status--error ml-2x"
                          onClick={() => setDeleteAvatarOpen(true)}
                        >
                          {profile?.avatar ? "Remove" : null}
                        </span>
                      </div>
                      <div className="d-flex mt-2x">
                        <span className="text-light-sm">
                          File size should be limited to 10MB.
                        </span>
                      </div>
                      <div className="d-flex mb-0x">
                        <span className="text-light-sm">
                          The supported file formats are jpg, jpeg and png.
                        </span>
                      </div>

                      <div className="d-flex">
                        {avatarValidationError.length ? (
                          <span className="input__error avatar-error">
                            <box-icon name="error-circle" size="xs" /> Please
                            use the supported file format and size
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section ml-10x">
              <div className="section__title mb-0x">
                <h3 className="txt-darkgrey-color">My Information</h3>
                <div className="actions">
                  <span className="link-item" onClick={() => setEditOpen(true)}>
                    <box-icon name="edit-alt" />
                  </span>
                </div>
              </div>
              <div className="section__content">
                {mentorInfo.map((info, index) => (
                  <div className="card card-addinfo" key={index}>
                    <div className="card-content">
                      <div className="card-text">
                        <div className="card-address">{info.label}</div>
                        <div className="d-block">{info.value || "-"}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </>
        )}
      </div>
      <Modal
        className="modal-block-form"
        isOpen={isEditOpen}
        onRequestClose={() => setEditOpen(false)}
      >
        <MentorUpdate
          updateMentorInformation={updateMentorInformation}
          isFromIntakeManager={false}
          closeModal={() => setEditOpen(false)}
          id={profile?._id ? profile._id : ""}
          initialValues={initialEditValues}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isDeleteAvatarOpen}
        onRequestClose={() => setDeleteAvatarOpen(false)}
      >
        <DeleteConfirm
          closeModal={() => setDeleteAvatarOpen(false)}
          deleteResource={handlePictureDelete}
          message={{
            header: "Do you want to delete this profile picture?",
            body: "This action is not reversible",
          }}
        />
      </Modal>
    </>
  );
};

interface State {
  profile: {
    fetchProfile: IUser;
    fetchProfileLoading: boolean;
  };
}

const mapStateToProps = (state: State) => ({
  profile: state.profile.fetchProfile,
  fetchProfileLoading: state.profile.fetchProfileLoading,
});

const mapDispatchToProps = {
  fetchUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
