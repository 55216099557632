import "boxicons";
import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import config from "configs";
import {
  fetchSchools,
  fetchDistricts,
  fetchUniversities,
} from "services/tenant";
import { error } from "utils/toast";
import { getRole } from "utils/roles";
import { Role } from "constants/roles";
import { ITerm } from "commons/types/terms";
import AddSponsor from "pages/sponsors/add";
import Loader from "commons/components/Loader";
import { getStudentFormValues } from "utils/user";
import SearchSponsor from "pages/sponsors/search";
import UpdateSponsor from "pages/sponsors/update";
import UpdateStudentForm from "../add/StudentForm";
import { ISponsor, IUser } from "commons/types/users";
import { fetchTerms } from "store/actions/data/terms";
import { IInstitution, IMentor } from "commons/types";
import { CustomErrorMessages } from "constants/errors";
import { IDistrict } from "commons/types/institutions";
import { fetchMentors } from "store/actions/data/mentors";
import { IAccommodation } from "commons/types/accommodation";
import * as accommodationService from "services/accommodation";
import { getDataFromLocalStorage } from "services/localStorage";
import { addSponsor, updateSponsor } from "store/actions/data/sponsors";
import { updateStudent, fetchStudent } from "store/actions/data/students";

type Props = {
  terms: ITerm[];
  student: IUser;
  isLoading: boolean;
  mentors: IMentor[];
  fetchTerms: () => {};
  fetchMentors: () => {};
  updateStudent: (id: string, payload: IUser) => Promise<IUser>;
  fetchStudent: (id: string) => Promise<IUser>;
  match: { params: { id: string } };
  addSponsor: (payload: IUser) => Promise<{ data: IUser }>;
  updateSponsor: (id: string, payload: IUser) => Promise<IUser>;
};

const EditStudent: React.FC<Props> = ({
  match: {
    params: { id },
  },
  student,
  isLoading,
  fetchTerms,
  addSponsor,
  updateStudent,
  fetchStudent,
  fetchMentors,
  updateSponsor: editSponsor,
}): JSX.Element => {
  useEffect(() => {
    fetchStudent(id);
    fetchMentors();
  }, [fetchMentors, fetchStudent, id]);

  const history = useHistory();
  const roles = getDataFromLocalStorage("roles");

  const [isAddSponsorOpen, setIsAddSponsorOpen] = useState(false);
  const [isSearchSponsorOpen, setIsSearchSponsorOpen] = useState(false);
  const [updateSponsor, setUpdateSponsor] = useState<ISponsor | null>(null);
  const [selectSponsor, setSelectSponsor] = useState<ISponsor | null>(null);

  const setSponsorUpdate = (sponsor: ISponsor) => {
    setUpdateSponsor(sponsor);
  };

  const [guardians, setGuardians] = useState(
    student.relatedUsers?.sponsors || [],
  );

  useEffect(() => {
    setGuardians(student.relatedUsers?.sponsors || []);
  }, [student.relatedUsers]);

  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  const handleAddGuardianSubmit = async (payload: any) => {
    const sponsor = getRole(roles, Role.SPONSOR);

    if (!sponsor) {
      return error(CustomErrorMessages.ROLE_MISSING);
    }

    const guardian = { ...payload };
    const { _id, name } = sponsor;
    guardian.roles = [{ _id, name }];

    delete guardian.relationshipWithSponsor;
    delete guardian.livingScheduleWithParents;

    const { data } = await addSponsor(guardian);

    setGuardians((prevState) => [
      ...prevState,
      {
        userId: { id: data._id, ...data },
        relationshipWithSponsor: payload.relationshipWithSponsor,
        livingScheduleWithParents: payload.livingScheduleWithParents,
      },
    ]);

    setIsAddSponsorOpen(false);
  };

  const handleRemoveGuardian = (id: string) => {
    setGuardians(guardians.filter((guardian) => guardian.userId?._id !== id));
  };

  const handleUpdateGuardianSubmit = async (payload: any) => {
    const guardian = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
    };
    const updatedSponsor = await editSponsor(payload.id, guardian);

    if (updatedSponsor) {
      setGuardians(
        guardians.map((user) => {
          if (user.userId?._id === payload.id)
            return {
              userId: { id: updatedSponsor._id, ...updatedSponsor },
              relationshipWithSponsor: payload.relationshipWithSponsor,
              livingScheduleWithParents: payload.livingScheduleWithParents,
            };
          return user;
        }),
      );

      setUpdateSponsor(null);
      setSelectSponsor(null);
    }
  };

  const initialValues = getStudentFormValues(student);

  const [schools, setSchools] = useState<IInstitution[]>([]);

  const [universities, setUniversities] = useState<IInstitution[]>([]);

  const [districts, setDistricts] = useState<IDistrict[]>([]);

  const [accommodations, setAccommodations] = useState<IAccommodation[]>([]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const populateInstitutionDropDown = async () => {
        try {
          const { data } = await fetchSchools();
          setSchools(data);
        } catch (ex) {
          error("Failed to fetch schools");
        }
      };
      const populateUniversityDropDown = async () => {
        try {
          const { data } = await fetchUniversities();
          setUniversities(data);
        } catch (ex) {
          error("Failed to fetch universities");
        }
      };
      const populateDistrictDropDown = async () => {
        try {
          const { data } = await fetchDistricts();
          setDistricts(data);
        } catch (ex) {
          error("Failed to fetch districts");
        }
      };
      const populateAccommodationDropDown = async () => {
        try {
          const { data } = await accommodationService.fetchAll({});
          setAccommodations(data);
        } catch (ex) {
          error("Failed to fetch accommodations");
        }
      };
      Promise.allSettled([
        populateInstitutionDropDown(),
        populateUniversityDropDown(),
        populateDistrictDropDown(),
        populateAccommodationDropDown(),
      ]);
    };
    fetchDropdownOptions();
  }, []);

  const saveStudent = async (data: IUser) => {
    const updatedStudent = await updateStudent(id, data);

    if (updatedStudent) {
      history.push(config.uiPath.students.list);
    }
  };

  return (
    <div className="content-wrap pt-8x">
      <div className="container">
        <div className="page-heading">
          <h2>Update a Student</h2>
        </div>
        <div className="content-7x">
          {isLoading ? (
            <Loader type="ThreeDots" />
          ) : (
            <UpdateStudentForm
              accommodations={accommodations}
              initialValues={initialValues}
              isUpdate={true}
              guardians={guardians}
              setIsAddSponsorOpen={setIsAddSponsorOpen}
              schools={schools}
              districts={districts}
              saveStudent={saveStudent}
              universities={universities}
              setSponsorUpdate={setSponsorUpdate}
              handleRemoveGuardian={handleRemoveGuardian}
              setIsSearchSponsorOpen={setIsSearchSponsorOpen}
            />
          )}
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddSponsorOpen}
        ariaHideApp={false}
      >
        <AddSponsor
          existingSponsors={guardians}
          handleSubmit={handleAddGuardianSubmit}
          closeModal={() => setIsAddSponsorOpen(false)}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isSearchSponsorOpen}
        ariaHideApp={false}
      >
        <SearchSponsor
          selectedSponsors={guardians}
          handleSelectSponsor={(sponsor: IUser) => {
            setGuardians((prevState) => [
              ...prevState,
              {
                userId: {
                  _id: sponsor._id || "",
                  email: sponsor.email || "",
                  lastName: sponsor.lastName || "",
                  firstName: sponsor.firstName || "",
                  phoneNumber: sponsor.phoneNumber || "",
                },
                livingScheduleWithParents: "",
                relationshipWithSponsor: "",
              },
            ]);
            setSelectSponsor({
              userId: sponsor,
              relationshipWithSponsor: "",
              livingScheduleWithParents: "",
            });
            setIsSearchSponsorOpen(false);
          }}
          handleUnselectSponsor={(sponsor: IUser) =>
            sponsor._id && handleRemoveGuardian(sponsor._id)
          }
          addSponsor={() => setIsAddSponsorOpen(true)}
          closeModal={() => setIsSearchSponsorOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={!!updateSponsor}
        className="modal-block"
        ariaHideApp={false}
      >
        {!!updateSponsor && (
          <UpdateSponsor
            sponsor={updateSponsor}
            existingSponsors={guardians}
            handleSubmit={handleUpdateGuardianSubmit}
            closeModal={() => setUpdateSponsor(null)}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!selectSponsor}
        className="modal-block"
        ariaHideApp={false}
      >
        {!!selectSponsor && (
          <UpdateSponsor
            sponsor={selectSponsor}
            existingSponsors={guardians}
            handleSubmit={handleUpdateGuardianSubmit}
            closeModal={() => {
              selectSponsor.userId._id &&
                handleRemoveGuardian(selectSponsor.userId._id);
              setSelectSponsor(null);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

interface State {
  mentors: { fetchMentors: IMentor[] };
  students: { fetchStudent: IUser; fetchStudentLoading: boolean };
  terms: { fetchTerms: ITerm[] };
}

const mapStateToProps = (state: State) => ({
  mentors: state.mentors.fetchMentors,
  student: state.students.fetchStudent,
  terms: state.terms.fetchTerms,
  isLoading: state.students.fetchStudentLoading,
});

const mapDispatchToProps = {
  updateStudent,
  fetchStudent,
  fetchMentors,
  addSponsor,
  fetchTerms,
  updateSponsor,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudent);
