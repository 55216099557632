import * as Yup from "yup";

const digitsOnly = (value: any) =>
  /^\d{1,5}$|(?=^.{1,5}$)^\d+\.\d{0,2}$/.test(value);
const percentOnly = (value: any) => /.*\%$/.test(value);

const AddGradeSchema = Yup.object().shape({
  term: Yup.string(),
  grade: Yup.string()
    .when("isGradeInPercent", {
      is: false,
      then: Yup.string().test(
        "Digits only",
        "Grade should be a digit and is required.",
        digitsOnly,
      ),
      otherwise: Yup.string().test(
        "Percentage only",
        "Enter grade in percentage. Eg. 20%",
        percentOnly,
      ),
    })
    .required("Add grade."),
});

export default AddGradeSchema;
