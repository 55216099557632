import React from "react";

type Props = {
  deleteGoal: any;
  closeModal: any;
};

const DeleteConfirm: React.FC<Props> = ({
  deleteGoal,
  closeModal,
}: Props): JSX.Element => {
  return (
    <div className="modal-wrap modal-wrap--sm confirm confirm--error">
      <div className="modal-wrap__body">
        <box-icon name="error-alt" />
        <h3>Do you want to delete this goal?</h3>
        <p>
          Deleting a goal will not delete the tasks. This action is not
          reversible
        </p>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--red mr-4x"
              type="button"
              onClick={deleteGoal}
            >
              Delete
            </button>
            <button
              className="btn btn-text txt-primary-color"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteConfirm;
