import Form from "../Form";
import { IEditQualitativeGradesProps } from "./EditQualitativeGrades.interface";

const EditQualitativeGrades: React.FC<IEditQualitativeGradesProps> = ({
  closeModal,
  initialValues,
  updateGradeType,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Change Grades</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={initialValues}
        isEdit={true}
        updateGradeType={updateGradeType}
        closeModal={closeModal}
      />
    </div>
  );
};

export default EditQualitativeGrades;
