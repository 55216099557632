import "boxicons";
import logo from "assets/images/boost_logo.svg";

const MentorReport = () => {
  return (
    <div className="page-content">
      <div className="container design-kit-container">
        <div className="modal-wrap">
          <div className="modal-wrap__header">
            <h3>View and export report</h3>
            <span className="link-item">
              <box-icon name="x"></box-icon>
            </span>
          </div>
          <div className="modal-wrap__body d-flex">
            <div className="modal-left report">
              <div className="report__head">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="row info">
                  <div className="col-6">
                    <span className="type">Student</span>
                    <h2>Samantha Doe</h2>
                    <ul className="school round round--horizontal">
                      <li>Grade 7</li>
                      <li>Chicago Public School</li>
                    </ul>
                    <div className="date">March 27 - April3, 2021</div>
                  </div>
                  <div className="col-6">
                    <span className="type">Mentor</span>
                    <h2>Matt Rawson</h2>
                  </div>
                </div>
              </div>
              <div className="report__content">
                <div className="block">
                  <div className="block__head">Notes</div>
                  <div className="block__content">
                    <div className="input-wrap mb-0x">
                      <textarea className="textarea no-border" name="message">
                        Add notes
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Grades</div>
                      <div className="block__content">
                        <ul className="report-list">
                          <li>
                            Adv Bio <span>93.57</span>
                          </li>
                          <li>
                            Geometry <span>93.57</span>
                          </li>
                          <li>
                            Spanish <span>93.57</span>
                          </li>
                          <li>
                            Health <span>93.57</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Missing Assignments</div>
                      <div className="block__content">
                        <ul className="report-list">
                          <li>
                            <span className="left">Adv Bio</span>{" "}
                            <span className="right">93.57</span>
                          </li>
                          <li>
                            <span className="left">Geometry</span>{" "}
                            <span className="right">N/A</span>
                          </li>
                          <li>
                            <span className="left">Spanish</span>{" "}
                            <span className="right">N/A</span>
                          </li>
                          <li>
                            <span className="left">Health</span>{" "}
                            <span className="right">N/A</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <h3>WEEKLY TO-DO LIST</h3>
                <div className="row">
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Monday</div>
                      <div className="block__content">
                        <ul className="report-list round">
                          <li>Add to your to-do list.</li>
                          <li>
                            Bio - Finish and Turn in Skeletal and Muscular
                            System Assignment and Lab.
                          </li>
                          <li>
                            Bio - Skeletal and Muscular and Integumentary System
                            Assignment
                          </li>
                          <li>Pg 439, 2-12 even</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Tuesday</div>
                      <div className="block__content">
                        <ul className="report-list round">
                          <li>Add to your to-do list.</li>
                          <li>
                            Bio - Finish and Turn in Skeletal and Muscular
                            System Assignment and Lab.
                          </li>
                          <li>
                            Bio - Skeletal and Muscular and Integumentary System
                            Assignment
                          </li>
                          <li>Pg 439, 2-12 even</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Wednesday</div>
                      <div className="block__content">
                        <ul className="report-list round">
                          <li>Add to your to-do list.</li>
                          <li>
                            Bio - Finish and Turn in Skeletal and Muscular
                            System Assignment and Lab.
                          </li>
                          <li>
                            Bio - Skeletal and Muscular and Integumentary System
                            Assignment
                          </li>
                          <li>Pg 439, 2-12 even</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Thursday</div>
                      <div className="block__content">
                        <ul className="report-list round">
                          <li>Add to your to-do list.</li>
                          <li>
                            Bio - Finish and Turn in Skeletal and Muscular
                            System Assignment and Lab.
                          </li>
                          <li>
                            Bio - Skeletal and Muscular and Integumentary System
                            Assignment
                          </li>
                          <li>Pg 439, 2-12 even</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Friday</div>
                      <div className="block__content">
                        <ul className="report-list round">
                          <li>Add to your to-do list.</li>
                          <li>
                            Bio - Finish and Turn in Skeletal and Muscular
                            System Assignment and Lab.
                          </li>
                          <li>
                            Bio - Skeletal and Muscular and Integumentary System
                            Assignment
                          </li>
                          <li>Pg 439, 2-12 even</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="block">
                      <div className="block__head">Weekend</div>
                      <div className="block__content">
                        <ul className="report-list round">
                          <li>Add to your to-do list.</li>
                          <li>
                            Bio - Finish and Turn in Skeletal and Muscular
                            System Assignment and Lab.
                          </li>
                          <li>
                            Bio - Skeletal and Muscular and Integumentary System
                            Assignment
                          </li>
                          <li>Pg 439, 2-12 even</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="block">
                  <div className="block__head">Non-negotiable Tasks</div>
                  <div className="block__content">
                    <ul className="report-list round">
                      <li>11/9 BIO - Take the Neuron Quiz</li>
                    </ul>
                  </div>
                </div>
                <div className="block">
                  <div className="block__head">On The Horizon</div>
                  <div className="block__content">
                    <ul className="report-list round">
                      <li>11/9 BIO - Take the Neuron Quiz</li>
                    </ul>
                  </div>
                </div>
                <h3>SEMESTER GOAL REMINDERS</h3>
                <div className="block">
                  <div className="block__head">Academic goals</div>
                  <div className="block__content">
                    <ul className="report-list round">
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                    </ul>
                  </div>
                </div>
                <div className="block">
                  <div className="block__head">Non-academic goals</div>
                  <div className="block__content">
                    <ul className="report-list round">
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                      <li>Earn a 3.65 or better unweighted GPA.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-right">
              <div className="report-form-section">
                <div className="input-wrap mb-0x">
                  <label className="input__label">Select a Plan</label>
                  <input
                    type="date"
                    className="input"
                    placeholder="Mar 27 - Aug 3 2021"
                    name="planDate"
                  />
                </div>
              </div>
              <div className="report-form-section">
                <div className="report-form-head">
                  <h3 className="mb-2x">Print this report</h3>
                </div>
                <button
                  className="btn btn--primary btn__icon btn__icon--right btn--block mr-2x"
                  type="button"
                >
                  Print Report
                  <box-icon name="printer"></box-icon>
                </button>
                <span className="mt-2x pl-8x pr-8x text-light-sm text-center d-block">
                  The report will open in a new window for you to print it.
                </span>
              </div>
              <div className="report-form-section no-border">
                <div className="report-form-head">
                  <h3 className="mb-4x">Send email to</h3>
                  <span className="link-primary-default">Select all</span>
                </div>
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check1"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check1" className="box-links__nodes">
                    Samantha Doe (Student)
                  </label>
                </div>
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check2"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check2" className="box-links__nodes">
                    Matt Rawson (Mentor)
                  </label>
                </div>
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check3"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check3" className="box-links__nodes">
                    John Doe (Father)
                  </label>
                </div>
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check4"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check4" className="box-links__nodes">
                    Mark Doe (Uncle)
                  </label>
                </div>
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check5"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check5" className="box-links__nodes">
                    Jill Doe (Guardian)
                  </label>
                </div>
                <div className="input-wrap">
                  <label className="input__label">Subject</label>
                  <input
                    type="text"
                    placeholder="Samantha Doe: Report for week of Mar 27 - Aug 3, 2021"
                    className="input"
                  />
                </div>

                <div className="input-wrap">
                  <label className="input__label">Email message</label>
                  <textarea className="textarea" name="message">
                    This is to inform you about the progress report of your
                    daughter.
                  </textarea>
                </div>
              </div>
              <div className="report-form-section no-border">
                <div className="row">
                  <div className="col-12 d-flex">
                    <button className="btn btn--primary mr-4x" type="button">
                      Send Email
                    </button>
                    <button className="btn" type="button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorReport;
