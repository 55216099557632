import React from "react";

interface IInCompleteConfirm {
  inCompleteTaskHandler: () => void;
  closeModal: () => void;
  message: { header: string; body: string };
}

const InCompleteConfirm: React.FC<IInCompleteConfirm> = ({
  inCompleteTaskHandler,
  closeModal,
  message,
}: IInCompleteConfirm): JSX.Element => {
  return (
    <div className="modal-wrap modal-wrap--sm confirm confirm--error">
      <div className="modal-wrap__body">
        <box-icon type="solid" name="error-circle" />
        <h3>{message.header}</h3>
        <p>{message.body}</p>
      </div>
      <div className="modal-wrap__footer">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--red mr-4x"
              type="button"
              onClick={inCompleteTaskHandler}
            >
              Mark as incomplete
            </button>
            <button
              className="btn btn-text txt-primary-color"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InCompleteConfirm;
