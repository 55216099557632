import React from "react";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";

interface ISponsorProps {
  sponsor: IUser;
  selected: boolean;
  handleSelectSponsor: (sponsor: IUser) => void;
  handleUnselectSponsor: (sponsor: IUser) => void;
}

const Sponsor: React.FC<ISponsorProps> = ({
  sponsor,
  selected,
  handleSelectSponsor,
  handleUnselectSponsor,
}) => {
  return (
    <div className="card card-addinfo border-bottom">
      <div className="card-content">
        <div className="card-text">
          <h3>{getFullName(sponsor)}</h3>
          <span className="card-address">{sponsor.email}</span>
        </div>
      </div>
      <div className="card-action">
        <span className="link-item">
          {selected ? (
            <box-icon
              onClick={() => handleUnselectSponsor(sponsor)}
              name="trash-alt"
              type="solid"
            />
          ) : (
            <box-icon
              onClick={() => handleSelectSponsor(sponsor)}
              name="plus-circle"
              type="solid"
              color="#1D70B8"
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default Sponsor;
