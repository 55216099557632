import SponsorForm from "../add/SponsorForm";
import { ISponsor, IUser } from "../../../commons/types/users";

interface Props {
  sponsor: ISponsor;
  closeModal: () => void;
  existingSponsors?: ISponsor[];
  handleSubmit: (data: IUser) => void;
}

const UpdateSponsor: React.FC<Props> = ({
  sponsor,
  closeModal,
  handleSubmit,
  existingSponsors,
}): JSX.Element => {
  const initialValues: any = {
    id: sponsor.userId._id,
    ...sponsor.userId,
    ...sponsor,
  };
  delete initialValues.userId;

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Update the Parent/Guardian</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <SponsorForm
        isUpdate={true}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        existingSponsors={existingSponsors}
      />
    </div>
  );
};

export default UpdateSponsor;
