const envConfig = {
   env: process.env.REACT_APP_ENV || "dev",
  sentryUrl: process.env.REACT_APP_SENTRY_URL || "",
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  auth0Client: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  auth0Audience: process.env.REACT_APP_AUDIENCE || "",
  serverUrl: process.env.REACT_APP_SERVER_URL || "",
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY || "",
  pusherCluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
  reporterUserName: process.env.REACT_APP_TEST_USER_NAME || "",
  reporterUserEmail: process.env.REACT_APP_TEST_USER_EMAIL || "",
  fullStoryOrgId: process.env.REACT_APP_FULLSTORY_ORG_ID || "",
};

export default envConfig;
