import { getDataFromLocalStorage } from "services/localStorage";
import { FetchAvailabilityActionType } from "store/actions/types/availability";

const profile = getDataFromLocalStorage("profile");

const INITIAL_STATE = {
  availability: profile?.availability,
  isAvailabilityPending: false,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchAvailabilityActionType.FETCH_AVAILABILITY_PENDING:
      return { ...state, isAvailabilityPending: true };
    case FetchAvailabilityActionType.FETCH_AVAILABILITY_FULFILLED:
      return {
        ...state,
        availability: action.data,
        isAvailabilityPending: false,
      };
    case FetchAvailabilityActionType.FETCH_AVAILABILITY_REJECTED:
      return { ...state, isAvailabilityPending: false };
    default:
      return state;
  }
};

export default reducer;
