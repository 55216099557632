import { ResponseRevision } from "commons/types/responseRevision";

export enum AddResponseRevisionActionType {
  ADD_RESPONSE_REVISION_PENDING = "ADD_RESPONSE_REVISION_PENDING",
  ADD_RESPONSE_REVISION_REJECTED = "ADD_RESPONSE_REVISION_REJECTED",
  ADD_RESPONSE_REVISION_FULFILLED = "ADD_RESPONSE_REVISION_FULFILLED",
}

export interface AddResponseRevisionAction {
  type: AddResponseRevisionActionType;
  data: any;
}

export enum FetchResponseRevisionsActionType {
  FETCH_RESPONSE_REVISIONS_PENDING = "FETCH_RESPONSE_REVISIONS_PENDING",
  FETCH_RESPONSE_REVISIONS_REJECTED = "FETCH_RESPONSE_REVISIONS_REJECTED",
  FETCH_RESPONSE_REVISIONS_FULFILLED = "FETCH_RESPONSE_REVISIONS_FULFILLED",
}

export interface FetchResponseRevisionsAction {
  type: FetchResponseRevisionsActionType;
  data: ResponseRevision;
}
