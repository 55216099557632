import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as sponsorsService from "services/sponsors";
import {
  AddSponsorActionType,
  FetchSponsorsActionType,
  UpdateSponsorActionType,
} from "store/actions/types/sponsors";
import { Params } from "commons/types/urls";

export const addSponsor = (payload: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddSponsorActionType.ADD_SPONSOR_PENDING,
  });
  try {
    const data = await sponsorsService.add(payload);
    dispatch({
      type: AddSponsorActionType.ADD_SPONSOR_FULFILLED,
      data: data,
    });
    success("Parent/guardian saved successfully");

    return data;
  } catch (ex: any) {
    dispatch({
      type: AddSponsorActionType.ADD_SPONSOR_REJECTED,
      data: ex,
    });

    error("Failed to save parent/guardian");
  }
};

export const updateSponsor =
  (id: string, payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateSponsorActionType.UPDATE_SPONSOR_PENDING,
    });
    try {
      const sponsor = await sponsorsService.update(id, payload);
      dispatch({
        type: UpdateSponsorActionType.UPDATE_SPONSOR_FULFILLED,
        data: sponsor,
      });
      success("Saved parent/guardian successfully");

      return sponsor;
    } catch (ex) {
      dispatch({
        type: UpdateSponsorActionType.UPDATE_SPONSOR_REJECTED,
        data: ex,
      });

      error("Failed to update parent/guardian");
    }
  };

export const fetchSponsors =
  (params: Params = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: FetchSponsorsActionType.FETCH_SPONSORS_PENDING,
    });
    try {
      const sponsors = await sponsorsService.fetchAll(params);
      dispatch({
        type: FetchSponsorsActionType.FETCH_SPONSORS_FULFILLED,
        data: sponsors,
      });

      return sponsors;
    } catch (ex) {
      dispatch({
        type: FetchSponsorsActionType.FETCH_SPONSORS_REJECTED,
        data: ex,
      });

      error("Failed to fetch parent/guardian");
    }
  };
