import {
  AddGoalActionType,
  FetchGoalActionType,
  FetchGoalsActionType,
  UpdateGoalActionType,
} from "store/actions/types/goals";

const INITIAL_STATE = {
  addGoal: {},
  addGoalLoading: false,
  addGoalError: null,
  fetchGoal: {},
  fetchGoalLoading: false,
  fetchGoalError: null,
  updateGoal: {},
  updateGoalLoading: false,
  updateGoalError: null,
  fetchGoals: [],
  fetchGoalsLoading: false,
  fetchGoalsError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddGoalActionType.ADD_GOAL_PENDING:
      return {
        ...state,
        addGoalLoading: true,
        addGoalError: null,
      };
    case AddGoalActionType.ADD_GOAL_FULFILLED:
      return {
        ...state,
        addGoal: action.data,
        addGoalLoading: false,
        addGoalError: null,
      };
    case AddGoalActionType.ADD_GOAL_REJECTED:
      return {
        ...state,
        addGoalLoading: false,
        addGoalError: action.data,
      };

    case FetchGoalActionType.FETCH_GOAL_PENDING:
      return {
        ...state,
        fetchGoalLoading: true,
        fetchGoalError: null,
      };
    case FetchGoalActionType.FETCH_GOAL_FULFILLED:
      return {
        ...state,
        fetchGoal: action.data,
        fetchGoalLoading: false,
        fetchGoalError: null,
      };
    case FetchGoalActionType.FETCH_GOAL_REJECTED:
      return {
        ...state,
        fetchGoalLoading: false,
        fetchGoalError: action.data,
      };

    case FetchGoalsActionType.FETCH_GOALS_PENDING:
      return {
        ...state,
        fetchGoalsLoading: true,
        fetchGoalsError: null,
      };
    case FetchGoalsActionType.FETCH_GOALS_FULFILLED:
      return {
        ...state,
        fetchGoals: action.data,
        fetchGoalsLoading: false,
        fetchGoalsError: null,
      };
    case FetchGoalsActionType.FETCH_GOALS_REJECTED:
      return {
        ...state,
        fetchGoalsLoading: false,
        fetchGoalsError: action.data,
      };

    case UpdateGoalActionType.UPDATE_GOAL_PENDING:
      return {
        ...state,
        updateGoalLoading: true,
        updateGoalError: null,
      };
    case UpdateGoalActionType.UPDATE_GOAL_FULFILLED:
      return {
        ...state,
        updateGoal: action.data,
        updateGoalLoading: false,
        updateGoalError: null,
      };
    case UpdateGoalActionType.UPDATE_GOAL_REJECTED:
      return {
        ...state,
        updateGoalLoading: false,
        updateGoalError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
