import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { ACADEMIC_YEAR_VALIDATION_REGEX } from "../../constants/regex";

const AcademicYearSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  year: Yup.string()
    .trim()
    .matches(
      ACADEMIC_YEAR_VALIDATION_REGEX,
      "Please enter a valid year format. Example: 2021 or 2021/2022",
    )
    .required("Please enter academic year"),
});

export default AcademicYearSchema;
