import React from "react";

interface IResponseCompleted {
  title: string;
  description: string;
  image: string;
}

const ResponseCompleted = (props: IResponseCompleted) => {
  const { title, description, image } = props;

  return (
    <div className="content-wrap">
      <div className="container">
        <div className="content-7x">
          <div className="content-panel">
            <div className="page-heading mt-4x">
              <div className="page-heading__left">
                <h2>Student Assessment Survey</h2>
              </div>
            </div>
            <div className="empty-page bg-white">
              <img src={image} alt={"done"} />
              <h2 className="txt-darkgrey-color mt-4x">{title}</h2>
              <div className="empty-page__content__body">
                <p className="txt-darkgrey-color">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseCompleted;
