import pinterpolate from "pinterpolate";

import config from "configs";
import { Role } from "constants/roles";
import { IUser } from "commons/types/users";
import { Params } from "commons/types/urls";
import { http, stringify } from "utils/http";

export const add = async (payload: IUser) => {
  const url = config.endpoints.users.all;
  const { data } = await http.post(url, payload);

  return data;
};

export const update = async (id: string, payload: IUser) => {
  const url = pinterpolate(config.endpoints.users.one, { id: id });
  const { data } = await http.patch(url, payload);

  return data;
};

export const fetchAll = async (params: Params) => {
  const url = stringify(config.endpoints.users.all, {
    role: Role.SPONSOR,
    ...params,
  });

  const { data } = await http.get(url);

  return data;
};
