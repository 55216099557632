import pinterpolate from "pinterpolate";

import config from "configs";
import { http, stringify } from "utils/http";
import { IGoal, IGoalParams } from "commons/types/goals";
import { UowDeleteEditType } from "../constants";

export const add = async (payload: IGoal) => {
  const url = config.endpoints.uow.all;
  delete payload.planningPeriod;
  const { data } = await http.post(url, {
    ...payload,
    requiredDeadline: payload.requiredDeadline
      ? new Date(payload.requiredDeadline).toISOString()
      : null,
  });

  return data;
};

export const update = async (id: string, payload: Partial<IGoal>) => {
  const url = pinterpolate(config.endpoints.uow.one, { id: id });
  delete payload.planningPeriod;

  if (payload.requiredDeadline) {
    payload.requiredDeadline = new Date(payload.requiredDeadline).toISOString();
  }

  const { data } = await http.patch(url, payload);

  return data;
};

export const fetch = async (id: string) => {
  const url = pinterpolate(config.endpoints.uow.one, { id: id });
  const { data } = await http.get(url);

  return data;
};

export const fetchAll = async (params: IGoalParams) => {
  const url = stringify(config.endpoints.uow.all, {
    ...params,
  });

  const { data } = await http.get(url);

  return data;
};

export const deleteUow = async (
  id: string,
  type: UowDeleteEditType = UowDeleteEditType.SELF,
) => {
  const url = pinterpolate(config.endpoints.uow.one, { id: id });
  const { data } = await http.delete(url, { data: { deleteOrEditType: type } });
  return data;
};

export const updateMany = async (payload: any) => {
  const { data } = await http.patch(config.endpoints.uow.all, payload);

  return data;
};

export const deleteMany = async (ids: string[]) => {
  const { data } = await http.delete(config.endpoints.uow.all, {
    data: { ids: ids },
  });

  return data;
};

export const markAsIncomplete = async (id: string) => {
  const { data } = await http.patch(config.endpoints.uow.markAsIncomplete, {
    id,
    undoCompleteAt: new Date(),
  });

  return data;
};
