import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";

import AddCenter from "./add/add-center";
import CentersList from "./list/CentersList";
import { RootState } from "../../store/reducers";
import Loader from "../../commons/components/Loader";
import schoolImage from "../../assets/images/school.svg";
import EmptyState from "../../commons/components/EmptyState";
import SettingsPanel from "pages/layouts/intake/settingsPanel";
import { ICenter, ICentersParams } from "commons/types/centers";
import { addCenter, fetchCenters } from "../../store/actions/data/centers";

interface ICenterProps {
  centers: ICenter[];
  fetchCentersLoading: boolean;
  addCenter: (center: ICenter) => Promise<void>;
  fetchCenters: (params: ICentersParams) => Promise<ICenter[]>;
}

const Centers = (props: ICenterProps) => {
  const { centers, addCenter, fetchCenters, fetchCentersLoading } = props;

  React.useEffect(() => {
    fetchCenters({});
  }, [fetchCenters]);

  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);

  const handleAddCenter = async (center: ICenter) => {
    await addCenter(center);
    setIsAddModalOpen(false);
    fetchCenters({});
  };

  React.useEffect(() => {
    fetchCenters({});
  }, [fetchCenters]);

  if (fetchCentersLoading) {
    return <Loader type="ThreeDots" />;
  }

  const EmptyCenterList = () => {
    return (
      <div className="content-panel">
        <h1>Centers</h1>
        <EmptyState
          image={schoolImage}
          title="You currently do not have any Centers."
          alt="centers empty"
        >
          <button
            className="btn btn--primary"
            type="button"
            onClick={() => setIsAddModalOpen(true)}
          >
            Add Center
          </button>
        </EmptyState>
      </div>
    );
  };

  return (
    <div className="content-wrap">
      <div className="container-fluid">
        <div className="main-content">
          <SettingsPanel activeTab="Centers" />
          {centers.length <= 0 ? (
            <EmptyCenterList />
          ) : (
            <CentersList
              centers={centers}
              onAddButtonClicked={() => setIsAddModalOpen(true)}
            />
          )}
        </div>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsAddModalOpen(false)}
      >
        <AddCenter
          saveCenter={handleAddCenter}
          closeModal={() => setIsAddModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  centers: state.centers.fetchCenters,
  fetchCentersLoading: state.centers.fetchCentersLoading,
});

const mapDispatchToProps = {
  addCenter,
  fetchCenters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Centers);
