import { connect } from "react-redux";

import AddGoalForm from "./AddGoalForm";
import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import { IGoal, IGoalParams } from "commons/types/goals";
import { addGoal, fetchGoals } from "store/actions/data/goals";
import { isStudentLoggedIn } from "utils/window";

type AddGoalProps = {
  closeModal: () => void;
  addGoal: (goal: IGoal) => Promise<IGoal>;
  fetchGoals: (params: IGoalParams) => Promise<IGoal[]>;
  student: IUser;
};

const AddGoal: React.FC<AddGoalProps> = ({
  closeModal,
  student,
  addGoal,
  fetchGoals,
}) => {
  const saveGoal = async (goal: IGoal) => {
    const newGoal = await addGoal(goal);

    if (newGoal) {
      fetchGoals({ assignee: goal.assignee });
      closeModal();
    }
  };

  const initialValues = {
    name: "",
    requiredDeadline: "",
    workData: {
      workType: "",
      status: "Created",
      uowType: "Goal",
      metrics: {
        dataType: "String",
        metricsType: "",
        baselineMetric: "",
        achievedMetric: "",
      },
    },
    isCompleted: false,
    assignee: student._id || "",
    uowType: "Goal",
  };

  const fullStudentName = getFullName(student);

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>
          Add a Goal
          {!isStudentLoggedIn() &&
            (fullStudentName ? ` for ${fullStudentName}` : null)}
        </h3>
        <span onClick={closeModal} className="link-item">
          <box-icon name="x" />
        </span>
      </div>
      <AddGoalForm
        initialValues={initialValues}
        saveGoal={saveGoal}
        closeModal={closeModal}
      />
    </div>
  );
};

interface State {}

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  addGoal,
  fetchGoals,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGoal);
