import * as React from "react";
import Modal from "react-modal";

interface Step {
  step: number;
  component: (props: any) => JSX.Element;
}

interface IMultiStepFormProp {
  currentStep?: number;
  steps: Array<Step>;
  onClose: () => void;
  goToHandler?: (step: number) => void;
  data: any;
  [key: string]: any;
}

const MultiStepForm: React.FC<IMultiStepFormProp> = ({
  currentStep = 1,
  steps,
  onClose,
  goToHandler,
  ...props
}) => {
  const getCurrentComponent = (props: any) => {
    const stepItem = steps.find((step) => step.step === currentStep);
    return stepItem?.component(props) || <div>Step is not defined</div>;
  };

  return (
    <Modal className="modal-block" isOpen={true}>
      {getCurrentComponent({
        onClose,
        goToHandler,
        ...props,
      })}
    </Modal>
  );
};

export default MultiStepForm;
