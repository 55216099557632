import { ISchoolDistrict } from "commons/types/schoolDistrict";

export enum AddSchoolDistrictActionType {
  ADD_School_District_PENDING = "ADD_School_District_PENDING",
  ADD_School_District_REJECTED = "ADD_School_District_REJECTED",
  ADD_School_District_FULFILLED = "ADD_School_District_FULFILLED",
}
export interface AddSchoolDistrictAction {
  type: AddSchoolDistrictActionType;
  data: ISchoolDistrict;
}

export enum FetchSchoolDistrictsActionType {
  FETCH_SCHOOL_DISTRICTS_PENDING = "FETCH_SCHOOL_DISTRICTS_PENDING",
  FETCH_SCHOOL_DISTRICTS_REJECTED = "FETCH_SCHOOL_DISTRICTS_REJECTED",
  FETCH_SCHOOL_DISTRICTS_FULFILLED = "FETCH_SCHOOL_DISTRICTS_FULFILLED",
}
export interface FetchSchoolDistrictsAction {
  type: FetchSchoolDistrictsActionType;
  data: ISchoolDistrict[];
}

export enum FetchSchoolDistrictActionType {
  FETCH_SCHOOL_DISTRICT_PENDING = "FETCH_SCHOOL_DISTRICT_PENDING",
  FETCH_SCHOOL_DISTRICT_REJECTED = "FETCH_SCHOOL_DISTRICT_REJECTED",
  FETCH_SCHOOL_DISTRICT_FULFILLED = "FETCH_SCHOOL_DISTRICT_FULFILLED",
}
export interface FetchSchoolDistrictAction {
  type: FetchSchoolDistrictActionType;
  data: ISchoolDistrict;
}

export enum UpdateSchoolDistrictActionType {
  UPDATE_SCHOOL_DISTRICT_PENDING = "UPDATE_SCHOOL_DISTRICT_PENDING",
  UPDATE_SCHOOL_DISTRICT_REJECTED = "UPDATE_SCHOOL_DISTRICT_REJECTED",
  UPDATE_SCHOOL_DISTRICT_FULFILLED = "UPDATE_SCHOOL_DISTRICT_FULFILLED",
}
export interface UpdateSchoolDistrictAction {
  type: UpdateSchoolDistrictActionType;
  data: ISchoolDistrict;
}

export enum DeleteSchoolDistrictActionType {
  DELETE_SCHOOL_DISTRICT_PENDING = "DELETE_SCHOOL_DISTRICT_PENDING",
  DELETE_SCHOOL_DISTRICT_FULFILLED = "DELETE_SCHOOL_DISTRICT_FULFILLED",
  DELETE_SCHOOL_DISTRICT_REJECTED = "DELETE_SCHOOL_DISTRICT_REJECTED",
}

export interface DeleteSchoolDistrictAction {
  type: DeleteSchoolDistrictActionType;
  data: ISchoolDistrict;
}
