import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as schoolService from "services/school";
import {
  AddSchoolActionType,
  DeleteSchoolActionType,
  FetchSchoolsActionType,
  UpdateSchoolActionType,
} from "../types/school";
import { ISchoolCreate, ISchoolParams } from "commons/types/school";

export const addSchool =
  (payload: ISchoolCreate) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddSchoolActionType.ADD_SCHOOL_PENDING,
    });
    try {
      const { data } = await schoolService.add(payload);
      dispatch({
        type: AddSchoolActionType.ADD_SCHOOL_FULFILLED,
        data: data,
      });
      success("School details saved");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddSchoolActionType.ADD_SCHOOL_REJECTED,
        data: err,
      });
      error("Saving school detail failed");
    }
  };

export const fetchSchools =
  (params: ISchoolParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchSchoolsActionType.FETCH_SCHOOLS_PENDING,
    });
    try {
      const { data } = await schoolService.fetchAll(params);
      dispatch({
        type: FetchSchoolsActionType.FETCH_SCHOOLS_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchSchoolsActionType.FETCH_SCHOOLS_REJECTED,
        data: err,
      });

      error("Schools failed to load");
    }
  };

export const updateSchool =
  (id: string, payload: ISchoolCreate) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateSchoolActionType.UPDATE_SCHOOL_PENDING,
    });
    try {
      const { data } = await schoolService.update(id, payload);
      dispatch({
        type: UpdateSchoolActionType.UPDATE_SCHOOL_FULFILLED,
        data: data,
      });
      success("School information updated successfully");
    } catch (err: any) {
      dispatch({
        type: UpdateSchoolActionType.UPDATE_SCHOOL_REJECTED,
        data: err,
      });

      error("School information failed to update");
    }
  };

export const deleteSchool = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DeleteSchoolActionType.DELETE_SCHOOL_PENDING,
  });
  try {
    const { data } = await schoolService.deleteOne(id);
    dispatch({
      type: DeleteSchoolActionType.DELETE_SCHOOL_FULFILLED,
      data: data,
    });
    success("School deleted successfully");

    return data;
  } catch (err: any) {
    dispatch({
      type: DeleteSchoolActionType.DELETE_SCHOOL_REJECTED,
      data: err,
    });

    error("Failed to delete school");
  }
};
