import React from "react";
import Form from "./Form";
import { ITermEditParam } from "./editparam.interface";

const Term = (props: ITermEditParam) => {
  return (
    <>
      <div className="modal-wrap">
        <div className="modal-wrap__header">
          <h3>Edit Term</h3>
          <span className="link-item" onClick={props.closeModal}>
            <box-icon name="x"></box-icon>
          </span>
        </div>
        <Form {...props} />
      </div>
    </>
  );
};
export default Term;
