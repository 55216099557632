import {
  AddStudentActionType,
  FetchStudentActionType,
  FetchStudentsActionType,
  UpdateStudentActionType,
  StudentFilterActionType,
  FetchPagedStudentsActionType,
} from "store/actions/types/students";

const INITIAL_STATE = {
  addStudent: {},
  addStudentLoading: false,
  addStudentError: null,
  fetchStudent: {},
  fetchStudentLoading: false,
  fetchStudentError: null,
  updateStudent: {},
  updateStudentLoading: false,
  updateStudentError: null,
  fetchStudents: [],
  fetchStudentsLoading: false,
  fetchStudentsError: null,
  fetchPagedStudents: {
    data: [],
    page: {
      totalItem: 0,
      totalPage: 0,
      currentPage: 0,
      itemCountInPage: 0,
      hasPrevious: false,
      hasNext: false,
    },
  },
  fetchPagedStudentsLoading: false,
  fetchPagedStudentsError: null,
  studentFilters: {
    term: undefined,
    termStatus: undefined,
    userStatus: undefined,
    learningCenter: undefined,
  },
  studentsListOffset: 1,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddStudentActionType.ADD_STUDENT_PENDING:
      return {
        ...state,
        addStudentLoading: true,
        addStudentError: null,
      };
    case AddStudentActionType.ADD_STUDENT_FULFILLED:
      return {
        ...state,
        addStudent: action.data,
        addStudentLoading: false,
        addStudentError: null,
      };
    case AddStudentActionType.ADD_STUDENT_REJECTED:
      return {
        ...state,
        addStudentLoading: false,
        addStudentError: action.data,
      };

    case FetchStudentActionType.FETCH_STUDENT_PENDING:
      return {
        ...state,
        fetchStudentLoading: true,
        fetchStudentError: null,
      };
    case FetchStudentActionType.FETCH_STUDENT_FULFILLED:
      return {
        ...state,
        fetchStudent: action.data,
        fetchStudentLoading: false,
        fetchStudentError: null,
      };
    case FetchStudentActionType.FETCH_STUDENT_REJECTED:
      return {
        ...state,
        fetchStudentLoading: false,
        fetchStudentError: action.data,
      };

    case FetchStudentsActionType.FETCH_STUDENTS_PENDING:
      return {
        ...state,
        fetchStudentsLoading: true,
        fetchStudentsError: null,
      };
    case FetchStudentsActionType.FETCH_STUDENTS_FULFILLED:
      return {
        ...state,
        fetchStudents: action.data,
        fetchStudentsLoading: false,
        fetchStudentsError: null,
      };
    case FetchStudentsActionType.FETCH_STUDENTS_REJECTED:
      return {
        ...state,
        fetchStudentsLoading: false,
        fetchStudentsError: action.data,
      };

    case FetchPagedStudentsActionType.FETCH_PAGED_STUDENTS_PENDING:
      return {
        ...state,
        fetchPagedStudentsLoading: true,
        fetchPagedStudentsError: null,
      };
    case FetchPagedStudentsActionType.FETCH_PAGED_STUDENTS_FULFILLED:
      return {
        ...state,
        fetchPagedStudents: action.data,
        fetchPagedStudentsLoading: false,
        fetchPagedStudentsError: null,
      };
    case FetchPagedStudentsActionType.FETCH_PAGED_STUDENTS_REJECTED:
      return {
        ...state,
        fetchPagedStudentsLoading: false,
        fetchPagedStudentsError: action.data,
      };

    case UpdateStudentActionType.UPDATE_STUDENT_PENDING:
      return {
        ...state,
        updateStudentLoading: true,
        updateStudentError: null,
      };
    case UpdateStudentActionType.UPDATE_STUDENT_FULFILLED:
      return {
        ...state,
        updateStudent: action.data,
        updateStudentLoading: false,
        updateStudentError: null,
      };
    case UpdateStudentActionType.UPDATE_STUDENT_REJECTED:
      return {
        ...state,
        updateStudentLoading: false,
        updateStudentError: action.data,
      };
    case StudentFilterActionType.SET_FILTER:
      return {
        ...state,
        studentFilters: action.data,
      };
    case StudentFilterActionType.RESET_FILTER:
      return {
        ...state,
        studentFilters: {
          term: undefined,
          termStatus: undefined,
          userStatus: undefined,
          learningCenter: undefined,
        },
      };

    case StudentFilterActionType.SET_PAGINATION:
      return {
        ...state,
        studentsListOffset: action.data,
      };

    case StudentFilterActionType.RESET_PAGINATION:
      return {
        ...state,
        studentsListOffset: 1,
      };

    case StudentFilterActionType.SET_TERM:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          term: action.data,
        },
      };
    case StudentFilterActionType.RESET_TERM:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          term: undefined,
        },
      };
    case StudentFilterActionType.SET_TERM_STATUS:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          termStatus: action.data,
        },
      };
    case StudentFilterActionType.RESET_TERM_STATUS:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          termStatus: undefined,
        },
      };
    case StudentFilterActionType.SET_USER_STATUS:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          userStatus: action.data,
        },
      };
    case StudentFilterActionType.RESET_USER_STATUS:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          userStatus: undefined,
        },
      };
    case StudentFilterActionType.SET_LEARNING_CENTER:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          learningCenter: action.data,
        },
      };
    case StudentFilterActionType.RESET_LEARNING_CENTER:
      return {
        ...state,
        studentFilters: {
          ...state.studentFilters,
          learningCenter: undefined,
        },
      };
    default:
      return state;
  }
};

export default reducer;
