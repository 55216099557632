import { IDistrict, ISchools } from "commons/types/institutions";

export enum FetchDistrictsActionType {
  FETCH_DISTRICT_PENDING = "FETCH_DISTRICT_PENDING ",
  FETCH_DISTRICT_FULFILLED = "FETCH_DISTRICT_FULFILLED",
  FETCH_DISTRICT_REJECTED = "FETCH_DISTRICT_REJECTED ",
}

export interface FetchDistrictAction {
  type: FetchDistrictAction;
  data: IDistrict;
}

export enum FetchSchoolsActionType {
  FETCH_SCHOOLS_PENDING = "FETCH_SCHOOLS_PENDING",
  FETCH_SCHOOLS_FULFILLED = "FETCH_SCHOOLS_FULFILLED",
  FETCH_SCHOOLS_REJECTED = "FETCH_SCHOOLS_REJECTED ",
}

export interface FetchSchoolsAction {
  type: FetchSchoolsActionType;
  data: ISchools;
}
