import { useState, useEffect, useCallback } from "react";
import Twilio, {
  Participant,
  Room,
  LocalVideoTrack,
  LocalTrackOptions,
} from "twilio-video";

import { error } from "utils/toast";

import { SCREEN_SHARE } from "constants/communication";

import ParticipantView from "./ParticipantView";

interface IRoomProps {
  room: Room;
  isAudio: boolean;
  logoutHandler: () => void;
}

const VideoRoom: React.FC<IRoomProps> = ({
  room,
  isAudio,
  logoutHandler,
}): JSX.Element => {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isScreenShareEnabled, setIsScreenShareEnabled] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(isAudio ? false : true);

  const [screenTrack, setScreenTrack] = useState<LocalVideoTrack | null>(null);

  const toggleVideo = () => {
    setIsVideoEnabled(!isVideoEnabled);
  };

  const toggleAudio = () => {
    setIsAudioEnabled(!isAudioEnabled);
  };

  const shareScreen = () => {
    setIsScreenShareEnabled(!isScreenShareEnabled);
  };

  useEffect(() => {
    room.localParticipant.audioTracks.forEach((audioTrack) => {
      if (isAudioEnabled) {
        audioTrack.track.enable();
      } else {
        audioTrack.track.disable();
      }
    });
  }, [isAudioEnabled, room.localParticipant.audioTracks]);

  useEffect(() => {
    room.localParticipant.videoTracks.forEach((videoTrack) => {
      if (videoTrack.trackName === SCREEN_SHARE) {
        return;
      }

      if (isVideoEnabled) {
        videoTrack.track.restart();
        videoTrack.track.enable();
      } else {
        videoTrack.track.disable();
        videoTrack.track.stop();
      }
    });
  }, [isVideoEnabled, room.localParticipant.videoTracks]);

  useEffect(() => {
    const participantConnected = (participant: Participant) => {
      setParticipants((prevParticipants: Participant[]) => [
        ...prevParticipants,
        participant,
      ]);
    };

    const participantDisconnected = (disconnectedParticipant: Participant) => {
      setParticipants((prevParticipants: Participant[]) =>
        prevParticipants.filter(
          (participant) => participant !== disconnectedParticipant,
        ),
      );
      if (participants.length < 2) {
        logoutHandler();
      }
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);

    room.participants.forEach(participantConnected);

    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
    // TODO: Fix eslint
    // eslint-disable-next-line
  }, [room]);

  const stopScreenShareHandler = useCallback(async () => {
    if (!screenTrack) return;

    screenTrack.disable();
    screenTrack.stop();

    await room.localParticipant.unpublishTrack(screenTrack);

    setIsScreenShareEnabled(false);

    setScreenTrack(null);
  }, [screenTrack, room.localParticipant]);

  const shareScreenHandler = useCallback(async () => {
    if (!screenTrack) {
      try {
        // @ts-ignore
        const stream = await navigator.mediaDevices.getDisplayMedia();

        const userScreenTrackOptions: LocalTrackOptions = {
          name: SCREEN_SHARE,
          logLevel: "error", // mandatory key in LocalTrackOptions
        };
        const userScreenTrack = new Twilio.LocalVideoTrack(
          stream.getTracks()[0],
          userScreenTrackOptions,
        );

        await room?.localParticipant.publishTrack(userScreenTrack);

        setScreenTrack(userScreenTrack);

        userScreenTrack.mediaStreamTrack.onended = () => {
          setIsScreenShareEnabled(false);
        };
      } catch (e: any) {
        setIsScreenShareEnabled(false);

        if (e.name !== "NotAllowedError") {
          error("Could not share the screen");
        }
      }
    }
  }, [room, screenTrack]);

  useEffect(() => {
    if (isScreenShareEnabled) {
      shareScreenHandler();
    } else {
      stopScreenShareHandler();
    }
  }, [isScreenShareEnabled, shareScreenHandler, stopScreenShareHandler]);

  const remoteParticipantsElement = participants.map(
    (participant: Participant) => {
      return (
        <ParticipantView
          isAudio={isAudio}
          key={participant.sid}
          participant={participant}
        />
      );
    },
  );
  return (
    <>
      <div className="video-call__body">
        <ParticipantView
          isLocal={true}
          isAudio={isAudio}
          isAudioEnabled={isAudioEnabled}
          isVideoEnabled={isVideoEnabled}
          key={room.localParticipant.sid}
          participant={room.localParticipant}
        />
        {remoteParticipantsElement}
      </div>
      <div className="video-call__footer">
        <div className="button-group">
          <button
            onClick={toggleAudio}
            className={
              isAudioEnabled
                ? "btn btn__with-icon btn--outlined-grey"
                : "btn btn__with-icon btn--grey"
            }
          >
            <box-icon
              name={isAudioEnabled ? "microphone" : "microphone-off"}
              animation="tada-hover"
            ></box-icon>
          </button>
          <button
            onClick={toggleVideo}
            className={
              isVideoEnabled
                ? "btn btn__with-icon btn--outlined-grey"
                : "btn btn__with-icon btn--grey"
            }
          >
            <box-icon
              name={isVideoEnabled ? "video" : "video-off"}
              animation="tada-hover"
            ></box-icon>
          </button>
          <button
            onClick={shareScreen}
            className={
              isScreenShareEnabled
                ? "btn btn__with-icon btn--outlined-grey"
                : "btn btn__with-icon btn--grey"
            }
          >
            <box-icon name={"desktop"} animation="tada-hover"></box-icon>
          </button>
          <button
            onClick={logoutHandler}
            className="btn btn__with-icon btn--red"
          >
            <box-icon name="phone-call" animation="tada-hover"></box-icon>
          </button>
        </div>
      </div>
    </>
  );
};

export default VideoRoom;
