import "boxicons";
import Modal from "react-modal";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  useSortBy,
  useTable,
} from "react-table";

import { uuid } from "utils/uuid";
import ListMenu from "./list-menu";
import AddMentor from "./AddMentor";
import { error } from "utils/toast";
import { getFullName } from "utils/names";
import { Status } from "constants/status";
import { ITerm } from "commons/types/terms";
import NameItem from "./menu-item/name-item";
import userImg from "assets/images/user.svg";
import { getDateInFormat } from "utils/dates";
import { STUDENT_STATUS } from "constants/student";
import DropStudentDialog from "../DropStudentDialog";
import { checkDropDownPosition } from "utils/dropdown";
import EmptyState from "commons/components/EmptyState";
import Loader from "../../../commons/components/Loader";
import DeleteConfirm from "commons/components/DeleteConfirm";
import { IEnrollment, ISponsor, IUser } from "commons/types/users";
import * as studentsService from "../../../services/students";
import {
  studentEnrollment,
  updateStudentEnrollment,
} from "../../../services/students";
import {
  TERM_STATUS_DROP_IN_PROGRESS_OPTIONS,
  TERM_STATUS_NOT_DROPPED_OPTIONS,
  TERM_STATUS_OPTIONS,
  TermStatus,
} from "constants/term";
import { getStudentFormValues } from "utils/user";

interface Props {
  students: Array<any>;
}

const ListStudentsTable: React.FC<Props> = ({
  students: studentsProp,
}): JSX.Element => {
  const history = useHistory();

  const [students, setStudents] = useState(studentsProp);

  useEffect(() => {
    setStudents(studentsProp);
  }, [studentsProp]);

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Student Name",
        accessor: "fullName",
        sortType: "basic",
        Cell: ({ row, value }: any) => {
          return <NameItem label={value} studentInfo={row.original} />;
        },
      },
      {
        Header: "Mentor",
        disableSortBy: true,
        accessor: "userData.enrollment.practitioners",
        Cell: ({ value = [], row }: any) => {
          const [addMentorOpen, setAddMentorOpen] = useState(false);

          let names: string | null = null;
          value.forEach(({ practitioner }: { practitioner: IUser }) => {
            if (names) {
              names = `${names}, ${getFullName(practitioner)}`;
            } else {
              names = getFullName(practitioner);
            }
          });

          return names ? (
            names
          ) : (
            <>
              <span
                className="link-item link-primary-default"
                onClick={() => setAddMentorOpen(true)}
              >
                + Add
              </span>
              <Modal
                isOpen={addMentorOpen}
                className="modal-block"
                ariaHideApp={false}
                onRequestClose={() => setAddMentorOpen(false)}
              >
                <div className="modal-wrap modal-wrap--lg">
                  <div className="modal-wrap__header">
                    <h3>Add Mentor</h3>
                    <span
                      className="link-item"
                      onClick={() => setAddMentorOpen(false)}
                    >
                      <box-icon name="x"></box-icon>
                    </span>
                  </div>
                  <AddMentor
                    saveData={async (payload) => {
                      let returnedStudent;
                      if (row.original?.userData?.enrollment?._id)
                        returnedStudent = await updateStudentEnrollment(
                          row.original._id,
                          {
                            ...payload,
                            _id: row.original.userData.enrollment._id,
                          },
                        );
                      else {
                        returnedStudent = await studentEnrollment(
                          row.original._id,
                          payload,
                        );
                      }

                      return returnedStudent;
                    }}
                    initialValues={{
                      mentorshipNote:
                        row.original?.userData?.enrollment?.mentorshipNote,
                      userId: "",
                    }}
                    closeDialog={() => setAddMentorOpen(false)}
                  />
                </div>
              </Modal>
            </>
          );
        },
      },
      {
        Header: "Location",
        disableSortBy: true,
        accessor: "userData.enrollment.learningCenter.name",
        Cell: ({ value }: any) => {
          return value || "-";
        },
      },
      {
        Header: "Parent Name",
        disableSortBy: true,
        accessor: "relatedUsers.sponsors",
        Cell: ({ value = [] }: any) => {
          const id = uuid();
          if (value && value.length > 0) {
            return (
              <>
                <p className="tooltip-text">{getFullName(value[0].userId)} </p>
                {value?.length > 1 && (
                  <>
                    <p
                      className="tooltip-info tooltip-info__link tooltip-text"
                      data-tip
                      data-for={id}
                    >{`(${value.length - 1} more)`}</p>
                    <ReactTooltip
                      multiline={true}
                      id={id}
                      place="bottom"
                      effect="solid"
                      className="tooltip"
                    >
                      {value
                        .slice(1)
                        .map((sponsor: ISponsor, index: number) => {
                          if (value.length - 2 === index) {
                            return (
                              <React.Fragment key={sponsor.userId._id}>
                                <span>{`${getFullName(sponsor.userId)} `}</span>
                              </React.Fragment>
                            );
                          }
                          return (
                            <React.Fragment key={sponsor.userId._id}>
                              <span>{`${getFullName(sponsor.userId)}, `}</span>
                              <br />
                            </React.Fragment>
                          );
                        })}
                    </ReactTooltip>
                  </>
                )}
              </>
            );
          }
          return "-";
        },
      },
      {
        Header: "Term",
        disableSortBy: true,
        accessor: "userData.enrollment.enrolledTerm",
        Cell: ({ value }: { value: ITerm }) => {
          return (value && value.name) || "-";
        },
      },
      {
        Header: "Joined Date",
        disableSortBy: true,
        accessor: "userData.enrollment.enrollmentDate",
        Cell: ({ value }: { value: string }) => {
          return (value && getDateInFormat(new Date(value))) || "-";
        },
      },
      {
        Header: "Term Status",
        disableSortBy: true,
        accessor: "userData.enrollment.status",
        width: 134,
        Cell: ({ value, row }: { value: string; row: { original: IUser } }) => {
          const currentStudent = row.original;
          const enrollment = currentStudent.userData?.enrollment;
          const enrollmentStatus = currentStudent.userData?.enrollment?.status;

          const termStatusOptions =
            enrollmentStatus === TermStatus.DropInProgress
              ? TERM_STATUS_DROP_IN_PROGRESS_OPTIONS
              : TERM_STATUS_NOT_DROPPED_OPTIONS;

          const [termStatusOption, setTermStatusOption] = useState(
            TERM_STATUS_OPTIONS.find((option) => option.value === value),
          );

          const dropMessage = enrollment?.dropMessage;

          const [
            isDropProgressConfirmationOpen,
            setIsDropProgressConfirmationOpen,
          ] = React.useState(false);

          const [isDropDialogOpen, setIsDropDialogOpen] = React.useState(false);

          const [isUpdating, setIsUpdating] = useState(false);

          const [disableTermStatusChange, setDisableTermStatusCHange] =
            useState(false);

          useEffect(() => {
            if (
              enrollmentStatus !== TermStatus.Draft ||
              (enrollment?.enrolledTerm?._id &&
                enrollment?.mentorshipSchedule?.latest?.day &&
                enrollment?.practitioners?.length)
            ) {
              setDisableTermStatusCHange(false);
            } else {
              setDisableTermStatusCHange(true);
            }
          }, [enrollmentStatus, enrollment]);

          async function updateTermStatus(
            payload: IEnrollment,
            successCallback?: () => void,
          ) {
            setIsUpdating(true);

            try {
              // @ts-ignore
              await updateStudentEnrollment(currentStudent._id, payload);

              setStudents((prevState) =>
                prevState.map((student) => {
                  if (
                    student.userData?.enrollment &&
                    student.userData.enrollment._id ===
                      currentStudent.userData?.enrollment?._id
                  ) {
                    return {
                      ...student,
                      userData: {
                        ...student.userData,
                        enrollment: {
                          ...student.userData?.enrollment,
                          status: payload.status,
                          dropMessage: payload.dropMessage || dropMessage,
                        },
                        // Update the same enrollment object in the enrollments array as well
                        enrollments: student.userData.enrollments.map(
                          (enrollment: IEnrollment) =>
                            enrollment._id === student.userData.enrollment._id
                              ? {
                                  ...enrollment,
                                  status: payload.status,
                                  dropMessage:
                                    payload.dropMessage || dropMessage,
                                }
                              : enrollment,
                        ),
                      },
                    };
                  }

                  return student;
                }),
              );

              setIsUpdating(false);

              successCallback && successCallback();
            } catch (err: any) {
              setIsUpdating(false);
              error("Failed to update enrollment status");
            }
          }

          if (
            !enrollment ||
            (enrollment && Object.keys(enrollment).length === 0)
          ) {
            return <>-</>;
          }

          if (isUpdating) {
            return (
              <div className="table-item">
                <Loader type="ThreeDots" />
              </div>
            );
          }

          const DropMessageTooltip = () => (
            <>
              {dropMessage &&
                (enrollmentStatus === TermStatus.DropInProgress ||
                  enrollmentStatus === TermStatus.Dropped) && (
                  <>
                    <p
                      className="table-col-relative__triangle-indicator"
                      data-tip
                      data-for={enrollment?._id}
                    />
                    <ReactTooltip
                      multiline={true}
                      id={enrollment?._id}
                      place="bottom"
                      effect="solid"
                    >
                      <span />
                      {dropMessage}
                      <span />
                    </ReactTooltip>
                  </>
                )}
            </>
          );

          return (
            <div className="table-item">
              <DropMessageTooltip />

              <Select
                name="termStatus"
                isSearchable={false}
                classNamePrefix="react-select"
                menuPlacement="auto"
                value={termStatusOption}
                onChange={(selectedOption) => {
                  if (selectedOption === termStatusOption) {
                    return;
                  }

                  if (selectedOption?.value === TermStatus.DropInProgress) {
                    setIsDropProgressConfirmationOpen(true);

                    return;
                  }

                  if (!selectedOption) {
                    return;
                  }
                  //userId is added in payload because backend throws error if userId is not found.
                  const payload: IEnrollment = {
                    userId: currentStudent?.userData?.enrollment?.practitioners
                      ?.length
                      ? currentStudent?.userData?.enrollment?.practitioners[0]
                          .practitioner._id
                      : null,
                    _id: currentStudent?.userData?.enrollment?._id,
                    status: selectedOption.value,
                  };

                  selectedOption && setTermStatusOption(selectedOption);

                  updateTermStatus(payload);
                }}
                isDisabled={disableTermStatusChange}
                options={termStatusOptions}
              />

              <Modal
                ariaHideApp={false}
                isOpen={isDropProgressConfirmationOpen}
                className="modal-block"
              >
                <DeleteConfirm
                  closeModal={() => setIsDropProgressConfirmationOpen(false)}
                  message={{
                    header:
                      "Do you want to change the term status to Drop in Progress?",
                  }}
                  deleteResource={() => {
                    setIsDropProgressConfirmationOpen(false);
                    setIsDropDialogOpen(true);
                  }}
                  confirmButtonLabel={"Drop in progress"}
                />
              </Modal>

              <DropStudentDialog
                isOpen={isDropDialogOpen}
                dropConfirm={async (dropReason: string) => {
                  setIsDropDialogOpen(false);
                  const payload: IEnrollment = {
                    _id: currentStudent?.userData?.enrollment?._id,
                    userId: currentStudent?.userData?.enrollment?.practitioners
                      ?.length
                      ? currentStudent?.userData?.enrollment?.practitioners[0]
                          .practitioner._id
                      : null,
                    status: TermStatus.DropInProgress,
                    dropMessage: dropReason,
                  };

                  await updateTermStatus(payload, () => {
                    setTermStatusOption({
                      value: TermStatus.DropInProgress,
                      label: "Drop In Progress",
                    });
                  });
                }}
                closeModal={() => setIsDropDialogOpen(false)}
              />
            </div>
          );
        },
      },
      {
        Header: "Student Status",
        accessor: "userData.status",
        disableSortBy: true,
        width: 116,
        Cell: ({ value, row }: { value: string; row: { original: IUser } }) => {
          const [isUpdating, setIsUpdating] = useState(false);

          const studentStatusOption = STUDENT_STATUS.find(
            (option) => option.value === value,
          );

          if (isUpdating) {
            return (
              <div className="table-item">
                <Loader type="ThreeDots" />
              </div>
            );
          }

          const updateUserStatus = async (selectedOption: {
            value: string;
            label: string;
          }) => {
            setIsUpdating(true);

            const selectedStudent = getStudentFormValues(row.original);
            const requestPayload = {
              userData: {
                ...selectedStudent.userData,
                status: selectedOption.value,
              },
            };

            try {
              await studentsService.update(
                // @ts-ignore
                row.original._id,
                requestPayload,
              );

              setStudents((prevState) =>
                prevState.map((student) => {
                  if (student._id === row.original._id) {
                    return {
                      ...student,
                      userData: {
                        ...student.userData,
                        status: selectedOption.value,
                      },
                    };
                  }

                  return student;
                }),
              );

              setIsUpdating(false);
            } catch (err: any) {
              error("Failed to update student status");

              setIsUpdating(false);
            }
          };

          return (
            <div className="table-item">
              <Select
                name="studentStatus"
                isSearchable={false}
                classNamePrefix="react-select"
                value={studentStatusOption}
                options={STUDENT_STATUS}
                menuPlacement="auto"
                onChange={async (selectedOption) => {
                  if (!selectedOption) {
                    return;
                  }

                  if (selectedOption === studentStatusOption) {
                    return;
                  }

                  updateUserStatus(selectedOption);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        disableSortBy: true,
        width: 50,
        Cell: ({ row }: { row: { original: IUser; index: string } }) => {
          const [isMenuTop, setMenuTop] = useState<boolean>(false);
          const [openMenu, setOpenMenu] = useState("");

          const closeMenu = useCallback(() => {
            setOpenMenu("");
          }, [setOpenMenu]);

          useEffect(() => {
            if (openMenu) {
              document.addEventListener("click", closeMenu);
            } else {
              document.removeEventListener("click", closeMenu);
            }

            return () => {
              document.removeEventListener("click", closeMenu);
            };
          }, [openMenu, closeMenu]);

          return (
            <div className="action-group">
              <span className="mr-2x link-item">
                <box-icon
                  onClick={() =>
                    history.push(`/dashboard/students/${row.original._id}/edit`)
                  }
                  name="highlight"
                  size="sm"
                  color="#959FAE"
                />
              </span>
              <span
                className="link-item mr-2x"
                onClick={(event: any) => {
                  if (openMenu) {
                    closeMenu();
                    return;
                  }

                  setMenuTop(checkDropDownPosition(event));
                  setOpenMenu(row.index.toString());
                }}
              >
                <box-icon
                  name="dots-horizontal-rounded"
                  size="sm"
                  color="#959FAE"
                />
                {row.index.toString() === openMenu && (
                  <ListMenu
                    newEnrollment={() => {
                      history.push(
                        `/dashboard/students/${row.original._id}/enroll/${
                          row.original.userData?.enrollments?.length || 0
                        }`,
                      );
                      closeMenu();
                    }}
                    canNewEnroll={
                      row.original?.userData?.status === Status.ACTIVE
                    }
                    editStudent={() =>
                      history.push(
                        `/dashboard/students/${row.original._id}/edit`,
                      )
                    }
                    canEdit={!!row.original.userData?.enrollment?.status}
                    editTerm={() => {
                      const index =
                        row.original.userData?.enrollments?.findIndex(
                          (enrollment) =>
                            enrollment._id ===
                            row.original.userData?.enrollment?._id,
                        );

                      history.push(
                        `/dashboard/students/${row.original._id}/enroll/${index}`,
                      );
                    }}
                    isMenuTop={isMenuTop}
                  />
                )}
              </span>
            </div>
          );
        },
      },
    ],
    [history],
  );

  const tableInstance = useTable({ columns, data: students }, useSortBy);

  const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps } =
    tableInstance;

  return (
    <>
      <div className="table-responsive">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroups: HeaderGroup) => {
              return (
                <tr key={JSON.stringify(headerGroups)} className="head-row">
                  {headerGroups.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="table__col"
                    >
                      <span className="item-container">
                        <span className="item">{column.render("Header")}</span>
                        {column.canSort && (
                          <span className="item">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <box-icon name="sort-up" color="#959FAE" />
                              ) : (
                                <box-icon name="sort-down" color="#959FAE" />
                              )
                            ) : (
                              <box-icon name="sort-alt-2" color="#959FAE" />
                            )}
                          </span>
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          {rows.length ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row: Row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="table__row">
                    {row.cells.map((cell: Cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: { width: cell.column.width },
                          })}
                          className="table__col table-col-relative"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()} className="bg-white">
              <tr className="table__row">
                <td
                  colSpan={
                    headerGroups.length ? headerGroups[0].headers.length : 1
                  }
                  className="table__col"
                >
                  <EmptyState
                    image={userImg}
                    title={"Sorry could not find any student"}
                    description={
                      "Try finding student using other keywords/filters"
                    }
                    alt={"User"}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default ListStudentsTable;
