import { FetchTwilioTokenActionType } from "store/actions/types/twilio";

const INITIAL_STATE = {
  fetchTwilioToken: {},
  fetchTwilioTokenError: null,
  fetchTwilioTokenLoading: false,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchTwilioTokenActionType.FETCH_TWILIO_TOKEN_PENDING:
      return {
        ...state,
        fetchTwilioTokenLoading: true,
        fetchTwilioTokenError: null,
      };
    case FetchTwilioTokenActionType.FETCH_TWILIO_TOKEN_FULFILLED:
      return {
        ...state,
        fetchTwilioToken: action.data,
        fetchTwilioTokenLoading: false,
        fetchTwilioTokenError: null,
      };
    case FetchTwilioTokenActionType.FETCH_TWILIO_TOKEN_REJECTED:
      return {
        ...state,
        fetchTwilioTokenLoading: false,
        fetchTwilioTokenError: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
