import { FC, ReactElement } from "react";
import ProtectedRoute from "protected-route";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import { getDataFromLocalStorage } from "services/localStorage";
import Plans from "pages/plans/list/Plans";
import Goals from "pages/goals/list";
import MessageList from "pages/mentor-dashboard/MessageList";
import IncomingCallModal from "pages/mentor-dashboard/incoming-call/IncomingCallModal";
import { IProfile } from "commons/types/users";
import StudentPersonalInfo from "pages/student-profile/personal-info/StudentPersonalInfo";
import MyMentor from "pages/student-profile/my-mentor";

interface IPractitionerDashboardProps {}

const StudentDashboard: FC<IPractitionerDashboardProps> = (): ReactElement => {
  const { path } = useRouteMatch();
  const currentUser: IProfile = getDataFromLocalStorage("profile");

  return (
    <main>
      <TopNavbar options={[]} />
      <main>
        <div className="content-wrap">
          <div className="container-fluid">
            <div className="main-content">
              <Switch>
                <Route
                  exact
                  path={`${path}`}
                  render={() => (
                    <Redirect to={`${path}/${currentUser._id}/plans`} />
                  )}
                />
                <ProtectedRoute
                  exact
                  path={`${path}/:id/plans`}
                  component={Plans}
                />
                <ProtectedRoute path={`${path}/:id/goals`} component={Goals} />
                <ProtectedRoute
                  path={`${path}/:id/personal-info`}
                  component={StudentPersonalInfo}
                />
                <ProtectedRoute
                  path={`${path}/:id/my-mentor`}
                  component={MyMentor}
                />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
              <MessageList />
              <IncomingCallModal />
            </div>
          </div>
        </div>
      </main>
    </main>
  );
};

export default StudentDashboard;
