import {
  FetchInitialConfigType,
  FetchFinalConfigType,
  FetchTenantsType,
  UpdateTeantType,
  AddTenantType,
  FetchAllUsersType,
  FetchTenantUserListType,
} from "store/actions/types/multitenancy";

const INITIAL_STATE = {
  fetchConfigLoading: true,
  initialConfigs: [],
  finalConfigs: [],
  tenantsList: [],
  usersList: [],
  usersListLoading: true,
  fetchTenantsLoading: true,
  addTenantLoading: false,
  fetchUsersLoading: true,
  fetchTenantUserListLoading: true,
  tenantUserList: [],
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchInitialConfigType.FETCH_INITIAL_CONFIG_PENDING:
      return {
        ...state,
        fetchConfigLoading: true,
      };
    case FetchInitialConfigType.FETCH_INITIAL_CONFIG_SUCCESS:
      return {
        ...state,
        fetchConfigLoading: false,
        initialConfigs: action.data,
      };
    case FetchInitialConfigType.FETCH_INITIAL_CONFIG_ERROR:
      return {
        ...state,
        fetchConfigLoading: false,
      };
    case FetchFinalConfigType.FETCH_FINAL_CONFIG_PENDING:
      return {
        ...state,
        fetchConfigLoading: true,
      };
    case FetchFinalConfigType.FETCH_FINAL_CONFIG_SUCCESS:
      return {
        ...state,
        fetchConfigLoading: false,
        finalConfig: action.data,
      };
    case FetchFinalConfigType.FETCH_FINAL_CONFIG_ERROR:
      return {
        ...state,
        fetchConfigLoading: false,
      };

    case FetchTenantsType.FETCH_TENANTS_PENDING:
      return {
        ...state,
        fetchTenantsLoading: true,
      };

    case FetchTenantsType.FETCH_TENANTS_SUCCESS:
      return {
        ...state,
        fetchTenantsLoading: false,
        tenantsList: action.data,
      };
    case FetchTenantsType.FETCH_TENANTS_ERROR:
      return {
        ...state,
        fetchTenantsLoading: false,
      };
    case AddTenantType.ADD_TENANT_PENDING:
      return {
        ...state,
        addTenantLoading: true,
      };
    case AddTenantType.ADD_TENANT_SUCCESS:
      return {
        ...state,
        addTenantLoading: false,
      };
    case AddTenantType.ADD_TENANT_ERROR:
      return {
        ...state,
        addTenantLoading: false,
      };
    case UpdateTeantType.UPDATE_TENANT_PENDING:
      return {
        ...state,
        addTenantLoading: true,
      };
    case UpdateTeantType.UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        addTenantLoading: false,
      };
    case UpdateTeantType.UPDATE_TENANT_ERROR:
      return {
        ...state,
        addTenantLoading: false,
      };

    case FetchAllUsersType.FETCH_USERS_PENDING:
      return {
        ...state,
        fetchUsersLoading: true,
      };
    case FetchAllUsersType.FETCH_USERS_SUCCESS:
      return {
        ...state,
        fetchUsersLoading: false,
        usersList: action.data,
      };

    case FetchAllUsersType.FETCH_USERS_ERROR:
      return {
        ...state,
        fetchUsersLoading: false,
      };
    case FetchTenantUserListType.FETCH_TENANT_USERLIST_PENDING:
      return {
        ...state,
        fetchTenantUserListLoading: true,
      };
    case FetchTenantUserListType.FETCH_TENANT_USERLIST_SUCCESS:
      return {
        ...state,
        tenantUserList: action.data,
        fetchTenantUserListLoading: false,
      };
    case FetchTenantUserListType.FETCH_TENANT_USERLIST_ERROR:
      return {
        ...state,
        fetchTenantUserListLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
