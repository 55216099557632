export enum TenantRole {
  ADMIN = "Admin",
  SPONSOR = "Sponsor",
  SUBJECT = "Subject",
  PRACTITIONER = "Practitioner",
}

export type TenantRoles = Record<TenantRole, string>;

export interface TenantConfig {
  users: TenantRoles;
}
