import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

import { TITLE_LENGTH } from "constants/index";

const TaskSchema: Yup.ObjectSchema<ObjectShape> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Task title is required")
    .max(TITLE_LENGTH, `Title must be less than ${TITLE_LENGTH} characters`),
  requiredDeadline: Yup.date().required("Please select to-do date"),
  assignee: Yup.string(),
  childOf: Yup.string().nullable(),
  isPublished: Yup.boolean(),
  planningPeriod: Yup.string().required("Plan is required"),
  workData: Yup.object().shape({
    priority: Yup.string().nullable(),
    recurrence: Yup.string().nullable(),
    optionalDeadline: Yup.date().nullable(),
  }),
});

export default TaskSchema;
