import ScrollArea from "react-scrollbar";

const CustomScrollbars = (props: any) => (
  <ScrollArea
    vertical={true}
    horizontal={false}
    smoothScrolling={true}
    {...props}
  ></ScrollArea>
);

export default CustomScrollbars;
