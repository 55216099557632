import dayjs from "dayjs";
import * as twilioChat from "twilio-chat";

import config from "configs";
import { http } from "utils/http";
import IMessage from "commons/types/message";

export const getTwilioToken = async () => {
  const { data } = await http.get(config.endpoints.communication.chatToken);
  return {
    jwt: data.twilioToken,
    identity: data.identity || "",
  };
};

export const createChatClient: any = async (token: string) => {
  let client = await twilioChat.Client.create(token);
  return client;
};

export const updateChatMessage = async (
  channelSid: string,
  messageSid: string,
  content: any,
) => {
  const { data } = await http.patch(config.endpoints.chat.updateMessage, {
    channelSid,
    messageSid,
    content,
  });

  return data;
};

export const getTextFromTwilioMessageBody: any = (
  twilioMessageBody: string,
) => {
  let messageText;
  try {
    const parsedMessageBody: IMessage = JSON.parse(twilioMessageBody);

    if (parsedMessageBody) {
      messageText = parsedMessageBody.text || twilioMessageBody;
    }
  } catch (err: any) {
    messageText = twilioMessageBody;
  }

  return messageText;
};

export const formatAMPM = (date: any) => {
  const strTime = `${dayjs(date).format("h")}:${dayjs(date).format(
    "m",
  )} ${dayjs(date).format("A")}`;
  return strTime;
};
