import React, { useEffect, useState } from "react";
import { TextAreaInput, TextInput } from "commons/components/form-fields";
import { Form, Formik } from "formik";
import { FormEvent } from "react";
import Modal from "react-modal";

import * as gradesService from "services/grades";
import { IAddUpdateGradeData, IGrade } from "../grade";
import AddGradeSchema from "../schema/addGradeSchema";
import { error, success } from "utils/toast";
import Confirm from "commons/components/Confirm";

export enum GradeType {
  Qualititive = "Qualititive",
  Quantitive = "Quantitive",
}

type AddGradesFormProps = {
  closeModal: () => void;
  initialValues: IAddUpdateGradeData;
  onGradeUpdateSuccess: () => void;
  isGradeNew: boolean;
};
interface FormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  values: IGrade;
  touched: IGrade;
  errors: IGrade;
  isSubmitting: boolean;
}

const AddGradeForm: React.FC<AddGradesFormProps> = ({
  closeModal,
  initialValues,
  onGradeUpdateSuccess,
  isGradeNew,
}) => {
  const [isCheckPercentage, setIsCheckPercentage] = useState<boolean>(
    initialValues.gradeType === GradeType.Qualititive,
  );
  const [isCheckGradingScale, setIsCheckGradingScale] = useState<boolean>(
    initialValues.gradeType === GradeType.Quantitive,
  );
  const [gradeDeleting, setGradeDeleting] = useState(false);
  const [isDeleteConfirmActive, setDeleteConfirmActive] = useState(false);

  const toggleDeleteConfirmActive = () => {
    setDeleteConfirmActive((prevState) => !prevState);
  };

  const onCheckPercentage = (values: IAddUpdateGradeData) => {
    values.isGradeInPercent = true;
    values.grade = isCheckPercentage ? initialValues.grade : "";
    setIsCheckGradingScale(false);
    setIsCheckPercentage(true);
  };

  const onCheckGradingScale = (values: IAddUpdateGradeData) => {
    values.isGradeInPercent = false;
    values.grade = isCheckGradingScale ? initialValues.grade : "";
    setIsCheckPercentage(false);
    setIsCheckGradingScale(true);
  };

  const addGrade = (values: IAddUpdateGradeData) => {
    gradesService
      .add({
        ...values,
        gradeType: isCheckPercentage
          ? GradeType.Qualititive
          : GradeType.Quantitive,
      })
      .then((response) => {
        success("Grade added");
        onGradeUpdateSuccess();
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        error("Failed to add grade.");
      });
  };

  const updateGrade = (values: IAddUpdateGradeData) => {
    gradesService
      .update({
        ...values,
        gradeType: isCheckPercentage
          ? GradeType.Qualititive
          : GradeType.Quantitive,
      })
      .then((response) => {
        success("Grade updated");
        onGradeUpdateSuccess();
        closeModal();
      })
      .catch((err) => {
        console.error(err);
        error("Failed to update grade.");
      });
  };

  //Setting isGradeInPercent true or false accoording to intial state for Yup validation.
  useEffect(() => {
    initialValues.isGradeInPercent = isCheckPercentage;
  }, [isCheckPercentage]);

  const saveGrade = (values: IAddUpdateGradeData) => {
    isGradeNew ? addGrade(values) : updateGrade(values);
  };

  const handleGradeDelete = () => {
    setGradeDeleting(true);
    if (!initialValues._id) {
      return;
    }
    gradesService
      .remove(initialValues._id)
      .then((response) => {
        success("Grade Deleted");
        onGradeUpdateSuccess();
        setGradeDeleting(false);
        closeModal();
      })
      .catch((err) => {
        error("Failed to delete grade.");
        setGradeDeleting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={AddGradeSchema}
        onSubmit={(values) => saveGrade(values)}
        enableReinitialize={true}
      >
        {(formikProps: FormikProps) => {
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <div className="modal-wrap__body scroll-modal-content pb-0x">
                <h3 className="mb-4x">Quantitative Grade</h3>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <input
                        type="radio"
                        id="percentage"
                        name="percentage"
                        checked={isCheckPercentage}
                        className="box-links__nodes"
                        onChange={() => onCheckPercentage(formikProps.values)}
                      />
                      <label htmlFor="percentage" className="input__label">
                        Percentage
                      </label>
                    </div>
                    {isCheckPercentage ? (
                      <div className="col-6">
                        <TextInput
                          label=""
                          placeholder="Add grade"
                          name="grade"
                          handleChange={formikProps.handleChange}
                          handleBlur={formikProps.handleBlur}
                          values={
                            initialValues.grade !== "-"
                              ? formikProps.values
                              : ""
                          }
                          touched={formikProps.touched}
                          errors={formikProps.errors}
                          helper="Please enter the maximum value for the percentage."
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <div className="input-wrap">
                      <input
                        type="radio"
                        id="gradindScale"
                        name="gradindScale"
                        className="box-links__nodes"
                        checked={isCheckGradingScale}
                        onChange={() => onCheckGradingScale(formikProps.values)}
                      />
                      <label htmlFor="gradindScale" className="input__label">
                        Grading Scale
                      </label>
                    </div>
                    {isCheckGradingScale ? (
                      <div className="col-6">
                        <TextInput
                          label=""
                          placeholder="Add grade"
                          name="grade"
                          handleChange={formikProps.handleChange}
                          handleBlur={formikProps.handleBlur}
                          values={
                            initialValues.grade !== "-"
                              ? formikProps.values
                              : ""
                          }
                          touched={formikProps.touched}
                          errors={formikProps.errors}
                          helper="Please enter the maximum value for the Grading Scale."
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-4x">
                    <TextAreaInput
                      label="Notes"
                      name="notes"
                      placeholder="We discussed about..."
                      touched={formikProps.touched}
                      errors={formikProps.errors}
                      values={formikProps.values?.notes}
                      handleChange={formikProps.handleChange}
                    />
                  </div>
                </div>
                <div className="modal-wrap__footer pt-0x d-flex">
                  <div className="row w-100p ">
                    <div className="col-12 status-toggle ">
                      <div className="d-flex">
                        <button
                          disabled={
                            formikProps.isSubmitting ||
                            initialValues === formikProps.values
                          }
                          className="btn btn--primary mr-4x"
                          type="submit"
                        >
                          {isGradeNew ? "Save" : "Update"}
                        </button>
                        <button
                          disabled={formikProps.isSubmitting}
                          onClick={closeModal}
                          className="btn txt-primary-color"
                          type="button"
                        >
                          Cancel
                        </button>
                      </div>
                      {!isGradeNew ? (
                        <>
                          <button
                            disabled={formikProps.isSubmitting}
                            onClick={toggleDeleteConfirmActive}
                            className="btn btn--red mr-4x"
                            type="button"
                          >
                            Delete grade
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        className="modal-block"
        isOpen={isDeleteConfirmActive}
        ariaHideApp={false}
        onRequestClose={toggleDeleteConfirmActive}
      >
        <Confirm
          closeModal={toggleDeleteConfirmActive}
          disabled={gradeDeleting}
          handleOk={handleGradeDelete}
          message={{
            header: "Confirm delete grade.",
            body: "Are you sure you want to delete this grade?",
          }}
        />
      </Modal>
    </>
  );
};

export default AddGradeForm;
