import * as React from "react";
import i18n from "configs/i18n";
import { connect } from "react-redux";
import { Channel } from "twilio-chat/lib/channel";

import config from "configs";
import { error } from "utils/toast";
import { Role } from "constants/roles";
import Menu, { MenuType } from "../menu";
import { EVENT } from "constants/pusher";
import pusherInstance from "utils/pusher";
import Notifications from "./Notifications";
import { toggleChat } from "store/actions/data/chat";
import INotification from "commons/types/notification";
import { Link, NavLink, useHistory } from "react-router-dom";
import { getDataFromLocalStorage } from "services/localStorage";
import { fetchAll, fetchUnseenNotifications } from "services/notifications";
import { isAdmin } from "utils/http";
import boostLogo from "../../../assets/images/boost_logo.svg";
import useLogout from "commons/hooks/useLogout";
import { isStudentLoggedIn } from "utils/window";

interface ITopNavbarProps {
  options: any[];
  toggleChat: () => any;
  unreadMessageChannels: Channel[];
}

interface INotificationData {
  notifications: INotification[];
  isLoading: boolean;
}

interface IEventData {
  unseenNotificationCount: number;
}

const TopNavbar: React.FC<ITopNavbarProps> = (props): JSX.Element => {
  const [notificationData, setNotificationData] =
    React.useState<INotificationData>({
      isLoading: false,
      notifications: [],
    });

  const [unseenNotifications, setUnseenNotifications] = React.useState(0);
  const history = useHistory();
  const { logout } = useLogout();

  /**
   * Handle Language Change
   * @param event
   */
  // const handleLanguageChange = (value: string) => {
  //   i18n.changeLanguage(value);
  // };

  React.useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  const profile = getDataFromLocalStorage("profile") || {};
  const tenantLogo = getDataFromLocalStorage("tenantLogo");
  const userRoles = profile?.roles?.map((role: any) => role.name);

  const fetchNotification = React.useCallback(
    async (id: string) => {
      setNotificationData((prevState) => ({
        isLoading: true,
        notifications: prevState.notifications,
      }));

      try {
        const { data } = await fetchAll(id);
        setNotificationData({
          isLoading: false,
          notifications: data,
        });
      } catch (err: any) {
        setNotificationData({
          isLoading: false,
          notifications: [],
        });
        error(err);
      }
    },
    [setNotificationData],
  );

  const fetchUnseenCount = React.useCallback(
    async (id: string) => {
      try {
        const { data } = await fetchUnseenNotifications(id);
        setUnseenNotifications(data);
      } catch (err: any) {
        console.error(err);
      }
    },
    [setUnseenNotifications],
  );

  const notificationUpdateHandler = React.useCallback(
    async (data: IEventData) => {
      try {
        const { unseenNotificationCount } = data;

        const { data: notificationList } = await fetchAll(profile._id);

        setNotificationData({
          isLoading: false,
          notifications: notificationList,
        });
        setUnseenNotifications(unseenNotificationCount);
      } catch (err: any) {
        console.log(err, "err");
        error(err);
      }
    },
    [profile._id],
  );

  React.useEffect(() => {
    if (!profile._id) {
      return;
    }
    fetchUnseenCount(profile._id);
  }, [profile._id, fetchUnseenCount]);

  React.useEffect(() => {
    if (!profile._id) {
      return;
    }

    const channel = pusherInstance.subscribeToUserChannel(profile._id);
    channel.bind(EVENT.NOTIFICATION_UPDATE, notificationUpdateHandler);

    return () => {
      channel.unbind(EVENT.NOTIFICATION_UPDATE, notificationUpdateHandler);
    };
  }, [profile._id, notificationUpdateHandler]);

  return (
    <nav className="navbar navbar--bordered-bottom navbar--sticky">
      <div className="container-fluid">
        <div className="navbar__wrap navbar__wrap--content-spread">
          <div className="navbar__left">
            <div className="navbar__logo">
              <Link to="">
                {isAdmin ? (
                  <img src={boostLogo} alt="boost logo" />
                ) : (
                  <img
                    src={tenantLogo}
                    alt="tenant logo"
                    className="tw-w-50 tw-h-10 tw-object-contain"
                  />
                )}
              </Link>
            </div>
            <div className="nav">
              <ul className="nav">
                {(props.options || []).map((navItem: any) => (
                  <li key={navItem.label} className="nav__node link-item">
                    <NavLink
                      to={navItem.link}
                      className={
                        navItem.active ? "nav__link--active" : "nav__link"
                      }
                      activeClassName="nav__link--active"
                    >
                      {navItem.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="navbar__right">
            <ul className="navbar-list">
              {/* {TODO: Implement in Phase 2} */}
              {/* <li className="language-container">
                <span className="text-dropdown">
                  <Menu
                    type={MenuType.Custom}
                    menuIcon={
                      <>
                        {i18next.language?.toLocaleUpperCase()}
                        <box-icon name="chevron-down"></box-icon>
                      </>
                    }
                  >
                    <li
                      className={
                        "menu-list" +
                        (i18next.language === "en" ? " menu-list--active" : "")
                      }
                      onClick={() => handleLanguageChange("en")}
                    >
                      <span className="ml-2x">English</span>
                    </li>
                    <li
                      className={
                        "menu-list" +
                        (i18next.language === "ru" ? " menu-list--active" : "")
                      }
                      onClick={() => handleLanguageChange("ru")}
                    >
                      <span className="ml-2x">Russian</span>
                    </li>
                  </Menu>
                </span>
              </li> */}
              <div className="navbar__right">
                {/* {tenantInfo?.roles?.includes("SUPER_ADMIN") && (
                  <strong>Super Admin</strong>
                )} */}
              </div>
              {userRoles?.includes(Role.PRACTITIONER) || isStudentLoggedIn() ? (
                <li className="notification-container">
                  {unseenNotifications ? (
                    <div className="notification-bell__count">
                      {unseenNotifications}
                    </div>
                  ) : null}
                  <Menu
                    menuIcon={
                      <span className="user-dropdown">
                        <box-icon name="bell"></box-icon>
                      </span>
                    }
                    type={MenuType.Custom}
                  >
                    <div className="modal-wrap">
                      <Notifications
                        notificationList={notificationData.notifications}
                        isLoading={notificationData.isLoading}
                        fetchData={fetchNotification}
                        fetchNotificationCount={fetchUnseenCount}
                      />
                    </div>
                  </Menu>
                </li>
              ) : null}
              {userRoles?.includes(Role.PRACTITIONER) || isStudentLoggedIn() ? (
                <li className="message-container">
                  <span
                    className="user-dropdown"
                    onClick={() => props.toggleChat()}
                  >
                    {props.unreadMessageChannels.length ? (
                      <div className="message-unread"></div>
                    ) : null}
                    <span className="mt-1x link-item action-links">
                      <box-icon name="chat" />
                    </span>
                  </span>
                </li>
              ) : null}
              <li className="language-container">
                <span className="user-dropdown">
                  <Menu
                    type={MenuType.Custom}
                    menuIcon={
                      profile?.avatar ? (
                        <div className="avatar">
                          <img src={profile.avatar} alt="avatar" />
                        </div>
                      ) : (
                        <box-icon name="user"></box-icon>
                      )
                    }
                  >
                    {userRoles?.includes(Role.PRACTITIONER) ? (
                      <li
                        className="menu-list"
                        onClick={() =>
                          history.push(config.uiPath.mentors.profile)
                        }
                      >
                        <span className="ml-2x">My Profile</span>
                      </li>
                    ) : null}
                    <li
                      className="menu-list"
                      onClick={() => {
                        logout();
                      }}
                    >
                      <span className="ml-2x">Log Out</span>
                    </li>
                  </Menu>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

interface IState {
  chat: { unreadMessageChannels: Channel[] };
}

const mapStateToProps = (state: IState) => ({
  unreadMessageChannels: state.chat.unreadMessageChannels,
});

const mapDispatchToProps = {
  toggleChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
