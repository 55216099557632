export enum CallInitiateEvent {
  CALL_STARTED = "CALL_STARTED",
  CALL_ENDED = "CALL_ENDED",
  CALL_REJECTED = "CALL_REJECTED",
  CALL_BUSY = "CALL_BUSY",
}

export enum CallType {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
}

export interface INotifyCall {
  to: string;
  from: string;
  room: string;
  event: CallInitiateEvent;
  type: CallType;
  triggeredBy?: string;
  initiator?: string;
}

export interface ICallParticipant {
  _id: string;
  auth0UserId: string;
  avatar: string;
  firstName: string;
  lastName: string;
  twilioUserSid: string;
}

export interface ICall {
  to: string;
  from: string;
  type: CallType;
  event: string;
  room: string;
  triggeredBy?: string;
  caller: ICallParticipant;
  receiver: ICallParticipant;
  isCallerMe?: boolean;
}
