import Form from "../Form";
import { IAddSatelliteLocationProps } from "./AddSatelliteLocation.interface";

const AddSatelliteLocation: React.FC<IAddSatelliteLocationProps> = ({
  closeModal,
  saveSatelliteLocation,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add a Satellite Location</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        initialValues={{
          address: "",
          location: "",
        }}
        saveSatelliteLocation={saveSatelliteLocation}
        closeModal={closeModal}
      />
    </div>
  );
};

export default AddSatelliteLocation;
