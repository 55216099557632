import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch, useRouteMatch, Route } from "react-router-dom";

import config from "configs";
import School from "pages/school";
import TopNavbar from "./TopNavbar";
import Terms from "pages/terms/list";
import TermForm from "pages/terms/add";
import { Centers } from "pages/centers";
import MentorForm from "pages/mentor/add";
import AddStudent from "pages/students/add";
import ProtectedRoute from "protected-route";
import ListStudents from "pages/students/list";
import EditStudent from "pages/students/update";
import Accommodations from "pages/accommodation";
import EnrollStudent from "pages/students/enroll";
import SchoolDistrict from "pages/school-district";
import MentorList from "pages/mentor/list/MentorList";
import GradeTypes from "pages/grade-types/GradeTypes";
import SatelliteLocation from "pages/satellite-location";
import AcademicYear from "../../../pages/academic-year";
import SurveyDownload from "../../../pages/survey/download";

interface ManagerDashboardProps {}

const ManagerDashboard: FC<ManagerDashboardProps> = (): ReactElement => {
  const { t } = useTranslation();

  const checkUrl = () => {
    return window.location.href.includes("settings");
  };

  const topNavOptions = [
    {
      label: t("navMenu.students"),
      path: config.uiPath.students.list,
      link: config.uiPath.students.list,
    },
    {
      label: t("navMenu.mentors"),
      path: config.uiPath.mentors.list,
      link: config.uiPath.mentors.list,
    },
    {
      label: t("navMenu.terms"),
      path: config.uiPath.terms.list,
      link: config.uiPath.terms.list,
    },
    {
      label: "Settings",
      path: config.uiPath.settings.center,
      link: config.uiPath.settings.center,
      active: checkUrl(),
    },
    {
      label: "Assessment",
      path: config.uiPath.survey.surveyDownload,
      link: config.uiPath.survey.surveyDownload,
    },
  ];

  const { path } = useRouteMatch();

  return (
    <>
      <TopNavbar options={topNavOptions} />
      <main>
        <div className="main-wrapper">
          <Switch>
            <ProtectedRoute exact path={path} component={ListStudents} />
            <ProtectedRoute
              exact
              path={`${path}/mentors`}
              component={MentorList}
            />
            <ProtectedRoute
              exact
              path={`${path}/mentors/add`}
              component={MentorForm}
            />
            <ProtectedRoute
              exact
              path={`${path}/students`}
              component={ListStudents}
            />
            <ProtectedRoute
              exact
              path={`${path}/students/add`}
              component={AddStudent}
            />
            <ProtectedRoute
              exact
              path={`${path}/students/:id/enroll/:index`}
              component={EnrollStudent}
            />
            <ProtectedRoute
              exact
              path={`${path}/students/:id/edit`}
              component={EditStudent}
            />
            <ProtectedRoute exact path={`${path}/terms`} component={Terms} />
            <ProtectedRoute
              exact
              path={`${path}/terms/add`}
              component={TermForm}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/centers`}
              component={Centers}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/academic-year`}
              component={AcademicYear}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/accommodations`}
              component={Accommodations}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/grade-type`}
              component={GradeTypes}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/school-district`}
              component={SchoolDistrict}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/satellite-location`}
              component={SatelliteLocation}
            />
            <ProtectedRoute
              exact
              path={`${path}/settings/schools`}
              component={School}
            />
            <ProtectedRoute
              exact
              path={`${path}/survey/download`}
              component={SurveyDownload}
            />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </main>
    </>
  );
};

export default ManagerDashboard;
