import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as notesService from "services/notes";
import { INote, INoteParams } from "commons/types/notes";
import {
  AddNoteActionType,
  DeleteNoteActionType,
  FetchNotesActionType,
  UpdateNoteActionType,
} from "store/actions/types/notes";

export const addNote = (payload: INote) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddNoteActionType.ADD_NOTE_PENDING,
  });
  try {
    const note = await notesService.add(payload);
    dispatch({
      type: AddNoteActionType.ADD_NOTE_FULFILLED,
      data: note,
    });
    success("Saved note");

    return note;
  } catch (ex) {
    dispatch({
      type: AddNoteActionType.ADD_NOTE_REJECTED,
      data: ex,
    });

    error("Failed to save note");
  }
};

export const updateNote =
  (id: string, payload: INote) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateNoteActionType.UPDATE_NOTE_PENDING,
    });
    try {
      const { data: note } = await notesService.update(id, payload);
      dispatch({
        type: UpdateNoteActionType.UPDATE_NOTE_FULFILLED,
        data: note,
      });
      success("Updated note");

      return note;
    } catch (ex) {
      dispatch({
        type: UpdateNoteActionType.UPDATE_NOTE_REJECTED,
        data: ex,
      });

      error("Failed to update note");
    }
  };

export const fetchNotes =
  (params: INoteParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchNotesActionType.FETCH_NOTES_PENDING,
    });
    try {
      const notes = await notesService.fetchAll(params);
      dispatch({
        type: FetchNotesActionType.FETCH_NOTES_FULFILLED,
        data: notes,
      });

      return notes;
    } catch (ex) {
      dispatch({
        type: FetchNotesActionType.FETCH_NOTES_REJECTED,
        data: ex,
      });

      error("Failed to fetch notes");
    }
  };

export const deleteNote = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DeleteNoteActionType.DELETE_NOTE_PENDING,
  });
  try {
    const { data: note } = await notesService.deleteNote(id);
    dispatch({
      type: DeleteNoteActionType.DELETE_NOTE_FULFILLED,
      data: note,
    });
    success("Deleted note");

    return note;
  } catch (ex) {
    dispatch({
      type: DeleteNoteActionType.DELETE_NOTE_REJECTED,
      data: ex,
    });

    error("Failed to delete note");
  }
};
