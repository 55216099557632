import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import * as FullStory from "@fullstory/browser";

import { isDev } from "services/fullstory";
import Config from "./configs/env.configs";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import store from "./store";

// Include common style here
import "react-datepicker/dist/react-datepicker.css";
import "assets/css/style.css";
import "assets/css/outputTailwind.css";

// Components
import Loader from "commons/components/Loader";
import ErrorBoundary from "commons/components/errors/error-boundary";
import { GlobalContextProvider } from "./GlobalContext";
import InitialConfigProvider from "./InitialConfigProvider";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isToday from "dayjs/plugin/isToday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(advancedFormat);
dayjs.extend(isToday);

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

/**
 * Full story initialization
 */

if (isDev()) {
  FullStory.init({ orgId: Config.fullStoryOrgId });
}

/**
 * Initializing Sentry for logging and tracing purposes
 */
Sentry.init({
  dsn: Config.sentryUrl,
  environment: Config.env,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Config.env === "prod" ? 0.5 : 1.0,
});

declare global {
  interface Window {
    skillSetsMap: { [key: string]: any };
    questionsMap: { [key: string]: any };
    optionsMap: { [key: string]: any };
  }
  namespace JSX {
    interface IntrinsicElements {
      "box-icon": any;
    }
  }
}

// Global variables
window.optionsMap = {};
window.skillSetsMap = {};
window.questionsMap = {};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback={<Loader type="ThreeDots" />}>
        <ErrorBoundary>
          <GlobalContextProvider>
            <InitialConfigProvider />
          </GlobalContextProvider>
        </ErrorBoundary>
      </React.Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
