import React, { useEffect } from "react";
import { IUser } from "commons/types/users";
import { getFullName } from "utils/names";
import { v4 as uuidV4 } from "uuid";
import { Channel } from "twilio-chat/lib/channel";
import { CallType } from "commons/types/call";
import IProfile from "commons/types/profile";
import { getDataFromLocalStorage } from "services/localStorage";
import config from "configs";
import useChatHook from "commons/hooks/useChatHook";
import Loader from "commons/components/Loader";
import { Practitioner } from "./MyMentor";

type MentorConversationProps = {
  student: IUser;
  practitioners: Practitioner;
};

const MentorConversation: React.FC<MentorConversationProps> = ({
  student,
  practitioners,
}) => {
  const profileData: IProfile = getDataFromLocalStorage("profile");

  const [channel, setChannel] = React.useState<Channel | never>();
  const { showChatHistoryOfAChannel, chatClient, getStudentMentorChannel } =
    useChatHook();

  useEffect(() => {
    if (chatClient) {
      const getChannel = async () => {
        const response = await getStudentMentorChannel(
          profileData.auth0UserId,
          practitioners.auth0UserId,
          profileData,
          practitioners,
        );
        setChannel(response);
      };
      getChannel();
    }
  }, [chatClient, getStudentMentorChannel, practitioners, profileData]);

  const onVideoCall = (room: string, isAudio: boolean = false) => {
    let messageText: string = `Started a video call with ${getFullName(
      student,
    )}`;
    let callType: string = CallType.VIDEO;
    if (isAudio) {
      messageText = `Started a call with ${getFullName(student)}`;
      callType = CallType.AUDIO;
    }
    const twilioChannel: any = channel;
    if (twilioChannel) {
      twilioChannel.sendMessage(messageText, { type: callType, room: room });
    }
  };

  // FIX THIS
  const startCallWindow = (roomName: string, isAudio: boolean = false) => {
    const caller = profileData.auth0UserId;
    const receiver = channel?.uniqueName.split(",").pop();

    window.open(
      `${
        config.uiPath.call.video
      }?caller=${caller}&receiver=${receiver}&peer=${""}&initiator=me&roomName=${roomName}&isAudio=${isAudio}`,
      "window",
      "toolbar=no, menubar=no, resizable=yes, fullscreen=yes",
    );
  };

  const handleChatHistory = () => {
    showChatHistoryOfAChannel(practitioners.auth0UserId, practitioners);
  };

  if (channel) {
    return (
      <div className="message__title tw-border-b-0">
        <div className="message-actions tw-space-x-4">
          <span
            className="action-links link link-item"
            onClick={handleChatHistory}
          >
            <box-icon name="chat" />
          </span>
          <span
            onClick={() => {
              const roomName = uuidV4();
              onVideoCall(roomName, true);
              startCallWindow(roomName, true);
            }}
            className="action-links link link-item"
          >
            <box-icon name="phone-call"></box-icon>
          </span>
          <span
            onClick={async () => {
              const roomName = uuidV4();
              onVideoCall(roomName);
              startCallWindow(roomName);
            }}
            className="action-links link link-item"
          >
            <box-icon name="video"></box-icon>
          </span>
        </div>
      </div>
    );
  }

  return <Loader type="ThreeDots" />;
};

export default MentorConversation;
