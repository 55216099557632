import React from "react";
import Modal from "react-modal";

import EditCenter from "./../edit/edit-center";
import DeleteConfirm from "commons/components/DeleteConfirm";

const CentersActionCell = ({ row, onDeleteCenter, onEditCenter }: any) => {
  const [isEditOpen, setEditOpen] = React.useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false);

  React.useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  const getInitialValues = () => {
    return {
      _id: row.original._id,
      name: row.original.name,
      address: row.original.address,
      city: row.original.city,
      state: row.original.state,
      zipCode: row.original.zipCode,
    };
  };

  const handleDeleteCenter = async () => {
    onDeleteCenter(row.original._id);

    setIsDeleteConfirmationOpen(false);
  };

  return (
    <>
      <div className="action-group">
        <span
          className="link-item mr-2x"
          onClick={() => {
            setEditOpen(true);
          }}
        >
          <box-icon name="edit-alt" color="#959FAE" />
        </span>
        <span
          className="link-item mr-2x"
          onClick={() => {
            setIsDeleteConfirmationOpen(true);
          }}
        >
          <box-icon name="trash-alt" color="#959FAE" />
        </span>
      </div>
      <Modal isOpen={isEditOpen} className="modal-block">
        <EditCenter
          initialValue={getInitialValues()}
          closeModal={() => setEditOpen(false)}
          updateCenter={onEditCenter}
        />
      </Modal>
      <Modal isOpen={isDeleteConfirmationOpen} className="modal-block">
        <DeleteConfirm
          closeModal={() => setIsDeleteConfirmationOpen(false)}
          message={{
            header: "Do you want to delete this center?",
            body: "This action is not reversible",
          }}
          deleteResource={handleDeleteCenter}
        />
      </Modal>
    </>
  );
};

export default CentersActionCell;
