import "boxicons";
import Modal from "react-modal";
import { v4 as uuidV4 } from "uuid";
import classNames from "classnames";
import { connect } from "react-redux";
import { Channel } from "twilio-chat/lib/channel";
import React, { useCallback, useEffect, useState } from "react";
import Select, { OptionTypeBase, StylesConfig, components } from "react-select";

import config from "configs";
import PlansList from "./PlansList";
import { error } from "utils/toast";
import AddTask from "pages/tasks/add";
import AddGoal from "pages/goals/add";
import eventBus from "utils/event-bus";
import ReportModal from "./ReportModal";
import * as uowService from "services/uow";
import { IUser } from "commons/types/users";
import ProfileActions from "./ProfileActions";
import { CallType } from "commons/types/call";
import Loader from "commons/components/Loader";
import { IPlansProps } from "./plans.interface";
import { getCurrentEnrollment } from "utils/user";
import AddAssignment from "pages/assignments/add";
import useChatHook from "commons/hooks/useChatHook";
import { IGoal as ITask } from "commons/types/goals";
import { updateMany, deleteMany } from "services/uow";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import useMountedRef from "commons/hooks/useMountedRef";
import { getFullName, getNameInitials } from "utils/names";
import { fetchStudent } from "store/actions/data/students";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { fetchPlanningPeriods } from "services/planningPeriods";
import { getDataFromLocalStorage } from "services/localStorage";
import {
  getDateInFormat,
  isDateInRange,
  getDateMonthInFormat,
} from "utils/dates";
import {
  addTask,
  updateTask,
  deleteTask,
  markAsIncomplete,
} from "store/actions/data/tasks";
import { IPriority } from "commons/types/plans";
import NotEnrolledError from "commons/components/errors/not-enrolled-error";
import TermNotEnrolledError from "pages/layouts/mentor/TermNotEnrolledError";
import MissingPlanningPeriodsError from "commons/components/errors/missing-planning-periods-error";
import { UowDeleteEditType } from "../../../constants";
import { subdomainName } from "utils/http";
import TodaysProgress from "./TodaysProgress";
import { getNextMentoringSchedule } from "./utils";
import { isStudentLoggedIn } from "utils/window";

const { Option } = components;

const DEFAULT_WEEK_GROUPS = {
  Monday: {
    checked: true,
    tasks: [],
  },
  Tuesday: {
    checked: false,
    tasks: [],
  },
  Wednesday: {
    checked: false,
    tasks: [],
  },
  Thursday: {
    checked: false,
    tasks: [],
  },
  Friday: {
    checked: false,
    tasks: [],
  },
  Saturday: {
    checked: false,
    tasks: [],
  },
  Sunday: {
    checked: false,
    tasks: [],
  },
};

const DEFAULT_PLANNING_PERIOD = {
  _id: "",
  startDate: new Date(),
  endDate: new Date(),
};

const customFilterStyle: StylesConfig<OptionTypeBase, false> = {
  control: () => ({
    display: "flex",
    padding: 0,
    justifyContent: "space-between",
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  valueContainer: () => ({
    width: 120,
    position: "relative",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
};

const Plans: React.FC<IPlansProps> = ({
  match: {
    params: { id },
  },
  addTask,
  student,
  updateTask,
  deleteTask,
  fetchStudent,
  markAsIncomplete,
  fetchStudentLoading,
}) => {
  const profileData: IUser = getDataFromLocalStorage("profile");

  const { showChatHistoryOfAChannel } = useChatHook();
  const isMounted = useMountedRef();

  const [draftTasks, setDraftTasks] = useState([]);
  const [channel, setChannel] = useState<Channel | never>();
  const [isAddGoalOpen, setIsAddGoalOpen] = useState(false);
  const [reportModal, setReportModal] = React.useState(false);
  const [currentWeekTasks, setCurrentWeekTasks] = useState([]);
  const [onTheHorizonTasks, setOnTheHorizonTasks] = useState([]);
  const [isAddSessionOpen, setIsAddSessionOpen] = useState(false);
  const [fetchTasksLoading, setFetchTasksLoading] = useState(true);
  const [isPlanningFetched, setIsPlanningFetched] = useState(false);
  const [isAddTaskOpen, setIsAddTaskOpen] = useState<boolean>(false);
  const [isProfileActionOpen, setProfileActionOpen] = useState(false);
  const [isAddAssignmentOpen, setIsAddAssignmentOpen] = useState(false);
  const [planningPeriods, setPlanningPeriods] = useState<IPlanningPeriod[]>([]);
  const [activePlanningPeriod, setActivePlanningPeriod] =
    React.useState<IPlanningPeriod>(DEFAULT_PLANNING_PERIOD);
  const [showMissingPlanningPeriodsError, setShowMissingPlanningPeriodsError] =
    useState(false);

  const [activeTerm, setActiveTerm] = React.useState("");

  const setCurrentChannel = async (data: any) => {
    setChannel(data.channel);
  };

  useEffect(() => {
    eventBus.on("chatChannelChanged", setCurrentChannel);

    return () => {
      eventBus.remove("chatChannelChanged", setCurrentChannel);
    };
  }, []);

  const onVideoCall = (room: string, isAudio: boolean = false) => {
    let messageText: string = `Started a video call with ${getFullName(
      student,
    )}`;
    let callType: string = CallType.VIDEO;
    if (isAudio) {
      messageText = `Started a call with ${getFullName(student)}`;
      callType = CallType.AUDIO;
    }
    const twilioChannel: any = channel;
    if (twilioChannel)
      twilioChannel.sendMessage(messageText, { type: callType, room: room });
  };

  // FIX THIS
  const startCallWindow = (roomName: string, isAudio: boolean = false) => {
    const caller = profileData.auth0UserId;
    const receiver = channel?.uniqueName.split(",").pop();

    window.open(
      `${
        config.uiPath.call.video
      }?caller=${caller}&receiver=${receiver}&peer=${""}&initiator=me&roomName=${roomName}&isAudio=${isAudio}`,
      "window",
      "toolbar=no, menubar=no, resizable=yes, fullscreen=yes",
    );
  };

  useEffect(() => {
    if (!isStudentLoggedIn()) {
      !channel &&
        student.auth0UserId &&
        showChatHistoryOfAChannel(student.auth0UserId, student);
    }
  }, [student, showChatHistoryOfAChannel, channel]);

  const handleBulkTaskUpdate = async (payload: any) => {
    try {
      const updateResult = await updateMany(payload);
      if (updateResult && isMounted) {
        fetchTasks();
        return updateResult;
      }
    } catch (err: any) {
      error(err);
    }
  };

  const handleBulkTaskDelete = async (ids: string[]) => {
    try {
      const deleteResult = await deleteMany(ids);
      if (deleteResult) {
        fetchTasks();
      }

      return deleteResult;
    } catch (err: any) {
      error(err);
    }
  };

  const fetchPlans = useCallback(
    async (term) => {
      if (student?._id && term) {
        setIsPlanningFetched(false);
        try {
          const planningPeriod = await fetchPlanningPeriods({
            user: student._id,
            term: term,
          });
          if (isMounted) {
            setPlanningPeriods(planningPeriod);
            if (!planningPeriod.length)
              setShowMissingPlanningPeriodsError(true);
            else setShowMissingPlanningPeriodsError(false);

            const activePlanningPeriod: IPlanningPeriod =
              planningPeriod.find((planningPeriod: IPlanningPeriod) =>
                isDateInRange(
                  planningPeriod.startDate,
                  planningPeriod.endDate,
                  new Date(),
                ),
              ) || DEFAULT_PLANNING_PERIOD;

            setActivePlanningPeriod(activePlanningPeriod);
          }
        } catch (err: any) {
          error(err);
        }
      }
      setIsPlanningFetched(true);
    },
    [student._id, isMounted],
  );

  useEffect(() => {
    if (isMounted) {
      fetchPlans(activeTerm);
    }
  }, [fetchPlans, activeTerm, isMounted]);

  const fetchTasks = useCallback(async () => {
    if (!isPlanningFetched) return;

    setFetchTasksLoading(true);
    let startDate = null;
    let endDate = null;

    startDate = new Date(activePlanningPeriod?.startDate);
    endDate = new Date(activePlanningPeriod?.endDate);

    const today = new Date();
    const yesterday = new Date();
    const weekAhead = new Date();

    yesterday.setDate(today.getDate() - 1);
    weekAhead.setDate(today.getDate() + 7);

    try {
      const currentWeekTasksPromise = uowService
        .fetchAll({
          assignee: id,
          uowType: "Task",
          startDate: startDate.toISOString() || today.toISOString(),
          endDate: endDate.toISOString() || weekAhead.toISOString(),
          isPublished: true,
        })
        .then((response) => response.data);

      const draftTasksPromise = uowService
        .fetchAll({
          assignee: id,
          uowType: "Task",
          isPublished: false,
          isCompleted: false,
        })
        .then((response) => response.data);

      const onTheHorizonTasksPromise = uowService
        .fetchAll({
          assignee: id,
          uowType: "Task",
          startDate: endDate ? endDate.toISOString() : weekAhead.toISOString(),
          isPublished: true,
        })
        .then((response) => response.data);

      const [currentWeekTasks, draftTasks, onTheHorizonTasks] =
        await Promise.all([
          currentWeekTasksPromise,
          draftTasksPromise,
          onTheHorizonTasksPromise,
        ]);

      setCurrentWeekTasks(currentWeekTasks);
      setDraftTasks(draftTasks);
      setOnTheHorizonTasks(
        onTheHorizonTasks.filter(
          (task: ITask) => task?.workData?.priority === IPriority.priority,
        ),
      );
    } catch (err: any) {
      error("Failed to fetch tasks");
    }
    setFetchTasksLoading(false);
  }, [
    id,
    isPlanningFetched,
    activePlanningPeriod?.endDate,
    activePlanningPeriod?.startDate,
  ]);

  const updateTaskHandler = async (id: string, task: any) => {
    task.termId = activeTerm;
    const updatedTask = await updateTask(id, task);

    if (updatedTask && isMounted) {
      fetchTasks();
    }
    return updatedTask;
  };

  const deleteTaskHandler = async (
    id: string,
    type: UowDeleteEditType = UowDeleteEditType.SELF,
  ) => {
    const deletedTask = await deleteTask(id, type);

    if (deletedTask && isMounted) {
      fetchTasks();
    }
    return deletedTask;
  };

  const addTaskHandler = async (data: ITask) => {
    data.termId = activeTerm;
    const task = await addTask(data);

    if (task && isMounted) {
      setIsAddTaskOpen(false);
      fetchTasks();
    }
    return task;
  };

  const markAsIncompleteHandler = async (id: string) => {
    const inCompletedTask = await markAsIncomplete(id);

    if (inCompletedTask && isMounted) {
      fetchTasks();
    }
    return inCompletedTask;
  };

  const generateInitialWeeks = () => {
    let weekGroups: any = {};

    const getDates = (startDate: Date, endDate: Date) => {
      const dates = [];
      let currentDate = startDate;
      const addDays = (prevDate: Date, days: number) => {
        const date = new Date(prevDate.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

      while (currentDate <= endDate && dates.length < 7) {
        dates.push(currentDate);
        currentDate = addDays(currentDate, 1);
      }

      return dates;
    };

    if (activePlanningPeriod) {
      getDates(
        new Date(activePlanningPeriod.startDate),
        new Date(activePlanningPeriod.endDate),
      ).forEach((plan) => {
        weekGroups[`${getDateInFormat(plan, "MM-DD-YYYY").toString()}`] = {
          checked: false,
          tasks: [],
          date: plan,
        };
      });
    } else {
      weekGroups = DEFAULT_WEEK_GROUPS;
    }

    return weekGroups;
  };

  useEffect(() => {
    fetchStudent(id);
  }, [id, fetchStudent]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    if (student.userData?.enrollments?.length && student._id === id) {
      const currentEnrollment = student.userData?.enrollments.find(
        (enrollment) => enrollment.enrolledTerm?.isCurrent,
      );
      if (currentEnrollment?.enrolledTerm?._id) {
        setActiveTerm(currentEnrollment.enrolledTerm?._id);
        return;
      }
    }
  }, [student.userData?.enrollments, id, student._id]);

  const activeEnrollment = student.userData?.enrollments?.filter(
    (enrollment) => enrollment.status !== "Draft",
  );

  const generateOption = () => {
    if (activeEnrollment) {
      const activeEnrollmentWithTerms = activeEnrollment.filter(
        (enrollment) => enrollment.enrolledTerm,
      );
      return activeEnrollmentWithTerms.map((enrollment) => {
        return {
          label: enrollment.enrolledTerm.name,
          value: enrollment.enrolledTerm._id,
        };
      });
    }
    return [];
  };

  const getActiveTermOption = () => {
    if (activeEnrollment && activeTerm) {
      return generateOption().find((option) => option.value === activeTerm);
    }
    return { label: "Please select", value: "" };
  };

  const viewReport = () => setReportModal(true);
  const closeReport = () => setReportModal(false);
  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);
  const showNotEnrolledError =
    !fetchStudentLoading &&
    (!student.userData?.status || student.userData?.status === "Unenrolled");

  const currentPlanIndex = planningPeriods.findIndex(
    (planningPeriod) =>
      planningPeriod?.startDate.toString() ===
      activePlanningPeriod?.startDate.toString(),
  );

  return (
    <>
      <LeftPanel activeTab="Plans" student={student} />
      <div className="content-panel">
        {showNotEnrolledError ? (
          <NotEnrolledError student={student} />
        ) : isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : showMissingPlanningPeriodsError ? (
          <MissingPlanningPeriodsError student={student} />
        ) : null}
        {isStudentLoggedIn() ? (
          <div>
            <div className="tw-flex tw-justify-between tw-items-center">
              <h1>My Plan</h1>
              <button
                onClick={() => setProfileActionOpen(true)}
                className="btn btn--primary mr-4x"
              >
                Add
              </button>
            </div>
            <TodaysProgress
              tasks={currentWeekTasks}
              nextMentoringSession={
                activePlanningPeriod?.startDate
                  ? getNextMentoringSchedule(
                      activePlanningPeriod.startDate.toString(),
                    )
                  : undefined
              }
            />
          </div>
        ) : (
          <>
            <div className="page-heading">
              <div className="page-heading__left">
                <div className="avatar">
                  {student.avatar ? (
                    <img src={student.avatar} alt="Student" />
                  ) : (
                    getNameInitials(student)
                  )}{" "}
                </div>
                <div className="heading-detail">
                  <span className="text-light-sm fs-14">
                    Student Profile | {subdomainName} Learning
                  </span>
                  <h2>{getFullName(student)}</h2>
                  <ul
                    className={classNames("grade-info", {
                      "round round--horizontal":
                        student?.userData?.schoolInfo?.grade &&
                        student?.userData?.schoolInfo?.institution?.name,
                    })}
                  >
                    <li className="">
                      {student?.userData?.schoolInfo?.grade
                        ? `Grade ${student?.userData?.schoolInfo?.grade}`
                        : null}
                    </li>
                    <li>
                      <span className="link-item link-text">
                        {student?.userData?.schoolInfo?.institution?.name}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-heading__right align-items-center">
                {channel ? (
                  <>
                    <span
                      onClick={async () => {
                        const roomName = uuidV4();
                        onVideoCall(roomName, true);
                        startCallWindow(roomName, true);
                      }}
                      className="primary action-links mt-2x mr-4x link link-item"
                    >
                      <box-icon name="phone-call" />
                    </span>
                    <span
                      onClick={async () => {
                        const roomName = uuidV4();
                        onVideoCall(roomName);
                        startCallWindow(roomName);
                      }}
                      className="primary action-links mt-2x mr-4x link link-item"
                    >
                      <box-icon name="video" />
                    </span>
                  </>
                ) : (
                  <span className="mt-2x mr-4x">
                    <Loader height={20} type="ThreeDots" />
                  </span>
                )}

                <button
                  onClick={() => setProfileActionOpen(true)}
                  className="btn btn--primary mr-4x"
                >
                  Add
                </button>
                <button
                  className="btn p-0x mt-2x"
                  title="View Report"
                  onClick={viewReport}
                >
                  <box-icon
                    name="cloud-download"
                    class="icon--green"
                  ></box-icon>
                </button>
              </div>
            </div>
            <div className="section-heading border-bottom mb-0x">
              <h3>Plans</h3>
            </div>
          </>
        )}
        <div className="filters border-bottom">
          <div className="term-select">
            <ul className="filters__list">
              <li>
                <Select
                  name="term"
                  onChange={(selected: any) => {
                    setActiveTerm(selected.value);
                  }}
                  value={getActiveTermOption()}
                  isSearchable={false}
                  onBlur={() => {}}
                  options={generateOption()}
                  className="filter-item link-item"
                  classNamePrefix="react-select"
                  styles={customFilterStyle}
                  components={{
                    Option: (props) => {
                      return (
                        <Option {...props}>
                          <span>{props.label}</span>
                        </Option>
                      );
                    },
                  }}
                />
              </li>
            </ul>
            <ul className="filters__list mt-1x ml-3x">
              <li
                className="link-item mt-1x"
                onClick={() => {
                  if (planningPeriods.length && currentPlanIndex > 0) {
                    setActivePlanningPeriod(
                      planningPeriods[currentPlanIndex - 1],
                    );
                  }
                }}
              >
                {planningPeriods.length && currentPlanIndex > 0 ? (
                  <box-icon name="chevron-left" />
                ) : (
                  <box-icon name="chevron-left" color="#959FAE" />
                )}
              </li>
              <li>
                <span className="txt-darkgrey-color fs-medium txt-bold">
                  <Select
                    classNamePrefix="react-select"
                    value={{
                      label: `Week of ${getDateMonthInFormat(
                        activePlanningPeriod?.startDate,
                      )} - ${getDateInFormat(activePlanningPeriod?.endDate)}`,
                      value: activePlanningPeriod?.startDate.toString(),
                    }}
                    onChange={(selected) => {
                      setActivePlanningPeriod(
                        planningPeriods.find(
                          (planningPeriod) =>
                            planningPeriod.startDate.toString() ===
                            selected?.value,
                        ) || DEFAULT_PLANNING_PERIOD,
                      );
                    }}
                    options={planningPeriods.map((planningPeriod) => {
                      return {
                        label: `${getDateMonthInFormat(
                          planningPeriod.startDate,
                        )} - ${getDateInFormat(planningPeriod.endDate)}`,
                        value: planningPeriod.startDate.toString(),
                      };
                    })}
                    isSearchable={false}
                    styles={{
                      dropdownIndicator: () => ({
                        display: "none",
                      }),
                      control: () => ({}),
                      indicatorSeparator: () => ({
                        display: "none",
                      }),
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      singleValue: () => ({
                        background: "none",
                        display: "flex",
                        padding: "2px",
                        cursor: "pointer",
                      }),
                    }}
                    components={{
                      Option: (props) => (
                        <Option {...props}>
                          <box-icon
                            name="calendar"
                            size="14px"
                            color="#1d70b8"
                          />
                          <span className="ml-2x">{props.data.label}</span>
                        </Option>
                      ),
                    }}
                  />
                </span>
              </li>
              <li
                className="link-item mt-1x"
                onClick={() => {
                  if (
                    planningPeriods.length &&
                    currentPlanIndex < planningPeriods.length - 1
                  ) {
                    setActivePlanningPeriod(
                      planningPeriods[currentPlanIndex + 1],
                    );
                  }
                }}
              >
                {planningPeriods.length &&
                currentPlanIndex < planningPeriods.length - 1 ? (
                  <box-icon name="chevron-right" />
                ) : (
                  <box-icon name="chevron-right" color="#959FAE" />
                )}
              </li>
            </ul>
          </div>

          {/* <ul className="filters__list">
        <li>
          <span className="filter-item">
            To-do Date
            <box-icon name="chevron-down"></box-icon>
          </span>
        </li>
        <li>
          <span className="filter-item-icon">
            <box-icon name="list-ul"></box-icon>
          </span>
        </li>
        <li>
          <span className="filter-item-icon">
            <box-icon name="calendar-week"></box-icon>
          </span>
        </li>
      </ul> */}
        </div>
        <PlansList
          planningPeriods={planningPeriods}
          student={student}
          draftTasks={draftTasks}
          saveTask={addTaskHandler}
          deleteTask={deleteTaskHandler}
          updateTask={updateTaskHandler}
          currentWeekTasks={currentWeekTasks}
          fetchTasksLoading={fetchTasksLoading}
          onTheHorizonTasks={onTheHorizonTasks}
          markAsIncomplete={markAsIncompleteHandler}
          handleBulkTaskUpdate={handleBulkTaskUpdate}
          handleBulkTaskDelete={handleBulkTaskDelete}
          openAddTask={() => setIsAddTaskOpen(true)}
          initialWeeks={generateInitialWeeks()}
          showChatHistoryOfAChannel={showChatHistoryOfAChannel}
        />
        <Modal
          isOpen={isAddTaskOpen}
          className="modal-block-task"
          ariaHideApp={false}
          onRequestClose={() => setIsAddTaskOpen(false)}
        >
          <AddTask
            goal={{ assignee: { _id: id } }}
            saveTask={addTaskHandler}
            closeModal={() => setIsAddTaskOpen(false)}
            student={student}
          />
        </Modal>

        <Modal
          isOpen={isAddGoalOpen}
          className="modal-block"
          ariaHideApp={false}
          onRequestClose={() => setIsAddGoalOpen(false)}
        >
          <AddGoal
            closeModal={() => setIsAddGoalOpen(false)}
            student={student}
          />
        </Modal>

        <Modal
          isOpen={isAddSessionOpen}
          className="modal-block"
          ariaHideApp={false}
          onRequestClose={() => setIsAddSessionOpen(false)}
        >
          Session coming soon...
        </Modal>

        <Modal
          isOpen={isAddAssignmentOpen}
          className="modal-block"
          ariaHideApp={false}
          onRequestClose={() => setIsAddAssignmentOpen(false)}
        >
          <AddAssignment
            closeModal={() => setIsAddAssignmentOpen(false)}
            student={student}
          />
        </Modal>

        <Modal
          className="modal-block"
          isOpen={isProfileActionOpen}
          ariaHideApp={false}
          onRequestClose={() => setProfileActionOpen(false)}
        >
          <ProfileActions
            taskClickHandler={() => {
              setIsAddTaskOpen(true);
              setProfileActionOpen(false);
            }}
            goalClickHandler={() => {
              setIsAddGoalOpen(true);
              setProfileActionOpen(false);
            }}
            sessionClickHandler={() => {
              setIsAddSessionOpen(true);
              setProfileActionOpen(false);
            }}
            assignmentClickHandler={() => {
              setIsAddAssignmentOpen(true);
              setProfileActionOpen(false);
            }}
            onClose={() => setProfileActionOpen(false)}
          />
        </Modal>
      </div>
      {reportModal ? (
        <ReportModal student={student} closeModal={closeReport} />
      ) : null}
    </>
  );
};

interface State {
  tasks: { fetchTasks: ITask };
  students: { fetchStudent: IUser; fetchStudentLoading: boolean };
}

const mapStateToProps = (state: State) => ({
  tasks: state.tasks.fetchTasks,
  student: state.students.fetchStudent,
  fetchStudentLoading: state.students.fetchStudentLoading,
});

const mapDispatchToProps = {
  addTask,
  updateTask,
  deleteTask,
  fetchStudent,
  markAsIncomplete,
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
