import { FC, ReactElement } from "react";
import { getFieldValue } from "utils/arrays";
import Select, { OptionTypeBase, StylesConfig } from "react-select";

interface IOptions {
  label: string;
  value: string;
}

interface ISelectProps {
  label: string;
  name: string;
  isMulti?: boolean;
  options: any[];
  errors: any;
  touched: any;
  value?: IOptions;
  handleChange: any;
  handleBlur?: any;
  isDisabled?: boolean;
  customStyle?: StylesConfig<OptionTypeBase, false>;
  required?: boolean;
}

const CustomSelect: FC<ISelectProps> = ({
  label,
  isMulti,
  name,
  value,
  options,
  touched,
  handleChange,
  handleBlur,
  errors,
  customStyle = {},
  isDisabled = false,
  required = false,
}): ReactElement => {
  /**
   * Handles selected value
   * @param OptionTypeBase
   */
  const handleSelect: any = (value: OptionTypeBase) => {
    handleChange(
      value,
      isMulti
        ? value.map((selectedValue: IOptions) => selectedValue.value)
        : value.value,
    );
  };

  return (
    <div className="input-wrap">
      <label className={`input__label ${required ? "required" : null}`}>
        {label}
      </label>
      <Select
        placeholder={`Please select ${label}`}
        value={value}
        isMulti={isMulti || false}
        options={options}
        onChange={handleSelect}
        onBlur={handleBlur}
        menuPlacement="auto"
        isDisabled={isDisabled}
        styles={
          getFieldValue(errors, name) && getFieldValue(touched, name)
            ? {
                ...customStyle,
                control: (styles) => ({
                  ...styles,
                  borderColor: "#f82b60",
                }),
              }
            : customStyle
        }
        classNamePrefix="react-select"
      />
      {getFieldValue(errors, name) && getFieldValue(touched, name) && (
        <p className="input__error">{getFieldValue(errors, name)}</p>
      )}
    </div>
  );
};

export default CustomSelect;
