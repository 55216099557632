import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { IUser } from "commons/types/users";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { getFullName } from "utils/names";
import { getCurrentEnrollment } from "utils/user";
import { LEARNING_ENVIRONMENTS_OPTIONS } from "constants/index";
import { fetchStudent } from "store/actions/data/students";
import { subdomainName } from "utils/http";
import { ICourses, ICoursesParams } from "commons/types/courses";
import {
  deleteCourse as deleteCourseAction,
  fetchCourses,
} from "store/actions/data/courses";
import Modal from "react-modal";
import AddCourse from "pages/courses/add/AddCourse";
import UpdateCourse from "pages/courses/update/UpdateCourse";
import DeleteConfirm from "pages/courses/delete/DeleteConfirm";
import ChangeEmailAddress from "./ChangeEmailAddress";

type StudentPersonalInfoProps = {
  student: IUser;
  match: { params: { id: string } };
  fetchStudent: (id: string) => Promise<IUser>;
  courses: ICourses[];
  fetchCourses: (params: ICoursesParams) => any;
  deleteCourseAction: (id: string) => any;
};

const StudentPersonalInfo: React.FC<StudentPersonalInfoProps> = ({
  match: {
    params: { id },
  },
  student,
  fetchStudent,
  courses,
  fetchCourses,
  deleteCourseAction,
}) => {
  const [actionCourse, setActionCourse] = useState<ICourses>();
  const [isEditCourseOpen, setEditCourseOpen] = useState<boolean>(false);
  const [isDeleteCourseOpen, setDeleteCourseOpen] = useState<boolean>(false);
  const [isAddCourseOpen, setAddCourseOpen] = useState<boolean>(false);
  const [isChangeEmailOpen, setIsChangeEmailOpen] = useState<boolean>(false);

  const { academicYear, academicTerm }: any =
    student.userData?.schoolInfo || {};

  useEffect(() => {
    fetchStudent(id);
  }, [id, fetchStudent]);

  useEffect(() => {
    fetchCourses({
      userId: student._id,
      termId: getCurrentEnrollment(student)?.enrolledTerm?._id,
    });
  }, [fetchCourses, student]);

  const deleteCourse = async () => {
    if (actionCourse) {
      await deleteCourseAction(actionCourse?._id);
      fetchCourses({
        userId: student._id,
        termId: getCurrentEnrollment(student)?.enrolledTerm?._id,
      });
      setDeleteCourseOpen(false);
    }
  };

  const StudentInfo = () => {
    return (
      <>
        <section className="section ml-10x">
          <h1>My Profile</h1>
          <div className="section__title mb-0x tw-mt-10">
            <h3 className="txt-darkgrey-color">My Information</h3>
          </div>
          <div className="section__content">
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Student name</span>
                  <span className="d-block">{getFullName(student)}</span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Nickname</span>
                  <span className="d-block">
                    {student.userData?.nickName || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Email address</span>
                  <span className="d-block">{student.email}</span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Grade</span>
                  <span className="d-block">
                    {student?.userData?.schoolInfo?.grade
                      ? `Grade ${student?.userData?.schoolInfo?.grade}`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Academic year</span>
                  <span className="d-block">
                    {academicYear || " "}{" "}
                    {academicTerm ? `(${academicTerm})` : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">{subdomainName} term</span>
                  <span className="d-block">
                    {getCurrentEnrollment(student)?.enrolledTerm?.name || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">Learning environment</span>
                  <span className="d-block">
                    {student.userData?.learningEnvironment
                      ? LEARNING_ENVIRONMENTS_OPTIONS.find(
                          (environment: any) =>
                            environment.value ===
                            student.userData?.learningEnvironment,
                        )?.label || "-"
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <span className="card-address">{subdomainName} location</span>
                  <span className="d-block">
                    {getCurrentEnrollment(student)?.learningCenter?.name || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Courses</h3>
            <div className="actions">
              {courses?.length ? (
                <span
                  className="link-item"
                  onClick={() => setAddCourseOpen(true)}
                >
                  <box-icon name="plus-circle" type="solid" />
                </span>
              ) : null}
            </div>
          </div>
          <div className="section__content">
            {Array.isArray(courses) && courses.length ? (
              courses.map((course: ICourses, index) => (
                <div className="card card-addinfo" key={index}>
                  <div className="card-content">
                    <div className="card-text">
                      <div className="card-address">
                        Teacher: {course.teacher}
                      </div>
                      <div className="d-block info-value">{course.name}</div>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionCourse(course);
                        setEditCourseOpen(true);
                      }}
                    >
                      <box-icon name="edit-alt" />
                    </span>
                    <span
                      className="link-item"
                      onClick={() => {
                        setActionCourse(course);
                        setDeleteCourseOpen(true);
                      }}
                    >
                      <box-icon name="trash-alt" />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <span className="add-new-text">Please add Course</span>
                      <span
                        className="add-new link-item"
                        onClick={() => setAddCourseOpen(true)}
                      >
                        <box-icon name="plus" /> Add Course
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <section className="section ml-10x">
          <div className="section__title mb-0x">
            <h3 className="txt-darkgrey-color">Settings</h3>
          </div>
          <div className="section__content">
            <div className="card card-addinfo">
              <div className="card-content">
                <div className="card-text">
                  <div className="card-address">Email Address</div>
                  <div className="d-block info-value">{student.email}</div>
                </div>
              </div>
              <div className="card-actions">
                <span
                  className="link-item"
                  onClick={() => {
                    setIsChangeEmailOpen(true);
                  }}
                >
                  <box-icon name="edit-alt" />
                </span>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <LeftPanel activeTab="Personal Info" student={student} />
      <div className="content-panel">
        <StudentInfo />
      </div>
      <Modal
        isOpen={isAddCourseOpen}
        onRequestClose={() => setAddCourseOpen}
        className="modal-block"
      >
        <AddCourse
          student={student}
          closeModal={() => setAddCourseOpen(false)}
        />
      </Modal>
      {actionCourse ? (
        <Modal
          isOpen={isEditCourseOpen}
          onRequestClose={() => setEditCourseOpen(false)}
          className="modal-block"
        >
          <UpdateCourse
            student={student}
            closeModal={() => setEditCourseOpen(false)}
            course={actionCourse}
          />
        </Modal>
      ) : null}
      <Modal
        isOpen={isDeleteCourseOpen}
        onRequestClose={() => setDeleteCourseOpen(false)}
        className="modal-block"
      >
        <DeleteConfirm
          closeModal={() => setDeleteCourseOpen(false)}
          deleteCourse={deleteCourse}
        />
      </Modal>
      <Modal
        isOpen={isChangeEmailOpen}
        onRequestClose={() => setIsChangeEmailOpen(false)}
        className="modal-block"
      >
        <ChangeEmailAddress
          closeModal={() => setIsChangeEmailOpen(false)}
          userId={id}
          email={student.email}
          fetchStudent={() => fetchStudent(id)}
        />
      </Modal>
    </>
  );
};

interface State {
  students: { fetchStudent: IUser };
  courses: { fetchCourses: ICourses[] };
}

const mapStateToProps = (state: State) => ({
  student: state.students.fetchStudent,
  courses: state.courses.fetchCourses,
});

const mapDispatchToProps = {
  fetchStudent,
  fetchCourses,
  deleteCourseAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentPersonalInfo);
