import "boxicons";
import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import AddAssignment from "../add";
import Assignments from "./Assignments";
import { IUser } from "commons/types/users";
import { getCurrentEnrollment } from "utils/user";
import { fetchStudent } from "store/actions/data/students";
import LeftPanel from "pages/layouts/mentor/leftPanel";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import TermNotEnrolledError from "pages/layouts/mentor/TermNotEnrolledError";

type MentorAssignmentsProps = {
  student: IUser;
  match: { params: { id: string } };
  fetchStudentLoading: boolean;
  fetchStudent: (id: string) => Promise<IUser>;
};

const MentorAssignments: React.FC<MentorAssignmentsProps> = ({
  match: {
    params: { id },
  },
  student,
  fetchStudentLoading,
  fetchStudent,
}): JSX.Element => {
  const { path } = useRouteMatch();
  useEffect(() => {
    fetchStudent(id);
  }, [id, fetchStudent]);

  const [isAddAssignmentModalOpen, setAddAssignmentModalOpen] =
    useState<boolean>(false);
  const openAddAssignmentModal = () => {
    setAddAssignmentModalOpen(true);
  };

  const closeAddAssignmentModal = () => {
    setAddAssignmentModalOpen(false);
  };

  const isShowNotEnrolledAlert =
    !fetchStudentLoading && student && !getCurrentEnrollment(student);

  return (
    <>
      <LeftPanel activeTab={"Assignments"} student={student} />
      <div className="content-panel">
        {isShowNotEnrolledAlert ? (
          <TermNotEnrolledError student={student} />
        ) : null}
        <Switch>
          <Route exact path={path}>
            <Assignments
              id={id}
              student={student}
              openAddAssignmentModal={openAddAssignmentModal}
            />
          </Route>
        </Switch>
      </div>
      <Modal
        className="modal-block"
        isOpen={isAddAssignmentModalOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        ariaHideApp={false}
      >
        <AddAssignment closeModal={closeAddAssignmentModal} student={student} />
      </Modal>
    </>
  );
};

interface State {
  students: { fetchStudent: IUser; fetchStudentLoading: boolean };
}

const mapStateToProps = (state: State) => ({
  student: state.students.fetchStudent,
  fetchStudentLoading: state.students.fetchStudentLoading,
});

const mapDispatchToProps = {
  fetchStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentorAssignments);
