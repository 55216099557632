import {
  AddSchoolDistrictActionType,
  DeleteSchoolDistrictActionType,
  FetchSchoolDistrictActionType,
  FetchSchoolDistrictsActionType,
  UpdateSchoolDistrictActionType,
} from "store/actions/types/schoolDistricts";

const INITIAL_STATE = {
  addSchoolDistrict: {},
  addSchoolDistrictLoading: false,
  addSchoolDistrictError: null,
  fetchSchoolDistricts: [],
  fetchSchoolDistrictsLoading: false,
  fetchSchoolDistrictsError: null,
  fetchSchoolDistrict: [],
  fetchSchoolDistrictLoading: false,
  fetchSchoolDistrictError: null,
  updateSchoolDistrict: {},
  updateSchoolDistrictLoading: false,
  updateSchoolDistrictError: null,
  deleteSchoolDistrict: {},
  deleteSchoolDistrictLoading: false,
  deleteSchoolDistrictError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddSchoolDistrictActionType.ADD_School_District_PENDING:
      return {
        ...state,
        addSchoolDistrictLoading: true,
        addSchoolDistrictError: null,
      };

    case AddSchoolDistrictActionType.ADD_School_District_FULFILLED:
      return {
        ...state,
        addSchoolDistrict: action.data,
        addSchoolDistrictLoading: false,
        addSchoolDistrictError: null,
      };
    case AddSchoolDistrictActionType.ADD_School_District_REJECTED:
      return {
        ...state,
        addSchoolDistrictLoading: false,
        addSchoolDistrictError: action.data,
      };
    case FetchSchoolDistrictsActionType.FETCH_SCHOOL_DISTRICTS_PENDING:
      return {
        ...state,
        fetchSchoolDistrictsLoading: true,
        fetchSchoolDistrictsError: null,
      };
    case FetchSchoolDistrictsActionType.FETCH_SCHOOL_DISTRICTS_FULFILLED:
      return {
        ...state,
        fetchSchoolDistrictsLoading: false,
        fetchSchoolDistrictsError: null,
        fetchSchoolDistricts: action.data,
      };

    case FetchSchoolDistrictsActionType.FETCH_SCHOOL_DISTRICTS_REJECTED:
      return {
        ...state,
        fetchSchoolDistrictsLoading: false,
        fetchSchoolDistrictsError: action.data,
      };
    case FetchSchoolDistrictActionType.FETCH_SCHOOL_DISTRICT_PENDING:
      return {
        ...state,
        fetchSchoolDistrictLoading: true,
        fetchSchoolDistrictError: null,
      };
    case FetchSchoolDistrictActionType.FETCH_SCHOOL_DISTRICT_FULFILLED:
      return {
        ...state,
        fetchSchoolDistrictLoading: false,
        fetchSchoolDistrictError: null,
        fetchSchoolDistrict: action.data,
      };

    case FetchSchoolDistrictActionType.FETCH_SCHOOL_DISTRICT_REJECTED:
      return {
        ...state,
        fetchSchoolDistrictLoading: false,
        fetchSchoolDistrictError: action.data,
      };
    case UpdateSchoolDistrictActionType.UPDATE_SCHOOL_DISTRICT_PENDING:
      return {
        ...state,
        updateSchoolDistrictLoading: true,
        updateSchoolDistrictError: null,
      };
    case UpdateSchoolDistrictActionType.UPDATE_SCHOOL_DISTRICT_FULFILLED:
      return {
        ...state,
        updateSchoolDistrictLoading: false,
        updateSchoolDistrict: action.data,
        updateSchoolDistrictError: null,
      };
    case UpdateSchoolDistrictActionType.UPDATE_SCHOOL_DISTRICT_REJECTED:
      return {
        ...state,
        updateSchoolDistrictLoading: false,
        updateSchoolDistrictError: action.data,
      };
    case DeleteSchoolDistrictActionType.DELETE_SCHOOL_DISTRICT_PENDING:
      return {
        ...state,
        deleteSchoolDistrictLoading: true,
        deleteSchoolDistrictError: null,
      };
    case DeleteSchoolDistrictActionType.DELETE_SCHOOL_DISTRICT_FULFILLED:
      return {
        ...state,
        deleteSchoolDistrict: action.data,
        deleteSchoolDistrictLoading: false,
        deleteSchoolDistrictError: null,
      };
    case DeleteSchoolDistrictActionType.DELETE_SCHOOL_DISTRICT_REJECTED:
      return {
        ...state,
        deleteSchoolDistrictLoading: false,
        deleteSchoolDistrictError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
