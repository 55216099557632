import { v4 as uuidV4 } from "uuid";
import Twilio, { Room } from "twilio-video";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";

import { error } from "utils/toast";
import VideoRoom from "./VideoRoom";
import pusherInstance from "utils/pusher";
import { EVENT } from "constants/pusher";
import { parse, stringify } from "utils/http";
import { getTwilioVideoConfig } from "services/twilio";
import { notifyCall } from "services/call";
import { getDataFromLocalStorage } from "services/localStorage";

import {
  CallInitiateEvent,
  CallType,
  ICall,
  INotifyCall,
} from "commons/types/call";

interface IVideoChatProps {
  location: {
    search: string;
    pathname: string;
  };
}

const VideoChat: React.FC<IVideoChatProps> = ({ location }) => {
  const history = useHistory();
  const [isConnecting, setIsConnecting] = useState(true);

  const [isAudio, setIsAudio] = useState(false);
  const [room, setRoom] = useState<Room | null>(null);
  const [roomName, setRoomName] = useState<string | null>(null);
  const [peer, setPeer] = useState<string | null>(null);

  const [isReceiverAvailable, setIsReceiverAvailable] = useState(true);
  const [unAvailableMessage, setUnAvailableMessage] = useState("");

  const user = getDataFromLocalStorage("profile");

  // TODO: Call information is important. If these are missing then the call should not be initiated
  const getCallInformation = useCallback(
    (callType: CallInitiateEvent): INotifyCall => {
      const query = parse(location.search);
      const { caller, receiver, initiator, roomName } = query;

      return {
        to: receiver?.toString() || "",
        from: caller?.toString() || "",
        room: roomName?.toString() || "",
        event: callType,
        type: isAudio ? CallType.AUDIO : CallType.VIDEO,
        initiator: initiator?.toString() || "",
        triggeredBy: user.auth0UserId,
      };
    },
    [location.search, isAudio, user.auth0UserId],
  );

  useEffect(() => {
    const initiateVideoCall = async (
      username: string,
      roomName: string,
      peer?: string | null,
    ) => {
      setIsConnecting(true);
      try {
        const { data: twilioVideoConfig } = await getTwilioVideoConfig(
          username,
          roomName,
          peer,
        );

        const room: Room = await Twilio.connect(twilioVideoConfig.token, {
          name: roomName,
        });

        const callInfo = getCallInformation(CallInitiateEvent.CALL_STARTED);

        if (callInfo?.initiator === "me") {
          try {
            delete callInfo.initiator;

            await notifyCall(callInfo);
          } catch (ex: any) {
            setIsReceiverAvailable(false);

            setUnAvailableMessage(ex.response.data.message);
            setIsConnecting(false);
            return;
          }
        }

        setIsReceiverAvailable(true);
        setRoom(room);
        setIsConnecting(false);
      } catch (ex) {
        error("Failed to initiate call");
        setIsConnecting(false);
      }
    };

    if (roomName) {
      initiateVideoCall(
        `${user.firstName} ${user.lastName},${user.auth0UserId}`,
        roomName,
        peer,
      );
    }
  }, [
    roomName,
    peer,
    user.auth0UserId,
    user.firstName,
    user.lastName,
    getCallInformation,
  ]);

  const logoutHandler = useCallback(
    async (shouldNotify: boolean = true) => {
      setRoom((prevRoom: Room | null) => {
        if (prevRoom) {
          prevRoom.localParticipant.videoTracks.forEach((trackPub) => {
            trackPub.track.stop();
          });
          prevRoom.localParticipant.audioTracks.forEach((trackPub) => {
            trackPub.track.stop();
          });
          // # TODO: REMOVE: Used in POC but giving error
          // prevRoom.localParticipant.tracks.forEach((trackPub) => {
          //   trackPub.track.stop();
          // });

          prevRoom.disconnect();
        }
        return null;
      });

      if (shouldNotify) {
        const callInfo = getCallInformation(CallInitiateEvent.CALL_ENDED);
        delete callInfo.initiator;

        try {
          await notifyCall(callInfo);
        } catch (ex) {
          error("Failed to notify call ended");
        }
      }
    },
    [getCallInformation],
  );

  useEffect(() => {
    const query = parse(location.search);
    const peer = query.peer;
    const urlRoomName = query.roomName;

    const isAudio = query.isAudio || false;
    if (!urlRoomName) {
      query.roomName = uuidV4();
      history.push(`${stringify(location.pathname, query)}`);
    } else if (typeof urlRoomName === "string") {
      setRoomName(urlRoomName);
      if (typeof peer === "string") setPeer(peer);

      setIsAudio(isAudio === "true");
    } else {
      error("Invalid room name or peer given");
    }
  }, [location, history]);

  useEffect(() => {
    if (room) {
      const tidyUp = async (event: any) => {
        await logoutHandler();
        if (event.persisted) {
          return;
        }
      };
      window.addEventListener("pagehide", async (e) => {
        await tidyUp(e);
      });
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, logoutHandler]);

  const handleCallRejection = useCallback(
    (data: ICall) => {
      if (user.auth0UserId !== data.triggeredBy) {
        logoutHandler(false);
      }
    },
    [user.auth0UserId, logoutHandler],
  );

  useEffect(() => {
    if (!user._id) {
      return;
    }
    const channel = pusherInstance.subscribeToUserChannel(user._id);

    channel.bind(EVENT.CALL_ENDED, handleCallRejection);

    return () => {
      channel.unbind(EVENT.CALL_ENDED, handleCallRejection);
    };
  }, [user._id, handleCallRejection]);

  return (
    <div className="video-call">
      {room ? (
        <VideoRoom
          room={room}
          isAudio={isAudio}
          logoutHandler={logoutHandler}
        />
      ) : (
        <div className="video-call__body">
          {isConnecting ? (
            <div className="info">
              <h1>Initiating call...</h1>
            </div>
          ) : (
            <div className="info">
              <div className="main-icon">
                <box-icon
                  size="56px"
                  color="#959FAE"
                  name="error-alt"
                ></box-icon>
              </div>
              {isReceiverAvailable ? (
                <h1>The host has ended this call.</h1>
              ) : (
                <h1>{unAvailableMessage}</h1>
              )}
              <div className="main-btn">
                <button
                  className="btn btn--primary mr-2x"
                  onClick={window.close}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoChat;
