import "boxicons";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import config from "configs";
import { fetchDistricts, fetchInstitutions } from "services/tenant";

import { error } from "utils/toast";
import { getRole } from "utils/roles";
import { Role } from "constants/roles";
import { Status } from "constants/status";
import AddStudentForm from "./StudentForm";
import AddSponsor from "pages/sponsors/add";
import { ITerm } from "commons/types/terms";
import SearchSponsor from "pages/sponsors/search";
import UpdateSponsor from "pages/sponsors/update";
import { IInstitution, IMentor } from "commons/types";
import { fetchTerms } from "store/actions/data/terms";
import { ISponsor, IUser } from "commons/types/users";
import { SCHOOL_TYPE, UserType } from "constants/index";
import { CustomErrorMessages } from "constants/errors";
import { IDistrict } from "commons/types/institutions";
import { addStudent } from "store/actions/data/students";
import { fetchMentors } from "store/actions/data/mentors";
import { IAccommodation } from "commons/types/accommodation";
import * as accommodationService from "services/accommodation";
import { getDataFromLocalStorage } from "services/localStorage";
import { addSponsor, updateSponsor } from "store/actions/data/sponsors";

type Props = {
  terms: ITerm[];
  mentors: IMentor[];
  fetchTerms: () => {};
  fetchMentors: () => {};
  addStudent: (payload: any) => any;
  addSponsor: (payload: any) => any;
  updateSponsor: (id: string, payload: IUser) => Promise<IUser>;
};

const AddStudent: React.FC<Props> = ({
  addSponsor,
  addStudent,
  fetchTerms,
  fetchMentors,
  updateSponsor: editSponsor,
}): JSX.Element => {
  useEffect(() => {
    fetchMentors();
    fetchTerms();
  }, [fetchMentors, fetchTerms]);

  const history = useHistory();
  const roles = getDataFromLocalStorage("roles");

  const [isAddSponsorOpen, setIsAddSponsorOpen] = useState(false);
  const [isSearchSponsorOpen, setIsSearchSponsorOpen] = useState(false);
  const [updateSponsor, setUpdateSponsor] = useState<ISponsor | null>(null);
  const [selectSponsor, setSelectSponsor] = useState<ISponsor | null>(null);

  const [guardians, setGuardians] = useState<ISponsor[]>([]);

  const setSponsorUpdate = (sponsor: ISponsor) => {
    setUpdateSponsor(sponsor);
  };

  const saveStudent = async (data: any) => {
    const subject = getRole(roles, Role.SUBJECT);
    if (!subject) {
      return error(CustomErrorMessages.ROLE_MISSING);
    }
    const studentData = { ...data };
    const { _id, name } = subject;
    studentData.roles = [{ _id, name }];

    if (!studentData.userData.schoolInfo.institution)
      delete studentData.userData.schoolInfo.institution;
    if (!studentData.userData.schoolInfo.schoolDistrict)
      delete studentData.userData.schoolInfo.schoolDistrict;
    if (!studentData.userData.schoolInfo.accommodation)
      delete studentData.userData.schoolInfo.accommodation;

    const student = await addStudent(studentData);

    if (student) {
      history.push(config.uiPath.students.list);
    }
  };

  const handleAddGuardianSubmit = async (payload: any) => {
    const sponsor = getRole(roles, Role.SPONSOR);

    if (!sponsor) {
      return error(CustomErrorMessages.ROLE_MISSING);
    }
    const guardian = { ...payload };
    const { _id, name } = sponsor;
    guardian.roles = [{ _id, name }];

    delete guardian.relationshipWithSponsor;
    delete guardian.livingScheduleWithParents;

    const { data } = await addSponsor(guardian);

    setGuardians((prevState) => [
      ...prevState,
      {
        userId: { id: data._id, ...data },
        relationshipWithSponsor: payload.relationshipWithSponsor,
        livingScheduleWithParents: payload.livingScheduleWithParents,
      },
    ]);

    setIsAddSponsorOpen(false);
  };

  const handleRemoveGuardian = (id: string) => {
    setGuardians(
      guardians.filter((guardian: ISponsor) => guardian.userId?._id !== id),
    );
  };

  const handleUpdateGuardianSubmit = async (payload: any) => {
    const guardian = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
    };
    const updatedSponsor = await editSponsor(payload.id, guardian);

    if (updatedSponsor) {
      setGuardians(
        guardians.map((user: ISponsor) => {
          if (user.userId?._id === payload.id)
            return {
              userId: {
                id: updatedSponsor._id,
                ...updatedSponsor,
              },
              relationshipWithSponsor: payload.relationshipWithSponsor,
              livingScheduleWithParents: payload.livingScheduleWithParents,
            };
          return user;
        }),
      );

      setUpdateSponsor(null);
      setSelectSponsor(null);
    }
  };

  const initialValues = {
    roles: [
      {
        _id: "",
        name: "",
      },
    ],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    locations: [],
    dateOfBirth: "",
    userData: {
      type: UserType.Subject,
      nickName: "",
      learningEnvironment: "",
      status: Status.DRAFT,
      diagnosis: "",
      isGuardianApplicable: true,
      enrollments: [],
      schoolInfo: {
        isSchoolInfoApplicable: true,
        institution: "",
        schoolType: SCHOOL_TYPE.SCHOOL,
        schoolDistrict: "",
        studentType: "",
        grade: "",
        academicYear: "",
        academicTerm: "",
        notes: "",
        accommodation: "",
        isOnAcademicProbation: false,
      },
    },
    relatedUsers: {
      type: UserType.Subject,
      sponsors: [],
      practitioners: [],
    },
  };

  const [schools, setSchools] = useState<IInstitution[]>([]);

  const [universities, setUniversities] = useState<IInstitution[]>([]);

  const [districts, setDistricts] = useState<IDistrict[]>([]);

  const [accommodations, setAccommodations] = useState<IAccommodation[]>([]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const populateInstitutionDropDown = async () => {
        try {
          const { data } = await fetchInstitutions({
            type: SCHOOL_TYPE.SCHOOL,
          });
          setSchools(data);
        } catch (ex) {
          error("Failed to fetch schools");
        }
      };
      const populateUniversityDropDown = async () => {
        try {
          const { data } = await fetchInstitutions({
            type: SCHOOL_TYPE.UNIVERSITY,
          });
          setUniversities(data);
        } catch (ex) {
          error("Failed to fetch universities");
        }
      };
      const populateDistrictDropDown = async () => {
        try {
          const { data } = await fetchDistricts();
          setDistricts(data);
        } catch (ex) {
          error("Failed to fetch districts");
        }
      };
      const populateAccommodationDropDown = async () => {
        try {
          const { data } = await accommodationService.fetchAll({});
          setAccommodations(data);
        } catch (ex) {
          error("Failed to fetch accommodations");
        }
      };
      Promise.allSettled([
        populateInstitutionDropDown(),
        populateUniversityDropDown(),
        populateDistrictDropDown(),
        populateAccommodationDropDown(),
      ]);
    };
    fetchDropdownOptions();
  }, []);

  return (
    <div className="content-wrap pt-8x">
      <div className="container">
        <div className="page-heading">
          <h2>Set up a Student</h2>
        </div>
        <div className="content-7x">
          <AddStudentForm
            guardians={guardians}
            setIsAddSponsorOpen={setIsAddSponsorOpen}
            schools={schools}
            districts={districts}
            saveStudent={saveStudent}
            universities={universities}
            initialValues={initialValues}
            accommodations={accommodations}
            setSponsorUpdate={setSponsorUpdate}
            handleRemoveGuardian={handleRemoveGuardian}
            setIsSearchSponsorOpen={setIsSearchSponsorOpen}
          />
        </div>
      </div>
      <Modal
        isOpen={isAddSponsorOpen}
        className="modal-block"
        ariaHideApp={false}
      >
        <AddSponsor
          existingSponsors={guardians}
          handleSubmit={handleAddGuardianSubmit}
          closeModal={() => setIsAddSponsorOpen(false)}
        />
      </Modal>

      <Modal
        className="modal-block"
        isOpen={isSearchSponsorOpen}
        ariaHideApp={false}
      >
        <SearchSponsor
          selectedSponsors={guardians}
          handleSelectSponsor={(sponsor: IUser) => {
            setGuardians((prevState) => [
              ...prevState,
              {
                userId: {
                  _id: sponsor._id || "",
                  email: sponsor.email || "",
                  lastName: sponsor.lastName || "",
                  firstName: sponsor.firstName || "",
                  phoneNumber: sponsor.phoneNumber || "",
                },
                livingScheduleWithParents: "",
                relationshipWithSponsor: "",
              },
            ]);
            setSelectSponsor({
              userId: sponsor,
              relationshipWithSponsor: "",
              livingScheduleWithParents: "",
            });
            setIsSearchSponsorOpen(false);
          }}
          handleUnselectSponsor={(sponsor: IUser) =>
            sponsor._id && handleRemoveGuardian(sponsor._id)
          }
          addSponsor={() => setIsAddSponsorOpen(true)}
          closeModal={() => setIsSearchSponsorOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={!!updateSponsor}
        className="modal-block"
        ariaHideApp={false}
      >
        {!!updateSponsor && (
          <UpdateSponsor
            sponsor={updateSponsor}
            existingSponsors={guardians}
            handleSubmit={handleUpdateGuardianSubmit}
            closeModal={() => setUpdateSponsor(null)}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!selectSponsor}
        className="modal-block"
        ariaHideApp={false}
      >
        {!!selectSponsor && (
          <UpdateSponsor
            sponsor={selectSponsor}
            existingSponsors={guardians}
            handleSubmit={handleUpdateGuardianSubmit}
            closeModal={() => {
              selectSponsor.userId._id &&
                handleRemoveGuardian(selectSponsor.userId._id);
              setSelectSponsor(null);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  mentors: state.mentors.fetchMentors,
  terms: state.terms.fetchTerms,
});

const mapDispatchToProps = {
  addStudent,
  addSponsor,
  fetchTerms,
  fetchMentors,
  updateSponsor,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
