import "boxicons";
import Modal from "react-modal";
import { useEffect, useState } from "react";

import { success } from "utils/toast";
import MentorUpdate from "pages/mentor/update";
import { updateMentor } from "services/mentors";
import { IUpdateMentorParams } from "services/mentors";

const ActionCell = ({ row, loadMentors }: any) => {
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const getInitialValues = () => {
    return {
      firstName: row.original.firstName,
      lastName: row.original.lastName,
      email: row.original.email,
      newEmail: "",
      newEmailConfirm: "",
      isEmailChangeActive: false,
      phone: row.original.phoneNumber,
      environment: row.original.userData?.learningEnvironment,
      learningCenter: row.original.userData?.learningCenter,
      status: row.original.userData?.status,
    };
  };

  const updateMentorInformation = async (payload: IUpdateMentorParams) => {
    const updatedMentor = await updateMentor(payload);
    if (updatedMentor) {
      success("Mentor Updated Successfully");
      loadMentors();
    }
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <>
      <div className="action-group">
        <span className="link-item mr-2x" onClick={() => setEditOpen(true)}>
          <box-icon name="edit-alt" color="#959FAE"></box-icon>
        </span>
      </div>
      <Modal
        className="modal-block-form mentor-modal"
        isOpen={isEditOpen}
        onRequestClose={() => setEditOpen(false)}
      >
        <MentorUpdate
          initialValues={getInitialValues()}
          id={row.original._id}
          closeModal={() => setEditOpen(false)}
          updateMentorInformation={updateMentorInformation}
          isFromIntakeManager={true}
        />
      </Modal>
    </>
  );
};
export default ActionCell;
