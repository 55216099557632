export enum EVENT {
  NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE",
  SUBCRIPTION_SUCCESS = "pusher:subscription_succeeded",
  SUBSCRIPTION_ERROR = "pusher:subscription_error",
  CALL_STARTED = "CALL_STARTED",
  CALL_ENDED = "CALL_ENDED",
  CALL_BUSY = "CALL_BUSY",
  CALL_REJECTED = "CALL_REJECTED",
  AVAILABILITY_UPDATE = "AVAILABILITY_UPDATE",
}
