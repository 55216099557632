import logo from "assets/images/boost_logo.svg";
import "boxicons";
import Select from "react-select";
import Modal from "react-modal";
import { subdomainName } from "utils/http";

const AddStudent = () => {
  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="nav__link nav__link--active link-item">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link link-item">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="nav__link link-item">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="user-dropdown link-item">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap pt-8x">
        <div className="container">
          <div className="page-heading">
            <h2>Set up a Student</h2>
          </div>
          <div className="content-7x">
            <section className="section">
              <div className="section__title">
                <h3 className="required">Parents/Guardians Information</h3>
                <div className="actions">
                  <span className="link-item mr-2x">
                    <box-icon name="search"></box-icon>
                  </span>
                  <span className="link-item">
                    <box-icon name="plus-circle"></box-icon>
                  </span>
                </div>
              </div>
              <div className="section__content">
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check2"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check2" className="box-links__nodes">
                    Please check this option if parents/guardians information is
                    not applicable to this student.
                  </label>
                </div>
                <div className="card card-addinfo">
                  <div className="card-content">
                    <div className="card-text">
                      <h3>John Doe (Father)</h3>
                      <span className="card-address">
                        +1 782 191 1193 (john@doe.com)
                      </span>
                    </div>
                  </div>
                  <div className="card-actions">
                    <span className="link-item">
                      <box-icon name="edit-alt"></box-icon>
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Enrollment Information</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">
                        First Name
                      </label>
                      <input
                        type="text"
                        placeholder="Samantha"
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">Last Name</label>
                      <input type="text" placeholder="Doe" className="input" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label required">Nick Name</label>
                      <input type="text" placeholder="Sam" className="input" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">
                        Email address
                      </label>
                      <input
                        type="text"
                        placeholder="samanthadoe@school.edu"
                        className="input"
                      />
                      <p className="input__helper">
                        Please use email address provided by the school. Do not
                        invite students in their personal email addresses.
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">
                        Phone Number
                      </label>
                      <input type="text" placeholder="+1" className="input" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3x required">Learning environment</div>
                    <div className="radio-inline">
                      <div className="radio-options">
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio1"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio1" className="input__label">
                            Remote
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio2"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio2" className="input__label">
                            In person
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio3"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio3" className="input__label">
                            Hybrid
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio4"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio4" className="input__label">
                            Unsure
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Location</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">
                        {subdomainName} Learning Center
                      </label>
                      <input
                        type="text"
                        placeholder="Samantha"
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label required">
                        Satellite Location
                      </label>
                      <input type="text" placeholder="Doe" className="input" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>School Information</h3>
              </div>
              <div className="section__content">
                <div className="input-wrap">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value="Checkbox Inline 2"
                    id="check2"
                    className="form-group__radio mr-10"
                  />
                  <label htmlFor="check2" className="box-links__nodes">
                    Please check this option if school information is not
                    applicable to this student.
                  </label>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">Notes</label>
                      <textarea className="textarea" name="message">
                        Add notes
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3x">
                      Does this student go to a school or a university?
                    </div>
                    <div className="radio-inline">
                      <div className="radio-options">
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio5"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio5" className="input__label">
                            School
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio6"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio6" className="input__label">
                            University
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio7"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio7" className="input__label">
                            2 year college
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Name of School</label>
                      <Select
                        placeholder="Please select school"
                        name="schoolOrUniversityName"
                        options={[
                          { label: "asd", value: "asd" },
                          { label: "dsa", value: "dsa" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">School District</label>
                      <input
                        type="text"
                        placeholder="Please select"
                        className="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Grade</label>
                      <input
                        type="text"
                        placeholder="Please select"
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Academic Year</label>
                      <input
                        type="text"
                        placeholder="Please select"
                        className="input"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="mb-3x">Academic Term</div>
                    <div className="radio-inline">
                      <div className="radio-options">
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio8"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio8" className="input__label">
                            Semester
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio9"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio9" className="input__label">
                            Trimester
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio10"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio10" className="input__label">
                            Quarter
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio11"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio11" className="input__label">
                            Year-round School
                          </label>
                        </div>
                        <div className="input-wrap">
                          <input
                            type="radio"
                            id="radio12"
                            name="hello"
                            className="box-links__nodes"
                          />
                          <label htmlFor="radio12" className="input__label">
                            Block
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Accomodation</label>
                      <input
                        type="text"
                        placeholder="Please select"
                        className="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">Notes</label>
                      <textarea className="textarea" name="message">
                        Add notes
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Student Diagnosis</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">
                        Main concerns and/or Behavioral Issues
                      </label>
                      <textarea className="textarea" name="message">
                        Please enter Student’s diagnosis,
                        issues/challenges/concerns, Hobbies and interest, focus
                        areas and additional information
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Suggest a Mentor</h3>
              </div>
              <div className="section__subtitle">
                <span className="text-small">
                  Please specify a date and time the student will be formally
                  enrolled in the membership.
                </span>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">
                        Potential Primary Mentor
                      </label>
                      <input
                        type="text"
                        placeholder="Please select"
                        className="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrap">
                      <label className="input__label">
                        Additional Notes for the Mentor
                      </label>
                      <textarea className="textarea" name="message">
                        Please write here if you have any specific notes for the
                        mentor(s)
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Program Information</h3>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">
                        Enroll Student into {subdomainName} Term
                      </label>
                      <input
                        type="text"
                        placeholder="Summer 2021"
                        className="input"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Type of Program</label>
                      <input
                        type="text"
                        placeholder="Weekly"
                        className="input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Mentorship Start Date</h3>
              </div>
              <div className="section__subtitle">
                <span className="text-small">
                  Please specify a date and time the student will be formally
                  enrolled in the membership.
                </span>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Select a Date</label>
                      <input
                        type="date"
                        className="input"
                        placeholder="Please select"
                        name="mentorshipStartDate"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-wrap">
                      <label className="input__label">Select a Time</label>
                      <input
                        type="text"
                        placeholder="05:00 pm"
                        className="input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__title">
                <h3>Weekly Planning Period (optional)</h3>
              </div>
              <div className="section__subtitle">
                <span className="text-small">
                  Please help mentors and students get started by setting up a
                  session schedule. Please note mentors can change this later
                  based on theirs and the students’ availibility.
                </span>
              </div>
              <div className="section__content">
                <div className="row">
                  <div className="col-6">
                    <span>What day of the week?</span>
                    <div className="week-list">
                      <ul>
                        <li className="selected">
                          <span className="link-item item">S</span>
                        </li>
                        <li>
                          <span className="link-item item">M</span>
                        </li>
                        <li>
                          <span className="link-item item">T</span>
                        </li>
                        <li>
                          <span className="link-item item">W</span>
                        </li>
                        <li>
                          <span className="link-item item">T</span>
                        </li>
                        <li>
                          <span className="link-item item">F</span>
                        </li>
                        <li>
                          <span className="link-item item">S</span>
                        </li>
                      </ul>
                      <span className="text-small">
                        Please note that mentorship week begins on the selected
                        day of the week.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="section__content">
                <div className="row">
                  <div className="col-12 d-flex">
                    <button className="btn btn--primary mr-4x" type="button">
                      Save Information
                    </button>
                    <button className="btn btn--green" type="button">
                      Enroll and Confirm
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <Modal
              className="modal-block"
              isOpen={false}
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                },
              }}
            >
              <div className="modal-wrap">
                <div className="modal-wrap__header">
                  <h3>Edit Term</h3>
                  <span className="link-item">
                    <box-icon name="x"></box-icon>
                  </span>
                </div>
                <div className="modal-wrap__body">
                  <div className="row">
                    <div className="col-6">
                      <div className="input-wrap">
                        <label className="input__label">Select a Date</label>
                        <input
                          type="date"
                          className="input"
                          placeholder="Please select"
                          name="mentorshipStartDate"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-wrap">
                        <label className="input__label">Select a Time</label>
                        <input
                          type="text"
                          placeholder="05:00 pm"
                          className="input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="input-wrap">
                        <label className="input__label">Select a Date</label>
                        <input
                          type="date"
                          className="input"
                          placeholder="Please select"
                          name="mentorshipStartDate"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-wrap">
                        <label className="input__label">Select a Time</label>
                        <input
                          type="text"
                          placeholder="05:00 pm"
                          className="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-wrap__footer">
                  <div className="row">
                    <div className="col-12 d-flex">
                      <button className="btn btn--primary mr-4x" type="button">
                        Save Information
                      </button>
                      <button className="btn" type="button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStudent;
