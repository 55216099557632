import Modal from "react-modal";
import React, { useState } from "react";
import pinterpolate from "pinterpolate";
import { useHistory } from "react-router-dom";

import config from "configs";
import ResendAssessment from "./ResendAssessment";
import { IUser } from "../../../commons/types/users";
import { getDateInFormat } from "../../../utils/dates";
import Loader from "../../../commons/components/Loader";
import { getTenantSpecificUserString } from "../../../tenant/tenant.utils";
import { IAssessmentItem, SurveyTypes } from "../../../commons/types/surveys";

interface AssessmentItemsProps {
  student: IUser;
  assessments: Array<IAssessmentItem>;
  isAssessmentItemsLoading: boolean;
  isAggregated?: boolean;
  onResendAssessmentSuccess?: () => void;
}

const AssessmentItems = (props: AssessmentItemsProps) => {
  const {
    student,
    assessments,
    isAssessmentItemsLoading,
    isAggregated = false,
  } = props;
  const history = useHistory();

  // @ts-ignore
  const studentId: string = student._id;

  // Todo we have get send sort query to Backend
  const assessmentsByDate = assessments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  if (isAssessmentItemsLoading) {
    return <Loader type="ThreeDots" />;
  }

  if (assessments.length <= 0) {
    return (
      <div className="card">
        <div className="card-content">
          <div className="card-text">
            <h3>There is no Student Assessment Survey yet.</h3>
          </div>
        </div>
      </div>
    );
  }

  const onCardClick = (
    id: string,
    responseId: string,
    role: string,
    responseCount: number,
  ) => {
    const assessmentDetailPath = pinterpolate(
      config.uiPath.students.individualAssessmentDetails,
      {
        id,
        responseId,
      },
    );
    history.push(
      isAggregated
        ? `${assessmentDetailPath}?isAggregated=true`
        : responseCount > 1
        ? `${assessmentDetailPath}?role=${role}&hasMulti=true`
        : `${assessmentDetailPath}?role=${role}`,
    );
  };

  const SurveyType = React.memo(
    ({ surveyType }: { surveyType: SurveyTypes }) => {
      const surveyTypeText = (() => {
        if (surveyType === SurveyTypes.INITIAL) {
          return "Initial Survey";
        }

        if (surveyType === SurveyTypes.FINAL) return "Final Survey";
      })();

      if (!surveyTypeText) {
        return null;
      }

      return <h3 className="color-orange-base ml-3x">{surveyTypeText}</h3>;
    },
  );

  const AssessmentItem = ({ assessment }: { assessment: IAssessmentItem }) => {
    const responderRole = assessment.responderRole;

    const assessmentTitle = responderRole
      ? `${getTenantSpecificUserString(responderRole)} ${assessment.title}`
      : assessment.title;

    const [isResendModalVisible, setIsResendModalVisible] = useState(false);

    const openResendModal = () => setIsResendModalVisible(true);

    const closeResendModal = () => setIsResendModalVisible(false);

    const assessmentTerm = assessment.term;

    return (
      <>
        <div
          className={`card mb-0x link-item ${
            assessment.assessmentType === "AGGREGATED" ? "bg-green" : ""
          }`}
          onClick={() => {
            onCardClick(
              studentId,
              assessment._id,
              responderRole || "",
              assessment.totalResponses,
            );
          }}
          key={`${assessment._id}-${responderRole}`}
        >
          <div className="card-content card-content-assessment flex_1 justify-content-between">
            <div className="card-text">
              <p className="fs-14 pb-6px text-light-md">
                {assessmentTerm.name}
              </p>
              <div className="card-info card-info--horizontal">
                <h3>{assessmentTitle}</h3>
                {assessment.surveyType ? (
                  <SurveyType surveyType={assessment.surveyType} />
                ) : null}
              </div>
              <ul className="card-info mt-2x">
                <li>
                  <box-icon name="calendar" />
                  <span className="pr-1x">Created on</span>
                  <strong>{getDateInFormat(assessment.createdAt)}</strong>
                </li>
                <li>
                  <box-icon name="reply" />
                  <span className="pr-1x">Responses</span>
                  <strong>{assessment.totalResponses}</strong>
                </li>
                <li>
                  <box-icon name="help-circle" type="solid" />
                  <span className="pr-1x">Questions</span>
                  <strong>{assessment.totalQuestions}</strong>
                </li>
              </ul>
            </div>

            {assessment.assessmentType === "INDIVIDUAL" ? (
              <button
                className="btn btn--outlined-primary align-self-center"
                type="button"
                onClick={(event) => {
                  openResendModal();
                  event.stopPropagation();
                }}
              >
                Re-send
              </button>
            ) : null}
          </div>
        </div>
        <ResendAssessmentModal
          assessment={assessment}
          isModalVisible={isResendModalVisible}
          closeModal={closeResendModal}
          student={student}
          onResendAssessmentSuccess={props.onResendAssessmentSuccess}
        />
      </>
    );
  };

  return (
    <>
      {assessmentsByDate.map((assessment) => (
        <AssessmentItem assessment={assessment} />
      ))}
    </>
  );
};

interface IResendAssessmentModalProps {
  assessment: IAssessmentItem;
  isModalVisible: boolean;
  closeModal: () => void;
  student: IUser;
  onResendAssessmentSuccess?: () => void;
}

const ResendAssessmentModal = (props: IResendAssessmentModalProps) => {
  const { assessment, isModalVisible, closeModal, student } = props;

  return (
    <>
      {assessment.assessmentType === "INDIVIDUAL" ? (
        <Modal
          className="modal-block-form"
          isOpen={isModalVisible}
          ariaHideApp={false}
          onRequestClose={closeModal}
        >
          <ResendAssessment
            assessment={assessment}
            closeModal={closeModal}
            student={student}
            onResendAssessmentSuccess={props.onResendAssessmentSuccess}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default AssessmentItems;
