import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { withRouter, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import config from "configs";
import { setDataToLocalStorage } from "services/localStorage";
import { error } from "utils/toast";
import { Role } from "constants/roles";
import { checkRole } from "utils/roles";
import { fetchRoles } from "services/roles";
import { getProfile } from "services/users";
import IRole from "commons/types/role";
import Loader from "commons/components/Loader";
import TopNavbar from "commons/components/layouts/TopNavbar";
import pusherInstance from "utils/pusher";
import useLogout from "commons/hooks/useLogout";

const Welcome = (props: any) => {
  const { t } = useTranslation();
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();

  const [role, setRole] = useState("");
  // const [userId, setUserId] = useState("");
  const [unauthorizedMessage, setUnauthorizedMessage] = useState("");
  const history = useHistory();

  const { logout } = useLogout();

  const fetchRolesFromApi = (roles: IRole[]) => {
    if (
      checkRole(roles, Role.INTAKE_MANAGER) ||
      checkRole(roles, Role.PRACTITIONER) ||
      checkRole(roles, Role.SUPER_ADMIN)
    ) {
      fetchRoles()
        .then((data) => {
          if (data.data) setDataToLocalStorage("roles", data?.data);
        })
        .catch(() => error("Failed to fetch user roles"));
    }
  };

  const fetchProfileInfo = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await getProfile(token);

      if (!response?.data?.roles) {
        setUnauthorizedMessage(
          "Sorry , You are not authorized to view this page",
        );
        return;
      }

      setDataToLocalStorage("profile", {
        ...response.data,
        authToken: token,
        isStudentLoggedIn: response.data.roles[0].name === Role.SUBJECT,
      });

      pusherInstance.subscribeToUserChannel(response?.data?._id);

      console.log("======>role", response.data.roles[0].name);

      setRole(response.data.roles[0].name);
      fetchRolesFromApi(response.data.roles);
      // setUserId(response.data._id);
    } catch (ex) {
      error("Failed to fetch profile information");
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfileInfo();
    } else {
      // clearLocalDataStorage();
    }
  }, [fetchProfileInfo, isAuthenticated]);

  useEffect(() => {
    /*
      Redirects to dashboard if user role is intake manager or practitioner
    */
    if (isAuthenticated && role) {
      if (role === Role.INTAKE_MANAGER) {
        history.push(config.uiPath.students.list);
      } else if (role === Role.PRACTITIONER || role === Role.SUBJECT) {
        history.push("/dashboard");
      } else {
        setUnauthorizedMessage(
          "Sorry , You are not authorized to view this page",
        );
      }
    }
  }, [isAuthenticated, role, history]);

  useEffect(() => {
    /* Redirrects to auth0 login */
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  /*
    Renders Unauthorised message and logout button
     - If Subject logs into the application

    Renders Loader
     - If application is redirecting to auth0 or dashboard page
  */

  return unauthorizedMessage === "" ? (
    <Loader type="ThreeDots" />
  ) : (
    <>
      <TopNavbar options={[]} />
      <div className="welcome-page mt-20x ml-5x">
        <header className="App-header">
          <div
            className="btn-group mt-5x align-items-center"
            role="group"
            aria-label="API Requests Examples"
          >
            <h1 className="mb-2x">{t("Unauthorized")}.</h1>
            {unauthorizedMessage ? (
              <>
                <h3 className="mb-2x">{unauthorizedMessage}</h3>
                <button
                  className="btn btn--primary mb-2x"
                  type="button"
                  onClick={() => {
                    logout();
                  }}
                >
                  Log Out
                </button>
              </>
            ) : null}
          </div>
        </header>
      </div>
    </>
  );
};

export default withRouter(Welcome);
