import {
  AddCourseActionType,
  FetchCoursesActionType,
  UpdateCourseActionType,
  DeleteCourseActionType,
} from "store/actions/types/courses";

const INITIAL_STATE = {
  fetchCourses: [],
  fetchCoursesLoading: false,
  fetchCoursesError: null,
  addCourse: {},
  addCourseLoading: false,
  addCourseError: null,
  updateCourse: {},
  updateCourseLoading: false,
  updateCourseError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchCoursesActionType.FETCH_COURSES_PENDING:
      return { ...state, fetchCoursesLoading: true, fetchCoursesError: null };
    case FetchCoursesActionType.FETCH_COURSES_FULFILLED:
      return {
        ...state,
        fetchCoursesLoading: false,
        fetchCourses: action.data,
        fetchCoursesError: null,
      };
    case FetchCoursesActionType.FETCH_COURSES_REJECTED:
      return {
        ...state,
        fetchCoursesLoading: false,
        fetchCoursesError: action.data,
      };
    case AddCourseActionType.ADD_COURSE_PENDING:
      return { ...state, addCourseLoading: true, addCourseError: null };
    case AddCourseActionType.ADD_COURSE_FULFILLED:
      return {
        ...state,
        addCourseLoading: false,
        addCourseError: null,
        addCourse: action.data,
      };
    case AddCourseActionType.ADD_COURSE_REJECTED:
      return { ...state, addCourseLoading: false, addCourseError: action.data };

    case UpdateCourseActionType.UPDATE_COURSE_PENDING:
      return { ...state, updateCourseLoading: true, updateCourseError: null };
    case UpdateCourseActionType.UPDATE_COURSE_FULFILLED:
      return {
        ...state,
        updateCourseLoading: false,
        updateCourseError: null,
        updateCourse: action.data,
      };
    case UpdateCourseActionType.UPDATE_COURSE_REJECTED:
      return {
        ...state,
        updateCourseLoading: false,
        updateCourseError: action.data,
      };
    case DeleteCourseActionType.DELETE_COURSE_PENDING:
      return {
        ...state,
        deleteCourseLoading: true,
        deleteCourseError: null,
      };
    case DeleteCourseActionType.DELETE_COURSE_FULFILLED:
      return {
        ...state,
        deleteCourseLoading: false,
        deleteCourseError: null,
        deleteCourse: action.data,
      };
    case DeleteCourseActionType.DELETE_COURSE_REJECTED:
      return {
        ...state,
        deleteCourseLoading: false,
        deleteCourseError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
