import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import AddEditTenantForm from "./AddEditTenantForm";
import { fetchTenantDetail } from "services/multitenancy";
import Loader from "commons/components/Loader";
import { ITenantDetail } from "store/actions/types/multitenancy";

interface AddEditTenantProps {}

interface ParamTypes {
  tenantId: string;
}

const initialValues: ITenantDetail = {
  tenantLogo: "",
  name: "",
  subDomain: "",
  tenantAdminName: "",
  location: "",
  phone: "",
  tenantFriendlyName: "",
  email: "",
  status: "Active",
  services: {
    auth0: {
      auth0Domain: "",
      audience: "",
      auth0CustomDomain: "",
      auth0DbConnection: "",
      singlePage: {
        auth0ClientId: "",
        auth0ClientSecret: "",
      },
      mobile: {
        auth0ClientId: "",
      },
      machineToMachine: {
        auth0ClientId: "",
        auth0ClientSecret: "",
      },
    },
    pusher: {
      pusherAppKey: "",
      pusherCluster: "",
      pusherSecret: "",
      pusherAppId: "",
    },
    twilio: {
      twilioToken: "",
      identity: "",
    },
  },
  features: {
    communication: {
      chat: false,
      audiocall: false,
      videocall: false,
    },
  },
};

const AddEditTenant: React.FC<AddEditTenantProps> = () => {
  const { tenantId } = useParams<ParamTypes>();
  const history = useHistory();

  const add = () => {
    return window.location.href.includes("add");
  };

  const [isTenantDetailLoading, setTenantDetailLoading] = useState(!add);
  const [tenantDetail, setTenantDetail] = useState(initialValues);

  useEffect(() => {
    !add() &&
      fetchTenantDetail(tenantId).then((response) => {
        setTenantDetail(response);
        setTenantDetailLoading(false);
      });
  }, [tenantId]);

  return (
    <div>
      {isTenantDetailLoading ? (
        <Loader type="ThreeDots" />
      ) : (
        <div className="content-wrap">
          <div className="container">
            <div className="tw-flex tw-justify-between tw-mt-8">
              <span
                onClick={() => history.push("/")}
                className="link-item tw-flex tw-space-x-1 tw-items-center"
              >
                <box-icon name="arrow-back"></box-icon>
                <span>Go Back</span>
              </span>
              {add() ? <h2>Set up a Tenant</h2> : <h2>Edit Tenant</h2>}
              <div className=" tw-w-12" />
            </div>
            <AddEditTenantForm
              isTenantNew={add()}
              initialValues={tenantDetail}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEditTenant;
