import configs from "configs";
import pinterpolate from "pinterpolate";
import { http, stringify } from "utils/http";
import { ICourses, ICoursesInput, ICoursesParams } from "commons/types/courses";

/**
 *
 * @returns courses
 */
export const fetchCourses = async (
  params: ICoursesParams,
): Promise<{ data: ICourses[] }> => {
  const url = stringify(configs.endpoints.courses.all, { ...params });
  const { data } = await http.get(url);
  return data;
};

export const addCourse = async (payload: ICoursesInput) => {
  const { data } = await http.post(configs.endpoints.courses.all, payload);
  return data;
};

export const updateCourse = async (
  payload: Partial<ICoursesInput>,
  id: string,
) => {
  const url = pinterpolate(configs.endpoints.courses.one, { id: id });
  const { data } = await http.patch(url, payload);
  return data;
};

export const deleteCourse = async (id: string) => {
  const url = pinterpolate(configs.endpoints.courses.one, { id: id });
  const { data } = await http.delete(url);
  return data;
};
