import { IProfile } from "commons/types/users";
import { getDataFromLocalStorage } from "services/localStorage";

/**
 * Toggle window fullscreen
 */
export const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

export const getOptionById = (id: any) => {
  return window.optionsMap[id.toString()];
};

export const getQuestionById = (id: any) => {
  return window.questionsMap[id.toString()];
};

export const isStudentLoggedIn = (): IProfile => {
  return getDataFromLocalStorage("profile")?.isStudentLoggedIn;
};
