import * as React from "react";

import "boxicons";
import dayjs from "dayjs";
import Select from "react-select";
import { useFormik } from "formik";

import { publish } from "services/report";
import { error, success } from "utils/toast";

import { IUser } from "commons/types/users";
import { GoalType } from "commons/types/goals";
import Loader from "commons/components/Loader";

import { IRelatedUsers, IMentorReportProps } from "commons/types/report";
import EmailSchema from "commons/schemas/emailSchema";
import { ComponentStatus } from "commons/types/status";
import {
  getCurrentPlan,
  getFullName,
  getGoals,
  getMentorNameByTerm,
  getNonNegotiableTasks,
  getOnTheHorizonTasks,
  getTasksByDay,
  openPDFHandler,
} from "./utils";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { getDateInFormat } from "utils/dates";
import generateReportPDF from "./MentorReportPdf";
import { subdomainName } from "utils/http";
import { getDataFromLocalStorage } from "services/localStorage";

const getEmailList = (users: IRelatedUsers, student: IUser) => {
  const mentors =
    users.practitioners.map((mentor) => ({
      email: mentor.userId?.email,
      label: "Mentor",
      name: getFullName({
        firstName: mentor.userId?.firstName!,
        lastName: mentor.userId?.lastName!,
        middleName: mentor.userId?.middleName!,
      }),
    })) || [];

  const studentMail = {
    email: student.email,
    label: "Student",
    name: `${student.firstName} ${student.lastName}`,
  };
  const sponsors =
    users.sponsors.map((sponsor) => ({
      email: sponsor.userId?.email,
      label: sponsor.relationshipWithSponsor,
      name: `${sponsor.userId?.firstName} ${sponsor.userId?.lastName}`,
    })) || [];
  return [...mentors, studentMail, ...sponsors];
};

const MentorReport: React.FunctionComponent<IMentorReportProps> = ({
  closeHandler,
  report,
  student,
  mountStatus,
  planningPeriods,
  currentPlan,
  setCurrentPlan,
  setTermId,
  termId,
  studentTermOptions,
}) => {
  const planOptions =
    planningPeriods?.map((record: IPlanningPeriod) => ({
      label: `${getDateInFormat(record?.startDate)} - ${getDateInFormat(
        record.endDate,
      )}`,
      startDate: record.startDate,
      endDate: record.endDate,
      value: record.startDate.toString(),
    })) || [];
  const [notes, setNotes] = React.useState("");
  const [componentState, setComponentState] = React.useState({
    status: ComponentStatus.Idle,
  });

  const relatedUserList = getEmailList(report.info.relatedUsers, student);
  const userCheckList = relatedUserList.reduce(
    (prev, cur) => ({
      ...prev,
      [cur.email || ""]: false,
    }),
    {},
  );
  const tenantLogo = getDataFromLocalStorage("tenantLogo") || {};

  const [checkedEmails, setCheckedEmails] =
    React.useState<Record<string, boolean>>(userCheckList);
  const emails = Object.keys(checkedEmails).filter(
    (email) => checkedEmails[email],
  );

  const initialValues = {
    subject: "",
    body: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      let blob: Blob;
      try {
        blob = await new Promise<Blob>((resolve) => {
          const generatedPDF = generateReportPDF({
            termId: termId,
            student: student,
            report: report,
            notes: notes,
            currentPlan: currentPlan,
          });
          generatedPDF.getBlob((blobResult) => resolve(blobResult));
        });
      } catch (err: any) {
        error("Error creating report pdf");
        return;
      }

      const attachment = new File(
        [blob as BlobPart],
        `${subdomainName} Report`,
        {
          type: blob.type,
        },
      );

      const payload = new FormData();

      payload.append("attachments", attachment);

      emails.forEach((email) => {
        payload.append(`to[]`, email);
      });
      payload.append("subject", values.subject);
      payload.append("body", values.body);

      setComponentState({ status: ComponentStatus.Loading });
      try {
        const response = await publish(payload);
        if (mountStatus) {
          success(response.data?.message || "Report sent successfully");
          setComponentState({ status: ComponentStatus.Loaded });
          closeHandler();
        }
      } catch (err: any) {
        if (mountStatus) {
          setComponentState({ status: ComponentStatus.Error });
          error(err?.message || "Failed to schedule message");
        }
      }
    },
    validationSchema: EmailSchema,
  });

  const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.currentTarget.value);
  };

  const isSelected = (email: string) => checkedEmails[email];
  const onCheckboxChange = (email: string) =>
    setCheckedEmails((prev) => ({ ...prev, [email]: !prev[email] }));
  const selectAll = () =>
    setCheckedEmails((prev) =>
      Object.keys(prev).reduce((prev, cur) => ({ ...prev, [cur]: true }), {}),
    );

  const printReport = async () => {
    try {
      const generatedPDF = generateReportPDF({
        termId: termId,
        student: student,
        report: report,
        notes: notes,
        currentPlan: currentPlan,
      });
      await openPDFHandler(generatedPDF);
    } catch (err) {
      error("Error creating report pdf");
    }
  };

  const getComponentBlock = () => {
    switch (componentState.status) {
      case ComponentStatus.Idle:
      case ComponentStatus.Loaded:
      case ComponentStatus.Error:
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="report-form-section">
              <div className="input-wrap mb-3x">
                <label className="input__label">Select Term</label>
                <Select
                  placeholder="Select Term"
                  name="term"
                  onChange={(selected: any) => {
                    setTermId(selected.value);
                  }}
                  options={studentTermOptions}
                  classNamePrefix="react-select"
                  value={studentTermOptions.filter(
                    (trm) => trm.value === termId,
                  )}
                />
              </div>

              <div className="input-wrap mb-0x">
                <label className="input__label">Select Plan</label>
                <Select
                  placeholder="Select Plan"
                  name="planningPeriod"
                  onChange={(selected: any) => {
                    setCurrentPlan({
                      id: selected.value,
                      startDate: selected.startDate,
                      endDate: selected.endDate,
                    });
                  }}
                  options={planOptions}
                  classNamePrefix="react-select"
                  value={planOptions.filter(
                    (option) => option.value === currentPlan.id,
                  )}
                  isDisabled={!termId}
                />
              </div>
            </div>
            <div className="report-form-section">
              <div className="report-form-head">
                <h3 className="mb-2x">Print this Report</h3>
              </div>
              <button
                type="button"
                className="btn btn--primary btn__icon btn__icon--right btn--block mr-2x"
                disabled={!currentPlan?.id}
                onClick={printReport}
              >
                Print Report
                <box-icon name="printer"></box-icon>
              </button>
              <span className="mt-2x pl-8x pr-8x text-light-sm text-center d-block">
                The printable report will open in a new window.
              </span>
            </div>
            <div className="report-form-section no-border">
              <div className="report-form-head">
                <h3 className="mb-4x">Send email to</h3>
                <button
                  type="button"
                  className="btn p-0x link-primary-default"
                  onClick={selectAll}
                >
                  Select all
                </button>
              </div>
              {relatedUserList.map((item, idx) => (
                <div className="input-wrap" key={idx}>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id={`checkbox-${idx}`}
                    checked={isSelected(item.email || "")}
                    onChange={() => onCheckboxChange(item.email || "")}
                    className="form-group__radio mr-10"
                  />
                  <label
                    htmlFor={`checkbox-${idx}`}
                    className="box-links__nodes"
                  >
                    {item.name} ({item.label})
                  </label>
                </div>
              ))}

              <div className="input-wrap">
                <label className="input__label required">Subject</label>
                <input
                  type="text"
                  placeholder=""
                  className={
                    formik.touched.subject && formik.errors.subject
                      ? "input input--error"
                      : "input"
                  }
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <label className="input__error">
                  {formik.touched.subject && formik.errors.subject}
                </label>
              </div>

              <div className="input-wrap">
                <label className="input__label">Email message</label>
                <textarea
                  placeholder=""
                  className={
                    formik.touched.body && formik.errors.body
                      ? "textarea input--error"
                      : "textarea"
                  }
                  name="body"
                  value={formik.values.body}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                <label className="input__error">
                  {formik.touched.body && formik.errors.body}
                </label>
              </div>
            </div>
            <div className="report-form-section no-border">
              <div className="row">
                <div className="col-12 d-flex flex-wrap">
                  <button
                    className="btn btn--primary mr-4x"
                    disabled={!emails.length || !currentPlan.id}
                    type="submit"
                  >
                    Send Email
                  </button>
                  <button
                    className="btn btn-text txt-primary-color"
                    type="button"
                    onClick={closeHandler}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        );

      case ComponentStatus.Loading:
        return <Loader type="ThreeDots" />;

      default:
        return <div>Error Status</div>;
    }
  };

  const tasksGroupedByDay = report.tasks?.length
    ? getTasksByDay(report.tasks, currentPlan)
    : {};
  const nonNegotiableTasks = getNonNegotiableTasks(report.tasks, currentPlan);
  const onTheHorizonTasks = getOnTheHorizonTasks(report.tasks, currentPlan);

  return (
    <div className="modal-wrap__body d-flex">
      <div className="modal-left__container">
        <div className="modal-left report">
          <div className="report__head">
            <div className="navbar__logo">
              <img
                src={tenantLogo}
                alt="logo"
                className="tw-w-50 tw-h-10 tw-object-contain"
              />
            </div>
            <div className="row info">
              <div className="col-6">
                <span className="type">Student</span>
                <h2>
                  {report.info.firstName} {report.info.lastName}
                </h2>
                <ul className="school round round--horizontal">
                  <li>Grade {report.info.userData.schoolInfo?.grade || "-"}</li>
                  <li>
                    {report.info.userData.schoolInfo.institution?.name || "-"}
                  </li>
                </ul>
                <div className="date txt-bold">
                  {getCurrentPlan(currentPlan)}
                </div>
              </div>
              <div className="col-6">
                <span className="type">Mentor</span>
                <h2>{getMentorNameByTerm(student, termId)}</h2>
              </div>
            </div>
          </div>
          <div className="report__content">
            <div className="block">
              <div className="block__head">Notes</div>
              <div className="block__content">
                <div className="input-wrap mb-0x">
                  <textarea
                    placeholder="Add Notes"
                    onChange={onNotesChange}
                    value={notes}
                    className="textarea no-border"
                    name="message"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="block">
                  <div className="block__head">
                    <div className="inline-block w-50p">Courses</div>
                    <div className="inline-block w-50p pl-2x">Grades</div>
                  </div>
                  <div className="block__content">
                    <ul className="report-list">
                      {report.courses.length ? (
                        report.courses.map((course) => (
                          <li key={course._id}>
                            <div className="inline-block w-50p">
                              {course.name}
                            </div>
                            <div className="inline-block w-50p pl-2x">
                              {course.grade ? course.grade : "-"}
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>-</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="block">
                  <div className="block__head">
                    <div className="inline-block w-30p">Courses</div>
                    <div className="inline-block w-70p pl-2x">
                      Missing Assignments
                    </div>
                  </div>
                  <div className="block__content">
                    <ul className="report-list">
                      {report.courses.length ? (
                        Object.keys(report.assignments).map((course, idx) => (
                          <li key={idx} className="align-items-center">
                            <div className="inline-block w-30p">{course}</div>
                            {!report.assignments[course]?.length ? (
                              <ul className="w-70p inline-block pl-2x">
                                <li></li>
                              </ul>
                            ) : (
                              <ul className="inline-block w-70p">
                                {report.assignments[course]?.map(
                                  (assignment, idx) => (
                                    <li
                                      key={idx}
                                      className="assignment__sub-list"
                                    >
                                      {assignment.name}
                                    </li>
                                  ),
                                )}
                              </ul>
                            )}
                          </li>
                        ))
                      ) : (
                        <li>-</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="mb-3x">WEEKLY TO-DO LIST</h3>
            <div className="row">
              {Object.keys(tasksGroupedByDay).map((day) => (
                <div className="col-6" key={day}>
                  <div className="block">
                    <div className="block__head">{day}</div>
                    <div className="block__content">
                      {!tasksGroupedByDay[day].length ? (
                        <ul className="report-list">
                          <li>-</li>
                        </ul>
                      ) : (
                        <ul className="report-list">
                          {tasksGroupedByDay[day].map((task) => (
                            <li key={task._id}>
                              {task.name}
                              {task.isCompleted && (
                                <span className="task-check">
                                  <box-icon name="check" />
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="block">
              <div className="block__head">Non-negotiable Tasks</div>
              <div className="block__content">
                {nonNegotiableTasks.length ? (
                  <ul className="report-list round">
                    {nonNegotiableTasks.map((task) => (
                      <li key={task._id}>
                        {task.requiredDeadline
                          ? dayjs(task.requiredDeadline).format("MM/DD")
                          : "No date"}{" "}
                        - {task.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul className="report-list">
                    <li>-</li>
                  </ul>
                )}
              </div>
            </div>
            <div className="block">
              <div className="block__head">On The Horizon</div>
              <div className="block__content">
                {!onTheHorizonTasks.length ? (
                  <ul className="report-list">
                    <li>-</li>
                  </ul>
                ) : (
                  <ul className="report-list round">
                    {onTheHorizonTasks.map((task, idx) => (
                      <li key={idx}>{task}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <h3 className="mb-3x">SEMESTER GOAL REMINDERS</h3>
            <div className="block">
              <div className="block__head">Academic goals</div>
              <div className="block__content">
                {getGoals(report.goals, GoalType.academic).length ? (
                  <ul className="report-list round">
                    {getGoals(report.goals, GoalType.academic).map(
                      (goal, idx) => (
                        <li key={idx}>{goal}</li>
                      ),
                    )}
                  </ul>
                ) : (
                  <ul className="report-list">
                    <li>-</li>
                  </ul>
                )}
              </div>
            </div>
            <div className="block">
              <div className="block__head">Non-academic goals</div>
              <div className="block__content">
                {getGoals(report.goals, GoalType.nonAcademic).length ? (
                  <ul className="report-list round">
                    {getGoals(report.goals, GoalType.nonAcademic).map(
                      (goal, idx) => (
                        <li key={idx}>{goal}</li>
                      ),
                    )}
                  </ul>
                ) : (
                  <ul className="report-list">
                    <li>-</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-right">{getComponentBlock()}</div>
    </div>
  );
};

export default MentorReport;
