import { GoalType } from "commons/types/goals";
import { IPriority } from "commons/types/plans";
import { IGoal, ITask } from "commons/types/report";
import dayjs from "dayjs";
import { IUser } from "commons/types/users";
import { getDayFromDate } from "utils/dates";
import { TCreatedPdf } from "pdfmake/build/pdfmake";

export interface CurrentPlan {
  id?: string;
  startDate: string;
  endDate: string;
}

export const getCurrentPlan = (currentPlan: CurrentPlan) => {
  if (!currentPlan.startDate || !currentPlan.endDate) {
    return "";
  }
  const formattedStartDate = dayjs(currentPlan.startDate).format("MMM DD");
  const formattedEndDate = dayjs(currentPlan.endDate).format("MMM DD, YYYY");
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getFullName = ({
  firstName,
  lastName,
  middleName,
}: {
  firstName: string;
  lastName: string;
  middleName: string | null;
}) => {
  return `${firstName} ${middleName ? middleName + " " : ""}${lastName}`;
};

export const getMentorNameByTerm = (user: IUser, termId: string) => {
  const currentMentors = user.userData?.enrollments?.find(
    (enrollment) => enrollment?.enrolledTerm?._id === termId,
  )?.practitioners;

  if (currentMentors?.length) {
    return getFullName({
      firstName: currentMentors[0].practitioner.firstName || "",
      lastName: currentMentors[0].practitioner.lastName || "",
      middleName: currentMentors[0].practitioner.middleName || "",
    });
  }

  return "-";
};

export const getNonNegotiableTasks = (
  tasks: Array<ITask>,
  currentPlan: CurrentPlan,
) => {
  const currentTasks = tasks.filter(
    (task) =>
      dayjs(task.requiredDeadline).isSameOrAfter(
        dayjs(currentPlan.startDate),
      ) &&
      dayjs(task.requiredDeadline).isSameOrBefore(dayjs(currentPlan.endDate)),
  );
  const nonNegotiables = currentTasks?.filter(
    (task) => task.workData.priority === IPriority.priority,
  );
  if (!nonNegotiables) {
    return [];
  }
  return nonNegotiables.sort(
    (a, b) =>
      (new Date(a.requiredDeadline) as any) -
      (new Date(b.requiredDeadline) as any),
  );
};

// Returns only priority tasks from the future planning periods
export const getOnTheHorizonTasks = (
  tasks: Array<ITask>,
  currentPlan: CurrentPlan,
) => {
  const onTheHorizonTasks = tasks.filter(
    (task) =>
      dayjs(task.requiredDeadline).isAfter(dayjs(currentPlan.endDate)) &&
      task.workData?.priority === IPriority.priority,
  );

  if (!onTheHorizonTasks?.length) {
    return [];
  }

  return onTheHorizonTasks.reduce((prev: Array<string>, cur) => {
    return [...prev, cur.name];
  }, []);
};

export const getGoals = (goals: Array<IGoal>, type: GoalType) => {
  const filteredGoal = goals.find((goal) => goal._id === type);
  if (!filteredGoal) {
    return [];
  }

  return filteredGoal.goalList;
};

export const getTasksByDay = (
  tasks: Array<ITask>,
  currentPlan: CurrentPlan,
) => {
  const { startDate, endDate } = currentPlan;
  const currentTasks = tasks.filter(
    (task) =>
      dayjs(task.requiredDeadline).isSameOrAfter(
        dayjs(currentPlan.startDate),
      ) &&
      dayjs(task.requiredDeadline).isSameOrBefore(dayjs(currentPlan.endDate)),
  );
  const dayJsStartDate = dayjs(startDate);
  const dayJsEndDate = dayjs(endDate);

  const planningPeriodDuration = dayJsEndDate.diff(dayJsStartDate, "day");

  const tasksByDay = Array(planningPeriodDuration)
    .fill([])
    .reduce(
      (
        prev: { [key: string]: Array<ITask | Record<string, never>> },
        _,
        idx,
      ) => {
        const currentDay = dayJsStartDate.add(idx, "day");
        return {
          ...prev,
          [currentDay.format("dddd")]: [],
        };
      },
      {},
    );

  currentTasks?.forEach((task) => {
    const day = getDayFromDate(task.requiredDeadline);
    if (
      day === "Monday" ||
      day === "Tuesday" ||
      day === "Wednesday" ||
      day === "Thursday" ||
      day === "Friday" ||
      day === "Saturday" ||
      day === "Sunday"
    ) {
      tasksByDay[day] = tasksByDay[day] ? [...tasksByDay[day], task] : [task];
    }
  });

  return tasksByDay;
};

export const openPDFHandler = (generatedPDF: TCreatedPdf) => {
  return new Promise<void>((resolve) => {
    generatedPDF.getBlob((blob) => {
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, "_blank");
      resolve();
    });
  });
};

export const getNextMentoringSchedule = (currentPlanStartDate: string) =>
  dayjs(currentPlanStartDate).add(1, "week").toString();
