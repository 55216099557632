import React from "react";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";

interface IMissingPlanningPeriodsErrorProps {
  student: IUser;
}

const MissingPlanningPeriodsError: React.FC<IMissingPlanningPeriodsErrorProps> =
  ({ student }) => {
    return (
      <div className="alert alert__danger">
        <span className="status link-item">
          <box-icon name="error-alt" />
        </span>
        <div className="alert__content">
          <strong>
            There are no planning periods generated for {getFullName(student)}.
            Creating task is not possible.
          </strong>
          Please change the planning period start date to current/future date to
          generate new planning periods.
        </div>
      </div>
    );
  };

export default MissingPlanningPeriodsError;
