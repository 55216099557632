import debounce from "lodash.debounce";
import { OptionTypeBase } from "react-select";
import React, { useCallback, useEffect } from "react";
import { connect, RootStateOrAny } from "react-redux";

import { error } from "../../../utils/toast";
import { getFullName } from "../../../utils/names";
import { fetchTerms } from "store/actions/data/terms";
import { SelectOption } from "./surveyDownload.types";
import { ITerm } from "../../../commons/types/terms";
import { ISurvey } from "../../../commons/types/surveys";
import RegularSurveyDownload from "./RegularSurveyDownload";
import { fetchSurveyTakers } from "./surveyDownload.service";
import * as studentsService from "../../../services/students";
import { getSurveyCategories } from "store/actions/data/surveys";
import AggregatedSurveyDownload from "./AggregatedSurveyDownload";

interface ISurveyDownloadProps {
  getSurveyCategories: () => void;
  fetchTerms: () => void;
  terms: Array<ITerm>;
  surveyCategories: Array<ISurvey>;
}

const SurveyDownload = (props: ISurveyDownloadProps) => {
  const { terms, surveyCategories } = props;

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      Promise.allSettled([props.fetchTerms(), props.getSurveyCategories()]);
    };

    fetchDropdownOptions();
  }, []);

  const termOptions: Array<SelectOption> = terms.map((record: ITerm) => ({
    label: record.name,
    value: record._id,
  }));

  const surveyCategoriesOptions: Array<SelectOption> = surveyCategories.map(
    (record: ISurvey) => ({
      label: record.name,
      value: record._id,
    }),
  );

  const loadStudentOptions = useCallback(
    debounce(
      (
        inputValue: string,
        callback: (options: ReadonlyArray<SelectOption>) => void,
      ) => {
        studentsService
          .fetchAll({ q: inputValue })
          .then((students) => {
            const studentOptions = students.map((student: any) => ({
              label: getFullName(student),
              value: student._id,
            }));

            return callback(studentOptions);
          })
          .catch(() => {
            error("Failed to fetch students");
          });
      },
      500,
    ),
    [],
  );

  const loadSurveyTakerOptions = useCallback(
    debounce(
      (
        inputValue: string,
        callback: (options: ReadonlyArray<OptionTypeBase>) => void,
      ) => {
        fetchSurveyTakers(inputValue)
          .then((users) => {
            const surveyTakerOptions = users.map((user) => {
              const roleLabel =
                user.roles && user.roles[0] ? `(${user.roles[0].name})` : "";

              return {
                label: `${getFullName(user)} ${roleLabel}`,
                value: user._id,
              };
            });

            return callback(surveyTakerOptions);
          })
          .catch(() => {
            error("Failed to fetch survey takers");
          });
      },
      500,
    ),
    [],
  );

  return (
    <div className="content-wrap pt-8x pb-8x">
      <div className="container-fluid">
        <div className="page-heading">
          <h2>Assessment Download</h2>
        </div>
        <RegularSurveyDownload
          termOptions={termOptions}
          surveyCategoriesOptions={surveyCategoriesOptions}
          loadStudentOptions={loadStudentOptions}
          loadSurveyTakerOptions={loadSurveyTakerOptions}
        />
        <div className="m-10x" />
        <AggregatedSurveyDownload
          termOptions={termOptions}
          surveyCategoriesOptions={surveyCategoriesOptions}
          loadStudentOptions={loadStudentOptions}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootStateOrAny) => ({
  terms: state.terms.fetchTerms,
  surveyCategories: state.surveys.surveyCategories,
});

const mapDispatchToProps = {
  fetchTerms,
  getSurveyCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDownload);
