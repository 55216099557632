interface ITaskMenuProps {
  completeTask: any;
  isMenuTop: boolean;
  incompleteTask: any;
  editMenu: () => void;
  deleteMenu: () => void;
  showDetail: () => void;
  duplicateTask: () => void;
}

const Menu: React.FC<ITaskMenuProps> = ({
  editMenu,
  incompleteTask,
  deleteMenu,
  showDetail,
  duplicateTask,
  completeTask,
  isMenuTop,
}) => {
  return (
    <div className={`menu-dialog ${isMenuTop && "menu-dialog--top"}`}>
      <ul className="menu-dialog__body">
        <li onClick={editMenu} className="menu-list">
          <box-icon name="edit-alt" />
          <span className="ml-2x">Edit</span>
        </li>
        <li onClick={showDetail} className="menu-list">
          <box-icon name="list-ul" />
          <span className="ml-2x">Details</span>
        </li>
        <li onClick={duplicateTask} className="menu-list">
          <box-icon name="copy" />
          <span className="ml-2x">Duplicate</span>
        </li>
        {incompleteTask && (
          <li onClick={incompleteTask} className="menu-list">
            <box-icon name="undo" />
            <span className="ml-2x">Mark as Incomplete</span>
          </li>
        )}
        {completeTask && (
          <li onClick={completeTask} className="menu-list">
            <box-icon name="check" />
            <span className="ml-2x">Mark Completed</span>
          </li>
        )}
        <li onClick={deleteMenu} className="menu-list menu-list--danger">
          <box-icon name="trash-alt" />
          <span className="ml-2x">Delete</span>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
