import Modal from "react-modal";
import { connect } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { Channel as ChannelType } from "twilio-chat/lib/channel";

import { EVENT } from "constants/pusher";

import {
  createChatClient,
  getTwilioToken,
  getTextFromTwilioMessageBody,
} from "services/chat";

import { error } from "utils/toast";
import eventBus from "utils/event-bus";
import pusherInstance from "utils/pusher";
import { addCall, endCall } from "store/actions/data/communication";

import { ICall } from "commons/types/call";
import { IUser } from "commons/types/users";
import Conversation from "./conversation";
import Loader from "commons/components/Loader";
import useChatHook from "commons/hooks/useChatHook";
import CustomScrollbars from "commons/components/CustomScrollbar";

import Channel from "./channel";
import NewMessage from "./new-messages/NewMessage";
import ToggleAvailability from "./ToggleAvailability";
import { setUnreadMessage } from "store/actions/data/chat";
import { IAvailability } from "commons/types/availability";
import { fetchAll as fetchStudents } from "services/students";
import { getDataFromLocalStorage } from "services/localStorage";
import {
  updateAvailability,
  setAvailability,
  fetchAvailability,
} from "store/actions/data/availability";
import { isStudentLoggedIn } from "utils/window";
import IProfile from "commons/types/profile";

export type MessageListProps = {
  isChatOpen: boolean;
  addCall: (data: ICall) => void;
  endCall: () => void;
  unreadMessageChannels: ChannelType[];
  setUnreadMessage: (channel: ChannelType) => void;
  setAvailability: (payload: IAvailability) => void;
  updateAvailability: (id: string, payload: any) => void;
  fetchAvailability: (id: string) => void;
  availability: IAvailability;
  isAvailabilityPending?: Boolean;
  callData: ICall;
  isBusy: Boolean;
};

interface IChannelStateType {
  twilioChannel: ChannelType;
  lastMessage: any;
  uniqueName: string;
  friendlyName?: string;
  members?: any[];
}

export const MessageList: React.FC<MessageListProps> = ({
  isChatOpen,
  addCall,
  endCall,
  setUnreadMessage,
  availability,
  fetchAvailability,
  updateAvailability,
  setAvailability,
  unreadMessageChannels,
  callData,
  isBusy,
}) => {
  const { showChatHistoryOfAChannel } = useChatHook();
  const profile: IProfile = getDataFromLocalStorage("profile");
  const [allChannels, setAllChannels] = React.useState<IChannelStateType[]>([]);
  const [students, setStudents] = React.useState<IUser[]>([]);
  const [twilioChannel, setTwilioChannel] = React.useState<ChannelType | null>(
    null,
  );
  const [showMessageThread, setShowMessageThread] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showMessages, setShowMessages] = React.useState(false);
  const [openNewMessage, setOpenNewMessage] = React.useState(false);
  const [isAvailabilityOpen, setAvailabilityOpen] = useState<boolean>(false);

  const [twilioChannelFromProp, setTwilioChannelFromProp] =
    React.useState(null);

  /**
   * Handles the change of chat channel
   * @param data
   */
  const chatChannelChangedHandler = (data: any) => {
    setTwilioChannelFromProp(data.channel);
    setShowMessages(true);
  };

  const joinChannel = async (channel: ChannelType) => {
    try {
      return await channel.join();
    } catch (ex) {
      return;
    }
  };

  /**
   * Handles following state of the component
   * 1. ComponentDidMount
   * 2. ComponentWillReceiveProps and
   * 3. ComponentWillUnmount
   */
  useEffect(() => {
    eventBus.on("chatChannelChanged", chatChannelChangedHandler);

    if (twilioChannelFromProp) {
      setTwilioChannel(twilioChannelFromProp);
    }

    if (showMessages && twilioChannelFromProp) {
      setShowMessageThread(false);
    } else {
      try {
        const twilioTokenFromService = getTwilioToken();
        twilioTokenFromService
          .then(async (data) => {
            const chatClient = await createChatClient(data.jwt);

            const updateClientToken = async () => {
              try {
                const token = await getTwilioToken();
                chatClient.updateToken(token.jwt);
              } catch (err: any) {
                error("Failed to update twilio token");
              }
            };

            chatClient.on("tokenAboutToExpire", updateClientToken);

            chatClient.on("tokenExpired", updateClientToken);

            // Listen for new invitations to your Client
            const channels: any = [];
            chatClient
              .getSubscribedChannels()
              .then(async function (paginator: any) {
                const processPaginator = async (paginator: any) => {
                  for (let i = 0; i < paginator.items.length; i++) {
                    const channel: any = paginator.items[i];
                    if (channel) {
                      if (channel.channelState.status !== "joined") {
                        await joinChannel(channel);
                      }

                      const messages = await channel.getMessages();
                      const totalMessages = messages.items.length;
                      const lastMessageData = messages.items[totalMessages - 1];
                      const lastMessage = lastMessageData
                        ? {
                            body: getTextFromTwilioMessageBody(
                              lastMessageData.body,
                            ),
                            author: lastMessageData.author,
                            me: true,
                            dateCreated: lastMessageData.dateCreated,
                            dateUpdated: lastMessageData.dateUpdated,
                          }
                        : null;

                      const channelName =
                        channel.friendlyName?.split(",")[1] ||
                        channel.friendlyName;

                      const messagesUnread =
                        await channel.getUnconsumedMessagesCount();

                      if (!!messagesUnread) {
                        setUnreadMessage(channel);
                      }

                      channels.push({
                        twilioChannel: channel,
                        uniqueName: channel.uniqueName,
                        friendlyName: channelName,
                        lastMessage,
                        members: channel.members,
                      });
                    }
                  }
                  if (paginator.hasNextPage) {
                    const nextPaginator = await paginator.nextPage();
                    await processPaginator(nextPaginator);
                  }
                };

                await processPaginator(paginator);

                const sortedChannels = sortChannel(channels);
                if (sortedChannels) setAllChannels(sortedChannels);
                setIsLoading(false);

                chatClient.on("channelAdded", (channel: any) => {
                  setAllChannels((existingChannels) => {
                    if (
                      existingChannels.every(
                        (existingChannel) =>
                          existingChannel.uniqueName !== channel.uniqueName,
                      )
                    ) {
                      if (channel.channelState.status !== "joined") {
                        joinChannel(channel);
                      }
                      const newAllChannels = sortChannel(
                        existingChannels.concat({
                          twilioChannel: channel,
                          uniqueName: channel.uniqueName,
                          friendlyName:
                            channel.friendlyName.split(",")[1] ||
                            channel.friendlyName,
                          lastMessage: channel.lastMessage,
                          members: channel.members,
                        }),
                      );
                      return newAllChannels;
                    }
                    return existingChannels;
                  });
                });
              });
          })
          .catch((err) => {
            console.error(err.name + ": " + err.message);
            setIsLoading(false);
          });
      } catch (ex) {
        error("Failed to fetch chat channels");
      }
    }

    return () => {
      eventBus.remove("chatChannelChanged", chatChannelChangedHandler);
    };
  }, [showMessages, twilioChannelFromProp, profile?._id, setUnreadMessage]);

  const sortChannel = (channels: IChannelStateType[]) => {
    return channels.sort((channel1: any, channel2: any) => {
      if (channel1.lastMessage && channel2.lastMessage) {
        return (
          new Date(channel2.lastMessage.dateCreated).getTime() -
          new Date(channel1.lastMessage.dateCreated).getTime()
        );
      }

      if (!channel1.lastMessage) return 1;
      if (!channel2.lastMessage) return -1;

      return 0;
    });
  };

  const moveChannelToTop = (channel: ChannelType) => {
    const newChannels = [...allChannels];
    const indexOfChannel = newChannels.findIndex(
      (ch: IChannelStateType) => ch.uniqueName === channel.uniqueName,
    );

    if (indexOfChannel < 0) return;

    newChannels.splice(0, 0, newChannels.splice(indexOfChannel, 1)[0]);
    setAllChannels(newChannels);
  };

  const onThreadClick = (channel: any) => {
    setTwilioChannel(channel);
    setShowMessageThread(false);
  };

  const showThreadList = () => {
    setShowMessageThread(true);
  };

  const updateAvailabilityStatus = async (
    isAvailable: boolean,
    isNotifyWhenOffline: boolean,
  ) => {
    if (profile) {
      updateAvailability(profile._id, { isAvailable, isNotifyWhenOffline });
    }
  };

  useEffect(() => {
    const fetchAndSetStudents = async () => {
      try {
        const studentsData = await fetchStudents({});
        setStudents(studentsData);
      } catch (ex) {
        setStudents([]);
      }
    };
    fetchAndSetStudents();
  }, []);

  useEffect(() => {
    fetchAvailability(profile?._id);
  }, [profile?._id, fetchAvailability]);

  useEffect(() => {
    const channel = pusherInstance.getChannel();
    channel?.bind(EVENT.AVAILABILITY_UPDATE, setAvailability);
    return () => {
      channel?.unbind(EVENT.AVAILABILITY_UPDATE, setAvailability);
    };
  }, [setAvailability]);

  const callStartedHandler = useCallback(
    (data: ICall) => {
      if (profile?._id === data.caller?._id) {
        data.isCallerMe = true;
      }
      if (!isBusy) {
        addCall(data);
      }
    },
    [addCall, profile?._id, isBusy],
  );

  const callEndedHandler = useCallback(
    (data: ICall) => {
      if (data.room === callData.room) {
        endCall();
      }
    },
    [endCall, callData],
  );

  useEffect(() => {
    const channel = pusherInstance.getChannel();

    if (!channel) return;

    channel.bind(EVENT.CALL_STARTED, callStartedHandler);
    channel.bind(EVENT.CALL_ENDED, callEndedHandler);

    return () => {
      channel.unbind(EVENT.CALL_STARTED, callStartedHandler);
      channel.unbind(EVENT.CALL_ENDED, callEndedHandler);
    };
  }, [callStartedHandler, callEndedHandler]);

  return (
    <>
      <div
        className={`right-panel ${isChatOpen ? null : "collapse"} ${
          showMessageThread ? null : "display__none"
        }`}
      >
        <div className="message">
          <div className="message__title">
            <h3>Messages</h3>
            <div className="message-actions">
              <span
                className={`${
                  availability?.isAvailable
                    ? "action-links active snooze"
                    : "action-links active snooze status-offline"
                }`}
                onClick={() => setAvailabilityOpen(true)}
              >
                <box-icon name="alarm-snooze"></box-icon>
              </span>
              {!isStudentLoggedIn() &&
                (isLoading ? (
                  <div className="loader mt-2x">
                    <Loader type="ThreeDots" />
                  </div>
                ) : (
                  <span
                    onClick={() => setOpenNewMessage(true)}
                    className="action-links add-member default"
                  >
                    <box-icon name="plus-circle" type="solid"></box-icon>
                  </span>
                ))}
            </div>
          </div>
          <div className="message__content">
            {isLoading ? (
              <div className="loader">
                <Loader type="ThreeDots" />
              </div>
            ) : null}
            <CustomScrollbars>
              {students
                ? allChannels.map((chnl: any, indx) => {
                    const auth0UserId = chnl.uniqueName?.split(",").pop();

                    // This will hide student's own message from message list in student web app
                    if (
                      isStudentLoggedIn() &&
                      auth0UserId === profile.auth0UserId
                    ) {
                      return <div />;
                    }

                    const student = students.find(
                      (student: IUser) => student.auth0UserId === auth0UserId,
                    );

                    return (
                      <Channel
                        profile={profile}
                        key={chnl.uniqueName}
                        channel={chnl}
                        onThreadClick={onThreadClick}
                        student={student}
                        moveChannelToTop={moveChannelToTop}
                        setUnreadMessage={setUnreadMessage}
                        unreadMessageChannels={unreadMessageChannels}
                      />
                    );
                  })
                : null}
            </CustomScrollbars>
          </div>
        </div>
      </div>
      {!showMessageThread && twilioChannel && (
        <Conversation
          twilioChannel={twilioChannel}
          showThreadList={showThreadList}
          studentData={students.find(
            (student: IUser) =>
              student.auth0UserId === twilioChannel.uniqueName.split(",").pop(),
          )}
        />
      )}
      <Modal
        className="modal-block"
        isOpen={isAvailabilityOpen}
        onRequestClose={() => setAvailabilityOpen(false)}
        ariaHideApp={false}
      >
        <ToggleAvailability
          closeModal={() => setAvailabilityOpen(false)}
          updateAvailability={updateAvailabilityStatus}
          availability={availability}
        />
      </Modal>
      <Modal
        className="modal-block"
        isOpen={openNewMessage}
        onRequestClose={() => setOpenNewMessage(false)}
        ariaHideApp={false}
      >
        <NewMessage
          showChatHistoryOfAChannel={showChatHistoryOfAChannel}
          closeModal={() => setOpenNewMessage(false)}
        />
      </Modal>
    </>
  );
};

interface State {
  chat: { isChatOpen: boolean; unreadMessageChannels: ChannelType[] };
  communication: {
    isIncomingCall: Boolean;
    isBusy: Boolean;
    data: ICall;
  };
  availability: { availability: IAvailability };
}

const mapStateToProps = (state: State) => ({
  isChatOpen: state.chat.isChatOpen,
  unreadMessageChannels: state.chat.unreadMessageChannels,
  callData: state.communication.data,
  isBusy: state.communication.isBusy,
  availability: state.availability.availability,
});

const mapDispatchToProps = {
  fetchStudents,
  addCall,
  endCall,
  setUnreadMessage,
  updateAvailability,
  setAvailability,
  fetchAvailability,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
