import Modal from "react-modal";
import { useEffect, useState } from "react";

import TermUpdate from "pages/terms/update";

const ActionCell = ({ row, loadTerms }: any) => {
  const [isEditOpen, setEditOpen] = useState<any>(false);
  const toggleModal = () => {
    setEditOpen(!isEditOpen);
  };
  const getInitialValues = () => {
    return {
      academicYear: row.original.yearId._id,
      termTitle: row.original.name,
      startDate: row.original.startDate,
      endDate: row.original.endDate,
      isCurrent: row.original.isCurrent,
      learningCenters: row.original.centers,
    };
  };

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <>
      <div className="action-group">
        <span className="link-item mr-2x" onClick={toggleModal}>
          <box-icon name="edit-alt" color="#959FAE" size="sm"></box-icon>
        </span>
      </div>
      <Modal isOpen={isEditOpen} className="modal-block">
        <TermUpdate
          initialValues={getInitialValues()}
          id={row.original._id}
          closeModal={toggleModal}
          loadTerms={loadTerms}
          isTermUsed={!!row.original.subjects.length}
        />
      </Modal>
    </>
  );
};
export default ActionCell;
