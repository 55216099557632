export enum FetchPlanningPeriodsActionType {
  FETCH_PLANNING_PERIOD_PENDING = "FETCH_PLANNING_PERIOD_PENDING",
  FETCH_PLANNING_PERIOD_FULFILLED = "FETCH_PLANNING_PERIOD_FULFILLED",
  FETCH_PLANNING_PERIOD_REJECTED = "FETCH_PLANNING_PERIOD_REJECTED",
}

export interface FetchPlanningPeriodsAction {
  type: FetchPlanningPeriodsActionType;
  data: any;
}
