import {
  AddSponsorAction,
  updateSponsorAction,
  FetchSponsorsAction,
  AddSponsorActionType,
  UpdateSponsorActionType,
  FetchSponsorsActionType,
} from "store/actions/types/sponsors";

const INITIAL_STATE = {
  addSponsor: {},
  addSponsorLoading: false,
  addSponsorError: null,
  fetchSponsors: [],
  fetchSponsorsLoading: false,
  fetchSponsorsError: null,
  updateSponsor: {},
  updateSponsorLoading: false,
  updateSponsorError: null,
};

const reducer = (
  state = INITIAL_STATE,
  action: AddSponsorAction | updateSponsorAction | FetchSponsorsAction,
) => {
  switch (action.type) {
    case AddSponsorActionType.ADD_SPONSOR_PENDING:
      return {
        ...state,
        addSponsorLoading: true,
        addSponsorError: null,
      };
    case AddSponsorActionType.ADD_SPONSOR_FULFILLED:
      return {
        ...state,
        addSponsor: action.data,
        addSponsorLoading: false,
        addSponsorError: null,
      };
    case AddSponsorActionType.ADD_SPONSOR_REJECTED:
      return {
        ...state,
        addSponsorLoading: false,
        addSponsorError: action.data,
      };
    case UpdateSponsorActionType.UPDATE_SPONSOR_PENDING:
      return {
        ...state,
        updateSponsorLoading: true,
        updateSponsorError: null,
      };
    case UpdateSponsorActionType.UPDATE_SPONSOR_FULFILLED:
      return {
        ...state,
        updateSponsor: action.data,
        updateSponsorLoading: false,
        updateSponsorError: null,
      };
    case UpdateSponsorActionType.UPDATE_SPONSOR_REJECTED:
      return {
        ...state,
        updateSponsorLoading: false,
        updateSponsorError: action.data,
      };

    case FetchSponsorsActionType.FETCH_SPONSORS_PENDING:
      return {
        ...state,
        fetchSponsorsLoading: true,
        fetchSponsorsError: null,
      };
    case FetchSponsorsActionType.FETCH_SPONSORS_FULFILLED:
      return {
        ...state,
        fetchSponsors: action.data,
        fetchSponsorsLoading: false,
        fetchSponsorsError: null,
      };
    case FetchSponsorsActionType.FETCH_SPONSORS_REJECTED:
      return {
        ...state,
        fetchSponsorsLoading: false,
        fetchSponsorsError: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
