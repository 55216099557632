import { IUser, PagedIUser } from "commons/types/users";

export enum AddStudentActionType {
  ADD_STUDENT_PENDING = "ADD_STUDENT_PENDING",
  ADD_STUDENT_REJECTED = "ADD_STUDENT_REJECTED",
  ADD_STUDENT_FULFILLED = "ADD_STUDENT_FULFILLED",
}

export interface AddStudentAction {
  type: AddStudentActionType;
  data: IUser;
}

export enum FetchStudentActionType {
  FETCH_STUDENT_PENDING = "FETCH_STUDENT_PENDING",
  FETCH_STUDENT_REJECTED = "FETCH_STUDENT_REJECTED",
  FETCH_STUDENT_FULFILLED = "FETCH_STUDENT_FULFILLED",
}

export interface fetchStudentAction {
  type: FetchStudentActionType;
  data: IUser;
}

export enum UpdateStudentActionType {
  UPDATE_STUDENT_PENDING = "UPDATE_STUDENT_PENDING",
  UPDATE_STUDENT_REJECTED = "UPDATE_STUDENT_REJECTED",
  UPDATE_STUDENT_FULFILLED = "UPDATE_STUDENT_FULFILLED",
}

export interface updateStudentAction {
  type: UpdateStudentActionType;
  data: IUser;
}

export enum FetchStudentsActionType {
  FETCH_STUDENTS_PENDING = "FETCH_STUDENTS_PENDING",
  FETCH_STUDENTS_REJECTED = "FETCH_STUDENTS_REJECTED",
  FETCH_STUDENTS_FULFILLED = "FETCH_STUDENTS_FULFILLED",
}

export interface FetchStudentsAction {
  type: FetchStudentsActionType;
  data: IUser[];
}

export enum FetchPagedStudentsActionType {
  FETCH_PAGED_STUDENTS_PENDING = "FETCH_PAGED_STUDENTS_PENDING",
  FETCH_PAGED_STUDENTS_REJECTED = "FETCH_PAGED_STUDENTS_REJECTED",
  FETCH_PAGED_STUDENTS_FULFILLED = "FETCH_PAGED_STUDENTS_FULFILLED",
}

export enum StudentFilterActionType {
  SET_TERM = "SET_TERM",
  RESET_TERM = "RESET_TERM",
  SET_FILTER = "SET_FILTER",
  RESET_FILTER = "RESET_FILTER",
  SET_TERM_STATUS = "SET_TERM_STATUS",
  SET_USER_STATUS = "SET_USER_STATUS",
  RESET_TERM_STATUS = "RESET_TERM_STATUS",
  RESET_USER_STATUS = "RESET_USER_STATUS",
  SET_LEARNING_CENTER = "SET_LEARNING_CENTER",
  RESET_LEARNING_CENTER = "RESET_LEARNING_CENTER",
  SET_PAGINATION = "SET_PAGINATION",
  RESET_PAGINATION = "RESET_PAGINATION",
}

export interface FetchPagedStudentsAction {
  type: FetchPagedStudentsActionType;
  data: PagedIUser;
}
