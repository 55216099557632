import React from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";

import { IProfile } from "commons/types/users";
import { ICreateResponseRevision } from "commons/types/responseRevision";
import { SurveyResponse, SurveyQuestions } from "commons/types/surveys";
import useMountedRef from "commons/hooks/useMountedRef";

interface QuestionItemProps {
  index: number;
  profile: IProfile;
  isEditable: boolean;
  showRevised: () => void;
  question: SurveyQuestions;
  responseData: SurveyResponse;
  aggregatedValue?: number;
  createResponseRevision: (
    responseId: string,
    payload: ICreateResponseRevision,
  ) => Promise<any>;
}

const QuestionItem: React.FC<QuestionItemProps> = (props) => {
  const {
    index,
    profile,
    question,
    isEditable,
    showRevised,
    responseData,
    aggregatedValue,
    createResponseRevision,
  } = props;

  const isMounted = useMountedRef();

  const [showNotes, setShowNotes] = React.useState(false);
  const [text, setText] = React.useState<string | undefined>();
  const [sliderValue, setSliderValue] = React.useState<number | undefined>();
  const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(false);

  const SliderWithTooltip = createSliderWithTooltip(Slider);

  const responses = responseData.result;

  // Need to sort response on the basis of order
  const sortQuestionOption = (question: SurveyQuestions) => {
    const options = question.data.options;
    // Sorting order on basis of question type
    if (question.question.isPositive) {
      options.sort((surveyQuestionA, surveyQuestionB) =>
        surveyQuestionA.order > surveyQuestionB.order
          ? 1
          : surveyQuestionB.order > surveyQuestionA.order
          ? -1
          : 0,
      );
    } else {
      options.sort((surveyQuestionA, surveyQuestionB) =>
        surveyQuestionA.order < surveyQuestionB.order
          ? 1
          : surveyQuestionB.order > surveyQuestionA.order
          ? -1
          : 0,
      );
    }
    return options;
  };

  const sortedOption = sortQuestionOption(question);

  const answerLabel = sortedOption.map((option) => {
    window.optionsMap[option._id] = option;

    return {
      label: option.label,
    };
  });

  /**
   * Find answer of question with given Id
   * @param questionId
   * @returns answer
   */
  const findAnswerId = (questionId: string) => {
    for (const responsesData of responses) {
      for (const response of responsesData.responses) {
        if (response.question === questionId) {
          return response.answer?.answer;
        }
      }
    }
  };

  /**
   * Map response to answer in slider
   * @param answerId
   * @returns index of answer
   */
  const mapResponseToAnswer = (answerId: string | undefined) => {
    if (answerId) {
      const index = sortedOption.map((option) => option._id).indexOf(answerId);

      return index;
    }

    return 0;
  };

  const mapResponseToScore = (answerId: string | undefined) => {
    if (answerId) {
      const mappedAnswer = sortedOption.find(
        (option) => option._id === answerId,
      );
      if (mappedAnswer) {
        return `${mappedAnswer.value} (${mappedAnswer.label})`;
      }
      return "N/A";
    }
    return "N/A";
  };

  const mapToolTipValueToAnswerValue = (value: number) => {
    const options = sortedOption.map((option) => option.value);

    return options[value];
  };

  const onQuestionClick = () => {
    if (isEditable && isMounted) {
      setShowNotes(true);
    }
  };

  const mapSliderToOptionId = (sliderValue: number) => {
    return sortedOption[sliderValue]._id;
  };

  const onSaveClick = async () => {
    const questionId = question.question._id;
    let skillSetId = "";
    let oldAnswer: string[] = [];
    setIsSaveDisabled(true);

    for (const responsesData of responses) {
      for (const response of responsesData.responses) {
        if (response.question === questionId) {
          oldAnswer = [response.answer.answer] || [];
          skillSetId = responsesData.skillset._id || "";

          break;
        }
      }
    }

    const revisionPayload: ICreateResponseRevision = {
      skillsetId: skillSetId || "",
      questionId: questionId,
      type: question.answerType.type,
      answer: {
        answer:
          sliderValue !== undefined
            ? mapSliderToOptionId(+sliderValue)
            : oldAnswer,
        value: sliderValue !== undefined ? (+sliderValue + 1)?.toString() : "1",
        notes: text ? [{ note: text }] : [],
      },
      createdBy: profile._id || "",
    };

    const updatedResponse = await createResponseRevision(
      responseData._id,
      revisionPayload,
    );

    if (updatedResponse && isMounted) {
      setText("");
      setShowNotes(false);
      setIsSaveDisabled(false);
    }
  };

  const mapResponseRevision = (questionId: string) => {
    let responseRevisionOfQuestion: any[] = [];

    for (const responsesData of responses) {
      for (const response of responsesData.responses) {
        if (response.question === questionId) {
          responseRevisionOfQuestion = response.revisions || [];

          break;
        }
      }
    }

    const answerId = findAnswerId(questionId);

    if (responseRevisionOfQuestion.length > 0 && !aggregatedValue) {
      const previousAnswerId =
        responseRevisionOfQuestion[responseRevisionOfQuestion.length - 1]
          ?.answer;

      return (
        <>
          <ul className="card-info">
            <box-icon name="reply" />
            <li>
              Revised Skill Score:
              <strong className="ml-2x">{mapResponseToScore(answerId)}</strong>
              <div className="revised" onClick={showRevised}>
                <box-icon name="note" size="bold" color="#1D70B8" />
                <p>Revised</p>
              </div>
            </li>
          </ul>
          <ul className="card-info">
            <box-icon name="reply" />
            <li>
              Previous Skill Score:&nbsp;{mapResponseToScore(previousAnswerId)}
            </li>
          </ul>

          <div
            style={{
              width: "96%",
              paddingTop: 62,
            }}
          >
            <div className="slider-wrapper">
              <SliderWithTooltip
                min={0}
                tipFormatter={(value) => mapToolTipValueToAnswerValue(value)}
                tipProps={{
                  visible: true,
                }}
                max={4}
                marks={answerLabel}
                value={sliderValue}
                trackStyle={{ backgroundColor: "#1D70B8" }}
                railStyle={{ backgroundColor: "#CED2D3" }}
                onChange={(value) => {
                  if (isMounted) {
                    setSliderValue(value);
                    setShowNotes(true);
                  }
                }}
                onBeforeChange={(value) => {
                  if (isMounted) {
                    setSliderValue(value);
                    setShowNotes(true);
                  }
                }}
                defaultValue={
                  aggregatedValue
                    ? aggregatedValue
                    : mapResponseToAnswer(answerId)
                }
                disabled={!isEditable}
              />
              {showNotes && (
                <div className="pt-6x">
                  <div className="input-wrap mb-2x">
                    <label className="input__label">Notes</label>
                    <textarea
                      className="textarea"
                      name="message"
                      placeholder="Add Notes"
                      onChange={(e) => isMounted && setText(e.target.value)}
                    >
                      {text}
                    </textarea>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex">
                      <button
                        className="btn btn--primary mr-4x"
                        type="button"
                        onClick={onSaveClick}
                        disabled={!text || isSaveDisabled}
                      >
                        Save
                      </button>
                      <button
                        className="btn"
                        type="button"
                        onClick={() => {
                          if (isMounted) {
                            setSliderValue(mapResponseToAnswer(answerId));
                            setShowNotes(false);
                            setIsSaveDisabled(false);
                            setText("");
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <ul className="card-info">
          <box-icon name="reply" />
          {aggregatedValue ? (
            <li>
              Aggregate Skill Score:&nbsp;
              <strong>{Number(aggregatedValue).toFixed(2)}</strong>
            </li>
          ) : (
            <li>
              Skill Score:&nbsp;
              <strong>{mapResponseToScore(answerId)}</strong>
            </li>
          )}
        </ul>
        <div
          style={{
            width: "96%",
            paddingTop: 62,
          }}
        >
          <div className="slider-wrapper">
            <SliderWithTooltip
              min={0}
              tipFormatter={(value) => mapToolTipValueToAnswerValue(value)}
              tipProps={{
                visible: true,
              }}
              max={4}
              marks={answerLabel}
              value={sliderValue}
              trackStyle={{ backgroundColor: "#1D70B8" }}
              railStyle={{ backgroundColor: "#CED2D3" }}
              onChange={(value) => {
                if (isMounted) {
                  setSliderValue(value);
                  setShowNotes(true);
                }
              }}
              onBeforeChange={(value) => {
                if (isMounted) {
                  setSliderValue(value);
                  setShowNotes(true);
                }
              }}
              defaultValue={
                aggregatedValue
                  ? question.question.isPositive
                    ? Math.round(aggregatedValue) - 1
                    : 4 - (Math.round(aggregatedValue) - 1)
                  : mapResponseToAnswer(answerId)
              }
              disabled={!isEditable}
            />
            {showNotes && (
              <div className="pt-6x">
                <div className="input-wrap mb-2x">
                  <label className="input__label">Notes</label>
                  <textarea
                    className="textarea"
                    name="message"
                    placeholder="Add Notes"
                    onChange={(e) => isMounted && setText(e.target.value)}
                  >
                    {text}
                  </textarea>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn--primary mr-4x"
                      type="button"
                      onClick={onSaveClick}
                      disabled={!text}
                    >
                      Save
                    </button>
                    <button
                      className="btn"
                      type="button"
                      onClick={() => {
                        if (isMounted) {
                          setSliderValue(mapResponseToAnswer(answerId));
                          setShowNotes(false);
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="card align-items-center">
      <div style={{ width: "100%" }}>
        <div className="card-content">
          <div style={{ width: "100%" }}>
            <div className="card-text">
              <div className="text-light-md mb-2x">
                {question.question?.skill?.name}
              </div>
              <div className="card-head p link-item" onClick={onQuestionClick}>
                <h3>{`${index + 1}. ${
                  question.question.title[responseData.role] ||
                  question.question.title["Subject"]
                }`}</h3>
              </div>
              {mapResponseRevision(question.question._id)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
