import { Formik } from "formik";
import Select from "react-select";
import { connect } from "react-redux";
import { FormEvent, useState, useEffect } from "react";

import { ISponsor, IUser } from "commons/types/users";
import { sponsorSchema } from "commons/schemas/sponsor";
import { TextInput } from "commons/components/form-fields";
import {
  DUPLICATE_USER_STATUS_CODE,
  LIVING_SCHEDULE_WITH_PARENTS,
} from "constants/index";
import PhoneNumber from "commons/components/form-fields/PhoneNumber";
import { RELATIONS_OPTIONS, PRIMARY_GUARDIAN_RELATIONS } from "constants/index";

interface Props {
  isUpdate?: boolean;
  initialValues: IUser;
  addSponsorError: any;
  closeModal: () => void;
  existingSponsors?: ISponsor[];
  handleSubmit: (data: IUser) => void;
}

interface FormikProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleBlur: (event: FormEvent) => void;
  handleChange: (event: FormEvent) => void;
  setFieldValue: (field: string, value: any) => void;
  values: any;
  touched: any;
  errors: any;
  isSubmitting: boolean;
}

const SponsorForm: React.FC<Props> = ({
  closeModal,
  handleSubmit,
  initialValues,
  addSponsorError,
  existingSponsors,
  isUpdate = false,
}): JSX.Element => {
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    if (addSponsorError?.response?.status === DUPLICATE_USER_STATUS_CODE) {
      setEmailError("Email already exists");
    } else {
      setEmailError("");
    }
  }, [addSponsorError]);

  const livingScheduleWithParentsOption = (props: FormikProps) => [
    {
      id: "primary-parent",
      label: "Primary Parent",
      value: "Primary Parent",
      disabled:
        existingSponsors &&
        existingSponsors.some(
          (sponsor: ISponsor) =>
            sponsor.livingScheduleWithParents === "Primary Parent" &&
            (!initialValues._id || initialValues._id !== sponsor.userId._id),
        ),
      isChecked: props.values.livingScheduleWithParents === "Primary Parent",
    },
    {
      id: "50/50",
      label: "50/50",
      value: "50/50",
      isChecked: props.values.livingScheduleWithParents === "50/50",
    },
    {
      id: "other",
      label: "Other",
      value: "",
      isChecked:
        !LIVING_SCHEDULE_WITH_PARENTS.includes(
          props.values.livingScheduleWithParents,
        ) && props.values.livingScheduleWithParents !== null,
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data: IUser) => {
        await handleSubmit(data);
      }}
      validationSchema={sponsorSchema}
    >
      {(props: FormikProps) => (
        <form onSubmit={props.handleSubmit}>
          <div className="modal-wrap__body">
            <div className="row">
              <div className="col-6">
                <TextInput
                  label="First Name"
                  placeholder="Enter first name"
                  name="firstName"
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  required={true}
                />
              </div>
              <div className="col-6">
                <TextInput
                  label="Last Name"
                  placeholder="Enter last name"
                  name="lastName"
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <PhoneNumber
                  label="Phone Number"
                  placeholder="+1 123-456-7890"
                  name="phoneNumber"
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label="Email Address"
                  placeholder="Enter email"
                  name="email"
                  required={true}
                  disabled={isUpdate}
                  values={props.values}
                  errors={
                    emailError && !isUpdate && props.values.email
                      ? { email: emailError }
                      : props.errors
                  }
                  touched={props.touched}
                  handleChange={(e: React.ChangeEvent<any>) => {
                    setEmailError("");
                    props.handleChange(e);
                  }}
                  handleBlur={props.handleBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-wrap">
                  <label className="input__label required">
                    Relationship with the Student
                  </label>
                  <Select
                    placeholder="Please select"
                    name="relationshipWithSponsor"
                    onChange={(selected: any) =>
                      props.setFieldValue(
                        "relationshipWithSponsor",
                        selected.value,
                      )
                    }
                    options={RELATIONS_OPTIONS}
                    onBlur={props.handleBlur}
                    classNamePrefix={`react-select ${
                      props.touched.relationshipWithSponsor &&
                      props.errors.relationshipWithSponsor &&
                      "react-select--error"
                    }`}
                    value={RELATIONS_OPTIONS.filter(
                      (option) =>
                        option.value === props.values.relationshipWithSponsor ||
                        (!PRIMARY_GUARDIAN_RELATIONS.includes(
                          props.values.relationshipWithSponsor,
                        ) &&
                          !PRIMARY_GUARDIAN_RELATIONS.includes(option.value) &&
                          props.values.relationshipWithSponsor !== null),
                    )}
                  />
                  {!PRIMARY_GUARDIAN_RELATIONS.includes(
                    props.values.relationshipWithSponsor,
                  ) &&
                    props.values.relationshipWithSponsor !== null && (
                      <div className="row mt-2x">
                        <div className="col-12">
                          <input
                            type="text"
                            className={
                              props.touched.relationshipWithSponsor &&
                              props.errors.relationshipWithSponsor
                                ? "input input--error"
                                : "input"
                            }
                            placeholder="Please specify"
                            name="relationshipWithSponsor"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.relationshipWithSponsor}
                          />
                        </div>
                      </div>
                    )}
                  {props.touched.relationshipWithSponsor &&
                    props.errors.relationshipWithSponsor && (
                      <label className="input__error">
                        Relationship with the sponsor is required!
                      </label>
                    )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label className="input__label required">
                  Living Schedule with Parents
                </label>

                <div className="radio-inline mb-0x">
                  <div className="radio-options">
                    {livingScheduleWithParentsOption(props).map(
                      (
                        { label, id, value, isChecked, disabled }: any,
                        index: number,
                      ) => (
                        <div key={`checkbox-${index}`} className="input-wrap">
                          <input
                            className="box-links__nodes"
                            type="radio"
                            name="livingScheduleWithParents"
                            value={value}
                            checked={isChecked}
                            onChange={props.handleChange}
                            id={id}
                            disabled={disabled}
                          />
                          <label
                            className={`input__label${
                              disabled && " button-disabled"
                            }`}
                            htmlFor={id}
                          >
                            {label}
                          </label>
                        </div>
                      ),
                    )}
                  </div>
                  {!LIVING_SCHEDULE_WITH_PARENTS.includes(
                    props.values.livingScheduleWithParents,
                  ) &&
                    props.values.livingScheduleWithParents !== null && (
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            className={
                              props.touched.livingScheduleWithParents &&
                              props.errors.livingScheduleWithParents
                                ? "input input--error"
                                : "input"
                            }
                            placeholder="Please specify"
                            name="livingScheduleWithParents"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.livingScheduleWithParents}
                          />
                        </div>
                      </div>
                    )}
                  {props.touched.livingScheduleWithParents &&
                    props.errors.livingScheduleWithParents && (
                      <label className="input__error">
                        Living schedule with parents is required!
                      </label>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-wrap__footer">
            <div className="row">
              <div className="col-12 d-flex">
                <button
                  disabled={props.isSubmitting}
                  className="btn btn--primary mr-4x"
                  type="submit"
                >
                  {isUpdate ? "Update" : "Add Parent or Guardian"}
                </button>
                <button
                  disabled={props.isSubmitting}
                  className="btn txt-primary-color"
                  type="button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: { sponsors: { addSponsorError: any } }) => ({
  addSponsorError: state.sponsors.addSponsorError,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorForm);
