export enum AddCourseActionType {
  ADD_COURSE_PENDING = "ADD_COURSE_PENDING",
  ADD_COURSE_FULFILLED = "ADD_COURSE_FULFILLED",
  ADD_COURSE_REJECTED = "ADD_COURSE_REJECTED",
}

export interface AddCourseAction {
  type: AddCourseActionType;
  data: any;
}

export enum FetchCoursesActionType {
  FETCH_COURSES_PENDING = "FETCH_COURSES_PENDING",
  FETCH_COURSES_FULFILLED = "FETCH_COURSES_FULFILLED",
  FETCH_COURSES_REJECTED = "FETCH_COURSES_REJECTED",
}

export interface FetchCoursesAction {
  type: FetchCoursesActionType;
  data: any;
}

export enum UpdateCourseActionType {
  UPDATE_COURSE_PENDING = "UPDATE_COURSE_PENDING",
  UPDATE_COURSE_FULFILLED = "UPDATE_COURSE_FULFILLED",
  UPDATE_COURSE_REJECTED = "UPDATE_COURSE_REJECTED",
}

export interface UpdateCourseAction {
  type: UpdateCourseActionType;
  data: any;
}

export enum DeleteCourseActionType {
  DELETE_COURSE_PENDING = "DELETE_COURSE_PENDING",
  DELETE_COURSE_FULFILLED = "DELETE_COURSE_FULFILLED",
  DELETE_COURSE_REJECTED = "DELETE_COURSE_REJECTED",
}
