import { FC, ReactElement, useEffect, useCallback, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "protected-route";

import config from "configs";
import TopNavbar from "./TopNavbar";
import TenantList from "pages/superadmin/tenantlist";
import UsersList from "pages/superadmin/userslist";
import AddEditTenant from "pages/superadmin/tenantlist/components/AddEditTenant";
import { useAuth0 } from "@auth0/auth0-react";
import { setDataToLocalStorage } from "services/localStorage";
import Loader from "../Loader";

const PermissionDenied = () => {
  return (
    <div className="pt-5">
      <h3>
        Sorry!, you don't have a permission to view this page
        <br></br>
        <b>Permission denied</b>
      </h3>
    </div>
  );
};

// const topNavOptions = [
//   {
//     label: "Tenants",
//     path: config.uiPath.superAdmin.tenantList,
//     link: config.uiPath.superAdmin.tenantList,
//   },
// {
//   label: "Users",
//   path: config.uiPath.superAdmin.usersList,
//   link: config.uiPath.superAdmin.usersList,
// },
// ];

interface ISuperAdminDashboardProps {}

const SuperAdminDashboard: FC<ISuperAdminDashboardProps> = (): ReactElement => {
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();

  const [isProfileInfoFetched, setIsProfileInfoFetched] = useState(false);

  const fetchProfileInfo = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      setDataToLocalStorage("profile", { authToken: token });
      setIsProfileInfoFetched(true);
    } catch (err) {
      console.error("Error in fetchProfileInfo", err);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated === false && isLoading === false) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfileInfo();
    }
  }, [fetchProfileInfo, isAuthenticated]);

  if (isProfileInfoFetched === false) {
    return <Loader type="ThreeDots" />;
  }

  return (
    <Router>
      <TopNavbar options={[]} />
      <div className="main-wrapper">
        <Switch>
          {/* <ProtectedRoute exact path={`${path}`} component={TenantList} /> */}
          <ProtectedRoute exact path="/" component={TenantList} />
          {/* <ProtectedRoute
            exact
            path={config.uiPath.superAdmin.tenantList}
            component={TenantList}
          /> */}
          <ProtectedRoute
            exact
            path={config.uiPath.superAdmin.tenantUserList}
            component={UsersList}
          />
          <ProtectedRoute
            exact
            path={config.uiPath.superAdmin.addTenant}
            component={AddEditTenant}
          />
          <ProtectedRoute
            exact
            path={config.uiPath.superAdmin.editTenant}
            component={AddEditTenant}
          />
          <Route path="/permission-denied" component={PermissionDenied} />
          <Route path="/*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </Router>
  );
};

export default SuperAdminDashboard;
