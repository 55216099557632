import React from "react";
import { useFormik } from "formik";

import { TextInput } from "commons/components/form-fields";
import AcademicYearSchema from "commons/schemas/academicYear";
import { IAcademicYear } from "../../commons/types/academicYear";
import { IAcademicYearFormProps } from "./academicYear.interface";

const Form: React.FC<IAcademicYearFormProps> = ({
  closeModal,
  initialValues,
  isEdit = false,
  saveAcademicYear,
  updateAcademicYear,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (academicYear: IAcademicYear) => {
      if (!isEdit && saveAcademicYear) {
        return saveAcademicYear(academicYear);
      }

      if (updateAcademicYear) {
        updateAcademicYear(initialValues._id, academicYear);
      }
    },
    validationSchema: AcademicYearSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body pb-0x">
        <div className="row">
          <div className="col-12">
            <TextInput
              label="Year"
              name="year"
              placeholder="Enter Academic Year"
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {isEdit ? "Update" : "Save"}
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
