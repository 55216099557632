import config from "configs";
import { http, stringify } from "utils/http";

export interface IPlanningPeriodsParams {
  user?: string;
  term?: string;
  date?: string;
  endAfter?: string;
}

export const fetchPlanningPeriods = async (
  params: IPlanningPeriodsParams = {},
) => {
  const url = stringify(config.endpoints.planningPeriods.all, params);
  const { data } = await http.get(url);
  return data;
};
