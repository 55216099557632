import "boxicons";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import config from "configs";
import { fetchLocations, fetchPrograms } from "services/tenant";

import { error } from "utils/toast";
import EnrollForm from "./EnrollForm";
import { getFullName } from "utils/names";
import { ITerm } from "commons/types/terms";
import { TermStatus } from "constants/term";
import Loader from "commons/components/Loader";
import Confirm from "commons/components/Confirm";
import { getStudentFormValues } from "utils/user";
import { IPrograms } from "commons/types/programs";
import { ILocation, IMentor } from "commons/types";
import { fetchTerms } from "store/actions/data/terms";
import { IEnrollment, IUser } from "commons/types/users";
import { fetchMentors } from "store/actions/data/mentors";
import { addSponsor, updateSponsor } from "store/actions/data/sponsors";
import { updateStudent, fetchStudent } from "store/actions/data/students";
import { studentEnrollment, updateStudentEnrollment } from "services/students";

type Props = {
  terms: ITerm[];
  student: IUser;
  mentors: IMentor[];
  fetchTerms: () => {};
  fetchMentors: () => {};
  match: { params: { id: string; index: number } };
  fetchStudent: (id: string) => Promise<IUser>;
};

// UserId is mentor id
const defaultEmpty = {
  learningCenter: "",
  satelliteLocation: null,
  enrollmentDate: "",
  enrolledTerm: "",
  practitioners: [],
  mentorshipNote: "",
  status: "Draft",
  mentorshipStartDate: "",
  mentorshipSchedule: {
    old: [],
    latest: {
      cadence: "",
      day: "",
    },
  },
  userId: "",
};

const EnrollStudent: React.FC<Props> = ({
  match: {
    params: { id, index },
  },
  terms,
  mentors,
  student,
  fetchTerms,
  fetchStudent,
  fetchMentors,
}): JSX.Element => {
  useEffect(() => {
    fetchStudent(id);
    fetchMentors();
    fetchTerms();
  }, [id, fetchStudent, fetchMentors, fetchTerms]);

  const history = useHistory();

  const saveEnrollment = async (data: IEnrollment) => {
    if (!data.userId) delete data.userId;

    if (!data.learningCenter) delete data.learningCenter;

    if (!data.enrolledTerm) delete data.enrolledTerm;

    if (!data.mentorshipSchedule?.latest?.day)
      delete data.mentorshipSchedule?.latest?.day;

    let studentEnrollmentResponse;
    if (isUpdate)
      studentEnrollmentResponse = await updateStudentEnrollment(id, data);
    else studentEnrollmentResponse = await studentEnrollment(id, data);

    if (studentEnrollmentResponse) {
      if (!isUpdate && data.status === "Enrolled") {
        const studentName = getFullName(student);
        setNotify({
          active: true,
          message: {
            header: "Student enrolled",
            body: `You have successfully enrolled ${studentName}. If you want to see it later, find it by going to the student list view.`,
          },
          action: () => {
            history.push(config.uiPath.students.list);
          },
        });
        return;
      }

      history.push(config.uiPath.students.list);
    }
  };

  const [institutions, setInstitutions] = useState<ILocation[]>([]);

  const [programs, setPrograms] = useState<IPrograms[]>([]);

  const [isUpdate, setIsUpdate] = useState(false);

  const [initialValues, setInitialValues] = useState<any>(defaultEmpty);

  const [isLoading, setIsLoading] = useState(true);

  const [notify, setNotify] = useState({
    active: false,
    message: { header: "", body: "" },
    action: () => {},
  });

  useEffect(() => {
    if (
      student?.userData?.enrollments &&
      student?.userData?.enrollments?.length > index
    ) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [index, student]);

  useEffect(() => {
    const enrollments = getStudentFormValues(student).userData?.enrollments;
    if (enrollments.length && enrollments[index]) {
      const newInitialValues = {
        ...enrollments[index],
        userId: enrollments[index].practitioners?.length
          ? enrollments[index].practitioners[0].practitioner?._id
          : null,
      };
      newInitialValues && setInitialValues(newInitialValues);
    } else {
      setInitialValues(defaultEmpty);
    }
  }, [isUpdate, index, student]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      setIsLoading(true);
      const populateLocationDropDown = async () => {
        try {
          const { data } = await fetchLocations();
          setInstitutions(data);
        } catch (ex) {
          error("Failed to fetch learning centers");
        }
      };
      const populateProgramDropDown = async () => {
        try {
          const { data } = await fetchPrograms();
          setPrograms(data);
        } catch (ex) {
          error("Failed to fetch programs");
        }
      };
      Promise.allSettled([
        populateLocationDropDown(),
        populateProgramDropDown(),
      ]).finally(() => {
        setIsLoading(false);
      });
    };
    fetchDropdownOptions();
  }, []);

  const allowedTerms: ITerm[] = terms.filter((term: ITerm) => {
    if (student.userData?.enrollments?.length) {
      let hasActiveTerm = false;
      for (let i = 0; i < student.userData?.enrollments?.length; i++) {
        const enrollment = student.userData?.enrollments[i];
        if (enrollment.enrolledTerm?.isCurrent) hasActiveTerm = true;
      }

      for (let i = 0; i < student.userData?.enrollments?.length; i++) {
        const enrollment = student.userData?.enrollments[i];
        if (
          (enrollment.enrolledTerm?._id === term?._id &&
            enrollment.status !== TermStatus.Dropped &&
            initialValues.enrolledTerm !== term?._id) ||
          (hasActiveTerm &&
            term?.isCurrent &&
            initialValues.enrolledTerm !== term?._id)
        ) {
          return false;
        }
      }
    }

    return true;
  });

  return (
    <div className="content-wrap pt-8x">
      <div className="container">
        <div className="page-heading">
          <h2>Enroll a Student</h2>
        </div>
        <div className="content-7x">
          {isLoading ? (
            <Loader type="ThreeDots" />
          ) : (
            <EnrollForm
              terms={allowedTerms}
              student={student}
              mentors={mentors}
              isUpdate={isUpdate}
              saveEnrollment={saveEnrollment}
              institutions={institutions}
              initialValues={initialValues}
              programs={programs}
            />
          )}
          <Modal
            className="modal-block"
            isOpen={notify.active}
            ariaHideApp={false}
          >
            <Confirm
              closeModal={() => {
                setNotify({
                  active: false,
                  action: () => {},
                  message: { header: "", body: "" },
                });
                notify.action();
              }}
              isInfo={true}
              cancelText={"Okay"}
              message={notify.message}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  mentors: state.mentors.fetchMentors,
  terms: state.terms.fetchTerms,
  student: state.students.fetchStudent,
});

const mapDispatchToProps = {
  addSponsor,
  fetchTerms,
  updateStudent,
  fetchStudent,
  fetchMentors,
  updateSponsor,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrollStudent);
