import React from "react";
import { ITask } from "commons/types/report";
import dayjs from "dayjs";
import { getDataFromLocalStorage } from "services/localStorage";
import { getNameInitials } from "../../../utils/names";
import { IUser } from "commons/types/users";
import { getShortDayMonth } from "../../../utils/dates";

type Props = {
  tasks: ITask[];
  nextMentoringSession: string | undefined;
};

const TodaysProgress: React.FC<Props> = ({ tasks, nextMentoringSession }) => {
  const profileData: IUser = getDataFromLocalStorage("profile");

  const weeksTask = tasks.filter(
    (task: ITask) =>
      task.requiredDeadline && dayjs(task.requiredDeadline).isToday(),
  );

  const totalTasks = weeksTask.length;
  const completedTasks = weeksTask.filter(
    (task: ITask) => task.isCompleted,
  ).length;

  const progressBarWidth = totalTasks ? (completedTasks / totalTasks) * 100 : 0;

  return (
    <div className="tw-bg-white tw-px-4 tw-py-3 tw-my-2 tw-flex tw-space-x-4 tw-items-center">
      <div className="tw-bg-gray-300 tw-h-12 tw-w-12 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-lg">
        {getNameInitials(profileData)}
      </div>
      <div className="tw-flex-1 tw-space-y-1.5">
        <div className="tw-flex tw-justify-between">
          <span className="tw-font-bold tw-text-black">Today's Progress</span>
          {totalTasks > 0 ? (
            <span>
              {completedTasks}/{totalTasks} tasks completed
            </span>
          ) : (
            <span className="">No tasks</span>
          )}
        </div>
        <div className="tw-w-full tw-bg-gray-100 tw-rounded-full tw-h-2.5">
          <div
            className={`tw-bg-success tw-h-2.5 tw-rounded-full`}
            style={{
              width: progressBarWidth + "%",
            }}
          />
        </div>
        {nextMentoringSession ? (
          <p>
            Your next mentoring session on{" "}
            {getShortDayMonth(nextMentoringSession)}
          </p>
        ) : (
          <p>No mentoring session available.</p>
        )}
      </div>
    </div>
  );
};

export default TodaysProgress;
