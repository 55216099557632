import "boxicons";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import useMountedRef from "commons/hooks/useMountedRef";

import {
  getDateInFormat,
  getDateMonthInFormat,
  isDateInRange,
} from "utils/dates";
import config from "configs";
import AddTask from "pages/tasks/add";
import TaskList from "pages/tasks/list";
import { IGoal } from "./goal.interface";
import Menu from "pages/goals/update/Menu";
import UpdateGoal from "pages/goals/update";
import { IUser } from "commons/types/users";
import { error, success } from "utils/toast";
import Loader from "commons/components/Loader";
import { fetchStudent } from "store/actions/data/students";
import MarkCompleted from "pages/goals/update/MarkCompleted";
import DeleteConfirm from "pages/goals/update/DeleteConfirm";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { IWeeksTasks } from "pages/plans/list/plans.interface";
import { fetchPlanningPeriods } from "services/planningPeriods";
import { IGoal as ITask, IGoalParams } from "commons/types/goals";
import { fetch, deleteUow, updateMany, deleteMany } from "services/uow";
import { addTask, fetchTasks, updateTask } from "store/actions/data/tasks";
import Select, { OptionTypeBase, StylesConfig, components } from "react-select";
import MissingPlanningPeriodsError from "commons/components/errors/missing-planning-periods-error";
import { isStudentLoggedIn } from "utils/window";

const { Option } = components;

type Props = {
  tasks: ITask[];
  fetchTasksLoading: boolean;
  match: { params: { id: string; goalId: string } };
  addTask: (task: ITask) => Promise<ITask>;
  fetchTasks: (params: IGoalParams) => Promise<ITask[]>;
  updateTask: (taskId: string, payload: any) => Promise<ITask>;
  fetchStudent: (id: string) => Promise<IUser>;
  student: IUser;
};

const DEFAULT_PLANNING_PERIOD = {
  _id: "",
  startDate: new Date(),
  endDate: new Date(),
};

const Goal: React.FC<Props> = ({
  match: {
    params: { id, goalId },
  },
  tasks,
  addTask,
  fetchTasks,
  fetchTasksLoading,
  student,
  fetchStudent,
}): JSX.Element => {
  const history = useHistory();
  const isMounted = useMountedRef();

  const [goal, setGoal] = useState<IGoal>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [isAddTaskOpen, setAddTaskOpen] = useState<boolean>(false);
  const [isCompletedOpen, setCompletedOpen] = useState<boolean>(false);
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [planningPeriods, setPlanningPeriods] = useState<IPlanningPeriod[]>([]);
  const [activePlanningPeriod, setActivePlanningPeriod] =
    React.useState<IPlanningPeriod>(DEFAULT_PLANNING_PERIOD);
  const [activeTerm, setActiveTerm] = React.useState("");
  const [isPlanningFetched, setIsPlanningFetched] = useState(false);
  const [showMissingPlanningPeriodsError, setShowMissingPlanningPeriodsError] =
    useState(false);
  const [currentWeekTasks, setCurrentWeekTasks] = useState<ITask[]>([]);

  const activeEnrollment = student.userData?.enrollments?.filter(
    (enrollment) => enrollment.status !== "Draft",
  );

  const generateInitialWeeks = () => {
    let weekGroups: IWeeksTasks = {};

    const getDates = (startDate: Date, endDate: Date) => {
      const dates = [];
      let currentDate = startDate;
      const addDays = (prevDate: Date, days: number) => {
        const date = new Date(prevDate.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };

      while (currentDate <= endDate && dates.length < 7) {
        dates.push(currentDate);
        currentDate = addDays(currentDate, 1);
      }

      return dates;
    };

    if (activePlanningPeriod) {
      getDates(
        new Date(activePlanningPeriod.startDate),
        new Date(activePlanningPeriod.endDate),
      ).forEach((plan) => {
        weekGroups[`${getDateInFormat(plan, "MM-DD-YYYY")}`] = {
          checked: false,
          tasks: [],
          date: plan.toString(),
        };
      });
    } else {
      weekGroups = {};
    }

    return weekGroups;
  };
  const generateTermOption = () => {
    if (activeEnrollment) {
      const activeEnrollmentWithTerms = activeEnrollment.filter(
        (enrollment) => enrollment.enrolledTerm,
      );
      return activeEnrollmentWithTerms.map((enrollment) => {
        return {
          label: enrollment.enrolledTerm.name,
          value: enrollment.enrolledTerm._id,
        };
      });
    }
    return [];
  };

  const getActiveTermOption = () => {
    if (activeEnrollment && activeTerm) {
      return generateTermOption().find((option) => option.value === activeTerm);
    }
    return { label: "Please select", value: "" };
  };

  const customFilterStyle: StylesConfig<OptionTypeBase, false> = {
    control: () => ({
      display: "flex",
      padding: 0,
      justifyContent: "space-between",
    }),
    dropdownIndicator: () => ({
      padding: 0,
    }),
    valueContainer: () => ({
      width: 120,
      position: "relative",
    }),
    menuList: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: (styles) => ({ display: "none" }),
  };

  const fetchPlans = useCallback(
    async (term) => {
      if (student?._id && term) {
        setIsPlanningFetched(false);
        try {
          const planningPeriod = await fetchPlanningPeriods({
            user: student._id,
            term: term,
          });
          if (isMounted) {
            setPlanningPeriods(planningPeriod);
            if (!planningPeriod.length)
              setShowMissingPlanningPeriodsError(true);
            else setShowMissingPlanningPeriodsError(false);

            const activePlanningPeriod: IPlanningPeriod =
              planningPeriod.find((planningPeriod: IPlanningPeriod) =>
                isDateInRange(
                  planningPeriod.startDate,
                  planningPeriod.endDate,
                  new Date(),
                ),
              ) || DEFAULT_PLANNING_PERIOD;

            setActivePlanningPeriod(activePlanningPeriod);
          }
        } catch (err: any) {
          error(err);
        }
      }
      setIsPlanningFetched(true);
    },
    [student._id, isMounted],
  );

  const currentPlanIndex = planningPeriods.findIndex(
    (planningPeriod) =>
      planningPeriod?.startDate.toString() ===
      activePlanningPeriod?.startDate.toString(),
  );

  useEffect(() => {
    if (isMounted) {
      fetchPlans(activeTerm);
    }
  }, [fetchPlans, activeTerm, isMounted]);

  useEffect(() => {
    if (student.userData?.enrollments?.length && student._id === id) {
      const currentEnrollment = student.userData?.enrollments.find(
        (enrollment) => enrollment.enrolledTerm?.isCurrent,
      );
      if (currentEnrollment?.enrolledTerm?._id) {
        setActiveTerm(currentEnrollment.enrolledTerm?._id);
        return;
      }
    }
  }, [student.userData?.enrollments, id, student._id]);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeCompleted = () => {
    setCompletedOpen(false);
  };

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const closeEdit = () => {
    setEditOpen(false);
  };

  const openDelete = () => {
    setDeleteOpen(true);
  };

  const fetchGoalTasks = useCallback(async () => {
    if (!isPlanningFetched) return;

    if (goalId) {
      const startDate = new Date(activePlanningPeriod?.startDate);
      const endDate = new Date(activePlanningPeriod?.endDate);
      const currentTasks = await fetchTasks({
        childOf: goalId,
        assignee: id,
        uowType: "Task",
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        isPublished: true,
      });
      setCurrentWeekTasks(currentTasks);
    }
  }, [
    isPlanningFetched,
    goalId,
    activePlanningPeriod?.startDate,
    activePlanningPeriod?.endDate,
    fetchTasks,
    id,
  ]);

  const getGoal = useCallback(async () => {
    setLoading(true);
    try {
      await fetchGoalTasks();
      const { data }: any = await fetch(goalId);

      if (isMounted) {
        setGoal(data);
        setLoading(false);
      }
    } catch (err: any) {
      if (isMounted) {
        error("Failed to fetch goal");
      }
    }
  }, [fetchGoalTasks, goalId, isMounted]);

  const deleteGoal = async () => {
    try {
      await deleteUow(goalId);
      if (isMounted) {
        success("Goal deleted");
        history.push(
          config.uiPath.students.goals.replace(":id", goal?.assignee._id),
        );
      }
    } catch (err: any) {
      if (isMounted) {
        error("Goal delete failed");
      }
    }
  };

  const saveTask = async (data: ITask) => {
    const task = await addTask(data);

    if (task && isMounted) {
      setAddTaskOpen(false);
      fetchGoalTasks();
    }
    return task;
  };

  const handleBulkTaskUpdate = async (payload: any) => {
    try {
      const updateResult = await updateMany(payload);
      if (updateResult && isMounted) {
        fetchGoalTasks();
      }

      return updateResult;
    } catch (ex) {
      if (isMounted) error("Failed to update tasks");
    }
  };

  const handleBulkTaskDelete = async (ids: string[]) => {
    try {
      const deleteResult = await deleteMany(ids);
      if (deleteResult && isMounted) {
        fetchGoalTasks();
      }

      return deleteResult;
    } catch (ex) {
      if (isMounted) error("Failed to delete tasks");
    }
  };

  useEffect(() => {
    if (goalId && isPlanningFetched) {
      getGoal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId, isPlanningFetched]);

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", closeMenu);
    }

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [isMenuOpen, closeMenu]);

  useEffect(() => {
    if (!(student?._id === id)) {
      fetchStudent(id);
    }
  }, [fetchStudent, id, student?._id]);

  const handleBackClick = () => {
    if (goal?.assignee?._id) {
      history.push(
        isStudentLoggedIn()
          ? config.uiPath.students.studentGoals.replace(":id", id)
          : config.uiPath.students.goals.replace(":id", goal.assignee._id),
      );
    }
  };

  const TermAndPlanningPeriod = () => {
    return (
      <div className="filters border-bottom">
        <div className="term-select">
          <ul className="filters__list">
            <li>
              <Select
                name="term"
                onChange={(selected: any) => {
                  setActiveTerm(selected.value);
                }}
                value={getActiveTermOption()}
                isSearchable={false}
                onBlur={() => {}}
                options={generateTermOption()}
                className="filter-item link-item"
                classNamePrefix="react-select"
                styles={customFilterStyle}
                components={{
                  Option: (props) => {
                    return (
                      <Option {...props}>
                        <span>{props.label}</span>
                      </Option>
                    );
                  },
                }}
              />
            </li>
          </ul>
          <ul className="filters__list mt-1x ml-3x">
            <li
              className="link-item mt-1x"
              onClick={() => {
                if (planningPeriods.length && currentPlanIndex > 0) {
                  setActivePlanningPeriod(
                    planningPeriods[currentPlanIndex - 1],
                  );
                }
              }}
            >
              {planningPeriods.length && currentPlanIndex > 0 ? (
                <box-icon name="chevron-left" />
              ) : (
                <box-icon name="chevron-left" color="#959FAE" />
              )}
            </li>
            <li>
              <span className="txt-darkgrey-color fs-medium txt-bold">
                <Select
                  classNamePrefix="react-select"
                  value={{
                    label: `Week of ${getDateMonthInFormat(
                      activePlanningPeriod?.startDate,
                    )} - ${getDateInFormat(activePlanningPeriod?.endDate)}`,
                    value: activePlanningPeriod?.startDate.toString(),
                  }}
                  onChange={(selected) => {
                    setActivePlanningPeriod(
                      planningPeriods.find(
                        (planningPeriod) =>
                          planningPeriod.startDate.toString() ===
                          selected?.value,
                      ) || DEFAULT_PLANNING_PERIOD,
                    );
                  }}
                  options={planningPeriods.map((planningPeriod) => {
                    return {
                      label: `${getDateMonthInFormat(
                        planningPeriod.startDate,
                      )} - ${getDateInFormat(planningPeriod.endDate)}`,
                      value: planningPeriod.startDate.toString(),
                    };
                  })}
                  isSearchable={false}
                  styles={{
                    dropdownIndicator: () => ({
                      display: "none",
                    }),
                    control: () => ({}),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    indicatorsContainer: () => ({
                      display: "none",
                    }),
                    singleValue: () => ({
                      background: "none",
                      display: "flex",
                      padding: "2px",
                      cursor: "pointer",
                    }),
                  }}
                  components={{
                    Option: (props) => (
                      <Option {...props}>
                        <box-icon name="calendar" size="14px" color="#1d70b8" />
                        <span className="ml-2x">{props.data.label}</span>
                      </Option>
                    ),
                  }}
                />
              </span>
            </li>
            <li
              className="link-item mt-1x"
              onClick={() => {
                if (
                  planningPeriods.length &&
                  currentPlanIndex < planningPeriods.length - 1
                ) {
                  setActivePlanningPeriod(
                    planningPeriods[currentPlanIndex + 1],
                  );
                }
              }}
            >
              {planningPeriods.length &&
              currentPlanIndex < planningPeriods.length - 1 ? (
                <box-icon name="chevron-right" />
              ) : (
                <box-icon name="chevron-right" color="#959FAE" />
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <main>
      {loading ? (
        <Loader type="ThreeDots" />
      ) : (
        <section>
          {showMissingPlanningPeriodsError ? (
            <MissingPlanningPeriodsError student={student} />
          ) : null}
          <div className="page-heading">
            <div className="page-heading__left">
              <div onClick={handleBackClick} className="mt-6x link-item">
                <box-icon name="arrow-back"></box-icon>
              </div>
              <div className="heading-detail">
                <span className="text-light-sm">Goals</span>
                <h2>{goal?.name}</h2>
                {goal?.workData?.metrics?.metricsType && (
                  <ul className="heading-info mt-1x mb-3x">
                    <li className="mr-5x">
                      Metric Type:
                      <span className="ml-1x">
                        <b>{goal.workData?.metrics?.metricsType}</b>
                      </span>
                    </li>
                    <li className="mr-5x">
                      Baseline:
                      <span className="ml-1x">
                        <b>{goal.workData?.metrics?.baselineMetric}</b>
                      </span>
                    </li>
                    <li className="mr-5x">
                      Achieved value:
                      <span className="ml-1x">
                        {goal.workData?.metrics?.achievedMetric ? (
                          <b> {goal.workData?.metrics?.achievedMetric}</b>
                        ) : (
                          "-"
                        )}
                      </span>
                    </li>
                    <li className="edit-icon">
                      <box-icon
                        name="edit-alt"
                        color="#1D70B8"
                        size="sm"
                        onClick={() => setEditOpen(true)}
                      />
                    </li>
                  </ul>
                )}
                <ul className="heading-info round round--horizontal">
                  <li>
                    <box-icon name="bullseye" type="solid" />
                    <span className="ml-2x">
                      {goal?.workData?.workType} Goal
                    </span>
                  </li>
                  <li>
                    Due date{" "}
                    {goal?.requiredDeadline
                      ? getDateInFormat(goal.requiredDeadline)
                      : ""}
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-heading__right align-items-center">
              <button
                onClick={() => setAddTaskOpen(true)}
                className="btn btn--primary mr-2x"
                type="button"
              >
                Add Task
              </button>
              <span className="link-item dropdown-button" onClick={openMenu}>
                <box-icon name="dots-horizontal-rounded"></box-icon>
                {isMenuOpen ? (
                  <Menu
                    editMenu={() => {
                      setEditOpen(true);
                    }}
                    markCompletedMenu={() => {
                      setCompletedOpen(true);
                    }}
                    deleteMenu={openDelete}
                  />
                ) : (
                  <span />
                )}
              </span>
            </div>
          </div>
          <Modal
            className="modal-block"
            isOpen={isEditOpen}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
            ariaHideApp={false}
          >
            <UpdateGoal
              closeModal={closeEdit}
              goal={goal}
              goalRefresh={getGoal}
            />
          </Modal>
          <Modal
            className="modal-block"
            isOpen={isCompletedOpen}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
            ariaHideApp={false}
            onRequestClose={closeCompleted}
          >
            <MarkCompleted
              closeModal={closeCompleted}
              goal={goal}
              refreshGoal={getGoal}
            />
          </Modal>

          <Modal
            className="modal-block"
            isOpen={isDeleteOpen}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
              },
            }}
            ariaHideApp={false}
            onRequestClose={() => setDeleteOpen(false)}
          >
            <DeleteConfirm
              closeModal={() => setDeleteOpen(false)}
              deleteGoal={deleteGoal}
            />
          </Modal>

          <TermAndPlanningPeriod />

          <TaskList
            planningPeriods={planningPeriods}
            goal={goal}
            tasks={tasks}
            saveTask={saveTask}
            loading={fetchTasksLoading}
            fetchTasks={fetchGoalTasks}
            handleBulkTaskUpdate={handleBulkTaskUpdate}
            handleBulkTaskDelete={handleBulkTaskDelete}
            setAddTaskOpen={setAddTaskOpen}
            student={student}
            currentWeekTasks={currentWeekTasks}
            initialWeeks={generateInitialWeeks()}
          />

          <Modal
            isOpen={isAddTaskOpen}
            className="modal-block-task"
            ariaHideApp={false}
            style={{
              content: {
                top: "50%",
                left: "50%",
              },
            }}
            onRequestClose={() => setAddTaskOpen(false)}
          >
            <AddTask
              goal={goal || null}
              saveTask={saveTask}
              closeModal={() => setAddTaskOpen(false)}
              student={student}
            ></AddTask>
          </Modal>
        </section>
      )}
    </main>
  );
};

interface IState {
  tasks: { fetchTasks: ITask[]; fetchTasksLoading: boolean };
  addTask: (task: ITask) => Promise<ITask>;
  students: { fetchStudent: IUser };
}

const mapStateToProps = (state: IState) => ({
  tasks: state.tasks.fetchTasks,
  fetchTasksLoading: state.tasks.fetchTasksLoading,
  student: state.students.fetchStudent,
});

const mapDispatchToProps = {
  addTask,
  fetchTasks,
  updateTask,
  fetchStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Goal);
