import Form from "../Form";
import { IEditSatelliteLocationProps } from "./editSatelliteLocation.interface";

const EditSatelliteLocation: React.FC<IEditSatelliteLocationProps> = ({
  closeModal,
  initialValues,
  editSatelliteLocation,
}) => {
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Edit a Satellite Location</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <Form
        isEdit={true}
        closeModal={closeModal}
        initialValues={initialValues}
        updateSatelliteLocation={editSatelliteLocation}
      />
    </div>
  );
};

export default EditSatelliteLocation;
