import config from "configs";
import pinterpolate from "pinterpolate";

import { http } from "utils/http";
import { IScheduledMessagePayload } from "commons/types/scheduledMessages";

export const list = async (receiver: string) => {
  const url = config.endpoints.scheduledMessages.all;
  const { data } = await http.get(url, {
    params: {
      receiver,
    },
  });
  return data;
};

export const details = async (id: string) => {
  const url = pinterpolate(config.endpoints.scheduledMessages.one, { id });
  const { data } = await http.get(url);
  return data;
};

export const schedule = async (payload: IScheduledMessagePayload) => {
  const url = config.endpoints.scheduledMessages.all;
  const { data } = await http.post(url, payload);
  return data;
};

export const update = async (id: string, payload: IScheduledMessagePayload) => {
  const url = pinterpolate(config.endpoints.scheduledMessages.one, { id });
  const { data } = await http.put(url, payload);
  return data;
};

export const deleteSchedule = async (id: string) => {
  const url = pinterpolate(config.endpoints.scheduledMessages.one, { id });
  const { data } = await http.delete(url);
  return data;
};

export const getTemplates = async () => {
  const url = config.endpoints.scheduledMessages.templates;
  const { data } = await http.get(url);
  return data;
};
