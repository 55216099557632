import React from "react";
import { IStudentEnrollment } from "commons/types/users";
import { getDayFromDate } from "utils/dates";

interface Props {
  enrollment: IStudentEnrollment;
  index: number;
  handleEditMentorOpen: (index: number) => void;
}

const EnrolledTermCard: React.FC<Props> = ({
  enrollment,
  handleEditMentorOpen,
  index,
}) => {
  return (
    <div key={enrollment._id} className="section__content">
      <div className="card card-addinfo">
        <div className="card-content">
          <div className="card-text">
            <span className="d-block">
              {enrollment?.enrolledTerm?.name || "-"}{" "}
              <span className="fs-small"> - {enrollment?.status} </span>
            </span>
            {enrollment?.status === "Draft" ? (
              ""
            ) : (
              <span className="card-address">
                {`Planning Period: ${
                  enrollment?.mentorshipSchedule?.latest?.program?.name
                    ? enrollment?.mentorshipSchedule?.latest?.program?.name
                    : "-"
                }, ${
                  enrollment
                    ? `${getDayFromDate(
                        enrollment?.mentorshipSchedule?.latest?.day || "",
                      )}`
                    : "-"
                }`}
              </span>
            )}
          </div>
        </div>
        <div className="card-actions">
          {enrollment.enrolledTerm && (
            <div
              className="link-item"
              onClick={() => handleEditMentorOpen(index)}
            >
              <box-icon name="edit-alt" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnrolledTermCard;
