import { IGradeType } from "commons/types/gradeTypes";

export enum AddGradeTypeActionType {
  ADD_GRADE_TYPE_PENDING = "ADD_GRADE_TYPE_PENDING",
  ADD_GRADE_TYPE_REJECTED = "ADD_GRADE_TYPE_REJECTED",
  ADD_GRADE_TYPE_FULFILLED = "ADD_GRADE_TYPE_FULFILLED",
}
export interface AddGradeTypeAction {
  type: AddGradeTypeActionType;
  data: IGradeType;
}

export enum FetchGradeTypesActionType {
  FETCH_GRADE_TYPES_PENDING = "FETCH_GRADE_TYPES_PENDING",
  FETCH_GRADE_TYPES_REJECTED = "FETCH_GRADE_TYPES_REJECTED",
  FETCH_GRADE_TYPES_FULFILLED = "FETCH_GRADE_TYPES_FULFILLED",
}
export interface FetchGradeTypesAction {
  type: FetchGradeTypesActionType;
  data: IGradeType[];
}

export enum FetchGradeTypeActionType {
  FETCH_GRADE_TYPE_PENDING = "FETCH_GRADE_TYPE_PENDING",
  FETCH_GRADE_TYPE_REJECTED = "FETCH_GRADE_TYPE_REJECTED",
  FETCH_GRADE_TYPE_FULFILLED = "FETCH_GRADE_TYPE_FULFILLED",
}
export interface FetchGradeTypeAction {
  type: FetchGradeTypeActionType;
  data: IGradeType;
}

export enum UpdateGradeTypeActionType {
  UPDATE_GRADE_TYPE_PENDING = "UPDATE_GRADE_TYPE_PENDING",
  UPDATE_GRADE_TYPE_REJECTED = "UPDATE_GRADE_TYPE_REJECTED",
  UPDATE_GRADE_TYPE_FULFILLED = "UPDATE_GRADE_TYPE_FULFILLED",
}
export interface UpdateGradeTypeAction {
  type: UpdateGradeTypeActionType;
  data: IGradeType;
}

export enum DeleteGradeTypeActionType {
  DELETE_GRADE_TYPE_PENDING = "DELETE_GRADE_TYPE_PENDING",
  DELETE_GRADE_TYPE_FULFILLED = "DELETE_GRADE_TYPE_FULFILLED",
  DELETE_GRADE_TYPE_REJECTED = "DELETE_GRADE_TYPE_REJECTED",
}

export interface DeleteGradeTypeAction {
  type: DeleteGradeTypeActionType;
  data: IGradeType;
}
