import "boxicons";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";
import { useMemo, useState, useEffect, useCallback } from "react";
import { Cell, Column, HeaderGroup, Row, useTable } from "react-table";

import configs from "configs";
import { error } from "utils/toast";
import ActionCell from "./ActionCell";
import { getFullName } from "utils/names";
import { DEBOUNCE_TIME } from "constants/index";
import Loader from "commons/components/Loader";
import { useTranslation } from "react-i18next";
import { fetchMentors } from "services/mentors";
import { MentorInterface } from "./mentor.interface";
import emptyUserImage from "../../../assets/images/empty-user.svg";

const MentorList = () => {
  const { t } = useTranslation();

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: () => t("mentorList.table.mentorName"),
        accessor: "name",
        Cell: ({ row }: any) => {
          return <span className="txt-bold">{row.original.name}</span>;
        },
      },
      {
        Header: () => t("mentorList.table.studentsCount"),
        accessor: "relatedUsers",
        Cell: ({ row }: any) => {
          return row.original.relatedUsers?.subjects?.length || 0;
        },
      },
      {
        Header: () => t("mentorList.table.actions"),
        accessor: "_id",
        Cell: ({ row }: any) => {
          return <ActionCell row={row} loadMentors={loadMentors} />;
        },
      },
    ],
    [t],
  );

  const [data, setData] = useState<any[]>([]);
  const [query, setQuery] = useState("");
  const [isLoading, setLoading] = useState<boolean>(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      try {
        setLoading(true);
        const results = await fetchMentors({ q: searchValue });
        const searchedMentors = results.map((mentor: MentorInterface) => {
          mentor.name = getFullName(mentor);
          return mentor;
        });
        setData(searchedMentors);
      } catch (err: any) {
        error("Failed to load mentor list");
      } finally {
        setLoading(false);
      }
    }, DEBOUNCE_TIME),
    [],
  );
  const handleQueryChange = async (searchValue: string) => {
    setQuery(searchValue);

    debouncedSearch(searchValue);
  };

  const loadMentors = async () => {
    try {
      const result: MentorInterface[] = await fetchMentors();
      const mentorWithFullName = result.map((mentor: MentorInterface) => ({
        ...mentor,
        name: mentor.firstName + " " + mentor.lastName,
      }));
      setData(mentorWithFullName);
      setLoading(false);
    } catch (err: any) {
      error("Failed to load mentor list");
    }
  };

  useEffect(() => {
    loadMentors();
  }, []);

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const EmptyMentor = () => {
    return (
      <th colSpan={3}>
        <div className="empty-mentors">
          <div className="empty-mentors__content">
            <img src={emptyUserImage} alt="Empty Mentors" />
            <h3>
              Sorry could not find this<br></br> mentor
            </h3>
            <p>
              Add a new mentor for this <br></br>session.
            </p>

            <Link className="btn btn--primary" to={configs.uiPath.mentors.add}>
              {t("mentorList.newMentorBtnLabel")}
            </Link>
          </div>
        </div>
      </th>
    );
  };

  return (
    <>
      <div className="content-wrap pt-8x pb-8x">
        <div className="container">
          <div className="page-heading">
            <h2>{t("mentorList.listTitle")}</h2>
            <div className="page-heading__right">
              <div className="filter-group align-items-center">
                <div className="input-wrap--icon-right mb-0 mr-2x">
                  <input
                    type="text"
                    placeholder={
                      t("mentorList.seacrchMentor") || "Search a mentor"
                    }
                    className="input"
                    value={query}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleQueryChange(e.target.value);
                    }}
                  />
                  <span className="form-icon">
                    <box-icon
                      name="search"
                      size="sm"
                      color="#959FAE"
                    ></box-icon>
                  </span>
                </div>
                <Link
                  className="btn btn--primary"
                  to={configs.uiPath.mentors.add}
                >
                  {t("mentorList.newMentorBtnLabel")}
                </Link>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader type="ThreeDots" />
          ) : (
            <div className="table-responsive">
              <table {...getTableProps()} className="table">
                <thead>
                  {headerGroups.map((headerGroup: HeaderGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="head-row mentor-table-head"
                    >
                      {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps()} className="table__col">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {data.length === 0 ? (
                  <EmptyMentor />
                ) : (
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row: Row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="table__row">
                          {row.cells.map((cell: Cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="table__col"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MentorList;
