import { error } from "./toast";

/**
 * Check for data and downloads file
 * @param data
 * @param fileName
 * @param type of file
 */
export const downloadFile = (
  data: BlobPart[],
  fileName: string,
  type: string,
) => {
  if (data.length && fileName && type) {
    const file = new Blob(data, { type: type });

    const tempElement = document.createElement("a");
    tempElement.download = fileName;
    tempElement.href = window.URL.createObjectURL(file);
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    tempElement.dispatchEvent(clickEvent);
    tempElement.remove();
  } else {
    error("Invalid data provided for download");
  }
};
