import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as satelliteLocationService from "services/satelliteLocation";
import {
  ISatelliteLocation,
  ISatelliteLocationParams,
  IUpdateSatelliteLocation,
} from "commons/types/satelliteLocations";
import {
  AddSatelliteLocationActionType,
  FetchSatelliteLocationsActionType,
  UpdateSatelliteLocationActionType,
  DeleteSatelliteLocationActionType,
} from "store/actions/types/satelliteLocation";

export const addSatelliteLocation =
  (payload: ISatelliteLocation) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddSatelliteLocationActionType.ADD_SATELLITE_LOCATION_PENDING,
    });
    try {
      const { data } = await satelliteLocationService.add(payload);
      dispatch({
        type: AddSatelliteLocationActionType.ADD_SATELLITE_LOCATION_FULFILLED,
        data: data,
      });
      success("Saved satellite location");
      return data;
    } catch (err: any) {
      dispatch({
        type: AddSatelliteLocationActionType.ADD_SATELLITE_LOCATION_REJECTED,
        data: err,
      });

      error("Satellite location failed to save");
    }
  };

export const fetchSatelliteLocations =
  (params: ISatelliteLocationParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchSatelliteLocationsActionType.FETCH_SATELLITE_LOCATIONS_PENDING,
    });
    try {
      const { data } = await satelliteLocationService.fetchAll(params);
      dispatch({
        type: FetchSatelliteLocationsActionType.FETCH_SATELLITE_LOCATIONS_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchSatelliteLocationsActionType.FETCH_SATELLITE_LOCATIONS_REJECTED,
        data: err,
      });

      error("Satellite locations failed to load");
    }
  };

export const updateSatelliteLocation =
  (id: string, payload: IUpdateSatelliteLocation) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateSatelliteLocationActionType.UPDATE_SATELLITE_LOCATION_PENDING,
    });
    try {
      const { data } = await satelliteLocationService.update(id, payload);
      dispatch({
        type: UpdateSatelliteLocationActionType.UPDATE_SATELLITE_LOCATION_FULFILLED,
        data: data,
      });
      success("Satellite location updated successfully");
      return data;
    } catch (err: any) {
      dispatch({
        type: UpdateSatelliteLocationActionType.UPDATE_SATELLITE_LOCATION_REJECTED,
        data: err,
      });

      error("Satellite location failed to update");
    }
  };

export const deleteSatelliteLocation =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: DeleteSatelliteLocationActionType.DELETE_SATELLITE_LOCATION_PENDING,
    });
    try {
      const { data } = await satelliteLocationService.deleteOne(id);
      dispatch({
        type: DeleteSatelliteLocationActionType.DELETE_SATELLITE_LOCATION_FULFILLED,
        data: data,
      });
      success("Satellite location deleted successfully");
      return data;
    } catch (err: any) {
      dispatch({
        type: DeleteSatelliteLocationActionType.DELETE_SATELLITE_LOCATION_REJECTED,
        data: err,
      });

      error("Satellite location failed to delete");
    }
  };
