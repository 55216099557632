import { TenantConfig, TenantRole } from "./tenant.types";

// Todo data needs to come from an api call when the tenant configuration setup is complete
export const tenantConfig: TenantConfig = {
  users: {
    [TenantRole.ADMIN]: "Tenant Admin",
    [TenantRole.SPONSOR]: "Parent/Guardian",
    [TenantRole.SUBJECT]: "Student",
    [TenantRole.PRACTITIONER]: "Mentor",
  },
};
