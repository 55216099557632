import {
  AddSchoolActionType,
  DeleteSchoolActionType,
  FetchSchoolActionType,
  FetchSchoolsActionType,
  UpdateSchoolActionType,
} from "store/actions/types/school";

const INITIAL_STATE = {
  addSchool: {},
  addSchoolLoading: false,
  addSchoolError: null,
  fetchSchools: [],
  fetchSchoolsLoading: false,
  fetchSchoolsError: null,
  fetchSchool: [],
  fetchSchoolLoading: false,
  fetchSchoolError: null,
  updateSchool: {},
  updateSchoolLoading: false,
  updateSchoolError: null,
  deleteSchool: {},
  deleteSchoolLoading: false,
  deleteSchoolError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddSchoolActionType.ADD_SCHOOL_PENDING:
      return {
        ...state,
        addSchoolLoading: true,
        addSchoolError: null,
      };

    case AddSchoolActionType.ADD_SCHOOL_FULFILLED:
      return {
        ...state,
        addSchool: action.data,
        addSchoolLoading: false,
        addSchoolError: null,
      };
    case AddSchoolActionType.ADD_SCHOOL_REJECTED:
      return {
        ...state,
        addSchoolLoading: false,
        addSchoolError: action.data,
      };
    case FetchSchoolsActionType.FETCH_SCHOOLS_PENDING:
      return {
        ...state,
        fetchSchoolsLoading: true,
        fetchSchoolsError: null,
      };
    case FetchSchoolsActionType.FETCH_SCHOOLS_FULFILLED:
      return {
        ...state,
        fetchSchoolsLoading: false,
        fetchSchoolsError: null,
        fetchSchools: action.data,
      };

    case FetchSchoolsActionType.FETCH_SCHOOLS_REJECTED:
      return {
        ...state,
        fetchSchoolsLoading: false,
        fetchSchoolsError: action.data,
      };
    case FetchSchoolActionType.FETCH_SCHOOL_PENDING:
      return {
        ...state,
        fetchSchoolLoading: true,
        fetchSchoolError: null,
      };
    case FetchSchoolActionType.FETCH_SCHOOL_FULFILLED:
      return {
        ...state,
        fetchSchoolLoading: false,
        fetchSchoolError: null,
        fetchSchool: action.data,
      };

    case FetchSchoolActionType.FETCH_SCHOOL_REJECTED:
      return {
        ...state,
        fetchSchoolLoading: false,
        fetchSchoolError: action.data,
      };
    case UpdateSchoolActionType.UPDATE_SCHOOL_PENDING:
      return {
        ...state,
        updateSchoolLoading: true,
        updateSchoolError: null,
      };
    case UpdateSchoolActionType.UPDATE_SCHOOL_FULFILLED:
      return {
        ...state,
        updateSchoolLoading: false,
        updateSchool: action.data,
        updateSchoolError: null,
      };
    case UpdateSchoolActionType.UPDATE_SCHOOL_REJECTED:
      return {
        ...state,
        updateSchoolLoading: false,
        updateSchoolError: action.data,
      };
    case DeleteSchoolActionType.DELETE_SCHOOL_PENDING:
      return {
        ...state,
        deleteSchoolLoading: true,
        deleteSchoolError: null,
      };
    case DeleteSchoolActionType.DELETE_SCHOOL_FULFILLED:
      return {
        ...state,
        deleteSchool: action.data,
        deleteSchoolLoading: false,
        deleteSchoolError: null,
      };
    case DeleteSchoolActionType.DELETE_SCHOOL_REJECTED:
      return {
        ...state,
        deleteSchoolLoading: false,
        deleteSchoolError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
