import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchTenantInitialConfig,
  fetchAllTenantsList,
} from "store/actions/data/multitenancy";
import TenantTable from "./components/TenantTable";
import Loader from "commons/components/Loader";

interface Props {
  fetchTenantInitialConfig: () => void;
  fetchAllTenantsList: () => void;
  tenants: any;
  fetchTenantsLoading: boolean;
}

const TenantList = (props: Props) => {
  const { tenants, fetchTenantsLoading } = props;

  useEffect(() => {
    props.fetchAllTenantsList();
  }, []);

  return (
    <>
      <div className="content-wrap content-panel">
        {fetchTenantsLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <div className="p-4x">
            <TenantTable
              fetchTenants={props.fetchAllTenantsList}
              tenantData={tenants.data}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  tenants: state.multitenancy.tenantsList,
  fetchTenantsLoading: state.multitenancy.fetchTenantsLoading,
  addTenantLoading: state.multitenancy.addTenantLoading,
});

const mapDispatchToProps = {
  fetchTenantInitialConfig,
  fetchAllTenantsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantList);
