import React from "react";
import { useFormik } from "formik";

import { update } from "services/uow";
import { GoalStatus } from "constants/status";
import { error, success } from "utils/toast";

import useMountedRef from "commons/hooks/useMountedRef";

type Props = {
  closeModal: any;
  goal: any;
  refreshGoal: any;
};
const MarkCompleted: React.FC<Props> = ({
  closeModal,
  goal,
  refreshGoal,
}: Props): JSX.Element => {
  const isMounted = useMountedRef();

  const formik = useFormik({
    initialValues: {
      status: goal?.workData?.status || GoalStatus.CREATED,
      description: goal?.description || "",
    },
    onSubmit: async (values) => {
      try {
        const payload: any = {
          ...goal,
          workData: { ...goal?.workData, status: values.status },
          description: values.description,
          assignee: goal.assignee?._id,
        };
        await update(goal._id, payload);

        if (isMounted) {
          refreshGoal();
        }

        success("Status updated");
      } catch (err: any) {
        error("Failed to update status");
      } finally {
        closeModal();
      }
    },
  });
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Mark this goal as complete?</h3>
        <span onClick={closeModal} className="link-item">
          <box-icon name="x" />
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body">
          <div className="row">
            <div className="col-12">
              <div className="mb-3x">What is the status of this goal?</div>
              <div className="radio-inline">
                <div className="input-wrap">
                  <input
                    type="radio"
                    id="radio1"
                    name="status"
                    className="box-link__nodes"
                    value={GoalStatus.MET}
                    checked={formik.values.status === GoalStatus.MET}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="radio1" className="input__label">
                    Met
                  </label>
                </div>
                <div className="input-wrap">
                  <input
                    type="radio"
                    id="radio2"
                    name="status"
                    className="box-link__nodes"
                    value={GoalStatus.PROGRESS}
                    checked={formik.values.status === GoalStatus.PROGRESS}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="radio2" className="input__label">
                    Made Progress
                  </label>
                </div>
                <div className="input-wrap">
                  <input
                    type="radio"
                    id="radio3"
                    name="status"
                    className="box-link__nodes"
                    value={GoalStatus.NOT_MET}
                    checked={formik.values.status === GoalStatus.NOT_MET}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="radio3" className="input__label">
                    Not Met
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="input-wrap">
                <label className="input__label">
                  Please add a note (optional)
                </label>
                <textarea
                  className="input textarea"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-wrap__footer">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                disabled={formik.isSubmitting}
                className="btn btn--primary mr-4x"
                type="submit"
              >
                Update Status
              </button>
              <button
                disabled={formik.isSubmitting}
                onClick={closeModal}
                className="btn txt-primary-color"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MarkCompleted;
