import { ICall } from "commons/types/call";
import { CallActionType } from "store/actions/types/communication";

const INITIAL_STATE = {
  isIncomingCall: false,
  isBusy: false,
  data: {},
};

interface ICommunicationActionParam {
  payload: ICall;
  type: CallActionType;
}

const reducer = (state = INITIAL_STATE, action: ICommunicationActionParam) => {
  switch (action.type) {
    case CallActionType.STARTED:
      return {
        ...state,
        isIncomingCall: !action.payload.isCallerMe,
        isBusy: true,
        data: action.payload,
      };

    case CallActionType.ENDED:
      return {
        ...state,
        isBusy: false,
        isIncomingCall: false,
        data: {},
      };

    case CallActionType.ANSWERED:
      return {
        ...state,
        isBusy: true,
        isIncomingCall: false,
      };

    case CallActionType.REJECTED:
      return {
        ...state,
        isBusy: false,
        isIncomingCall: false,
        data: {},
      };

    default:
      return state;
  }
};

export default reducer;
