import "boxicons";

import logo from "assets/images/boost_logo.svg";
import student1 from "assets/images/student1.jpg";

// RC-slider imports
import "rc-slider/assets/index.css";
import Slider, { Range, createSliderWithTooltip } from "rc-slider";

const SurveyAggregated = () => {
  const SliderWithTooltip = createSliderWithTooltip(Slider);
  const RangeWithToolTip = createSliderWithTooltip(Range);

  return (
    <div>
      <nav className="navbar navbar--bordered-bottom">
        <div className="container-fluid">
          <div className="navbar__wrap navbar__wrap--content-spread">
            <div className="navbar__left">
              <div className="navbar__logo">
                <img src={logo} alt="logo" />
              </div>
              <div className="nav">
                <ul className="nav">
                  <li className="nav__node">
                    <span className="link-item nav__link nav__link--active">
                      Student
                    </span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Mentor</span>
                  </li>
                  <li className="nav__node">
                    <span className="link-item nav__link">Terms</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar__right">
              <span className="link-item user-dropdown">
                <box-icon name="user" color="#959FAE"></box-icon>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div className="content-wrap">
        <div className="container-fluid">
          <div className="main-content">
            <div className="left-panel">
              <ul className="nav-left">
                {/* <li className="nav-left__item">
                  <span className="link-item">
                    <box-icon name="calendar-check"></box-icon>
                    Dashboard
                  </span>
                </li> */}
                <li className="nav-left__item--active">
                  <span className="link-item">
                    <box-icon name="group"></box-icon>
                    Students
                  </span>
                </li>
              </ul>
            </div>
            <div className="content-panel">
              <div className="page-heading">
                <div className="page-heading__left">
                  <div className="mt-6x">
                    <box-icon name="arrow-back"></box-icon>
                  </div>
                  <div className="heading-detail">
                    <span className="text-light-sm">
                      Assessment - Summer 2021
                    </span>
                    <h2>Student Assessment Survey- Aggregated</h2>
                    <ul className="heading-info">
                      <li>
                        <box-icon name="graduation" type="solid"></box-icon>
                        Created on April 30, 2021
                      </li>
                      <li>Questions 21</li>
                    </ul>
                  </div>
                </div>
                <div className="page-heading__right  align-items-center">
                  <button className="btn btn--primary mr-2x" type="button">
                    Add Task
                  </button>
                  <box-icon name="dots-horizontal-rounded"></box-icon>
                </div>
              </div>
              <div className="tab-wrapper">
                <ul className="tab">
                  <li className="tab__item tab__item--active">
                    <span>Responses</span>
                  </li>
                  <li className="tab__item">
                    <span>All Notes</span>
                  </li>
                </ul>
              </div>
              <div className="section-heading mt-4x">
                <h3>Responses - 3</h3>
              </div>
              <div className="content-list survey-list">
                <div className="accordion">
                  <div className="accordion-item">
                    <div className="accordion-item__title p-0x">
                      <div className="title-left">
                        <h3>Organization</h3>
                      </div>
                      <div className="title-right">
                        <div className="actions d-flex align-items-center">
                          <span className="status status--warning-dark fs-body2">
                            Skill Set Score: 3.2
                          </span>
                          <span className="link-item">
                            <box-icon name="chevron-up"></box-icon>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item__content">
                      <div className="card align-items-center">
                        <div className="card-content">
                          <div className="card-text">
                            <div className="card-info">
                              Physical organization
                            </div>
                            <div className="card-head">
                              <h3>4. Is it easy to with RC slider</h3>
                            </div>
                            <ul className="card-info">
                              <li>Aggregate Skill Score: 3 (Sometimes)</li>
                            </ul>
                            <div className="slider-wrapper">
                              <SliderWithTooltip
                                min={1}
                                max={5}
                                marks={[
                                  {}, // need to be empty if our min value is 1 (offset for 0)
                                  { style: {}, label: "Never" },
                                  { style: {}, label: "Rarely" },
                                  { style: {}, label: "Sometimes" },
                                  { style: {}, label: "Usually" },
                                  { style: {}, label: "Always" },
                                ]}
                                trackStyle={{ backgroundColor: "#1D70B8" }}
                                railStyle={{ backgroundColor: "#CED2D3" }}
                                defaultValue={2}
                                disabled={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card align-items-center">
                        <div className="card-content">
                          <div className="card-text">
                            <div className="card-info">
                              Physical organization
                            </div>
                            <div className="card-head">
                              <h3>4. Is it easy to with RC range</h3>
                            </div>
                            <ul className="card-info">
                              <li>Aggregate Skill Score: 3 (Sometimes)</li>
                            </ul>
                            <div className="slider-wrapper">
                              <RangeWithToolTip
                                count={4}
                                min={0}
                                max={10}
                                allowCross={false}
                                pushable={true}
                                marks={[
                                  { style: {}, label: "0" },
                                  { style: {}, label: "10" },
                                  { style: {}, label: "20" },
                                  { style: {}, label: "30" },
                                  { style: {}, label: "40" },
                                  { style: {}, label: "50" },
                                  { style: {}, label: "60" },
                                  { style: {}, label: "70" },
                                  { style: {}, label: "80" },
                                  { style: {}, label: "90" },
                                  { style: {}, label: "100" },
                                ]}
                                trackStyle={[
                                  { backgroundColor: "red" },
                                  { backgroundColor: "yellow" },
                                  { backgroundColor: "blue" },
                                  { backgroundColor: "green" },
                                ]}
                                railStyle={{ backgroundColor: "#CED2D3" }}
                                defaultValue={[1, 4, 6, 9, 10]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card align-items-center">
                        <div className="card-content">
                          <div className="card-text">
                            <div className="card-info">
                              Physical organization
                            </div>
                            <div className="card-head">
                              <h3>4. Is it easy to with RC slider</h3>
                            </div>
                            <ul className="card-info">
                              <li>Aggregate Skill Score: 3 (Sometimes)</li>
                            </ul>
                            <div className="slider-wrapper">
                              <SliderWithTooltip
                                min={1}
                                max={5}
                                marks={[
                                  {}, // need to be empty if our min value is 1 (offset for 0)
                                  { style: {}, label: "Never" },
                                  { style: {}, label: "Rarely" },
                                  { style: {}, label: "Sometimes" },
                                  { style: {}, label: "Usually" },
                                  { style: {}, label: "Always" },
                                ]}
                                defaultValue={2}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="message">
                <div className="message__title">
                  <h3>Messages</h3>
                  <div className="message-actions">
                    <span className="action-links active">
                      <span>Available</span>
                      <box-icon name="alarm-snooze"></box-icon>
                    </span>
                    <span className="action-links default">
                      <box-icon name="plus-circle" type="solid"></box-icon>
                    </span>
                  </div>
                </div>
                <div className="message__content">
                  <div className="message-list unread">
                    <div className="avatar-wrap active">
                      <div className="avatar mr-2x">
                        <img src={student1} alt="logo" />
                      </div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Samantha Doe <span>(Grade 7)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">Just now</span>
                        <span className="msg-preview text-ellipsis">
                          Hi! researched but I dont know the answer.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">BP</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Brendan Pollock <span>(Homework Center)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Yes thanks! I will ask the student.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">LD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        Lori Denver <span>(Grade 9)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">Okay</span>
                      </div>
                    </div>
                  </div>

                  <div className="message-list">
                    <div className="avatar-wrap">
                      <div className="avatar mr-2x">JD</div>
                      <span className="avatar-status">active</span>
                    </div>
                    <div className="message-text">
                      <h3>
                        John Doe <span>(Samantha's Parent)</span>
                        <span className="msg-status">unread</span>
                      </h3>
                      <div className="msg-preview-wrap">
                        <span className="time">1d</span>
                        <span className="msg-preview text-ellipsis">
                          Sure. I will ask Sam about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyAggregated;
