import pinterpolate from "pinterpolate";

import config from "configs";
import { http } from "utils/http";

export const get = async (
  id: string,
  params: { startDate: string; endDate: string; termId: string },
) => {
  const url = pinterpolate(config.endpoints.users.report, { id: id });
  const { data } = await http.get(url, { params });

  return data;
};

export const publish = async (payload: FormData) => {
  const url = config.endpoints.email.publish;
  const { data } = await http.post(url, payload);
  return data;
};
