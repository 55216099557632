import dayjs from "dayjs";
import Modal from "react-modal";
import { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemState,
  AccordionItemButton,
  AccordionItemHeading,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

import AddTask from "pages/tasks/add";
import eventBus from "utils/event-bus";
import Menu from "pages/tasks/list/Menu";
import UpdateTask from "pages/tasks/update";
import {
  getDayFromDate,
  isBeforeOrSame,
  getDateInFormat,
  getTodayStartDate,
  getPlanningPeriodByDate,
} from "utils/dates";
import { toggleInArray } from "utils/arrays";
import { error, success } from "utils/toast";
import Loader from "commons/components/Loader";
import { IGoal, IGoal as ITask } from "commons/types/goals";
import { checkDropDownPosition } from "utils/dropdown";
import TaskDetail from "pages/tasks/detail/TaskDetail";
import useMountedRef from "commons/hooks/useMountedRef";
import { CLIP_LENGTH, UowDeleteEditType } from "constants/index";
import DeleteConfirm from "commons/components/DeleteConfirm";
import SelectRecurrenceDeleteEditType from "commons/components/SelectRecurrenceDeleteEditType";
import ConfirmSuccess from "pages/tasks/list/ConfirmSuccess";
import { IPlansListProps, IWeeksTasks } from "./plans.interface";
import InCompleteConfirm from "commons/components/IncompleteConfirm";
import MessageAttachment from "commons/components/message-attachment";
import { IProfile } from "commons/types/users";

const PlansList: React.FC<IPlansListProps> = ({
  student,
  saveTask,
  updateTask,
  deleteTask,
  draftTasks,
  openAddTask,
  initialWeeks,
  markAsIncomplete,
  currentWeekTasks,
  onTheHorizonTasks,
  planningPeriods,
  fetchTasksLoading,
  handleBulkTaskUpdate,
  handleBulkTaskDelete,
  showChatHistoryOfAChannel,
}) => {
  const user = localStorage.getItem("profile");
  const currentUser: IProfile = user ? JSON.parse(user) : null;

  const isMounted = useMountedRef();

  const [openMenu, setOpenMenu] = useState(false);
  const [draftChecked, setDraftChecked] = useState(false);
  const [isMenuTop, setMenuTop] = useState<boolean>(false);
  const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);
  const [horizonChecked, setHorizonChecked] = useState(false);
  const [isDeleteTaskOpen, setIsDeleteTaskOpen] = useState(false);
  const [actionTask, setActionTask] = useState<null | ITask>(null);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [isTaskSuccessOpen, setIsTaskSuccessOpen] = useState(false);
  const [isMarkAsIncomplete, setIsMarkAsIncomplete] = useState(false);
  const [isDuplicateTaskOpen, setIsDuplicateTaskOpen] = useState(false);
  const [isBulkDeleteTaskOpen, setIsBulkDeleteTaskOpen] = useState(false);
  const [actionTaskGroup, setActionTaskGroup] = useState<null | string>(null);
  const [triggeredBulkTaskAction, setTriggeredBulkTaskAction] =
    useState<string>("");
  const [quickAddTask, setQuickAddTask] = useState<null | {
    requiredDeadline: string;
  }>(null);
  const [bulkTransactionLoading, setBulkTransactionLoading] =
    useState<boolean>(false);
  const [viewTaskDetail, setViewTaskDetail] = useState<null | ITask>(null);
  const [weeksTasks, setWeeksTasks] = useState<IWeeksTasks>({
    ...initialWeeks,
  });
  const [channel, setChannel] = useState(null);
  const [showDeleteTaskOptions, setShowDeleteTaskOptions] = useState(false);
  const [selectedDeleteType, setSelectedDeleteType] = useState(
    UowDeleteEditType.SELF,
  );

  const setCurrentChannel = async (data: any) => {
    setChannel(data.channel);
  };

  useEffect(() => {
    eventBus.on("chatChannelChanged", setCurrentChannel);

    return () => {
      eventBus.remove("chatChannelChanged", setCurrentChannel);
    };
  }, []);

  const completeTaskHandler = async (id: string) => {
    const updatedTask = await handleBulkTaskUpdate({
      ids: [id],
      isCompleted: true,
    });
    if (updatedTask && isMounted) {
      setOpenMenu(false);
      setViewTaskDetail(null);
      setIsTaskSuccessOpen(true);
      resetBulkTransaction();
    }
  };

  const updateTaskHandler = async (id: string, task: ITask) => {
    const updatedTask = await updateTask(id, task);

    if (updatedTask) {
      setIsEditTaskOpen(false);
      setOpenMenu(false);
      resetBulkTransaction();
    }
  };

  const publishTaskHandler = async (id: string) => {
    const updatedTask = await updateTask(id, { isPublished: true });

    if (updatedTask && isMounted) {
      setOpenMenu(false);
      setViewTaskDetail(null);
      resetBulkTransaction();
    }
  };

  const handleSaveTask = async (data: ITask) => {
    const task = await saveTask(data);

    if (task) {
      setIsDuplicateTaskOpen(false);
      resetBulkTransaction();
      setQuickAddTask(null);
    }
  };

  const markAsIncompleteHandler = async (id: string) => {
    const task = await markAsIncomplete(id);
    if (task) {
      setIsMarkAsIncomplete(false);
      setViewTaskDetail(null);
    }
  };

  const deleteTaskHandler = async (
    id: string,
    type: UowDeleteEditType = UowDeleteEditType.SELF,
  ) => {
    const deletedTask = await deleteTask(id, type);

    if (deletedTask && isMounted) {
      setIsDeleteTaskOpen(false);
      setOpenMenu(false);
      resetBulkTransaction();
      setSelectedDeleteType(UowDeleteEditType.SELF);
    }
  };

  const openEditMenu = (task: ITask) => {
    setActionTask(task);
    setIsEditTaskOpen(true);
  };

  const getCreator = (task: any) => {
    return task?.createdBy?._id === currentUser._id
      ? "you"
      : task?.createdBy?.firstName;
  };

  useEffect(() => {
    let tasks: IWeeksTasks = { ...initialWeeks };
    currentWeekTasks.map((task: ITask) => {
      const day: string = getDateInFormat(
        task.requiredDeadline,
        "MM-DD-YYYY",
      ).toString();

      tasks[day] = {
        date: task.requiredDeadline,
        checked: false,
        tasks: tasks[day] ? [...tasks[day].tasks, task] : [task],
      };

      return tasks;
    });

    setWeeksTasks(tasks);
  }, [currentWeekTasks, initialWeeks]);

  const checkTaskBulkActionDisabled = (task: any) => {
    // Disable check when bulk action in progress
    if (bulkTransactionLoading) return true;

    // Enable check if none selected or if action triggered is delete only
    if (!triggeredBulkTaskAction || triggeredBulkTaskAction === "delete")
      return false;

    // Disable if task is already complete and the action is not delete
    if (task.isCompleted) return true;

    // Disable if action is to complete but the task is in DRAFT
    if (triggeredBulkTaskAction === "complete" && !task.isPublished)
      return true;

    // Disable if the task is already published and the action is to publish
    if (
      task.isPublished &&
      triggeredBulkTaskAction === "publish" &&
      (!task._id || !selectedTasks.includes(task._id))
    )
      return true;

    return false;
  };

  const toggleOpenMenu = (task: ITask) => {
    const newState = !openMenu;
    if (newState) {
      delete task.recurringTaskParent;
      setActionTask(task);
    }
    setOpenMenu(newState);
  };

  const closeMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  useEffect(() => {
    if (openMenu) {
      document.addEventListener("click", closeMenu);
    } else {
      document.removeEventListener("click", closeMenu);
    }

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [openMenu, closeMenu]);

  const resetBulkTransaction = () => {
    setTriggeredBulkTaskAction("");
    setSelectedTasks([]);
  };

  const handleNewSelectedTasks = (
    newSelectedTasks: string[],
    actionType: string,
  ) => {
    if (newSelectedTasks.length < 1) {
      return resetBulkTransaction();
    }

    setTriggeredBulkTaskAction(actionType);
    setSelectedTasks(newSelectedTasks);
  };

  const handleBulkCheck = (
    checkedBulkType: "overdue" | "draft" | "horizon",
  ) => {
    let setBulkCheck;
    let newBulkCheck;
    let actionTasks: ITask[] = [];
    let newTriggeredActionType = triggeredBulkTaskAction;
    const newSelectedTasks = [...selectedTasks];

    if (checkedBulkType === "draft") {
      newBulkCheck = !draftChecked;
      setBulkCheck = setDraftChecked;
      newTriggeredActionType = "publish";
      actionTasks = draftTasks;
    } else if (checkedBulkType === "horizon") {
      newBulkCheck = !horizonChecked;
      setBulkCheck = setHorizonChecked;
      newTriggeredActionType = "complete";
      actionTasks = onTheHorizonTasks;
    } else {
      return;
    }

    if (newBulkCheck) {
      actionTasks.forEach((task) => {
        if (
          task._id &&
          !newSelectedTasks.includes(task._id) &&
          !task.isCompleted
        )
          newSelectedTasks.push(task._id);
      });
    } else {
      actionTasks.forEach((task) => {
        if (task._id && newSelectedTasks.includes(task._id))
          newSelectedTasks.splice(newSelectedTasks.indexOf(task._id), 1);
      });
    }

    setBulkCheck(newBulkCheck);
    handleNewSelectedTasks(newSelectedTasks, newTriggeredActionType);
  };

  const handleHorizonCheck = () => {
    handleBulkCheck("horizon");
  };

  const handleDraftCheck = () => {
    handleBulkCheck("draft");
  };

  /**
   * Auto check or uncheck bulk action checkbox according to selected task
   * @param selectedTasks
   */
  const handleSingleCheckBulkEffect = (selectedTasks: string[]) => {
    const newTasks = { ...weeksTasks };
    Object.keys(weeksTasks).map((day) => {
      if (
        weeksTasks[day].tasks.length &&
        weeksTasks[day].tasks.every(
          (task: ITask) => task._id && selectedTasks.includes(task._id),
        )
      ) {
        newTasks[day] = {
          ...weeksTasks[day],
          checked: true,
        };
      } else {
        newTasks[day] = {
          ...weeksTasks[day],
          checked: false,
        };
      }

      return day;
    });
    setWeeksTasks(newTasks);

    if (
      draftTasks.length &&
      draftTasks.every(
        (task: ITask) => task._id && selectedTasks.includes(task._id),
      )
    )
      setDraftChecked(true);
    else setDraftChecked(false);

    if (
      onTheHorizonTasks.length &&
      onTheHorizonTasks.every(
        (task: ITask) => task._id && selectedTasks.includes(task._id),
      )
    )
      setHorizonChecked(true);
    else setHorizonChecked(false);
  };

  const handleCheck = (task: ITask) => {
    if (task.isCompleted) {
      setActionTask(task);
      setIsMarkAsIncomplete(true);
      return;
    }
    const newTriggeredActionType = !task.isPublished
      ? "publish"
      : !task.isCompleted
      ? "complete"
      : "delete";
    const newSelectedTasks = [...toggleInArray(selectedTasks, task._id)];

    handleSingleCheckBulkEffect(newSelectedTasks);

    handleNewSelectedTasks(newSelectedTasks, newTriggeredActionType);
  };

  const bulkPublishTasks = async () => {
    setBulkTransactionLoading(true);
    try {
      const result = await handleBulkTaskUpdate({
        ids: selectedTasks,
        isPublished: true,
      });

      if (result && isMounted) resetBulkTransaction();
    } catch (err: any) {
      error("Failed to publish tasks");
    }
    if (isMounted) {
      setBulkTransactionLoading(false);
    }
  };

  const bulkCompleteTasks = async () => {
    setBulkTransactionLoading(true);
    try {
      const result = await handleBulkTaskUpdate({
        ids: selectedTasks,
        isCompleted: true,
      });

      if (result && isMounted) {
        setIsTaskSuccessOpen(true);
        resetBulkTransaction();
      }
    } catch (err: any) {
      error("Failed to mark tasks as completed");
    }
    if (isMounted) {
      setBulkTransactionLoading(false);
    }
  };

  const handleBulkDelete = async () => {
    setBulkTransactionLoading(true);
    try {
      const result = await handleBulkTaskDelete(selectedTasks);

      if (result && isMounted) {
        success("Tasks deleted");
        setIsBulkDeleteTaskOpen(false);
        resetBulkTransaction();
      }
    } catch (err: any) {
      error("Failed to delete tasks");
    }
    if (isMounted) {
      setBulkTransactionLoading(false);
    }
  };

  const handleWeekDayCheck = (day: string) => {
    const newDayCheck = !weeksTasks[day].checked;
    const newTasks = { ...weeksTasks };
    newTasks[day] = {
      ...weeksTasks[day],
      checked: newDayCheck,
    };

    let newTriggeredActionType = "";
    const newSelectedTasks = [...selectedTasks];
    if (newDayCheck) {
      newTriggeredActionType = "complete";
      weeksTasks[day].tasks.forEach((task: any) => {
        if (
          task._id &&
          !newSelectedTasks.includes(task._id) &&
          !task.isCompleted
        )
          newSelectedTasks.push(task._id);
      });
    } else {
      weeksTasks[day].tasks.forEach((task: any) => {
        if (task._id && newSelectedTasks.includes(task._id))
          newSelectedTasks.splice(newSelectedTasks.indexOf(task._id), 1);
      });
    }

    setWeeksTasks(newTasks);
    handleNewSelectedTasks(newSelectedTasks, newTriggeredActionType);
  };

  const getAttachedGoal = (task: any) => {
    if (task.childOf?.name?.length > CLIP_LENGTH) {
      return `${task.childOf?.name?.slice(0, CLIP_LENGTH)}...`;
    } else {
      return task?.childOf?.name;
    }
  };

  const noTasksElement = (sectionName: string) => {
    return (
      <div className="accordion">
        <div className="accordion-item">
          <div className="card empty-card">
            <div className="card-content">
              <div className="tasks ml-2x">
                <box-icon name="info-circle" type="solid" />
              </div>
              <div className="card-text">
                <div className="title-left">
                  <h3 className="mb-2x">No {sectionName} tasks.</h3>
                </div>
                <p className="card-info">Check other days for updates.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const menuElement = (
    task: ITask & { recurrenceType: string },
    group: string,
  ) => (
    <span
      className="link-item dropdown-button"
      onClick={(event) => {
        setMenuTop(checkDropDownPosition(event));
        toggleOpenMenu(task);
        setActionTaskGroup(group);
      }}
    >
      <box-icon name="dots-horizontal-rounded" />
      {openMenu && actionTask?._id === task._id && actionTaskGroup === group && (
        <Menu
          deleteMenu={() => {
            task.recurrenceType === "Non-Repeat"
              ? setIsDeleteTaskOpen(true)
              : setShowDeleteTaskOptions(true);
          }}
          showDetail={() => {
            setViewTaskDetail(task);
          }}
          incompleteTask={
            task.isPublished && task.isCompleted
              ? () => task._id && markAsIncompleteHandler(task._id)
              : null
          }
          completeTask={
            task.isPublished && !task.isCompleted
              ? () => task._id && completeTaskHandler(task._id)
              : null
          }
          editMenu={() => openEditMenu(task)}
          duplicateTask={() => setIsDuplicateTaskOpen(true)}
          isMenuTop={isMenuTop}
        />
      )}
    </span>
  );

  const RecurrenceDetail = ({ title }: { title: String }) => {
    return (
      <div className="recurrence-details mt-1x mb-1x">
        <box-icon name="repeat" color="#666666" class="mr-1x"></box-icon>
        <span>{title}</span>
      </div>
    );
  };

  const GetDay = ({ task }: { task: IGoal }) => {
    return (
      <>
        {getDayFromDate(task.requiredDeadline, true) === "Today" ? (
          <p className="color-tertiary-red-80">Today</p>
        ) : (
          <p>{getDayFromDate(task.requiredDeadline, true)}</p>
        )}
      </>
    );
  };

  const checkTasksComplete = (task: IGoal[]) => {
    return task.every((task: any) => task.isCompleted);
  };

  return fetchTasksLoading ? (
    <Loader type="ThreeDots" />
  ) : (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="accordion"
      preExpanded={["overdue", ...Object.keys(weeksTasks)]}
    >
      {Object.keys(weeksTasks).map((day) => (
        <AccordionItem uuid={day} key={day} className="accordion-item">
          <AccordionItemState>
            {({ expanded }) => (
              <>
                <AccordionItemHeading className="">
                  <AccordionItemButton className="accordion-item__title">
                    <div className="title-left">
                      <div
                        className={
                          checkTasksComplete(weeksTasks[day].tasks)
                            ? "input-wrap mb-8x button-disabled"
                            : "input-wrap mb-8x"
                        }
                      >
                        <input
                          type="checkbox"
                          id={day}
                          className={"form-group__radio mr-10"}
                          checked={
                            !!weeksTasks[day].tasks.length &&
                            (weeksTasks[day].checked ||
                              checkTasksComplete(weeksTasks[day].tasks))
                          }
                          onChange={() => {
                            handleWeekDayCheck(day);
                          }}
                          disabled={checkTasksComplete(weeksTasks[day].tasks)}
                        />
                        <label
                          htmlFor={day}
                          className="box-links__nodes"
                        ></label>
                      </div>
                      <span>{getDayFromDate(day)}</span>
                      <span className="ml-2x">
                        {dayjs(weeksTasks[day].date).format("MM/DD")}
                      </span>
                    </div>
                    <div className="title-right">
                      {weeksTasks[day].date &&
                      isBeforeOrSame(
                        getTodayStartDate(),
                        new Date(weeksTasks[day].date),
                      ) ? (
                        <span
                          className="link-item mr-3x plus-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            setQuickAddTask({
                              requiredDeadline: weeksTasks[day].date,
                            });
                          }}
                        >
                          <box-icon name="plus-circle" type="solid" />
                        </span>
                      ) : null}
                      <span>
                        <box-icon
                          name={!expanded ? "chevron-down" : "chevron-up"}
                        ></box-icon>
                      </span>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-item__content">
                  {weeksTasks[day].tasks.map((task: any) => (
                    <div
                      key={task._id}
                      className={`card ${
                        task?.isCompleted ? "card--success" : ""
                      }`}
                    >
                      <div className="card-content">
                        <div
                          className={
                            task.isCompleted ||
                            checkTaskBulkActionDisabled(task)
                              ? "input-wrap button-disabled mr-2x"
                              : "input-wrap mr-2x"
                          }
                        >
                          <input
                            type="checkbox"
                            name="checkbox"
                            value="Checkbox Inline 2"
                            id={task._id}
                            checked={
                              task._id &&
                              (selectedTasks.includes(task._id) ||
                                task.isCompleted)
                                ? true
                                : false
                            }
                            onChange={() => handleCheck(task)}
                            disabled={checkTaskBulkActionDisabled(task)}
                            className="form-group__radio mr-10"
                          />
                          <label
                            htmlFor={task._id}
                            className="box-links__nodes"
                          ></label>
                        </div>
                        <div className="card-text">
                          <h3
                            className={
                              task.isCompleted
                                ? "link-item txt-darkgrey-color"
                                : "link-item"
                            }
                            onClick={() => setViewTaskDetail(task)}
                          >
                            {task.name}
                          </h3>
                          {task.recurrenceType === "Weekly" && (
                            <RecurrenceDetail
                              title={`Weekly on  ${getDayFromDate(day)}`}
                            />
                          )}
                          {task.recurrenceType === "Daily" && (
                            <RecurrenceDetail title={`Daily`} />
                          )}
                          <ul className="card-info">
                            <li className="duedate warning-icon">
                              {task.workData?.priority === "Priority" && (
                                <box-icon
                                  name="error-alt"
                                  color="#ff1154"
                                ></box-icon>
                              )}
                              <GetDay task={task} />
                            </li>
                            {task.workData?.optionalDeadline ? (
                              <li className="ml-4x">
                                <box-icon name="calendar"></box-icon>
                                {"Due "}
                                <strong className="ml-2x">
                                  {getDateInFormat(
                                    task.workData?.optionalDeadline,
                                  )}
                                </strong>
                              </li>
                            ) : null}
                            <li className="ml-4x">
                              {task.childOf?.name && (
                                <box-icon
                                  type="solid"
                                  name="bullseye"
                                ></box-icon>
                              )}
                              {getAttachedGoal(task)}
                            </li>
                            <li>
                              {getCreator(task) !== "you" &&
                                `Added by ${getCreator(task)}`}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-actions">
                        {!currentUser.isStudentLoggedIn && (
                          <MessageAttachment
                            channel={channel}
                            showChatHistoryOfAChannel={
                              showChatHistoryOfAChannel
                            }
                            attributes={{
                              _id: task._id || "",
                              name: task.name,
                              uowType: task.uowType,
                              childOf: task.childOf?._id,
                              assignee: student?._id || "",
                            }}
                            receiver={student}
                          />
                        )}
                        {menuElement(task, day)}
                      </div>
                    </div>
                  ))}
                </AccordionItemPanel>
              </>
            )}
          </AccordionItemState>
        </AccordionItem>
      ))}

      <AccordionItem uuid="horizon" className="accordion-item">
        <AccordionItemState>
          {({ expanded }) => (
            <>
              <AccordionItemHeading className="">
                <AccordionItemButton className="accordion-item__title">
                  <div className="title-left">
                    <div
                      className={
                        checkTasksComplete(onTheHorizonTasks)
                          ? "input-wrap mb-8x button-disabled"
                          : "input-wrap mb-8x"
                      }
                    >
                      <input
                        type="checkbox"
                        id="horizon"
                        className="form-group__radio mr-10"
                        checked={
                          !!onTheHorizonTasks.length &&
                          (horizonChecked ||
                            checkTasksComplete(onTheHorizonTasks))
                        }
                        onChange={() => {
                          handleHorizonCheck();
                        }}
                        disabled={checkTasksComplete(onTheHorizonTasks)}
                      />
                      <label
                        htmlFor="horizon"
                        className="box-links__nodes"
                      ></label>
                    </div>
                    <span>On the Horizon</span>
                  </div>
                  <div className="title-right">
                    <span
                      className="link-item mr-3x plus-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setQuickAddTask({
                          requiredDeadline: "",
                        });
                      }}
                    >
                      <box-icon name="plus-circle" type="solid" />
                    </span>
                    <span>
                      <box-icon
                        name={!expanded ? "chevron-down" : "chevron-up"}
                      ></box-icon>
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-item__content">
                {!onTheHorizonTasks.length
                  ? noTasksElement("on the horizon")
                  : onTheHorizonTasks.map((task: any) => (
                      <div
                        key={task._id || ""}
                        className={`card ${
                          task?.isCompleted ? "card--success" : ""
                        }`}
                      >
                        <div className="card-content">
                          <div
                            className={
                              task.isCompleted ||
                              checkTaskBulkActionDisabled(task)
                                ? "input-wrap button-disabled mr-2x"
                                : "input-wrap mr-2x"
                            }
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              value="Checkbox Inline 2"
                              id={task._id || ""}
                              checked={
                                task._id &&
                                (selectedTasks.includes(task._id) ||
                                  task.isCompleted)
                                  ? true
                                  : false
                              }
                              onChange={() => handleCheck(task)}
                              disabled={checkTaskBulkActionDisabled(task)}
                              className="form-group__radio mr-10"
                            />
                            <label
                              htmlFor={task._id || ""}
                              className="box-links__nodes"
                            ></label>
                          </div>
                          <div className="card-text">
                            <h3
                              className="link-item"
                              onClick={() => setViewTaskDetail(task)}
                            >
                              {task.name}
                            </h3>
                            <ul className="card-info">
                              <li className="duedate warning-icon">
                                {task.workData?.priority === "Priority" && (
                                  <box-icon name="error-alt"></box-icon>
                                )}
                                {dayjs(task.requiredDeadline).format(
                                  "ddd MMM D YYYY",
                                )}
                              </li>
                              {task.workData?.optionalDeadline ? (
                                <li>
                                  <box-icon name="calendar"></box-icon>Due
                                  <strong className="ml-2x">
                                    {new Date(
                                      task.workData.optionalDeadline,
                                    ).toDateString()}
                                  </strong>
                                </li>
                              ) : null}
                              <li>
                                {task.childOf?.name && (
                                  <box-icon
                                    type="solid"
                                    name="bullseye"
                                  ></box-icon>
                                )}
                                {getAttachedGoal(task)}
                              </li>
                              <li>
                                {getCreator(task) !== "you" &&
                                  `Added by ${getCreator(task)}`}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-actions">
                          <MessageAttachment
                            channel={channel}
                            showChatHistoryOfAChannel={
                              showChatHistoryOfAChannel
                            }
                            attributes={{
                              _id: task._id || "",
                              name: task.name,
                              uowType: task.uowType,
                              childOf: task.childOf?._id,
                              assignee: student?._id || "",
                            }}
                            receiver={student}
                          />
                          {menuElement(task, "horizon")}
                        </div>
                      </div>
                    ))}
              </AccordionItemPanel>
            </>
          )}
        </AccordionItemState>
      </AccordionItem>

      <AccordionItem uuid="draft" className="accordion-item">
        <AccordionItemState>
          {({ expanded }) => (
            <>
              <AccordionItemHeading className="">
                <AccordionItemButton className="accordion-item__title">
                  <div className="title-left">
                    <div
                      className={
                        checkTasksComplete(draftTasks)
                          ? "input-wrap mb-8x button-disabled"
                          : "input-wrap mb-8x"
                      }
                    >
                      <input
                        type="checkbox"
                        id="draft"
                        className="form-group__radio mr-10"
                        checked={draftChecked}
                        onChange={() => {
                          handleDraftCheck();
                        }}
                        disabled={checkTasksComplete(draftTasks)}
                      />
                      <label
                        htmlFor="draft"
                        className="box-links__nodes"
                      ></label>
                    </div>
                    <span>Draft</span>
                  </div>
                  <div className="title-right">
                    <span
                      className="link-item mr-3x plus-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setQuickAddTask({
                          requiredDeadline: "",
                        });
                      }}
                    >
                      <box-icon name="plus-circle" type="solid" />
                    </span>
                    <span>
                      <box-icon
                        name={!expanded ? "chevron-down" : "chevron-up"}
                      ></box-icon>
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-item__content">
                {!draftTasks.length
                  ? noTasksElement("draft")
                  : draftTasks.map((task: any) => (
                      <div key={task._id || ""} className="card card--warning">
                        <div className="card-content">
                          <div
                            className={
                              task.isCompleted ||
                              checkTaskBulkActionDisabled(task)
                                ? "input-wrap button-disabled mr-2x"
                                : "input-wrap mr-2x"
                            }
                          >
                            <input
                              type="checkbox"
                              id={task._id || ""}
                              checked={
                                task._id &&
                                (selectedTasks.includes(task._id) ||
                                  task.isCompleted)
                                  ? true
                                  : false
                              }
                              onChange={() => handleCheck(task)}
                              disabled={
                                task.isCompleted ||
                                checkTaskBulkActionDisabled(task)
                              }
                              className="form-group__radio mr-10"
                            />
                            <label
                              htmlFor={task._id || ""}
                              className="box-links__nodes"
                            ></label>
                          </div>
                          <div className="card-text">
                            <h3
                              className="link-item"
                              onClick={() => setViewTaskDetail(task)}
                            >
                              {task.name}
                            </h3>
                            <ul className="card-info">
                              <li className="duedate">
                                {dayjs(task.requiredDeadline).format(
                                  "ddd MMM D YYYY",
                                )}
                              </li>
                              {task.workData?.optionalDeadline ? (
                                <li>
                                  <box-icon name="calendar"></box-icon>Due
                                  <strong className="ml-2x">
                                    {new Date(
                                      task.workData.optionalDeadline,
                                    ).toDateString()}
                                  </strong>
                                </li>
                              ) : null}
                              <li>
                                {task.childOf?.name && (
                                  <box-icon
                                    type="solid"
                                    name="bullseye"
                                  ></box-icon>
                                )}
                                {getAttachedGoal(task)}
                              </li>
                              <li>
                                {getCreator(task) !== "you" &&
                                  `Added by ${getCreator(task)}`}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-actions">
                          {menuElement(task, "draft")}
                        </div>
                      </div>
                    ))}
              </AccordionItemPanel>
            </>
          )}
        </AccordionItemState>
      </AccordionItem>

      <div onClick={openAddTask} className="link-add-new">
        <box-icon name="plus"></box-icon>
        <span className="link-text">Add New Task</span>
      </div>

      {selectedTasks.length > 0 && (
        <div className="bulk-action-block">
          <div className="bulk-action-block__content">
            <div className="bulk-action-block-left">
              {triggeredBulkTaskAction === "publish" && (
                <button
                  disabled={bulkTransactionLoading}
                  onClick={bulkPublishTasks}
                  className="btn btn--primary"
                >
                  Publish Drafts
                </button>
              )}
              {triggeredBulkTaskAction === "complete" && (
                <button
                  disabled={bulkTransactionLoading}
                  onClick={bulkCompleteTasks}
                  className="btn btn--green"
                >
                  Mark as Completed
                </button>
              )}
              <button
                disabled={bulkTransactionLoading}
                onClick={() => setIsBulkDeleteTaskOpen(true)}
                className="btn"
              >
                Delete
              </button>
            </div>

            <div className="bulk-action-block-right">
              <button
                disabled={bulkTransactionLoading}
                onClick={resetBulkTransaction}
                className="btn txt-primary-color"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        className="modal-block"
        isOpen={isBulkDeleteTaskOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsBulkDeleteTaskOpen(false)}
      >
        {selectedTasks.length < 1 ? (
          <Loader type="ThreeDots" />
        ) : (
          <DeleteConfirm
            deleteResource={handleBulkDelete}
            closeModal={() => setIsBulkDeleteTaskOpen(false)}
            message={{
              header: "Do you want to delete the task(s)?",
              body: "Deleting tasks will remove them permanently. This action is not reversible",
            }}
          />
        )}
      </Modal>

      <Modal
        isOpen={isEditTaskOpen}
        className="modal-block-task"
        ariaHideApp={false}
        onRequestClose={() => setIsEditTaskOpen(false)}
      >
        {actionTask === null ? (
          <Loader type="ThreeDots" />
        ) : (
          <UpdateTask
            goal={{ assignee: student }}
            saveTask={updateTaskHandler}
            closeModal={() => setIsEditTaskOpen(false)}
            task={actionTask}
            student={student}
          />
        )}
      </Modal>
      <Modal
        isOpen={isDuplicateTaskOpen}
        className="modal-block-task"
        ariaHideApp={false}
        onRequestClose={() => setIsDuplicateTaskOpen(false)}
      >
        {actionTask === null ? (
          <Loader type="ThreeDots" />
        ) : (
          <AddTask
            goal={{ assignee: student }}
            saveTask={handleSaveTask}
            closeModal={() => setIsDuplicateTaskOpen(false)}
            duplicate={actionTask}
            student={student}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!quickAddTask}
        className="modal-block-task"
        ariaHideApp={false}
        onRequestClose={() => setQuickAddTask(null)}
      >
        {quickAddTask === null ? (
          <Loader type="ThreeDots" />
        ) : (
          <AddTask
            goal={{ assignee: student }}
            saveTask={handleSaveTask}
            closeModal={() => setQuickAddTask(null)}
            quickTask={quickAddTask}
            student={student}
          />
        )}
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isDeleteTaskOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setIsDeleteTaskOpen(false);
          setSelectedDeleteType(UowDeleteEditType.SELF);
        }}
      >
        {actionTask === null ? (
          <Loader type="ThreeDots" />
        ) : (
          <DeleteConfirm
            deleteResource={() => {
              if (actionTask._id)
                deleteTaskHandler(actionTask._id, selectedDeleteType);
            }}
            closeModal={() => {
              setIsDeleteTaskOpen(false);
              setSelectedDeleteType(UowDeleteEditType.SELF);
            }}
            message={{
              header: "Do you want to delete the task(s)?",
              body: "Deleting tasks will remove them permanently. This action is not reversible",
            }}
          />
        )}
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isMarkAsIncomplete}
        ariaHideApp={false}
        onRequestClose={() => setIsMarkAsIncomplete(false)}
      >
        {actionTask === null ? (
          <Loader type="ThreeDots" />
        ) : (
          <InCompleteConfirm
            inCompleteTaskHandler={() => {
              if (actionTask._id) markAsIncompleteHandler(actionTask._id);
            }}
            closeModal={() => setIsMarkAsIncomplete(false)}
            message={{
              header: "Are you sure you want to mark this as incomplete?",
              body: "Student can see this task has been marked as incomplete.",
            }}
          />
        )}
      </Modal>
      <Modal
        className="modal-block"
        isOpen={isTaskSuccessOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsTaskSuccessOpen(false)}
      >
        <ConfirmSuccess
          multiple={true}
          user={student}
          closeModal={() => setIsTaskSuccessOpen(false)}
        />
      </Modal>

      <Modal
        className="modal-block"
        isOpen={showDeleteTaskOptions}
        ariaHideApp={false}
        onRequestClose={() => setShowDeleteTaskOptions(false)}
      >
        <SelectRecurrenceDeleteEditType
          onCancel={() => setShowDeleteTaskOptions(false)}
          onTypeSelected={(type: UowDeleteEditType) => {
            setSelectedDeleteType(type);
            setIsDeleteTaskOpen(true);
            setShowDeleteTaskOptions(false);
          }}
        />
      </Modal>

      <Modal
        className="modal-block"
        isOpen={viewTaskDetail !== null}
        ariaHideApp={false}
        onRequestClose={() => setViewTaskDetail(null)}
      >
        {viewTaskDetail !== null && (
          <TaskDetail
            closeModal={() => {
              setViewTaskDetail(null);
            }}
            planningPeriod={getPlanningPeriodByDate(
              planningPeriods,
              new Date(viewTaskDetail.requiredDeadline),
              true,
            )}
            inCompleteTaskHandler={markAsIncompleteHandler}
            publishTaskHandler={publishTaskHandler}
            completeTaskHandler={completeTaskHandler}
            task={viewTaskDetail}
          />
        )}
      </Modal>
    </Accordion>
  );
};

export default PlansList;
