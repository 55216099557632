import { connect } from "react-redux";
import { useEffect, useCallback, useMemo } from "react";

import { getFullName } from "utils/names";
import { IGoal } from "commons/types/goals";
import UpdateTaskForm from "./UpdateTaskForm";
import { getCurrentEnrollment } from "utils/user";
import { fetchGoals } from "store/actions/data/goals";
import { IUpdateTaskParams } from "./updateTask.interface";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { fetchPlanningPeriods } from "store/actions/data/planningPeriods";

const UpdateTask: React.FC<IUpdateTaskParams> = ({
  goal,
  fetchGoals,
  planningPeriods,
  fetchPlanningPeriods,
  student,
  ...props
}): JSX.Element => {
  const propsData = useMemo(() => ({ ...props }), [props]);

  const fetchFormData = useCallback(async () => {
    fetchPlanningPeriods &&
      fetchPlanningPeriods({
        user: student._id,
        term: getCurrentEnrollment(student)?.enrolledTerm._id,
      });
    fetchGoals({ assignee: goal?.assignee?._id });
  }, [fetchGoals, goal, fetchPlanningPeriods, student]);

  useEffect(() => {
    fetchFormData();
  }, [fetchFormData]);

  const getInitialValues = (task: any) => {
    return {
      ...task,
      goalDeadline: task.childOf?.requiredDeadline,
      childOf: task.childOf?._id,
      termEndDate:
        goal?.term?.endDate ||
        getCurrentEnrollment(student)?.enrolledTerm.endDate,
      workData: {
        ...task.workData,
        uowType: "Task",
      },
    };
  };

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Update a Task for {getFullName(goal?.assignee)}</h3>
        <span className="link-item" onClick={propsData.closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <UpdateTaskForm
        goal={goal}
        fetchGoals={fetchGoals}
        initialValues={getInitialValues(propsData.task)}
        planningPeriods={planningPeriods}
        student={student}
        {...props}
      />
    </div>
  );
};

interface State {
  goals: {
    fetchGoals: IGoal[];
  };
  planningPeriods: { fetchPlanningPeriods: IPlanningPeriod[] };
}

const mapStateToProps = (state: State) => ({
  goals: state.goals.fetchGoals,
  planningPeriods: state.planningPeriods.fetchPlanningPeriods,
});

const mapDispatchToProps = {
  fetchGoals,
  fetchPlanningPeriods,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTask);
