import pinterpolate from "pinterpolate";

import config from "configs";
import { Role } from "constants/roles";
import { IEnrollment, IUser } from "commons/types/users";
import { Params } from "commons/types/urls";
import { http, stringify } from "utils/http";

export const add = async (payload: IUser) => {
  const url = config.endpoints.users.all;
  const { data } = await http.post(url, payload);

  return data;
};

export const update = async (id: string, payload: IUser) => {
  const url = pinterpolate(config.endpoints.users.one, { id: id });
  const { data } = await http.patch(url, payload);

  return data;
};

export const fetch = async (id: string) => {
  const url = pinterpolate(config.endpoints.users.one, { id: id });
  const { data } = await http.get(url);

  return data;
};

export const fetchPagedAll = async (params: Params) => {
  const url = stringify(`${config.endpoints.users.all}/page`, {
    role: Role.SUBJECT,
    ...params,
  });

  const { data } = await http.get(url);

  return data;
};

export const fetchAll = async (params: Params) => {
  const url = stringify(config.endpoints.users.all, {
    role: Role.SUBJECT,
    ...params,
  });

  const { data } = await http.get(url);

  return data;
};

export const fetchAllStudentsOfPractitioner = async (
  practitionerId: string,
) => {
  const url = pinterpolate(config.endpoints.users.practitionerSubjects, {
    practitionerId: practitionerId,
  });

  const { data } = await http.get(url);

  return data;
};

export const studentEnrollment = async (id: string, payload: IEnrollment) => {
  const url = pinterpolate(config.endpoints.users.enrollment, { id: id });
  const { data } = await http.post(url, payload);

  return data;
};

export const updateStudentEnrollment = async (
  studentId: string,
  payload: IEnrollment,
) => {
  const url = pinterpolate(config.endpoints.users.enrollmentOne, {
    id: studentId,
    enrollmentId: payload._id || "",
  });
  const { data } = await http.patch(url, payload);

  return data;
};
