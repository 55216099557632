import config from "configs";
import { Role } from "constants/roles";
import { IUser } from "commons/types/users";
import { http, stringify } from "utils/http";
import {
  DownloadSurveyParams,
  DownloadSurveyData,
  DownloadAggregateSurveyParams,
  ISurveyFileNameParams,
} from "./surveyDownload.types";
import { getDateInFormat } from "utils/dates";

export const fetchSurveyTakers = async (
  queryString: string,
): Promise<Array<IUser>> => {
  const url = stringify(config.endpoints.users.all, {
    roles: [Role.SUBJECT, Role.PRACTITIONER, Role.SPONSOR],
    q: queryString,
  });

  const { data } = await http.get(url);

  return data;
};

export const downloadSurveyResponses = async (
  downloadSurveyParams: DownloadSurveyParams,
): Promise<DownloadSurveyData> => {
  const url = stringify(
    config.endpoints.survey.downloadSurveyResponses,
    downloadSurveyParams,
  );

  const { data } = await http.get(url);

  return data.data;
};

export const downloadAggregatedSurveyResponses = async (
  downloadSurveyParams: DownloadAggregateSurveyParams,
): Promise<DownloadSurveyData> => {
  const url = stringify(
    config.endpoints.survey.downloadAggregateSurveyResponses,
    downloadSurveyParams,
  );

  const { data } = await http.get(url);

  return data.data;
};

/**
 * Generates filename for suvey responses based on given params
 * @param fileNameParams
 * @returns fileName string
 */
export const generateFileName = (fileNameParams: ISurveyFileNameParams) => {
  const { isAggregatedSurvey, surveyType, term, student } = fileNameParams;

  let fileName = isAggregatedSurvey
    ? "Aggregated-Survey-Responses"
    : "Individual-Survey-Responses";

  if (surveyType) {
    fileName += `-${surveyType}`;
  }

  if (term) {
    fileName += `-${term}`;
  }

  if (student) {
    fileName += `-${student}`;
  }

  return (fileName += `-${getDateInFormat(Date())}`);
};
