export enum FetchInitialConfigType {
  FETCH_INITIAL_CONFIG_PENDING = "FETCH_INITIAL_CONFIG_PENDING",
  FETCH_INITIAL_CONFIG_SUCCESS = "FETCH_INITIAL_CONFIG_SUCCESS",
  FETCH_INITIAL_CONFIG_ERROR = "FETCH_INITIAL_CONFIG_ERROR",
}

export enum FetchFinalConfigType {
  FETCH_FINAL_CONFIG_PENDING = "FETCH_FINAL_CONFIG_PENDING",
  FETCH_FINAL_CONFIG_SUCCESS = "FETCH_FINAL_CONFIG_SUCCESS",
  FETCH_FINAL_CONFIG_ERROR = "FETCH_FINAL_CONFIG_ERROR",
}

export enum FetchTenantsType {
  FETCH_TENANTS_PENDING = "FETCH_TENANTS_PENDING",
  FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS",
  FETCH_TENANTS_ERROR = "FETCH_TENANTS_ERROR",
}

export enum AddTenantType {
  ADD_TENANT_PENDING = " ADD_TENANT_PENDING",
  ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS",
  ADD_TENANT_ERROR = "ADD_TENANT_ERROR",
}

export enum UpdateTeantType {
  UPDATE_TENANT_PENDING = "UPDATE_TENANT_PENDING",
  UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS",
  UPDATE_TENANT_ERROR = "UPDATE_TENANT_ERROR",
}

export enum DeleteTenantType {
  DELETE_TENANT_PENDING = "DELETE_TENANT_PENDING",
  DELETE_TENANT_SUCCESS = "DELETE_TENANT_SUCCESS",
  DELETE_TENANT_ERROR = "DELETE_TENANT_ERROR",
}

export interface InitialConfig {
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUDIENCE: string;
}

export enum FetchAllUsersType {
  FETCH_USERS_PENDING = "FETCH_USERS_PENDING",
  FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR = "FETCH_USERS_ERROR",
}

export enum FetchTenantUserListType {
  FETCH_TENANT_USERLIST_PENDING = "FETCH_TENANT_USERLIST_PENDING",
  FETCH_TENANT_USERLIST_SUCCESS = "FETCH_TENANT_USERLIST_SUCCESS",
  FETCH_TENANT_USERLIST_ERROR = "FETCH_TENANT_USERLIST_ERROR",
}

export enum AddUserType {
  ADD_USER_PENDING = "ADD_USER_PENDING",
  ADD_USER_SUCCESS = "ADD_USER_SUCCESS",
  ADD_USER_ERROR = "ADD_USER_ERROR",
}

export enum UpdateUserType {
  UPDATE_USER_PENDING = "UPDATE_USER_PENDING",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
}

export enum DeleteUserType {
  DELETE_USER_PENDING = "DELETE_USER_PENDING",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR = "DELETE_USER_ERROR",
}

export interface ITenantDetail {
  tenantLogo: string;
  name: string;
  email: string;
  subDomain: string;
  phone: string;
  location: string;
  tenantFriendlyName: string;
  tenantAdminName: string;
  status: string;
  features: {
    communication: {
      chat: boolean;
      audiocall: boolean;
      videocall: boolean;
    };
  };
  services: {
    auth0: {
      auth0Domain: string;
      audience: string;
      auth0CustomDomain: string;
      auth0DbConnection: string;
      singlePage: {
        auth0ClientId: string;
        auth0ClientSecret: string;
      };
      mobile: {
        auth0ClientId: string;
      };
      machineToMachine: {
        auth0ClientId: string;
        auth0ClientSecret: string;
      };
    };
    pusher: {
      pusherAppKey: string;
      pusherCluster: string;
      pusherSecret: string;
      pusherAppId: string;
    };
    twilio: {
      twilioToken: string;
      identity: string;
    };
  };

  _id?: string;
}

export interface ITenantUser {
  firstName: string;
  middleName: string;
  lastName: string;
  phone: string;
  dateOfBirth: string;
  roles: string[];
  tenant: string;
  auth0UserId?: string;
  emailVerified?: boolean;
  email: string;
  _id?: string;
}
