interface ITaskMenuProps {
  canEdit: boolean;
  isMenuTop: boolean;
  editTerm: () => void;
  canNewEnroll: boolean;
  editStudent: () => void;
  newEnrollment: () => void;
}

const ListMenu: React.FC<ITaskMenuProps> = ({
  canEdit,
  editTerm,
  isMenuTop,
  editStudent,
  canNewEnroll,
  newEnrollment,
}) => {
  return (
    <div className={`menu-dialog ${isMenuTop && "menu-dialog--top"}`}>
      <ul className="menu-dialog__body">
        <li onClick={newEnrollment} className="menu-list">
          <span className="ml-2x">Enroll in New Term</span>
        </li>
        <li onClick={editStudent} className="menu-list">
          <span className="ml-2x">Edit Student Information</span>
        </li>
        {canEdit && (
          <li onClick={editTerm} className="menu-list">
            <span className="ml-2x">Edit Term Information</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ListMenu;
