import React from "react";
import { connect } from "react-redux";
import { Column, useTable } from "react-table";

import AcademicYearActionCell from "./AcademicYearActionCell";
import {
  IAcademicYear,
  IAcademicYearParams,
} from "../../../commons/types/academicYear";
import {
  deleteAcademicYear,
  fetchAcademicYears,
} from "../../../store/actions/data/academicYear";

const AcademicYearList = ({
  academicYears,
  onAddButtonClicked,
  deleteAcademicYear,
  fetchAcademicYears,
}: {
  academicYears: Array<IAcademicYear>;
  onAddButtonClicked: () => void;
  deleteAcademicYear: (id: string) => void;
  fetchAcademicYears: (params: IAcademicYearParams) => void;
}) => {
  const handleDeleteAcademicYear = React.useMemo(
    () => async (id: string) => {
      await deleteAcademicYear(id);

      fetchAcademicYears({});
    },
    [deleteAcademicYear, fetchAcademicYears],
  );

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Year",
          accessor: "name",
          Cell: ({ value }: { value: string }) => {
            return value;
          },
        },
        {
          Header: "Actions",
          accessor: "_id",
          Cell: ({ row }: any) => {
            return (
              <AcademicYearActionCell
                row={row}
                onDeleteAcademicYear={handleDeleteAcademicYear}
              />
            );
          },
        },
      ] as Column<IAcademicYear>[],
    [handleDeleteAcademicYear],
  );

  const tableInstance = useTable({ columns, data: academicYears });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <div className="content-panel">
        <div className="page-heading">
          <h1>Academic Years</h1>
          <div className="page-heading__right align-items-center">
            <button
              className="btn btn--primary"
              type="button"
              onClick={onAddButtonClicked}
            >
              Add
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="head-row">
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="table__col">
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="table__row">
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="table__col">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="right-gap" />
    </>
  );
};

const mapDispatchToProps = {
  deleteAcademicYear,
  fetchAcademicYears,
};

export default connect(null, mapDispatchToProps)(AcademicYearList);
