import { useFormik } from "formik";
import { useEffect, useState } from "react";

import { error } from "utils/toast";
import { ILocation } from "commons/types";
import Loader from "commons/components/Loader";
import { fetchAll as fetchCenters } from "services/centers";
import { TextInput, CustomSelect } from "commons/components/form-fields";
import SatelliteLocationSchema from "commons/schemas/satelliteLocation";
import { ISatelliteLocationFormProps } from "./satelliteLocation.interface";

interface LabelValue {
  label: string;
  value: string;
}

const Form: React.FC<ISatelliteLocationFormProps> = ({
  closeModal,
  initialValues,
  isEdit = false,
  saveSatelliteLocation,
  updateSatelliteLocation,
}) => {
  const [centers, setCenters] = useState<LabelValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (!isEdit && saveSatelliteLocation) {
        return saveSatelliteLocation(values);
      }

      if (updateSatelliteLocation && initialValues._id) {
        updateSatelliteLocation(initialValues._id, values);
      }
    },
    enableReinitialize: true,
    validationSchema: SatelliteLocationSchema,
  });

  useEffect(() => {
    const populateCenterDropdown = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchCenters({});
        const centerOptions: LabelValue[] = data.map((record: ILocation) => ({
          label: record.name,
          value: record._id,
        }));

        setCenters(centerOptions);
      } catch (ex) {
        error("Failed to fetch centers");
      }
      setIsLoading(false);
    };

    Promise.allSettled([populateCenterDropdown()]);
  }, []);

  if (isLoading) return <Loader type="ThreeDots" />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body pb-0x">
        <div className="row">
          <div className="col-12">
            <CustomSelect
              label="Center"
              name="location"
              options={centers}
              required={true}
              value={centers.find(
                (center: LabelValue) => center.value === formik.values.location,
              )}
              handleChange={(center: LabelValue) => {
                formik.setFieldValue("location", center.value);
              }}
              touched={formik.touched}
              errors={formik.errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextInput
              label="Satellite Location Name"
              name="address"
              placeholder="Enter Satellite Location Name"
              touched={formik.touched}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {isEdit ? "Update" : "Save"}
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
