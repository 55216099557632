import { IAssignment } from "commons/types/assignments";

export enum AddAssignmentActionType {
  ADD_ASSIGNMENT_PENDING = "ADD_ASSIGNMENT_PENDING",
  ADD_ASSIGNMENT_REJECTED = "ADD_ASSIGNMENT_REJECTED",
  ADD_ASSIGNMENT_FULFILLED = "ADD_ASSIGNMENT_FULFILLED",
}
export interface AddAssignmentAction {
  type: AddAssignmentActionType;
  data: IAssignment;
}

export enum FetchAssignmentsActionType {
  FETCH_ASSIGNMENT_PENDING = "FETCH_ASSIGNMENT_PENDING",
  FETCH_ASSIGNMENT_REJECTED = "FETCH_ASSIGNMENT_REJECTED",
  FETCH_ASSIGNMENT_FULFILLED = "FETCH_ASSIGNMENT_FULFILLED",
}
export interface FetchAssignmentsAction {
  type: FetchAssignmentsActionType;
  data: IAssignment[];
}

export enum FetchAssignmentActionType {
  FETCH_ASSIGNMENT_PENDING = "FETCH_ASSIGNMENT_PENDING",
  FETCH_ASSIGNMENT_REJECTED = "FETCH_ASSIGNMENT_REJECTED",
  FETCH_ASSIGNMENT_FULFILLED = "FETCH_ASSIGNMENT_FULFILLED",
}
export interface FetchAssignmentAction {
  type: FetchAssignmentActionType;
  data: IAssignment;
}

export enum UpdateAssignmentActionType {
  UPDATE_ASSIGNMENT_PENDING = "UPDATE_ASSIGNMENT_PENDING",
  UPDATE_ASSIGNMENT_REJECTED = "UPDATE_ASSIGNMENT_REJECTED",
  UPDATE_ASSIGNMENT_FULFILLED = "UPDATE_ASSIGNMENT_FULFILLED",
}
export interface UpdateAssignmentAction {
  type: UpdateAssignmentActionType;
  data: IAssignment;
}

export enum DeleteAssignmentActionType {
  DELETE_ASSIGNMENT_PENDING = "DELETE_ASSIGNMENT_PENDING",
  DELETE_ASSIGNMENT_FULFILLED = "DELETE_ASSIGNMENT_FULFILLED",
  DELETE_ASSIGNMENT_REJECTED = "DELETE_ASSIGNMENT_REJECTED",
}

export interface DeleteAssignmentAction {
  type: DeleteAssignmentActionType;
  data: IAssignment;
}
