import { useFormik } from "formik";

import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import { INote } from "commons/types/notes";
import { NoteSchema } from "commons/schemas/notes";
import { TextAreaInput } from "commons/components/form-fields";
import { getDateInFormat } from "utils/dates";

const NoteForm: React.FC<{
  closeModal: () => void;
  initialValues: INote;
  isEdit?: boolean;
  saveNote: (newNote: INote) => any;
  updateNote: (id: string, payload: INote) => any;
}> = ({ closeModal, initialValues, isEdit = false, saveNote, updateNote }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notes: initialValues.notes,
      createdFor: (initialValues.createdFor as IUser)._id || "",
    },
    onSubmit: async (values) => {
      if (!isEdit && saveNote) {
        const newNote = await saveNote(values);
        if (newNote) closeModal();
      }
      if (updateNote && initialValues._id) {
        const updatedNote = await updateNote(initialValues._id, values);
        if (updatedNote) closeModal();
      }
    },
    validationSchema: NoteSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="modal-wrap__body task pb-0x">
        {isEdit ? (
          <div className="mb-4x task__details">
            <ul className="task-list">
              <li className="row">
                <div className="col-6 pl-0x d-flex">
                  <div className="pl-1x task-list__content">
                    <span>Added by</span>
                    {getFullName(initialValues.createdBy as IUser)}
                  </div>
                </div>
                <div className="col-6 pl-0x">
                  <div className="pl-1x task-list__content">
                    <span>Added date</span>
                    {getDateInFormat(initialValues.createdAt || "")}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ) : null}
        <TextAreaInput
          label="Please write the student’s progress"
          name="notes"
          placeholder="Enter student's notes"
          touched={formik.touched}
          errors={formik.errors}
          values={formik.values.notes}
          handleChange={formik.handleChange}
        />
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-4x"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {isEdit ? "Save" : "Add"}
            </button>
            <span className="btn txt-primary-color" onClick={closeModal}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NoteForm;
