import { Dispatch } from "redux";

import { error, success } from "utils/toast";
import * as centersService from "services/centers";
import { IUpdateCenters, ICentersParams, ICenter } from "commons/types/centers";
import {
  AddCenterActionType,
  FetchCentersActionType,
  UpdateCenterActionType,
  DeleteCenterActionType,
} from "store/actions/types/centers";

export const addCenter = (payload: ICenter) => async (dispatch: Dispatch) => {
  dispatch({
    type: AddCenterActionType.ADD_CENTER_PENDING,
  });
  try {
    const { data } = await centersService.add(payload);
    dispatch({
      type: AddCenterActionType.ADD_CENTER_FULFILLED,
      data: data,
    });
    success("Saved Center");
    return data;
  } catch (err: any) {
    dispatch({
      type: AddCenterActionType.ADD_CENTER_REJECTED,
      data: err,
    });

    error("Center failed to save");
  }
};

export const fetchCenters =
  (params: ICentersParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: FetchCentersActionType.FETCH_CENTERS_PENDING,
    });
    try {
      const { data } = await centersService.fetchAll(params);

      dispatch({
        type: FetchCentersActionType.FETCH_CENTERS_FULFILLED,
        data: data,
      });
      return data;
    } catch (err: any) {
      dispatch({
        type: FetchCentersActionType.FETCH_CENTERS_REJECTED,
        data: err,
      });

      error("Center failed to load");
    }
  };

export const updateCenter =
  (id: string, payload: IUpdateCenters) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateCenterActionType.UPDATE_CENTER_PENDING,
    });
    try {
      const { data } = await centersService.update(id, payload);
      dispatch({
        type: UpdateCenterActionType.UPDATE_CENTER_FULFILLED,
        data: data,
      });
      success("Center updated successfully");
    } catch (err: any) {
      dispatch({
        type: UpdateCenterActionType.UPDATE_CENTER_REJECTED,
        data: err,
      });

      error("Center failed to update");
    }
  };

export const deleteCenter = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DeleteCenterActionType.DELETE_CENTER_PENDING,
  });
  try {
    const { data } = await centersService.deleteOne(id);

    dispatch({
      type: DeleteCenterActionType.DELETE_CENTER_FULFILLED,
      data: data,
    });
    success("Center deleted successfully");
  } catch (err: any) {
    dispatch({
      type: DeleteCenterActionType.DELETE_CENTER_REJECTED,
      data: err,
    });

    error("Center failed to delete");
  }
};
