import {
  FetchMentorsAction,
  FetchMentorsActionType,
} from "store/actions/types/mentors";

const INITIAL_STATE = {
  fetchMentors: [],
  fetchMentorsLoading: false,
  fetchMentorsError: null,
};

const reducer = (state = INITIAL_STATE, action: FetchMentorsAction) => {
  switch (action.type) {
    case FetchMentorsActionType.FETCH_MENTORS_PENDING:
      return {
        ...state,
        fetchMentorsLoading: true,
        fetchMentorsError: null,
      };
    case FetchMentorsActionType.FETCH_MENTORS_FULFILLED:
      return {
        ...state,
        fetchMentors: action.data,
        fetchMentorsLoading: false,
        fetchMentorsError: null,
      };
    case FetchMentorsActionType.FETCH_MENTORS_REJECTED:
      return {
        ...state,
        fetchMentorsLoading: false,
        fetchMentorsError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
