import { Status } from "constants/status";
import { ISponsor, IUser } from "commons/types/users";
import { AVATAR_FILETYPE, AVATAR_MAX_SIZE, UserType } from "constants/index";

export const getCurrentEnrollment = (student: IUser) => {
  return student.userData?.enrollments?.find(
    (enrollment: any) => enrollment.enrolledTerm?.isCurrent === true,
  );
};

export const getStudentEnrollments = (student: IUser) => {
  return student.userData?.enrollments;
};

export const getStudentFormValues = (student: IUser) => {
  const enrollments = student.userData?.enrollments;
  return {
    firstName: student.firstName,
    lastName: student.lastName,
    email: student.email,
    phoneNumber: student.phoneNumber,
    locations: student.locations?.map((location: any) => location._id),
    dateOfBirth: student.dateOfBirth,
    timezone: student.timezone,
    userData: {
      type: UserType.Subject,
      externalResources: student.userData?.externalResources,
      nickName: student.userData?.nickName || "",
      learningEnvironment: student.userData?.learningEnvironment || "",
      status: student.userData?.status || Status.DRAFT,
      diagnosis: student.userData?.diagnosis || "",
      isGuardianApplicable: student.userData?.isGuardianApplicable,
      enrollments: enrollments?.length
        ? enrollments.map((enrollment) => {
            return {
              learningCenter: enrollment.learningCenter?._id,
              _id: enrollment?._id,
              satelliteLocation: enrollment.satelliteLocation?._id,
              enrollmentDate: enrollment.enrollmentDate,
              enrolledTerm: enrollment.enrolledTerm?._id,
              mentorshipNote: enrollment.mentorshipNote || "",
              practitioners: enrollment.practitioners || [],
              status: enrollment?.status || Status.DRAFT,
              dropMessage: enrollment?.dropMessage,
              mentorshipSchedule: {
                old: enrollments
                  ? enrollment.mentorshipSchedule?.old?.map((oldSchedule) => ({
                      ...oldSchedule,
                      program: oldSchedule?.program?._id,
                    }))
                  : [],
                latest: enrollments
                  ? {
                      ...enrollment.mentorshipSchedule?.latest,
                      program:
                        enrollment.mentorshipSchedule?.latest?.program?._id,
                    }
                  : {
                      cadence: "",
                      day: "",
                    },
              },
            };
          })
        : [],
      schoolInfo: {
        isSchoolInfoApplicable:
          student.userData?.schoolInfo?.isSchoolInfoApplicable,
        institution: student.userData?.schoolInfo?.institution?._id,
        schoolType: student.userData?.schoolInfo?.schoolType || "",
        schoolDistrict: student.userData?.schoolInfo?.schoolDistrict?._id,
        studentType: student.userData?.schoolInfo?.studentType || "",
        grade: student.userData?.schoolInfo?.grade || "",
        academicYear: student.userData?.schoolInfo?.academicYear || "",
        academicTerm: student.userData?.schoolInfo?.academicTerm || "",
        notes: student.userData?.schoolInfo?.notes || "",
        accommodation: student.userData?.schoolInfo?.accommodation?._id,
        isOnAcademicProbation:
          student.userData?.schoolInfo?.isOnAcademicProbation || false,
      },
    },
    relatedUsers: {
      type: UserType.Subject,
      sponsors: student.relatedUsers?.sponsors?.map((guardian: ISponsor) => {
        return {
          userId: guardian.userId._id,
          relationshipWithSponsor: guardian.relationshipWithSponsor || "",
          livingScheduleWithParents: guardian.livingScheduleWithParents || "",
        };
      }),
      practitioners:
        student.relatedUsers?.practitioners?.map((practitioner) => {
          return {
            userId: practitioner.userId?._id,
            status: practitioner.status,
          };
        }) || [],
    },
  };
};

export const validateAvatar = (file: File) => {
  const fileExtension: string = file?.name.split(".").pop() || "";
  return file.size < AVATAR_MAX_SIZE && AVATAR_FILETYPE.includes(fileExtension);
};
