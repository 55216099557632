import * as FullStory from "@fullstory/browser";
import envConfig from "configs/env.configs";

export function identify(uid: string, userVars: any) {
  isDev() && FullStory.identify(uid, userVars);
}

export function isDev() {
  return envConfig.env === "dev" && envConfig.fullStoryOrgId;
}
