import { getFullName } from "utils/names";
import Conversation from "pages/mentor-dashboard/conversation";
import { CHAT_MESSAGE_COUNT_BEFORE_SCROLL } from "constants/index";
import { IAttachedMessageBoxProps } from "./messageAttachment.interface";

const AttachedMessageBox: React.FC<IAttachedMessageBoxProps> = ({
  channel,
  receiver,
  closeModal,
  attributes,
}) => {
  return (
    <div className="modal-wrap modal-wrap--sm">
      <div className="modal-wrap__header">
        <h3>Message {getFullName(receiver)}</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x"></box-icon>
        </span>
      </div>
      <div className="modal-wrap__body">
        <Conversation
          twilioChannel={channel}
          maxMessageCount={CHAT_MESSAGE_COUNT_BEFORE_SCROLL}
          attributes={attributes}
          studentData={receiver}
        />
      </div>
    </div>
  );
};

export default AttachedMessageBox;
