import "boxicons";
import Modal from "react-modal";
import { useEffect } from "react";
import { connect } from "react-redux";
import React, { useCallback, useState } from "react";
import Select, { OptionTypeBase, StylesConfig } from "react-select";

import config from "configs";
import { Link, useLocation } from "react-router-dom";
import StudentsList from "./StudentsList";
import { IUser } from "commons/types/users";
import { Params } from "commons/types/urls";
import Loader from "commons/components/Loader";
import useChatHook from "commons/hooks/useChatHook";
import SendAssessment from "../../../student-profile/assessment/SendAssessment";
import {
  fetchStudents,
  fetchMentorStudents,
} from "store/actions/data/students";
import { getDataFromLocalStorage } from "services/localStorage";

interface IStudentProps {
  students: IUser[];
  fetchStudentsLoading: boolean;
  fetchMentorStudents: (id: string) => Promise<IUser[]>;
  fetchStudents: (params?: Params) => Promise<IUser[]>;
}

const customFilterStyle: StylesConfig<OptionTypeBase, false> = {
  control: () => ({
    display: "flex",
    padding: 0,
    justifyContent: "space-between",
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  valueContainer: () => ({
    width: 100,
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
};

const ListStudents: React.FC<IStudentProps> = ({
  students,
  fetchStudents,
  fetchMentorStudents,
  fetchStudentsLoading = true,
}): JSX.Element => {
  const profile = getDataFromLocalStorage("profile");
  const { pathname } = useLocation();

  const [onlyMyStudents, setOnlyMyStudents] = useState(true);

  const [showAssessmentForm, setShowAssessmentForm] = useState<boolean>(false);

  const toggleAssessmentForm = () =>
    setShowAssessmentForm((prevState) => !prevState);

  const fetchStudentData = useCallback(() => {
    if (onlyMyStudents) fetchMentorStudents(profile?._id);
    else fetchStudents();
  }, [onlyMyStudents, fetchMentorStudents, fetchStudents, profile?._id]);

  useEffect(() => {
    fetchStudentData();
  }, [fetchStudentData]);

  const { showChatHistoryOfAChannel } = useChatHook();

  return (
    <>
      <div className="left-panel">
        <ul className="nav-left">
          <li
            className={`${
              pathname === config.uiPath.mentor.students ||
              pathname === config.uiPath.mentor.dashboard
                ? "nav-left__item--active"
                : "nav-left__item"
            } text-bold`}
          >
            <span className="link-item">
              <Link to={config.uiPath.mentor.students}>
                <box-icon name="group"></box-icon>
                Students
              </Link>
            </span>
          </li>
          {/* <li
            className={`${
              pathname === config.uiPath.mentor.assessment
                ? "nav-left__item--active"
                : "nav-left__item"
            } text-bold`}
          >
            <span className="link-item">
              <Link to={config.uiPath.mentor.assessment}>
                <box-icon name="spreadsheet" type="solid" />
                Assessment
              </Link>
            </span>
          </li> */}
        </ul>
      </div>
      <div className="content-panel">
        <div className="page-heading border-bottom mb-0x">
          <h2>Students</h2>
        </div>
        <div className="filters border-bottom">
          <ul className="filters__list">
            <li>
              <Select
                name="workData.recurrence"
                onChange={(selected: any) => {
                  setOnlyMyStudents(selected.value);
                }}
                isSearchable={false}
                onBlur={() => {}}
                options={[
                  {
                    label: (
                      <>
                        <span className="with-icon">
                          <box-icon name="globe" />
                          All Students
                        </span>
                      </>
                    ),
                    value: false,
                  },
                  {
                    label: (
                      <>
                        <span className="with-icon">
                          <box-icon name="group" />
                          My Students
                        </span>
                      </>
                    ),
                    value: true,
                  },
                ]}
                className="filter-item link-item"
                classNamePrefix="react-select"
                styles={customFilterStyle}
                value={
                  onlyMyStudents
                    ? {
                        label: (
                          <>
                            <span className="with-icon">
                              <box-icon name="group" />
                              My Students
                            </span>
                          </>
                        ),
                        value: true,
                      }
                    : {
                        label: (
                          <>
                            <span className="with-icon">
                              <box-icon name="globe" />
                              All Students
                            </span>
                          </>
                        ),
                        value: false,
                      }
                }
              />
            </li>
            {/* <li>
              <span className="filter-item link-item">
                A-Z
                <box-icon name="chevron-down"></box-icon>
              </span>
            </li> */}
          </ul>
          <button onClick={toggleAssessmentForm} className="btn btn--primary">
            Send Survey
          </button>
        </div>
        {fetchStudentsLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <StudentsList
            students={students}
            showChatHistoryOfAChannel={showChatHistoryOfAChannel}
          />
        )}
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={showAssessmentForm}
        className="modal-block-form"
        onRequestClose={toggleAssessmentForm}
      >
        <SendAssessment toggleAssessmentForm={toggleAssessmentForm} />
      </Modal>
    </>
  );
};

interface State {
  students: { fetchStudents: IUser[]; fetchStudentsLoading: boolean };
}

const mapStateToProps = (state: State) => ({
  students: state.students.fetchStudents,
  fetchStudentsLoading: state.students.fetchStudentsLoading,
});

const mapDispatchToProps = {
  fetchStudents,
  fetchMentorStudents,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListStudents);
