import { Params } from "./urls";

export interface IGoal {
  _id?: string;
  name: string;
  termId?: string;
  description?: string;
  requiredDeadline: string;
  isCompleted: boolean;
  isPublished?: boolean;
  assignee: any;
  createdBy?: any;
  updatedBy?: any;
  createdAt?: string;
  updatedAt?: string;
  uowType: string;
  childOf?: IGoal;
  parentOf?: any[];
  planningPeriod?: any;
  recurrenceEndDate?: any;
  recurringTaskParent?: string;
  workData: {
    uowType: string;
    workType: string;
    status: string;
    priority?: string;
    optionalDeadline?: string;
    metrics?: {
      dataType?: string;
      metricsType?: string;
      baselineMetric?: string;
      achievedMetric?: string;
      baselineDate?: Date;
    };
  };
}

export interface IGoalParams extends Params {
  assignee?: string;
  uowType?: string;
  childOf?: string;
  startDate?: string;
  endDate?: string;
  isPublished?: boolean;
  isCompleted?: boolean;
}

export enum GoalType {
  academic = "Academic",
  nonAcademic = "Non-Academic",
}
