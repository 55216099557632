import Modal from "react-modal";
import { connect } from "react-redux";
import classNames from "classnames";
import React, { useState, useCallback, useEffect } from "react";

import GoalsList from "./GoalsList";
import AddTask from "pages/tasks/add";
import { ISchoolInfo, IUser } from "commons/types/users";
import Loader from "commons/components/Loader";
import { addTask } from "store/actions/data/tasks";
import { fetchGoals } from "store/actions/data/goals";
import useMountedRef from "commons/hooks/useMountedRef";
import { getFullName, getNameInitials } from "utils/names";
import { IGoal, IGoalParams, IGoal as ITask } from "commons/types/goals";
import { subdomainName } from "utils/http";
import { getDataFromLocalStorage } from "services/localStorage";

type GoalsProps = {
  id: string;
  student: IUser;
  goals: IGoal[];
  fetchGoalsLoading?: boolean;
  openAddGoalModal: () => void;
  addTask: (task: ITask) => Promise<ITask>;
  fetchGoals: (params: IGoalParams) => Promise<IGoal[]>;
};

const Goals: React.FC<GoalsProps> = ({
  id,
  goals,
  student,
  addTask,
  fetchGoals,
  openAddGoalModal,
  fetchGoalsLoading = true,
}) => {
  const profileData: IUser = getDataFromLocalStorage("profile");

  const isMounted = useMountedRef();

  const [addTaskForGoal, setAddTaskForGoal] = useState<null | IGoal>(null);

  const fetchGoalsList = useCallback(() => {
    fetchGoals({ assignee: id });
  }, [fetchGoals, id]);

  useEffect(() => {
    fetchGoalsList();
  }, [fetchGoalsList]);

  const handleSaveTask = async (data: ITask) => {
    const task = await addTask(data);

    if (task && isMounted) {
      setAddTaskForGoal(null);
      fetchGoalsList();
    }
    return task;
  };

  const getStudentSchoolInfoItem = (key: keyof ISchoolInfo) => {
    if (!student?.userData?.schoolInfo) {
      return null;
    }
    return student.userData.schoolInfo[key];
  };

  return (
    <>
      <div className="page-heading">
        <div className="page-heading__left">
          {profileData.isStudentLoggedIn ? (
            <div>
              <h1>My Goal</h1>
            </div>
          ) : (
            <>
              <div className="avatar mt-3x">
                {student.avatar ? (
                  <img src={student.avatar} alt="Student" />
                ) : (
                  getNameInitials(student)
                )}
              </div>
              <div className="heading-detail">
                <span className="text-light-sm fs-14">
                  Student Profile | {subdomainName} Learning
                </span>
                <h2>{getFullName(student)}</h2>
                <ul
                  className={classNames("heading-info", {
                    "round round--horizontal":
                      !!getStudentSchoolInfoItem("grade") &&
                      !!getStudentSchoolInfoItem("institution")?.name,
                  })}
                >
                  <li className="">
                    {getStudentSchoolInfoItem("grade")
                      ? `Grade ${getStudentSchoolInfoItem("grade")}`
                      : ""}
                  </li>
                  <li>
                    <span className="link-item link-text">
                      {getStudentSchoolInfoItem("institution")?.name}
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div className="page-heading__right">
          <button
            onClick={openAddGoalModal}
            className="btn btn--primary mr-2x"
            type="button"
          >
            Add Goal
          </button>
        </div>
      </div>
      <div className="section-heading">
        <h3>Goals</h3>
      </div>
      {fetchGoalsLoading ? (
        <Loader type="ThreeDots" />
      ) : (
        <GoalsList setAddTaskForGoal={setAddTaskForGoal} goals={goals} />
      )}
      <Modal
        isOpen={!!addTaskForGoal}
        ariaHideApp={false}
        className="modal-block-task"
        onRequestClose={() => setAddTaskForGoal(null)}
      >
        <AddTask
          student={student}
          goal={addTaskForGoal}
          saveTask={handleSaveTask}
          closeModal={() => setAddTaskForGoal(null)}
        />
      </Modal>
    </>
  );
};

interface State {
  goals: {
    fetchGoals: IGoal[];
    fetchGoalsLoading: boolean;
  };
}

const mapStateToProps = (state: State) => ({
  goals: state.goals.fetchGoals,
  fetchGoalsLoading: state.goals.fetchGoalsLoading,
});

const mapDispatchToProps = {
  addTask,
  fetchGoals,
};

export default connect(mapStateToProps, mapDispatchToProps)(Goals);
