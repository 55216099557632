import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import VideoChat from "pages/video";
import DesignKitPage from "design-kit";
import Profile from "pages/ProfilePage";
import ProtectedRoute from "protected-route";
import WelcomePage from "./pages/WelcomePage";
import RespondForm from "pages/survey/respond";
import DesignKitDashboard from "./design-kit/dashboard";
import DesignKitChatScreen from "./design-kit/chatScreen";
import DesignKitAddStudent from "./design-kit/addStudent";
import DesignKitMentorPlans from "./design-kit/mentorPlans";
import DesignKitMentorReport from "./design-kit/mentorReport";
import DesignKitMentorEmpty from "./design-kit/mentorEmpty";
import DesignKitMentorDashboard from "./design-kit/mentorDashboard";
import DesignKitMentorGoalsList from "./design-kit/mentorGoalsList";
import DesignKitMentorGoalsEmpty from "./design-kit/mentorGoalsEmpty";
import DesignKitMentorGoalsDetail from "./design-kit/mentorGoalsDetail";
import DesignKitMentorStudentList from "./design-kit/mentorStudentList";
import DesignKitSurveyStudentList from "./design-kit/surveyStudentList";
import DesignKitSurveyAggregated from "./design-kit/surveyAggregated";
import DesignKitSurveyNotes from "./design-kit/surveyNotes";
import DesignKitSurveyForm from "./design-kit/surveyForm";
import DesignKitMentorStudentProfile from "./design-kit/mentorStudentProfile";
import DesignKitMentorMissingAssignment from "./design-kit/mentorMissingAssignment";
import DashboardPage from "pages/DashboardPage";
// import { useAuth0 } from "@auth0/auth0-react";

const PermissionDenied = () => {
  return (
    <div className="pt-5">
      <h3>
        Sorry!, you don't have a permission to view this page
        <br></br>
        <b>Permission denied</b>
      </h3>
    </div>
  );
};

const Routes = (props: any) => {
  return (
    <Router>
      <Switch>
        {/* Public Routes */}
        <Route
          exact
          path="/"
          render={(props: any) => <WelcomePage {...props} />}
        />
        {/* Common Routes */}
        <ProtectedRoute path="/dashboard" component={DashboardPage} />
        {/* <ProtectedRoute path="/superadmin" component={DashboardPage} /> */}
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/videochat" component={VideoChat} />
        {/* Public Routes */}
        <Route
          exact
          path="/survey/:id/responder\::responderId"
          component={RespondForm}
        />
        <Route
          exact
          path="/design-kit"
          render={(props: any) => <DesignKitPage {...props} />}
        />
        <Route
          exact
          path="/design-kit/dashboard"
          render={(props: any) => <DesignKitDashboard {...props} />}
        />
        <Route
          exact
          path="/design-kit/addStudent"
          render={(props: any) => <DesignKitAddStudent {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorDashboard"
          render={(props: any) => <DesignKitMentorDashboard {...props} />}
        />
        <Route
          exact
          path="/design-kit/chatScreen"
          render={(props: any) => <DesignKitChatScreen {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorStudentList"
          render={(props: any) => <DesignKitMentorStudentList {...props} />}
        />
        <Route
          exact
          path="/design-kit/surveyStudentList"
          render={(props: any) => <DesignKitSurveyStudentList {...props} />}
        />
        <Route
          exact
          path="/design-kit/surveyAggregated"
          render={(props: any) => <DesignKitSurveyAggregated {...props} />}
        />
        <Route
          exact
          path="/design-kit/surveyNotes"
          render={(props: any) => <DesignKitSurveyNotes {...props} />}
        />
        <Route
          exact
          path="/design-kit/surveyForm"
          render={(props: any) => <DesignKitSurveyForm {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorGoalsList"
          render={(props: any) => <DesignKitMentorGoalsList {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorGoalsDetail"
          component={DesignKitMentorGoalsDetail}
        />
        <Route
          exact
          path="/design-kit/mentorMissingAssignment"
          render={(props: any) => (
            <DesignKitMentorMissingAssignment {...props} />
          )}
        />
        <Route
          exact
          path="/design-kit/mentorReport"
          render={(props: any) => <DesignKitMentorReport {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorGoalsEmpty"
          render={(props: any) => <DesignKitMentorGoalsEmpty {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorPlans"
          render={(props: any) => <DesignKitMentorPlans {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorStudentProfile"
          render={(props: any) => <DesignKitMentorStudentProfile {...props} />}
        />
        <Route
          exact
          path="/design-kit/mentorEmpty"
          render={(props: any) => <DesignKitMentorEmpty {...props} />}
        />
        {/* If none of the routes match */}
        <Route path="/permission-denied" component={PermissionDenied} />
        <Route path="/*" render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
};

export default Routes;
