import "boxicons";
import { v4 as uuidV4 } from "uuid";
import classNames from "classnames";
import { connect } from "react-redux";
import { Channel } from "twilio-chat/lib/channel";
import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";

import config from "configs";
import { IUser } from "commons/types/users";
import { getDayFromDate } from "utils/dates";
import IProfile from "commons/types/profile";
import { CallType } from "commons/types/call";
import Loader from "commons/components/Loader";
import Dropdown from "./schedule-message/Dropdown";
import MessageImage from "assets/images/message.svg";
import { setReadMessage } from "store/actions/data/chat";
import { getFullName, getNameInitials } from "utils/names";
import { getDataFromLocalStorage } from "services/localStorage";
import { formatAMPM, getTextFromTwilioMessageBody } from "services/chat";

export type Props = {
  attributes?: any;
  studentData?: IUser;
  showThreadList?: any;
  twilioChannel: Channel;
  maxMessageCount?: number;
  setReadMessage: (channel: Channel) => void;
};

interface IMessage {
  sid: string;
  body: string;
  author: string;
  attributes?: any;
  dateCreated: string;
}

export const Conversation: React.FC<Props> = ({
  attributes,
  studentData,
  twilioChannel,
  showThreadList,
  setReadMessage,
  maxMessageCount,
}) => {
  const profileData: IProfile = getDataFromLocalStorage("profile");

  const [room, setRoom] = React.useState("");
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const [isTyping, setIsTyping] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [typedMessage, setTypedMessage] = React.useState("");
  const [messageAttributes, setMessageAttributes] = React.useState(attributes);

  const scrollDiv: any = useRef();

  const chatInputBox = useRef<HTMLTextAreaElement | null>(null);

  const addMessage = useCallback(
    (message: any) => {
      // const messageData = { ...message, me: message.author === chatConstants.mentorTwilioIdentity };
      const messageData = {
        ...message,
        me: message.author === profileData.auth0UserId,
      };

      const newArr: any = [messageData];
      setMessages((state) => {
        const test: any = [...state, ...newArr];
        return test;
      });
      scrollToBottom();
    },
    [profileData.auth0UserId],
  );

  const updateMessage = useCallback(
    (message: IMessage) => {
      const messageData = {
        ...message,
        me: message.author === profileData.auth0UserId,
      };

      setMessages((existingMsgs: any) =>
        existingMsgs.map((msg: any) =>
          msg.sid === messageData.sid ? { ...msg, ...messageData } : msg,
        ),
      );
    },
    [profileData.auth0UserId],
  );

  const messageAddHandler = useCallback(
    ({ author, body, dateCreated, attributes, sid }: IMessage) => {
      const timeCreated = formatAMPM(dateCreated);
      const textFromTwilioMessageBody = getTextFromTwilioMessageBody(body);
      addMessage({
        sid,
        author,
        body: textFromTwilioMessageBody,
        timeCreated,
        dateCreated,
        attributes,
      });
    },
    [addMessage],
  );

  const messageUpdateHandler = useCallback(
    ({ message }: any) => {
      const timeCreated = formatAMPM(message.dateCreated);
      const textFromTwilioMessageBody = getTextFromTwilioMessageBody(
        message.body,
      );
      updateMessage({
        body: textFromTwilioMessageBody,
        timeCreated,
        ...message.state,
      });
    },
    [updateMessage],
  );

  const typingStartedHandler = useCallback(
    (member: any) => {
      if (member?.state?.identity !== profileData.auth0UserId) {
        scrollToBottom();
        setIsTyping(true);
      }
    },
    [profileData.auth0UserId],
  );

  const typingEndedHandler = useCallback(
    (member: any) => {
      if (member?.state?.identity !== profileData.auth0UserId)
        setIsTyping(false);
    },
    [profileData.auth0UserId],
  );

  const memberJoinHandler = useCallback((member: any) => {}, []);

  const memberLeftHandler = useCallback(
    (member: any) => {
      addMessage({
        body: `${member.identity || "NoIdentity"} has left the channel.`,
      });
    },
    [addMessage],
  );

  const getProfilePicture = (self: boolean = false) => {
    const user = self ? profileData : studentData;

    if (user?.avatar) {
      return <img src={user.avatar} alt="avatar" />;
    } else if (user) {
      return getNameInitials(user);
    } else if (channelName.trim().length && !self) {
      return getNameInitials(channelName);
    } else {
      return "U";
    }
  };

  const configureChannelEvents = useCallback(
    (channel: Channel) => {
      channel.on("typingStarted", typingStartedHandler);
      channel.on("typingEnded", typingEndedHandler);
      channel.on("messageAdded", messageAddHandler);
      channel.on("memberJoined", memberJoinHandler);
      channel.on("memberLeft", memberLeftHandler);
      channel.on("messageUpdated", messageUpdateHandler);
    },
    [
      messageAddHandler,
      memberJoinHandler,
      memberLeftHandler,
      typingEndedHandler,
      typingStartedHandler,
      messageUpdateHandler,
    ],
  );

  useEffect(() => {
    return () => {
      twilioChannel.removeListener("typingStarted", typingStartedHandler);
      twilioChannel.removeListener("typingEnded", typingEndedHandler);
      twilioChannel.removeListener("messageAdded", messageAddHandler);
      twilioChannel.removeListener("memberJoined", memberJoinHandler);
      twilioChannel.removeListener("memberLeft", memberLeftHandler);
      twilioChannel.removeListener("messageUpdated", messageUpdateHandler);
    };
  }, [
    twilioChannel,
    messageAddHandler,
    memberJoinHandler,
    memberLeftHandler,
    typingEndedHandler,
    typingStartedHandler,
    messageUpdateHandler,
  ]);

  const showChatHistory = useCallback(
    async (channel: Channel) => {
      setIsLoading(true);
      let nChannel = channel;

      if (nChannel) {
        configureChannelEvents(nChannel);
        const messages = await nChannel.getMessages();
        const prepareMessages =
          messages.items?.map((message, idx) => {
            const messageText = getTextFromTwilioMessageBody(message.body);
            return {
              sid: message.sid,
              body: messageText,
              attributes: message.attributes,
              author: message.author,
              dateCreated: message.dateCreated.toString(),
              dateUpdated: message.dateUpdated.toString(),
              timeCreated: formatAMPM(message.dateCreated),
              me: message.author === profileData.auth0UserId,
            };
          }) || [];
        setMessages(prepareMessages);
        scrollToBottom();
      }
      setIsLoading(false);
    },
    [configureChannelEvents, profileData.auth0UserId],
  );

  const onTextAreaOnChange = (e: any) => {
    setTypedMessage(e.target.value);

    if (chatInputBox.current) {
      chatInputBox.current.style.height = "0px";
      resizeChatInput(e.target.value ? chatInputBox.current.scrollHeight : 0);
    }
  };

  const onMessageSubmit = () => {
    if (!typedMessage) return;

    const messageText: string = typedMessage;

    setTypedMessage("");
    const nChannel: any = twilioChannel;
    if (nChannel) {
      nChannel.sendMessage(messageText, messageAttributes);
      setMessageAttributes(null);
    }
    resizeChatInput(0);
  };

  const onVideoCall = (room: string, isAudio: boolean = false) => {
    let messageText: string = `Started a video call with ${channelName}`;
    let callType: string = CallType.VIDEO;
    if (isAudio) {
      messageText = `Started a call with ${channelName}`;
      callType = CallType.AUDIO;
    }

    const nChannel: any = twilioChannel;
    if (nChannel)
      nChannel.sendMessage(messageText, { type: callType, room: room });
  };

  const backToThreadList = () => {
    showThreadList();
  };

  const scrollToBottom = () => {
    if (scrollDiv && scrollDiv.current) {
      // scrollDiv.current.focus();
      const scrollHeight = scrollDiv.current.scrollHeight;
      const height = scrollDiv.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const getFormattedTime = (dateString: string) => {
    return new Date(dateString).toLocaleTimeString().replace(/(.*)\D\d+/, "$1");
  };

  const startCallWindow = (roomName: string, isAudio: boolean = false) => {
    const caller = profileData.auth0UserId;
    const receiver = twilioChannel.uniqueName.split(",").pop();

    window.open(
      `${
        config.uiPath.call.video
      }?caller=${caller}&receiver=${receiver}&peer=${""}&initiator=me&roomName=${roomName}&isAudio=${isAudio}`,
      "window",
      "toolbar=no, menubar=no, resizable=yes, fullscreen=yes",
    );
  };

  const channelName =
    (studentData && getFullName(studentData)) ||
    twilioChannel.friendlyName.split(",")[1] ||
    twilioChannel.friendlyName;

  useLayoutEffect(() => {
    if (twilioChannel) {
      showChatHistory(twilioChannel);
    }
  }, [twilioChannel, showChatHistory, setReadMessage]);

  useLayoutEffect(() => {
    !isLoading && scrollToBottom();
  }, [isLoading]);

  const attachedMessage = (msg: any) => {
    return msg.attributes?.uowType === "Task" ? (
      <div className="bubble-reply">
        <box-icon name="calendar-check" />
        <p>Re: {msg.attributes.name}</p>
      </div>
    ) : null;
  };

  const emptyMessage = () => {
    return (
      <div className="empty-section">
        <div className="empty-section__content wide">
          <img className="empty-icon" src={MessageImage} alt="Chat" />
          <h3>This is the beginning of your conversation with your student.</h3>
          <p>Get started by saying “Hi!” or composing a new message.</p>
        </div>
      </div>
    );
  };

  const readMessages = async () => {
    if (!isLoading) {
      setReadMessage(twilioChannel);
      const lastMessage = twilioChannel.lastMessage;
      if (lastMessage?.index) {
        twilioChannel.advanceLastConsumedMessageIndex(lastMessage.index);
      }
    }
  };

  const initiateCall = (isAudioCall = false) => {
    let roomName = room;
    if (!roomName) {
      roomName = uuidV4();
      setRoom(roomName);
    }

    onVideoCall(roomName, isAudioCall);
    startCallWindow(roomName, isAudioCall);
  };

  const resizeChatInput = (height: number) => {
    if (chatInputBox.current) {
      chatInputBox.current.style.height = height.toString() + "px";
      scrollToBottom();
    }
  };

  let prevMsgTime: string | null = null;
  const getDateSeparator = (dateString: string) => {
    const day = getDayFromDate(dateString, true);
    let currentMsgTime = null;
    if (day === "Today" || day === "Yesterday") {
      currentMsgTime = day;
    } else {
      currentMsgTime = new Date(dateString).toLocaleDateString();
    }

    if (prevMsgTime !== currentMsgTime) {
      prevMsgTime = currentMsgTime;

      return (
        <div className="date-separator">
          <span className="text-content">{currentMsgTime}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="right-panel conversation">
      <div className="message">
        {showThreadList && (
          <div className="message__title">
            <div className="message-titletext">
              <span className="icon-back link-item">
                <box-icon
                  name="arrow-back"
                  onClick={backToThreadList}
                ></box-icon>
              </span>
              <h3 className="ml-2x">{channelName}</h3>
            </div>
            <div className="message-actions">
              <span
                onClick={() => initiateCall(true)}
                className="action-links link link-item"
              >
                <box-icon name="phone-call"></box-icon>
              </span>
              <span
                onClick={() => initiateCall()}
                className="action-links link link-item"
              >
                <box-icon name="video"></box-icon>
              </span>
              <Dropdown channelUniqueName={twilioChannel.uniqueName} />
            </div>
          </div>
        )}
        <div
          ref={scrollDiv}
          className={`message__content ${
            !messages.length ? "is-empty" : ""
          } chat-box ${
            maxMessageCount && messages.length > maxMessageCount
              ? "scroll-section"
              : ""
          }`}
          onMouseEnter={readMessages}
          onMouseLeave={readMessages}
        >
          {isLoading ? (
            <Loader type="ThreeDots" />
          ) : !messages.length ? (
            emptyMessage()
          ) : (
            messages.map((msg: any, indx) => {
              let messageBody = msg.body;
              if (
                (msg.attributes?.type === "videoCall" ||
                  msg.attributes?.type === CallType.VIDEO) &&
                msg.attributes?.room
              ) {
                messageBody = !msg.me ? (
                  <>
                    Incoming video call from{" "}
                    {studentData?.userData?.nickName || channelName}.
                    <button
                      className="btn btn--small btn--primary mr-2x mt-2x"
                      onClick={() => startCallWindow(msg.attributes?.room)}
                    >
                      Join now
                    </button>
                  </>
                ) : (
                  <>
                    Outgoing video call to{" "}
                    {studentData?.userData?.nickName || channelName}.
                    <button
                      className="btn btn--small btn--primary mr-2x mt-2x"
                      onClick={() => startCallWindow(msg.attributes?.room)}
                    >
                      Re-join now
                    </button>
                  </>
                );
              } else if (msg.attributes?.room) {
                messageBody = !msg.me ? (
                  <>
                    Incoming call from{" "}
                    {studentData?.userData?.nickName || channelName}.
                    <button
                      className="btn btn--small btn--primary mr-2x mt-2x"
                      onClick={() =>
                        startCallWindow(msg.attributes?.room, true)
                      }
                    >
                      Join now
                    </button>{" "}
                  </>
                ) : (
                  <>
                    Outgoing call to{" "}
                    {studentData?.userData?.nickName || channelName}.
                    <button
                      className="btn btn--small btn--primary mr-2x mt-2x"
                      onClick={() =>
                        startCallWindow(msg.attributes?.room, true)
                      }
                    >
                      Re-join now
                    </button>
                  </>
                );
              }
              return !msg.me ? (
                <div key={msg.sid}>
                  {getDateSeparator(msg.dateCreated)}
                  <div className="chat-list incoming">
                    <div className="avatar avatar--sm mr-2x">
                      {getProfilePicture()}
                    </div>
                    <div className="bubble-wrap">
                      {attachedMessage(msg)}
                      <div
                        className={classNames("bubble", {
                          "message-call": msg.attributes?.room,
                        })}
                      >
                        <p>{messageBody}</p>
                      </div>
                      <div className="msg-time">
                        {getFormattedTime(msg.dateCreated)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={msg.sid}>
                  {getDateSeparator(msg.dateCreated)}
                  <div className="chat-list outgoing">
                    <div className="bubble-wrap">
                      {attachedMessage(msg)}
                      <div
                        className={classNames("bubble", {
                          "message-call": msg.attributes?.room,
                        })}
                      >
                        <p>{messageBody}</p>
                      </div>
                      <div className="msg-time">
                        {getFormattedTime(msg.dateCreated)}
                      </div>
                    </div>
                    <div className="avatar avatar--sm ml-2x">
                      {getProfilePicture(true)}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {isTyping && (
            <div className="chat-list incoming faded">
              <div className="avatar avatar--sm mr-2x">
                {getProfilePicture()}
              </div>
              <div className="bubble-wrap">
                <div className="bubble">
                  <Loader height={10} width={40} type="ThreeDots" />
                </div>
              </div>
            </div>
          )}
        </div>
        {messageAttributes && messageAttributes.uowType === "Task" && (
          <div className="message__reply status-block status-block--info">
            <div className="message__reply--content">
              <box-icon name="calendar-check" />
              <strong>Re:</strong> {messageAttributes.name}
            </div>
            <span className="link-item">
              <box-icon onClick={() => setMessageAttributes(null)} name="x" />
            </span>
          </div>
        )}
        <div className="message__footer">
          <div className="message__box">
            <div className="input-wrap">
              <textarea
                id="chat-input"
                ref={chatInputBox}
                className="input"
                onChange={onTextAreaOnChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onMessageSubmit();
                  } else {
                    twilioChannel.typing();
                  }
                }}
                rows={1}
                value={typedMessage}
              ></textarea>
            </div>
            <button type="button" className={`${typedMessage ? "active" : ""}`}>
              <box-icon
                name="send"
                type={typedMessage ? "solid" : "regular"}
                onClick={onMessageSubmit}
              ></box-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setReadMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
