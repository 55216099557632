import React from "react";
import { connect } from "react-redux";

import Form from "./Form";
import { getFullName } from "utils/names";
import { IUser } from "commons/types/users";
import { getCurrentEnrollment } from "utils/user";
import { ASSIGNMENT_STATUS } from "constants/index";
import { IAssignment } from "commons/types/assignments";
import { addAssignment } from "store/actions/data/assignments";

type AddAssignmentProps = {
  closeModal: () => void;
  student: IUser;
  addAssignment: (params: any) => any;
};

const AddAssignment: React.FC<AddAssignmentProps> = ({
  closeModal,
  student,
  addAssignment,
}) => {
  const user = localStorage.getItem("profile");
  let currentUser: any = {};
  if (user) {
    try {
      currentUser = JSON.parse(user);
    } catch (err: any) {
      console.error(err);
    }
  }

  const initialValues: IAssignment = {
    term: getCurrentEnrollment(student)?.enrolledTerm?._id || "",
    name: "",
    course: "",
    submissionDate: new Date(),
    isPrintable: false,
    status: ASSIGNMENT_STATUS.Created,
    assignedTo: student._id || "",
    createdBy: currentUser._id,
    isComplete: false,
  };
  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Add Missing Assignments for {getFullName(student)}</h3>
        <span onClick={closeModal} className="link-item">
          <box-icon name="x" />
        </span>
      </div>
      <Form
        student={student}
        closeModal={closeModal}
        saveAssignment={addAssignment}
        initialValues={initialValues}
      />
    </div>
  );
};

interface State {}

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  addAssignment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssignment);
