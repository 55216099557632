import envConfig from "./env.configs";

const config = {
  serverBaseUrl: envConfig.serverUrl || "http://localhost:8000",

  endpoints: {
    users: {
      all: "/users",
      one: "/users/:id",
      profile: "users/profile",
      practitionerSubjects: "/users/:practitionerId/subjects",
      subjectPractitioners: "/users/:subjectId/practitioners",
      availability: "/users/:id/availability",
      report: "users/:id/report",
      notification: "users/:id/notifications",
      basicInfoByAuth0Id: "/users/:auth0Id/basic-info",
      unseenNotifications: "users/:id/unseen-notifications",
      seenNotifications: "users/:id/seen-notifications",
      avatar: "users/:id/avatar",
      schedule: "users/:id/change-mentorship-schedule",
      pusherAuth: "real-time-communication/pusherAuth",
      call: "users/call",
      enrollment: "users/:id/enrollment",
      enrollmentOne: "users/:id/enrollment/:enrollmentId",
      welcome: "users/:id/welcome",
      reset: "users/:id/resetPassword",
    },
    notifications: {
      all: "/notifications",
    },
    terms: { all: "/terms", one: "/terms/:id" },
    chat: {
      getTwilioToken: "/users/:identity/twilioToken",
      updateMessage: "/communication/message-update",
    },
    uow: {
      all: "/uow",
      one: "/uow/:id",
      markAsIncomplete: "/uow/undo-completed",
    },
    notes: {
      all: "/notes",
      one: "/notes/:id",
    },
    communication: {
      video_token: "/communication/videoToken",
      chatToken: "/communication/chatToken",
    },
    roles: {
      all: "/roles",
      one: "roles/:id",
    },
    assignments: {
      all: "/assignments",
      one: "/assignments/:id",
    },
    accommodations: {
      all: "/accommodations",
      one: "/accommodations/:id",
    },
    academicYear: {
      all: "/academic-years",
      one: "/academic-years/:id",
    },
    gradeTypes: {
      all: "/grade-types",
      one: "/grade-types/:id",
    },
    scheduledMessages: {
      all: "/message/schedule",
      one: "/message/schedule/:id",
      templates: "/message-templates",
    },
    districts: {
      all: "/districts",
    },
    institutions: {
      all: "/institutions",
    },
    operatingEntities: {
      all: "/operating-entities",
    },
    locations: {
      all: "/locations",
      one: "/locations/:id",
    },
    satelliteLocations: {
      all: "/satellite-location",
      one: "/satellite-location/:id",
    },
    courses: {
      all: "/courses",
      one: "/courses/:id",
    },
    planningPeriods: {
      all: "/planning-periods",
    },
    email: {
      publish: "/email",
    },
    programs: {
      all: "/programs",
    },
    schoolDistrict: {
      all: "/districts",
      one: "/districts/:id",
    },
    school: {
      all: "/institutions",
      one: "/institutions/:id",
    },
    survey: {
      initiate: "/surveys/initiate",
      getCategories: "/survey-categories",
      all: "/surveys",
      one: "/surveys/:id?isTemplate=false",
      detail: "/surveys/:id?inDetail=true&isTemplate=false",
      responses: "/survey-responses",
      response: "/surveys/:id/responses",
      updateResponse: "/survey-responses/:id/update-answer",
      checkResponseIsSubmitted:
        "/survey-responses/is-submitted/:surveyId/:responderId",
      aggregation: "/survey-responses/aggregated/:surveyId",
      resendEmail: "/surveys/:id/resend",
      downloadSurveyResponses: "/survey-responses/download",
      downloadAggregateSurveyResponses: "/survey-responses/download-aggregated",
    },
    responses: {
      all: "/survey-responses",
      one: "/survey-responses/:id",
      forStudent: "/survey-responses/students/:id",
      forStudentEarly: "/survey-responses/early-responses/students/:id",
    },
    responseNote: {
      all: "/response-notes",
      one: "/response-notes/:id",
    },
    responseRevision: {
      all: "/response-revisions",
      one: "/response-revisions/:id",
    },
    grades: {
      get: "/grades/?params",
      add: "/grades",
      delete: "/grades/:gradeId",
      update: "/grades/:gradeId",
    },
    multitenancy: {
      getInitialConfig: "/tenants/initial-config/web",
      getTenantProfile: "/tenant-users/profile",
      getFinalConfig: "/tenants/final-config/web",
      getTenantsList: "/tenants",
      addTenant: "/tenants",
      updateTenant: "/tenants/:tenantId",
      getOneTenant: "/tenants/:tenantId",
      deleteTenant: "/tenants/:tenantId",
      user: {
        getUsers: "/tenant-users",
        addUser: "/tenant-users",
        editUser: "/tenant-users/:userId",
        deleteUser: "/tenant-users/:userId",
      },
      getTenantUserList: "/tenant-users/tenant/:tenantId",
    },
  },
  uiPath: {
    students: {
      add: "/dashboard/students/add",
      list: "/dashboard/students",
      goals: "/dashboard/students/:id/goals",
      plans: "/dashboard/students/:id/plans",
      assignments: "/dashboard/students/:id/assignments",
      personalInfo: "/dashboard/students/:id/personal-info",
      studentPersonalInfo: "/dashboard/:id/personal-info",
      studentMyMentor: "/dashboard/:id/my-mentor",
      academicInfo: "/dashboard/students/:id/academic-info",
      assessment: "/dashboard/students/:id/assessment",
      individualAssessmentDetails:
        "/dashboard/students/:id/assessment-detail/:responseId",
      grades: "/dashboard/students/:id/grades",
      studentPlans: "/dashboard/:id/plans",
      studentGoals: "/dashboard/:id/goals",
    },
    goals: {
      details: "/dashboard/students/:id/goals/:goalId",
      studentDetails: "/dashboard/:id/goals/:goalId",
    },
    mentors: {
      list: "/dashboard/mentors",
      add: "/dashboard/mentors/add",
      profile: "/dashboard/profile",
    },
    terms: {
      list: "/dashboard/terms",
      add: "/dashboard/terms/add",
    },
    mentor: {
      dashboard: "/dashboard",
      students: "/dashboard/students",
      assessment: "/dashboard/assessment",
    },
    call: {
      video: "/videochat",
    },
    settings: {
      center: "/dashboard/settings/centers",
      gradeType: "/dashboard/settings/grade-type",
      accommodation: "/dashboard/settings/accommodations",
      schoolDistrict: "/dashboard/settings/school-district",
      academicYear: "/dashboard/settings/academic-year",
      school: "/dashboard/settings/schools",
      satelliteLocation: "/dashboard/settings/satellite-location",
    },
    survey: {
      surveyDownload: "/dashboard/survey/download",
    },
    superAdmin: {
      tenantList: "/superadmin/tenantlist",
      tenantUserList: "/superadmin/tenantUserList/:tenantId",
      addTenant: "/superadmin/tenant/add",
      editTenant: "/superadmin/tenant/:tenantId/edit",
    },
  },
};

export default config;
