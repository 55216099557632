import * as React from "react";

export enum MenuType {
  Custom = "custom",
  Default = "default",
}

export enum MenuAlignment {
  right = "right",
  left = "left",
}

interface IMenu {
  menuIcon?: React.ReactNode;
  align?: MenuAlignment;
}

interface IMenuPropsCustom extends IMenu {
  children: React.ReactNode;
  type: MenuType.Custom;
}

interface Items {
  onClick: () => void;
  icon: string;
  label: string;
  className?: string;
}

interface IMenuProps extends IMenu {
  items: Array<Items>;
  type: MenuType.Default;
}

const Menu: React.FC<IMenuProps | IMenuPropsCustom> = (props) => {
  const [actionMenuVisible, setActionMenuVisible] = React.useState(false);

  const menuEl = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(
    (e: any) => {
      if (!menuEl?.current?.contains(e.target)) {
        setActionMenuVisible(false);
      }
    },
    [setActionMenuVisible],
  );

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getMenuIcon = () => {
    if (props.menuIcon) {
      return props.menuIcon;
    }
    return <box-icon name="dots-horizontal-rounded"></box-icon>;
  };

  /**
   * Renders default menu jsx using given items
   */
  const defaultMenu = (items: Items[]) => {
    return (
      <React.Fragment>
        {items.map((item, idx) => (
          <li
            key={idx}
            onClick={item.onClick}
            className={item.className || "menu-list"}
          >
            <box-icon name={item.icon} />
            <span className="ml-2x">{item.label}</span>
          </li>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div ref={menuEl} className={`menu-container ${props.align || "left"}`}>
      <button
        className="btn p-0x action-links menu__three-dots link-item"
        onClick={() => setActionMenuVisible((prevState) => !prevState)}
      >
        {getMenuIcon()}
      </button>
      {actionMenuVisible ? (
        <div className="menu-dialog menu-dialog--position">
          <ul className="menu-dialog__body">
            {!props?.type || props.type === MenuType.Default ? (
              defaultMenu(props.items)
            ) : (
              <ul
                className="menu-dialog__body"
                onClick={() => {
                  setActionMenuVisible(false);
                }}
              >
                {props.children}
              </ul>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Menu;
