import * as Yup from "yup";

const AddEditTenantSchema = Yup.object().shape({
  name: Yup.string().required("Tenant name is required."),
  location: Yup.string().required("Tenant location is required."),
  subDomain: Yup.string().required("Tenant sub-domain is required."),
  phone: Yup.string().required("Tenant phone number is required."),
  email: Yup.string().email().required("Tenant email is required."),
  // tenantAdminName: Yup.string().required("Tenant admin name is required."),
  tenantFriendlyName: Yup.string().required(
    "Tenant friendly name is required.",
  ),
  services: Yup.object().shape({
    auth0: Yup.object().shape({
      auth0Domain: Yup.string().required("Required"),
      audience: Yup.string().required("Required"),
      auth0CustomDomain: Yup.string().required("Required"),
      auth0DbConnection: Yup.string().required("Required"),
      singlePage: Yup.object().shape({
        auth0ClientId: Yup.string().required("Required"),
        auth0ClientSecret: Yup.string().required("Required"),
      }),
      machineToMachine: Yup.object().shape({
        auth0ClientId: Yup.string().required("Required"),
        auth0ClientSecret: Yup.string().required("Required"),
      }),
    }),
  }),
});

export default AddEditTenantSchema;
