import {
  AddGradeTypeActionType,
  FetchGradeTypesActionType,
  UpdateGradeTypeActionType,
  DeleteGradeTypeActionType,
} from "store/actions/types/gradeTypes";

const INITIAL_STATE = {
  addGradeType: {},
  addGradeTypeLoading: false,
  addGradeTypeError: null,
  fetchGradeTypes: [],
  fetchGradeTypesLoading: false,
  fetchGradeTypesError: null,
  updateGradeType: {},
  updateGradeTypeLoading: false,
  updateGradeTypeError: null,
  deleteGradeType: {},
  deleteGradeTypeLoading: false,
  deleteGradeTypeError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddGradeTypeActionType.ADD_GRADE_TYPE_PENDING:
      return { ...state, addAssignmentLoading: true, addAssignmentError: null };

    case AddGradeTypeActionType.ADD_GRADE_TYPE_FULFILLED:
      return {
        ...state,
        addGradeType: action.data,
        addGradeTypeLoading: false,
        addGradeTypeError: null,
      };
    case AddGradeTypeActionType.ADD_GRADE_TYPE_REJECTED:
      return {
        ...state,
        addAssignmentLoading: false,
        addAssignmentError: action.data,
      };
    case FetchGradeTypesActionType.FETCH_GRADE_TYPES_PENDING:
      return {
        ...state,
        fetchGradeTypesLoading: true,
        fetchGradeTypesError: null,
      };
    case FetchGradeTypesActionType.FETCH_GRADE_TYPES_FULFILLED:
      return {
        ...state,
        fetchGradeTypesLoading: false,
        fetchGradeTypesError: null,
        fetchGradeTypes: action.data,
      };

    case FetchGradeTypesActionType.FETCH_GRADE_TYPES_REJECTED:
      return {
        ...state,
        fetchAssignmentsLoading: false,
        fetchAssignmentsError: action.data,
      };
    case UpdateGradeTypeActionType.UPDATE_GRADE_TYPE_PENDING:
      return {
        ...state,
        updateGradeTypeLoading: true,
        updateGradeTypeError: null,
      };
    case UpdateGradeTypeActionType.UPDATE_GRADE_TYPE_FULFILLED:
      return {
        ...state,
        updateGradeTypeLoading: false,
        updateGradeType: action.data,
        updateGradeTypeError: null,
      };
    case UpdateGradeTypeActionType.UPDATE_GRADE_TYPE_REJECTED:
      return {
        ...state,
        updateGradeTypeLoading: false,
        updateGradeTypeError: action.data,
      };
    case DeleteGradeTypeActionType.DELETE_GRADE_TYPE_PENDING:
      return {
        ...state,
        deleteGradeTypeLoading: true,
        deleteGradeTypeError: null,
      };
    case DeleteGradeTypeActionType.DELETE_GRADE_TYPE_FULFILLED:
      return {
        ...state,
        deleteGradeType: action.data,
        deleteGradeTypeLoading: false,
        deleteGradeTypeError: null,
      };
    case DeleteGradeTypeActionType.DELETE_GRADE_TYPE_REJECTED:
      return {
        ...state,
        deleteGradeTypeLoading: false,
        deleteGradeTypeError: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
