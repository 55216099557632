import { useHistory } from "react-router-dom";

import errorImage from "assets/images/error-404-pc.svg";
import TopNavbar from "../../layouts/TopNavbar";

interface SomethingWentWrongProps {
  changeErrorState: () => void;
}
const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({
  changeErrorState,
}) => {
  const history = useHistory();

  const onClickGoToHomepageButton = () => {
    history.push("/");
    changeErrorState();
  };

  return (
    <>
      <TopNavbar options={[]} />
      <div className="error-section">
        <div className="error-section__content">
          <img src={errorImage} alt="Error" />
          <h3>Page not found</h3>
          <p>
            Sorry, we couldn’t find the page<br></br> you are looking for.
          </p>

          <button
            className="btn btn--primary mt-5x"
            type="button"
            onClick={onClickGoToHomepageButton}
          >
            Go to Homepage
          </button>
        </div>
      </div>
    </>
  );
};

export default SomethingWentWrong;
