import "izitoast/dist/css/iziToast.css";

import iziToast from "izitoast";

iziToast.settings({
  timeout: 10000,
  resetOnHover: true,
  progressBar: true,
  transitionIn: "fadeInUp",
  transitionOut: "fadeOutDown",
  position: "bottomRight",
});

export const success = (message: string) => {
  iziToast.success({
    title: "Success",
    message,
  });
};

export const error = (message: string) => {
  iziToast.error({
    title: "Error",
    message,
  });
};

export const info = (message: string) => {
  iziToast.info({
    title: "Info",
    message,
  });
};

export const warn = (message: string) => {
  iziToast.warning({
    title: "Warning",
    message,
  });
};
