import Modal from "react-modal";
import { useState } from "react";

import Loader from "../Loader";
import AttachedMessageBox from "./AttachedMessageBox";
import { IMessageAttachmentProps } from "./messageAttachment.interface";

const MessageAttachment: React.FC<IMessageAttachmentProps> = ({
  channel,
  receiver,
  attributes,
  showChatHistoryOfAChannel,
}) => {
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);

  return (
    <>
      <span className="link-item mr-2x">
        <box-icon
          onClick={() => {
            setIsMessageBoxOpen(true);
            showChatHistoryOfAChannel(receiver.auth0UserId || "", receiver);
          }}
          name="chat"
        ></box-icon>
      </span>
      <Modal
        isOpen={isMessageBoxOpen && channel ? true : false}
        className="modal-block"
        ariaHideApp={false}
        onRequestClose={() => setIsMessageBoxOpen(false)}
      >
        {!attributes._id || !channel ? (
          <Loader type="ThreeDots" />
        ) : (
          <AttachedMessageBox
            receiver={receiver}
            closeModal={() => setIsMessageBoxOpen(false)}
            attributes={attributes}
            channel={channel}
            showChatHistoryOfAChannel={showChatHistoryOfAChannel}
          />
        )}
      </Modal>
    </>
  );
};

export default MessageAttachment;
