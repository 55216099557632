import { useAuth0 } from "@auth0/auth0-react";
import { Route, useHistory } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import rolePaths from "constants/roles";
import Loader from "commons/components/Loader";
import { getDataFromLocalStorage } from "services/localStorage";
import useLogout from "commons/hooks/useLogout";

const ProtectedRoute = ({ component, ...args }: any) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  const { logout } = useLogout();

  const currentUser = getDataFromLocalStorage("profile");

  if (!isAuthenticated) {
    if (!currentUser || !currentUser._id) {
      logout();
    }
  }

  if (
    Array.isArray(currentUser?.roles) &&
    rolePaths[currentUser.roles[0].name]?.indexOf(args.computedMatch.path) ===
      -1
  ) {
    history.push("/permission-denied");
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <>
        <div className="left-panel"></div>
        <div className="content-panel">
          <Loader type="ThreeDots" />
        </div>
      </>
    ),
  });

  return <Route component={Component} {...args} />;
};

export default ProtectedRoute;
