import { ComponentStatus } from "./status";

export interface IStepData {
  currentStep: Steps;
  visible: boolean;
  selectedId?: string;
  template?: string;
  subStep?: SubSteps;
}

export interface IMetaData {
  message: string;
  receiver: string;
  sender: string;
  note: string;
}

export interface IScheduledMessage {
  createdBy: string;
  metaData: IMetaData;
  scheduledFor: string;
  status: string;
  _id: string;
}

export interface IScheduledMessageData {
  status: ComponentStatus;
  data: Array<IScheduledMessage>;
}

export interface IScheduledMessagePayload {
  message: string;
  scheduledFor: string;
  receiver: string;
  note: string;
}

export interface IScheduleMessage {
  message: string;
  scheduledFor: string;
  _id: string;
  note: string;
}
export interface IScheduleMessageData {
  status: ComponentStatus;
  data: IScheduleMessage;
  subStep: SubSteps;
}

export enum Steps {
  ScheduledMessages = 1,
  ScheduleMessage = 2,
}

export enum SubSteps {
  MessageForm = 1,
  Templates = 2,
}

export interface ITemplate {
  body: string;
  createdBy: string;
  _id: string;
}

export interface ITemplateComponentState {
  status: ComponentStatus;
  data: Array<ITemplate>;
}
