import React from "react";
import { useFormik } from "formik";

import { IUser } from "commons/types/users";
import { studentSchema } from "commons/schemas/studentUpdate";
import { CustomSelect, TextInput } from "commons/components/form-fields";
import {
  ACADEMIC_YEAR_OPTIONS,
  GRADES_OPTIONS,
  ACADEMIC_TERM,
  LEARNING_ENVIRONMENTS_OPTIONS,
} from "constants/index";

interface IUpdateStudentPersonalInfoProps {
  student: IUser;
  closeModal: () => void;
  editInformation: (payload: IUser) => void;
  hasWelcomeEmailBeenSent: boolean;
}
const UpdateStudentPersonalInfo: React.FC<IUpdateStudentPersonalInfoProps> = ({
  student,
  closeModal,
  editInformation,
  hasWelcomeEmailBeenSent,
}) => {
  const formik = useFormik({
    initialValues: {
      ...student,
      newEmail: "",
      newEmailConfirm: "",
      isEmailChangeActive: false,
    },

    onSubmit: async (values) => {
      try {
        const toSubmit = {
          ...values,
          email: values.isEmailChangeActive
            ? values.newEmail || ""
            : values.email,
        };
        await editInformation(toSubmit);
        closeModal();
      } catch (err: any) {}
    },
    validationSchema: studentSchema,
  });

  const AcademicTermOptions = ACADEMIC_TERM.map((term) => ({
    label: term,
    value: term,
  }));

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Edit student information</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="modal-wrap__body">
          <div className="row">
            <div className="col-6">
              <TextInput
                values={formik.values}
                label="First name"
                placeholder="Samantha"
                errors={formik.errors}
                name={"firstName"}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </div>
            <div className="col-6">
              <TextInput
                values={formik.values}
                label="Last name"
                placeholder="Doe"
                errors={formik.errors}
                name={"lastName"}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                values={formik.values}
                label="Nickname"
                placeholder=""
                errors={formik.errors}
                name={"userData.nickName"}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <CustomSelect
                options={GRADES_OPTIONS}
                value={
                  GRADES_OPTIONS.find(
                    (grade) =>
                      grade.value === formik.values.userData?.schoolInfo?.grade,
                  ) || { label: "", value: "" }
                }
                label={"Grade"}
                name="userData.schoolInfo.grade"
                handleChange={(value: any) => {
                  formik.setFieldValue(
                    "userData.schoolInfo.grade",
                    value.value,
                  );
                }}
                touched={formik.touched}
                errors={formik.errors}
              />
            </div>
            <div className="col-6">
              <CustomSelect
                options={AcademicTermOptions}
                value={
                  AcademicTermOptions.find(
                    (term) =>
                      term.value ===
                      formik.values.userData?.schoolInfo?.academicTerm,
                  ) || { label: "", value: "" }
                }
                name="userData.schoolInfo.academicTerm"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={(value: any) => {
                  formik.setFieldValue(
                    "userData.schoolInfo.academicTerm",
                    value.value,
                  );
                }}
                label="Term"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <CustomSelect
                options={ACADEMIC_YEAR_OPTIONS}
                value={
                  ACADEMIC_YEAR_OPTIONS.find(
                    (year) =>
                      year.value ===
                      formik.values.userData?.schoolInfo?.academicYear,
                  ) || { label: "", value: "" }
                }
                name="userData.schoolInfo.academicYear"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={(value: any) => {
                  formik.setFieldValue(
                    "userData.schoolInfo.academicYear",
                    value.value,
                  );
                }}
                label="Academic year"
              />
            </div>
            <div className="col-6">
              <CustomSelect
                options={LEARNING_ENVIRONMENTS_OPTIONS}
                value={
                  LEARNING_ENVIRONMENTS_OPTIONS.find(
                    (env) =>
                      env.value === formik.values.userData?.learningEnvironment,
                  ) || { label: "", value: "" }
                }
                name="userData.learningEnvironment"
                errors={formik.errors}
                touched={formik.touched}
                handleChange={(value: any) =>
                  formik.setFieldValue(
                    "userData.learningEnvironment",
                    value.value,
                  )
                }
                label="Learning Environment"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="input-wrap">
                <div className="justify-content-between d-flex">
                  <label className="input__label">Current Email</label>
                  {hasWelcomeEmailBeenSent && (
                    <div className="link-text fs-small">
                      {!formik.values.isEmailChangeActive && (
                        <span
                          onClick={() =>
                            formik.setFieldValue("isEmailChangeActive", true)
                          }
                        >
                          Change
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <input
                  disabled={true}
                  type="text"
                  placeholder={formik.values.email}
                  className="input"
                  name="email"
                />
                {!hasWelcomeEmailBeenSent && (
                  <span className="text-small">
                    Student's email address can only be changed after welcome
                    email is sent.
                  </span>
                )}
              </div>

              {formik.values.isEmailChangeActive && (
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div className="justify-content-between d-flex">
                        <h2 className="txt-bold text-large mt-2x">
                          Change Email Address
                        </h2>
                        <div className="link-text fs-small mt-2x">
                          <span
                            onClick={() =>
                              formik.setFieldValue("isEmailChangeActive", false)
                            }
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                      <label className="input__label text-small color-grey-50 mb-4x mt-1x">
                        This change will send notification to current email.
                      </label>
                      <TextInput
                        label={"New email address."}
                        name={"newEmail"}
                        disabled={false}
                        placeholder={"Enter new email address."}
                        touched={formik.touched}
                        errors={formik.errors}
                        values={formik.values}
                        handleChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <TextInput
                        label={"Confirm email address."}
                        name={"newEmailConfirm"}
                        disabled={!formik.values.newEmail}
                        placeholder={"Confirm email address."}
                        touched={formik.touched}
                        errors={formik.errors}
                        values={formik.values}
                        handleChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-wrap__footer">
          <div className="row">
            <div className="col-12 d-flex">
              <button
                className="btn btn--primary mr-4x"
                type="submit"
                disabled={
                  formik.isSubmitting || formik.values === formik.initialValues
                }
              >
                Save Information
              </button>
              <button
                disabled={formik.isSubmitting}
                onClick={closeModal}
                className="btn txt-primary-color"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateStudentPersonalInfo;
