import { INote } from "commons/types/notes";

export enum AddNoteActionType {
  ADD_NOTE_PENDING = "ADD_NOTE_PENDING",
  ADD_NOTE_REJECTED = "ADD_NOTE_REJECTED",
  ADD_NOTE_FULFILLED = "ADD_NOTE_FULFILLED",
}

export interface AddNoteAction {
  type: AddNoteActionType;
  data: INote;
}

export enum FetchNotesActionType {
  FETCH_NOTES_PENDING = "FETCH_NOTES_PENDING",
  FETCH_NOTES_REJECTED = "FETCH_NOTES_REJECTED",
  FETCH_NOTES_FULFILLED = "FETCH_NOTES_FULFILLED",
}

export interface FetchNotesAction {
  type: FetchNotesActionType;
  data: INote[];
}

export enum UpdateNoteActionType {
  UPDATE_NOTE_PENDING = "UPDATE_NOTE_PENDING",
  UPDATE_NOTE_REJECTED = "UPDATE_NOTE_REJECTED",
  UPDATE_NOTE_FULFILLED = "UPDATE_NOTE_FULFILLED",
}

export interface updateNoteAction {
  type: UpdateNoteActionType;
  data: INote;
}

export enum DeleteNoteActionType {
  DELETE_NOTE_PENDING = "DELETE_NOTE_PENDING",
  DELETE_NOTE_REJECTED = "DELETE_NOTE_REJECTED",
  DELETE_NOTE_FULFILLED = "DELETE_NOTE_FULFILLED",
}

export interface DeleteNoteAction {
  type: DeleteNoteActionType;
  data: INote;
}
