import "boxicons";
import day from "dayjs";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Column, useTable } from "react-table";
import React, { useState, useEffect } from "react";

import configs from "configs";
import { uuid } from "utils/uuid";
import { error } from "utils/toast";
import ActionCell from "./ActionCell";
import { ILocation } from "commons/types";
import { fetchTerms } from "services/terms";
import Loader from "commons/components/Loader";
import EmptyState from "commons/components/EmptyState";
import schoolImage from "../../../assets/images/school.svg";

const Terms = () => {
  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: "Term",
        accessor: "name",
        Cell: ({ value }: any) => {
          return <span className="txt-bold">{value}</span>;
        },
      },
      {
        Header: "Academic Year",
        accessor: "yearId",
        Cell: ({ value }: { value: { name: string } }) => {
          return value.name;
        },
      },
      {
        Header: "Location",
        accessor: "centers",
        Cell: ({ value }: { value: ILocation[] }) => {
          const id = uuid();
          if (value && value.length > 0) {
            return (
              <>
                <p className="tooltip-text">{value[0].name} </p>
                {value?.length > 1 && (
                  <>
                    <p
                      className="tooltip-info tooltip-info__link tooltip-text"
                      data-tip
                      data-for={id}
                    >{`(${value.length - 1} more)`}</p>
                    <ReactTooltip
                      multiline={true}
                      id={id}
                      place="bottom"
                      effect="solid"
                    >
                      {value
                        .slice(1)
                        .map((center: ILocation, index: number) => {
                          if (value.length - 2 === index) {
                            return (
                              <React.Fragment key={center._id}>
                                <span>{`${center.name} `}</span>
                              </React.Fragment>
                            );
                          }
                          return (
                            <React.Fragment key={center._id}>
                              <span>{`${center.name}, `}</span>
                              <br />
                            </React.Fragment>
                          );
                        })}
                    </ReactTooltip>
                  </>
                )}
              </>
            );
          }
          return "-";
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }: any) => {
          return day(value).format("MMM D, YYYY");
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ value }: any) => {
          return day(value).format("MMM D, YYYY");
        },
      },
      {
        Header: "Students",
        accessor: "subjects",
        Cell: ({ value }: any) => {
          return <div className="center"> {value ? value.length : 0}</div>;
        },
      },
      {
        Header: "Current",
        accessor: "isCurrent",
        Cell: ({ value }: any) => {
          return (
            <div className="center">
              <input
                type="checkbox"
                className="left-auto center"
                value="isCurrent"
                name="isCurrent"
                checked={value}
              />
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }: any) => {
          return <ActionCell row={row} loadTerms={loadTerms} />;
        },
      },
    ],
    [],
  );

  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadTerms = async () => {
    try {
      const data = await fetchTerms();
      setTerms(data);
      setLoading(false);
    } catch (err: any) {
      error("Terms failed to load");
    }
  };

  useEffect(() => {
    loadTerms();
  }, []);

  const tableInstance = useTable({ columns, data: terms });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (loading) {
    return <Loader type="ThreeDots" />;
  }

  const EmptyTermList = () => {
    return (
      <div className="content-panel">
        <h1>Terms</h1>
        <EmptyState
          image={schoolImage}
          title="You currently do not have any Terms."
          alt="centers empty"
        >
          <Link className="btn btn--primary" to={configs.uiPath.terms.add}>
            Add Term
          </Link>
        </EmptyState>
      </div>
    );
  };

  return (
    <div className="content-wrap pt-8x pb-8x">
      <div className="container">
        {terms.length === 0 ? (
          <EmptyTermList />
        ) : (
          <div>
            <div className="page-heading">
              <h2>Terms</h2>
              <div className="page-heading__right align-items-center">
                <Link
                  className="btn btn--primary"
                  to={configs.uiPath.terms.add}
                >
                  Add Term
                </Link>
              </div>
            </div>
            <div className="table-responsive">
              <table {...getTableProps()} className="table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="head-row"
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()} className="table__col">
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="table__row">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className="table__col">
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Terms;
