import { Dispatch } from "redux";

import * as gradesService from "services/grades";
import { error } from "utils/toast";

import {
  AddGradesActionType,
  FetchGradesActionType,
  UpdateGradesActionType,
  DeleteGradesActionType,
} from "store/actions/types/grades";
import { IAddUpdateGradeData } from "pages/grades/grade";

export const addGrade =
  (payload: IAddUpdateGradeData) => async (dispatch: Dispatch) => {
    dispatch({
      type: AddGradesActionType.ADD_GRADES_PENDING,
    });

    try {
      const { data: grade } = await gradesService.add(payload);
      dispatch({
        type: AddGradesActionType.ADD_GRADES_FULFILLED,
        data: grade,
      });
    } catch (err) {
      dispatch({
        type: AddGradesActionType.ADD_GRADES_REJECTED,
        data: err,
      });

      error("Failed to add grades");
    }
  };

export const fetchGrade = (params: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: FetchGradesActionType.FETCH_GRADES_PENDlING,
  });

  try {
    const { data: grade } = await gradesService.fetch(params);
    dispatch({
      type: FetchGradesActionType.FETCH_GRADES_FULFILLED,
      data: grade,
    });
  } catch (err) {
    dispatch({
      type: FetchGradesActionType.FETCH_GRADES_REJECTED,
      data: err,
    });

    error("Failed to fetch grades");
  }
};

export const deleteGrade = (payload: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DeleteGradesActionType.DELETE_GRADES_PENDING,
  });

  try {
    const { data: grade } = await gradesService.remove(payload);
    dispatch({
      type: DeleteGradesActionType.DELETE_GRADES_FULFILLED,
      data: grade,
    });
  } catch (err) {
    dispatch({
      type: DeleteGradesActionType.DELETE_GRADES_REJECTED,
      data: err,
    });

    error("Failed to delete grades");
  }
};

export const updateGrade =
  (payload: IAddUpdateGradeData) => async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateGradesActionType.UPDATE_GRADES_PENDING,
    });

    try {
      const { data: grade } = await gradesService.update(payload);
      dispatch({
        type: UpdateGradesActionType.UPDATE_GRADES_FULFILLED,
        data: grade,
      });
    } catch (err) {
      dispatch({
        type: UpdateGradesActionType.UPDATE_GRADES_REJECTED,
        data: err,
      });

      error("Failed to delete grades");
    }
  };
