import React, { useState } from "react";
import { useTable } from "react-table";
import Modal from "react-modal";

import { success } from "utils/toast";
import Confirm from "commons/components/Confirm";
import { deleteTenant } from "services/multitenancy";

import { useHistory } from "react-router-dom";
import config from "configs";
import { ITenantDetail } from "store/actions/types/multitenancy";
import dayjs from "dayjs";

interface Props {
  tenantData: ITenantDetail[];
  fetchTenants: () => void;
}

const TenantTable = (props: Props) => {
  const { tenantData, fetchTenants } = props;

  const history = useHistory();
  const [rowData, setRowData] = useState<any>({});
  const [isDeleteConfirmActive, setDeleteConfirmActive] = useState(false);
  const [tenantDeleting, setTenantDeleting] = useState<boolean>(false);
  const toggleDeleteConfirmActive = () => {
    setDeleteConfirmActive((prevState) => !prevState);
  };

  const handleTenantDelete = () => {
    setTenantDeleting(true);
    deleteTenant(rowData._id).then((response) => {
      setTenantDeleting(false);
      fetchTenants();
      success("Tenant deleted.");
    });
  };

  const data = React.useMemo(() => [...tenantData], []);

  const columns: any = React.useMemo(
    () => [
      {
        Header: "Logo",
        Cell: ({ row }: any) => {
          return (
            <img
              src={row.original.tenantLogo}
              alt="tenant logo"
              className="tw-w-12 tw-h-12 tw-object-contain"
            />
          );
        },
      },
      {
        Header: "Tenant Name",
        accessor: "name",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Subdomain",
        accessor: "subDomain",
      },
      {
        Header: "Status",
        Cell: ({ row }: any) => {
          return (
            <span
              className={`${
                row.original.status === "Active"
                  ? "tw-bg-green-100 tw-text-green-700"
                  : "tw-bg-red-100 tw-text-red-700"
              } tw-px-4 tw-py-1 tw-rounded-full tw-font-semibold`}
            >
              {row.original.status}
            </span>
          );
        },
      },
      {
        Header: "Start Date",
        Cell: ({ row }: any) => {
          return (
            <span className="">
              {dayjs(row.original.createdAt).format("MMM D, YYYY")}
            </span>
          );
        },
      },
      {
        Header: "End Date",
        Cell: () => <span>-</span>,
      },
      {
        Header: "Actions",
        // accessor: "_id",
        // disableSortBy: true,
        // width: 50,
        Cell: ({ row }: any) => {
          return (
            <div className="action-group tw-justify-start tw-space-x-2 tw-items-center">
              <span
                onClick={() => {
                  history.push(
                    `/superadmin/tenantUserList/${row.original._id}`,
                  );
                }}
                className=" link-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-5 tw-w-5 tw-text-[#959FAE]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
              </span>
              <span
                onClick={() => {
                  history.push(`/superadmin/tenant/${row.original._id}/edit`);
                  setRowData(row.original);
                }}
                className=" link-item"
              >
                <box-icon name="highlight" size="sm" color="#959FAE" />
              </span>
              <span
                onClick={() => {
                  setRowData(row.original);
                  toggleDeleteConfirmActive();
                }}
                className="link-item "
              >
                <box-icon name="trash" size="sm" color="#959FAE" />
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div>
      <div className="section-heading border-bottom mb-0x p-2x">
        <div className="page-heading">
          <h3>Boost Accountability Tenants</h3>
          <div className="page-heading__right">
            <div className="filter-group align-items-center">
              <button
                className="btn btn--primary"
                type="button"
                onClick={() => {
                  history.push(config.uiPath.superAdmin.addTenant);
                }}
              >
                Add Tenant
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table tw-table-fixed" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="head__row">
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps()}
                    className="table__col table-col-relative"
                  >
                    <span className="item-container">
                      <span className="item">{column.render("Header")}</span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table__row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="table__col table-col-relative"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal
        className="modal-block"
        isOpen={isDeleteConfirmActive}
        ariaHideApp={false}
        onRequestClose={toggleDeleteConfirmActive}
      >
        <Confirm
          closeModal={toggleDeleteConfirmActive}
          disabled={tenantDeleting}
          handleOk={handleTenantDelete}
          message={{
            header: "Confirm delete tenant.",
            body: "Are you sure you want to delete this tenant?",
          }}
        />
      </Modal>
    </div>
  );
};

export default TenantTable;
