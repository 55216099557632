import config from "configs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { IUser } from "commons/types/users";
import { getDateDifference } from "utils/dates";
import { getCurrentEnrollment } from "utils/user";
import StudentAssessments from "./StudentAssessments";
import { getFullName, getNameInitials } from "utils/names";
import { info } from "utils/toast";

interface IStudentsProps {
  student: any;
  showChatHistoryOfAChannel: any;
}

const StudentListCard: React.FC<IStudentsProps> = ({
  student,
  showChatHistoryOfAChannel,
}) => {
  const [showAssessments, setShowAssessments] = useState<boolean>(false);

  const history = useHistory();

  const getInactiveTime = (availability: any) => {
    const difference = getDateDifference(availability.lastAvailableOn);
    const hours = Math.floor(difference / 60);
    const days = Math.floor(hours / 24);
    if (days >= 1) {
      return `${days}${days > 1 ? " days" : " day"}`;
    }
    if (hours >= 1) {
      return `${hours}${hours > 1 ? " hours" : " hour"}`;
    }
    return `${difference}${difference > 1 ? " minutes" : " minute"}`;
  };

  const handleShowAssessment = (e: any) => {
    e.stopPropagation();
    setShowAssessments((prevState) => !prevState);
  };

  const handleChatHistory = (auth: string, student: any) => {
    auth
      ? showChatHistoryOfAChannel(auth, student)
      : info(
          `Please send a welcome email for ${getFullName(
            student,
          )} to activate the chat.`,
        );
  };

  let mentorNames: string | null = null;
  student.relatedUsers?.practitioners?.forEach((mentor: IUser) => {
    if (mentorNames) {
      mentorNames = `${mentorNames}, ${getFullName(mentor)}`;
    } else {
      mentorNames = getFullName(mentor);
    }
  });

  return (
    <div className="cardContainer">
      <div
        onClick={() => handleChatHistory(student.auth0UserId, student)}
        key={student._id}
        className={`card tw-flex-col ${showAssessments ? "mb-0x " : ""}`}
      >
        <div className="tw-flex tw-justify-between tw-items-center">
          <div
            onClick={() => {
              if (student._id)
                history.push(
                  config.uiPath.students.plans.replace(":id", student?._id),
                );
            }}
            className="card-content link-item tw-items-center"
          >
            <div className="avatar ml-2x">
              {student.avatar ? (
                <img src={student.avatar} alt="Student" />
              ) : (
                getNameInitials(student)
              )}
            </div>
            <div className="card-text">
              <div className="card-head">
                <h3>{getFullName(student)}</h3>{" "}
                {student.userData?.schoolInfo?.grade && (
                  <span>Grade {student.userData.schoolInfo.grade}</span>
                )}
              </div>
              <ul className="card-info">
                {getCurrentEnrollment(student) ? (
                  <li>
                    Last active {getInactiveTime(student.availability)} ago
                  </li>
                ) : (
                  <span className="d-flex enrollment-error">
                    <box-icon name="error-alt" /> Not enrolled in a current term
                  </span>
                )}{" "}
                {mentorNames && <li>Mentored by {mentorNames}</li>}
              </ul>
            </div>
          </div>
          <div className="card-actions tw-space-x-2">
            <span className="link-item">
              <box-icon name="chat" />
            </span>
            <span className="link-item">
              <box-icon name="spreadsheet" type="solid" />
            </span>
            <span
              className={`${
                showAssessments ? "link-item-active" : "link-item"
              }`}
            >
              <box-icon onClick={handleShowAssessment} name="clipboard" />
            </span>
            <span className="link-item">
              <box-icon
                onClick={handleShowAssessment}
                name={`${showAssessments ? "chevron-up" : "chevron-down"}`}
              />
            </span>
          </div>
        </div>
        <div className="tw-text-sm tw-space-x-4 tw-mt-4 tw-pl-2 tw-flex card-actions">
          <div className="tw-flex tw-items-center">
            <box-icon name="task-x"></box-icon>
            <span className="tw-ml-1">
              Due till today: {student.totalAssignedTask}
            </span>
          </div>
          <div className="tw-flex tw-items-center">
            <box-icon type="solid" name="check-square"></box-icon>
            <span className="tw-ml-1">
              Completed till today: {student.completedAssignedTask}
            </span>
          </div>
          <div className="tw-flex tw-items-center">
            <box-icon name="loader-alt"></box-icon>
            <span className="tw-ml-1">
              Remaining tasks{" "}
              {student.completedPercent
                ? (100 - student.completedPercent).toFixed(2)
                : 0}
            </span>
          </div>
        </div>
      </div>
      {showAssessments && <StudentAssessments student={student} />}
    </div>
  );
};

export default StudentListCard;
