import dayjs from "dayjs";

import { DAYS_OF_WEEK } from "constants/index";
import { IPlanningPeriod } from "commons/types/planningPeriod";
import { dateFormat } from "../constants/date-format";

export const checkEndDateBeforeStartDate = (startDate: any, endDate: any) => {
  return dayjs(endDate).isBefore(dayjs(startDate));
};

/**
 * Checks if the date is before/same or not
 * @param compareWith
 * @param compareTo
 * @returns
 */
export const isBeforeOrSame = (compareWith: Date, compareTo: Date) => {
  return dayjs(compareWith).isSameOrBefore(dayjs(compareTo));
};

export const getDayFromDate = (date: string, relative: boolean = false) => {
  if (relative) {
    if (
      dayjs(new Date()).add(1, "day").format("YYYY-MM-DD") ===
      dayjs(date).format("YYYY-MM-DD")
    )
      return "Tomorrow";
    if (
      dayjs(new Date()).subtract(1, "day").format("YYYY-MM-DD") ===
      dayjs(date).format("YYYY-MM-DD")
    )
      return "Yesterday";
    if (
      dayjs(new Date()).format("YYYY-MM-DD") ===
      dayjs(date).format("YYYY-MM-DD")
    )
      return "Today";
  }

  return DAYS_OF_WEEK[dayjs(date).get("day")];
};

export const getDateDifference = (date: Date) => {
  const difference = dayjs().diff(dayjs(date), "minutes");
  return difference;
};

export const getDateInFormat = (
  date: Date | string,
  format: string = "MMM D, YYYY",
) => {
  return dayjs(date).format(format);
};
export const getDateMonthInFormat = (date: Date) => {
  return dayjs(date).format("MMM D");
};

export const addDays = (prevDate: Date, days: number) => {
  const date = new Date(prevDate.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const isDateInRange = (
  startDate: Date,
  endDate: Date,
  date: Date,
  inclusive: boolean = false,
) => {
  if (inclusive) {
    return (
      dayjs(date).isSameOrAfter(dayjs(startDate)) &&
      dayjs(date).isSameOrBefore(dayjs(endDate))
    );
  }

  return (
    dayjs(date).isAfter(dayjs(startDate)) &&
    dayjs(date).isBefore(dayjs(endDate))
  );
};

export const getPlanningPeriodByDate = (
  planningPeriods: IPlanningPeriod[],
  date: Date,
  inclusive = false,
) => {
  return planningPeriods.find((planningPeriod) =>
    isDateInRange(
      planningPeriod.startDate,
      planningPeriod.endDate,
      date,
      inclusive,
    ),
  );
};

export const getUpcomingDateByDay = (
  day: string,
  options?: { startDate?: any; endDate?: any },
) => {
  const startDate = options?.startDate ? dayjs(options.startDate) : dayjs();
  let currentDate = startDate;
  let endDate = options?.endDate ? dayjs(options.endDate) : null;
  for (let i = 0; i < 7; i++) {
    if (endDate && endDate < currentDate) return null;
    if (DAYS_OF_WEEK[currentDate.day()] === day) return currentDate;
    currentDate = currentDate.add(1, "day");
  }
  return null;
};

/**
 * Get today start date
 */
export const getTodayStartDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return today;
};

export const getShortDayMonth = (date: string) =>
  dayjs(date).format(dateFormat.ShortDayMonth);
