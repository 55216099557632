import React, { createContext, useContext, useState, ReactNode } from "react";

export type ContextInterface = {
  initialTenantConfig: any;
  setInitialTenantConfig: (data: any) => void;
};

const defaultState = {
  initialTenantConfig: {},
  setInitialTenantConfig: () => null,
};

const Context = createContext<ContextInterface>(defaultState);

export const GlobalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [initialTenantConfig, setInitialTenantConfig] = useState(
    defaultState.initialTenantConfig,
  );

  return (
    <Context.Provider
      value={{
        initialTenantConfig,
        setInitialTenantConfig,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useGloabalContext = () => {
  return useContext(Context);
};
