import { FetchProfileActionType } from "store/actions/types/profile";

const INITIAL_STATE = {
  fetchProfile: null,
  fetchProfileLoading: true,
  fetchProfileError: null,
  mentorInactiveErrorMessage: "",
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FetchProfileActionType.FETCH_PROFILE_PENDING:
      return {
        ...state,
        fetchProfileLoading: true,
        fetchProfileError: null,
      };
    case FetchProfileActionType.FETCH_PROFILE_FULFILLED:
      return {
        ...state,
        fetchProfile: action.data,
        fetchProfileLoading: false,
        fetchProfileError: null,
      };

    case FetchProfileActionType.FETCH_PROFILE_REJECTED:
      return {
        ...state,
        fetchProfileLoading: false,
        fetchProfileError: action.data,
      };

    case FetchProfileActionType.MENTOR_INACTIVE_ERROR_MESSAGE:
      return {
        ...state,
        mentorInactiveErrorMessage: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
