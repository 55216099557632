import React from "react";
import { ErrorMessage, Form, FormikProvider, useFormik } from "formik";

import { getFullName } from "../../../utils/names";
import { IUser } from "../../../commons/types/users";
import { error, success } from "../../../utils/toast";
import { resendSurveyEmail } from "../../../services/surveys";
import { OptionsInput } from "../../../commons/components/form-fields";
import { RendSurveyEmailSchema } from "../../../commons/schemas/survey";
import {
  IAssessmentItem,
  IResendSurveyPayload,
} from "../../../commons/types/surveys";

interface IResendAssessmentProps {
  closeModal: () => void;
  student: IUser;
  assessment: IAssessmentItem;
  onResendAssessmentSuccess?: () => void;
}

interface FormValues {
  subjects: Array<string>;
  sponsors: Array<string>;
  isPractitionersChecked: boolean;
  checked: null; // Only used for validation test to check if at-least one check box is selected
}

const initialFormValues: FormValues = {
  subjects: [],
  sponsors: [],
  isPractitionersChecked: false,
  checked: null,
};

const ResendAssessment: React.FC<IResendAssessmentProps> = (props) => {
  const { closeModal, assessment, student, onResendAssessmentSuccess } = props;

  const formik = useFormik<FormValues>({
    initialValues: initialFormValues,
    validationSchema: RendSurveyEmailSchema,
    onSubmit: (formValues: FormValues) => {
      const onSubmitSuccess = () => {
        onResendAssessmentSuccess && onResendAssessmentSuccess();
        closeModal();
      };

      return handleFormSubmit(student, assessment, formValues, onSubmitSuccess);
    },
  });

  const ResponderSelection = () => {
    const sponsors = student.relatedUsers?.sponsors;

    const studentCheckBoxOptions = [
      {
        id: "subject",
        label: `${getFullName(student)} (Student)`,
        value: student._id || "",
        isChecked:
          !!student._id && formik.values.subjects.includes(student._id),
      },
    ];

    const sponsorCheckBoxOptions =
      sponsors?.map((sponsor) => ({
        id: `sponsor-${sponsor.userId._id}`,
        label: `${getFullName(sponsor.userId)} (${
          sponsor.relationshipWithSponsor
        })`,
        value: sponsor.userId._id,
        isChecked: formik.values.sponsors.includes(sponsor.userId._id),
      })) || [];

    const practitionersCheckBoxOptions = [
      {
        id: `practitioner`,
        label: "Send this survey to mentor",
        value: true.toString(),
        isChecked: formik.values.isPractitionersChecked,
      },
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h2 className="txt-bold text-large">Select Responders</h2>
            <label className="input__label text-small color-grey-50 mb-4x mt-1x">
              Re-send this survey to the selected responders
            </label>
            <div className="d-flex flex-column pb-2x">
              <OptionsInput
                type={"checkbox"}
                groupName={"subjects"}
                options={studentCheckBoxOptions}
                handleChange={formik.handleChange}
                errors={formik.errors}
                touched={formik.touched}
              />
              {sponsorCheckBoxOptions.length ? (
                <OptionsInput
                  type={"checkbox"}
                  groupName={"sponsors"}
                  options={sponsorCheckBoxOptions}
                  handleChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4x">
            <OptionsInput
              type={"checkbox"}
              groupName={"isPractitionersChecked"}
              options={practitionersCheckBoxOptions}
              handleChange={() => {
                formik.setFieldValue(
                  "isPractitionersChecked",
                  !formik.values.isPractitionersChecked,
                );
              }}
              errors={formik.errors}
              touched={formik.touched}
              labelClassName={"text-bold"}
            />
          </div>
        </div>
      </>
    );
  };

  const ActionButtons = () => (
    <div className="row">
      <div className="col-12 d-flex">
        <button
          disabled={formik.isSubmitting}
          className="btn btn--primary mr-4x"
          type="submit"
        >
          Re-send
        </button>
        <button
          type="reset"
          disabled={formik.isSubmitting}
          className="btn txt-primary-color"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div className="modal-wrap modal-wrap--lg">
      <div className="modal-wrap__header">
        <h3>Re-send an Assessment</h3>
        <span className="link-item" onClick={closeModal}>
          <box-icon name="x" />
        </span>
      </div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="modal-wrap__body">
            <AssessmentDetailItem
              label={"Term"}
              description={assessment.term.name}
            />
            <AssessmentDetailItem
              label={"Survey Type"}
              description={assessment.surveyType || "-"}
            />
            <AssessmentDetailItem
              label={"Survey"}
              description={assessment.category.name}
            />

            <ResponderSelection />

            <label className="input__error">
              <ErrorMessage name="checked" />
            </label>
          </div>
          <div className="modal-wrap__footer">
            <ActionButtons />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

async function handleFormSubmit(
  student: IUser,
  assessment: IAssessmentItem,
  formValues: FormValues,
  successCallback: () => void,
) {
  const selectedPractitionersIds = (() => {
    if (!formValues.isPractitionersChecked) {
      return [];
    }

    const selectedTerm = student.userData?.enrollments?.find((enrollment) => {
      if (enrollment.enrolledTerm) {
        return enrollment.enrolledTerm._id === assessment.term._id;
      }

      return false;
    });

    // @ts-ignore
    const practitionerIds: Array<string> =
      selectedTerm?.practitioners
        ?.map((practitioner) => practitioner.practitioner._id)
        .filter(Boolean) || [];

    return practitionerIds;
  })();

  try {
    const resendSurveyPayload: IResendSurveyPayload = {
      subject: formValues.subjects[0],
      sponsors: formValues.sponsors,
      practitioners: selectedPractitionersIds,
    };

    await resendSurveyEmail(assessment._id, resendSurveyPayload);

    success("Survey emails sent successfully");
    successCallback();
  } catch (err) {
    error("Failed to resend survey emails");
  }
}

interface IAssessmentDetailItemProps {
  label: string;
  description: string;
}

const AssessmentDetailItem = (props: IAssessmentDetailItemProps) => (
  <div className="row">
    <div className="input-wrap col-12">
      <div className="ml-4x">
        <label className="input__label text-small">{props.label}</label>
        <p className="text-regular text-semibold">{props.description}</p>
      </div>
      <div className="border-bottom pt-4x" />
    </div>
  </div>
);

export default ResendAssessment;
