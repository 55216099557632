import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

export const RendSurveyEmailSchema: Yup.ObjectSchema<ObjectShape> = Yup.object()
  .shape({
    subjects: Yup.array().of(Yup.string()),
    sponsors: Yup.array().of(Yup.string()),
    isPractitionersChecked: Yup.boolean(),
  })
  .test({
    name: "is at least one selected",
    message: "Select at least one option",
    test: (value, { createError }) => {
      const isNoneChecked =
        !value?.sponsors?.length &&
        !value?.subjects?.length &&
        !value.isPractitionersChecked;

      if (isNoneChecked) {
        return createError({
          message: "Please check at least one option",
          path: "checked",
        });
      }

      return true;
    },
  });

export const SendIndividualSurveySchema: Yup.ObjectSchema<ObjectShape> =
  Yup.object().shape({
    subject: Yup.string().required("Field is required"),
    surveyType: Yup.string().required("Survey type is required"),
    category: Yup.string().required("Survey is required"),
    termId: Yup.string().required("Term is required"),
    isPractitionerChecked: Yup.boolean(),
  });
