import { Dispatch } from "redux";
import { error } from "utils/toast";
import { FetchAvailabilityActionType } from "../types/availability";
import {
  getAvailabilityStatus,
  updateAvailabilityStatus,
} from "services/users";

interface IAvailability {
  isAvailable: Boolean;
  isNotifyWhenOffline: Boolean;
}

export const setAvailability =
  (payload: IAvailability) => (dispatch: Dispatch) => {
    dispatch({
      type: FetchAvailabilityActionType.FETCH_AVAILABILITY_FULFILLED,
      data: payload,
    });
  };

export const fetchAvailability = (id: string) => async (dispatch: Dispatch) => {
  dispatch({ type: FetchAvailabilityActionType.FETCH_AVAILABILITY_PENDING });
  try {
    const { data } = await getAvailabilityStatus(id);
    dispatch({
      type: FetchAvailabilityActionType.FETCH_AVAILABILITY_FULFILLED,
      data: data,
    });
  } catch (err: any) {
    dispatch({ type: FetchAvailabilityActionType.FETCH_AVAILABILITY_REJECTED });
    error("Failed to load availability status");
  }
};

export const updateAvailability =
  (id: string, payload: IAvailability) => async (dispatch: Dispatch) => {
    try {
      const { data } = await updateAvailabilityStatus(id, payload);
      dispatch({
        type: FetchAvailabilityActionType.FETCH_AVAILABILITY_FULFILLED,
        data: data,
      });
    } catch (err: any) {
      dispatch({
        type: FetchAvailabilityActionType.FETCH_AVAILABILITY_REJECTED,
      });
      error("Failed to update availability status");
    }
  };
