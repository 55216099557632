import { useState } from "react";
import { UowDeleteEditType } from "../../constants";

interface IRecurrenceDeleteEditTypeProps {
  onCancel: () => void;
  onTypeSelected: (type: UowDeleteEditType) => void;
}

const SelectRecurrenceDeleteEditType = ({
  onCancel,
  onTypeSelected,
}: IRecurrenceDeleteEditTypeProps) => {
  const [selectedType, setSelectedType] = useState(UowDeleteEditType.SELF);

  const deleteEditTypeOptions = [
    { label: "This task", value: UowDeleteEditType.SELF },
    {
      label: "This and following tasks",
      value: UowDeleteEditType.SELF_AND_FOLLOWING,
    },
    { label: "All tasks", value: UowDeleteEditType.ALL },
  ];

  return (
    <>
      <div className="modal-wrap__body">
        <ul className="menu-dialog__body">
          {deleteEditTypeOptions.map((deleteType) => (
            <li className="menu-list" key={deleteType.value}>
              <input
                className="box-links__nodes"
                type="radio"
                name="deleteType"
                id={deleteType.value}
                value={UowDeleteEditType.SELF}
                checked={selectedType === deleteType.value}
                onChange={() => setSelectedType(deleteType.value)}
              ></input>
              <label className="input__label" htmlFor={deleteType.value}>
                {deleteType.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="modal-wrap__footer pt-0x">
        <div className="row">
          <div className="col-12 d-flex">
            <button
              className="btn btn--primary mr-2x"
              type="submit"
              onClick={() => onTypeSelected(selectedType)}
            >
              Ok
            </button>
            <button
              className="btn txt-primary-color"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectRecurrenceDeleteEditType;
