import {
  AddGradesActionType,
  FetchGradesActionType,
  UpdateGradesActionType,
  DeleteGradesActionType,
} from "store/actions/types/grades";

const INITIAL_STATE = {
  addGrade: {},
  addGradeLoading: false,
  addGradeError: null,
  fetchGrade: [],
  fetchGradeLoading: false,
  fetchGradeError: null,
  updateGrade: {},
  updateGradeLoading: false,
  updateGradeError: null,
  deleteGrade: {},
  deleteGradeLoading: false,
  deleteGradeError: null,
};

const reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AddGradesActionType.ADD_GRADES_PENDING:
      return {
        ...state,
        addGoalLoading: true,
        addGoalError: null,
      };
    case AddGradesActionType.ADD_GRADES_FULFILLED:
      return {
        ...state,
        addGoal: action.data,
        addGoalError: false,
        addGoalLoading: false,
      };
    case AddGradesActionType.ADD_GRADES_REJECTED:
      return {
        ...state,
        addGoal: action.data,
        addGoalError: true,
        addGoalLoading: false,
      };
    case FetchGradesActionType.FETCH_GRADES_PENDlING:
      return {
        ...state,
        fetchGradeLoading: true,
        fetchGradeError: false,
      };
    case FetchGradesActionType.FETCH_GRADES_FULFILLED:
      return {
        ...state,
        fetchGrade: action.data,
        fetchGradeLoading: false,
        fetchGradeError: false,
      };
    case FetchGradesActionType.FETCH_GRADES_REJECTED:
      return {
        ...state,
        fetchGradeLoading: false,
        fetchGradeError: true,
      };

    case UpdateGradesActionType.UPDATE_GRADES_PENDING:
      return {
        ...state,
        updateGradeLoading: true,
        updateGradeError: false,
      };
    case UpdateGradesActionType.UPDATE_GRADES_FULFILLED:
      return {
        ...state,
        updateError: action,
        updateGradeLoading: false,
        updateGradeError: false,
      };
    case UpdateGradesActionType.UPDATE_GRADES_REJECTED:
      return {
        ...state,
        updateGradeLoading: false,
        updateGradeError: true,
      };
    case DeleteGradesActionType.DELETE_GRADES_PENDING:
      return {
        ...state,
        deleteGradeLoading: true,
        deleteGradeError: false,
      };
    case DeleteGradesActionType.DELETE_GRADES_FULFILLED:
      return {
        ...state,
        deleteGrade: action.data,
        deleteGradeLoading: false,
        deleteGradeError: false,
      };
    case DeleteGradesActionType.DELETE_GRADES_REJECTED:
      return {
        ...state,
        deleteGradeLoading: false,
        deleteGradeError: true,
      };

    default:
      return state;
  }
};

export default reducer;
