import React, { useEffect, useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import Config from "./configs/env.configs";
import Loader from "commons/components/Loader";
import { fetchInitialTenanatConfigs } from "services/multitenancy";
import SuperAdminDashboard from "commons/components/layouts/SuperAdminDashboard";
import Routes from "routes";
import { error } from "./utils/toast";
import { isAdmin, subdomain, subdomainName } from "utils/http";
import { setDataToLocalStorage } from "services/localStorage";
import { connect } from "react-redux";
import { RootState } from "store/reducers";
import boostFavicon from "./assets/images/boost_favicon.png";

type Props = {
  mentorInactiveErrorMessage: string;
};

function InitialConfigProvider(props: Props) {
  const [isInitialConfigFetched, setIsInitialConfigFetched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [tenantConfig, setTenantConfig] = useState({
    auth0Domain: "",
    auth0Client: "",
    auth0Audience: "",
  });

  const setFavIcon = (icon: any) => {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = icon;
  };

  useEffect(() => {
    document.title = isAdmin ? "Boost" : subdomainName;

    if (subdomain && isAdmin === false) {
      fetchInitialTenanatConfigs()
        .then((response: any) => {
          const {
            data: {
              services: {
                auth0: {
                  singlePage: { auth0ClientId },
                  auth0Domain,
                  audience,
                },
                pusher: { pusherAppKey, pusherCluster },
              },
              tenantLogo,
              tenantFavIcon,
            },
          } = response;

          setFavIcon(tenantFavIcon);
          setDataToLocalStorage("tenantLogo", tenantLogo);
          setDataToLocalStorage("pusher", { pusherAppKey, pusherCluster });
          setTenantConfig({
            auth0Domain,
            auth0Client: auth0ClientId,
            auth0Audience: audience,
          });
          setIsInitialConfigFetched(true);
        })
        .catch((err) => {
          console.log(err.response);
          // error("Failed to fetch initial configs");
          setErrorMessage(err.response?.data?.message);
          setIsInitialConfigFetched(true);
        });
    } else {
      setFavIcon(boostFavicon);
    }
  }, []);

  if (isAdmin) {
    return (
      <Auth0Provider
        domain={Config.auth0Domain}
        clientId={Config.auth0Client}
        redirectUri={window.location.origin}
        audience={Config.auth0Audience}
        useRefreshTokens={true}
      >
        <SuperAdminDashboard />
      </Auth0Provider>
    );
  }

  if (isInitialConfigFetched === false) {
    return <Loader type="ThreeDots" />;
  }

  if (errorMessage) {
    return (
      <div className="tw-flex tw-justify-center tw-pt-4 tw-text-3xl">
        {errorMessage}
      </div>
    );
  }

  if (props.mentorInactiveErrorMessage) {
    return (
      <div className="tw-flex tw-justify-center tw-pt-4 tw-text-3xl">
        {props.mentorInactiveErrorMessage}
      </div>
    );
  }

  return (
    <Auth0Provider
      domain={tenantConfig.auth0Domain}
      clientId={tenantConfig.auth0Client}
      redirectUri={window.location.origin}
      audience={tenantConfig.auth0Audience}
      useRefreshTokens={true}
    >
      <Routes />
    </Auth0Provider>
  );
}

const mapStateToProps = (state: RootState) => ({
  mentorInactiveErrorMessage: state.profile.mentorInactiveErrorMessage,
});

export default connect(mapStateToProps, {})(InitialConfigProvider);
