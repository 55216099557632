import React, { useEffect } from "react";

import UsersTable from "./components/UsersTable";
import { fetchTenantUserList } from "store/actions/data/multitenancy";
import { connect } from "react-redux";
import Loader from "commons/components/Loader";
import { useParams } from "react-router-dom";

interface Props {
  fetchTenantUserList: (payload: string) => void;
  fetchTenantUserListLoading: boolean;
  tenantUserList: any;
}

interface ParamTypes {
  tenantId: string;
}

const TenantUserList = (props: Props) => {
  const { fetchTenantUserList, tenantUserList, fetchTenantUserListLoading } =
    props;

  const { tenantId } = useParams<ParamTypes>();

  useEffect(() => {
    fetchTenantUserList(tenantId);
  }, [fetchTenantUserList, tenantId]);

  return (
    <>
      <div className="content-wrap content-panel">
        {fetchTenantUserListLoading ? (
          <Loader type="ThreeDots" />
        ) : (
          <div className="p-4x">
            <UsersTable
              fetchAllUsers={() => fetchTenantUserList(tenantId)}
              usersListData={tenantUserList.data}
              tenantId={tenantId}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  tenantUserList: state.multitenancy.tenantUserList,
  fetchTenantUserList: state.multitenancy.fetchTenantUserList,
  fetchTenantUserListLoading: state.multitenancy.fetchTenantUserListLoading,
});

const mapDispatchToProps = {
  fetchTenantUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantUserList);
