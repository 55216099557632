import { ICall } from "commons/types/call";
import { Dispatch } from "redux";

import { CallActionType } from "../types/communication";

export const addCall = (payload: ICall) => async (dispatch: Dispatch) => {
  dispatch({ type: CallActionType.STARTED, payload });
};

export const answerCall = () => async (dispatch: Dispatch) => {
  dispatch({ type: CallActionType.ANSWERED });
};

export const endCall = () => async (dispatch: Dispatch) => {
  dispatch({ type: CallActionType.ENDED });
};

export const rejectCall = () => async (dispatch: Dispatch) => {
  dispatch({ type: CallActionType.REJECTED });
};

export const busyCall = (payload: ICall) => async (dispatch: Dispatch) => {
  dispatch({ type: CallActionType.BUSY, payload });
};
